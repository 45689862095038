import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isMobilePhone from 'validator/lib/isMobilePhone'

import { createInvoiceAction, sendTemplateMessage } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { memberActions, renewalActions } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import { fetchDueRenewalData } from 'app/store/actions/paymentEntity'

import { columns } from './config'
import DueRenewalActionForm from './DueRenewalActionForm'

const sendPaymentReminder = async (invoiceId) => {
  try {
    const response = await sendTemplateMessage({
      template_name: 'renewal_reminder',
      params: {
        invoice_uuid: invoiceId
      }
    })

    return response
  } catch (error) {
    throw error
  }
}

const DueRenewal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)

  const dueRenewalData = useSelector(
    (state) => state.paymentEntityReducer.dueRenewalData
  )

  const dispatch = useDispatch()

  const CustomToolbarElement = <div>Total : ₹ {dueRenewalData?.due ?? 0}</div>

  const openModal = React.useCallback((e, action, dataIndex) => {
    e.preventDefault()
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onSaveClick = async (newData, action) => {
    try {
      await createInvoiceAction({
        ...newData,
        action,
        invoice: dueRenewalData.data[editIndex].uuid
      })

      eventEmitter.emit(constants.CLOSE_MODAL_FORM)
      eventAlert('Done', constants.SUCCESS)
      closeModal()
      dispatch(fetchDueRenewalData({}, false, false, constants.DUE_RENEWAL))
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'remind') {
      const { uuid, student_phone_number, parent_phone_number } =
        dueRenewalData?.data[dataIndex] || {}

      const isStudentPhoneAvailable = isMobilePhone(
        student_phone_number || '',
        'en-IN'
      )

      const isParentPhoneAvailable = isMobilePhone(
        parent_phone_number || '',
        'en-IN'
      )

      if (isStudentPhoneAvailable || isParentPhoneAvailable) {
        let userName = ''
        let userNumber = ''

        if (isParentPhoneAvailable) {
          userName = dueRenewalData?.data[dataIndex]?.parent_name
          userNumber = parent_phone_number
        } else {
          userName = dueRenewalData?.data[dataIndex]?.student_name
          userNumber = student_phone_number
        }

        try {
          await sendPaymentReminder(uuid)

          eventAlert(
            `Reminder sent to ${userName} (${userNumber})`,
            constants.SUCCESS
          )
        } catch (error) {
          eventAlert(error.message, constants.ERROR)
        }
      } else {
        eventAlert('No Phone Number Available', constants.ERROR)
      }
    } else {
      openModal(e, action, dataIndex)
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        options: {
          customBodyRenderLite: (dataIndex) => {
            return (
              <ButtonGroup>
                {Object.keys(renewalActions).map((action, index) => (
                  <Button
                    onClick={(e) => {
                      onActionButtonClick(e, action, dataIndex)
                    }}
                    key={index}
                  >
                    {renewalActions[action]}
                  </Button>
                ))}
              </ButtonGroup>
            )
          }
        }
      }
    ]
  }, [onActionButtonClick])

  return (
    <>
      <BaseEntity
        entity={constants.DUE_RENEWAL}
        label={constants.DUE_RENEWAL_LABEL}
        fetchData={fetchDueRenewalData}
        data={dueRenewalData}
        columns={modifiedColumns}
        CustomToolbarElement={CustomToolbarElement}
        readOnly
      />
      <DueRenewalActionForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
      />
    </>
  )
}

export default DueRenewal
