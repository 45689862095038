import styled, { css } from 'styled-components'

const breakpoint = '1024px'
const shadow = '10px 10px 32px -8px rgb(0 0 0 / 20%)' //'10px 10px 33px -16px rgba(0, 0, 0, 0.75)'

export const Body = styled.div`
  height: 72%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const Content = styled.div`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: lowercase;
  ${(props) =>
    props.color &&
    props.plan &&
    css`
      font-family: AvenirDemi;
      font-size: 40px;
      color: ${props.color};
    `}

  ${(props) =>
    props.subheading &&
    props.type &&
    css`
      font-family: AvenirMedium;
      font-size: 14px;
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : 'var(--oh-black-primary)'};
    `}
`

export const InfoBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const RowFlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Coins = styled.div`
  font-size: 16px;
  font-family: AvenirMedium;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CoinImg = styled.div`
  height: 12px;
  width: 12px;
  background: #ef5c34;
  border-radius: 32px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: ${breakpoint}) {
    display: none;
  }
`

export const Heading = styled.div`
  font-family: AvenirDemi;
  font-size: 14px;
  line-height: 24px;
  display: block;
`

export const Icon = styled.img``

export const Wrapper = styled.div`
  width: 264px;
  height: 226px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  transition: all 0.5s ease;
  box-shadow: ${shadow};
  background: #fff;

  &:hover {
    box-shadow: unset;
  }

  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid #ef5c34;
    `}
`
