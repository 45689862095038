export const FETCH_SUBJECT_DATA = 'FETCH_SUBJECT_DATA'
export const FETCH_SUBJECT_DATA_SUCCESS = 'FETCH_SUBJECT_DATA_SUCCESS'

export const FETCH_SUBJECT_CATEGORY_DATA = 'FETCH_SUBJECT_CATEGORY_DATA'
export const FETCH_SUBJECT_CATEGORY_DATA_SUCCESS =
  'FETCH_SUBJECT_CATEGORY_DATA_SUCCESS'

export const FETCH_BOARD_DATA = 'FETCH_BOARD_DATA'
export const FETCH_BOARD_DATA_SUCCESS = 'FETCH_BOARD_DATA_SUCCESS'

export const FETCH_STANDARD_DATA = 'FETCH_STANDARD_DATA'
export const FETCH_STANDARD_DATA_SUCCESS = 'FETCH_STANDARD_DATA_SUCCESS'

export const FETCH_COURSE_DATA = 'FETCH_COURSE_DATA'
export const FETCH_COURSE_DATA_SUCCESS = 'FETCH_COURSE_DATA_SUCCESS'

export const FETCH_BATCH_DATA = 'FETCH_BATCH_DATA'
export const FETCH_BATCH_DATA_SUCCESS = 'FETCH_BATCH_DATA_SUCCESS'

export const FETCH_TRIAL_SESSIONS_DATA = 'FETCH_TRIAL_SESSIONS_DATA'
export const FETCH_TRIAL_SESSIONS_DATA_SUCCESS =
  'FETCH_TRIAL_SESSIONS_DATA_SUCCESS'

export const FETCH_ENROLMENT_DATA = 'FETCH_ENROLMENT_DATA'
export const FETCH_ENROLMENT_DATA_SUCCESS = 'FETCH_ENROLMENT_DATA_SUCCESS'

export const FETCH_MAKE_UP_SESSION_DATA = 'FETCH_MAKE_UP_SESSION_DATA'
export const FETCH_MAKE_UP_SESSION_DATA_SUCCESS =
  'FETCH_MAKE_UP_SESSION_DATA_SUCCESS'

export const FETCH_EXPLORE_SESSION_DATA = 'FETCH_EXPLORE_SESSION_DATA'
export const FETCH_EXPLORE_SESSION_DATA_SUCCESS =
  'FETCH_EXPLORE_SESSION_DATA_SUCCESS'

export const FETCH_EXPLORE_ENROLMENT_DATA = 'FETCH_EXPLORE_ENROLMENT_DATA'
export const FETCH_EXPLORE_ENROLMENT_DATA_SUCCESS =
  'FETCH_EXPLORE_ENROLMENT_DATA_SUCCESS'

export const FETCH_EXCEL_ENROLMENT_DATA = 'FETCH_EXCEL_ENROLMENT_DATA'
export const FETCH_EXCEL_ENROLMENT_DATA_SUCCESS =
  'FETCH_EXCEL_ENROLMENT_DATA_SUCCESS'

export const FETCH_PAST_ATTENDANCE_DATA = 'FETCH_PAST_ATTENDANCE_DATA'
export const FETCH_PAST_ATTENDANCE_DATA_SUCCESS =
  'FETCH_PAST_ATTENDANCE_DATA_SUCCESS'

export const FETCH_FUTURE_ATTENDANCE_DATA = 'FETCH_FUTURE_ATTENDANCE_DATA'
export const FETCH_FUTURE_ATTENDANCE_DATA_SUCCESS =
  'FETCH_FUTURE_ATTENDANCE_DATA_SUCCESS'

export const FETCH_SCHOOL_ATTENDANCE_DATA = 'FETCH_SCHOOL_ATTENDANCE_DATA'
export const FETCH_SCHOOL_ATTENDANCE_DATA_SUCCESS =
  'FETCH_SCHOOL_ATTENDANCE_DATA_SUCCESS'

export const FETCH_INVENTORY_SCHEDULES_DATA = 'FETCH_INVENTORY_SCHEDULES_DATA'
export const FETCH_INVENTORY_SCHEDULES_DATA_SUCCESS =
  'FETCH_INVENTORY_SCHEDULES_DATA_SUCCESS'

export const UPDATE_CLASS_DATA = 'UPDATE_CLASS_DATA'
export const UPDATE_CLASS_DATA_SUCCESS = 'UPDATE_CLASS_DATA_SUCCESS'

export const CREATE_DEMO_CLASS_BOOKING = 'CREATE_DEMO_CLASS_BOOKING'
export const CREATE_CLASS_BOOKING = 'CREATE_CLASS_BOOKING'
export const CREATE_EXCEL_BOOKING = 'CREATE_EXCEL_BOOKING'

export const FETCH_EXPLORE_SESSION_FEEDBACK_DATA =
  'FETCH_EXPLORE_SESSION_FEEDBACK_DATA'
export const FETCH_EXPLORE_SESSION_FEEDBACK_DATA_SUCCESS =
  'FETCH_EXPLORE_SESSION_FEEDBACK_DATA_SUCCESS'

export const FETCH_SCHEDULE_SESSION_ACTIVITY_DATA =
  'FETCH_SCHEDULE_SESSION_ACTIVITY_DATA'
export const FETCH_SCHEDULE_SESSION_ACTIVITY_DATA_SUCCESS =
  'FETCH_SCHEDULE_SESSION_ACTIVITY_DATA_SUCCESS'

export const FETCH_OPENDAY_SCHEDULE_DATA = 'FETCH_OPENDAY_SCHEDULE_DATA'
export const FETCH_OPENDAY_SCHEDULE_DATA_SUCCESS =
  'FETCH_OPENDAY_SCHEDULE_DATA_SUCCESS'

export const FETCH_OPENDAY_SLOTS_DATA = 'FETCH_OPENDAY_SLOTS_DATA'
export const FETCH_OPENDAY_SLOTS_DATA_SUCCESS =
  'FETCH_OPENDAY_SLOTS_DATA_SUCCESS'

export const FETCH_OPPORTUNITIES_DATA = 'FETCH_OPPORTUNITIES_DATA'
export const FETCH_OPPORTUNITIES_DATA_SUCCESS =
  'FETCH_OPPORTUNITIES_DATA_SUCCESS'
