import Switch from '@material-ui/core/Switch'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import {
  fetchSchoolAttendanceData,
  updateClassData,
  fetchAttendanceData
} from 'app/store/actions/classEntity'

import { columns } from './config'

const SchoolAttendance = () => {
  const attendanceData = useSelector(
    (state) => state.classEntityReducer.schoolAttendanceData
  )

  const dispatch = useDispatch()

  const onChangeAttendance = React.useCallback(
    async (attendanceData, attended) => {
      try {
        // update attendance state
        dispatch(
          updateClassData(constants.SCHOOL_ATTENDANCE, constants.UPDATED, {
            ...attendanceData,
            uuid: attendanceData.attendance_uuid,
            attended
          })
        )
      } catch (error) {
        console.log('error in updating attendance :- ', error)
      }
    },
    [dispatch]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        label: 'attended',
        options: {
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const data = attendanceData.data[dataIndex]
            return (
              <Switch
                key={data.schedule}
                checked={data.attended}
                color="primary"
                onChange={(event) =>
                  onChangeAttendance(data, event.target.checked)
                }
              />
            )
          }
        }
      }
    ]
  }, [onChangeAttendance, attendanceData])

  const getLabel = React.useCallback(() => {
    return attendanceData.label || ''
  }, [attendanceData])

  return (
    <BaseEntity
      entity={constants.SCHOOL_ATTENDANCE}
      label={getLabel}
      columns={modifiedColumns}
      data={attendanceData}
      fetchData={fetchSchoolAttendanceData}
      readOnly
    />
  )
}

export default SchoolAttendance
