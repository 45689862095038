import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchStandardData } from 'app/store/actions/classEntity'

import { columns } from './config'

const Standard = () => {
  const standardData = useSelector(
    (state) => state.classEntityReducer.standardData
  )

  return (
    <BaseEntity
      entity={constants.STANDARD}
      label={constants.STANDARD_LABEL}
      columns={columns}
      data={standardData}
      fetchData={fetchStandardData}
      readOnly
    />
  )
}

export default Standard
