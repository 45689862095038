import styled from 'styled-components'

export const MessageWrapper = styled.div`
  margin-right: 100px;
  font-style: oblique;
  font-weight: 600;
`

export const ProgramCapacityIndicator = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${(props) => (props.isFull ? 'var(--oh-red)' : 'var(--oh-black)')};
`
