import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import { sentryLogger } from 'sentry'

import { apiEndpoints, validateUpdateRequest } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch } from '../request'

const teacherUpdateStructure = (data) => ({
  actual_teacher: data.payload.actual_teacher,
  attended: data.payload.attended,
  arrival: data.payload.arrival
})

const fetchAttendance = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.dashboardSchedules}`, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching attendance')
    throw error
  }
}

const fetchPastAttendance = async ({ url, queries } = {}) => {
  const filterQueries = {
    ...queries,
    date_lte: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    remove_empty_attendances: true,
    type: 'membership,explore'
  }
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.dashboardSchedules}`, filterQueries)

  try {
    let response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching attendance')
    throw error
  }
}

const fetchFutureAttendance = async ({ url, queries } = {}) => {
  const filterQueries = {
    ...queries,
    date_gte: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    remove_empty_attendances: true,
    type: 'membership,explore'
  }
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.dashboardSchedules}`, filterQueries)

  try {
    let response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching attendance')
    throw error
  }
}

const getAttendanceData = async (uuid) => {
  try {
    const response = await get(`${apiEndpoints.dashboardSchedules}/${uuid}/`)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching attendance')
    throw error
  }
}

const updateAttendance = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.ohClasses}/attendance/${data.uuid}/`,
      { attended: !data.attended }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating attendance')
    throw error
  }
}

const updateTeacherDetails = async (data) => {
  validateUpdateRequest(data)
  try {
    const response = await patch(
      `${apiEndpoints.ohClasses}/teacher_attendance/${data.uuid}/`,
      teacherUpdateStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating teacher details')
    throw error
  }
}

const fetchSchoolAttendance = async ({ url, queries } = {}) => {
  const tribe = new URLSearchParams(window.location.search).get('tribe') || ''

  const student =
    new URLSearchParams(window.location.search).get('student') || ''

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.tribe}/schedule/attendance?tribe=${tribe}&student=${student}`,
        queries
      )

  try {
    const response = await get(requestUrl)

    const { data: responseData } = response

    return {
      results: responseData.data
    }
  } catch (error) {
    sentryLogger(error, 'error in fetching school attendance')
    throw error
  }
}

const fetchCancelledSession = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.attendance}`, queries)

  try {
    const response = await get(requestUrl)

    return {
      results: response.data
    }
  } catch (error) {
    sentryLogger(error, 'error in fetching cancelled sessions')
    throw error
  }
}

const fetchAttendanceV1 = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.attendance}`, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching  attendance')
    throw error
  }
}

export {
  fetchAttendance,
  updateAttendance,
  fetchSchoolAttendance,
  fetchPastAttendance,
  fetchFutureAttendance,
  updateTeacherDetails,
  fetchCancelledSession,
  getAttendanceData,
  fetchAttendanceV1
}
