import dayjs from 'dayjs'

export const formatTimestamp = (timestamp) => {

  if (!timestamp) return ''
  const date = dayjs(timestamp)
  const now = dayjs()

  if (date.isSame(now, 'day')) {
    return date.format('hh:mm a') // 12-hour format
  } else {
    return date.format('DD/MM/YY') // MM/DD/YY format
  }
}

export const sortDataByLastDate = (data) => {
  return data?.sort((a, b) => {
    const dateA = new Date(a?.last_message?.created)
    const dateB = new Date(b?.last_message?.created)
    return dateB - dateA
  })
}

export const getChatHeaderData = (data) => {
  const { teacher_display_meta, parent_search_meta } = data?.details || {}
  const { student_level } = parent_search_meta || {}
  const { chat_thumbnail, chat_title, chat_labels, chat_thumbnail_labels } = teacher_display_meta || {}
  const milestone = chat_thumbnail_labels?.milestone

  const newChatLabels = {
    ...chat_labels
  }

  if (student_level !== 'BEG' && milestone != 0) {
    newChatLabels.journey = `${student_level} | ${milestone}`
  }

  newChatLabels.subject = data?.journey_subject_name

  const dataObject = {
    uuid: data?.uuid,
    url: chat_thumbnail,
    name: chat_title,
    chat_labels: newChatLabels,
    parent_number: parent_search_meta?.parent_phone_number,
  }

  return dataObject
}

export const getParentChatHeaderData = (data) => {
  const { centre_display_meta } = data?.details || {}
  const { parent_search_meta } = data?.details || {}

  const { phone_number } = parent_search_meta || {}
  const { chat_thumbnail, chat_title } = centre_display_meta || {}

  const dataObject = {
    uuid: data?.uuid,
    url: chat_thumbnail,
    name: chat_title,
    parent_number: phone_number,
    parent_id: data?.parent,
    conversation_status: data?.conversation_status
  }

  return dataObject
}

export const getStudentNameStandard = (dataArray) => {
  const studentsObject = {}

  if (dataArray)
    dataArray.forEach((item, index) => {
      studentsObject[`student${index + 1}`] = `${item?.user?.full_name} | ${item?.standard?.short_code}`
    })

  return studentsObject
}


export const createMessageObject = (bodyText, type, authorName = "admin") => {
  return {
    author_icon: "https://res.cloudinary.com/openhouse-study/image/upload/v1728386395/user/poh.png",
    author_name: authorName,
    author_type: "admin",
    content: {
      body: bodyText,
      cta: [],
      media: null,
      type: type
    },
    created: dayjs().format(),
    status: "unread"
  }
}

export const createWSMessageObject = (bodyText, type, channel_id) => {
  return {
    channel_id: channel_id,
    message: {
      body: bodyText,
      cta: [],
      media: null,
      type: type
    }
  }
}