import * as types from '../types/app'

const initialState = {
  drawerNavigationMenuState: {},
  theme: 'light',
  serverDataFetchError: {}
}

const handleUpdateServerDataFetchError = (state, action) => {
  const { entity, error, message } = action

  if (entity) {
    return {
      ...state,
      serverDataFetchError: {
        ...state.serverDataFetchError,
        [entity]: error ? message : null
      }
    }
  }

  return state
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_DRAWER_NAVIGATION_MENU_STATE:
      return {
        ...state,
        drawerNavigationMenuState: action.value
      }

    case types.UPDATE_THEME:
      return {
        ...state,
        theme: action.value
      }

    case types.UPDATE_SERVER_DATA_FETCH_ERROR:
      return handleUpdateServerDataFetchError(state, action)

    default:
      return state
  }
}

export { appReducer }
