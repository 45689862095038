import { commonAttributes } from 'app/config'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    ...commonAttributes.standard,
    label: 'Grades'
  }
]
