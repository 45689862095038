import { Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import React from 'react'

import * as Styles from './styles'

const Actions = ({ onAddToCartClick, disableAddToCart, isEditing }) => {
  const theme = useTheme()

  return (
    <Styles.ActionContainer>
      <Styles.ButtonWrapper>
        <MuiThemeProvider theme={theme}>
          <Button
            color="primary"
            variant="contained"
            onClick={onAddToCartClick}
            disabled={disableAddToCart}>
            {isEditing ? 'update cart' : 'add to cart'}
          </Button>
        </MuiThemeProvider>
      </Styles.ButtonWrapper>
    </Styles.ActionContainer>
  )
}

export { Actions }
