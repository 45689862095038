export const PillListIcon = {
  parent_name:
    'https://res.cloudinary.com/openhouse-study/image/upload/v1723794285/assets/Alfred/chat/Group.png',
  batch_timings:
    'https://res.cloudinary.com/openhouse-study/image/upload/v1723794284/assets/Alfred/chat/Vector.png',
  journey:
    'https://res.cloudinary.com/openhouse-study/image/upload/v1723794285/assets/Alfred/chat/Level.png',
  subject:
    'https://res.cloudinary.com/openhouse-study/image/upload/v1724418802/assets/Alfred/chat/subject.png'
}

export const checkMark =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1723799881/assets/Alfred/chat/check_1.png'
export const emptyDataIcon =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1723816058/assets/Alfred/chat/Group_1.png'
export const downloadButton =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1724119189/assets/Alfred/chat/download.png'
export const pdfIcon =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1724119187/assets/Alfred/chat/pdf.png'
export const backIcon =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1713970128/app_assets/images/keyboard_backspace.png'
export const progressReport =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1728978449/app_assets/images/report.png'
export const sendButton =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1730804672/app_assets/images/send_button.png'
export const studentIcon =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020949/app_assets/images/parent.png'
export const threeDots =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1733295358/app_assets/images/three_dots.png'
export const markResolvedCheck =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020949/app_assets/images/consistently_showing.png'
export const newTab =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1732709254/assets/Alfred/chat/new-tab.png'
export const scrollToBottom =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1733823020/assets/Alfred/chat/scrolltobottom.png'

export const debounceWaitTime = 500

export const MessageFileText = 'Homework.pdf'
export const NewMomentText = 'You have a new moment!'
export const VideoFallbackText = 'Your browser does not support video files.'
export const VideoNotFound = 'Video not found.'
export const ImageNotFoundText = 'Image not found.'
export const FileNotFoundText = 'File not found.'
