import styled, { css } from 'styled-components'

export const Table = styled.div`
  margin-left: 1em;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  margin-right: 1.5em;
`

export const Header = styled.div`
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.5em;
`
export const BtnGroup = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
`

export const CoinRefundContainer = styled.div`
  display: flex;
  flex-direction: column;
`
