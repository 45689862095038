import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import EmptyDataIcon from 'app/components/generic/EmptyDataIcon'
import Loading from 'app/components/generic/Loading'
import SearchComponent from 'app/components/generic/SearchComponent'
import constants from 'app/constants'
import {
  fetchParentListData,
  setParentChatMessagesHeaderData
} from 'app/store/actions/communicationEntity'
import { fetchParentList } from 'api/chat'
import { AutoComplete } from 'app/components/generic/FormFields'
import { selectParentListData } from 'app/store/selectors/communicationEntity/chat'

import * as styles from './styles'
import { fetchCentre } from 'api'
import { checkMark } from '../../constants'
import { getParentChatHeaderData } from '../../helpers'
import StudentListCard from '../StudentListCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { sentryLogger } from 'sentry'
import { chatStatusPillList } from './config'
import Pill from 'app/components/generic/Pill'
import { useWebSocket } from 'app/WebSocketProvider'

const useStyles = makeStyles(() => ({
  formControl: {
    width: 191,
    borderRadius: 8,
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  }
}))

const ParentList = (props) => {

  const {
    selectedChat,
    setSelectedChat,
    selectedPill,
    setSelectedPill,
    searchValue,
    setSearchValue,
    data,
    setData,
    nextUrl,
    setNextUrl,
    hasMore,
    setHasMore
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const parentListData = useSelector(selectParentListData)
  const storedCentre = JSON.parse(localStorage.getItem('SELECTED_CENTRE_PARENT_CHAT_CENTRE'))

  const [initialLoading, setInitialLoading] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [selectedCentre, setSelectedCentre] = React.useState(storedCentre)

  const { addMessageListener, removeMessageListener } = useWebSocket()


  const fetchCentreValues = async (searchedText) => {
    try {
      const queries = { limit: 1000 }

      if (!isEmpty(searchedText)) {
        queries.name = searchedText
      }

      const response = await fetchCentre({ queries })

      return response.results.map((item) => ({
        value: item.uuid,
        name: item.name,
        city: item.city
      }))
    } catch (error) {
      sentryLogger(error, 'Error in fetching centres')
      console.log('Error in fetching centres :- ', error)
      throw error
    }
  }

  const handleAutoCompleteOnChange = (name, value) => {
    localStorage.setItem('SELECTED_CENTRE_PARENT_CHAT_CENTRE', JSON.stringify(value))
    setSelectedCentre(value ?? null)
    dispatch(
      fetchParentListData(
        {
          queries: {
            centre_uuid: value?.value,
            page_size: 10,
            ...(selectedPill !== 'all' && { conversation_status: selectedPill }),
            search: searchValue
          }
        },
        false,
        false,
        constants.PARENT_LIST
      )
    )
  }

  const handleParentCardClick = (event, item) => {
    if (selectedChat === item.uuid) return //clicking the same parent card results in nothing

    setSelectedChat(item.uuid)
    const chatHeaderData = getParentChatHeaderData(item)
    dispatch(setParentChatMessagesHeaderData(chatHeaderData, constants.PARENT_CHAT_MESSAGES_HEADER))

    //reduce the unread count of the card clicked to zero
    const updatedData = data?.map((parentItem) =>
      (item?.uuid === parentItem?.uuid && parentItem?.is_admin_member_of_channel) ? { ...parentItem, unread_message_count: 0 } : parentItem
    )
    setData(updatedData)
  }

  const handleChatStatusPillClick = (item) => {
    setSelectedPill(item.name)
    dispatch(
      fetchParentListData(
        {
          queries: {
            page_size: 10,
            centre_uuid: selectedCentre?.value,
            ...(item.name !== 'all' && { conversation_status: item.name }),
            search: searchValue
          }
        },
        false,
        false,
        constants.PARENT_LIST
      )
    )
  }

  const fetchData = async (centre, pill, searchText, noNextUrl, pageSize = null, page) => {
    try {
      setLoading(true)
      const data = await fetchParentList({
        queries: {
          page_size: pageSize || 10,
          centre_uuid: centre || selectedCentre?.value,
          ...((pill || selectedPill) !== 'all' && { conversation_status: (pill || selectedPill) }),
          search: searchText || searchValue
        },
        url: noNextUrl ? null : nextUrl
      })
      if (!nextUrl || noNextUrl) {
        setData(data.results)
      }
      else {
        setData((prevData) => [...prevData, ...data.results])
      }
      setNextUrl(data.next)
      setHasMore(!!data.next)
    } catch (error) {
      sentryLogger(error, 'Failed to fetch parent list')
      console.error('Failed to fetch parent list')
    }
    finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }

  const handleSearchValueChange = (value) => {
    setSearchValue(value)
  }

  // to get the initial list of parents on load
  React.useEffect(() => { fetchData() }, [])

  // adding a listener to update parent list on each message received
  React.useEffect(() => {
    const unreadCountListener = (message) => {
      const parsedMessage = JSON.parse(message)
      if (parsedMessage?.uuid) {
        const container = document.getElementById('parentListScrollableDiv')
        const pageSize = Math.ceil(data.length / 10) * 10

        setNextUrl(null)
        fetchData(selectedCentre?.value, selectedPill, searchValue, true, pageSize, 1)
      }
    }
    addMessageListener(unreadCountListener)

    return () => {
      removeMessageListener(unreadCountListener)
      console.log('unread count listener removed from parent list')
    }
  }, [selectedCentre, selectedPill, searchValue, data])

  // this is used for setting search results in the list
  React.useEffect(() => {
    const data = parentListData.data
    setInitialLoading(parentListData.isLoading)
    setData(data)
    setNextUrl(parentListData.next)
    setHasMore(!!parentListData.next)
  }, [parentListData])


  return (
    <styles.ParentListContainer>
      <styles.HeaderContainer>
        <styles.InnerHeaderContainer>
          <styles.HeaderDescription>
            <div style={styles.ChatTitle}>chat</div>
            <div>parent list</div>
          </styles.HeaderDescription>
          <FormControl
            key={'centre'}
            variant="outlined"
            className={classes.formControl}
          >
            <AutoComplete
              async={false}
              name={'centre'}
              label={'centre'}
              fetchValues={fetchCentreValues}
              onChange={handleAutoCompleteOnChange}
              initialSelectIndex={0}
              minCharactersToSearch={0}
              searchWithEmptyValue={true}
              defaultValue={selectedCentre ? selectedCentre : null}
            />
          </FormControl>
        </styles.InnerHeaderContainer>
        <SearchComponent
          label={'search by parent name, number or program'}
          fetchDataAction={fetchParentListData}
          initialQueries={
            {
              page_size: 10,
              centre_uuid: selectedCentre?.value,
              ...(selectedPill !== 'all' && { conversation_status: selectedPill }),
            }}
          constants={constants.PARENT_LIST}
          onSearchChange={handleSearchValueChange}
        />
        <styles.ChatStatusPillContainer>
          {chatStatusPillList.map((item) => {
            return (
              < Pill
                showPillIcon={false}
                text={item.displayName}
                pillStyles={styles.pillStyles}
                selectedPillStyles={styles.selectedPillStyles}
                isSelected={item.name === selectedPill}
                onClick={() => handleChatStatusPillClick(item)}
                pointerDisplay={true} />)
          })}
        </styles.ChatStatusPillContainer>
      </styles.HeaderContainer>

      <styles.BodyContainer
        id="parentListScrollableDiv"
        style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
        {initialLoading ? (
          <Loading />
        ) : data?.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={hasMore}
            loader={loading ? <Loading /> : null}
            scrollableTarget="parentListScrollableDiv"
          >
            {data?.map((item, index) => (
              <StudentListCard
                key={item?.uuid}
                uuid={item?.uuid}
                name={item?.details?.centre_display_meta?.chat_title}
                thumbnailUrl={null}
                timestamp={item?.last_message?.created}
                lastMessage={item?.last_message?.content?.body}
                lastMessageStatus={item?.last_message?.status}
                lastMessageAuthor={item?.last_message?.author_type}
                iconURL={checkMark}
                unreadCount={selectedPill === 'resolved' ? 0 : item?.unread_message_count}
                onClick={(event) => handleParentCardClick(event, item)}
                isSelected={item?.uuid === selectedChat}
                lastListItem={index === data?.length - 1}
                avatarTextStyles={item?.uuid === selectedChat ? styles.selectedAvatarStyles : styles.unselectedAvatarStyles}
                showAvatarPill={item?.conversation_status === 'open' && selectedPill === 'all'}
                avatarPillText={item?.conversation_status}
                pillData={{ centre: item?.details?.centre_display_meta?.chat_labels?.centre_name }}
                showPill={selectedCentre ? false : true}
                showPillIcon={false}
                showCustomLastMessage={item?.last_message?.content?.body?.length > 0 ? false : true}
                showOnlyAdminReadReceipts={true}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyDataIcon message={'parents'} />
        )}
      </styles.BodyContainer>
    </styles.ParentListContainer>
  )
}

export default ParentList
