import { Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@mui/material'
import Modal from '@mui/material/Modal'
import React from 'react'

import { ModalContent, boxSx, closeIconSx, modalSx } from './styles'

const BaseModal = ({ open, onCloseModal, children }) => {
  return (
    <Modal sx={modalSx} open={open} onClose={onCloseModal}>
      <Box sx={boxSx}>
        <IconButton aria-label="close" onClick={onCloseModal} sx={closeIconSx}>
          <CloseIcon />
        </IconButton>
        <ModalContent>{children}</ModalContent>
      </Box>
    </Modal>
  )
}

export default BaseModal
