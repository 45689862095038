import * as types from '../types/common'

export const fetchStates = () => ({
  type: types.FETCH_STATES
})

export const fetchStatesSuccess = (value) => ({
  type: types.FETCH_STATES_SUCCESS,
  value
})

export const fetchCities = () => ({
  type: types.FETCH_CITIES
})

export const fetchCitiesSuccess = (value) => ({
  type: types.FETCH_CITIES_SUCCESS,
  value
})
