import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import React from 'react'

import { levelMap } from 'app/config'
import { formatCadence } from 'app/helpers'

import { formatDateToMedium } from '../Journey/helpers'

export const CartDetailsTable = (props) => {
  const { data } = props
  const type = data?.type
  const items = data?.invoice_items

  if (!items) return null

  return type !== 'topup' ? (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}>
          <TableRow>
            <TableCell>Child Name</TableCell>
            <TableCell>Grade</TableCell>
            <TableCell>Item Status</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Enrolment Type</TableCell>
            <TableCell>Level</TableCell>
            <TableCell>Batch</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Price (in coins)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item?.student?.user?.full_name || 'N/A'}</TableCell>
              <TableCell>{item?.student?.standard?.name || 'N/A'}</TableCell>
              <TableCell>{item?.status || 'N/A'}</TableCell>
              <TableCell>{item?.subject_name || 'N/A'}</TableCell>
              <TableCell>{item?.product?.type || 'N/A'}</TableCell>
              <TableCell>{levelMap?.[item?.batch?.level] || 'N/A'}</TableCell>
              <TableCell>
                {formatCadence(item?.batch?.cadence) || 'N/A'}
              </TableCell>
              <TableCell>
                {formatDateToMedium(item?.start_date) || 'N/A'}
              </TableCell>
              <TableCell>
                {formatDateToMedium(item?.end_date) || 'N/A'}
              </TableCell>
              <TableCell>{item?.cost_price || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}>
          <TableRow>
            <TableCell>Item Type</TableCell>
            <TableCell>Item Status</TableCell>
            <TableCell>Price (in coins)</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.reason}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.cost_price}</TableCell>
              <TableCell>
                {item?.product?.description ? item.product.description : ''}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CartDetailsTable
