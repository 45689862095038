import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const BatchDetailsTable = (props) => {
  const { data } = props

  

  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}
        >
          <TableRow>
            <TableCell>Batch Timing</TableCell>
            <TableCell>Grades</TableCell>
            <TableCell>Teachers</TableCell>
            <TableCell>Batch Level</TableCell>
            <TableCell>Enrolment Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (data, index) =>
              data && (
                <TableRow key={index}>
                  <TableCell>{data?.batch_timing}</TableCell>
                  <TableCell>{data?.grades}</TableCell>
                  <TableCell>{data?.teachers}</TableCell>
                  <TableCell>{data?.batch_level}</TableCell>
                  <TableCell>{data?.enrolment_count}</TableCell>
                </TableRow>
              )
          )}
          <TableRow>
            <TableCell colSpan={4}>Total Students</TableCell>
            <TableCell>
              {data.reduce((acc, curr) => acc + (curr?.enrolment_count ?? 0), 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

BatchDetailsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
