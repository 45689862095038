import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import constants from 'app/constants'
import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { eventAlert, makeRequestUrl } from '../app/helpers'
import { get, post, patch, remove } from '../request'

const opendaySlotsRequestStructure = (data) => {
  return {
    date: data.date,
    start_time: data.start_time,
    end_time: data.end_time,
    title: data.title,
    description: data.description,
    showcase_schedule: data.showcase_schedule,
    subject: data.subject,
    batches: data.batches
  }
}

const opendaySlotsQueries = (data) => {
  return {
    date_gte: data?.date_gte,
    date_lte: data?.date_lte,
    start_time: data?.start_time,
    end_time: data?.end_time,
    showcase_schedule_uuid: data?.scheduleUuid,
    subject_uuid: data?.subject
  }
}

const fetchOpendaySlots = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.opendaySlots, opendaySlotsQueries(queries))

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching openday slots')
    throw error
  }
}

const createOpendaySlots = async (data) => {
  try {
    const response = await post(
      apiEndpoints.opendaySlots,
      opendaySlotsRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating openday slots')
    throw error
  }
}

const getShowcaseSlotsStudents = async ({ url, queries, slotUuid } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.opendaySlots}${slotUuid}/students`,
        queries
      )

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in getting showcase slots students')
    throw error
  }
}

const getShowcaseSlotsBatches = async ({ url, queries, slotUuid } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.opendaySlots}${slotUuid}/batches`, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in getting showcase slot batches')
    throw error
  }
}

const updateOpendaySlots = async (data) => {
  // validateUpdateRequest(data)
  try {
    const response = await patch(
      `${apiEndpoints.opendaySlots}${data.uuid}/`,
      opendaySlotsRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating openday slots')
    throw error
  }
}

const deleteOpendaySlot = async (id) => {
  try {
    const response = await remove(`${apiEndpoints.opendaySlots}${id}/`)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in deleting openday slot')
    throw error
  }
}

const deleteOpendaySlots = async (data) => {
  const { ids } = data
  validateDeleteRequest(ids)

  if (typeof ids === 'string') {
    ids = [ids]
  }
  if (ids.length === 1) {
    await deleteOpendaySlot(ids[0])

    return ids
  } else {
    throw new Error('Only one slot can be deleted at a time')
  }
}

export {
  fetchOpendaySlots,
  createOpendaySlots,
  updateOpendaySlots,
  getShowcaseSlotsStudents,
  getShowcaseSlotsBatches,
  deleteOpendaySlots
}
