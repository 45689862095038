import styled from 'styled-components';


export const TeacherListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-left: 0px;
  max-width: 25%;
  min-width: 25%;
  height: 100vh;
  overflow: hidden;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 1;
  position: sticky;
  top: 0;
  min-height: 20vh;
  max-height: 20vh;
`

export const InnerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
`

export const HeaderDescription = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 16px;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0.5rem;
  border-top: 1px solid #ccc;
  position: relative;
`

export const AvatarColor = '#979797'

export const ChatTitle = {
  fontSize: '20px', fontWeight: 600
}

export const SearchComponent = {
  border: '1px solid black',
  padding: '0.5rem',
  borderRadius: '4px'
}