import { DialogContentText as MuiDialogContentText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { Transition } from 'app/components/common'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

const StyledDialog = styled(Dialog)`
  ${(props) =>
    css`
      ${props.$css}
    `}
`

const styles = (theme) => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  headerWrapper: {
    display: 'flex',
    gap: '16px',
    padding: '16px 24px'
  },
  headerBlocks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  headerHeading: {
    fontFamily: 'Avenir',
    fontSize: '14px'
  },

  headerValue: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '700'
  }
})

const CloseButton = withStyles(styles)((props) => {
  const { classes, onClick } = props

  return (
    <IconButton
      aria-label="close"
      className={classes.closeButton}
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  )
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? <CloseButton onClick={onClose} /> : null}
    </MuiDialogTitle>
  )
})

const DialogContentText = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle1">{children}</Typography>
    </MuiDialogTitle>
  )
})

const HeaderContent = withStyles(styles)((props) => {
  const { children, classes } = props
  return (
    <div className={classes.headerWrapper}>
      {children?.map((el, index) => {
        return (
          <div className={classes.headerBlocks} key={'headerBlock' + index}>
            <div className={classes.headerHeading}>{el.heading}</div>
            <div className={classes.headerValue}>{el.value}</div>
          </div>
        )
      })}
    </div>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

const ModalForm = (props) => {
  const [isSaving, setIsSaving] = React.useState(false)

  const handleClose = () => {
    if (props.onModalClose) {
      props.onModalClose()
    }
  }

  const handleisSavingDone = React.useCallback(() => {
    setIsSaving(false)
  }, [])

  React.useEffect(() => {
    eventEmitter.addListener(constants.CLOSE_MODAL_FORM, handleisSavingDone)

    return () => {
      eventEmitter.removeListener(
        constants.CLOSE_MODAL_FORM,
        handleisSavingDone
      )
    }
  }, [handleisSavingDone])

  React.useEffect(() => {
    eventEmitter.addListener(
      constants.MODAL_FORM_SAVE_ERROR,
      handleisSavingDone
    )

    return () => {
      eventEmitter.removeListener(
        constants.MODAL_FORM_SAVE_ERROR,
        handleisSavingDone
      )
    }
  }, [handleisSavingDone])

  const handleOnSaveClick = (e) => {
    e.preventDefault()
    setIsSaving(true)

    try {
      props.onSaveClick()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <StyledDialog
      disableBackdropClick={props.disableBackdropClick}
      PaperProps={{ style: props.containerStyles }}
      onClose={handleClose}
      open={props.isModalOpen}
      TransitionComponent={Transition}
      data-testid="modal-form"
      fullWidth
      maxWidth={props.maxWidth}
      $css={props.modalCss}
    >
      {props.titleHeaderData && props.modalTitle ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '40px'
          }}
        >
          <DialogTitle onClose={handleClose}>{props.modalTitle}</DialogTitle>
          <HeaderContent>{props.titleHeaderData}</HeaderContent>
        </div>
      ) : (
        props.modalTitle && (
          <DialogTitle onClose={handleClose}>{props.modalTitle}</DialogTitle>
        )
      )}
      {props.headerData && props.modalSubtitle ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogContentText>{props.modalSubtitle}</DialogContentText>
          <HeaderContent>{props.headerData}</HeaderContent>
        </div>
      ) : (
        props.modalSubtitle && (
          <DialogContentText>{props.modalSubtitle}</DialogContentText>
        )
      )}
      <DialogContent dividers>
        {props.children}
        {props.showCloseIcon ? (
          <CloseButton onClick={props.onModalClose} />
        ) : null}
      </DialogContent>
      {props.enableSave ? (
        <DialogActions>
          {!props.isEnrolment ? (
            props.cancelButton ? (
              <Button autoFocus onClick={props.onModalClose} color="primary">
                Cancel
              </Button>
            ) : (
              <Button
                autoFocus
                onClick={handleOnSaveClick}
                color="primary"
                disabled={isSaving}
              >
                {props.buttonLabel || (isSaving ? 'Saving' : 'Save')}
              </Button>
            )
          ) : (
            <>
              <Button autoFocus onClick={handleOnSaveClick} color="primary">
                Yes
              </Button>

              <Button
                autoFocus
                onClick={() => props.onModalClose()}
                color="primary"
              >
                No
              </Button>
            </>
          )}
        </DialogActions>
      ) : props.DialogActions ? (
        props.DialogActions
      ) : null}
    </StyledDialog>
  )
}

ModalForm.defaultProps = {
  enableSave: true,
  containerStyles: {},
  disableBackdropClick: false,
  cancelButton: false,
  maxWidth: 'md',
  showCloseIcon: false
}

ModalForm.propTypes = {
  cancelButton: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  buttonLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  isModalOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  onModalClose: PropTypes.func,
  onSaveClick: PropTypes.func,
  containerStyles: PropTypes.object,
  enableSave: PropTypes.bool.isRequired,
  isEnrolment: PropTypes.bool,
  maxWidth: PropTypes.string,
  showCloseIcon: PropTypes.bool
}

export default ModalForm
