import styled from 'styled-components';

export const StudentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-left: 0px;
  max-width: 32%;
  min-width: 32%;
  height: 100vh;
  overflow: hidden;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 1;
  position: sticky;
  top: 0;
  min-height: 20vh;
  max-height: 20vh;
`

export const Heading = styled.div`
  font-size: 16px;
  margin-top: 1.5rem;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0.5rem;
  border-top: 1px solid #ccc;
`

export const SearchComponent = {
  border: '1px solid black',
  padding: '0.5rem',
  borderRadius: '4px'
}

export const AvatarTextStyles = {
  backgroundColor: '#F5A066'
}