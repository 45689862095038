import { FormHelperText, InputLabel, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Select } from 'app/components/generic/FormElements'
import { TextField } from 'app/components/generic/FormElements/TextField'
import { validateFee, validateNotEmpty } from 'app/validators'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const IndependentDispatchForm = (props) => {
  const classes = useStyles()

  const { errors, values } = useFormikContext()
  const { formData, action } = props

  const formElement = React.useMemo(() => {
    return (
      <FormControl className={classes.formControl} fullWidth>
        {action === 'dispatch' ? (
          <>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'teacher_name'}
                label={'Teacher Name'}
                variant="outlined"
                required
                disabled
                validate={validateNotEmpty({ isRequired: true })}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'final_dispatch_amount'}
                label={'Dispatch Amount'}
                variant="outlined"
                required
                disabled
                validate={validateFee({ isRequired: true })}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
              {...(errors.mode ? { error: true } : null)}
            >
              <InputLabel id="dispatchMode-label">Mode of Dispatch</InputLabel>
              <Select
                label="Mode of Dispatch"
                name="mode"
                defaultValue={
                  get(formData, 'mode') !== undefined
                    ? get(formData, 'mode')
                    : ''
                }
                validate={validateNotEmpty({ isRequired: true })}
              >
                <MenuItem key={'cash'} value="cash">
                  {' '}
                  Cash{' '}
                </MenuItem>
                <MenuItem key={'bank'} value="bank">
                  {' '}
                  Bank Transfer{' '}
                </MenuItem>
              </Select>
              {!isEmpty(errors.mode) && (
                <FormHelperText>{errors.mode}</FormHelperText>
              )}
            </FormControl>
            {values.mode === 'bank' ? (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  name={'utr_id'}
                  label={'UTR ID'}
                  variant="outlined"
                  required
                  validate={validateNotEmpty({ isRequired: true })}
                />
              </FormControl>
            ) : null}
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'notes'}
                label={'Notes'}
                variant="outlined"
                required
                validate={validateNotEmpty({ isRequired: true })}
              />
            </FormControl>
          </>
        ) : null}
      </FormControl>
    )
  }, [action, classes, formData, values, errors])

  return (
    <div>{formElement}</div>
  )
}

IndependentDispatchForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default IndependentDispatchForm
