import React from 'react'
import * as styles from './styles'

const Avatar = (props) => {
  const {
    thumbnailUrl = '',
    name,
    avatarImageStyles = {},
    avatarTextStyles = {},
    showAvatarPill,
    avatarPillText,
    avatarPillTextStyles = {}
  } = props

  const initials = name?.split(' ')
    ?.filter((_, index, arr) => index === 0 || index === arr.length - 1)
    ?.map(item => item?.charAt(0))
    ?.join('')

  return (
    <div style={{ position: 'relative' }}>
      {thumbnailUrl ? (
        <img src={thumbnailUrl} style={{ ...styles.AvatarImageStyles, ...avatarImageStyles }} alt="avatar" />
      ) : (
        <div style={{ ...styles.AvatarTextStyles, ...avatarTextStyles }}>{initials}</div>
      )}
      {showAvatarPill ? <div style={{ ...styles.AvatarPillTextStyles, ...avatarPillTextStyles }}>{avatarPillText}</div> : null}
    </div>
  )

}

export default Avatar
