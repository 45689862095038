import React from 'react'

import { navigationPaths } from 'app/config'
import { userEditModalHandler } from 'app/helpers'
import BasicDetailsIcon from 'assets/images/basic_details.svg'
import SeperatorIcon from 'assets/images/seperator.svg'

import * as styles from './styles'

import { ChildDetails } from '../ChildDetails'
import { Details } from '../components/Details'
import { EditButton } from '../EditButton'
import { StudentBasicDetails } from '../StudentBasicDetails'

export const StudentDetails = (props) => {
  const { studentBasicData, parentDetails, studentData } = props

  return (
    <div>
      <styles.Header>
        <div>
          <StudentBasicDetails studentBasicData={studentBasicData}/>
          <styles.SchoolWrapper>
            {studentBasicData?.school?.name}
          </styles.SchoolWrapper>
        </div>
        <styles.EditButtonWrapper>
          <EditButton
            editHandler={() =>
              userEditModalHandler(
                navigationPaths.student,
                studentBasicData?.user_id
              )
            }
            student
          />
        </styles.EditButtonWrapper>
      </styles.Header>
      <styles.ParentWrapper>
        <Details
          heading={'Basic Details'}
          icon={BasicDetailsIcon}
          detailsData={parentDetails}
          isDetailsInRows={true}
        />
      </styles.ParentWrapper>
      <div>
        {studentData ? (
          <ChildDetails studentData={studentData} />
        ) : (
          <styles.NoDataWrapper>
            {'No Child Details available'}
          </styles.NoDataWrapper>
        )}
      </div>
    </div>
  )
}
