import {
  CircularProgress,
  FormControl,
  FormHelperText,
  makeStyles,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React from 'react'

import { fetchUser } from 'api'
import Button from 'app/components/generic/Button'
import { TextField, RadioGroup } from 'app/components/generic/FormElements'
import { AutoComplete } from 'app/components/generic/FormFields'
import { makeUpRadioButtons } from 'app/config'
import {
  validateNotEmpty,
  validateNotNil,
  validateNotNilAndBlank
} from 'app/validators'

const fetchParentDetails = async (searchedText) => {
  try {
    if (!isEmpty(searchedText) && searchedText.length === 10) {
      const queries = {
        phone_number: searchedText
      }

      const response = await fetchUser({ queries }, 'parent')

      if (response.results.length === 1) {
        return response.results.map((item) => {
          const { uuid, first_name, last_name, phone_number } = item?.user

          return {
            uuid,
            value: uuid,
            name: `${phone_number} (${first_name} ${last_name})`,
            phone_number: phone_number,
            students: item?.students
          }
        })
      } else if (response.results.length > 1) {
        throw new Error('Multiple user found with same number')
      } else {
        return []
      }
    }
  } catch (error) {
    console.log('Error in fetching user :- ', error)
    throw error
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  select: {
    margin: theme.spacing(1)
  },
  formError: {
    marginTop: 0,
    marginBottom: 0
  }
}))

const makeUpForList = []

const MakeUpForm = (props) => {
  const {
    handleParentSelection,
    handleStudentSelection,
    selectedParentDetails,
    selectedStudentDetails,
    students,
    cancelledSessions,
    handleCancelledSessionSelecetion,
    selectedCancelledSession,
    makeUpSessions,
    handleMakeUpSessionSelection,
    selectedMakeUpSession,
    loadingSubmit,
    handleOnBookClick,
    handleRadioChange,
    bookingFor,
    handleReasonChange,
    alfredReason
  } = props
  const classes = useStyles()
  const { errors, values, setFieldValue } = useFormikContext()

  console.log(errors)

  const form = React.useMemo(() => {
    return (
      <>
        <FormControl
          fullWidth
          variant="outlined"
          size="small"
          className="parent-selection"
          className={classes.formControl}
          {...(errors.parentNumber ? { error: true } : null)}
        >
          <AutoComplete
            name={'parentNumber'}
            label="parent number"
            placeholder="Enter Parent Mobile Number"
            minCharactersToSearch={10}
            required
            async
            fetchOnMount={false}
            searchWithEmptyValue={false}
            clearOnBlur={false}
            fetchValues={fetchParentDetails}
            onChange={handleParentSelection}
            {...(!isEmpty(selectedParentDetails)
              ? { defaultValue: selectedParentDetails }
              : null)}
          />
          {errors.parentNumber && (
            <FormHelperText className={classes.formError}>
              {errors.parentNumber}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          variant="outlined"
          size="medium"
          className="student-selection"
          required
          className={classes.select}
          {...(errors.students ? { error: true } : null)}
        >
          <InputLabel id="student-select">student</InputLabel>
          <Select
            name={'students'}
            label="student"
            labelId="student-select"
            onChange={handleStudentSelection}
            disabled={isEmpty(selectedParentDetails)}
            placeholder="Select Student"
            value={
              !isEmpty(selectedStudentDetails) ? selectedStudentDetails : ''
            }
          >
            {students.map((item) => (
              <MenuItem key={item.user.uuid} value={item.user.uuid}>
                {!isEmpty(item.user?.phone_number)
                  ? `${item.user.full_name}-${`${
                      item.standard?.name ? item.standard.name : ''
                    }`} (${item.user?.phone_number})`
                  : `${item.user.full_name} (${`${
                      item.standard?.name ? item.standard.name : ''
                    }`})`}
              </MenuItem>
            ))}
          </Select>
          {errors.students && (
            <FormHelperText className={classes.formError}>
              {errors.students}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.make_up_for) ? { error: true } : null)}
          required
          className={classes.select}
        >
          <FormLabel>book make-up session for</FormLabel>
          <RadioGroup
            row
            aria-label={'make_up_for'}
            name="make_up_for"
            color="primary"
            value={bookingFor}
            defaultValue="cancelled"
            onChange={handleRadioChange}
          >
            {makeUpRadioButtons.map((radioButton, index) => (
              <FormControlLabel
                key={radioButton.label + index}
                {...radioButton}
              />
            ))}
          </RadioGroup>
          {!isEmpty(errors.make_up_for) && (
            <FormHelperText>{errors.make_up_for}</FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          variant="outlined"
          size="medium"
          className="canceled-session"
          required
          className={classes.select}
          {...(errors.cancelled ? { error: true } : null)}
        >
          <InputLabel id="cancelled-session-select">
            {`${bookingFor} session `}
          </InputLabel>
          <Select
            name={'cancelled'}
            label="cancelled Session"
            labelId="cancelled-session"
            onChange={handleCancelledSessionSelecetion}
            disabled={
              isEmpty(selectedStudentDetails) && isEmpty(cancelledSessions)
            }
            value={
              !isEmpty(selectedCancelledSession) ? selectedCancelledSession : ''
            }
            placeholder={'Select Canceled Session'}
          >
            {cancelledSessions.map((item) => (
              <MenuItem key={item.uuid} value={item.value}>
                {item.data}
              </MenuItem>
            ))}
          </Select>
          {errors.cancelled && (
            <FormHelperText className={classes.formError}>
              {errors.cancelled}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          variant="outlined"
          size="medium"
          className="makeup-session"
          required
          className={classes.select}
          {...(errors.makeUp ? { error: true } : null)}
        >
          <InputLabel id="makeup-session-select">make-up session</InputLabel>
          <Select
            name={'makeUp'}
            label="makeUp Session"
            labelId="make-up-session"
            onChange={handleMakeUpSessionSelection}
            disabled={isEmpty(selectedCancelledSession)}
            value={!isEmpty(selectedMakeUpSession) ? selectedMakeUpSession : ''}
            placeholder={'Select Make-Up Session'}
          >
            {makeUpSessions.map((item) => (
              <MenuItem key={item.uuid} value={item.value}>
                {item.data}
              </MenuItem>
            ))}
          </Select>
          {errors.makeUp && (
            <FormHelperText className={classes.formError}>
              {errors.makeUp}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          className={classes.select}
          fullWidth
          variant="outlined"
          {...(!isEmpty(errors.alfred_reason) ? { error: true } : null)}
          required
        >
          <TextField
            name={'alfred_reason'}
            label={'Reason for using Alfred instead of the App'}
            variant="outlined"
            required
            validate={validateNotNilAndBlank({ isRequired: true })}
          />
          {errors.alfred_reason && (
            <FormHelperText className={classes.formError}>
              {errors.alfred_reason}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl required={true} fullWidth className={classes.formControl}>
          <Button
            variant="contained"
            onClick={handleOnBookClick}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? (
              <CircularProgress color="primary" />
            ) : (
              'Book Make-up session'
            )}
          </Button>
        </FormControl>
      </>
    )
  }, [
    selectedParentDetails,
    selectedStudentDetails,
    selectedCancelledSession,
    selectedMakeUpSession,
    students,
    cancelledSessions,
    makeUpSessions,
    alfredReason,
    errors,
    loadingSubmit
  ])

  return <>{form}</>
}

export default MakeUpForm
