import { isEmpty } from 'lodash'

import { journeyFormActions } from 'app/pages/Journey/constants'
import {
  changeBatchTimingsRequestBodyStructure,
  pauseRequestBodyStructure,
  unsubscribeRequestBodyStructure,
  submitDiscoveryReportRequestBodyStructure
} from 'app/pages/Journey/transformer'
import { sentryLogger } from 'sentry'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, post, patch } from '../request'

const fetchJourneyData = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.studentProgressJourney}`, queries)
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const fetchJourneyReportsData = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.progressReports}`, queries)
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const fetchEomWindowData = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'EOM_WINDOW_FORCE_OPEN'
  })
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    console.log(error, 'error in fetching EOM window data')
    throw error
  }
}

const fetchAbilitiesProgressData = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.abilitiesProgress}`, queries)
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const manageEnrolmentActions = async (data, action) => {
  try {
    let res
    switch (action) {
      case journeyFormActions.PAUSE_ENROLMENT:
        res = await pauseSubscription({
          ...data
        })
        break

      case journeyFormActions.UNSUBSCRIBE:
        res = await unsubscribeSubscription({
          ...data
        })
        break

      case journeyFormActions.CHANGE_BATCH_TIMINGS:
        res = await changeBatchTimings({
          ...data
        })
        break

      case journeyFormActions.SUBMIT_DISCOVERY_REPORT:
        res = await submitDiscoveryReport({
          ...data
        })
        break

      default:
        break
    }

    return res
  } catch (error) {
    sentryLogger(error)
    throw error
  }
}

const getPauseEligibility = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subscription}${data?.journeyId}/pause/eligibility/`
  )
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const pauseSubscription = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subscription}${data?.uuid}/pause/`
  )
  try {
    const response = await post(requestUrl, pauseRequestBodyStructure(data))
    return response?.data
  } catch (error) {
    throw error
  }
}

const getUnsubscribeEligibility = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subscription}${data?.journeyId}/unsubscribe/eligibility/`
  )
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const unsubscribeSubscription = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subscription}${data?.uuid}/unsubscribe/`
  )
  try {
    const response = await post(
      requestUrl,
      unsubscribeRequestBodyStructure(data)
    )
    return response?.data
  } catch (error) {
    throw error
  }
}

const getBatchChangeEligibility = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subscription}${data?.journeyId}/batch_change/eligibility/`
  )
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const changeBatchTimings = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subscription}${data?.uuid}/batch_change/`
  )
  try {
    const response = await post(
      requestUrl,
      changeBatchTimingsRequestBodyStructure(data)
    )
    return response?.data
  } catch (error) {
    throw error
  }
}

const getUpcomingMakeupClassesInBatch = async (data) => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.attendance}`, data)
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const getSubjectCurriculum = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subject}/${data?.subjectId}/curriculum`
  )
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    throw error
  }
}

const submitDiscoveryReport = async (data) => {
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.subscription}${data?.uuid}/discovery_report/`
  )
  try {
    const response = await post(
      requestUrl,
      submitDiscoveryReportRequestBodyStructure(data)
    )
    return response?.data
  } catch (error) {
    throw error
  }
}

export {
  fetchJourneyData,
  fetchJourneyReportsData,
  fetchEomWindowData,
  fetchAbilitiesProgressData,
  manageEnrolmentActions,
  getPauseEligibility,
  getUnsubscribeEligibility,
  getBatchChangeEligibility,
  getUpcomingMakeupClassesInBatch,
  getSubjectCurriculum
}
