import { isEmpty } from 'lodash'

import { fetchCentre, fetchSubject, fetchUser, fetchStandard } from 'api'
import {
  commonAttributes,
  tableColumnDefaultOption,
  bulkMessagingFilterTypes
} from 'app/config'
import constants from 'app/constants'

export const FilterFields = [
  {
    name: bulkMessagingFilterTypes.CENTRE,
    label: 'Centre',
    inputType: 'autocomplete',
    multiple: true,
    async: false,
    required: false,
    fetchValues: async (searchedText) => {
      try {
        const queries = {
          limit: 1000
        }

        if (!isEmpty(searchedText)) {
          queries.name = searchedText
        }

        const response = await fetchCentre({ queries })

        return response.results.map((item) => ({
          value: item.uuid,
          name: item.name || ''
        }))
      } catch (error) {
        console.log('Error in fetching centres :- ', error)
        throw error
      }
    }
  },
  {
    name: bulkMessagingFilterTypes.TEACHER,
    label: 'Teacher',
    inputType: 'autocomplete',
    multiple: true,
    async: true,
    required: false,
    fetchValues: async (searchedText) => {
      try {
        const queries = {
          limit: 25,
          basic: true
        }

        if (!isEmpty(searchedText)) {
          queries.name = searchedText
        }

        const response = await fetchUser({ queries }, constants.TEACHER)

        return response.results.map((item) => ({
          value: item.user?.uuid,
          name: item.user?.full_name || ''
        }))
      } catch (error) {
        console.log('Error in fetching teachers :- ', error)
        throw error
      }
    }
  },
  {
    name: bulkMessagingFilterTypes.CATEGORY,
    label: 'Category',
    inputType: 'select',
    multiple: true,
    required: false
  },
  {
    name: bulkMessagingFilterTypes.SUBJECT,
    label: 'Subject',
    inputType: 'autocomplete',
    multiple: true,
    async: false,
    required: false,
    fetchValues: async (searchedText) => {
      try {
        const queries = {
          limit: 1000
        }

        if (!isEmpty(searchedText)) {
          queries.name = searchedText
        }

        const response = await fetchSubject({ queries })

        return response.results.map((item) => ({
          value: item.uuid,
          name: item.name
        }))
      } catch (error) {
        console.log('Error in fetching subjects :- ', error)
        throw error
      }
    }
  },
  {
    name: bulkMessagingFilterTypes.CLASS,
    label: 'Class',
    inputType: 'autocomplete',
    multiple: true,
    async: false,
    required: false,
    fetchValues: async (searchedText) => {
      try {
        const queries = {
          limit: 1000
        }

        if (!isEmpty(searchedText)) {
          queries.name = searchedText
        }

        const response = await fetchStandard({ queries })

        return response.results.map((item) => ({
          value: item.uuid,
          name: item.name || ''
        }))
      } catch (error) {
        console.log('Error in fetching categories :- ', error)
        throw error
      }
    }
  }
]

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Name',
    ...tableColumnDefaultOption
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
    ...tableColumnDefaultOption
  }
]
