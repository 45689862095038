import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { Checkbox, TableHead } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles'

export const StudentsTable = (props) => {
  const { data, attendanceChange, componentDisabled } = props

  return (
    <styles.Container>
      <TableContainer>
        <TableHead>
          <TableRow>
            <TableCell className="table-cell-custom">Present</TableCell>
            <TableCell className="table-cell-custom">Absent</TableCell>
            <TableCell className="table-cell-custom">Canceled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="value-cell-custom">
              {data?.filter((el) => el.attended === 'present')?.length}
            </TableCell>
            <TableCell className="value-cell-custom">
              {data?.filter((el) => el.attended === 'absent')?.length}
            </TableCell>
            <TableCell className="value-cell-custom">
              {data?.filter((el) => el.attended === 'cancelled')?.length}
            </TableCell>
          </TableRow>
        </TableBody>
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#202020',
              color: '#fff'
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Attended</TableCell>
              <TableCell>Mark Attendance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              (student, index) =>
                student && (
                  <TableRow key={index}>
                    <TableCell>{student?.name}</TableCell>
                    <TableCell className={`${student?.color} bold`}>
                      {student?.attended}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        type="checkbox"
                        color="success"
                        name="attended"
                        value={student?.check}
                        checked={student?.check}
                        disabled={componentDisabled}
                        onChange={() => attendanceChange(index)}
                      />
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </styles.Container>
  )
}

StudentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
