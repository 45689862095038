import * as types from '../types/demoScheduling'

export const updateDemoSchedulingData = (event, data, value) => ({
  type: types.UPDATE_DEMO_SCHEDULING_DATA,
  event,
  data,
  value
})

export const resetDemoSchedulingData = () => ({
  type: types.RESET_DEMO_SCHEDULING_DATA
})

export const updateDemoSchedulingUserData = (value) => ({
  type: types.UPDATE_DEMO_SCHEDULING_USER_DATA,
  value
})
