import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import CloseIcon from '@material-ui/icons/Close'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'
import { updateDemoSchedulingData } from 'app/store/actions/demoScheduling'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  table: {
    width: 'auto',
    whiteSpace: 'nowrap',
    tableLayout: 'auto'
  },
  tableCell: {
    minWidth: 115
  },
  container: {
    overflowX: 'auto'
  },
  contentContainer: {
    padding: 16
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 16
  },
  buttonContainer: {
    display: 'flex',
    width: 700,
    justifyContent: 'space-between'
  }
}))

const columns = (value) => [
  {
    value: get(value, 'schedule_event.batch.course.subject.name')
  },
  {
    value: `${get(
      value,
      'schedule_event.batch.course.teacher.user.first_name'
    )} ${get(value, 'schedule_event.batch.course.teacher.user.last_name')}`
  },
  {
    value: `${get(value, 'start_time')} - ${get(value, 'end_time')}`
  },
  {
    value: get(value, 'date')
  }
]

const CurrentSelection = (props) => {
  const demoSchedulingData = useSelector(
    (state) => state.demoSchedulingReducer.demoSchedulingData
  )

  const [isScheduling, setIsScheduling] = React.useState(false)
  const [nurturing, setNurturing] = React.useState(false)

  const { isModalOpen, handleModalClose, handleOnSaveClick } = props

  const dispatch = useDispatch()
  const classes = useStyles()

  React.useEffect(() => {
    const handleIsScheduling = () => {
      setIsScheduling(false)
    }

    eventEmitter.addListener(
      constants.IS_SCHEDULING_REQUEST_FINISHED,
      handleIsScheduling
    )

    return () => {
      eventEmitter.removeListener(
        constants.IS_SCHEDULING_REQUEST_FINISHED,
        handleIsScheduling
      )
    }
  }, [])

  const removeDemoSchedule = React.useCallback(
    (scheduleId, batchId) => {
      dispatch(updateDemoSchedulingData(constants.REMOVED, scheduleId, batchId))
    },
    [dispatch]
  )

  const onSaveClick = React.useCallback(
    (status) => {
      const scheduleIds = []
      Object.keys(demoSchedulingData).forEach((timings) => {
        Object.keys(demoSchedulingData[timings]).forEach((timing) => {
          scheduleIds.push(demoSchedulingData[timings][timing].uuid)
        })
      })
      setIsScheduling(true)
      handleOnSaveClick(scheduleIds, status, nurturing)
      setNurturing(false)
    },
    [handleOnSaveClick, demoSchedulingData, nurturing, setNurturing]
  )

  const handleNurturingChange = React.useCallback(
    (e) => {
      setNurturing((state) => !state)
    },
    [setNurturing, nurturing]
  )

  const rows = () => {
    const rowItems = []

    Object.keys(demoSchedulingData).forEach((scheduleId, scheduleIdIndex) => {
      const timings = demoSchedulingData[scheduleId]
      Object.keys(timings).forEach((batchId, batchIdIndex) => {
        const timing = timings[batchId]
        rowItems.push(
          <TableRow key={batchIdIndex}>
            {columns(timing).map((column, index) => {
              return (
                <TableCell
                  className={classes.tableCell}
                  key={`${scheduleIdIndex}-${batchIdIndex}-${index}`}
                >
                  {column.value}
                </TableCell>
              )
            })}
            <TableCell className={classes.tableCell}>
              <IconButton
                onClick={() =>
                  removeDemoSchedule({ batchId: scheduleId }, timing)
                }
              >
                <ClearIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        )
      })
    })
    return rowItems
  }

  return (
    <Dialog
      fullScreen
      open={isModalOpen}
      onClose={handleModalClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Batches Selected
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.contentContainer}>
        {!isEmpty(demoSchedulingData) ? (
          <div className={classes.container}>
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                className={classes.table}
                size="small"
                style={{ width: '100%' }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Subject</TableCell>
                    <TableCell className={classes.tableCell}>Teacher</TableCell>
                    <TableCell className={classes.tableCell}>Timing</TableCell>
                    <TableCell className={classes.tableCell}>Date</TableCell>
                    <TableCell className={classes.tableCell}>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{rows()}</TableBody>
              </Table>
            </TableContainer>
            <div className={classes.centeredContainer}>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSaveClick(false)}
                  disabled={isScheduling}
                >
                  {isScheduling ? 'Please Wait' : 'Schedule Demo'}
                </Button>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      value={nurturing}
                      onChange={handleNurturingChange}
                    />
                  }
                  label="Send Nurturing Message"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSaveClick(true)}
                  disabled={isScheduling}
                >
                  {isScheduling ? 'Please Wait' : 'Book Mini Course'}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.headingContainer}>
            Currently, No Batches Selected
          </div>
        )}
      </div>
    </Dialog>
  )
}

CurrentSelection.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleOnSaveClick: PropTypes.func.isRequired
}

export default CurrentSelection
