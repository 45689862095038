import Input from '@material-ui/core/Input'
import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/components/generic/Button'

const FileUpload = (props) => {
  const { label, onUpload, name, inputProps = {} } = props

  return (
    <Button component="label">
      {label}
      <Input
        inputProps={inputProps}
        name={name}
        type="file"
        style={{ display: 'none' }}
        onChange={onUpload}
        key={Date.now()} // to reset component, upload same file again after failed
      />
    </Button>
  )
}

FileUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  inputProps: PropTypes.object
}

export default FileUpload
