export const scheduleColors = [
  '#95C48C', //green
  '#E87D7C', //red
  '#58B7E6', //blue
  '#F3C520', //yellow
  '#58B7E6', //blue
  '#F3C520', //yellow
  '#95C48C', //green
  '#E87D7C', //red
  '#F3C520', //yellow
  '#95C48C', //green
  '#E87D7C', //red
  '#58B7E6', //blue
  '#F3C520', //yellow
  '#58B7E6', //blue
  '#E87D7C' //red
]

export const subjectsList = [
  { name: 'art', image: require('assets/images/art.svg') },
  { name: 'dance', image: require('assets/images/dance.svg') },
  { name: 'karate', image: require('assets/images/karate.svg') },
  { name: 'vocals', image: require('assets/images/vocals.svg') },
  { name: 'commerce', image: require('assets/images/commerce.svg') },
  { name: 'science', image: require('assets/images/science.svg') },
  { name: 'fitness', image: require('assets/images/fitness.svg') },
  { name: 'theatre', image: require('assets/images/theatre.svg') }
]

export const batchTypesColorList = {
  class: '#ef5c34',
  event: '#58B7E6',
  mock: '#F3C520',
  explore: '#95C48C',
  membership: '#ef5c34'
}
