import { ContactSupportOutlined } from '@material-ui/icons'
import Dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api/common'
import {
  commonAttributes,
  invoiceTypes,
  navigationPaths,
  tableColumnDefaultOption,
  reasons
} from 'app/config'
import { validateNotEmpty } from 'app/validators'

export default {
  studentName: {
    name: 'student_name',
    label: 'Student Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_name'
    }
  },
  studentNumber: {
    name: 'student_phone_number',
    label: 'Student Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_phone_number'
    }
  },
  parentName: {
    name: 'parent.user',
    label: 'Parent Name',
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        return value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}
            >{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null
      },
      filterKey: 'parent_name'
    }
  },
  parentNumber: {
    name: 'parent_phone_number',
    label: 'Parent Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_phone_number'
    }
  },
  centre: {
    ...commonAttributes.centre,
    name: 'enrolment.batch.centre.name',
    label: 'Centre',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre'
    }
  },
  startDate: {
    ...commonAttributes.date,
    name: 'start_date',
    label: 'Start Date'
  },
  endDate: {
    ...commonAttributes.date,
    name: 'end_date',
    label: 'End Date'
  },
  dueDate: {
    ...commonAttributes.date,
    name: 'invoice_date',
    label: 'Due Date',
    options: {
      ...commonAttributes.date.options,
      filterKey: 'invoice_date'
    }
  },
  plan: {
    name: 'subscription.plan',
    label: 'Plan',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'current_plan',
      customBodyRender: (value) => {
        return value ? <span>{value?.name}</span> : null
      }
    }
  },
  teacher: {
    name: 'teacher',
    label: 'Teacher',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher'
    }
  },
  batchTimings: {
    name: 'timing',
    label: 'Batch Timings',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'timing',
      disableFilter: true,
      customBodyRender: (values) => (
        <span>
          {Array.isArray(values)
            ? values
                .map(
                  (item) =>
                    `${Dayjs()
                      .day(item?.weekday + 1)
                      .format('ddd')}, ${item?.start_time?.replace(
                      '-',
                      ':'
                    )} - ${item?.end_time?.replace('-', ':')}`
                )
                .join(' | ')
            : null}
        </span>
      )
    }
  },
  subjects: {
    ...commonAttributes.subject,
    options: {
      ...commonAttributes.subject.options,
      filterKey: 'subject'
    }
  },
  invoiceAmount: {
    name: 'amount',
    label: 'Invoice Amount',
    options: {
      ...commonAttributes.amount.options,
      filterKey: 'amount'
    }
  },
  invoiceType: {
    name: 'type',
    label: 'Invoice Type',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: invoiceTypes,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'type'
    }
  },
  coinsCredited: {
    name: 'topup.coins_credited',
    label: 'Coins credited',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  discount: {
    name: 'topup.discount',
    label: 'Discount',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  invoiceGeneratedBy: {
    name: 'author',
    label: 'Sales SPOC',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'author',
      customBodyRender: (value) =>
        value ? <span>{`${value.first_name} ${value.last_name}`}</span> : null
    }
  },
  collectedBy: {
    name: 'collected_by',
    label: 'Collected by',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'collected_by'
    }
  },
  reason: {
    name: 'topup.reason',
    label: 'Reason',
    widget: 'autocomplete',
    widgetFilter: true,
    validate: validateNotEmpty,
    optional: true,
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item,
          name: item
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (data && 'results' in data) {
        return data.results[0].value.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return []
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.results.map((item) => getItemStructure(item))
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'reason',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=RECHARGE_REASON`
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'reason'
    }
  },
  link: {
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) =>
        value ? (
          <a target="_blank" rel="noopener noreferrer" href={value}>
            {value}
          </a>
        ) : null
    }
  },
  mode: {
    name: 'mode',
    label: 'Payment Method',
    options: {
      ...commonAttributes.options,
      filterKey: 'mode'
    }
  },
  notes: {
    name: 'notes',
    label: 'Notes',
    options: {
      ...commonAttributes.options,
      filterKey: 'mode',
      customBodyRender: (value) => (value ? <span>{''}</span> : null)
    }
  },
  firstTimeOrRepeat: {
    name: 'first_time_or_repeat',
    label: 'New / Repeat',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: ['new', 'repeat'],
    options: {
      ...tableColumnDefaultOption.options
    }
  }
}
