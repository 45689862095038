import Switch from '@material-ui/core/Switch'
import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import {
  fetchMockPricingData,
  updateMockPricingData
} from 'app/store/actions/mockTestEntity'

import { columns } from './config'

const Pricing = () => {
  const pricingData = useSelector(
    (state) => state.mockTestEntityReducer.pricingData
  )

  return (
    <BaseEntity
      entity={constants.PRICING}
      label={constants.PRICING_LABEL}
      columns={columns}
      data={pricingData}
      fetchData={fetchMockPricingData}
      updateEntityData={updateMockPricingData}
    />
  )
}

export default Pricing
