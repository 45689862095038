import { put, takeLatest } from 'redux-saga/effects'

import { clearStorage, redirectToOrigin } from 'app/helpers'

import { logoutSuccess } from '../actions/auth'
import * as types from '../types/auth'

export function* handleLogout() {
  try {
    clearStorage()
    yield put(logoutSuccess())
    redirectToOrigin()
  } catch (error) {
    console.log(error)
  }
}

export function* watchHandleLogout() {
  yield takeLatest(types.LOGOUT_START, handleLogout)
}
