import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTribesForClub } from 'api/tribe'
import { convertRegistration } from 'api/waitlist'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import { eventEmitter, showToast } from 'app/helpers'
import {
  fetchWaitlistQueuedData,
  updateCommunityData
} from 'app/store/actions/communityEntity'

import { columns } from './config'
import QueuedActionForm from './QueuedActionForm'

const Queued = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [editIndex, setEditIndex] = React.useState(null)
  const [tribes, setTribes] = React.useState([])
  const dispatch = useDispatch()

  const waitlistQueuedData = useSelector(
    (state) => state.communityEntityReducer.waitlistQueuedData
  )

  const openModal = React.useCallback((dataIndex = 0) => {
    setIsModalOpen(true)
    setEditIndex(dataIndex)
  }, [])

  const chooseTribe = React.useCallback(
    async (dataIndex) => {
      const { club_details } = waitlistQueuedData.data[dataIndex]
      fetchTribesForClub(club_details.uuid).then((response) => {
        setTribes(response.results)
        openModal(dataIndex)
      })

      // try {
      //   confirm(() => {
      //     convertWaitlistToUUID(uuid).then((data) => {
      //       if (data.success) {
      //
      //       }
      //     })
      //   }, 'Convert this registration to tribe selection first?')
      // } catch (error) {
      //   console.log(error)
      //   showToast(constants.ERROR_MESSAGE, constants.ERROR)
      // }
    },
    [waitlistQueuedData, tribes, openModal]
  )

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onSaveClick = async (newData) => {
    try {
      const { uuid } = waitlistQueuedData.data[editIndex]
      await convertRegistration(newData.tribe_uuid, uuid)
      eventEmitter.emit(constants.CLOSE_MODAL_FORM)
      showToast('Assinged Tribe Successfully', constants.SUCCESS)
      dispatch(fetchWaitlistQueuedData({}, constants.WAITLIST_QUEUED))
      closeModal()
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      showToast(error.message, constants.ERROR)
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'is_paid',
        label: 'Is Paid',
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const queuedData = waitlistQueuedData?.data[dataIndex]
            return queuedData.is_paid ||
              queuedData.invoices[0].status === 'paid'
              ? 'paid'
              : 'due'
          }
        }
      },
      {
        name: '',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => chooseTribe(dataIndex)}
                >
                  Assign Tribe
                </Button>
              </ButtonGroup>
            )
          }
        }
      }
    ]
  }, [chooseTribe])

  return (
    <>
      <BaseEntity
        entity={constants.WAITLIST_QUEUED}
        label={constants.WAITLIST_QUEUED_LABEL}
        fetchData={fetchWaitlistQueuedData}
        data={waitlistQueuedData}
        columns={modifiedColumns}
        updateEntityData={updateCommunityData}
      />
      <QueuedActionForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        tribes={tribes}
      />
    </>
  )
}

export default Queued
