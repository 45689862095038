import {
  cityCodes,
  commonAttributes,
  tableColumnDefaultOption
} from 'app/config'
import {
  validateNotEmpty,
  validateURL,
  validatePostalCode,
  validatePhoneNumber
} from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Name',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'postal_code',
    label: 'Postal Code',
    validate: validatePostalCode,
    ...tableColumnDefaultOption
  },
  {
    name: 'postal_address',
    label: 'Postal Address',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'city',
    label: 'City',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: cityCodes,
    ...tableColumnDefaultOption
  },
  {
    name: 'gmap_link',
    label: 'Google Map Link',
    contentType: 'link',
    validate: validateURL,
    ...tableColumnDefaultOption
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
    validate: validatePhoneNumber,
    ...tableColumnDefaultOption
  }
]
