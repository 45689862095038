import { ContactSupport } from '@material-ui/icons'
import dayjs from 'dayjs'

import {
  fetchAbilitiesProgressData,
  fetchJourneyReportsData
} from 'api/journey'
import { SUBSCRIPTION_STATUSES } from 'app/constants'

import {
  ReportStatus,
  ReportStatusIcon,
  ProgressDetails,
  AbilitiesStatus,
  JourneyReportStatus,
  JourneyCardStatusDetails,
  CardStatus
} from './constants'

export const journeyDateSort = (a, b) => {
  const dateA = new Date(a?.year, a?.month - 1)
  const dateB = new Date(b?.year, b?.month - 1)
  return dateB - dateA
}

const filterFullyInactiveReports = (results) => {
  let reportsData = []
  let startMonth = null
  let startYear = null
  let endMonth = null
  let endYear = null

  for (let i = 0; i < results?.length; i++) {
    //LOGIC: for a card to be fully inactive (that we are checking here), the status has to be inactive and total_booked_sessions should be zero

    const currentObject = results[i]
    const total_sessions = currentObject?.total_sessions
    const status = currentObject?.status

    //if its not fully inactive then push the current object directly to the array
    if (status !== 'inactive' || total_sessions > 0) {
      reportsData.push(currentObject)
      continue
    }

    //if its fully inactive and the start of consective fully inactive cards
    if (
      i === 0 ||
      results[i - 1].status !== 'inactive' ||
      results[i - 1]?.total_sessions > 0
    ) {
      endMonth = currentObject.month
      endYear = currentObject.year
    }

    //if its fully inactive and the end of consective fully inactive cards
    if (
      i === results?.length - 1 ||
      results[i + 1].status !== 'inactive' ||
      results[i + 1]?.total_sessions > 0
    ) {
      startMonth = currentObject.month
      startYear = currentObject.year

      const object = {
        start_month: startMonth,
        start_year: startYear,
        end_month: endMonth,
        end_year: endYear,
        show_end_month: true,
        month: startMonth,
        year: startYear,
        card_status: 'fully_inactive',
        parent_viewed: currentObject?.parent_viewed,
        status: currentObject?.status,
        total_sessions: total_sessions,
        total_regular_sessions: currentObject?.total_regular_sessions,
        total_makeup_sessions: currentObject?.total_makeup_sessions,
        regular_attended: currentObject?.regular_attended,
        makeup_attended: currentObject?.makeup_attended,
        journey: currentObject?.journey,
        pre_progress_snapshot: currentObject?.pre_progress_snapshot
      }
      reportsData.push(object)
    }
  }
  return reportsData
}

const transformMainCardData = async (results) => {
  const transformedData = results.map((item) => {
    const isDiscoveryReport =
      item?.post_progress_snapshot?.current_subscription_status ===
        SUBSCRIPTION_STATUSES.DISCOVERING ||
      item?.post_progress_snapshot?.current_subscription_status ===
        SUBSCRIPTION_STATUSES.DISCOVERED
    const discoverySuccessful =
      item?.post_progress_snapshot?.is_discovery_successful === true &&
      isDiscoveryReport
    const discoveryUnsuccessful =
      item?.post_progress_snapshot?.is_discovery_successful === false &&
      isDiscoveryReport
    let obj = null

    if (isDiscoveryReport) {
      if (discoveryUnsuccessful) {
        obj = {
          ...JourneyCardStatusDetails.INACTIVE,
          text: null,
          status: CardStatus.DISCOVERY_FAIL,
          smallCard: false
        }
      } else if (discoverySuccessful) {
        obj = {
          ...JourneyCardStatusDetails.ACTIVE,
          text: null,
          status: CardStatus.DISCOVERY_PASS
        }
      }
      obj = { ...obj, collapsedCard: true }
    }
    //for yellow cards
    else if (item?.status === JourneyReportStatus.PENDING) {
      obj = {
        ...JourneyCardStatusDetails.PENDING
      }
    }
    //for red cards where there have been zero bookings
    else if (item?.card_status && item?.card_status === 'fully_inactive') {
      const monthsDifference =
        item?.end_month -
        item?.start_month +
        12 * (item?.end_year - item?.start_year) +
        1

      //for grouped cards
      if (monthsDifference > 1) {
        const startMonth = getMonthName(item?.start_month)
        const endMonth = getMonthName(item?.end_month)
        obj = {
          ...JourneyCardStatusDetails.INACTIVE,
          text: `The student was inactive for ${monthsDifference} months (from ${startMonth} ${item?.start_year} to ${endMonth} ${item?.end_year}).`,
          showEndMonth: true
        }
      }
      //for individual cards
      else if (monthsDifference === 1) {
        obj = {
          ...JourneyCardStatusDetails.INACTIVE,
          text: 'The student was inactive for the month.'
        }
      }
    }
    //for red cards where there have been bookings
    else if (
      item?.status === JourneyReportStatus.INACTIVE &&
      item?.total_sessions > 0
    ) {
      obj = {
        ...JourneyCardStatusDetails.INACTIVE,
        text: 'As the student was not active during the time of progress marking, we were not able to share the report for the month.'
      }
    } else {
      obj = { ...JourneyCardStatusDetails.ACTIVE }
    }

    item['card_details'] = obj
    return item
  })

  return transformedData
}

export const getJourneyReportsData = async (journey_uuid) => {
  const data = await fetchJourneyReportsData({
    queries: { journey: journey_uuid }
  })
  const results = data?.results.sort(journeyDateSort)
  const filteredResults = filterFullyInactiveReports(results)
  const transformedData = transformMainCardData(filteredResults)
  return transformedData
}

export const getMonthName = (monthNumber, full_name) => {
  const date = dayjs().month(monthNumber - 1)

  if (full_name) return date.format('MMMM')
  else return date.format('MMM').toLowerCase()
}

export const getMilestone = (data) => {
  const currentMilestoneName = data?.milestone?.name || ''
  const currentMilestoneSequence = 'M' + (data?.milestone?.sequence || '')
  return data?.milestone
    ? currentMilestoneSequence + ' ' + currentMilestoneName
    : 'N/A'
}

export const getLevel = (data) => {
  const currentLevelName = data?.level?.name || ''
  const currentLevelSequence = 'L' + (data?.level?.sequence || '')
  return data?.level ? currentLevelSequence + ' ' + currentLevelName : 'N/A'
}

export const getReportStatusText = (data) => {
  const parentViewed = data?.parent_viewed
  const status = data?.status

  if (parentViewed) return ReportStatus['viewed']
  return ReportStatus[status]
}

export const getReportStatusIcon = (data) => {
  const parentViewed = data?.parent_viewed
  const status = data?.status

  if (parentViewed) return ReportStatusIcon['viewed']
  return ReportStatusIcon[status]
}

export const getProgressDetails = (data) => {
  let title = ''
  let show_difference = false
  let difference_before = ''
  let difference_after = ''
  let difference_color = ''
  let title_icon = ''

  //check level upgrade
  if (
    data?.post_progress_snapshot &&
    data?.pre_progress_snapshot?.current_level?.uuid !==
      data?.post_progress_snapshot?.current_level?.uuid
  ) {
    title = 'Level Upgraded'
    show_difference = true
    difference_before = data?.pre_progress_snapshot?.current_level?.level?.name
    difference_after = data?.post_progress_snapshot?.current_level?.level?.name
    difference_color = '#F4E5D2'
    title_icon = ProgressDetails.LEVEL_UPGRADED
  }
  //check milestone upgrade
  else if (
    data?.post_progress_snapshot &&
    data?.pre_progress_snapshot?.current_milestone?.uuid !==
      data?.post_progress_snapshot?.current_milestone?.uuid
  ) {
    title = 'Milestone Upgraded'
    show_difference = true
    difference_before = getMilestone(
      data?.pre_progress_snapshot?.current_milestone
    )
    difference_after = getMilestone(
      data?.post_progress_snapshot?.current_milestone
    )
    difference_color = '#FDF3D2'
    title_icon = ProgressDetails.MILESTONE_UPGRADED
  } else if (
    data?.pre_progress?.current_level?.uuid ===
      data?.post_progress?.current_level?.uuid &&
    data?.post_progress_snapshot?.current_level?.total_milestones ===
      data?.post_progress_snapshot?.current_level?.completed_milestones &&
    data?.post_progress_snapshot?.current_level?.goal_status === 'pending'
  ) {
    title = 'Preparing for level goal'
    show_difference = false
    title_icon = ProgressDetails.PREPARING_FOR_LEVEL_GOAL
  } else {
    title = `${data?.abilities_progressed} abilities progressed`
    show_difference = false
    title_icon = ProgressDetails.ABILITIES_PROGRESSED
  }

  return {
    progress_title: title,
    show_progress_difference: show_difference,
    progress_difference_before: difference_before,
    progress_difference_after: difference_after,
    progress_difference_color: difference_color,
    progress_title_icon: title_icon
  }
}

export const getAbilitiesData = (data) => {
  const currentMilestoneData = data?.pre_progress_snapshot
  const currentMilestoneUuid = currentMilestoneData?.current_milestone?.uuid
  const levels = data?.post_progress_snapshot?.levels

  for (let i = 0; i < levels?.length; i++) {
    const milestones = levels[i]?.milestones

    // Traverse milestones array
    for (let j = 0; j < milestones?.length; j++) {
      if (milestones[j]?.uuid === currentMilestoneUuid) {
        const abilities = milestones[j]?.abilities

        const abilitiesStatusCount = [
          {
            ability_status: 'Consistenly showing',
            count: abilities?.filter(
              (item) => item?.status === 'consistently showing'
            )?.length,
            icon_url: AbilitiesStatus.CONSISTENTLY_SHOWING
          },
          {
            ability_status: 'Showing',
            count: abilities?.filter((item) => item?.status === 'showing')
              ?.length,
            icon_url: AbilitiesStatus.SHOWING
          },
          {
            ability_status: 'Not started',
            count: abilities?.filter((item) => item?.status === 'not started')
              ?.length,
            icon_url: AbilitiesStatus.NOT_SHOWING
          }
        ]

        return abilitiesStatusCount
      }
    }
  }
}

export const getAbilitiesProgressData = async (report_uuid) => {
  const data = await fetchAbilitiesProgressData({
    queries: { report_id: report_uuid }
  })

  const results = data?.results?.map((item) => ({
    old_status: item?.old_status,
    new_status:
      item?.new_status === item?.old_status ? 'no change' : item?.new_status,
    description: item?.journey_ability?.ability?.description,
    month: getMonthName(item?.report?.month, true),
    year: item?.report?.year,
    level: getLevel(item?.report?.pre_progress_snapshot?.current_level),
    milestone: getMilestone(
      item?.report?.pre_progress_snapshot?.current_milestone
    ),
    course: item?.report?.pre_progress_snapshot?.subject?.name
  }))

  return results
}

//formats dates to 21 July 2024 (DD MMMM YYYY)
export const formatDateToLong = (date) => {
  if (!date) return null

  return dayjs(date)?.format('DD MMMM YYYY') || ''
}

//formats dates to 21 July (DD MMMM)
export const formatDateToMedium = (date) => {
  if (!date) return null

  return dayjs(date)?.format('DD MMMM') || ''
}

export const formatMakeupDatesList = (dates) => {
  const formattedDates = dates.map((date) => formatDateToMedium(date))

  if (formattedDates.length === 0) return ''
  if (formattedDates.length === 1) return formattedDates[0]
  if (formattedDates.length === 2)
    return `${formattedDates[0]} and ${formattedDates[1]}`

  const allButLast = formattedDates.slice(0, -1).join(', ')
  const last = formattedDates[formattedDates.length - 1]

  return `${allButLast}, and ${last}`
}
