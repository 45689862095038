import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import React from 'react'

const defaultToolbarStyles = {
  iconButton: {},
  minWidth: 48
}

const EditAction = (props) => {
  const handleClick = () => {
    if (props.onEditClick) {
      props.onEditClick()
    }
  }

  return (
    <React.Fragment>
      <Tooltip title={'Edit'}>
        <IconButton
          onClick={handleClick}
          className="table-edit-icon"
          {...(props.id ? { id: `edit-button-id-${props.id}` } : null)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

EditAction.propTypes = {
  id: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired
}

export default withStyles(defaultToolbarStyles, { name: 'EditAction' })(
  EditAction
)
