import styled, { css } from 'styled-components'

const breakpoint = '1024px'
const shadow = '10px 10px 32px -8px rgb(0 0 0 / 20%)'

export const Wrapper = styled.div`
  width: 264px;
  height: 226px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  transition: all 0.5s ease;
  box-shadow: ${shadow};
  background: #fff;
  gap: 16px;

  &:hover {
    box-shadow: unset;
  }

  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid #ef5c34;
    `}
`

export const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

export const Content = styled.div`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: lowercase;
  ${(props) =>
    props.color &&
    props.plan &&
    css`
      font-family: AvenirDemi;
      font-size: 40px;
      color: ${props.color};
    `}

  ${(props) =>
    props.subheading &&
    props.type &&
    css`
      font-family: AvenirMedium;
      font-size: 14px;
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : 'var(--oh-black-primary)'};
    `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CardName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Icon = styled.img`
  height: 16px;
  width: 16px;
`

export const Heading = styled.div`
  font-family: AvenirMedium;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
`

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Coins = styled.div`
  font-size: 16px;
  font-family: AvenirMedium;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
