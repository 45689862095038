import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { getCancelReasons } from 'api'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

import CancelForm from './CancelForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const CancelModal = (props) => {
  const classes = useStyles()
  const formRef = React.useRef(null)

  const [formData, setFormData] = React.useState({
    reason: '',
    notes: '',
    reason_for_using_alfred: ''
  })

  const [reasons, setReasons] = React.useState([])

  const { isModalOpen, closeModal, onSaveClick, action } = props

  useEffect(() => {
    ;(async () => {
      try {
        const reasonList = await getCancelReasons()
        if (Array.isArray(reasonList)) {
          reasonList.push('Other')
          setReasons(reasonList)
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  const handleOnSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current
      try {
        const errors = await validateForm(values)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        } else {
          // form is valid
          try {
            await onSaveClick(values, action)
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [onSaveClick, action])

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}>
        {({}) => {
          return (
            <Form>
              <FormControl className={classes.formControl} fullWidth>
                {action === 'cancel_sessions' ? (
                  <CancelForm formData={formData} reasons={reasons} />
                ) : null}
              </FormControl>
            </Form>
          )
        }}
      </Formik>
    )
  }, [classes, action, formData, reasons])

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}>
      {formElement}
    </ModalForm>
  )
}

export default CancelModal
