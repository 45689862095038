import { isEmpty } from 'lodash'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const eventRequestStructure = (data) => ({
  name: data.name,
  organiser: data.organiser,
  date: data.date,
  start_time: data.start_time,
  end_time: data.end_time,
  poster: data.poster,
  meeting_link: data.meeting_link,
  must_attend: data.must_attend,
  status: data.status,
  number_of_seats: data.number_of_seats,
  batches: data.batches,
  teachers: data.teachers,
  standards: data.standards,
  public: data.public,
  recent_enrollments_only: data.recent_enrollments_only,
  description: data.description,
  outcome: data.outcome,
  host_first_name: data.host_first_name,
  host_last_name: data.host_last_name,
  host_details: data.host_details,
  illustration: data.illustration,
  feedback_url: data.feedback_url
})

const eventRegistrationRequestStructure = (data) => ({
  event: data.event,
  student: data.student,
  attendance: data.attendance,
  school: data.school,
  standard: data.standard,
  class_enrolled: data.class_enrolled,
  club_enrolled: data.club_enrolled,
  num_registered_events: data.num_registered_events
})

const fetchEvent = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.event}/event/alfred`, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching event :- ', error.message)
    throw error
  }
}

const createEvent = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.event}/event`,
      eventRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating event :- ', error.message)
    throw error
  }
}

const updateEvent = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.event}/event/${data.uuid}/`,
      eventRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in updating event :- ', error.message)
    throw error
  }
}

const deleteEvent = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.event}/event/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting event :- ', error.message)
    throw error
  }
}

const fetchEventRegistration = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.event}/event_registration/alfred`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching event :- ', error.message)
    throw error
  }
}

const createEventRegistration = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.event}/event_registration`,
      eventRegistrationRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating event registration :- ', error.message)
    throw error
  }
}

const updateEventRegistration = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.event}/event_registration/${data.uuid}/`,
      eventRegistrationRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in updating event registration :- ', error.message)
    throw error
  }
}

const deleteEventRegistration = async (ids) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.event}/event_registration/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting event registration :- ', error.message)
    throw error
  }
}

export {
  fetchEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  fetchEventRegistration,
  createEventRegistration,
  updateEventRegistration,
  deleteEventRegistration
}
