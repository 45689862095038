import {
  commonStructure,
  handleEntitiesUpdate,
  dataResponseHandler,
  dataFetchingHandler
} from './reducer.helper'

import * as types from '../types/userEntity'

const initialState = {
  studentData: commonStructure(),
  teacherData: commonStructure(),
  adminData: commonStructure(),
  parentData: commonStructure(),
  superproviderData: commonStructure()
}

const userEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_STUDENT_DATA:
    case types.FETCH_TEACHER_DATA:
    case types.FETCH_ADMIN_DATA:
    case types.FETCH_PARENT_DATA:
    case types.FETCH_SUPERPROVIDER_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_STUDENT_DATA_SUCCESS:
    case types.FETCH_TEACHER_DATA_SUCCESS:
    case types.FETCH_ADMIN_DATA_SUCCESS:
    case types.FETCH_PARENT_DATA_SUCCESS:
    case types.FETCH_SUPERPROVIDER_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    case types.UPDATE_USER_DATA_SUCCESS:
      return handleEntitiesUpdate(state, action)

    default:
      return state
  }
}

export { userEntityReducer }
