import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
  createTheme
} from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'

import { createMockTestReportCard } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import FileUpload from 'app/components/generic/FileUpload'
import { Loading } from 'app/components/generic/LottieComponents'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { fetchMockTestReportCardData } from 'app/store/actions/mockTestEntity'

import { columns } from './config'
import {
  LoadingView,
  LoadingViewText,
  ErrorModalTitle,
  StatusIcon
} from './styles'

const failedIcon = require('assets/images/failed.png')
const pendingIcon = require('assets/images/pending.png')
const processingIcon = require('assets/images/processing.gif')
const successIcon = require('assets/images/success.png')

const theme = createTheme({
  overrides: {
    Dialog: {
      backgroundColor: 'red'
    }
  }
})

export const TextOnlyTooltip = withStyles({
  tooltip: {
    color: 'while',
    fontSize: '14px',
    backgroundColor: 'black',
    padding: '10px',
    whiteSpace: 'pre'
  }
})(Tooltip)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}))

const MockTestReportCard = () => {
  const classes = useStyles()
  const reportCardData = useSelector(
    (state) => state.mockTestEntityReducer.mockTestReportCard
  )

  const [modalOpen, setModalOpen] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const dispatch = useDispatch()

  const onModalClose = () => {
    setErrors('')
    setModalOpen(false)
  }

  const download = React.useCallback(
    (dataIndex) => {
      const { pdf_url } = reportCardData.data[dataIndex]
      window.open(pdf_url)
    },
    [reportCardData]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'pdf_url',
        isAction: true,
        disabledInForm: true,
        options: {
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const { pdf_url } = reportCardData.data[dataIndex]
            return pdf_url ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => download(dataIndex)}>
                preview
              </Button>
            ) : null
          }
        }
      },
      {
        name: 'task_result',
        label: 'Status',
        isAction: true,
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { task_result } = reportCardData.data[dataIndex]
            let tooltip = ''
            if (task_result?.status === 'STARTED') {
              tooltip =
                'Task is under process. Please refresh to see latest update.'
            } else if (task_result?.status == 'PENDING') {
              tooltip =
                'Task has not been picked up yet. Please refresh to see latest update.'
            } else if (task_result?.status == 'SUCCESS') {
              tooltip = 'Task has been executed successfully.'
            } else {
              tooltip = `${task_result?.result} for celery task ${task_result?.celery_task_id}\n Stack Trace:\n ${task_result?.traceback}\n`
            }
            return task_result ? (
              <TextOnlyTooltip
                title={tooltip}
                aria-label={tooltip}
                arrow
                interactive>
                <StatusIcon
                  src={
                    task_result?.status === 'SUCCESS'
                      ? successIcon
                      : task_result?.status === 'STARTED'
                      ? processingIcon
                      : task_result?.status === 'PENDING'
                      ? pendingIcon
                      : failedIcon
                  }></StatusIcon>
              </TextOnlyTooltip>
            ) : null
          }
        }
      }
    ]
  }, [download])

  const sendReportGeneratedNotification = () => {
    Swal.fire({
      title: '',
      text: 'reports is being generated. Please see status for update!',
      confirmButtonText: 'ok',
      icon: 'success',
      padding: 30,
      borderRadius: 300
    })
  }

  const onUpload = async (e) => {
    try {
      setModalOpen(true)
      const res = await createMockTestReportCard(e.target.files[0])
      if (res.success) {
        setModalOpen(false)
        sendReportGeneratedNotification()
        dispatch(
          fetchMockTestReportCardData({}, constants.MOCK_TEST_REPORT_CARD)
        )
      }
    } catch (e) {
      let _error = (
        <div className={classes.root}>
          {e.message.split('@').map((err, _index) => (
            <Alert severity="error" key={_index * 10}>
              <b>Error Found:</b> {err}
            </Alert>
          ))}
        </div>
      )
      setErrors(_error)
      setModalOpen(true)
    }
  }

  const CustomToolbarElement = (
    <FileUpload
      label="upload & share"
      onUpload={onUpload}
      inputProps={{ accept: '.csv' }}
    />
  )

  const loadingView = (
    <LoadingView>
      <Loading height={200} width={200} />
      <LoadingViewText>
        {' '}
        please wait while we generate your reports
      </LoadingViewText>
    </LoadingView>
  )

  return (
    <>
      <BaseEntity
        entity={constants.MOCK_TEST_REPORT_CARD}
        label={constants.MOCK_TEST_REPORT_CARD_LABEL}
        fetchData={fetchMockTestReportCardData}
        data={reportCardData}
        columns={modifiedColumns}
        readOnly
        CustomToolbarElement={CustomToolbarElement}
      />
      <ThemeProvider theme={theme}>
        <ModalForm
          disableBackdropClick={!errors}
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={modalOpen}
          onModalClose={onModalClose}
          enableSave={false}
          modalTitle={
            errors ? <ErrorModalTitle>Found Errors!</ErrorModalTitle> : ''
          }>
          {errors || loadingView}
        </ModalForm>
      </ThemeProvider>
    </>
  )
}

export default MockTestReportCard
