import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React from 'react'

import { TextField } from 'app/components/generic/FormElements'
import { validateNotEmpty } from 'app/validators'
const ReasonSubmitForm = (props) => {
  const { errors, setFieldValue } = useFormikContext()

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.deletion_reason) ? { error: true } : null)}
        required
      >
        <TextField
          name={'deletion_reason'}
          label={'Deletion Reason'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
        />
        {!isEmpty(errors.deletion_reason) && (
          <FormHelperText>{errors.deletion_reason}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default ReasonSubmitForm
