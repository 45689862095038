import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export const EnrolmentsTable = (props) => {
  const { data } = props

  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}
        >
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Standard</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Parent name</TableCell>
            <TableCell>Parent number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (enrolment, index) =>
              enrolment && (
                <TableRow key={index}>
                  <TableCell>{enrolment?.name}</TableCell>
                  <TableCell>{enrolment?.standard}</TableCell>
                  <TableCell>{enrolment?.start_date}</TableCell>
                  <TableCell>{enrolment?.end_date}</TableCell>
                  <TableCell>{enrolment?.status}</TableCell>
                  <TableCell>{enrolment?.parent_name}</TableCell>
                  <TableCell>{enrolment?.parent_number}</TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

EnrolmentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
