import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { debounceWaitTime } from 'app/pages/Communication/Chat/constants'
import { makeStyles } from '@material-ui/core'
import { TextField, FormControl, FormHelperText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  searchComponent: {
    height: '36px',
  },
  textField: {
    height: '100%',
    fontFamily: 'Avenir',
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--oh-orange)',
      },
    },
  },
  errorMessage: {
    color: 'red',
    fontSize: '10px',
  },
}))

const SearchComponent = ({ label, initialQueries, fetchDataAction, constants, onSearchChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [error, setError] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const fetchSearchResults = async (searchText, initialQueries) => {
    // updateLoadingValue(true)
    setError('')
    try {
      // await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch(fetchDataAction({ queries: { ...initialQueries, search: searchText } }, false, false, constants))
    } catch (error) {
      console.error('An error occured while fetching data', error)
      setError('An error occurred while fetching data')
    }
  }

  const debouncedFetchData = React.useCallback(
    debounce((searchValue) => {
      fetchSearchResults(searchValue, initialQueries)
    }, debounceWaitTime),
    [debounceWaitTime, initialQueries]
  )

  const handleSearchChange = (event) => {
    const newSearchText = event.target.value
    setSearchText(newSearchText)

    // pass the search value to the parent component
    if (onSearchChange) {
      onSearchChange(newSearchText)
    }

    debouncedFetchData(newSearchText)
  }

  return (
    <FormControl className={classes.searchComponent} variant="outlined">
      <TextField
        value={searchText}
        onChange={handleSearchChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={label}
        variant="outlined"
        className={`${classes.textField} ${isFocused ? 'focused' : ''}`}
      />
      {error && (
        <FormHelperText className={classes.errorMessage}>{error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default SearchComponent