import {
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import React from 'react'

import { getRefundAmount } from 'api'
import {
  RadioGroup,
  Select,
  TextField
} from 'app/components/generic/FormElements'
import { cancelActions, getWidgetTypeName } from 'app/config'
import { validator, validateFee, validateNotEmpty } from 'app/validators'

const CancelForm = (props) => {
  const { formData } = props
  const { errors, values, setFieldValue } = useFormikContext()

  const radioButtons = [
    {
      value: 'true',
      control: <Radio />,
      label: 'Yes'
    },
    {
      value: 'false',
      control: <Radio />,
      label: 'No'
    }
  ]

  React.useEffect(() => {
    if (formData?.remaining_coins) {
      setFieldValue('remaining_coins', formData?.remaining_coins)
    }
  }, [formData?.remaining_coins])

  // React.useEffect(() => {
  //   ;(async () => {
  //     if (values?.remaining_coins >= 0) {
  //       const amount = await getRefundAmount({
  //         remaining_coins: values?.remaining_coins,
  //         uuid: formData.uuid
  //       })
  //       setFieldValue('new_balance', amount.refund_amount)
  //     } else {
  //       setFieldValue('new_balance', 0)
  //     }
  //   })()
  // }, [values?.remaining_coins])

  const validateCoins =
    ({ isRequired } = {}) =>
    (value) => {
      return validator(isRequired, value, () => {
        if (!isNil(value) && typeof value !== 'string') {
          value = value.toString()
        }
        if (
          typeof value === 'string' &&
          (isEmpty(value) || value === '<p></p>/n')
        ) {
          return 'Please enter a valid amount'
        }
      })
    }

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.cancel_type) ? { error: true } : null)}
        required
      >
        <InputLabel>{'Cancel type'}</InputLabel>
        <Select
          name={'cancel_type'}
          defaultValue={formData.cancel_type || ''}
          label={'Payment Mode'}
          validate={validateNotEmpty({ isRequired: true })}
        >
          {Object.keys(cancelActions).map((key, index) => (
            <MenuItem key={index} value={key}>
              {cancelActions[key]}
            </MenuItem>
          ))}
        </Select>
        {!isEmpty(errors.cancel_type) && (
          <FormHelperText>{errors.cancel_type}</FormHelperText>
        )}
      </FormControl>
      {values?.cancel_type === 'pause' && (
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.follow_up_date) ? { error: true } : null)}
          required
        >
          <TextField
            name={'follow_up_date'}
            label={`Follow up Date`}
            type={getWidgetTypeName('datepicker')}
            variant="outlined"
            required
            validate={validateNotEmpty({ isRequired: true })}
            InputLabelProps={{ shrink: true }}
          />
          {!isEmpty(errors.follow_up_date) && (
            <FormHelperText>{errors.follow_up_date}</FormHelperText>
          )}
        </FormControl>
      )}
      {/* <FormControl
        variant="outlined"
        {...(!isEmpty(errors.new_balance) ? { error: true } : null)}
        required
      >
        <TextField
          name={'new_balance'}
          label={'Refund amount (Please make a note of this)'}
          variant="outlined"
          required
          disabled
          validate={validateFee({ isRequired: true })}
        />
        {!isEmpty(errors.new_balance) && (
          <FormHelperText>{errors.new_balance}</FormHelperText>
        )}
      </FormControl> */}
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.reason) ? { error: true } : null)}
        required
      >
        <TextField
          name={'reason'}
          label={'Reason'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
        />
        {!isEmpty(errors.reason) && (
          <FormHelperText>{errors.reason}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.parent_name) ? { error: true } : null)}
        required
      >
        <TextField
          name={'parent_name'}
          label={'Parent Name'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.parent_name) && (
          <FormHelperText>{errors.parent_name}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.parent_number) ? { error: true } : null)}
        required
      >
        <TextField
          name={'parent_number'}
          label={'Parent Number'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.parent_number) && (
          <FormHelperText>{errors.parent_number}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.remaining_coins) ? { error: true } : null)}
        required
      >
        <TextField
          name={'remaining_coins'}
          label={
            'Remaining Coins (after coins reserved for future sessions are unblocked)'
          }
          disabled
          variant="outlined"
          required
          validate={validateCoins({ isRequired: true })}
        />
        {!isEmpty(errors.remaining_coins) && (
          <FormHelperText>{errors.remaining_coins}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.process_refund) ? { error: true } : null)}
        required
        // component="fieldset"
      >
        <FormLabel>Process Refund</FormLabel>
        <RadioGroup
          row
          aria-label={'process_refund'}
          name="process_refund"
          color="primary"
        >
          {radioButtons.map((radioButton, index) => (
            <FormControlLabel
              key={radioButton.label + index}
              {...radioButton}
            />
          ))}
        </RadioGroup>
        {!isEmpty(errors.process_refund) && (
          <FormHelperText>{errors.process_refund}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.zero_balance) ? { error: true } : null)}
        required
        // component="fieldset"
      >
        <FormLabel>Make wallet balance zero</FormLabel>
        <RadioGroup
          row
          aria-label={'zero_balance'}
          name="zero_balance"
          color="primary"
        >
          {radioButtons.map((radioButton, index) => (
            <FormControlLabel
              key={radioButton.label + index}
              {...radioButton}
            />
          ))}
        </RadioGroup>
        {!isEmpty(errors.zero_balance) && (
          <FormHelperText>{errors.zero_balance}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default CancelForm
