import constants from 'app/constants'
import { apiFormattedResult } from 'app/helpers'

const eventStateHandler = (state, action, stateName, id = 'uuid') => {
  if (action.event === constants.CREATED) {
    return {
      ...state,
      [stateName]: {
        ...state[stateName],
        data: [action.value, ...state[stateName].data]
      }
    }
  } else if (action.event === constants.UPDATED) {
    const itemIndex = state[stateName].data.findIndex(
      (item) => item[id] === action.value[id]
    )

    if (itemIndex > -1) {
      return {
        ...state,
        [stateName]: {
          ...state[stateName],
          data: [
            ...state[stateName].data.slice(0, itemIndex),
            action.value,
            ...state[stateName].data.slice(itemIndex + 1)
          ]
        }
      }
    }

    return state
  } else if (action.event === constants.REMOVED) {
    return {
      ...state,
      [stateName]: {
        ...state[stateName],
        data: [
          ...state[stateName].data.filter(
            (item) => !(action.value.indexOf(item[id]) > -1)
          )
        ],
        count: state[stateName].count - action.value.length
      }
    }
  }

  return state
}

export const dataResponseHandler = (state, stateName, action) => {
  return {
    ...state,
    [stateName]: {
      isLoading: false,
      isLoaded: true,
      isPaginating: false,
      isFiltering: false,
      ...(action.error
        ? { error: action.error }
        : apiFormattedResult(action.value))
    }
  }
}

export const dataFetchingHandler = (state, stateName, action) => {
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      isLoading: !action.isFiltering && !action.isPaginating,
      isLoaded: false,
      isPaginating: action.isPaginating,
      isFiltering: action.isFiltering,
      data:
        !action.isFiltering && !action.isPaginating
          ? []
          : state[stateName].data,
      error: null
    }
  }
}

export const handleEntitiesUpdate = (state, action) => {
  let stateName

  if (action.entity === 'school_attendance') {
    stateName = 'attendanceData'
  } else {
    stateName = `${action.entity}Data`
  }

  return eventStateHandler(state, action, stateName)
}

export const commonStructure = () => ({
  isLoading: false,
  isLoaded: false,
  isPaginating: false,
  isFiltering: false,
  data: [],
  error: null
})
