import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ModalForm from 'app/components/ModalForm'
import { tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import { showToast } from 'app/helpers'
import { fetchFutureAttendanceData } from 'app/store/actions/classEntity'

import { columns } from './config'
import { StudentsTable } from './StudentsTable'

const theme = createTheme({
  overrides: {
    Dialog: {
      backgroundColor: 'red'
    }
  }
})

const FutureAttendance = () => {
  const futureattendanceData = useSelector(
    (state) => state.classEntityReducer.futureattendanceData
  )
  const [studentsModalOpen, setStudentsModalOpen] = React.useState(false)
  const [studentsData, setStudentsData] = React.useState([])
  const getStudentsDetails = React.useCallback(
    (dataIndex) => {
      const data = futureattendanceData.data[dataIndex]
      if (!isEmpty(data)) {
        const attendanceData = data?.attendances?.map((student) => {
          return {
            name: student?.student_name,
            parentName: student?.parent_name ? student?.parent_name : null,
            parentNumber: student?.parent_number ? student?.parent_number : null
          }
        })
        setStudentsData(attendanceData)
        setStudentsModalOpen(true)
      } else {
        showToast('Oops, Unable to fetch data', constants.ERROR)
      }
    },
    [futureattendanceData]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 2),
      {
        name: 'start_time',
        label: 'Timing',
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const data = futureattendanceData.data[dataIndex]
            return (
              <span>{`${dayjs(`${data.date} ${data.start_time}`).format(
                'H:mm a'
              )} - ${dayjs(`${data.date} ${data.end_time}`).format(
                'H:mm a'
              )}`}</span>
            )
          }
        }
      },
      ...columns.slice(2, 6),
      {
        name: 'action',
        disabledInForm: true,
        options: {
          customBodyRenderLite: (dataIndex) => {
            const data = futureattendanceData.data[dataIndex]

            return data?.attendances?.length > 0 ? (
              <Button onClick={() => getStudentsDetails(dataIndex)}>
                See attendance
              </Button>
            ) : null
          }
        }
      }
    ]
  }, [futureattendanceData])

  return (
    <>
      <BaseEntity
        entity={constants.FUTURE_ATTENDANCE}
        label={constants.FUTURE_ATTENDANCE_LABEL}
        columns={modifiedColumns}
        data={futureattendanceData}
        fetchData={fetchFutureAttendanceData}
        readOnly
      />
      <ThemeProvider theme={theme}>
        <ModalForm
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={studentsModalOpen}
          onModalClose={() => setStudentsModalOpen(false)}
          enableSave={false}
          modalTitle="Students">
          <StudentsTable data={studentsData} />
        </ModalForm>
      </ThemeProvider>
    </>
  )
}

export default FutureAttendance
