import React from 'react'
import * as styles from './styles'
import {
  NewMomentText, ImageNotFoundText
} from '../../../constants'

const MessageImage = ({ text, contentMedia }) => {
  return (
    <styles.Container>
      {NewMomentText}
      <styles.InnerContainer>
        {text}
        {contentMedia ?
          <styles.Image src={contentMedia} />
          : <div>{ImageNotFoundText}</div>}
      </styles.InnerContainer>
    </styles.Container>
  )
}

export default MessageImage