import styled from 'styled-components';

export const Icon = styled.img`
  height: 0.8rem;
  width: 0.8rem;
  margin-right: 0.25rem;
`

export const SelectedPillStyle = {
  border: '1px solid rgba(0, 0, 0, 0.12)'
}

export const PillStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#EEF7FF',
  borderRadius: '2rem',
  padding: '0.25rem 0.5rem 0.25rem 0.5rem',
  marginRight: '0.5rem',
  fontSize: '12px'
}