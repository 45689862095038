import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'

import { apiEndpoints } from 'api'
import { TextField, Select } from 'app/components/generic/FormElements'
import { DatePicker } from 'app/components/generic/FormFields'
import { FOLLOW_UP_DATE_MESSAGE, TICKET_STATUS_OPTIONS } from 'app/constants'
import { validateDate, validateNotEmpty } from 'app/validators'

import * as styles from './styles'

import { get } from '../../../../../request'

const EditTicketStatusForm = (props) => {
  const { formData } = props
  const { errors, values, setFieldValue } = useFormikContext()

  const [statusOptions, setStatusOptions] = useState([])

  //UseEffect for fetching deduction reasons array
  React.useEffect(() => {
    ;(async () => {
      const statusConfig = await get(
        `${apiEndpoints.configApp}?key=CRM_TICKET_STATUSES`
      )
      setStatusOptions(statusConfig?.data?.results?.[0].value)
    })()
  }, [])

  React.useEffect(() => {
    if (formData?.status) {
      setFieldValue('status', formData?.status)
    }
    if (formData?.resolution_summary) {
      setFieldValue('resolution_summary', formData?.resolution_summary)
    }
    if (formData?.follow_up_date) {
      setFieldValue('follow_up_date', formData?.follow_up_date)
    }
  }, [formData?.status, formData?.resolution_summary, formData?.follow_up_date])

  React.useEffect(() => {
    if (
      values?.status === TICKET_STATUS_OPTIONS.RESOLVED &&
      formData.follow_up_date !== dayjs().format('YYYY-MM-DD') &&
      dayjs(formData.follow_up_date).isAfter(dayjs()) &&
      formData?.status !== TICKET_STATUS_OPTIONS.RESOLVED
    )
      setFieldValue('follow_up_date', dayjs().format('YYYY-MM-DD'))
  }, [values?.status])

  const validateResolutionSummary = (value) => {
    if (values?.status === TICKET_STATUS_OPTIONS.RESOLVED && isEmpty(value)) {
      return 'Summary is required when status is Resolved'
    }
  }
  const onDateChangeHandler = (e) => {
    setFieldValue('follow_up_date', e.target.value)
  }

  return (
    <>
      <FormControl variant="outlined" required>
        <InputLabel>{'Status'}</InputLabel>
        <Select
          name={'status'}
          defaultValue={formData?.status || ''}
          label={'Status'}
          validate={validateNotEmpty({ isRequired: true })}>
          {statusOptions?.map((key, index) => {
            return (
              <MenuItem key={statusOptions[index]} value={statusOptions[index]}>
                {statusOptions[index]}
              </MenuItem>
            )
          })}
        </Select>
        {!isEmpty(errors.status) && (
          <FormHelperText>{errors.status}</FormHelperText>
        )}
      </FormControl>
      {values?.status !== TICKET_STATUS_OPTIONS.RESOLVED ? (
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.follow_up_date) ? { error: true } : null)}
          required>
          <DatePicker
            inputProps={{ min: dayjs().format('YYYY-MM-DD') }}
            name={'follow_up_date'}
            label={'Follow Up Date'}
            value={values?.follow_up_date}
            onChange={onDateChangeHandler}
            variant="outlined"
            required
            validate={validateDate}
          />
        </FormControl>
      ) : null}
      {values?.status === TICKET_STATUS_OPTIONS.RESOLVED ? (
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.resolution_summary) ? { error: true } : null)}
          required>
          <TextField
            name={'resolution_summary'}
            label={'Resolution Summary'}
            variant="outlined"
            required
            validate={validateResolutionSummary}
          />
        </FormControl>
      ) : null}
      {formData?.status !== TICKET_STATUS_OPTIONS.RESOLVED &&
      formData.follow_up_date !== dayjs().format('YYYY-MM-DD') &&
      dayjs(formData.follow_up_date).isAfter(dayjs()) ? (
        <styles.Message>{FOLLOW_UP_DATE_MESSAGE}</styles.Message>
      ) : null}
    </>
  )
}

export default EditTicketStatusForm
