import React from 'react'

import FlagChip from './FlagChip'
import * as styles from './styles'

const FlagsComponent = ({ flags, flagBoxStyles }) => {
  return (
    <styles.FlagsContainer>
      {flags?.map((flag) => (
        <FlagChip flag={flag} key={flag.uuid} flagBoxStyles={flagBoxStyles} color="red" />
      ))}
    </styles.FlagsContainer>
  )
}

export default FlagsComponent
