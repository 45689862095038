import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchEventData, updateEventData } from 'app/store/actions/eventEntity'

import { columns } from './config'

const Event = () => {
  const eventData = useSelector((state) => state.eventEntityReducer.eventData)

  return (
    <BaseEntity
      entity={constants.EVENT}
      label={constants.EVENT_LABEL}
      columns={columns}
      data={eventData}
      fetchData={fetchEventData}
      updateEntityData={updateEventData}
    />
  )
}

export default Event
