import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import React from 'react'

import * as Styles from './styles'

import { BookingTypes } from '../../../../../../constants'
import {
  calculateDiscountPercentage,
  calculateTotalPrice
} from '../../formConfig/helpers'

const userDetails = [
  {
    label: 'Parent Name',
    objectKeyName: 'selectedParentDetails',
    valueAccessorPath: ['first_name', 'last_name']
  },
  {
    label: 'Parent Number',
    objectKeyName: 'selectedParentDetails',
    valueAccessorPath: 'phone_number'
  },
  {
    label: 'Student Name',
    objectKeyName: 'selectedStudentDetails',
    valueAccessorPath: ['user.first_name', 'user.last_name']
  },
  {
    label: 'Student Number',
    objectKeyName: 'selectedStudentDetails',
    valueAccessorPath: 'user.phone_number'
  },
  {
    label: 'Student Grade',
    objectKeyName: 'selectedStudentDetails',
    valueAccessorPath: 'standard.name'
  }
]

const CheckoutDetails = ({
  bookingType,
  schema,
  formData,
  cartItems,
  ...rest
}) => {
  if (isNil(schema)) return null

  return (
    <Styles.DetailsContainer>
      {userDetails.map((detail, index) => {
        const object = rest[detail.objectKeyName] || {}

        const value = Array.isArray(detail.valueAccessorPath)
          ? detail.valueAccessorPath.map((item) => get(object, item)).join(' ')
          : get(object, detail.valueAccessorPath)

        if (!isEmpty(value)) {
          return (
            <Styles.KeyValue key={index}>
              <Styles.Key>{detail.label}:</Styles.Key>
              <Styles.Value>{value}</Styles.Value>
            </Styles.KeyValue>
          )
        }

        return null
      })}

      {bookingType === BookingTypes.CLASS &&
        Object.keys(schema.definitions).map((key) => {
          const value = schema.definitions[key]

          const {
            title,
            enum: _enum,
            enumNames,
            additionalProperties: {
              isDisabledInCheckout,
              valueDependentKey,
              getValue
            } = {}
          } = value

          let fieldValue =
            _enum && enumNames
              ? enumNames[
                  _enum.findIndex((item) => item === get(formData, key))
                ]
              : get(formData, key)

          if (isFunction(getValue)) {
            if (valueDependentKey) {
              fieldValue = getValue(get(formData, valueDependentKey))
            } else {
              fieldValue = getValue(fieldValue)
            }
          }

          if (
            (isString(fieldValue) && isEmpty(fieldValue)) ||
            isNil(fieldValue) ||
            isDisabledInCheckout
          )
            return null

          return (
            <Styles.KeyValue key={key}>
              <Styles.Key>{title}:</Styles.Key>
              <Styles.Value>{fieldValue}</Styles.Value>
            </Styles.KeyValue>
          )
        })}

      {bookingType === BookingTypes.CLASS ? (
        <Styles.KeyValue>
          <Styles.Key>Discount Percentage:</Styles.Key>
          <Styles.Value>
            {calculateDiscountPercentage({
              isDiscountInPercentage: formData.is_discount_in_percentage,
              discountPercentage: formData.discount_percentage,
              actualPrice: calculateTotalPrice(cartItems),
              customPricing: formData.custom_pricing
            })}
            %
          </Styles.Value>
        </Styles.KeyValue>
      ) : null}
    </Styles.DetailsContainer>
  )
}

export { CheckoutDetails }
