import { isNil } from '@openhouse-technologies/utils'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { isEmpty } from 'lodash'
import React from 'react'
import isMobilePhone from 'validator/lib/isMobilePhone'

import {
  fetchProgramEnrolments,
  getBatchEligibilityForStudent,
  getEnrolment
} from 'api'
import { fetchJourneyData } from 'api/journey'
import { START_DATE_MAX_NUMBER } from 'app/config'
import { EXCLUDE_FROM_OPENDAY_COURSE_ID } from 'app/constants'
import { createScheduleData, formatCadence } from 'app/helpers'
import { sentryLogger } from 'sentry'

import { conflictTypes, levelMap } from '../../../constants'
import { composeBatchDetails } from '../../../transformers'

dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)

export const validateParentDetails = (details) => {
  const { first_name, last_name, phone_number, state, centre_preference } =
    details || {}

  let isValid = false
  const invalidAttributes = []

  const isValidFirstName = !isEmpty(first_name)
  const isValidLastName = !isEmpty(last_name)
  const isValidMobile = isMobilePhone(phone_number || '', 'en-IN')
  const isValidState = !isEmpty(state)
  const isValidCentre = !isEmpty(centre_preference)

  if (isValidFirstName && isValidLastName && isValidMobile && isValidState) {
    isValid = true
  } else {
    isValid = false

    if (!isValidFirstName) invalidAttributes.push('first_name')
    if (!isValidLastName) invalidAttributes.push('last_name')
    if (!isValidMobile) invalidAttributes.push('phone_number')
    if (!isValidState) invalidAttributes.push('state')
    if (!isValidCentre) invalidAttributes.push('centre')
  }

  return {
    isValid,
    invalidAttributes
  }
}

export const validateStudentDetails = (details) => {
  const { first_name, last_name, state } = details.user || {}

  const { standard } = details

  let isValid = false
  const invalidAttributes = []

  const isValidFirstName = !isEmpty(first_name)
  const isValidLastName = !isEmpty(last_name)
  const isValidGrade = !isEmpty(standard)
  const isValidState = !isEmpty(state)

  if (isValidFirstName && isValidLastName && isValidGrade && isValidState) {
    isValid = true
  } else {
    isValid = false

    if (!isValidFirstName) invalidAttributes.push('first_name')
    if (!isValidLastName) invalidAttributes.push('last_name')
    if (!isValidGrade) invalidAttributes.push('grade')
    if (!isValidState) invalidAttributes.push('state')
  }

  return {
    isValid,
    invalidAttributes
  }
}

export const newFlowValidateStudentDetails = async (details, selectedBatch) => {
  let { isValid, invalidAttributes } = validateStudentDetails(details)
  const { uuid, standards, level, subject } = composeBatchDetails(selectedBatch)

  const batchLevelSequence = parseInt(level)

  const batchEligibility = await getBatchEligibilityForStudent({
    student: details.user.uuid,
    batch: uuid
  })

  let {
    is_eligible,
    reason,
    makeup_enrolments,
    enable_discovery,
    discovery_end_date,
    resubscribe_start_date
  } = batchEligibility?.data
  let description = ''

  //check for level eligibility
  const studentJourney = await fetchJourneyData({
    queries: { student: details.user.uuid, subject: subject.uuid }
  })

  const studentLevelSequence =
    studentJourney?.results?.[0]?.current_level?.level.sequence

  if (studentLevelSequence) {
    if (studentLevelSequence !== batchLevelSequence) {
      is_eligible = true
      reason = conflictTypes.LEVEL_CONFLICT
      description = (
        <>
          The level of the batch is{' '}
          <strong>{levelMap[batchLevelSequence]}</strong> and the level of the
          child is <strong>{levelMap[studentLevelSequence]}</strong>.
        </>
      )
    }
  }

  if (is_eligible) {
    return {
      isValid,
      invalidAttributes,
      message: reason === conflictTypes.LEVEL_CONFLICT ? reason : null,
      description: reason === conflictTypes.LEVEL_CONFLICT ? description : null,
      enable_discovery,
      discovery_end_date,
      resubscribe_start_date
    }
  } else {
    return {
      isValid: false,
      invalidAttributes,
      message: reason,
      description,
      makeup_enrolments,
      enable_discovery,
      discovery_end_date,
      resubscribe_start_date
    }
  }
}

export const getProgramEnrolments = async (parentId) => {
  const res = await fetchProgramEnrolments({
    url: null,
    queries: { parent: parentId }
  })
  const programEnrolments = res?.results

  return programEnrolments
}

export const fetchScheduledEvents = async (
  selectedBatchType,
  batchId,
  handleOnChange
) => {
  try {
    const data = await getEnrolment({ batchId })

    const filteredData = data?.schedule_events?.schedules
      .filter((item, index) =>
        dayjs(`${item.date} ${item.start_time}`).isSameOrAfter(
          dayjs(),
          'second'
        )
      )
      .reduce((acc, curr) => {
        return [
          ...acc,
          {
            label: curr.date,
            value: curr.uuid
          }
        ]
      }, [])

    if (!isEmpty(filteredData)) {
      const { label, value } = filteredData[0]

      handleOnChange(batchId, 'startDate', {
        label,
        value
      })
    }

    const maxFilteredData = filteredData.slice(0, START_DATE_MAX_NUMBER)

    return selectedBatchType === 'event' ? maxFilteredData : filteredData
  } catch (error) {
    sentryLogger(error, 'error in getting scheduled events')
  }
}

export const fetchExcelScheduledEvents = async (
  selectedBatchType,
  batchId,
  handleOnChange,
  discoveryEndDate,
  resubscribeStartDate
) => {
  try {
    const data = await getEnrolment({ batchId })
    const filteredData = data?.schedule_events?.schedules
      .filter((item, index) => {
        return dayjs(`${item.date} ${item.start_time}`).isBetween(
          resubscribeStartDate
            ? dayjs(resubscribeStartDate)
            : discoveryEndDate
            ? dayjs(discoveryEndDate).add(1, 'day')
            : dayjs().subtract(1, 'month'),
          dayjs().add(6, 'month'),
          'day',
          '[]'
        )
      })
      .reduce((acc, curr) => {
        return [
          ...acc,
          {
            label: curr.date,
            value: curr.uuid
          }
        ]
      }, [])
    // .filter((obj) => {
    //   const date = dayjs(obj.label)
    //   const month = date.month() + 1
    //   const day = date.date()

    //   // Exclude objects between the 11th and 24th of September except for phonics
    //   if (
    //     month === 9 &&
    //     day >= 11 &&
    //     day <= 24 &&
    //     !EXCLUDE_FROM_OPENDAY_COURSE_ID.includes(data.course.uuid)
    //   ) {
    //     return false
    //   }

    //   return true
    // })

    const maxFilteredData = filteredData.slice(0, START_DATE_MAX_NUMBER)

    return selectedBatchType === 'event' ? maxFilteredData : filteredData
  } catch (error) {
    sentryLogger(error, 'error in getting excel scheduled events')
  }
}

export const fetchEndDates = (date, batchId, onChange) => {
  const endDateList = createScheduleData(date)
  return endDateList
}

export const validateBatchInfoDate = (item) => {
  const startDateStatus = Object.values(item).find((el) => el?.startDate)
  const endDateStatus = Object.values(item).find((el) => el?.endDate)

  return {
    startDateStatus,
    endDateStatus
  }
}

export const formatErrorMessage = (errorMessage, studentName = null) => {
  if (!errorMessage) return null
  return errorMessage.replace('<student_name>', studentName)
}

export const getCartItemStructure = (item, userBatchInfo, userInfo) => ({
  batchDetails: { ...item },
  id: item.uuid + userInfo.student.user.uuid, //to keep the items unique, join the batch id with student id
  price: userBatchInfo.price,
  userInfo,
  batchFormInfo: { ...userBatchInfo }
})

export const validateItemForCart = (existingCartItems, item) => {
  const isItemInCart = existingCartItems.find((el) => el.id === item.id)
  //dont allow addToCart if item is already in cart for same student
  return {
    isValid: !isItemInCart,
    message: `This batch is already in cart for the selected child.`
  }
}

export const validateCourseDetail = (selectedBatchInfo, subject) => {
  let isValid = false

  const { isDiscover, startDate, endDate } = selectedBatchInfo
  const { discovery_enabled } = subject

  let isValidStartDate = !isNil(startDate)
  let isValidEndDate = !isNil(endDate)
  let isValidIsDiscover = !isNil(isDiscover)

  if (discovery_enabled) {
    if (isDiscover === 'false') {
      isValid = isValidStartDate && isValidIsDiscover
    } else {
      isValid = isValidStartDate && isValidEndDate && isValidIsDiscover
    }
  } else {
    isValid = isValidStartDate && isValidEndDate
  }
  return isValid
}

export const calculateEndDate = (startDate) => {
  if (!startDate) return null

  const date = dayjs(startDate)
  const day = date.date()

  let endDate
  if (day <= 15) {
    endDate = date.endOf('month')
  } else {
    endDate = date.add(1, 'month').endOf('month')
  }

  // Return the end date in the same format as the input start date
  return endDate.format('YYYY-MM-DD')
}

export const formatEndDate = (endDate) => {
  if (!endDate) return null
  const date = dayjs(endDate)

  return date.format('ddd, D MMM YYYY')
}

export const formatDateForCart = (date) => {
  return dayjs(date).format('D MMM YYYY')
}

export const transformCartItem = (item) => {
  const transformedObject = {
    subjectName: item.batchDetails.course.subjects[0].name,
    subjectImage: item.batchDetails.course.subjects[0].app_image,
    batchLevel: item.batchDetails.course.level?.name || null,
    bookingType:
      item.batchFormInfo.isDiscover === true ? 'Discovery' : 'Subscription',
    batchTimings: formatCadence(item.batchDetails.cadence),
    startDate: formatDateForCart(item.batchFormInfo.startDate.label),
    endDate: formatDateForCart(item.batchFormInfo.endDate.value),
    price: item.price
  }
  return transformedObject
}
