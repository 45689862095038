import MuiDialogActions from '@material-ui/core/DialogActions'
import { withStyles, useTheme } from '@material-ui/core/styles'
import NextIcon from '@material-ui/icons/ArrowForward'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import _ from 'lodash'
import React from 'react'

import ModalForm from 'app/components/ModalForm'

import MainForm from './components/programForms/MainForm'
import * as styles from './styles'
const steps = ['Program Type', 'Reserve your Spot']

const DialogActions = withStyles((theme) => ({
  root: {
    height: 64,
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    justifyContent: 'space-between'
  }
}))(MuiDialogActions)

const Enrolment = (props) => {
  const { formData, onSaveClick, formRef, isModalOpen, closeModal } = props
  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})
  const theme = useTheme()

  const onModalClose = React.useCallback(() => {
    setActiveStep(0)
    setCompleted({})
    closeModal()
  }, [])

  React.useEffect(() => {
    if (!isModalOpen) setActiveStep(0)
  }, [isModalOpen])

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = async () => {
    let error = false
    if (activeStep === 0) {
      error = await onSaveClick('program')
    }

    if (activeStep === 0 && !error) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1
      setActiveStep(newActiveStep)
    }
  }

  const DialogActionsElement = (
    <DialogActions>
      <styles.NextButtonWrapper>
        <MuiThemeProvider theme={theme}>
          {activeStep === 0 ? (
            <styles.LoadingButton
              loading={false}
              loadingPosition="start"
              variant="contained"
              color="primary"
              startIcon={<NextIcon />}
              onClick={handleNext}
            >
              next
            </styles.LoadingButton>
          ) : (
            <styles.LoadingButton
              loadingPosition="start"
              variant="contained"
              color="primary"
              onClick={onSaveClick}
            >
              confirm
            </styles.LoadingButton>
          )}
        </MuiThemeProvider>
      </styles.NextButtonWrapper>
    </DialogActions>
  )

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const renderStep = () => {
    if (activeStep === 0 || activeStep === 1) {
      return (
        <MainForm
          activeStep={activeStep}
          formRef={formRef}
          formData={formData}
        />
      )
    }

    return null
  }

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      enableSave={false}
      showCloseIcon
      modalTitle="Register"
      disableBackdropClick
      onModalClose={onModalClose}
      modalCss={`
          .MuiDialog-paperScrollPaper {
              overflow-y: unset;
          }
        `}
      DialogActions={DialogActionsElement}
    >
      <styles.Container>
        <styles.FormContainer>
          <MuiThemeProvider theme={theme}>
            <styles.StepperWrapper>
              <Stepper
                activeStep={activeStep}
                orientation="horizontal"
                color="inherit"
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </styles.StepperWrapper>
            {renderStep()}
          </MuiThemeProvider>
        </styles.FormContainer>
      </styles.Container>
    </ModalForm>
  )
}

export default Enrolment
