import { InputLabel, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import React from 'react'

import { Select, TextField } from 'app/components/generic/FormElements'
import { validateNotEmpty } from 'app/validators'

const CancelForm = (props) => {
  const { formData, reasons } = props
  const { errors, values } = useFormikContext()

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.reason) ? { error: true } : null)}
        required>
        <InputLabel>{'Select reason'}</InputLabel>
        <Select
          name={'reason'}
          defaultValue={formData.reason || ''}
          label={'Select reason'}
          validate={validateNotEmpty({ isRequired: true })}>
          {reasons?.map((val, index) => (
            <MenuItem key={index} value={val}>
              {val}
            </MenuItem>
          ))}
        </Select>
        {!isEmpty(errors.reason) && (
          <FormHelperText>{errors.reason}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.notes) ? { error: true } : null)}
        required>
        <TextField
          name={'notes'}
          label={'Notes'}
          variant="outlined"
          required
          validate={
            values?.reason === 'Other' && validateNotEmpty({ isRequired: true })
          }
        />
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.reason_for_using_alfred) ? { error: true } : null)}
        required>
        <TextField
          name={'reason_for_using_alfred'}
          label={'Reason for using Alfred instead of the App'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
        />
      </FormControl>
    </>
  )
}

export default CancelForm
