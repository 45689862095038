import { isEmpty } from 'lodash'

import { fetchCentre, fetchStandard } from 'api'

import { FilterTypes } from '../../constants'

export const HeaderFields = [
  {
    name: FilterTypes.CENTRE,
    label: 'Centre *',
    inputType: 'autocomplete',
    // TODO - change multiple to true after BE supports it in batch api
    multiple: false,
    async: false,
    initialSelectIndex: 0,
    required: true,
    fetchValues: async (searchedText) => {
      try {
        const queries = {
          limit: 25
        }

        if (!isEmpty(searchedText)) {
          queries.name = searchedText
        }

        const response = await fetchCentre({ queries })

        return response.results.map((item) => ({
          value: item.uuid,
          name: item.name,
          city: item.city
        }))
      } catch (error) {
        console.log('Error in fetching centres :- ', error)
        throw error
      }
    }
  },
  {
    name: FilterTypes.GRADE,
    label: 'Grade',
    inputType: 'autocomplete',
    multiple: true,
    async: false,
    fetchValues: async (searchedText) => {
      try {
        const queries = {
          limit: 100
        }

        if (!isEmpty(searchedText)) {
          queries.name = searchedText
        }

        const response = await fetchStandard({ queries })

        return response.results.map((item) => ({
          value: item.uuid,
          name: item.name
        }))
      } catch (error) {
        console.log('Error in fetching grades :- ', error)
        throw error
      }
    }
  },
  {
    name: FilterTypes.CATEGORY,
    label: 'Subject *',
    inputType: 'autocomplete',
    multiple: true,
    async: true,
    required: true,
    initialSelectIndex: 0,
    minCharactersToSearch: 0
  },
  {
    name: FilterTypes.BATCH_TYPE,
    label: 'Batch Type',
    inputType: 'select'
  }
]
