import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import CancelSessions from '../Enrolment/components/CancelSessions'
import EnrolmentForm from '../Enrolment/components/EnrolmentForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const MasterModal = (props) => {
  const classes = useStyles()

  const {
    isModalOpen,
    closeModal,
    onSaveClick,
    action,
    data,
    onActionButtonClick,
    setCancelId,
    isProgramEnrolment
  } = props

  const formElement = React.useMemo(() => {
    return action === 'terminate_enrolment' ? (
      <EnrolmentForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        enrolmentData={data || {}}
      />
    ) : action === 'cancel_sessions' ? (
      <CancelSessions
        isProgramEnrolment={isProgramEnrolment ?? false}
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={data || {}}
        onActionButtonClick={onActionButtonClick}
        setCancelId={setCancelId}
      />
    ) : null
  }, [action, classes, isModalOpen, closeModal, data])

  return <>{formElement}</>
}

MasterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default MasterModal
