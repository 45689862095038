import { isEmpty, isNil } from 'lodash'

import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const courseRequestStructure = (data) => {
  const structuredData = {
    name: data.name,
    details: data.details,
    min_age: data.min_age,
    max_age: data.max_age,
    subjects: data.subjects,
    standards: data.standards,
    boards: data.boards,
    fee: data.fee,
    coins_per_session: data.coins_per_session,
    coins_per_month: data.coins_per_month,
    batches_per_booking: data.batches_per_booking,
    modules: data.modules,
    mindsets: data.mindsets,
    images: data.images || [],
    videos: data.videos || [],
    hero: data.hero,
    trial_class_plan: data.trial_class_plan || null,
    trial_learning_outcomes: data.trial_learning_outcomes || null,
    publish_in: data.publish_in || [],
    thumbnail: data.thumbnail || null,
    is_makeup_allowed: data.is_makeup_allowed
  }
  if (!isNil(data.level)) {
    if (typeof data.level === 'object') {
      structuredData.level = data.level?.uuid
    } else {
      structuredData.level = data.level
    }
  }
  return structuredData
}

const fetchCourse = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.course, { ...queries, level: 'all' })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    // showToast('Oops, Error in fetching course', constants.ERROR)
    sentryLogger(error, 'error in fetching course')
    throw error
  }
}

const createCourse = async (data) => {
  try {
    const response = await post(
      apiEndpoints.course,
      courseRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating course')
    throw error
  }
}

const updateCourse = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.course}/${data.uuid}/`,
      courseRequestStructure(data)
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating course')
    throw error
  }
}

const deleteCourse = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.course}/${id}`)
      })
    )

    return ids
  } catch (error) {
    sentryLogger(error, 'error in deleting course')
    throw error
  }
}

export { fetchCourse, createCourse, updateCourse, deleteCourse }
