import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { confirm, eventEmitter } from 'app/helpers'
import {
  fetchSubjectData,
  updateClassData
} from 'app/store/actions/classEntity'

import { columns } from './config'

const Subject = () => {
  const subjectData = useSelector(
    (state) => state.classEntityReducer.subjectData
  )

  const saveFunction = (formData, onSave) => {
    if (formData?.progress_enabled === 'true') {
      const onError = () => {
        eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
      }
      const isConfirm = confirm(
        onSave,
        `Are you sure you want to enable journey for this category?`,
        onError
      )
    } else onSave()
  }

  return (
    <BaseEntity
      entity={constants.SUBJECT}
      label={constants.SUBJECT_LABEL}
      columns={columns}
      data={subjectData}
      fetchData={fetchSubjectData}
      updateEntityData={updateClassData}
      beforeSaveFunction={saveFunction}
    />
  )
}

export default Subject
