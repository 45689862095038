import React from 'react'
import SeperatorIcon from 'assets/images/seperator.svg'

import * as styles from './styles'


export const StudentBasicDetails = (props) => {
  const { studentBasicData } = props

  return (
      <>
        <styles.Heading>{studentBasicData?.user?.full_name}</styles.Heading>
        <styles.SubWrapper>
          <styles.TextWrapper>
            Age {studentBasicData?.standard?.age}
          </styles.TextWrapper>
          <styles.Icon src={SeperatorIcon} />
          <styles.TextWrapper>
            {studentBasicData?.standard?.name}
          </styles.TextWrapper>
        </styles.SubWrapper>
      </>
  )
}
