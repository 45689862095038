import { takeEvery, takeLatest } from 'redux-saga/effects'

import {
  fetchMockPaper,
  createMockPaper,
  updateMockPaper,
  deleteMockPaper,
  fetchMockPricing,
  createMockPricing,
  updateMockPricing,
  deleteMockPricing,
  fetchMockTestReportCard
} from 'api'
import constants from 'app/constants'
import {
  fetchMockPaperData,
  fetchMockPaperDataSuccess,
  updateMockPaperDataSuccess,
  fetchMockTestReportCardData,
  fetchMockTestReportCardSuccess,
  fetchMockPricingData,
  fetchMockPricingDataSuccess
} from 'app/store/actions/mockTestEntity'
import * as types from 'app/store/types/mockTest'

import { handleFetch, handleUpdate } from './saga.helper'

const mapping = {
  [constants.PAPER]: {
    api: {
      fetch: fetchMockPaper,
      create: createMockPaper,
      update: updateMockPaper,
      delete: deleteMockPaper
    },
    action: {
      fetch: fetchMockPaperData,
      fetchSuccess: fetchMockPaperDataSuccess
    }
  },
  [constants.PRICING]: {
    api: {
      fetch: fetchMockPricing,
      create: createMockPricing,
      update: updateMockPricing,
      delete: deleteMockPricing
    },
    action: {
      fetch: fetchMockPricingData,
      fetchSuccess: fetchMockPricingDataSuccess
    }
  },
  [constants.MOCK_TEST_REPORT_CARD]: {
    api: {
      fetch: fetchMockTestReportCard
    },
    action: {
      fetch: fetchMockTestReportCardData,
      fetchSuccess: fetchMockTestReportCardSuccess
    }
  }
}

export function* _fetchMockTest({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updateMockTest({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(
    mappingData,
    entity,
    event,
    value,
    updateMockPaperDataSuccess
  )
}

export function* watchFetchMockTest() {
  yield takeLatest(
    [
      types.FETCH_MOCK_PAPER_DATA,
      types.FETCH_MOCK_PRICING_DATA,
      types.FETCH_MOCK_TEST_REPORT_CARD_DATA
    ],
    _fetchMockTest
  )
}

export function* watchUpdateMockTest() {
  yield takeEvery(
    [
      types.UPDATE_MOCK_PAPER_DATA,
      types.UPDATE_MOCK_PRICING_DATA,
      types.FETCH_MOCK_TEST_REPORT_CARD_DATA
    ],
    _updateMockTest
  )
}
