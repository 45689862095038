import { Divider } from '@material-ui/core'
import {
  getValueFromLocalStorage,
  setValueInLocalStorage
} from '@openhouse-technologies/utils'
import { isNil, isEmpty } from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useCart } from 'react-use-cart'

import { fetchUser, getCategoryCost } from 'api'
import ModalForm from 'app/components/ModalForm'
import { navigationPaths } from 'app/config'
import constants from 'app/constants'
import { eventAlert } from 'app/helpers'
import { composeBatchDetails } from 'app/pages/Class/Inventory/transformers'

import { Actions } from './components/Actions'
// import { CoinSummary } from './components/CoinSummary'
import { CourseDetail } from './components/CourseDetail'
import { ParentChildForm } from './components/ParentChildForm'
import { demoSchema } from './formConfig'
import * as Styles from './styles'

import { SeparationContainer } from '../../../styles'
import {
  calculateEndDate,
  formatEndDate,
  getCartItemStructure,
  validateBatchInfoDate,
  validateCourseDetail,
  validateItemForCart,
  validateParentDetails,
  newFlowValidateStudentDetails
} from '../helpers'

const AddToCartModal = ({
  isModalOpen,
  bookingType,
  selectedItem,
  onHandleModalClose,
  selectedBatchType,
  isEditing,
  editingItem
}) => {
  const LinkRedirector = ({ href }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ cursor: 'pointer' }}>
        here
      </a>
    )
  }

  const {
    addItem: addItemToCart,
    isEmpty: isCartEmpty,
    items: currentCartItems,
    updateItem: updateCartItem
  } = useCart()

  const [formData, setFormData] = useState({})
  const [batchInfo, setBatchInfo] = useState({})
  const [errorSchema, setErrorSchema] = useState({})
  const [selectedParentDetails, setSelectedParentDetails] = useState(
    getValueFromLocalStorage('CART_PARENT') || {}
  ) //
  const isParentLocked = !isCartEmpty

  const [selectedStudentDetails, setSelectedStudentDetails] = useState({})
  const [students, setStudents] = useState([])
  const [invalidCoins, setIsInvalidCoins] = useState(false)
  const [coinData, setCoinData] = useState({})
  const [inputError, setInputError] = useState({
    parent: null,
    student: null,
    isDiscover: null
  })
  const [commonCourses, setCommonCourses] = React.useState([])
  const [eligibleGrades, setEligibleGrades] = React.useState([])
  const [disableAddToCart, setDisableAddtoCart] = React.useState(true)
  const [courseFormDisabled, setCourseFormDisabled] = React.useState(true)
  const [courseFormValid, setCourseFormValid] = React.useState(false)
  const [studentConflictDetails, setStudentConflictDetails] = React.useState({
    isStudentValid: null,
    message: '',
    description: '',
    makeup_enrolments: []
  })

  const [enableDiscovery, setEnableDiscovery] = React.useState(null)
  const [discoveryEndDate, setDiscoveryEndDate] = React.useState(null)
  const [resubscribeStartDate, setResubscribeStartDate] = React.useState(null)

  const [currentBatchInfo, setCurrentBatchInfo] = React.useState({})

  const formRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEditing) {
      if (!editingItem) return
    } else if (!selectedItem) return
    handleSelectedParentChange()
  }, [selectedItem, editingItem, isEditing])

  useEffect(() => {
    ;(async () => {
      if (!isEmpty(selectedParentDetails) && !isEmpty(selectedStudentDetails)) {
        const isParentValid = validateParentDetails(
          selectedParentDetails
        )?.isValid
        const {
          isValid: isStudentValid,
          message,
          description,
          makeup_enrolments,
          enable_discovery,
          discovery_end_date,
          resubscribe_start_date
        } = await newFlowValidateStudentDetails(
          selectedStudentDetails,
          selectedItem
        )

        setStudentConflictDetails({
          isStudentValid,
          message,
          description,
          makeup_enrolments
        })

        setDiscoveryEndDate(discovery_end_date)
        setResubscribeStartDate(resubscribe_start_date)

        setEnableDiscovery(enable_discovery)
        setBatchInfo((state) => ({
          ...state,
          [selectedItem?.uuid]: {
            ...state[selectedItem?.uuid],
            isDiscover: enable_discovery
          }
        }))

        const parentStudentValid = isParentValid && isStudentValid
        setCourseFormDisabled(!parentStudentValid)
      } else {
        setCurrentBatchInfo({})
        setCourseFormDisabled(true)
      }
    })()
  }, [selectedParentDetails, selectedStudentDetails, selectedItem])

  useEffect(() => {
    setDisableAddtoCart(courseFormDisabled || !courseFormValid)
  }, [courseFormDisabled, courseFormValid])

  const handleSelectedParentChange = () => {
    if (!isEmpty(selectedParentDetails)) {
      setSelectedStudentDetails({})
      setCommonCourses({})
      const grades = []

      if (selectedParentDetails.students.length === 0) {
        eventAlert(
          'No students found! Add a student for the parent.',
          constants.ERROR
        )
      }

      const mappedStudents = selectedParentDetails?.students.map((student) => {
        const isEligible = eligibleGrades.includes(student?.standard?.name)
        return {
          ...student,
          isDisabled: !isEligible,
          message: isEligible ? null : ''
        }
      })

      const eligibleStudents = mappedStudents.filter(
        (student) => !student.isDisabled
      )

      if (
        eligibleStudents.length === 0 &&
        selectedParentDetails?.students?.length > 0
      ) {
        // eventAlert(
        //   'No students match the grade for the selected course!',
        //   constants.ERROR
        // )
      }

      setStudents(mappedStudents || [])

      handleValidateParentDetails(selectedParentDetails)
      if (isEditing) setSelectedStudentDetails(editingItem.userInfo.student)
    } else {
      setStudents([])
      setSelectedStudentDetails({})
      setCommonCourses({})
      setInputError({
        parent: null,
        student: null,
        isDiscover: null
      })
      setDisableAddtoCart(true)
      setCurrentBatchInfo({})
    }
  }

  useEffect(() => {
    handleSelectedParentChange()
  }, [selectedParentDetails])

  useEffect(() => {
    if (isModalOpen) {
      if (isEditing && editingItem) {
        setSelectedParentDetails(editingItem?.userInfo?.parent)
        setBatchInfo({
          [editingItem?.batchDetails?.uuid]: editingItem?.batchFormInfo
        })
      } else if (selectedItem) {
        const { uuid, standards } = composeBatchDetails(selectedItem)
        const grades = standards?.map((std) => std.name) || []
        setBatchInfo((state) => {
          const batchId = uuid

          const { isDiscover, startDate, endDate } = state[batchId] || {}

          let newState = {
            ...state,
            [batchId]: {
              ...state[batchId],
              price: null,
              isDiscover: isNil(isDiscover) ? '' : isDiscover,
              startDate: isNil(startDate) ? null : startDate,
              endDate: isNil(endDate) ? null : endDate
            }
          }

          return newState
        })

        setEligibleGrades(grades)
      }
    }
  }, [isModalOpen, selectedItem, isEditing, editingItem])

  const handleParentSelection = async (name, value) => {
    setSelectedParentDetails(value || {})
    if (!isNil(value)) {
      await verifyDetails(value?.uuid)
    }
  }

  const handleStudentSelection = (event) => {
    const { value } = event.target
    const selectedStudent = students.find((item) => item.user.uuid === value)
    let studentDetails = selectedStudent || {}
    if (selectedStudent) {
      studentDetails.parent = selectedParentDetails.uuid
    }
    setSelectedStudentDetails(studentDetails)
  }

  const ReVerifyButton = () => {
    return (
      <>
        if updated, click{' '}
        <span
          onClick={verifyDetails}
          style={{
            textDecoration: 'underline',
            color: 'blue',
            cursor: 'pointer'
          }}>
          here
        </span>{' '}
        to verify
      </>
    )
  }

  const verifyDetails = async (parent_uuid) => {
    try {
      const queries = { uuid: parent_uuid }
      const response = await fetchUser({ queries }, 'parent')

      if (response.results.length === 1) {
        const item = response.results[0]
        setSelectedParentDetails({
          uuid: item.user.uuid,
          value: item.user.uuid,
          name: `${item.user.phone_number} (${item.user.first_name} ${item.user.last_name})`,
          first_name: item.user.first_name,
          last_name: item.user.last_name,
          phone_number: item.user.phone_number,
          state: item.user.state,
          email: item.user.email_address,
          students: item.students,
          coins: item.user.coin_summary?.remaining_coins,
          credits: item.user.credits,
          member_status: item.user.member_status,
          centre_preference: item.centre_preference
        })

        setStudents(item.students || [])
      } else {
        throw new Error('Multiple users found with the same number')
      }
    } catch (error) {
      console.error('Error in verifying user details:', error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const handleValidateParentDetails = (selectedParentDetails) => {
    let error = null,
      isValid = false

    if (isEmpty(selectedParentDetails)) {
      error = 'Please Select Parent'
    } else {
      const result = validateParentDetails(selectedParentDetails)

      isValid = result.isValid

      if (!isValid) {
        error = (
          <div>
            Parent details ({result.invalidAttributes.join(', ')}) invalid,
            click{' '}
            <LinkRedirector
              href={`${window.location.origin}${navigationPaths.parent}?uuid=${selectedParentDetails?.uuid}&editMode=1`}
            />{' '}
            to update, <ReVerifyButton />
          </div>
        )
      }
    }

    setInputError((state) => ({
      ...state,
      parent: error
    }))

    return isValid
  }

  useEffect(() => {
    const { batch } = currentBatchInfo

    if (
      currentBatchInfo.startDate &&
      (currentBatchInfo.isDiscover === true ||
        currentBatchInfo.isDiscover === false)
    ) {
      ;(async () => {
        const categoryCost = await getCategoryCost(currentBatchInfo)

        if (categoryCost?.success) {
          const {
            data: { end_date, cost }
          } = categoryCost
          setBatchInfo((state) => {
            const newBatchInfo = {
              ...state,
              [batch]: {
                ...state[batch],
                endDate: {
                  value: end_date,
                  label: formatEndDate(end_date)
                },
                price: cost
              }
            }
            setCourseFormValid(
              validateCourseDetail(
                newBatchInfo[batch],
                selectedItem?.course?.subjects?.[0]
              )
            )

            return newBatchInfo
          })
        }
      })()
    }
  }, [currentBatchInfo, selectedItem])
  const handleBatchInfoChange = async (batchId, property, value) => {
    setBatchInfo((prevState) => {
      const newBatchInfo = {
        ...prevState,
        [batchId]: {
          ...prevState[batchId],
          [property]: value
        }
      }
      if (property === 'startDate') {
        setCurrentBatchInfo({
          batch: selectedItem?.uuid,
          startDate: value,
          isDiscover: newBatchInfo[batchId]?.isDiscover
        })
      } else if (property === 'isDiscover') {
        setCurrentBatchInfo({
          batch: selectedItem.uuid,
          startDate: newBatchInfo[batchId].startDate,
          isDiscover: value
        })
      }
      return newBatchInfo
    })
  }

  const onSubmitForm = ({ formData }) => {
    setFormData(formData)
  }

  const handleOnFormDataChange = ({ formData }) => {
    if (formRef.current) {
      const { errorSchema } = formRef.current.validate(formData)
      const modifiedErrorSchema = {}

      Object.keys(errorSchema).forEach((key) => {
        if (formData[key]) {
          modifiedErrorSchema[key] = errorSchema[key]
        }
      })

      setErrorSchema(modifiedErrorSchema)
      setFormData(formData)
    }
  }

  const onAddToCartClick = async () => {
    const userInfo = {
      student: selectedStudentDetails,
      parent: selectedParentDetails
    }

    const cartItem = getCartItemStructure(
      selectedItem,
      batchInfo[selectedItem.uuid],
      userInfo
    )
    if (isEditing) {
      updateCartItem(editingItem.id, cartItem)
      setValueInLocalStorage('CART_PARENT', selectedParentDetails)
      onHandleModalClose()
    } else {
      const cartValidation = validateItemForCart(currentCartItems, cartItem)
      if (cartValidation.isValid) {
        addItemToCart(cartItem)
        setValueInLocalStorage('CART_PARENT', selectedParentDetails)
        onModalClose()
      } else {
        eventAlert(cartValidation?.message, constants.ERROR)
      }
    }
  }

  const onModalClose = () => {
    setFormData({})
    setBatchInfo({})
    setErrorSchema({})
    // setSelectedParentDetails({})
    setSelectedStudentDetails({})
    setStudents([])
    setInputError({
      parent: null,
      student: null
    })
    setCommonCourses({})
    setStudentConflictDetails({})
    setEnableDiscovery(null)
    setCourseFormDisabled(true)
    setDisableAddtoCart(true)
    setCurrentBatchInfo({})
    onHandleModalClose()
  }

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      enableSave={false}
      showCloseIcon
      onModalClose={onModalClose}>
      <Styles.Container>
        <Styles.FormContainer>
          <ParentChildForm
            ref={formRef}
            bookingType={bookingType}
            schema={demoSchema}
            onSubmit={onSubmitForm}
            formData={formData}
            onChangeForm={handleOnFormDataChange}
            errorSchema={errorSchema}
            selectedParentDetails={selectedParentDetails}
            selectedStudentDetails={selectedStudentDetails}
            handleParentSelection={handleParentSelection}
            handleStudentSelection={handleStudentSelection}
            students={students}
            inputError={inputError}
            isParentLocked={isParentLocked}
            isEditing={isEditing}
            studentConflictDetails={studentConflictDetails}
            onHandleModalClose={onHandleModalClose}
          />
          <SeparationContainer $css={`flex:0.05;`}>
            <Divider orientation="vertical" />
          </SeparationContainer>
          <Styles.ItemsContainer>
            <CourseDetail
              enableDiscovery={enableDiscovery}
              discoveryEndDate={discoveryEndDate}
              resubscribeStartDate={resubscribeStartDate}
              errorSchema={inputError}
              selectedItem={selectedItem}
              bookingType={bookingType}
              batchInfo={batchInfo}
              onBatchInfoChange={handleBatchInfoChange}
              selectedBatchType={selectedBatchType}
              coinData={coinData}
              courseFormDisabled={courseFormDisabled}
              isEditing={isEditing}
            />
          </Styles.ItemsContainer>
        </Styles.FormContainer>
      </Styles.Container>
      <Styles.ActionsContainer>
        <Actions
          onAddToCartClick={onAddToCartClick}
          disableAddToCart={disableAddToCart}
          isEditing={isEditing}
        />
      </Styles.ActionsContainer>
    </ModalForm>
  )
}

export default AddToCartModal
