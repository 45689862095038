import styled from 'styled-components';

export const PohMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #ef5c34;
`;

export const AvatarMessage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 2px;
  padding-right: 2px;
`;

export const PohMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
  max-width: 250px;
`;

export const MessageText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-align: left;
  margin-left: 10px;
`;

export const CtaText = styled.span`
  font-size: 14px;
  font-weight: 400;
  align-self: flex-start;
  cursor: default;
`;

export const Timestamp = styled.div`
  font-size: 10px;
  color: #0000008f;
  margin-top: 5px;
  padding-left: 10px;
`;

export const Line = styled.div`
  border: none;
  border-top: 1px solid #c2c2c2;
  min-width: 10%;
  margin: 0;
  flex-grow: 1;
`;
