import { Radio } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import { isEmpty } from 'lodash'
import React from 'react'

import { apiEndpoints, apiURLOrigin } from 'api/common'
import { downloadJMDashBoard } from 'api/user'
import { DateRangePicker } from 'app/components/generic/DateRangePicker'
import constants from 'app/constants'
import { getJMName } from 'app/helpers'
import { formatAmount } from 'app/utils'
import { validateNotEmpty } from 'app/validators'

// import { MonthRangePicker } from './components/generic/MonthRangePicker'
export const publicNavigationPaths = {
  home: '/',
  login: '/login',
  notFound: '/not-found',
  dashboard: '/school/dashboard',
  schoolAttendance: '/school/attendance'
}

export const mainLayoutNavigationPaths = {
  student: '/user/student',
  teacher: '/user/teacher',
  superprovider: '/user/superprovider',
  admin: '/user/admin',
  parent: '/user/parent',
  subjectCategory: '/class/subject-category',
  subject: '/class/subject',
  standard: '/class/standard',
  board: '/class/board',
  course: '/class/course',
  batch: '/class/batch',
  inventory: '/class/inventory',
  trialsessions: '/class/trialsessions',
  enrolment: '/class/enrolment',
  attendance: '/class/attendance',
  centre: '/centre',
  room: '/room',
  canceledMember: '/membership/canceled',
  pausedMember: '/membership/paused',
  due: '/payment/due',
  paid: '/payment/paid',
  dueMember: '/payment/dueMember',
  paidMember: '/payment/paidMember',
  dueRenewal: '/payment/dueRenewal',
  paidRenewal: '/payment/paidRenewal',
  summaryTeacher: '/payment/summary/teacher',
  summaryStudent: '/payment/summary/student',
  summaryIndependent: '/payment/summary/independent',
  summaryIndependentOngoing: '/payment/summary/independent-ongoing',
  summarySuperprovider: '/payment/summary/superprovider',
  dispatch: '/payment/dispatch',
  club: '/community/club',
  referralCode: '/community/referral-code',
  clubRegistration: '/community/club-registration',
  waitlistQueued: '/community/waitlist-queued',
  waitlistTribeSelection: '/community/waitlist-tribe-selection',
  waitlistOthers: '/community/waitlist-others',
  bulkMessaging: '/communication/bulk-messaging',
  interactionHistory: '/communication/interactionhistory',
  ticket: '/communication/ticket',
  teacherParentChat: '/communication/teacher-parent-chat',
  centreParentChat: '/communication/centre-parent-chat',
  tribe: '/community/tribe',
  membership: '/community/membership',
  moments: '/community/classroom-moments',
  openbook: '/community/openbook',
  inactiveMembership: '/community/inactive-membership',
  event: '/event',
  eventRegistration: '/event/registration',
  reportCard: '/report-card',
  demoSchedulingForm: '/schedule-demo',
  paper: '/mock/paper',
  pricing: '/mock/pricing',
  mockReportCard: '/mock-report-card',
  portfolio: '/portfolio',
  parentmembership: '/parentmembership',
  parentdashboard: '/dashboard/parent',
  teacherdashboard: '/dashboard/teacher',
  attendance: '/attendance',
  pastattendance: '/attendance/past',
  futureattendance: '/attendance/future',
  bookMakeupSession: '/class/makeupsession/bookmakeupsession',
  makeUpSession: '/class/makeupsession/showmakeupsession',
  exploreSessionProgress: '/progresstracking/exploresession',
  exploreSession: '/class/exploresession',
  exploreEnrolment: '/class/exploreenrolment',
  excelEnrolment: '/class/excelenrolment',
  opendaySchedule: '/scheduler/showcases',
  opendaySlots: '/scheduler/slots',
  journeySelection: '/journey/journeyselection',
  childCategoryJourney: '/journey/childcategoryjourney',
  opportunities: '/scheduler/opportunities',
  orders: '/inventory/orders'
}

export const additionalNavigationPaths = {
  demoScheduling: '/request',
  scheduler: '/scheduler',
  schedule: '/centreschedule'
}

export const navigationPaths = {
  ...publicNavigationPaths,
  ...mainLayoutNavigationPaths,
  ...additionalNavigationPaths
}

export const navigationMenus = [
  {
    name: 'dashboard',
    displayName: 'Dashboard',
    icon: {
      name: 'Parentdashboard'
    },
    subMenus: [
      {
        name: 'parentdashboard',
        displayName: 'Parent Dashboard',
        path: navigationPaths.parentdashboard,
        component: 'ParentDashboardPage'
      },
      {
        name: 'teacherdashboard',
        displayName: 'Teacher Dashboard',
        path: navigationPaths.teacherdashboard,
        component: 'TeacherDashboardPage'
      }
    ]
  },
  {
    name: 'attendance',
    displayName: 'Attendance',
    icon: {
      name: 'Attendance'
    },
    subMenus: [
      {
        name: 'markattendance',
        displayName: 'Mark Attendance',
        path: navigationPaths.attendance,
        component: 'AttendancePage'
      },
      {
        name: 'pastattendance',
        displayName: 'Past Attendance',
        path: navigationPaths.pastattendance,
        component: 'PastAttendancePage'
      }
      // ,
      // {
      //   name: 'futureattendance',
      //   displayName: 'Future Attendance',
      //   path: navigationPaths.futureattendance,
      //   component: 'FutureAttendancePage'
      // }
    ]
  },
  {
    name: 'user',
    displayName: 'User',
    icon: {
      name: 'People'
    },
    subMenus: [
      {
        name: 'student',
        displayName: 'Student',
        path: navigationPaths.student,
        component: 'UserStudentPage'
      },
      {
        name: 'teacher',
        displayName: 'Teacher',
        path: navigationPaths.teacher,
        component: 'UserTeacherPage'
      },
      {
        name: 'superprovider',
        displayName: 'Superprovider',
        path: navigationPaths.superprovider,
        component: 'UserSuperproviderPage'
      },
      {
        name: 'admin',
        displayName: 'Admin',
        path: navigationPaths.admin,
        component: 'UserAdminPage'
      },
      {
        name: 'parent',
        displayName: 'Parent',
        path: navigationPaths.parent,
        component: 'UserParentPage'
      }
    ]
  },
  {
    name: 'offering',
    displayName: 'Offering',
    icon: {
      name: 'Class'
    },
    subMenus: [
      {
        name: 'subjectCategory',
        displayName: 'Subject Category',
        path: navigationPaths.subjectCategory,
        component: 'ClassSubjectCategoryPage'
      },
      {
        name: 'subject',
        displayName: 'Subject',
        path: navigationPaths.subject,
        component: 'ClassSubjectPage'
      },
      {
        name: 'standard',
        displayName: 'Standard',
        path: navigationPaths.standard,
        component: 'ClassStandardPage'
      },
      {
        name: 'board',
        displayName: 'Board',
        path: navigationPaths.board,
        component: 'ClassBoardPage'
      },
      {
        name: 'course',
        displayName: 'Course',
        path: navigationPaths.course,
        component: 'ClassCoursePage'
      }
    ]
  },
  {
    name: 'enrolment',
    displayName: 'Batch & Enrolments',
    icon: {
      name: 'Enrolment'
    },
    subMenus: [
      {
        name: 'batch',
        displayName: 'Batch',
        path: navigationPaths.batch,
        component: 'ClassBatchPage'
      },
      {
        name: 'trialsessions',
        displayName: 'Trial Sessions',
        path: navigationPaths.trialsessions,
        component: 'ClassTrialSessionsPage'
      },
      {
        name: 'enrolment',
        displayName: 'Event Enrolments',
        path: navigationPaths.enrolment,
        component: 'ClassEnrolmentPage'
      },
      {
        name: 'excelEnrolment',
        displayName: 'Excel Enrolments',
        path: navigationPaths.excelEnrolment,
        component: 'ClassExcelEnrolmentPage'
      },
      {
        name: 'exploreEnrolment',
        displayName: 'Explore Enrolments',
        path: navigationPaths.exploreEnrolment,
        component: 'ClassExploreEnrolmentPage'
      },
      {
        name: 'makeupSession',
        displayName: 'Make-Up Sessions',
        path: navigationPaths.makeUpSession,
        component: 'ClassMakeUpSessionPage'
      }
      // {
      //   name: 'exploreSession',
      //   displayName: 'Explore Session',
      //   path: navigationPaths.exploreSession,
      //   component: 'ClassExploreSessionPage'
      // }
    ]
  },
  {
    name: 'inventory',
    displayName: 'Booking & Inventory',
    icon: {
      name: 'Inventory'
    },
    subMenus: [
      {
        name: 'inventory',
        displayName: 'Inventory',
        path: navigationPaths.inventory,
        component: 'ClassInventoryPage'
      },
      {
        name: 'bookMakeupSession',
        displayName: 'Book Make-Up Session',
        path: navigationPaths.bookMakeupSession,
        component: 'ClassMakeUpSessionBookingPage'
      },
      {
        name: 'orders',
        displayName: 'Orders',
        path: navigationPaths.orders,
        component: 'OrdersPage'
      }
    ]
  },
  {
    name: 'progressTracking',
    displayName: 'Progress Tracking',
    icon: {
      name: 'ProgressTracking'
    },
    subMenus: [
      {
        name: 'exploreSession',
        displayName: 'Explore Session',
        path: navigationPaths.exploreSessionProgress,
        component: 'ExploreSessionPage'
      },
      {
        name: 'journeySelection',
        displayName: 'journey selection',
        path: navigationPaths.journeySelection,
        component: 'JourneySelection',
        sideBarVisibility: false
      },
      {
        name: 'childCategoryJourney',
        displayName: 'child category journey',
        path: navigationPaths.childCategoryJourney,
        component: 'ChildCategoryJourney',
        sideBarVisibility: false
      }
    ]
  },
  {
    name: 'community',
    displayName: 'Community',
    icon: {
      name: 'Language'
    },
    subMenus: [
      // {
      //   name: 'club',
      //   displayName: 'Club',
      //   path: navigationPaths.club,
      //   component: 'CommunityClubPage'
      // },
      // {
      //   name: 'tribe',
      //   displayName: 'Tribe',
      //   path: navigationPaths.tribe,
      //   component: 'CommunityTribePage'
      // },

      // {
      //   name: 'waitlist-queued',
      //   displayName: 'Registration Requests',
      //   path: navigationPaths.waitlistQueued,
      //   component: 'CommunityWaitlistQueuedPage'
      // },

      // {
      //   name: 'membership',
      //   displayName: 'Membership',
      //   path: navigationPaths.membership,
      //   component: 'CommunityTribeMembershipPage'
      // },
      // {
      //   name: 'referral-code',
      //   displayName: 'Referral Code',
      //   path: navigationPaths.referralCode,
      //   component: 'CommunityReferralCodePage'
      // },
      // {
      //   name: 'club-registration',
      //   displayName: 'Legacy Registration',
      //   path: navigationPaths.clubRegistration,
      //   component: 'CommunityClubRegistrationPage'
      // },
      {
        name: 'openbook',
        displayName: 'Openbook',
        path: navigationPaths.openbook,
        component: 'Openbook'
      }
    ]
  },
  {
    name: 'space',
    displayName: 'Space',
    icon: {
      name: 'Room'
    },
    subMenus: [
      {
        name: 'centre',
        displayName: 'Centre',
        path: navigationPaths.centre,
        component: 'SpaceCentrePage'
      },
      {
        name: 'room',
        displayName: 'Room',
        path: navigationPaths.room,
        component: 'SpaceRoomPage',
        sideBarVisibility: false
      }
    ]
  },
  // {
  //   name: 'membership',
  //   displayName: 'Membership',
  //   icon: {
  //     name: 'ParentMembership'
  //   },
  //   subMenus: [
  //     {
  //       name: 'canceled',
  //       displayName: 'Cancelled Membership',
  //       path: navigationPaths.canceledMember,
  //       component: 'MembershipCanceledPage'
  //     },
  //     {
  //       name: 'pause',
  //       displayName: 'Paused Membership',
  //       path: navigationPaths.pausedMember,
  //       component: 'MembershipPausePage'
  //     }
  //     // {
  //     //   name: 'parentmembership',
  //     //   displayName: 'Purchase membership',
  //     //   path: navigationPaths.parentmembership,
  //     //   component: 'ParentMembershipPage'
  //     // }
  //   ]
  // },
  // {
  //   name: 'payment',
  //   displayName: 'Payment',
  //   icon: {
  //     name: 'Payment'
  //   },
  //   subMenus: [
  //     {
  //       name: 'nonMember',
  //       displayName: 'Non-member',
  //       nested: true,
  //       subMenus: [
  //         {
  //           name: 'due',
  //           displayName: 'Due',
  //           path: navigationPaths.due,
  //           doubleNested: true,
  //           component: 'PaymentDuePage'
  //         },
  //         {
  //           name: 'paid',
  //           displayName: 'Paid',
  //           path: navigationPaths.paid,
  //           doubleNested: true,
  //           component: 'PaymentPaidPage'
  //         }
  //       ]
  //     },
  //     {
  //       name: 'member',
  //       displayName: 'Member',
  //       nested: true,
  //       subMenus: [
  //         {
  //           name: 'due_member',
  //           displayName: 'Due',
  //           path: navigationPaths.dueMember,
  //           doubleNested: true,
  //           component: 'PaymentDueMemberPage'
  //         },
  //         {
  //           name: 'paid_member',
  //           displayName: 'Paid',
  //           path: navigationPaths.paidMember,
  //           doubleNested: true,
  //           component: 'PaymentPaidMemberPage'
  //         }
  //       ]
  //     },
  //     {
  //       name: 'renewal',
  //       displayName: 'Renewal',
  //       nested: true,
  //       subMenus: [
  //         {
  //           name: 'due_renewal',
  //           displayName: 'Due',
  //           path: navigationPaths.dueRenewal,
  //           doubleNested: true,
  //           component: 'PaymentDueRenewalPage'
  //         },
  //         {
  //           name: 'paid_renewal',
  //           displayName: 'Paid',
  //           path: navigationPaths.paidRenewal,
  //           doubleNested: true,
  //           component: 'PaymentPaidRenewalPage'
  //         }
  //       ]
  //     },
  //     {
  //       name: 'summary',
  //       displayName: 'Payout',
  //       nested: true,
  //       subMenus: [
  //         {
  //           name: 'non-members',
  //           displayName: 'non-members',
  //           nested: true,
  //           subMenus: [
  //             {
  //               name: 'teacher',
  //               displayName: 'Teacher',
  //               path: navigationPaths.summaryTeacher,
  //               doubleNested: true,
  //               component: 'PaymentTeacherSummaryPage'
  //             },
  //             {
  //               name: 'student',
  //               displayName: 'Student',
  //               path: navigationPaths.summaryStudent,
  //               doubleNested: true,
  //               component: 'PaymentStudentSummaryPage'
  //             },
  //             {
  //               name: 'dispatch',
  //               displayName: 'Dispatch',
  //               path: navigationPaths.dispatch,
  //               doubleNested: true,
  //               component: 'PaymentDispatchPage'
  //             }
  //           ]
  //         },
  //         {
  //           name: 'members',
  //           displayName: 'Teacher',
  //           nested: true,
  //           subMenus: [
  //             {
  //               name: 'independentOngoing',
  //               displayName: 'Ongoing Earnings',
  //               path: navigationPaths.summaryIndependentOngoing,
  //               doubleNested: true,
  //               component: 'PaymentIndependentOngoingSummaryPage'
  //             },
  //             {
  //               name: 'independent',
  //               displayName: 'Dispatch',
  //               path: navigationPaths.summaryIndependent,
  //               doubleNested: true,
  //               component: 'PaymentIndependentSummaryPage'
  //             }
  //             // {
  //             //   name: 'superprovider',
  //             //   displayName: 'Superprovider',
  //             //   path: navigationPaths.summarySuperprovider,
  //             //   doubleNested: true,
  //             //   component: 'PaymentSuperproviderSummaryPage'
  //             // },
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    name: 'communication',
    displayName: 'Communication',
    icon: {
      name: 'Chat'
    },
    subMenus: [
      {
        name: 'bulk-messaging',
        displayName: 'Bulk Messaging',
        path: navigationPaths.bulkMessaging,
        component: 'CommunicationBulkMessagingPage'
      },
      {
        name: 'interaction-history',
        displayName: 'Interaction History',
        path: navigationPaths.interactionHistory,
        component: 'InteractionHistoryPage'
      },
      {
        name: 'ticket',
        displayName: 'Ticket',
        path: navigationPaths.ticket,
        component: 'TicketPage'
      },
      {
        name: 'teacher-parent chat',
        displayName: 'Teacher-Parent Chat',
        path: navigationPaths.teacherParentChat,
        component: 'TeacherParentChat'
      },
      {
        name: 'centre-parent chat',
        displayName: 'Centre-Parent Chat',
        path: navigationPaths.centreParentChat,
        component: 'CentreParentChat'
      },
      {
        name: 'classroom-moments',
        displayName: 'Moments',
        path: navigationPaths.moments,
        component: 'Moments'
      }
    ]
  },
  // {
  //   name: 'events',
  //   displayName: 'Event',
  //   icon: {
  //     name: 'Event'
  //   },
  //   subMenus: [
  //     {
  //       name: 'event',
  //       displayName: 'Event',
  //       path: navigationPaths.event,
  //       component: 'EventPage'
  //     },
  //     {
  //       name: 'event-registration',
  //       displayName: 'Registration',
  //       path: navigationPaths.eventRegistration,
  //       component: 'EventRegistrationPage'
  //     }
  //   ]
  // },
  {
    name: 'scheduler',
    displayName: 'Scheduler',
    icon: {
      name: 'Scheduler'
    },
    subMenus: [
      {
        name: 'calendar',
        displayName: 'Calendar',
        path: navigationPaths.scheduler
      },
      {
        name: 'opendaySchedule',
        displayName: 'showcases',
        path: navigationPaths.opendaySchedule,
        component: 'OpendaySchedulePage'
      },
      {
        name: 'opportunities',
        displayName: 'showcases & competitions',
        path: navigationPaths.opportunities,
        component: 'Opportunities'
      },
      {
        name: 'opendaySlots',
        displayName: 'openday slots',
        path: navigationPaths.opendaySlots,
        component: 'OpendaySlotsPage',
        sideBarVisibility: false
      }
    ]
  }
  // {
  //   name: 'portfolio',
  //   displayName: 'portfolio',
  //   icon: {
  //     name: 'Portfolio'
  //   },
  //   nested: false,
  //   path: navigationPaths.portfolio,
  //   component: 'PortfolioPage'
  // }
]

export const drawerWidth = 270

export const tableDefaultOption = {
  filter: false,
  print: false,
  viewColumns: false,
  fixedHeader: true,
  fixedSelectColumn: true,
  selectableRows: 'multiple',
  enableNestedDataAccess: '.', // allows nested data separated by ".",
  setTableProps: () => ({
    'data-testid': 'table'
  }),
  search: false,
  downloadOptions: {
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true
    }
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    if (
      columns[0]?.download?.serverSide === true &&
      columns[0]?.download?.type === 'JM_DASHBOARD'
    ) {
      const page_number = parseInt(localStorage.getItem(constants.PAGE_NO)) + 1
      const page_size = 25
      const jm_name = getJMName()

      if (jm_name && jm_name?.length !== 0) {
        downloadJMDashBoard({
          page_size,
          page_number,
          journey_mentor: jm_name
        })
      } else {
        downloadJMDashBoard({ page_size, page_number })
      }
      return false
    } else {
      const modifiedData = data.map((eachData, rowIndex) => {
        const { data } = eachData

        const modifiedColumnsData = data.map((eachColumnData, columnIndex) => {
          if (
            eachColumnData !== null &&
            (Array.isArray(eachColumnData) ||
              typeof eachColumnData === 'object')
          ) {
            const { customBodyRender, customBodyRenderLite } =
              columns[columnIndex]

            if (typeof customBodyRender === 'function') {
              const element = customBodyRender(eachColumnData)
              return element?.props?.children
            } else if (typeof customBodyRenderLite === 'function') {
              const element = customBodyRenderLite(rowIndex)
              return element?.props?.children
            }
          }

          return eachColumnData
        })

        return {
          ...eachData,
          data: modifiedColumnsData
        }
      })

      return `${buildHead(columns)}${buildBody(modifiedData)}`.trim()
    }
  }
}

export const serverSideOptions = {
  serverSide: true,
  rowsPerPage: 25,
  rowsPerPageOptions: [25]
}

export const readOnlyTableDefaultOption = {
  ...tableDefaultOption,
  selectableRows: 'none'
}

export const tableColumnDefaultOption = {
  options: {
    filter: true,
    sort: true
  }
}

export const getWidgetTypeName = (widgetName) => {
  switch (widgetName) {
    case 'datepicker':
      return 'date'

    case 'timepicker':
      return 'time'

    default:
      return 'text'
  }
}

// cloudinary upload presets for set of folder
// can be found on settings < uploads < upload presets
export const uploadPresets = {
  [constants.USER]: 'user_czawjri1',
  [constants.EVENT_POSTER]: 'event_poster_yrv1y1uz',
  [constants.SUBJECT]: 'subject_eklmdzhh',
  [constants.CLUB]: 'club_r8hq4kks',
  [constants.MOCK_TEST_THUMBNAIL]: 'mock_thumbnails_gqvw7zie',
  [constants.MOCK_TEST_PDF]: 'mock_sample_papers_apsbfg2p',
  [constants.CENTRE]: 'learning-centres',
  [constants.COURSE]: 'course_x12hu6so',
  [constants.SUBJECT_CATEGORY]: 'subject_category_m2ytl7yd',
  [constants.OPENBOOK]: 'openbook_uymcyqzz'
}

export const commonAttributes = {
  batches: {
    name: 'batches',
    label: 'Batches',
    widget: 'autocomplete',
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        const { uuid, teacher = '', subject = '', timings } = item

        return {
          uuid: uuid,
          name: `${teacher}, ${subject}, ${timings}`
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
      return data.results.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'batches',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.fetchBatch
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'batches',
      customBodyRender: (value) => {
        return (
          <span>
            {Array.isArray(value) &&
              value
                .map((item) => {
                  const { teacher = '', subject = '', timings } = item

                  return `${teacher}, ${subject}, ${timings}`
                })
                .join(' | ')}
          </span>
        )
      }
    }
  },
  boards: {
    name: 'boards',
    label: 'Boards',
    widget: 'autocomplete',
    widgetFilter: true,
    validate: validateNotEmpty,
    optional: true,
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
      return data.results.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'boards',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.board
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'boards',
      customBodyRender: (value) => (
        <span>
          {Array.isArray(value) && value.map((item) => item.name).join(', ')}
        </span>
      )
    }
  },
  centre: {
    name: 'centre',
    label: 'Centre',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
              uuid: item.uuid,
              name: item.name
            }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'centre',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.centre
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre',
      customBodyRender: (value) => (value ? <span>{value?.name}</span> : null)
    }
  },
  standard: {
    name: 'standards',
    label: 'Standards',
    formDataKey: 'standards',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'standards',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.standard
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'standards',
      customBodyRender: (value) => (
        <span>
          {Array.isArray(value) && value.map((item) => item.name).join(', ')}
        </span>
      )
    }
  },
  student: {
    name: 'student.user.name',
    label: 'Student Name',
    widget: 'autocomplete',
    formDataKey: 'student',
    validate: validateNotEmpty,
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'user')) {
          return {
            uuid: item?.user?.uuid,
            name: item?.user?.name
              ? item?.user?.name
              : `${item?.user?.first_name} ${item?.user?.last_name}`
          }
        } else {
          return {
            uuid: item.uuid,
            name: item.name
          }
        }
      }
      if (Array.isArray(data) && !isEmpty(data)) {
        return [getItemStructure(data[0])]
      } else if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'user')) {
          return item.user.uuid
        }
      }
      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        return getItemStructure(data)
      }
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'student',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.user}/student`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_name',
      customBodyRender: (value) => (
        <span>
          {value &&
            (value.name
              ? value.name
              : `${value.first_name} ${value.last_name}`)}
        </span>
      )
    }
  },
  subject: {
    name: 'subjects',
    label: 'Subjects',
    formDataKey: 'subjects',
    widget: 'autocomplete',
    widgetFilter: true,
    validate: validateNotEmpty,
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      }

      return data.results.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'subjects',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.subject}?limit=500&offset=0`
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'subjects',
      customBodyRender: (value) => (
        <span>
          {Array.isArray(value) && value.map((item) => item.name).join(', ')}
        </span>
      )
    }
  },
  club: {
    name: 'club.name',
    label: 'Club',
    formDataKey: 'club',
    widget: 'autocomplete',
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      }

      return data.results.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'club',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.club
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'club_name'
    }
  },
  teacher: {
    name: 'teacher.user',
    label: 'Teacher Name',
    widget: 'autocomplete',
    formDataKey: 'teacher',
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'user')) {
          return {
            uuid: item.user.uuid,
            name: `${item.user.first_name} ${item.user.last_name}`
          }
        } else {
          return {
            uuid: item.uuid,
            name: item.name
          }
        }
      }
      if (Array.isArray(data) && !isEmpty(data)) {
        return [getItemStructure(data[0])]
      } else if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'user')) {
          return item.user.uuid
        }
      }
      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        return getItemStructure(data)
      }
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'teacher',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.user}/teacher`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name',
      customBodyRender: (value) => (
        <span>{`${(value && value.first_name) || ''} ${
          (value && value.last_name) || ''
        }`}</span>
      )
    }
  },
  level: {
    name: 'level',
    label: 'Batch Level',
    widget: 'autocomplete',
    widgetFilter: true,
    validate: validateNotEmpty,
    optional: true,
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          name: item[1],
          uuid: item[1]
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return Object.entries(data.results[0].value).map((item) =>
          getItemStructure(item)
        )
      }
      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'level',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=EXCEL_COURSE_LEVELS`
    },
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'level',
      customBodyRender: (value) => {
        return <div>{levelMap[value]}</div>
      }
    }
  },
  user: {
    name: 'user',
    label: 'User Name',
    widget: 'autocomplete',
    formDataKey: 'user',
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item?.uuid,
          name:
            item?.name || `${item?.first_name || ''} ${item?.last_name || ''}`
        }
      }

      if (Array.isArray(data) && !isEmpty(data)) {
        return [getItemStructure(data[0])]
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item?.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'user',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiURLOrigin}/base/user`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'user_name',
      customBodyRender: (value) => (
        <span>{`${(value && value.first_name) || ''} ${
          (value && value.last_name) || ''
        }`}</span>
      )
    }
  },
  uuid: {
    name: 'uuid',
    label: 'Id',
    disabledInFormOnCreate: true,
    editable: false,
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      display: false
    }
  },
  amount: {
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        return <span>{formatAmount(value)}</span>
      }
    }
  },
  image: {
    name: 'image',
    label: 'Image',
    widget: 'fileupload',
    // optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'image',
      disableFilter: true,
      customBodyRender: (value) => (
        <>{!isEmpty(value) ? <Avatar src={value} /> : null}</>
      )
    }
  },
  files: {
    name: 'file',
    label: 'File',
    widget: 'fileupload',
    // optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'file',
      disableFilter: true
    }
  },
  date: {
    options: {
      ...tableColumnDefaultOption.options,
      headerWidget: DateRangePicker,
      headerWidgetInputType: 'daterange'
    }
  }
  // month: {
  //   options: {
  //     ...tableColumnDefaultOption.options,
  //     headerWidget: MonthRangePicker,
  //     headerWidgetInputType: 'monthrange'
  //   }
  // }
}

export const indianStatesJson = {
  AN: 'Andaman and Nicobar Islands',
  AP: 'Andhra Pradesh',
  AR: 'Arunachal Pradesh',
  AS: 'Assam',
  BR: 'Bihar',
  CG: 'Chandigarh',
  CH: 'Chhattisgarh',
  DN: 'Dadra and Nagar Haveli',
  DD: 'Daman and Diu',
  DL: 'Delhi',
  GA: 'Goa',
  GJ: 'Gujarat',
  HR: 'Haryana',
  HP: 'Himachal Pradesh',
  JK: 'Jammu and Kashmir',
  JH: 'Jharkhand',
  KA: 'Karnataka',
  KL: 'Kerala',
  LA: 'Ladakh',
  LD: 'Lakshadweep',
  MP: 'Madhya Pradesh',
  MH: 'Maharashtra',
  MN: 'Manipur',
  ML: 'Meghalaya',
  MZ: 'Mizoram',
  NL: 'Nagaland',
  OR: 'Odisha',
  PY: 'Puducherry',
  PB: 'Punjab',
  RJ: 'Rajasthan',
  SK: 'Sikkim',
  TN: 'Tamil Nadu',
  TS: 'Telangana',
  TR: 'Tripura',
  UP: 'Uttar Pradesh',
  UK: 'Uttarakhand',
  WB: 'West Bengal'
}

export const enrolmentTypes = {
  CLASS: 'regular',
  DEMO: 'demo',
  MEMBERSHIP: 'membership',
  EVENT: 'event',
  PROGRAM: 'program',
  MAKE_UP_SESSION: 'make_up_session'
}

export const targetAudienceSelectionModes = {
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL',
  AUTOMATIC_WITH_EXCLUSION: 'AUTOMATIC_WITH_EXCLUSION'
}

export const bulkMessagingFilterTypes = {
  CENTRE: 'centre',
  TEACHER: 'teacher',
  SUBJECT: 'subject',
  CATEGORY: 'category',
  CLASS: 'class'
}

export const bulkMessagingAvailableFilters = {
  [constants.STUDENT]: [
    bulkMessagingFilterTypes.CENTRE,
    bulkMessagingFilterTypes.TEACHER,
    bulkMessagingFilterTypes.CATEGORY,
    bulkMessagingFilterTypes.SUBJECT
  ],
  [constants.PARENT]: [
    bulkMessagingFilterTypes.CENTRE,
    bulkMessagingFilterTypes.TEACHER,
    bulkMessagingFilterTypes.CATEGORY,
    bulkMessagingFilterTypes.SUBJECT
  ],
  [constants.TEACHER]: [
    bulkMessagingFilterTypes.CENTRE,
    bulkMessagingFilterTypes.SUBJECT,
    bulkMessagingFilterTypes.CATEGORY
  ]
}

export const programList = {
  exporation: 'exploration',
  summer_explore: 'exploration_camp'
}

export const programTypeList = ['exploration', 'exploration_camp', 'excel']

export const general_summer_explore_batch_list = [
  {
    title: '1st April - 30th April 2024',
    start_date: '2024-04-01',
    end_date: '2024-04-30'
  },
  {
    title: '1st May - 28th May 2024',
    start_date: '2024-05-01',
    end_date: '2024-05-28'
  }
]

//For TR & SL
export const special_summer_explore_batch_list = [
  {
    title: '14th May - 24th May',
    start_date: '2024-05-14',
    end_date: '2024-05-24'
  }
]

export const programsMap = ['exploration', 'exploration_camp']
export const countList = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10'
}

export const makeUpRadioButtons = [
  {
    value: 'cancelled',
    control: <Radio />,
    label: 'Cancelled Session'
  },
  {
    value: 'no-show',
    control: <Radio />,
    label: 'No Show '
  }
]
export const levelMap = {
  1: 'beginner',
  2: 'intermediate',
  3: 'advanced'
}

export const batchTypesMap = {
  class: 'class',
  explore: 'explore',
  membership: 'regular member',
  event: 'event',
  excel: 'excel',
  exploration: 'exploration',
  exploration_camp: 'exploration_camp'
}

export const statusMap = {
  active: 'active',
  terminated: 'terminated',
  lapsed: 'lapsed',
  upcoming: 'upcoming',
  due: 'due'
}

export const ageRange = {
  min: 3,
  max: 18
}

export const cityCodes = {
  Bengaluru: 'Bengaluru',
  Kolkata: 'Kolkata'
}

export const platforms = {
  oh_meets: 'OH Meets',
  zoom: 'Zoom'
}

export const yesNoOptions = {
  true: 'yes',
  false: 'no'
}

export const batchTypes = {
  class: 'regular non-member',
  event: 'event',
  mock: 'mock',
  explore: 'trial',
  membership: 'regular member',
  excel: 'excel',
  exploration: 'exploration'
}

export const invoiceTypes = {
  newMember: 'new member',
  renewal: 'renewal',
  topUp: 'add coins'
}

export const centreScheduleBatchTypes = {
  class: 'regular',
  event: 'workshop',
  mock: 'mock',
  explore: 'trial',
  membership: 'regular',
  exploration: 'exploration',
  exploration_camp: 'summer'
}

export const subjectCategoryTypes = {
  academic: 'academic',
  extracurricular: 'extracurricular'
}

export const studentSourceTypes = {
  teacher: 'teacher',
  openhouse: 'openhouse'
}

export const teacherStatuses = {
  active: 'Active',
  applied: 'Applied',
  terminated: 'Terminated'
}

export const teacherTypes = {
  superprovider: 'superprovider',
  independent: 'independent'
}

export const terminationReasons = {
  price: 'Price',
  teacher: 'Teacher',
  openhouse: 'Openhouse'
}

export const statuses = {
  active: 'Active',
  suspended: 'Suspended'
}

export const app_statuses = {
  active: 'Active',
  inactive: 'Inactive',
  canceled: 'Cancelled'
}

export const months = {
  jan: 'January',
  feb: 'February',
  mar: 'March',
  apr: 'April',
  may: 'May',
  jun: 'June',
  jul: 'July',
  aug: 'August',
  sep: 'September',
  oct: 'October',
  nov: 'November',
  dec: 'December'
}

export const prorataModes = {
  absolute: 'absolute',
  percentage: 'percentage'
}

export const START_DATE_MAX_NUMBER = 8

export const paymentModes = {
  upi: 'UPI',
  cash: 'Cash',
  bank: 'Bank',
  cheque: 'Cheque',
  pos_machine: 'POS Machine',
  paid_to_teacher: 'Paid To Teacher',
  payment_gateway_error: 'Payment Gateway Error'
}

export const reasons = {
  topUp: 'Recharge',
  promotion: 'Promotion',
  refund: 'Refund'
}

export const invoiceActions = {
  remind: 'Remind',
  edit: 'Edit',
  collect: 'Collect',
  cancel: 'Cancel'
}

export const independantTeacherActions = {
  edit: 'Edit',
  approve: 'Approve',
  unapprove: 'Unapprove',
  dispatch: 'Dispatch'
}

export const parentActions = {
  // extend: 'Extend',
  topup: 'Add coins',
  deduct: 'Deduct',
  // enrolment: 'Register',
  // renew_now: 'Renew Early',
  // change_plan: 'Change Plan',
  // cancel: 'Cancel Membership',
  suggest_schedule: 'Suggest Schedule',
  change_phone_number: 'Change Phone Number'
}

export const nonMemberActions = {
  suggest_schedule: 'Suggest Schedule',
  change_phone_number: 'Change Phone Number',
  topup: 'Add coins',
  deduct: 'Deduct'
  // enrolment: 'Register'
}

export const memberActions = {
  remind: 'Remind',
  collect: 'Collect',
  cancel: 'Cancel'
}

export const topupActions = {
  // remind: 'Remind',
  collect: 'Collect',
  cancel: 'Cancel'
}

export const renewalActions = {
  remind: 'Remind',
  collect: 'Collect',
  cancel: 'Cancel'
}

export const cancelActions = {
  terminate: 'Terminate',
  pause: 'Hibernate'
}

export const enrolmentActions = {
  terminate_enrolment: 'Terminate',
  cancel_sessions: 'Cancel Sessions'
}

export const excelEnrolmentActions = {
  cancel_sessions: 'Cancel Sessions'
}

export const cancellationActions = {
  cancel: 'Cancel'
}

export const programActions = {
  terminate_enrolment: 'Terminate'
}

export const opendayScheduleActions = {
  view_slots: 'view slots',
  add_slots: 'add slots',
  confirm: 'confirm'
}

export const progressTrackingActions = {
  show_Moments: 'Show Moments'
}

export const NO_OF_YEARS_FOR_OPPORTUNITIES = {
  before: 6,
  after: 3
}

export const OPENDAY_SLOTS_MINUTE_INTERVAL = 15

export const userStatusOptions = {
  true: 'customer',
  false: 'non-customer'
}

export const sourceTypes = {
  alfred: 'alfred',
  member_app: 'member app'
}

export const cartTypes = {
  topup: 'Topup',
  booking: 'Booking',
  topup_booking: 'Booking & Recharge'
}

export const cartStatusTypes = {
  pending: 'Pending',
  completed: 'Completed',
  booking_error: 'Booking Error',
  expired: 'Expired'
}
