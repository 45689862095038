/* eslint-disable react/prop-types */
import { Divider } from '@material-ui/core'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  enrolmentCardEventList,
  enrolmentCardProgramList,
  enrolmentCardTrialList,
  enrolmentCardVisibilityList
} from 'app/pages/Dashboard/ParentDashboard/constants'
import NotFound from 'assets/images/not_found.svg'

import * as styles from './styles'

import { NotFoundBlock } from '../../../../../components/NotFoundBlock'
import { TitleDescription } from '../../../../../components/TitleDescription'

export const Enrolments = (props) => {
  const { enrolmentData } = props
  const [enrolmentBlocks, setEnrolmentBlocks] = useState([])

  const getCount = (type, status, time) => {
    let count = 0
    count = enrolmentData?.filter((el) => {
      let isRequired = type.includes(el.type)
      if (status) {
        isRequired =
          isRequired &&
          (status === 'terminated'
            ? el.status === 'terminated' || el.status === 'expired'
            : el.status === status)
      }

      if (time === 'ongoing') {
        isRequired =
          isRequired &&
          dayjs(
            type.every((v) => enrolmentCardVisibilityList.includes(v))
              ? el.start_date
              : el.end_date
          ).isSameOrBefore(dayjs(), 'day')
      }

      if (time === 'upcoming') {
        isRequired = isRequired && el?.status === 'upcoming'
      }

      if (time === 'attended')
        isRequired =
          isRequired &&
          dayjs(el.start_date).add(7, 'day').isBefore(dayjs(), 'day')
      return isRequired
    }).length

    return count
  }

  useEffect(() => {
    if (!isEmpty(enrolmentData)) {
      const blocks = [
        {
          heading: 'Programs',
          ongoing: getCount(enrolmentCardProgramList, 'active', 'ongoing'),
          upcoming: getCount(enrolmentCardProgramList, 'upcoming', 'upcoming'),
          // terminated: getCount(enrolmentCardProgramList, 'terminated'),
          total: getCount(enrolmentCardProgramList)
        },
        {
          heading: 'Workshops',
          ongoing: getCount(enrolmentCardEventList, 'active', 'ongoing'),
          upcoming: getCount(enrolmentCardEventList, 'upcoming', 'upcoming'),
          // terminated: getCount(enrolmentCardEventList, 'terminated'),
          attended: getCount(enrolmentCardEventList, 'active', 'attended'),
          total: getCount(enrolmentCardEventList)
        },
        {
          heading: 'Trials',
          ongoing: getCount(enrolmentCardTrialList, 'active', 'ongoing'),
          upcoming: getCount(enrolmentCardTrialList, 'upcoming', 'upcoming'),
          // terminated: getCount(enrolmentCardTrialList, 'terminated'),
          attended: getCount(enrolmentCardTrialList, 'active', 'attended'),
          total: getCount(enrolmentCardTrialList)
        }
      ]
      setEnrolmentBlocks(blocks)
    }
  }, [enrolmentData])

  return (
    <styles.Body>
      <styles.ColumnFlexGap>
        {enrolmentBlocks?.filter((el) => el.total)?.length ? (
          enrolmentBlocks?.map((block, index) => {
            return block.total ? (
              <div key={'Enrolment' + index}>
                {block.heading === 'Programs' ? (
                  <styles.ColumnFlexStart>
                    <styles.Content heading type="dark">
                      {block.heading}
                    </styles.Content>
                    <styles.RowFlexGap>
                      <TitleDescription
                        heading="active"
                        description={block.ongoing}
                      />
                      <TitleDescription
                        heading="upcoming"
                        description={block.upcoming}
                      />
                    </styles.RowFlexGap>
                  </styles.ColumnFlexStart>
                ) : block.heading === 'Workshops' &&
                  block.ongoing + block.upcoming > 0 ? (
                  <styles.RowFlexStart>
                    <styles.Content heading>
                      {block.ongoing + block.upcoming}
                    </styles.Content>
                    <styles.Content subheading type="light">
                      active workshops
                    </styles.Content>
                  </styles.RowFlexStart>
                ) : block.heading === 'Trials' && block.upcoming > 0 ? (
                  <styles.RowFlexStart>
                    <styles.Content heading>{block.upcoming}</styles.Content>
                    <styles.Content subheading type="light">
                      upcoming trials
                    </styles.Content>
                  </styles.RowFlexStart>
                ) : null}
              </div>
            ) : block.heading === 'Programs' ? (
              <styles.ColumnFlexStart>
                <styles.Content heading type="dark">
                  {block.heading}
                </styles.Content>
                <styles.RowFlexGap>
                  <TitleDescription
                    heading="active"
                    description={block.ongoing}
                  />
                  <TitleDescription
                    heading="upcoming"
                    description={block.upcoming}
                  />
                </styles.RowFlexGap>
              </styles.ColumnFlexStart>
            ) : null
          })
        ) : (
          <NotFoundBlock message="Oh no! No enrolments found." />
        )}
      </styles.ColumnFlexGap>
    </styles.Body>
  )
}
