export const dataColumns = {
  'active student': {
    type: 'ACTIVE_STUDENTS',
    heading: 'Students List',
    tableHeadings: [
      { label: 'Student Name', type: 'text-link' },
      { label: 'Status', type: 'text' },
      { label: 'Last Attended', type: 'text' },
      { label: 'Enrolled Between', type: 'text' },
      { label: 'Session Attended', type: 'text' }
    ],
    accessors: [
      'student_name',
      'student_status',
      'last_attended',
      'active_between',
      'session_attended'
    ]
  },
  dropouts: {
    type: 'DROPOUTS',
    heading: 'Students List',
    tableHeadings: [
      { label: 'Student Name', type: 'text-link' },
      { label: 'Status', type: 'text' },
      { label: 'Last Attended', type: 'text' },
      { label: 'Enrolled Between', type: 'text' },
      { label: 'Session Attended', type: 'text' }
      // { label: 'Reason for discontinuation', type: 'text' }
    ],
    accessors: [
      'student_name',
      'student_status',
      'last_attended',
      'active_between',
      'session_attended'
    ]
  },
  'student attendance': {
    type: 'DASHBOARD_STUDENT_ATTENDANCE',
    heading: 'Attendance',
    tableHeadings: [
      { label: 'Student Name', type: 'text-link' },
      { label: 'Status', type: 'text' },
      { label: 'Attendance %', type: 'text' },
      { label: 'Expected', type: 'text' },
      { label: 'Attended', type: 'text' },
      // { label: 'Cancelled', type: 'text' },
      { label: 'Last Attended', type: 'text' }
    ],
    accessors: [
      'student_name',
      'student_status',
      'attendance_percentage',
      'expected',
      'attended',
      'last_attended'
    ]
  }
}

export const blocks = [
  {
    type: 'active student',
    data: [],
    slider: true,
    headerData: false
  },
  {
    type: 'dropouts',
    data: [],
    slider: true,
    headerData: false
  },
  {
    type: 'student attendance',
    data: [],
    slider: true,
    headerData: false
  }
]

export const stateData = {
  KA: 'Karnataka',
  WB: 'West Bengal'
}

export const dropoutStatus = {
  DISCONTINUED: 'discontinued',
  TERMINATED: 'terminated',
  LAPSED: 'lapsed'
}

export const DASHBOARD_CARD_TYPES = {
  ACTIVE: 'active',
  DROPOUTS: 'dropouts',
  STUDENT_ATTENDANCE: 'student_attendance'
}
