import styled from 'styled-components';

export const CtaContainer = styled.div`
  text-align: center;
`;

export const ProgressReportContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 30px;
  height: 35px;
  margin-right: 10px;
`;

export const VerticalLine = styled.div`
  min-width: 3px;
  max-width: 3px;
  background-color: #ff6a33;
  margin-right: 10px;
  border-radius: 10px;
  height: 50px;
`;

export const Text = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;
