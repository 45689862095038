import styled, { css } from 'styled-components'

export const CoinRefundContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 10px;
`

export const CoinRefundInnerContainer = styled.div`
  padding: 12px 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`
export const CoinRefundHeadingText = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-left: 9px;
`

export const CoinRefundFirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`

export const CoinRefundSecondRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`

export const CoinRefundLastRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 600;
`

export const CoinPenaltyText = styled.span`
  display: flex;
  align-items: center;
  color: rgba(252, 85, 85, 1);
`
export const CoinContainer = styled.div`
  display: flex;
  align-items: center;
`
export const CoinIcon = styled.img`
  height: 16px;
  margin-right: 4px;
`
