import { makeStyles } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import { FormHelperText } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import { Asset } from './Asset'
import { Container, DragNDrop, Assets } from './styles'
import { useDragNDrop } from './useDragNDrop'

const useStyles = makeStyles((theme) => ({
  error: {
    padding: theme.spacing(1),
    color: '#f44336!important',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontFamily: 'Avenir!important',
    letterSpacing: '0em!important'
  }
}))

const AssetUploadWidget = ({ assetType = 'image', ...props }) => {
  const classes = useStyles()
  const [assets, setAssets] = React.useState(
    Array.isArray(props?.fieldData)
      ? props?.fieldData.map((data, index) => ({
          src: props.newMedia ? data.url : data,
          file: props.newMedia ? data.uuid : data,
          alt: `saved-${assetType}-${index}`
        }))
      : []
  )
  const [error, setError] = React.useState(null)
  const inputRef = React.useRef()
  const dropArea = React.useRef()

  React.useEffect(() => {
    let temp = [...assets]
    temp = [
      ...temp.filter((asset) => !asset.rawFile).map((asset) => asset.file)
    ]
    props.setFieldValue(props.fieldName, temp)
    const errorValidation = props.validate?.(temp)
    setError(errorValidation)
  }, [assets])

  const handleFiles = (files) => {
    const assets = []
    for (let i = 0; i < files.length; i++) {
      assets.push({
        src: URL.createObjectURL(files[i]),
        alt: files[i].name,
        type: files[i].type,
        rawFile: files[i]
      })
    }
    setAssets((prevAssets) => [...prevAssets, ...assets])
  }

  useDragNDrop(dropArea, handleFiles)

  return (
    <Container>
      <h3>{props.label || `Upload ${assetType}(s)`}</h3>
      <div>
        <DragNDrop
          ref={dropArea}
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click()
            }
          }}
        >
          <h4>
            <CloudUpload /> Drag n Drop / Click to add {assetType}(s)
          </h4>
          <input
            ref={inputRef}
            hidden
            accept={`${assetType}/*`}
            multiple
            type="file"
            onChange={({ target: { files } }) => {
              handleFiles(files)
            }}
          />
        </DragNDrop>
        <Assets>
          {assets.map(({ rawFile, ...asset }, index) => {
            return (
              <Asset
                key={index}
                rawFile={rawFile}
                asset={asset}
                assetType={assetType}
                newMedia={props.newMedia}
                onUpload={(newUrl) => {
                  const temp = [...assets]
                  temp[index] = {
                    src: props.newMedia ? newUrl.url : newUrl,
                    file: props.newMedia ? newUrl.uuid : newUrl,
                    alt: `saved-${assetType}-${index}`
                  }
                  setAssets(temp)
                }}
                onDelete={(x) => {
                  const temp = [...assets]
                  temp.splice(index, 1)
                  setAssets(temp)
                }}
              />
            )
          })}
        </Assets>
      </div>
      {error && (
        <FormHelperText error={!!error} className={classes.error}>
          {error}
        </FormHelperText>
      )}
    </Container>
  )
}

AssetUploadWidget.propTypes = {
  assetType: PropTypes.oneOf(['image', 'video']),
  fieldData: PropTypes.array,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  setFieldValue: PropTypes.func,
  validate: PropTypes.func,
  newMedia: PropTypes.bool
}

export default AssetUploadWidget
