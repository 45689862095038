import dayjs from 'dayjs'
import _, { isEmpty } from 'lodash'
import React from 'react'

import {
  getSessions,
  fetchScheduleSuggestion,
  fetchEnrolmentExtensions,
  fetchInteractionHistory,
  fetchTickets
} from 'api'
import { getRatings } from 'api/classroomExperience'
import { fetchJourneyData } from 'api/journey'
import Button from 'app/components/generic/Button'
import constants, {
  SUBSCRIPTION_STATUS_MAP,
  SUBSCRIPTION_STATUSES,
  TICKET_STATUS_OPTIONS
} from 'app/constants'
import { formatCadence, showToast } from 'app/helpers'
import { batchTypesMap } from 'app/pages/Class/Inventory/constants'
import {
  discoveryEnrolmentOptions,
  discoveryEnrolmentReportSubmittedOptions,
  manageEnrolmentOptions
} from 'app/pages/Journey/constants'
import { getLevel, getMilestone } from 'app/pages/Journey/helpers'
import AttendanceIcon from 'assets/images/attendance.svg'
import ChildrenIcon from 'assets/images/children.svg'
import DropoutIcon from 'assets/images/dropout.svg'
import EngagementIcon from 'assets/images/engage.svg'
import EnrolmentIcon from 'assets/images/enrolments.svg'
import InteractionsIcon from 'assets/images/interactions.svg'
import JourneyIcon from 'assets/images/journey.svg'
import LifeTimeIcon from 'assets/images/lifeTimeHealth.svg'
import NegativeIcon from 'assets/images/negative.svg'
import NeutralIcon from 'assets/images/neutral.svg'
import PositiveIcon from 'assets/images/positive.svg'
import RatingsIcon from 'assets/images/ratings.svg'
import RenewalsIcon from 'assets/images/renewals.svg'
import SuggestScheduleIcon from 'assets/images/schedule_suggestions.svg'
import SkippedIcon from 'assets/images/skipped.svg'
import StarIcon from 'assets/images/star.svg'
import TicketIcon from 'assets/images/ticket.svg'
import TrendingDownIcon from 'assets/images/trending_down.svg'
import TrendingFlatIcon from 'assets/images/trending_flat.svg'
import TrendingUpIcon from 'assets/images/trending_up.svg'
import WalletIcon from 'assets/images/wallet.svg'

import * as styles from './styles'

export const getType = (status, type) => {
  switch (status) {
    case 'blocked':
      return 'session reserved'
    case 'debit':
      return 'session completed'
    case 'unblocked':
      return 'session cancelled'
    default:
      return type
  }
}

export const getValue = (initial, current, status) => {
  switch (status) {
    case 'adjustment':
      return initial - current
    case 'debit':
      return initial - current
    case 'blocked':
      return initial - current
    case 'unblocked':
      return initial
    default:
      return initial + current
  }
}

export const getIcon = (type) => {
  let icon = null
  switch (type) {
    case 'enrolments':
      icon = <styles.Icon src={EnrolmentIcon} alt="enrolment-icon" />
      break
    case 'children':
      icon = <styles.Icon src={ChildrenIcon} alt="children-icon" />
      break
    case 'journeys':
      icon = <styles.Icon src={JourneyIcon} alt="children-icon" />
      break
    case 'wallet':
      icon = <styles.Icon src={WalletIcon} alt="wallet-icon" />
      break
    case 'monthly attendance':
      icon = <styles.Icon src={AttendanceIcon} alt="attendance-icon" />
      break
    case 'renewals':
      icon = <styles.Icon src={RenewalsIcon} alt="renewals-icon" />
      break
    case 'classroom experience':
      icon = <styles.Icon src={RatingsIcon} alt="ratings-icon" />
      break
    case 'Schedule Suggestion':
      icon = (
        <styles.Icon src={SuggestScheduleIcon} alt="schedule-suggestion-icon" />
      )
      break
    case 'engagement':
      icon = <styles.Icon src={EngagementIcon} alt="engagement-icon" />
      break
    case 'blocked':
      icon = <styles.Icon src={NegativeIcon} alt="blocked-icon" />
      break
    case 'unblocked':
      icon = <styles.Icon src={NeutralIcon} alt="unblocked-icon" />
      break
    case 'debit':
      icon = <styles.Icon src={NegativeIcon} alt="debit-icon" />
      break
    case 'credit':
      icon = <styles.Icon src={PositiveIcon} alt="credit-icon" />
      break
    case 'trending_up':
      icon = <styles.Icon src={TrendingUpIcon} alt="trending-up-icon" />
      break
    case 'trending_down':
      icon = <styles.Icon src={TrendingDownIcon} alt="trending-down-icon" />
      break
    case 'trending_flat':
      icon = <styles.Icon src={TrendingFlatIcon} alt="trending-flat-icon" />
      break
    case 'dropouts':
      icon = <styles.Icon src={DropoutIcon} alt="dropout-icon" />
      break
    case 'student attendance':
      icon = <styles.Icon src={AttendanceIcon} alt="attendance-icon" />
      break
    case 'lifetime health':
      icon = <styles.Icon src={LifeTimeIcon} alt="life-time-icon" />
      break
    case 'interactions':
      icon = <styles.Icon src={InteractionsIcon} alt="interactions-icon" />
      break
    case 'tickets log':
      icon = <styles.Icon src={TicketIcon} alt="tickets-icon" />
      break
  }
  return icon
}

export const getEnrolmentData = (enrolmentData, modalOpenFunc, setFunc) => {
  const data = enrolmentData?.data?.map((enrolment) => {
    const isProgramEnrolment = enrolment?.program !== undefined
    const isDiscovery = enrolment?.program_enrolment
      ? enrolment?.program_enrolment?.is_discovery
      : enrolment?.is_discovery
    const singleEnrolment = {
      start_date: enrolment?.start_date,
      course_name: isProgramEnrolment
        ? enrolment?.program?.name
        : enrolment?.batch?.course.name,
      batch_timing: isProgramEnrolment ? (
        <styles.Icon src={SkippedIcon} alt="skipped" />
      ) : (
        enrolment?.batch?.cadence
          ?.sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
          .map((item) => {
            const start = item.start_time.split('-').join(':')
            const end = item.end_time.split('-').join(':')
            return `${dayjs()
              .day(item.weekday >= 0 && item.weekday < 7 ? item.weekday + 1 : 7)
              .format('ddd')}, ${dayjs('1/1/1 ' + start).format(
              'h:mma'
            )} - ${dayjs('1/1/1 ' + end).format('h:mma')}`
          })
          .join(' | ')
      ),
      kid: enrolment?.student.user?.full_name,
      isDiscovery: isDiscovery ? 'Yes' : 'No',
      status: enrolment?.status,
      type: isProgramEnrolment
        ? enrolment?.program?.type
        : enrolment?.batch?.type,
      attended: enrolment?.attendances?.filter((el) => el.attended)?.length,
      sessions_attended: isProgramEnrolment ? (
        <styles.Icon src={SkippedIcon} alt="skipped" />
      ) : (
        `${enrolment?.attendances?.filter((el) => el.attended)?.length}/${
          enrolment?.attendances?.length
        }`
      ),
      sessions_absent: isProgramEnrolment ? (
        <styles.Icon src={SkippedIcon} alt="skipped" />
      ) : (
        `${
          enrolment?.attendances?.filter((el) => !el.attended && el.processed)
            ?.length
        }/${enrolment?.attendances?.length}`
      ),
      sessions_canceled: isProgramEnrolment ? (
        <styles.Icon src={SkippedIcon} alt="skipped" />
      ) : (
        `${enrolment?.attendances?.filter((el) => el.cancelled)?.length}/${
          enrolment?.attendances?.length
        }`
      ),
      attendances: enrolment?.attendances,
      uuid: enrolment?.uuid,
      terminated_on: enrolment?.end_date ? enrolment?.end_date : '',
      termination_reason: enrolment?.notes?.replace(/<[^>]+>/g, '') || '',
      enrolment: enrolment
    }
    return {
      ...singleEnrolment,
      actions: isProgramEnrolment ? (
        <styles.Icon src={SkippedIcon} alt="skipped" />
      ) : (
        <Button
          onClick={(e) => {
            setFunc(singleEnrolment)
            modalOpenFunc(e, 'attendance')
          }}>
          Sessions
        </Button>
      )
    }
  })

  return data
}

//TODO: Change this transformer and add new data
export const getWalletData = (data, openModal) => {
  let walletData = data.results.map((wallet) => {
    const rupees_paid = wallet?.total_paid?.rupees || 0
    const coins_paid = wallet?.total_paid?.coins || 0
    const total_paid = rupees_paid + coins_paid
    return {
      //TODO: Add children
      status: wallet?.type || '',
      coins_paid: coins_paid,
      rupees_paid: wallet?.transaction_bifurcation?.rupees?.value || '',
      gst: wallet?.transaction_bifurcation?.rupees?.gst || '',
      total_paid: total_paid,
      event: wallet?.event_display_name || '',
      event_sub_type: wallet?.description || '',
      child: wallet?.student_names || '',
      type:
        wallet?.event == 'opening_balance' ? (
          <div onClick={(e) => openModal(e, 'wallet')}>
            <styles.ButtonLink>{wallet?.event_display_name}</styles.ButtonLink>
          </div>
        ) : (
          wallet?.event_display_name
        ),
      transaction_date: formatDateTime(wallet?.transaction_date),
      transaction_date_sort: wallet?.modified,
      closing_balance: wallet?.closing_balance,
      notes: wallet?.notes
    }
  })

  return walletData
}

export const getWalletv1Data = (data, enrolmentData) => {
  let walletData = data.results.map((wallet) => {
    let enrolment = enrolmentData.data.find((enrolment) => {
      return enrolment.uuid === wallet.enrolment
    })

    let type = getType(wallet?.status, wallet?.type)

    return {
      sessions_attended:
        enrolment &&
        `${enrolment?.attendances?.filter((el) => el.processed)?.length}/${
          enrolment?.attendances?.length
        }`,
      batch_type: enrolment?.batch?.type,
      course: enrolment?.batch?.course.name,
      status_icon: wallet?.status ? (
        <styles.StatusIcon>
          {getIcon(wallet?.status)}
          {wallet?.status}
        </styles.StatusIcon>
      ) : (
        ''
      ),
      status: wallet?.status || '',
      date_range: wallet?.date ? formatDate(wallet?.date) : '',
      start_date: wallet?.date || '',
      end_date: wallet?.date || '',
      value: 0,
      coins: wallet?.value || '',
      count: 1,
      kid: enrolment?.student?.user?.full_name,
      type: type,
      transaction_date: formatDateTime(wallet?.modified),
      transaction_date_sort: wallet?.modified
    }
  })

  const grouped = Object.values(_.groupBy(walletData, 'status'))

  const reduced = grouped?.map((group) => {
    const courseGrouped = _.groupBy(Object.values(group), 'course')

    const kidReduced = Object.values(courseGrouped)?.map((courseGroup) => {
      const kidGrouped = _.groupBy(Object.values(courseGroup), 'kid')
      const combined = Object.values(kidGrouped).map((arr) => {
        let combine = arr
        if (arr[0].course) {
          combine = arr.reduce((prev, current) => {
            return {
              ...current,
              count: current.count + (prev.count || 0),
              start_date: isEmpty(prev.start_date)
                ? current.start_date
                : prev.start_date,
              end_date: current?.end_date,
              date_range: `${formatDate(current.end_date)} - ${
                isEmpty(prev.start_date)
                  ? formatDate(current.start_date)
                  : formatDate(prev.start_date)
              }`
            }
          }, {})
        }
        return combine
      })

      return combined
    })
    return kidReduced
  })

  const result = _.flattenDeep(reduced)

  const sorted = result.sort(compareFunc)
  let currentValue = 0
  let hygieneResult = _.reverse(sorted).map((el, index, array) => {
    currentValue = getValue(currentValue, el.coins * el.count, el.status)
    return {
      ...el,
      coins:
        el.count > 1
          ? `${el.coins} * ${el.count} = ${el.coins * el.count}`
          : el.coins,
      value: currentValue
    }
  })

  return _.reverse(hygieneResult)
}

export const getScheduleSuggestion = async (parentId) => {
  const res = await fetchScheduleSuggestion({ parent: parentId })
  const suggestions = res?.results.map((item) => {
    return {
      course_name: item?.course?.name,
      child: item?.source === 'alfred' ? item?.suggestion.split('**')[2] : 'NA',
      ideal_timings:
        item?.source === 'alfred'
          ? item?.suggestion.split('**')[0]
          : item?.suggestion,
      other_timings:
        item?.source === 'alfred' ? item?.suggestion.split('**')[1] : 'NA',
      source: item?.source
    }
  })
  return suggestions
}

export const getSessionDetails = async (
  enrolmentId,
  parentId,
  queries = {}
) => {
  const data = await getSessions({
    queries: { enrolment: enrolmentId, parent: parentId, ...queries }
  })

  if (!isEmpty(data)) {
    const sessions = data?.results?.sort(sortFunc)?.map((sessions, index) => {
      return {
        date: dayjs(sessions?.schedule?.date).format('DD MMM YY'),
        start_time: dayjs(
          `${sessions?.schedule?.date} ${sessions?.schedule?.start_time}`
        ).format('h:mma'),
        end_time: dayjs(
          `${sessions?.schedule?.date} ${sessions?.schedule?.end_time}`
        ).format('h:mma'),
        attendance: sessions?.cancelled
          ? 'canceled'
          : dayjs(sessions?.schedule?.date).isAfter(dayjs())
          ? 'not started'
          : sessions?.attended
          ? 'attended'
          : 'absent',
        enrolment: sessions?.enrolment,
        cancelled_from: sessions?.cancellation_source || ''
      }
    })
    return sessions
  } else {
    showToast('Oops, Unable to fetch data', constants.ERROR)
    return []
  }
}

export const fetchAttendanceData = async (parentId) => {
  const today = dayjs().format('YYYY-MM-DD')

  const firstDayOfMonth = dayjs().startOf('month').format('YYYY-MM-DD')
  const data = await getSessions({
    queries: {
      parent: parentId,
      date_gte: firstDayOfMonth,
      date_lte: today,
      batch_type_ne: batchTypesMap.explore
    }
  })

  const result = {
    attendedCount: 0,
    uniqueUuidCount: 0,
    attendancePercentage: 0
  }

  const uuidSet = new Set(_.map(data?.results, 'uuid'))

  result.attendedCount = _.countBy(data?.results, 'attended')['true'] || 0
  result.uniqueUuidCount = uuidSet.size

  if (result.uniqueUuidCount > 0) {
    result.attendancePercentage = Math.round(
      (result.attendedCount / result.uniqueUuidCount) * 100
    )
  }

  return {
    ...result,
    hasAttendees: result.uniqueUuidCount > 0
  }
}

export const fetchLifeTimeAttendanceData = async (parentId) => {
  const data = await getSessions({
    queries: {
      parent: parentId,
      batch_type_ne: batchTypesMap.explore
    }
  })

  const result = {
    attendedCount: 0,
    uniqueUuidCount: 0,
    attendancePercentage: 0
  }

  const uuidSet = new Set(_.map(data?.results, 'uuid'))

  result.attendedCount = _.countBy(data?.results, 'attended')['true'] || 0
  result.uniqueUuidCount = uuidSet.size

  if (result.uniqueUuidCount > 0) {
    result.attendancePercentage = Math.round(
      (result.attendedCount / result.uniqueUuidCount) * 100
    )
  }

  return {
    ...result,
    hasAttendees: result.uniqueUuidCount > 0
  }
}

export const getAttendanceSessions = (sessions) => {
  let min = { date: null }
  let max = { date: null }

  const before = sessions?.filter(
    (el) =>
      dayjs(
        `${dayjs(el.date).format('YYYY/MM/DD')} ${dayjs(
          el.start_time,
          'h:mma'
        ).format('HH:mm:ss')}`
      ).isBefore(dayjs(), 'minute') && el.attendance === 'attended'
  )
  const after = sessions?.filter(
    (el) =>
      dayjs(
        `${dayjs(el.date).format('YYYY/MM/DD')} ${dayjs(
          el.start_time,
          'h:mma'
        ).format('HH:mm:ss')}`
      ).isSameOrAfter(dayjs(), 'minute') &&
      el.attendance === 'not started' &&
      el.enrolment?.status !== 'terminated'
  )

  const beforeSession = {
    ...before[before.length - 1],
    date: `${dayjs(before[before.length - 1]?.date)?.format(
      'YYYY/MM/DD'
    )} ${dayjs(before[before.length - 1]?.start_time, 'h:mma').format(
      'HH:mm:ss'
    )}`
  }
  const afterSession = {
    ...after[0],
    date: `${dayjs(after[0]?.date)?.format('YYYY/MM/DD')} ${dayjs(
      after[0]?.start_time,
      'h:mma'
    ).format('HH:mm:ss')}`
  }

  min =
    min.date &&
    beforeSession.date &&
    dayjs(beforeSession.date).isSameOrBefore(dayjs(min.date), 'minute')
      ? min
      : dayjs(beforeSession.date).isValid()
      ? beforeSession
      : min
  max =
    max.date &&
    afterSession.date &&
    dayjs(afterSession.date).isSameOrAfter(dayjs(max.date), 'minute')
      ? max
      : dayjs(afterSession.date).isValid()
      ? afterSession
      : max

  const nextEnrolment = max?.enrolment
  const prevEnrolment = min?.enrolment
  const nextDate = max.date
  const prevDate = min.date
  const nextDays = dayjs(nextDate).diff(dayjs(), 'hour')
  const prevDays = Math.abs(dayjs(prevDate).diff(dayjs(), 'hour'))
  const upcoming = nextDate
    ? dayjs(nextDate).isToday()
      ? `today, ${dayjs(nextDate).format('h:mma')}`
      : `${dayjs(nextDate).format('MMM D, h:mma')} (in ${Math.round(
          nextDays / 24
        )} days)`
    : 'NA'
  const lastAttended = prevDate
    ? dayjs(prevDate).isToday()
      ? `today, ${dayjs(prevDate).format('h:mma')}`
      : `${dayjs(prevDate).format('MMM D, h:mma')} (${Math.round(
          prevDays / 24
        )} days ago)`
    : 'NA'
  const upcomingCourse = dayjs(nextDate).isValid()
    ? `${
        nextEnrolment?.student?.user?.full_name
      } - ${nextEnrolment?.batch?.course?.name?.split('|')[0].trim()}`
    : ''
  const lastAttendedCourse = dayjs(prevDate).isValid()
    ? `${
        prevEnrolment?.student?.user?.full_name
      } - ${prevEnrolment?.batch?.course?.name?.split('|')[0].trim()}`
    : ''

  return {
    upcoming,
    lastAttended,
    upcomingCourse,
    lastAttendedCourse
  }
}

export const getRatingSessions = async (
  parent_uuid,
  enrolmentId,
  modalOpenFunc,
  setFunc,
  enrolmentData
) => {
  const data = await getRatings({
    queries: { parent: parent_uuid, enrolment: enrolmentId }
  })

  const sessionsAttended = enrolmentData.reduce((acc, curr) => {
    if (curr.attended !== undefined) {
      return acc + curr.attended
    }
    return acc
  }, 0)
  if (!isEmpty(data)) {
    const enrolments = _.groupBy(data.results, 'attendance.enrolment.uuid')
    const sessions = Object.values(enrolments)?.map((sessions, index) => {
      const attended = sessions.length
      const rated = sessions.filter((el) => el.rating).length
      const skipped = sessions.filter((el) => el.status === 'skipped').length
      const rating = sessions.reduce((acc, obj) => acc + obj.rating, 0)
      const totalRating =
        Math.round((rating / (rated > 0 ? rated : 1) + Number.EPSILON) * 100) /
        100
      const enrolment = sessions[0]?.attendance?.enrolment

      const singleEnrolment = {
        date: dayjs(sessions[0]?.attendance?.schedule?.date).format(
          'DD MMM YY'
        ),
        start_time: dayjs(
          `${sessions[0]?.attendance?.schedule?.date} ${sessions[0]?.attendance?.schedule?.start_time}`
        ).format('H:mma'),
        end_time: dayjs(
          `${sessions[0]?.attendance?.schedule?.date} ${sessions[0]?.attendance?.schedule?.end_time}`
        ).format('H:mma'),
        attended: attended ? attended : 0,
        sessionsAttended: sessionsAttended,
        rated: rated,
        skipped: skipped,
        totalRating: rating,
        totalRatingComponent:
          totalRating === 0 ? (
            <styles.Icon src={SkippedIcon} alt="skipped" />
          ) : (
            <styles.RowFlex>
              <styles.StarIcon src={StarIcon} alt="star" />
              <styles.Content subheading type="dark">
                {Math.round((totalRating + Number.EPSILON) * 100) / 100}
              </styles.Content>
            </styles.RowFlex>
          ),
        enrolment: enrolment,
        course_name: enrolment?.batch?.course?.name,
        kid: enrolment?.student?.user?.full_name,
        teacher: enrolment?.batch?.teacher?.full_name,
        status: enrolment?.status,
        type: enrolment?.type,
        uuid: enrolment?.uuid,
        sessions: sessions,
        schedule: enrolment?.schedule
      }
      return {
        ...singleEnrolment,
        actions: (
          <Button
            onClick={(e) => {
              setFunc(singleEnrolment)
              modalOpenFunc(e, 'classroom experience')
            }}>
            Sessions
          </Button>
        )
      }
    })
    return sessions
  } else {
    showToast('Oops, Unable to fetch data', constants.ERROR)
    return []
  }
}

export const getRatingData = (classroomExperienceData) => {
  const overall = classroomExperienceData.reduce(
    (acc, obj) => acc + obj?.totalRating,
    0
  )
  const rated = classroomExperienceData.reduce(
    (acc, obj) => acc + obj?.rated,
    0
  )
  const attended = classroomExperienceData.reduce(
    (acc, obj) => acc + obj?.attended,
    0
  )
  const overallRating =
    Math.round((overall / (rated > 0 ? rated : 1) + Number.EPSILON) * 100) / 100
  const percentage =
    Math.round(((rated / attended) * 100 + Number.EPSILON) * 100) / 100

  const activeClasses = classroomExperienceData.filter(
    (el) => el.enrolment.status === 'active'
  )
  const uniqueEnrolments = _.uniqBy(activeClasses, 'enrolment.uuid')
  const hours = uniqueEnrolments?.reduce(
    (acc, cadence) =>
      acc +
      dayjs(
        `1/1/1 ${dayjs(cadence?.end_time, 'HH:mma').format('HH:mm:ss')}`
      ).diff(
        dayjs(
          `1/1/1 ${dayjs(cadence?.start_time, 'HH:mma').format('HH:mm:ss')}`
        ),
        'minute'
      ),
    0
  )

  return {
    overallRating,
    rated,
    attended: attended ? attended : 0,
    hours: hours / 60,
    percentage
  }
}

export const formatDate = (date) => {
  return dayjs(date).format('D MMM, YY')
}

export const formatDateTime = (date) => {
  return dayjs(date).format('D MMM YYYY H:mma')
}

export const compareFunc = (a, b) => {
  const first = dayjs(a.transaction_date_sort)
  const second = dayjs(b.transaction_date_sort)
  if (first.diff(second) < 0) {
    return 1
  }
  if (first.diff(second) > 0) {
    return -1
  }
  // a must be equal to b
  return 0
}

const sortFunc = (a, b) => {
  const aTime = `${a?.schedule?.date} ${a?.schedule?.start_time}`
  const bTime = `${b?.schedule?.date} ${b?.schedule?.start_time}`
  if (dayjs(aTime).isBefore(dayjs(bTime))) return -1
  if (dayjs(aTime).isAfter(dayjs(bTime))) return 1
  return 0
}

const sortingFunc = (a, b) => {
  const aTime = `${a?.created}`
  const bTime = `${b?.created}`
  if (dayjs(aTime).isBefore(dayjs(bTime))) return 1
  if (dayjs(aTime).isAfter(dayjs(bTime))) return -1
  return 0
}

const sortingComapre = (a, b) => {
  const aTime = `${a?.end_date}`
  const bTime = `${b?.end_date}`
  if (dayjs(aTime).isBefore(dayjs(bTime))) return 1
  if (dayjs(aTime).isAfter(dayjs(bTime))) return -1
  return 0
}

export const sortCompare = (a, b) => {
  const aTime = `${a?.invoice_date}`
  const bTime = `${b?.invoice_date}`
  if (dayjs(aTime).isBefore(dayjs(bTime))) return -1
  if (dayjs(aTime).isAfter(dayjs(bTime))) return 1
  return 0
}

export const getEnrolmentExtensions = async (parentId, enrolmentData) => {
  const res = await fetchEnrolmentExtensions({
    url: null,
    queries: { user: parentId }
  })

  const extensionData = res?.results

  let extensionsLeft = extensionData?.filter((el) =>
    ['pending'].includes(el.status)
  )
  let extensionsDone = extensionData?.filter((el) =>
    ['completed'].includes(el.status)
  )

  const extensionsRemaining = extensionsLeft.map((action) => {
    const enrolment = action?.action_detail?.enrolment
    return {
      ...enrolment,
      sessions_remaining: enrolmentData
        ?.find((el) => el.uuid === enrolment.uuid)
        ?.attendances?.filter(
          (el) => !el.processed && !el.cancelled && !el.deleted
        ).length
    }
  })

  return {
    extensionsRemaining,
    extensionsDone
  }
}

export const getInteractionsData = async (phone_number) => {
  const res = await fetchInteractionHistory({
    queries: { parent_phone_number: phone_number }
  })

  const dateSort = (a, b) => {
    const dateA = new Date(a?.interaction_date)
    const dateB = new Date(b?.interaction_date)
    return dateB - dateA
  }
  //Add a last interaction date key that compares all interaction dates and selects the one with the latest date
  const sortedResults = res?.results?.sort(dateSort)
  return sortedResults?.map((item) => {
    return {
      latest_interaction_date: sortedResults?.[0]?.interaction_date,
      latest_interaction_summary: sortedResults?.[0]?.interaction_topics,
      interaction_date: item?.interaction_date,
      interaction_topics_list: item?.interaction_topics_list,
      interaction_topics: item?.interaction_topics,
      interaction_channel: item?.interaction_mode,
      created_by: item?.created_by?.full_name
    }
  })
}

export const getTicketsData = async (phone_number, openModalForm) => {
  const res = await fetchTickets({
    queries: { user_phone_number: phone_number }
  })

  const statusDateSort = (a, b) => {
    const dateA = new Date(a.reported_date)
    const dateB = new Date(b.reported_date)
    return dateB - dateA
  }
  //Add a last interaction date key that compares all reported dates and selects the one with the latest date
  const sortedResults = res?.results?.sort(statusDateSort)
  return sortedResults?.map((item) => ({
    reported_date: item?.created,
    ticket_status: item?.status,
    ticket_type: item?.type,
    ticket_description: item?.description,
    raised_on: item?.source,
    created_by: item?.created_by?.full_name,
    follow_up_date: item?.follow_up_date,
    actions:
      item?.status !== TICKET_STATUS_OPTIONS.RESOLVED ? (
        <Button
          onClick={(e) => {
            openModalForm({
              event: e,
              action: 'edit_ticket_status',
              actionData: item
            })
          }}>
          edit
        </Button>
      ) : null
  }))
}

const groupByStudentId = (results) => {
  return _.chain(results)
    .groupBy((item) => item?.student?.user?.uuid)
    .map((value, key) => ({
      uuid: value[0]?.student?.user?.uuid,
      name: value[0]?.student?.user?.full_name,
      journeys: value.map((item) => ({
        uuid: item?.uuid,
        course_name: item?.active_enrolment?.batch?.course?.name,
        status: item?.status,
        milestone: getMilestone(item?.current_milestone),
        level: getLevel(item?.current_level),
        progress_enabled: item?.subject?.progress_enabled,

        subject_name: item?.subject?.name,
        subject_uuid: item?.subject?.uuid,
        batch_uuid: item?.active_enrolment?.batch?.uuid,
        timings: item?.active_enrolment?.batch?.timings_humanized,
        student_name: item?.student?.user?.full_name,
        student_uuid: item?.student?.user?.uuid,
        discovery_enabled: item?.subject?.discovery_enabled,
        teacher_name: item?.active_enrolment?.batch?.teacher?.user?.full_name,

        current_subscription_status: item?.current_subscription_status,
        current_subscription_status_display_name:
          item?.current_subscription_status_display_name,
        upcoming_subscription_status: item?.upcoming_subscription_status,
        next_status_change_at: item?.next_status_change_at,

        is_discovery_successful: item?.is_discovery_successful,
        dropdown_options:
          item?.current_subscription_status ===
            SUBSCRIPTION_STATUSES.DISCOVERING ||
          item?.current_subscription_status ===
            SUBSCRIPTION_STATUSES.DISCOVERY_UPCOMING ||
          item?.current_subscription_status === SUBSCRIPTION_STATUSES.DISCOVERED
            ? item?.is_discovery_successful == null
              ? discoveryEnrolmentOptions
              : discoveryEnrolmentReportSubmittedOptions
            : manageEnrolmentOptions
      }))
    }))
    .value()
}

export const getJourneyData = async (parent_uuid) => {
  const data = await fetchJourneyData({
    queries: { parent: parent_uuid }
  })
  const results = data?.results

  const statusCount = [
    {
      heading: 'active',
      count: results?.filter(
        (item) =>
          !SUBSCRIPTION_STATUS_MAP.inactive.includes(
            item?.current_subscription_status
          ) &&
          !SUBSCRIPTION_STATUS_MAP.upcoming.includes(
            item?.current_subscription_status
          ) //not inactive and not upcoming
      ).length
    },
    {
      heading: 'upcoming',
      count: results?.filter((item) =>
        SUBSCRIPTION_STATUS_MAP.upcoming.includes(
          item?.current_subscription_status
        )
      ).length
    },
    {
      heading: 'inactive',
      count: results?.filter((item) =>
        SUBSCRIPTION_STATUS_MAP.inactive.includes(
          item?.current_subscription_status
        )
      ).length
    }
  ]

  const students = groupByStudentId(results)

  const parent =
    results?.length > 0
      ? {
          uuid: results[0]?.student?.parent?.user?.uuid,
          name: results[0]?.student?.parent?.user?.full_name,
          number: results[0]?.student?.parent?.user?.phone_number
        }
      : { uuid: null, name: null, number: null }

  return {
    status_count: statusCount,
    parent: parent,
    students: students,
    isClickable: !isEmpty(students) ? true : false,
    isQueryParams: !isEmpty(students) ? true : false,
    queryParams: !isEmpty(students) ? { parent: parent?.uuid } : null
  }
}
