import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { validateReferral } from 'api'
import ModalForm from 'app/components/ModalForm'
import constants, { TICKET_STATUS_OPTIONS } from 'app/constants'
import { eventEmitter, canAddReferralCode } from 'app/helpers'

import CancelForm from './forms/CancelForm'
import ChangePhoneNumber from './forms/ChangePhoneNumber'
import ChangePlanForm from './forms/ChangePlanForm'
import DeductForm from './forms/DeductForm'
import EditTicketStatusForm from './forms/EditTicketStatusForm'
import Enrolment from './forms/Enrolment'
import ExtendSubscriptionForm from './forms/ExtendSubscriptionForm'
import ReasonSubmitForm from './forms/ReasonSubmitForm'
import RenewNowForm from './forms/RenewNowForm'
import SuggestSchedule from './forms/SuggestSchedule'
import TopupForm from './forms/TopupForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const MasterForm = (props) => {
  const [formData, setFormData] = React.useState({
    uuid: '',
    addCoinsType: {},
    coins: '',
    list_price: '',
    discount_percentage: '',
    actual_amount: '',
    gst_amount: '',
    reason: '',
    notes: '',
    end_date: '',
    plan: '',
    current_balance: '',
    new_balance: '',
    remaining_coins: '',
    parent_name: '',
    parent_number: '',
    renewal_amount: '',
    subscription: '',
    process_refund: 'false',
    cancel_type: 'pause',
    follow_up_date: '',
    zero_balance: 'false',
    course: '',
    ideal_timings: '',
    other_timings: '',
    child_name: '',
    scheduleSuggestions: [
      {
        course: '',
        ideal_timings: '',
        other_timings: '',
        child_name: ''
      }
    ],
    students: {},
    new_phone_number: '',
    deletion_reason: '',
    program: '',
    duration: '',
    time_slot: '',
    centre: {},
    program_fee: 0,
    referral_code: '',
    use_referral_code: false,
    has_referred_by: false,
    is_customer: false,
    is_policy_acknowledged_alfred: false,
    policy_toggle_check: false,
    is_customer: false,
    status: TICKET_STATUS_OPTIONS.TO_BE_PICKED,
    resolution_summary: '',
    reason_for_using_alfred: '',
    coin_summary: {},
    payment_summary: {},
    admission_fee: 0,
    monthly_consumption: 0,
    show_reason_field: true
  })

  const classes = useStyles()
  const formRef = React.useRef(null)

  const {
    isModalOpen,
    closeModal,
    onSaveClick,
    action,
    data,
    editIndex,
    extraFormData,
    buttonLabel
  } = props

  const coin_summary = data?.user?.coin_summary

  React.useEffect(() => {
    const user_data = data?.user
    const active_subscription = user_data?.active_subscription
    const current_cycle = active_subscription?.current_cycle
    const plan = active_subscription?.plan
    const coin_summary = user_data?.coin_summary
    const payment_summary = data?.payment_summary || {}

    setFormData((formData) => ({
      ...formData,
      uuid: user_data?.uuid,
      end_date: current_cycle?.end_date,
      plan: plan?.uuid,
      current_balance: coin_summary?.remaining_coins,
      remaining_coins:
        coin_summary?.remaining_coins + coin_summary?.blocked_coins,
      parent_name: `${user_data?.first_name} ${user_data?.last_name}`,
      parent_number: user_data?.phone_number,
      renewal_amount: plan?.amount,
      subscription: active_subscription?.uuid,
      students: data?.students,
      centre: data?.centre,
      is_customer: !isEmpty(data?.customer_start_date), // customer_start_data is null then the user has never became a customer
      has_referred_by: !isEmpty(user_data?.referred_by),
      is_policy_acknowledged_alfred: data?.is_policy_acknowledged_alfred,
      policy_toggle_check: false,
      user_status: user_data?.status,
      is_customer: !isEmpty(data?.customer_start_date), // customer_start_data is null then the user has never became a customer
      has_referred_by: !isEmpty(user_data?.referred_by),
      reason_for_using_alfred: data?.reason_for_using_alfred || '',

      //checkoutModal extradata
      coin_summary: data?.coin_summary || {},
      payment_summary: data?.payment_summary || {},
      admission_fee: data?.payment_summary?.admission_fee || 0,
      monthly_consumption: data?.coin_summary?.monthly_consumption || 0,
      show_reason_field: data?.show_reason_field ?? true
    }))
  }, [data, editIndex])

  React.useEffect(() => {
    if (action === 'edit_ticket_status') {
      setFormData((formData) => ({
        ...formData,
        ticketId: extraFormData?.uuid,
        status: extraFormData?.status,
        resolution_summary: extraFormData?.resolution_summary,
        follow_up_date: extraFormData?.follow_up_date
      }))
    }
  }, [extraFormData])

  const handleOnSaveClick = React.useCallback(
    async (type = null) => {
      if (formRef.current) {
        const { submitForm, validateForm, values, setFieldError } =
          formRef.current
        try {
          const errors = await validateForm(values)
          if (!isEmpty(errors)) {
            // call submit form , it will ask to validate all fields
            submitForm()
            // emit event to enable save button again
            eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
            if (type === 'program') return true
          } else {
            // form is valid
            if (type === 'program') return false
            else {
              // Check ReferralCode validity in case of Topup form
              if (
                action === 'topup' &&
                canAddReferralCode(values) &&
                values.use_referral_code
              ) {
                try {
                  await validateReferral({
                    user: values?.uuid,
                    referral_code: values.referral_code
                  })
                } catch (error) {
                  console.log('validateReferral :- ', error)
                  setFieldError('referral_code', error?.message)
                  eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
                  return false
                }
              }

              try {
                await onSaveClick(values, action)
              } catch (error) {
                console.log(error)
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    [onSaveClick, action]
  )

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}>
        {({}) => {
          return (
            <Form>
              <FormControl className={classes.formControl} fullWidth>
                {action === 'topup' ? (
                  <TopupForm
                    coinSummary={props?.coinSummary ?? coin_summary}
                    formData={formData}
                    cartType={props?.cartType ?? 'recharge'}
                  />
                ) : action === 'extend' ? (
                  <ExtendSubscriptionForm formData={formData} />
                ) : action === 'deduct' ? (
                  <DeductForm formData={formData} />
                ) : action === 'change_plan' ? (
                  <ChangePlanForm formData={formData} />
                ) : action === 'cancel' ? (
                  <CancelForm formData={formData} />
                ) : action === 'renew_now' ? (
                  <RenewNowForm formData={formData} />
                ) : action === 'suggest_schedule' ? (
                  <SuggestSchedule
                    formData={formData}
                    setFormData={setFormData}
                  />
                ) : action === 'change_phone_number' ? (
                  <ChangePhoneNumber formData={formData} />
                ) : action === 'parent_number_deletion_request' ? (
                  <ReasonSubmitForm formData={formData} />
                ) : action === 'edit_ticket_status' ? (
                  <EditTicketStatusForm formData={formData} />
                ) : null}
              </FormControl>
            </Form>
          )
        }}
      </Formik>
    )
  }, [action, classes, formData])

  return action === 'enrolment' ? (
    <Enrolment
      formData={formData}
      onSaveClick={handleOnSaveClick}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      formRef={formRef}
    />
  ) : (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}
      buttonLabel={buttonLabel}>
      {formElement}
    </ModalForm>
  )
}

MasterForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default MasterForm
