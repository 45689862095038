import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2em;
`

export const FormContainer = styled.div``

export const Heading = styled(Typography)`
  font-size: 1.1em;
  line-height: 1;
  margin: 2em 0 1em 0;
`

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
`

export const LoadingContainer = styled.div`
  margin: 1em 0;
`

export const Result = styled.h4`
  text-align: right;
  font-weight: bold;
`

export const LoadingButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
