import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import dayjs from 'dayjs'
import LocaleData from 'dayjs/plugin/localeData'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import React from 'react'

import { TextField } from 'app/components/generic/FormElements'
import { getWidgetTypeName } from 'app/config'
import { validator } from 'app/validators'

dayjs.extend(LocaleData)
dayjs.extend(LocalizedFormat)

const ExtendSubscriptionForm = (props) => {
  const { formData } = props
  const { errors } = useFormikContext()
  const prevValue = formData.end_date

  const validateEndDate =
    ({ isRequired } = {}) =>
    (value) => {
      return validator(isRequired, value, () => {
        if (!isNil(value) && typeof value !== 'string') {
          value = value.toString()
        }
        if (
          (typeof value === 'string' &&
            (isEmpty(value) || value === '<p></p>/n')) ||
          dayjs(value).isBefore(dayjs(prevValue))
        ) {
          return 'Please enter a valid value'
        }
      })
    }

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.end_date) ? { error: true } : null)}
        required
      >
        <TextField
          name={'end_date'}
          label={`End Date`}
          type={getWidgetTypeName('datepicker')}
          variant="outlined"
          required
          validate={validateEndDate({ isRequired: true })}
          InputLabelProps={{ shrink: true }}
        />
        {!isEmpty(errors.end_date) && (
          <FormHelperText>{errors.end_date}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default ExtendSubscriptionForm
