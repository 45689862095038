import React from 'react'
import * as styles from './styles'
import AvatarTitleDescription from 'app/components/generic/AvatarTitleDescription'
import UnreadCount from 'app/components/generic/UnreadCount'

const TeacherListCard = (props) => {

  const {
    uuid,
    thumbnailUrl,
    name,
    centres,
    categories,
    unreadCount,
    color,
    onClick,
    isSelected,
    lastListItem
  } = props

  return (
    <div onClick={onClick}>
      <styles.SelectedCardContainer isSelected={isSelected} lastListItem={lastListItem}>
        <styles.InnerCardContainer>
          <AvatarTitleDescription
            isTitleClickable={false}
            uuid={uuid}
            thumbnailUrl={thumbnailUrl}
            name={name}
            centres={centres}
            categories={categories}
            unreadCount={unreadCount}
          />
          <UnreadCount unreadCount={unreadCount} />
        </styles.InnerCardContainer>
      </styles.SelectedCardContainer>
    </div>
  )
}

export default TeacherListCard