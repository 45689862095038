import { FormHelperText, Typography } from '@material-ui/core'
import { styled as muiStyled } from '@mui/material/styles'
import styled, { css } from 'styled-components'

import { LoadingButton as LoadingButtonComponent } from 'app/components/generic/LoadingButton'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`
export const FormContainer = styled.div`
  min-width: 100%;
`
export const StepperWrapper = styled.div`
  margin-bottom: 10px;
`
export const ConfirmModalWrapper = styled.div`
  display: flex;
`
export const MessageWrapper = styled.div`
  margin-right: 100px;
  font-style: oblique;
  font-weight: 600;
`

export const Heading = styled(Typography)`
  font-size: 1.5em;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 2em;
`

export const NoTemplate = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 8, 0.5);
  text-align: right;
  span {
    color: #228bec;
    cursor: pointer;
  }
`

export const TotalUsers = styled.div`
  font-size: 1.1em;
  line-height: 1;
  font-weight: bold;
  margin-right: 1em;
`

export const LoadingButton = muiStyled(LoadingButtonComponent)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  textTransform: 'lowercase',
  '&:hover': {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#d5d5d5'
  }
}))

export const NextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const Message = styled(FormHelperText)`
  margin-left: 10px;
  margin-bottom: 10px;
  color: black;
  font-size: 0.9rem;
  font-family: Avenir;
`

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
`

export const Title = styled.h2`
  margin-bottom: 20px;
`

export const CartValue = styled.div`
  background-color: var(--oh-light-cream);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  font-family: AvenirDemi;
`
export const Subheading = styled.div`
  font-family: AvenirDemi;
  font-size: 16px;
  margin-bottom: 16px;
`
export const PaymentSummary = styled.div`
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`

export const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

export const SummaryItemEnd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`

export const TotalPayable = styled(SummaryItem)`
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 10px;
`

export const ActionButton = styled.button`
  background-color: var(--oh-orange);
  font-family: AvenirDemi;
  font-size: 18px;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 24px;
  width: 100%;
  cursor: pointer;
`

export const CoinImageContainer = styled.div`
  height: 20px;
  width: 20px;
`

// export const TotalText = styled.span``

export const TotalAmount = styled.span`
  color: var(--oh-orange);
  border-radius: 24px;
  border: 1px solid var(--oh-orange);
  padding: 2px 8px;
  display: flex;
  align-items: center;
`

export const CoinImg = styled.img`
  margin-top: -1px;
  margin-right: 2px;
  height: 16px;
  width: 16px;
`
export const CoinsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`

export const ActionsContainer = styled.div`
  margin-top: auto;
`
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Separator = styled.div`
  margin-top: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 6px;
`
