import PropTypes from 'prop-types'
import React from 'react'

import { getGroupedResult } from 'app/utils'

import SubjectCard from './SubjectCard'

const SubjectResult = (props) => {
  const { batch, onSubjectClick } = props

  const [subjects, setSubjects] = React.useState({})

  React.useEffect(() => {
    const result = getGroupedResult(batch, 'course.subject.name')
    setSubjects(result)
  }, [batch])

  const subjectElement = React.useMemo(() => {
    return Object.keys(subjects).map((key, index) => {
      return (
        <SubjectCard
          key={index}
          subjectName={key}
          subjectDetails={subjects[key]}
          onSubjectClick={onSubjectClick}
        />
      )
    })
  }, [subjects, onSubjectClick])

  return subjectElement
}

SubjectResult.propTypes = {
  batch: PropTypes.array.isRequired,
  onSubjectClick: PropTypes.func.isRequired
}

export default SubjectResult
