import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchAdminData, updateUserData } from 'app/store/actions/userEntity'

import { columns } from './config'

const Admin = () => {
  const adminData = useSelector((state) => state.userEntityReducer.adminData)

  return (
    <BaseEntity
      entity={constants.ADMIN}
      label={constants.ADMIN_LABEL}
      columns={columns}
      data={adminData}
      fetchData={fetchAdminData}
      updateEntityData={updateUserData}
      disableDelete
    />
  )
}

export default Admin
