import dayjs from 'dayjs'
import { isEmpty, orderBy } from 'lodash'

import {
  fetchStates,
  fetchStudentsListData,
  fetchTeacherDashboardData
} from 'api'
import { navigationPaths } from 'app/config'

import { dropoutStatus, DASHBOARD_CARD_TYPES } from './config'

import { formatDate } from '../ParentDashboard/helpers'

export const getTeacherDashboardData = async (teacherId, count) => {
  const queries = count
    ? {
        start_date: dayjs().subtract(count, 'day').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD')
      }
    : null

  const res = await fetchTeacherDashboardData({
    url: null,
    teacherId: teacherId,
    queries: queries
  })
  const resultStudentsData = res?.results?.data?.students
  const resultAttenndanceData = res?.results?.data?.students?.attendance
  const rangeTeacherDashboardData = {
    activeStudents: resultStudentsData?.active,
    dropouts: resultStudentsData?.discontinued,
    expected: resultAttenndanceData?.expected,
    attended: resultAttenndanceData?.attended
  }
  return rangeTeacherDashboardData
}

export const getTeacherState = async (stateCode) => {
  const res = await fetchStates({
    url: null,
    queries: { code: stateCode }
  })
  const teacherState = res?.results[0]?.name

  return teacherState
}

const sortingHandler = (studentList) => {
  let students = []
  const nameComparator = function (studentList) {
    return studentList.student_name.text
  }
  const lastAttendedComparator = function (studentList) {
    const lastAttended = studentList.last_attended || '1999-07-30' //'1999-07-30'= minDate for cases where data is null
    return new Date(lastAttended)
  }

  switch (studentList.card_type) {
    case DASHBOARD_CARD_TYPES.STUDENT_ATTENDANCE:
      students = orderBy(
        studentList,
        [
          function (studentList) {
            return studentList.attendance_percentage
          },
          lastAttendedComparator,
          nameComparator
        ],
        ['asc', 'desc', 'asc']
      )
      break

    default:
      students = orderBy(
        studentList,
        [lastAttendedComparator, nameComparator],
        ['desc', 'asc']
      )
      break
  }

  return students
}

export const fetchActiveStudentsData = async (
  teacherId,
  overallStudentsData
) => {
  if (!isEmpty(teacherId)) {
    const res = await fetchStudentsListData({
      url: null,
      teacherId: teacherId,
      queries: {
        status: 'active'
      }
    })
    const studentsList = res.results?.data?.map((element) => {
      const studentData = element?.student
      const status = element?.status
      return {
        student_name: {
          text: studentData?.user?.full_name,
          link: `${navigationPaths.parentdashboard}?parent=${studentData?.parent?.user_id}&openSlider=true&type=children&key=uuid&value=${studentData?.user_id}`
        },
        student_status: status,
        last_attended:
          status === 'active'
            ? element?.attendance?.latest
              ? formatDate(element?.attendance?.latest)
              : ''
            : 'NA',
        active_between:
          status === 'active'
            ? element?.attendance?.earliest
              ? `${formatDate(element?.attendance?.earliest)} - present`
              : ''
            : `starting from ${formatDate(
                element?.attendance?.firstSessionBooked
              )}`,
        session_attended: element?.attendance?.attended,
        card_type: DASHBOARD_CARD_TYPES.ACTIVE
      }
    })
    const activeStudentsData = sortingHandler(studentsList)

    const activeStudentsCount = overallStudentsData?.activeStudents

    return { activeStudentsCount, activeStudentsData }
  }
}

export const fetchDropoutStudentsData = async (
  teacherId,
  overallStudentsData,
  rangeStudentsData
) => {
  if (!isEmpty(teacherId)) {
    const res = await fetchStudentsListData({
      url: null,
      teacherId: teacherId,
      queries: {
        status: 'discontinued'
      }
    })
    const studentsList = res.results?.data?.map((element) => {
      const studentData = element?.student
      return {
        student_name: {
          text: studentData?.user?.full_name,
          link: `${navigationPaths.parentdashboard}?parent=${studentData?.parent?.user_id}&openSlider=true&type=children&key=uuid&value=${studentData?.user_id}`
        },
        student_status:
          element?.status === dropoutStatus?.TERMINATED
            ? dropoutStatus?.DISCONTINUED
            : element?.status,
        last_attended: element?.attendance?.latest
          ? formatDate(element?.attendance?.latest)
          : '',
        active_between:
          element?.attendance?.earliest && element?.attendance?.latest
            ? `${formatDate(element?.attendance?.earliest)} - ${formatDate(
                element?.attendance?.latest
              )}`
            : '',
        session_attended: element?.attendance?.attended,
        card_type: DASHBOARD_CARD_TYPES.DROPOUTS
      }
    })

    const overallDropoutStudentsCount = overallStudentsData?.dropouts
    const rangeDropoutStudentsCount = rangeStudentsData?.dropouts
    const dropoutStudentsData = sortingHandler(studentsList)
    return {
      overallDropoutStudentsCount,
      rangeDropoutStudentsCount,
      dropoutStudentsData
    }
  }
}

export const fetchStudentAttendanceData = async (
  teacherId,
  overallStudentsData
) => {
  if (!isEmpty(teacherId)) {
    const res = await fetchStudentsListData({
      url: null,
      teacherId: teacherId,
      queries: {
        status: 'active'
      }
    })
    const studentsList = res.results?.data?.map((element) => {
      const studentData = element?.student
      return {
        student_name: {
          text: studentData?.user?.full_name,
          link: `${navigationPaths.parentdashboard}?parent=${studentData?.parent?.user_id}&openSlider=true&type=children&key=uuid&value=${studentData?.user_id}`
        },
        student_status: element?.status,
        attendance_percentage:
          element?.attendance?.attended && element?.attendance?.expected
            ? Math.floor(
                (100 * element?.attendance?.attended) /
                  element?.attendance?.expected
              )
            : 0,
        expected: element?.attendance?.expected,
        attended: element?.attendance?.attended,
        last_attended: element?.attendance?.latest
          ? formatDate(element?.attendance?.latest)
          : '',
        card_type: DASHBOARD_CARD_TYPES.STUDENT_ATTENDANCE
      }
    })

    const studentsExpectedCount = overallStudentsData?.expected
    const studentsAttendedCount = overallStudentsData?.attended
    const studentsData = sortingHandler(studentsList)
    return {
      studentsExpectedCount,
      studentsAttendedCount,
      studentsData
    }
  }
}
