/* eslint-disable react/prop-types */
import React from 'react'

import ExtraDetails from './component/ExtraDetails'

const CoursesDetail = ({
  cartItems = [],
  isCheckoutMode,
  deselectBatch,
  bookingType,
  onEditClick,
  batchInfo,
  onBatchInfoChange,
  selectedBatchType,
  showEditOptions,
  errorSchema
}) => {
  return (
    <>
      {cartItems?.map((selectedBatch, index) => {
        return (
          <ExtraDetails
            key={index}
            cartItems={cartItems}
            isCheckoutMode={isCheckoutMode}
            deselectBatch={deselectBatch}
            bookingType={bookingType}
            onEditClick={onEditClick}
            batchInfo={batchInfo}
            index={index}
            selectedBatch={selectedBatch}
            onBatchInfoChange={onBatchInfoChange}
            selectedBatchType={selectedBatchType}
            showEditOptions={showEditOptions}
            errorSchema={errorSchema}
          />
        )
      })}
    </>
  )
}

export default CoursesDetail
