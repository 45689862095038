import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { pluralizeString } from '@openhouse-technologies/utils'
import React from 'react'
import { useSelector } from 'react-redux'

import Loading from 'app/components/generic/Loading'
import ModalForm from 'app/components/ModalForm'
import { tableColumnDefaultOption } from 'app/config'
import { dispatchUpdateDeliveryStatsModalState } from 'app/store/dispatcher'
import {
  selectIsDeliveryStatsModalOpen,
  selectDeliveryType,
  selectDeliveryStatsData
} from 'app/store/selectors'

import { columns } from './config'
import * as Styles from './styles'

import {
  tableStyleOverridesOption,
  deliveryStatuses,
  deliveryTypes
} from '../../config'
import { Table } from '../Table'

const getMuiTheme = () =>
  createTheme({
    overrides: {
      ...tableStyleOverridesOption,
      MuiTableCell: {
        ...tableStyleOverridesOption.MuiTableCell,
        head: {
          backgroundColor: '#fff !important',
          color: '#6E7995'
        },
        root: {
          ...tableStyleOverridesOption.MuiTableCell.root,
          width: 'auto',
          textAlign: 'left'
        }
      }
    }
  })

const DeliveryStats = () => {
  const isDeliveryStatsModalOpen = useSelector(selectIsDeliveryStatsModalOpen)
  const deliveryType = useSelector(selectDeliveryType)
  const { isLoading, isLoaded, data } = useSelector(selectDeliveryStatsData)

  const onModalClose = () => {
    dispatchUpdateDeliveryStatsModalState(false)
  }

  const modifiedColumns = [
    ...columns,
    ...(deliveryType === deliveryTypes.FAILED
      ? [
          {
            name: 'reason',
            label: 'Reason for failure',
            ...tableColumnDefaultOption
          }
        ]
      : []),
    ...(deliveryType === deliveryTypes.DELIVERED ||
    deliveryType === deliveryTypes.PENDING
      ? [
          {
            name: 'status',
            label: 'Status',
            ...tableColumnDefaultOption
          }
        ]
      : [])
  ]

  const filteredData = Array.isArray(data)
    ? data.filter((item) =>
        deliveryStatuses[deliveryType].includes(item.status)
      )
    : []

  return (
    <ModalForm
      isModalOpen={isDeliveryStatsModalOpen}
      enableSave={false}
      showCloseIcon
      disableBackdropClick
      onModalClose={onModalClose}
      modalCss={`
        .MuiDialogContent-root {
            overflow-y: unset;
        }
      `}
    >
      <Styles.Container>
        {isLoading && !isLoaded ? (
          <Loading />
        ) : (
          <>
            <Styles.Heading>
              {deliveryType}{' '}
              {pluralizeString('Message', filteredData.length, false)}
            </Styles.Heading>
            <ThemeProvider theme={getMuiTheme()}>
              <Table title={''} data={filteredData} columns={modifiedColumns} />
            </ThemeProvider>
          </>
        )}
      </Styles.Container>
    </ModalForm>
  )
}

export { DeliveryStats }
