export const FETCH_MOCK_PAPER_DATA = 'FETCH_MOCK_PAPER_DATA'
export const FETCH_MOCK_PAPER_DATA_SUCCESS = 'FETCH_MOCK_PAPER_DATA_SUCCESS'

export const UPDATE_MOCK_PAPER_DATA = 'UPDATE_MOCK_PAPER_DATA'
export const UPDATE_MOCK_PAPER_DATA_SUCCESS = 'UPDATE_MOCK_PAPER_DATA_SUCCESS'

export const FETCH_MOCK_PRICING_DATA = 'FETCH_MOCK_PRICING_DATA'
export const FETCH_MOCK_PRICING_DATA_SUCCESS = 'FETCH_MOCK_PRICING_DATA_SUCCESS'

export const UPDATE_MOCK_PRICING_DATA = 'UPDATE_MOCK_PRICING_DATA'
export const UPDATE_MOCK_PRICING_DATA_SUCCESS =
  'UPDATE_MOCK_PRICING_DATA_SUCCESS'

export const FETCH_MOCK_TEST_REPORT_CARD_DATA = 'FETCH_MOCK_TEST_REPORT_CARD'
export const FETCH_MOCK_TEST_REPORT_CARD_DATA_SUCCESS =
  'FETCH_MOCK_TEST_REPORT_CARD_SUCCESS'
