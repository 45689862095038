import styled from 'styled-components'

export const TotalAndActionsContainer = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 16px 0;
`
export const TotalOuterContainer = styled.div`
  background: var(--oh-light-cream);
  border-radius: 8px;
  padding: 16px;
`

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  font-size: 18px;
  font-family: AvenirDemi;
  background: #fff;
`

export const TotalText = styled.span``

export const TotalAmount = styled.span`
  color: var(--oh-orange);
  border-radius: 24px;
  border: 1px solid var(--oh-orange);
  padding: 0 8px;
  display: flex;
  align-items: center;
`

export const CtaButton = styled.button`
  width: 100%;
  padding: 10px;
  margin-bottom: 8px;
  border: none;
  border-radius: 24px;
  font-family: AvenirDemi;
  font-size: 16px;
  cursor: pointer;
  text-transform: lowercase;
  ${(props) =>
    props.primary
      ? `
    background-color: var(--oh-orange);
    color: white;
  `
      : `
    background-color: white;
    color: var(--oh-orange);
    border: 2px solid var(--oh-orange);
  `}
  ${(props) =>
    props.lastButton &&
    `
    margin-bottom:0;
    `}
`

export const RechargeNote = styled.div`
  background-color: #fff0f0;
  border: 1px solid #ffd6d6;
  border-radius: 4px;
  padding: 12px;
  margin-top: 16px;
`

export const NoteHeading = styled.p`
  font-family: Avenir;
  font-size: 14px;
  color: #333;
  margin: 0 0 8px 0;
`

export const BoldText = styled.span`
  font-family: AvenirDemi;
`

export const NoteText = styled.p`
  font-family: Avenir;
  font-size: 14px;
  color: #333;
  margin: 0;
`

export const LoadingText = styled.p`
  text-align: center;
  font-family: Avenir;
`

export const ErrorText = styled.span`
  text-align: center;
  color: #b82741;
  font-family: AvenirDemi;
`

export const CoinImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  margin-top: -2px;
`

export const ButtonsContainer = styled.div`
  ${(props) =>
    props.isRenderButton
      ? `
        margin-top: 16px;`
      : `
  `}
`
