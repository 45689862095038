import styled, { css } from 'styled-components'

const breakpoint = '1024px'
const shadow = '10px 10px 32px -8px rgb(0 0 0 / 20%)'

export const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 15px;
  min-width: 45%;
`

export const Content = styled.div`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;

  ${(props) =>
    props.heading &&
    props.type &&
    css`
      font-family: AvenirDemi;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : 'var(--oh-black-primary)'};
    `}

  ${(props) =>
    props.description &&
    props.type &&
    css`
      font-family: Avenir;
      font-size: 14px;
      line-height: 24px;
      text-transform: ${props.textTransform};
      color: ${props.type === 'light'
        ? 'var(--oh-black-secondary)'
        : 'var(--oh-black-primary)'};
    `}

    ${(props) =>
    props.subtitle &&
    props.type &&
    css`
      font-family: AvenirMedium;
      font-size: 12px;
      line-height: 20px;
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : 'var(--oh-black-secondary)'};
    `}


    ${(props) =>
    props.size &&
    css`
      font-family: AvenirDemi;
      font-size: ${props.size === 'medium'
        ? '20px'
        : props.size === 'small'
        ? '12px'
        : '24px'};
      line-height: ${props.size === 'medium'
        ? '24px'
        : props.size === 'small'
        ? '16px'
        : '32px'};
    `}

      ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

export const InfoBlock = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;

  ${(props) =>
    props.flexType &&
    css`
      flex-direction: ${props.flexType};
    `}

  ${(props) =>
    props.align &&
    css`
      align-items: ${props.align};
    `}
`

export const Icon = styled.img`
  height: 8px;
  width: 8px;
  margin-right: 5px;
`
