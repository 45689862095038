import {
  commonStructure,
  handleEntitiesUpdate,
  dataResponseHandler,
  dataFetchingHandler
} from './reducer.helper'

import * as types from '../types/paymentEntity'

const initialState = {
  dueData: commonStructure(),
  paidData: commonStructure(),
  dueMemberData: commonStructure(),
  paidMemberData: commonStructure(),
  dueRenewalData: commonStructure(),
  paidRenewalData: commonStructure(),
  canceledMemberData: commonStructure(),
  pausedMemberData: commonStructure(),
  studentSummaryData: commonStructure(),
  teacherSummaryData: commonStructure(),
  superproviderSummaryData: commonStructure(),
  independentSummaryData: commonStructure(),
  independentOngoingSummaryData: commonStructure(),
  dispatchData: commonStructure()
}

const paymentEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DUE_DATA:
    case types.FETCH_PAID_DATA:
    case types.FETCH_DUE_MEMBER_DATA:
    case types.FETCH_PAID_MEMBER_DATA:
    case types.FETCH_DUE_RENEWAL_DATA:
    case types.FETCH_PAID_RENEWAL_DATA:
    case types.FETCH_CANCELED_MEMBER_DATA:
    case types.FETCH_PAUSED_MEMBER_DATA:
    case types.FETCH_STUDENT_SUMMARY_DATA:
    case types.FETCH_TEACHER_SUMMARY_DATA:
    case types.FETCH_SUPERPROVIDER_SUMMARY_DATA:
    case types.FETCH_INDEPENDENT_SUMMARY_DATA:
    case types.FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA:
    case types.FETCH_DISPATCH_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_DUE_MEMBER_DATA_SUCCESS:
    case types.FETCH_PAID_MEMBER_DATA_SUCCESS:
    case types.FETCH_DUE_RENEWAL_DATA_SUCCESS:
    case types.FETCH_PAID_RENEWAL_DATA_SUCCESS:
    case types.FETCH_CANCELED_MEMBER_DATA_SUCCESS:
    case types.FETCH_PAUSED_MEMBER_DATA_SUCCESS:
    case types.FETCH_DUE_DATA_SUCCESS:
    case types.FETCH_PAID_DATA_SUCCESS:
    case types.FETCH_STUDENT_SUMMARY_DATA_SUCCESS:
    case types.FETCH_TEACHER_SUMMARY_DATA_SUCCESS:
    case types.FETCH_SUPERPROVIDER_SUMMARY_DATA_SUCCESS:
    case types.FETCH_INDEPENDENT_SUMMARY_DATA_SUCCESS:
    case types.FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA_SUCCESS:
    case types.FETCH_DISPATCH_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    case types.UPDATE_PAYMENT_DATA_SUCCESS:
      return handleEntitiesUpdate(state, action)

    default:
      return state
  }
}

export { paymentEntityReducer }
