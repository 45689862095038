import AppBarComponent from '@material-ui/core/AppBar'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import PropTypes from 'prop-types'
import React from 'react'

import { fetchBoard, fetchStandard, fetchSubject } from 'api'
import { drawerWidth } from 'app/config'

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: theme.palette.background.default
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const AppBar = (props) => {
  const [standards, setStandards] = React.useState([])
  const [subjects, setSubjects] = React.useState([])
  const [boards, setBoards] = React.useState([])

  const classes = useStyles()

  const { handleOnChange, queryData } = props

  React.useEffect(() => {
    const fetchStandards = async () => {
      try {
        const response = await fetchStandard()
        setStandards(response.results)
      } catch (error) {
        console.log(error)
      }
    }

    const fetchSubjects = async () => {
      try {
        const response = await fetchSubject()
        setSubjects(response.results)
      } catch (error) {
        console.log(error)
      }
    }

    const fetchBoards = async () => {
      try {
        const response = await fetchBoard()
        setBoards(response.results)
      } catch (error) {
        console.log(error)
      }
    }

    fetchStandards()
    fetchSubjects()
    fetchBoards()
  }, [])

  return (
    <AppBarComponent elevation={1} position="fixed" className={classes.appBar}>
      <Toolbar>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Standard</InputLabel>
          <Select
            name="standard_uuid"
            label="Standard"
            onChange={handleOnChange}
            defaultValue={''}
          >
            {standards.map((value, index) => (
              <MenuItem key={index} value={value.uuid}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Subjects</InputLabel>
          <Select
            value={queryData.subjects_uuid || []}
            multiple
            name="subjects_uuid"
            MenuProps={MenuProps}
            onChange={handleOnChange}
            label="Subjects"
            renderValue={(selected) => {
              return selected
                .map((selectedItem) => {
                  return subjects.find((item) => item.uuid === selectedItem)
                    .name
                })
                .join(', ')
            }}
          >
            {subjects.map((subject, index) => (
              <MenuItem key={index} value={subject.uuid} label={subject.name}>
                <Checkbox
                  color="primary"
                  checked={
                    (queryData.subjects_uuid || []).indexOf(subject.uuid) > -1
                  }
                />
                <ListItemText primary={subject.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Boards</InputLabel>
          <Select
            value={queryData.boards_uuid || []}
            multiple
            name="boards_uuid"
            MenuProps={MenuProps}
            onChange={handleOnChange}
            label="Boards"
            renderValue={(selected) => {
              return selected
                .map((selectedItem) => {
                  return boards.find((item) => item.uuid === selectedItem).name
                })
                .join(', ')
            }}
          >
            {boards.map((board, index) => (
              <MenuItem key={index} value={board.uuid} label={board.name}>
                <Checkbox
                  color="primary"
                  checked={
                    (queryData.boards_uuid || []).indexOf(board.uuid) > -1
                  }
                />
                <ListItemText primary={board.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Batch Type</InputLabel>
          <Select
            name="batch_type"
            label="batch_type"
            onChange={handleOnChange}
            defaultValue={''}
          >
            <MenuItem value="academic"> Academic </MenuItem>
            <MenuItem value="extracurricular"> Extracurricular </MenuItem>
            <MenuItem value="event"> Event </MenuItem>
            <MenuItem value="mock"> Mock </MenuItem>
          </Select>
        </FormControl>
      </Toolbar>
    </AppBarComponent>
  )
}

AppBar.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  queryData: PropTypes.object.isRequired
}

export default AppBar
