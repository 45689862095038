import dayjs from 'dayjs'

import { getMonthName } from './helpers'

export const backIcon =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1713970128/app_assets/images/keyboard_backspace.png'
export const warningSign =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020949/app_assets/images/warning_amber.png'
export const teacher =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020949/app_assets/images/teacher.png'
export const showing =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714021909/app_assets/images/showing.png'
export const notShowing =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020949/app_assets/images/not_showing.png'
export const consistentlyShowing =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020949/app_assets/images/consistently_showing.png'
export const child =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714141205/app_assets/images/child.png'
export const parent =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020949/app_assets/images/parent.png'
export const forwardIcon =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020948/app_assets/images/arrow_forward.png'
export const viewed =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020948/app_assets/images/viewed.png'
export const levelUpgrade =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020948/app_assets/images/progress1.png'
export const abilitiesProgressed =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020948/app_assets/images/progress2.png'
export const milestoneUpgrade =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020948/app_assets/images/progress3.png'
export const levelGoal =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714020948/app_assets/images/progress4.png'
export const warningSignSmall =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714141210/app_assets/images/warning_amber2.png'
export const tick =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714141208/app_assets/images/tick.png'
export const pending =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714141206/app_assets/images/pending.png'
export const parentViewed =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714141203/app_assets/images/viewed_2.png'
export const checkDouble =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714141203/app_assets/images/check-double.png'
export const dottedStroke =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1714380737/app_assets/images/Dotted-Stroke.svg'

export const showMoreCharacterLength = 300

export const CardColors = {
  RED: '#E87D7C',
  GREEN: '#95C48C',
  YELLOW: '#F3C520'
}

export const CardStatus = {
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
  DISCOVERY_PASS: 'Discovery Pass',
  DISCOVERY_FAIL: 'Discovery Fail'
}

export const JourneyReportStatus = {
  INACTIVE: 'inactive',
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  COMPLETED: 'completed'
}

export const ReportStatus = {
  viewed: 'Viewed by parent',
  inactive: 'Inactive',
  pending: 'Pending with teacher',
  completed: 'Filled by teacher',
  submitted: 'Submitted to parent'
}

export const ReportStatusIcon = {
  viewed: parentViewed,
  inactive: warningSignSmall,
  pending: pending,
  completed: tick,
  submitted: checkDouble
}

export const ProgressDetails = {
  LEVEL_UPGRADED: levelUpgrade,
  MILESTONE_UPGRADED: milestoneUpgrade,
  PREPARING_FOR_LEVEL_GOAL: levelGoal,
  ABILITIES_PROGRESSED: abilitiesProgressed
}

export const AbilitiesStatus = {
  SHOWING: showing,
  CONSISTENTLY_SHOWING: consistentlyShowing,
  NOT_SHOWING: notShowing
}

export const CardIcon = {
  INACTIVE: warningSign
}

export const JourneyCardStatusDetails = {
  ACTIVE: {
    color: CardColors.GREEN,
    status: CardStatus.ACTIVE,
    text: null,
    smallCard: false,
    iconUrl: CardIcon.ACTIVE,
    showEndMonth: false
  },
  INACTIVE: {
    color: CardColors.RED,
    status: CardStatus.INACTIVE,
    text: 'The student was inactive for the month.',
    smallCard: true,
    iconUrl: CardIcon.INACTIVE,
    showEndMonth: false
  },
  PENDING: {
    color: CardColors.YELLOW,
    status: CardStatus.ACTIVE,
    text: 'The teacher is yet to submit the report, so we were not able to share the report for the month.',
    smallCard: true,
    iconUrl: CardIcon.INACTIVE,
    showEndMonth: false
  }
}

export const journeyFormActions = {
  CHANGE_BATCH_TIMINGS: 'change_batch_timings',
  PAUSE_ENROLMENT: 'pause_enrolment',
  UNSUBSCRIBE: 'unsubscribe',
  SUBMIT_DISCOVERY_REPORT: 'submit_discovery_report'
}

export const manageEnrolmentOptions = {
  [journeyFormActions.CHANGE_BATCH_TIMINGS]: 'Change Timings',
  [journeyFormActions.PAUSE_ENROLMENT]: 'Pause',
  [journeyFormActions.UNSUBSCRIBE]: 'Unsubscribe'
}

export const discoveryEnrolmentOptions = {
  [journeyFormActions.CHANGE_BATCH_TIMINGS]: 'Change Timings',
  [journeyFormActions.SUBMIT_DISCOVERY_REPORT]: 'Unlock Subscription'
}

export const discoveryEnrolmentReportSubmittedOptions = {
  [journeyFormActions.CHANGE_BATCH_TIMINGS]: 'Change Timings'
}

export const CURRENT_MONTH_MESSAGE =
  'If the current month is paused, the parent receives only a 50% refund of their unused coins'

export const COIN_IMAGE_SRC =
  'https://res.cloudinary.com/openhouse-study/image/upload/v1708550817/Icons/coin.png'

export const getConfirmModalHtml = (action, data) => {
  switch (action) {
    case journeyFormActions.PAUSE_ENROLMENT:
      return `You are about to pause ${data?.subject_name}<br>
              classes for ${data?.student_name} for ${dayjs()
        .month(data?.month - 1)
        .format('MMMM')}.<br><br>
              Resume date: ${data?.resume_date}${
        data.refund_value
          ? `<br>
              <span style="vertical-align:middle;">Refund: <img src="${COIN_IMAGE_SRC}" style="height: 20px; vertical-align: middle;"> ${data.refund_value}</span>`
          : ''
      }`

    case journeyFormActions.UNSUBSCRIBE:
      return `You are about to unsubscribe ${data?.student_name} from ${
        data?.subject_name
      } ${getMonthName(data?.month, true)} onwards.<br>
      ${
        data?.last_session_date &&
        `<span style="color:red;">Last session date: ${data?.last_session_date}</span> <br>`
      }
      <br> <span style="font-size:14;"><span style="font-weight: 600;">Please note: </span>Any ${
        data?.subject_name
      } make-up classes booked for ${getMonthName(
        data?.month,
        true
      )} onwards will be cancelled automatically.</span>`

    case journeyFormActions.CHANGE_BATCH_TIMINGS:
      return `You are about to change the ${
        data?.subject_name
      } batch timings for ${
        data?.student_name
      } to <strong>${data?.new_timings.join(' | ')}</strong><br>`

    case journeyFormActions.SUBMIT_DISCOVERY_REPORT:
      return `You are about to submit the discovery report for ${data?.student_name}`
  }
}

export const noSubscriptionAlertOptions = {
  title: 'The parent is due for recharge.',
  text: 'To pause, their subscription, they must recharge first.',
  showConfirmButton: false
}
