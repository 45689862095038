import React from 'react';
import * as styles from './styles';

const HeaderDetailsCard = (props) => {
  const { title, titleColor, description, iconUrl, displayIcon, numberText, displayNumberText } = props

  return (
    <styles.Card>
      <styles.Container>
        <styles.Title titleColor={titleColor}>{title}</styles.Title>
        {displayNumberText && <styles.NumberText>{numberText}</styles.NumberText>}
      </styles.Container>

      <styles.Container>
        {displayIcon && <styles.Icon src={iconUrl} />}
        <styles.Description>{description}</styles.Description>
      </styles.Container>
    </styles.Card>
  );
}

export default HeaderDetailsCard;
