import styled from 'styled-components'

export const MessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-left: 0px;
  width: 100%;
  height: 100vh;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #ccc;
  padding: 1rem;
  overflow-y: auto;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  z-index: 1;
  position: sticky;
  top: 0;
  min-height: 20vh;
  max-height: 20vh;
`

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeaderText = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  flex-direction: row;
  display: flex;
`

export const HeaderDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.25rem;
`

export const PhoneLink = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: #ef5c34;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 4px;
`

export const NewTabIcon = styled.img`
  margin-left: 5px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-bottom: 2px;
`

export const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  margin-right: 30px;
  align-items: flex-end;
`

export const SeeMore = styled.img`
  width: 24px;
  height: 24px;
  margin-top: 5px;
  cursor: pointer;
`

export const ScrollToBottom = styled.img`
  position: fixed;
  bottom: 60px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const avatarImageStyles = {
  height: '90px',
  width: '90px',
  borderRadius: '50%'
}

export const avatarTextStyles = {
  width: '2.5rem',
  height: '2.5rem',
  backgroundColor: '#DEF1FA',
  marginRight: '0.5rem',
  flexShrink: '0',
  color: '#58B7E6'
}

export const avatarMessageTextStyles = {
  width: '2rem',
  height: '2rem',
  backgroundColor: '#DEF1FA',
  flexShrink: '0',
  color: '#58B7E6'
}

export const PillStyles = {
  backgroundColor: '#EEF7FF'
}

export const button = {
  backgroundColor: '#EF5C34',
  color: 'white',
  border: 'none',
  marginBottom: '5px',
  fontWeight: 500,
  fontSize: '15px',
  width: '110px',
  height: '34px'
}

export const issueText = {
  color: '#D9435E',
  fontWeight: 600,
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer'
}

export const FlagStyles = {
  marginRight: '120px'
}

export const FlagBoxStyles = {
  padding: '2px 10px'
}
