import dayjs from 'dayjs'
import React from 'react'

import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { formatAmount } from 'app/utils'

export const columns = [
  {
    ...commonAttributes.teacher,
    name: 'teacher.user',
    label: 'Name',
    options: {
      ...commonAttributes.teacher.options,
      filterKey: 'teacher_name'
    }
  },
  {
    name: 'teacher.user.phone_number',
    label: 'Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_phone_number'
    }
  },
  {
    name: 'start_date',
    label: 'Cycle',
    options: {
      ...commonAttributes.date.options,
      filterKey: 'start_date',
      customBodyRender: (value) => (
        <span>{`${value ? dayjs(value).format('MMMM, YY') : ''}`}</span>
      )
    }
  },
  {
    name: 'tax',
    label: 'Tax Amount',
    ...commonAttributes.amount,
    options: {
      ...commonAttributes.amount.options,
      filterKey: 'tax'
    }
  },
  {
    name: 'dispatched_amount',
    label: 'Final dispatch Amount',
    ...commonAttributes.amount,
    options: {
      ...commonAttributes.amount.options,
      filterKey: 'dispatched_amount'
    }
  },
  {
    name: 'status',
    label: 'Dispatch Status',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'status'
    }
  },
  {
    name: 'utr_id',
    label: 'UTR ID',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'utr_id'
    }
  },
  {
    name: 'mode',
    label: 'Dispatch Mode',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'mode'
    }
  },
  {
    name: 'notes',
    label: 'Dispatch Note',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'notes'
    }
  }
]
