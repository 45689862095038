import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createInvoiceAction, getCartDetails } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import { fetchOrdersData } from 'app/store/actions/ordersEntity'

import CartDetailsTable from './CartDetailsTable'
import { columns } from './config'
import OrdersCollectForm from './OrdersCollectForm'

const Orders = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [editIndex, setEditIndex] = React.useState(null)
  const [cartDetailsOpen, setCartDetailsOpen] = React.useState(false)
  const [cartDetails, setCartDetails] = React.useState(null)
  const [loadingCartDetails, setLoadingCartDetails] = React.useState(false)

  const ordersData = useSelector(
    (state) => state.ordersEntityReducer.ordersData
  )

  const dispatch = useDispatch()

  const fetchCartDetails = React.useCallback(
    async ({ invoiceId, cartType }) => {
      setLoadingCartDetails(true)
      try {
        const response = await getCartDetails({ invoiceId })
        setCartDetails(response)
        setCartDetailsOpen(true)
      } catch (error) {
        console.error('Failed to fetch cart details:', error)
        eventAlert('Failed to fetch cart details', constants.ERROR)
      } finally {
        setLoadingCartDetails(false)
      }
    },
    []
  )

  const openModal = React.useCallback((e, dataIndex) => {
    e.preventDefault()
    setIsModalOpen(true)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
    setCartDetailsOpen(false)
    setCartDetails(null)
  }

  const onSaveClick = async (newData) => {
    try {
      await createInvoiceAction({
        ...newData,
        invoice: ordersData.data[editIndex].uuid
      })

      eventEmitter.emit(constants.CLOSE_MODAL_FORM)
      eventAlert('Done', constants.SUCCESS)
      closeModal()
      dispatch(fetchOrdersData({}, false, false, constants.ORDERS))
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const onActionButtonClick = (e, dataIndex) => {
    openModal(e, dataIndex)
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 9),
      {
        name: '',
        label: 'cart',
        options: {
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const invoiceId = ordersData?.data?.[dataIndex].uuid
            const cartType = ordersData?.data?.[dataIndex].type
            return (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  fetchCartDetails({ invoiceId, cartType })
                }}>
                view details
              </a>
            )
          }
        }
      },
      ...columns.slice(9),
      {
        name: '',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const cartType = ordersData?.data?.[dataIndex].type
            const cartStatus = ordersData?.data?.[dataIndex].status
            const showCollectButton =
              (cartType === 'topup_booking' ||
                cartType === 'topup' ||
                cartType === 'booking') &&
              cartStatus === 'pending'
            return showCollectButton ? (
              <ButtonGroup>
                <Button
                  onClick={(e) => {
                    onActionButtonClick(e, dataIndex)
                  }}>
                  Collect
                </Button>
              </ButtonGroup>
            ) : null
          }
        }
      }
    ]
  }, [onActionButtonClick, fetchCartDetails, ordersData])

  return (
    <>
      <BaseEntity
        entity={constants.ORDERS}
        label={constants.ORDERS_LABEL}
        fetchData={fetchOrdersData}
        data={ordersData}
        columns={modifiedColumns}
        readOnly
      />
      <OrdersCollectForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action="collect"
      />
      <ModalForm
        containerStyles={{ borderRadius: 10 }}
        className="border"
        isModalOpen={cartDetailsOpen}
        onModalClose={closeModal}
        enableSave={false}
        modalTitle="Cart Details">
        {loadingCartDetails ? (
          <p>Loading cart details...</p>
        ) : (
          <CartDetailsTable data={cartDetails} />
        )}
      </ModalForm>
    </>
  )
}

export default Orders
