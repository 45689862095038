import { ButtonGroup } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cancelSingleSession } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import { useStyles } from 'app/components/generic/Button'
import { cancellationActions } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import {
  fetchExploreSessionData,
  updateClassData
} from 'app/store/actions/classEntity'

import CancelModal from './components/Cancel/CancelModal'
import { columns } from './config'

const ExploreSession = () => {
  const [action, setAction] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false)

  const exploreSessionData = useSelector(
    (state) => state.classEntityReducer.exploreSessionData
  )

  const dispatch = useDispatch()

  const closeCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'cancel') {
      setAction(action)
      setEditIndex(dataIndex)
      setIsCancelModalOpen(true)
    }
  }

  const onSaveClick = async (newData, action) => {
    const { attendances: attendances } = exploreSessionData.data[editIndex]
    try {
      if (action === 'cancel') {
        const response = await cancelSingleSession({
          sessionId: attendances[0]?.uuid,
          queries: {
            attendance: {
              reason: !isEmpty(newData?.notes?.trim())
                ? `${newData.reason}##${newData.notes}`
                : `${newData.reason}`
            }
          }
        })
        if (response.success) {
          eventEmitter.emit(constants.CLOSE_MODAL_FORM)
          eventAlert('Session canceled Successfully', constants.SUCCESS)
        }
        closeCancelModal()
      }

      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchExploreSessionData(
          { ...queries },
          false,
          false,
          constants.EXPLORE_SESSION
        )
      )
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }
  const classes = useStyles()
  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        label: 'actions',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { status } = exploreSessionData?.data[dataIndex] || {}

            return (
              status !== 'terminated' &&
              status !== 'expired' && (
                <ButtonGroup>
                  {Object.keys(cancellationActions).map((action, index) => {
                    return (
                      <Button
                        key={'ExploreSession' + index}
                        title={cancellationActions[action]}
                        className={classes.button}
                        onClick={(e) => {
                          onActionButtonClick(e, action, dataIndex)
                        }}
                      >
                        {cancellationActions[action]}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              )
            )
          }
        }
      }
    ]
  }, [exploreSessionData])

  return (
    <>
      <BaseEntity
        entity={constants.EXPLORE_SESSION}
        label={constants.EXPLORE_SESSION_LABEL}
        columns={modifiedColumns}
        data={exploreSessionData}
        fetchData={fetchExploreSessionData}
        updateEntityData={updateClassData}
        createDependency
        disableDelete
        readOnly
      />
      <CancelModal
        isModalOpen={isCancelModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeCancelModal}
        action={action}
      />
    </>
  )
}

export default ExploreSession
