import {
  cityCodes,
  commonAttributes,
  tableColumnDefaultOption
} from 'app/config'
import { validateCount, validateNotEmpty } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Room Name',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  // {
  //   name: 'centre.name',
  //   label: 'Centre Name',
  //   disabledInForm: true,
  //   options: {
  //     ...tableColumnDefaultOption.options,
  //     filterKey: 'centre_name'
  //   }
  // },
  // {
  //   name: 'centre.city',
  //   label: 'Centre City',
  //   valueLabelMappings: cityCodes,
  //   disabledInForm: true,
  //   options: {
  //     ...tableColumnDefaultOption.options,
  //     filterKey: 'centre_city'
  //   }
  // },
  {
    name: 'capacity',
    label: 'Capacity',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'capacity'
    }
  },
  {
    name: 'centre',
    label: 'Centre',
    editable: false,
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      display: false
    },
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('centre_uuid')
  }
]
