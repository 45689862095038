import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import packageJson from '../package.json'
const { version } = packageJson

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d98b9ac6047944ed99d56323ebd34f6a@o343131.ingest.sentry.io/5663170\n',
    release: version,
    environment: process.env.REACT_APP_CUSTOM_NODE_ENV,
    attachStacktrace: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

export const sentryLogger = (exception, message) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.captureException(exception)
  } else {
    console.log(message, ':', exception.message)
  }
}

export const setSentryUser = (userId) => {
  if (userId) {
    Sentry.setUser({ id: userId })
  }
}
