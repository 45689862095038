import ActivitiesIcon from 'assets/images/activities.svg'
import AreaOfDevelopmentIcon from 'assets/images/area_of_development.svg'
import ExpectaionIcon from 'assets/images/expectaions.svg'
import FavouriteIcon from 'assets/images/favourites.svg'
import HealthIcon from 'assets/images/health.svg'
import MoreIcon from 'assets/images/more.svg'

export const groupKeys = {
  AREAS_OF_DEVELOPMENT: 'areas',
  AREAS_OF_DEVELOPMENT_OTHERS: 'areas',
  EXPECTATIONS: 'expectations',
  EXPECTATIONS_OTHERS: 'expectations',
  ALLERGIES: 'health',
  MEDICAL_CONDITIONS: 'health',
  OTHER_ACTIVITIES: 'activities',
  NAME_OF_ACTIVITIES: 'activities',
  FAVOURITE_COLOUR: 'favourites',
  FAVOURITE_FICTIONAL_CHARACTER: 'favourites',
  FAVOURITE_HOBBIES: 'favourites',
  RETURN_TIME_FROM_SCHOOL: 'more',
  LANGUAGES: 'more'
}

export const childDataColumn = {
  areas: {
    id: '64512268-28ab-4a73-b9bf-42238a2f2b40',
    heading: 'areas of development',
    icon: AreaOfDevelopmentIcon,
    data: []
  },
  expectations: {
    id: '591fb0e8-57f9-4819-953f-0ce8b8603473',
    heading: 'expectations from Openhouse',
    icon: ExpectaionIcon,
    data: []
  },
  health: {
    id: '0fffb586-494f-4e65-a0be-7196f512a2f3',
    heading: 'health information',
    icon: HealthIcon,
    data: []
  },
  activities: {
    id: 'c5b7e381-4ceb-4692-872f-71ea234115ec',
    heading: 'Activities',
    icon: ActivitiesIcon,
    data: []
  },
  favourites: {
    id: 'edebf752-978c-4133-a656-949477417d85',
    heading: 'Favourites',
    icon: FavouriteIcon,
    data: []
  },
  more: {
    id: '2eb02d0f-adca-4c73-b29b-7b4e2513e056',
    heading: 'More',
    icon: MoreIcon,
    data: []
  }
}

export const noHeadingList = ['AREAS_OF_DEVELOPMENT', 'EXPECTATIONS']
