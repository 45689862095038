export const commonSchema = {
  type: 'object',
  required: []
}

export const demoSchema = { ...commonSchema }

export const classSchema = {
  ...commonSchema,
  required: [...commonSchema.required, 'payment_frequency'],
  definitions: {
    ...commonSchema.definitions,
    payment_frequency: {
      type: 'string',
      title: 'Payment Frequency',
      enum: ['monthly'],
      enumNames: ['Monthly'],
      default: 'monthly'
    },
    is_discount_in_percentage: {
      type: 'boolean',
      title: 'Discount Type (For Recurring Payments)',
      enum: [true, false],
      enumNames: ['Discount Percentage', 'Custom Pricing'],
      default: true,
      additionalProperties: {
        isDisabledInCheckout: true
      }
    },
    discount_percentage: {
      type: ['number', 'null'],
      title: 'Discount Percentage',
      minimum: 0,
      maximum: 100,
      messages: {
        type: 'Please Enter Valid Value',
        minimum: 'Discount Percentage should be greater than equal to 0',
        maximum: 'Discount Percentage should be less than equal to 100'
      },
      additionalProperties: {
        isDisabledInCheckout: true
      }
    },
    custom_pricing: {
      type: ['number', 'null'],
      title: 'Custom Pricing',
      minimum: 0,
      messages: {
        type: 'Please Enter Valid Value',
        minimum: 'Custom Pricing should be greater than equal to 0'
      },
      additionalProperties: {
        isDisabledInCheckout: true
      }
    }
  },
  properties: {
    ...commonSchema.properties,
    payment_frequency: {
      $ref: '#/definitions/payment_frequency'
    },
    is_discount_in_percentage: {
      $ref: '#/definitions/is_discount_in_percentage'
    }
  },
  dependencies: {
    is_discount_in_percentage: {
      oneOf: [
        {
          properties: {
            is_discount_in_percentage: {
              enum: [true]
            },
            discount_percentage: {
              $ref: '#/definitions/discount_percentage'
            }
          }
        },
        {
          properties: {
            is_discount_in_percentage: {
              enum: [false]
            },
            custom_pricing: {
              $ref: '#/definitions/custom_pricing'
            }
          }
        }
      ]
    }
  }
}
