/* eslint-disable react/prop-types */
import Dayjs from 'dayjs'
import { isEmpty, isNil } from 'lodash'
import React from 'react'

import { navigationPaths } from 'app/config'
import SchoolIcon from 'assets/images/school.svg'

import * as Styles from './styles'

const StudentProfileIconDefault = require('assets/images/student-profile.default.png')

const StudentItem = ({ student }) => {
  const { full_name, image, date_of_birth } = student?.student?.user || {}
  const { standard } = student?.student || {}
  const { start_date, type, status } = student || {}
  if (isNil(student?.student)) return null

  const schoolName = student?.student?.school?.name

  return (
    <Styles.Container>
      <Styles.ImageContainer>
        <Styles.Image src={image || StudentProfileIconDefault} />
      </Styles.ImageContainer>

      <Styles.DetailsContainer>
        <h2>{full_name}</h2>
        {!isNil(date_of_birth) ? (
          <Styles.StudentSummaryContainer>
            <Styles.Detail>
              <Styles.AgeIcon />
              <h3>
                Age {Dayjs().diff(Dayjs(date_of_birth), 'year')},{standard.name}
              </h3>
            </Styles.Detail>
            {type === 'membership' ? (
              <Styles.Detail>
                <Styles.StatusIcon />
                <h3>
                  {`${status} ${
                    Dayjs(start_date).isAfter(Dayjs()) ? `from` : `since`
                  } ${Dayjs(start_date).format('DD MMM,YY')}`}
                </h3>
              </Styles.Detail>
            ) : null}
          </Styles.StudentSummaryContainer>
        ) : null}
        {type === 'make_up_session' ? (
          <Styles.Detail>
            <Styles.SessionIcon />
            <h3>Date {start_date}</h3>
          </Styles.Detail>
        ) : null}
        {schoolName ? (
          <Styles.Detail>
            <Styles.SchoolIcon src={SchoolIcon} alt="school-icon" />
            <h3>{schoolName}</h3>
          </Styles.Detail>
        ) : null}
        <Styles.InteractionHistoryLink
          href={`${navigationPaths.interactionHistory}?parent_phone_number=${student?.student?.parent?.user?.phone_number}`}
          target="_blank">
          Interaction History
        </Styles.InteractionHistoryLink>
        {status === 'paused' ? (
          <Styles.Detail>
            <Styles.SessionIcon />
            <h3>{status} for {Dayjs(start_date)?.format('MMMM')}</h3>
          </Styles.Detail>
        ) : null}
      </Styles.DetailsContainer>
    </Styles.Container>
  )
}

export { StudentItem }
