import _, { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCart } from 'react-use-cart'

import { getCoinCalculation } from 'api'
import { confirm } from 'app/helpers'

import Actions from './components/Actions'
import AddToCartModal from './components/AddToCartModal' // Import the new modal
import BatchDetails from './components/BatchDetails'
import BookDemoClass from './components/BookDemoClass'
import CartSlider from './components/Cart'
import CheckoutModal from './components/CheckoutModal'
import MembershipPreview from './components/MembershipPreview'
import { useBatchSelection } from './hooks/useBatchSelection'

import {
  batchTypesMap,
  BookingTypes,
  batchTypesNewFlow,
  BOOKING_PAYMENT_CTA_TEXT
} from '../../constants'

const ModalsAndActions = (props) => {
  const { selectedBatchType } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [bookingType, setBookingType] = useState(null)

  const batchTypesNewFlowValues = Object.values(batchTypesNewFlow)
  const isNewFlow = batchTypesNewFlowValues.includes(selectedBatchType)

  const { selectedBatches, deselectBatch } = useBatchSelection()
  const [cartItems, setCartItems] = useState([])

  const [isMembershipPreviewOpen, setIsMembershipPreviewOpen] = useState(false)
  const [editingItem, setEditingItem] = useState(null)

  const [checkoutType, setCheckoutType] = useState(null)

  const [paymentOptions, setPaymentOptions] = useState([])
  const [coinSummary, setCoinSummary] = useState({
    expires_at: null,
    new_wallet_validity: null,
    remaining_coins: null
  })
  const [ctaOptions, setCtaOptions] = useState([])

  const { items: newFlowCartItems } = useCart()
  useEffect(() => {
    const cartOpenHandler = () => {
      if (isNewFlow) {
        onBatchClick()
        return
      }
      switch (selectedBatchType) {
        case batchTypesMap.explore:
          onDemoBookClick()
          break
        case batchTypesMap.exploration:
          onBookExplorationClick()
          break
        case batchTypesMap.excel:
          onBookExcelClick()
          break
        case batchTypesMap.class:
          onClassBookClick()
          break
      }
    }
    if (selectedBatches.length > 0) cartOpenHandler()
  }, [selectedBatches])

  const onHandleModalClose = () => {
    setIsModalOpen(false)
    setBookingType(null)
    deselectBatch({ bulk: true })
    setEditingItem(false)
  }

  const onBatchClick = () => {
    setIsModalOpen(true)
  }

  const onClassBookClick = () => {
    setCartItems([...selectedBatches])
    setBookingType(BookingTypes.CLASS)
    setIsModalOpen(true)
  }

  const onDemoBookClick = () => {
    setCartItems([...selectedBatches])
    if (selectedBatchType === 'explore') setBookingType(BookingTypes.MEMBERSHIP)
    else setBookingType(BookingTypes.DEMO)
    setIsModalOpen(true)
  }

  const onBookExplorationClick = () => {
    setCartItems([...selectedBatches])
    setBookingType(BookingTypes.EXPLORATION)
    setIsModalOpen(true)
  }

  const onBookExcelClick = () => {
    setCartItems([...selectedBatches])
    setBookingType(BookingTypes.EXCEL)
    setIsModalOpen(true)
  }

  const onMembershipPreviewBookClick = () => {
    setCartItems([...selectedBatches])
    setIsMembershipPreviewOpen(true)
  }

  const onMembershipBookClick = () => {
    setCartItems([...selectedBatches])
    setBookingType(BookingTypes.MEMBERSHIP)

    const totalSelections = _.countBy(selectedBatches, 'course.uuid')

    let flag = true
    selectedBatches.forEach((item) => {
      flag =
        flag &&
        totalSelections[item.course.uuid] === item.course.batches_per_booking
    })

    if (!flag) {
      confirm(
        () => setIsModalOpen(true),
        'We advise you to book twice a week classes per regular class, for a good educational experience.',
        () => {}
      )
    } else {
      setIsModalOpen(true)
    }
  }

  const onMembershipPreviewClose = () => {
    setIsMembershipPreviewOpen(false)
  }

  const onBookingSuccess = () => {
    setCartItems([])
  }

  const onDeleteCartItem = (item) => {
    setCartItems((state) => {
      const updatedCartItems = state.filter((batch) => batch.uuid !== item.uuid)

      if (!updatedCartItems.length) onHandleModalClose()
      else deselectBatch(item)

      return updatedCartItems
    })
  }

  const onCartItemEditHandler = (item) => {
    setEditingItem(item)
    setIsModalOpen(true)
  }

  const checkoutButtonClickHandler = (type) => {
    setCheckoutType(type)
  }

  useEffect(() => {
    if (newFlowCartItems.length > 0) {
      ;(async () => {
        const coinCalculationResponse = await getCoinCalculation({
          cart_type: 'booking',
          parent: newFlowCartItems?.[0]?.userInfo.parent.uuid,
          cartItems: newFlowCartItems
        })
        const coinCalculationData = coinCalculationResponse?.data
        const {
          expires_at,
          new_wallet_validity,
          remaining_coins,
          total_spending,
          nudges
        } = coinCalculationData
        const coinSummary = {
          expires_at,
          new_wallet_validity,
          remaining_coins,
          monthly_consumption: total_spending,
          nudges
        }

        if (coinCalculationResponse?.success) {
          setPaymentOptions(coinCalculationData.payment_options)
          setCoinSummary(coinSummary)
        }
      })()
    }
  }, [newFlowCartItems])

  useEffect(() => {
    if (paymentOptions?.length <= 0) {
    } else {
      setCtaOptions(
        paymentOptions?.map((option, index) => {
          return {
            text: BOOKING_PAYMENT_CTA_TEXT[option.payment_type],
            type: index === 0 ? 'primary' : 'secondary',
            payment_type: option.payment_type
          }
        })
      )
    }
  }, [paymentOptions])

  return isNewFlow ? (
    <>
      <AddToCartModal
        isModalOpen={isModalOpen}
        bookingType={bookingType}
        selectedItem={selectedBatches?.[0]}
        onHandleModalClose={onHandleModalClose}
        deselectBatch={deselectBatch}
        setIsModalOpen={setIsModalOpen}
        selectedBatchType={selectedBatchType}
        isEditing={!!editingItem}
        editingItem={editingItem}
      />
      <CartSlider
        coinSummary={coinSummary}
        onCartItemEditHandler={onCartItemEditHandler}
        checkoutButtonClickHandler={checkoutButtonClickHandler}
        ctaOptions={ctaOptions}
      />
      <CheckoutModal
        coinSummary={coinSummary}
        isModalOpen={!!checkoutType}
        onModalClose={() => {
          setCheckoutType(null)
        }}
        checkoutType={checkoutType}
        paymentOptions={paymentOptions}
      />
      <BatchDetails />
    </>
  ) : (
    <>
      <Actions
        cartItemsLength={selectedBatches.length}
        isDisabled={isEmpty(selectedBatches)}
        onDemoBookClick={onDemoBookClick}
        onClassBookClick={onClassBookClick}
        onBookExplorationClick={onBookExplorationClick}
        onBookExcelClick={onBookExcelClick}
        onMembershipPreviewBookClick={onMembershipPreviewBookClick}
        onMembershipBookClick={onMembershipBookClick}
        selectedBatchType={selectedBatchType}
        isNewFlow={isNewFlow}
      />
      <BookDemoClass
        isModalOpen={isModalOpen}
        bookingType={bookingType}
        cartItems={cartItems}
        onBookingSuccess={onBookingSuccess}
        onDeleteCartItem={onDeleteCartItem}
        onHandleDemoClassCloseClick={onHandleModalClose}
        deselectBatch={deselectBatch}
        setIsBookDemoClassModalOpen={setIsModalOpen}
        selectedBatchType={selectedBatchType}
        setCartItems={setCartItems}
        setBookingType={setBookingType}
      />
      <BatchDetails />
      <MembershipPreview
        cartItems={cartItems}
        onDelete={onDeleteCartItem}
        isOpen={isMembershipPreviewOpen}
        onClose={onMembershipPreviewClose}
      />
    </>
  )
}

export default ModalsAndActions
