import {
  commonStructure,
  handleEntitiesUpdate,
  dataResponseHandler,
  dataFetchingHandler
} from './reducer.helper'

import * as types from '../types/eventEntity'

const initialState = {
  eventData: commonStructure(),
  eventRegistrationData: commonStructure()
}

const eventEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_EVENT_DATA:
    case types.FETCH_EVENT_REGISTRATION_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_EVENT_DATA_SUCCESS:
    case types.FETCH_EVENT_REGISTRATION_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    case types.UPDATE_EVENT_DATA_SUCCESS:
      return handleEntitiesUpdate(state, action)

    default:
      return state
  }
}

export { eventEntityReducer }
