import { Button, ButtonGroup } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchDueAmount, terminateProgramEnrolment } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import { useStyles } from 'app/components/generic/Button'
import { programActions } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import {
  fetchExploreEnrolmentData,
  updateClassData
} from 'app/store/actions/classEntity'
import { getCurrentMonth } from 'app/utils'

import { columns } from './config'

import MasterModal from '../components/MasterModal'

const ExploreEnrolment = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [cancelId, setCancelId] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)
  const [dueAmount, setDueAmount] = React.useState({
    enrolment_uuid: '',
    data: [],
    dataIndex: null,
    due: 0
  })
  const exploreEnrolmentData = useSelector(
    (state) => state.classEntityReducer.exploreEnrolmentData
  )
  const dispatch = useDispatch()

  const openModal = React.useCallback((action, dataIndex) => {
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onActionButtonClick = (e, action, dataIndex) => {
    if (action === 'terminate_enrolment') {
      openModal('terminate_enrolment', dataIndex)
    }
  }

  const onSaveClick = async (newData, action) => {
    const { uuid: enrolmentUuid } = exploreEnrolmentData.data[editIndex]
    try {
      if (action === 'terminate_enrolment') {
        await terminateProgramEnrolment({
          enrolment_uuid: enrolmentUuid,
          notes: newData.notes,
          endDate: newData.end_date,
          reason: newData.reason
        })

        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Enrolment Terminated Successfully', constants.SUCCESS)
        closeModal()
      }

      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchExploreEnrolmentData(
          { ...queries },
          false,
          false,
          constants.EXPLORE_ENROLMENT
        )
      )
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }
  const classes = useStyles()
  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        label: 'actions',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { status, is_termination_requested } =
              exploreEnrolmentData?.data[dataIndex] || {}

            return (
              status !== 'terminated' &&
              status !== 'expired' &&
              !is_termination_requested && (
                <ButtonGroup>
                  {Object.keys(programActions).map((action, index) => {
                    return (
                      <Button
                        key={'ExploreEnrolment' + index}
                        title={programActions[action]}
                        className={classes.button}
                        onClick={(e) => {
                          onActionButtonClick(e, action, dataIndex)
                        }}
                      >
                        {programActions[action]}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              )
            )
          }
        }
      }
    ]
  }, [exploreEnrolmentData])

  return (
    <>
      <BaseEntity
        entity={constants.EXPLORE_ENROLMENT}
        label={constants.EXPLORE_ENROLMENT_LABEL}
        columns={modifiedColumns}
        data={exploreEnrolmentData}
        fetchData={fetchExploreEnrolmentData}
        updateEntityData={updateClassData}
        createDependency
        disableDelete
        readOnly
      />
      <MasterModal
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={exploreEnrolmentData.data[editIndex] || {}}
        onActionButtonClick={onActionButtonClick}
        setCancelId={setCancelId}
      />
    </>
  )
}

export default ExploreEnrolment
