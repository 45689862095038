import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;

  p {
    font-size: 14px;
    margin: 0 0 0.6em 0;
  }

  h3 {
    margin: 0.1em 0;

    font-size: 18px;
  }

  h3 del {
    font-weight: normal;
    margin-right: 0.5em;
    font-size: 12px;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4em;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
`
