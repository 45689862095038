/* eslint-disable react/prop-types */
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
import { useTheme, ThemeProvider } from '@material-ui/core/styles'
import Form from '@rjsf/material-ui'
import { isNil, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { fetchUser } from 'api'
import { AutoComplete } from 'app/components/generic/FormFields'
import { navigationPaths } from 'app/config'

import * as Styles from './styles'

import { conflictMessages, conflictTypes } from '../../../../../../constants'
import { getTransformErrors } from '../../formConfig'

const fetchParentDetails = async (searchedText) => {
  try {
    if (!isEmpty(searchedText) && searchedText.length === 10) {
      const queries = {
        phone_number: searchedText
      }

      const response = await fetchUser({ queries }, 'parent')

      if (response.results.length === 1) {
        return response.results.map((item) => {
          const {
            uuid,
            first_name,
            last_name,
            phone_number,
            email_address,
            state,
            coin_summary,
            member_status,
            credits
          } = item?.user

          return {
            uuid,
            value: uuid,
            name: `${phone_number} (${first_name} ${last_name})`,
            first_name,
            last_name,
            phone_number,
            email: email_address,
            state,
            students: item?.students,
            coins: coin_summary?.remaining_coins,
            credits,
            member_status
          }
        })
      } else if (response.results.length > 1) {
        throw new Error('Multiple user found with same number')
      } else {
        return []
      }
    }
  } catch (error) {
    console.log('Error in fetching user :- ', error)
    throw error
  }
}

const AddNewUser = ({ user, onClick, disabled = false }) => {
  return (
    <Styles.NoUser>
      Can't find?{' '}
      <Styles.Button onClick={onClick} disabled={disabled}>
        <span>Add new {user}</span>
      </Styles.Button>
    </Styles.NoUser>
  )
}

const ParentChildForm = React.forwardRef(
  (
    {
      bookingType,
      schema,
      errorSchema,
      formData,
      onChangeForm,
      onSubmit,
      selectedParentDetails,
      selectedStudentDetails,
      handleParentSelection,
      handleStudentSelection,
      students,
      inputError,
      setSelectedParentDetails,
      allowAddingNewUser,
      isParentLocked,
      isEditing,
      studentConflictDetails,
      onHandleModalClose
    },
    ref
  ) => {
    const theme = useTheme()

    const modifiedTheme = {
      ...theme,
      props: {
        ...theme.props,
        MuiTextField: {
          ...theme.props.MuiTextField,
          variant: 'outlined',
          size: 'small',
          className: 'inventory-input'
        },
        MuiCheckbox: {
          color: 'primary',
          size: 'small',
          className: 'inventory-checkbox'
        }
      }
    }

    const uiSchema = {}

    const cities = useSelector((state) => state.commonReducer.cities)
    const [clearValue, setClearValue] = useState(false)

    const getState = () => {
      const centre = JSON.parse(localStorage.getItem('SELECTED_CENTRE'))
      const city = centre?.city
      const state = cities.data.find((el) => el.name === city)?.state?.code
      return state
    }

    const onClickAddParent = () => {
      const centre = JSON.parse(localStorage.getItem('SELECTED_CENTRE'))
      const state = getState()
      window.open(
        `${window.location.origin}${navigationPaths.parent}?openModalForm=1&centre=${centre?.value}&state=${state}`
      )
    }

    const onClickAddStudent = () => {
      const state = getState()
      const parentId = selectedParentDetails?.uuid
      // setSelectedParentDetails({})
      // setClearValue(true)
      window.open(
        `${window.location.origin}${
          navigationPaths.student
        }?openModalForm=1&parent=${parentId}&no_phone_number=${true}&state=${state}`
      )
      onHandleModalClose()
    }

    useEffect(() => {
      setClearValue(isEmpty(selectedParentDetails))
    }, [selectedParentDetails])

    useEffect(() => {
      if (isEditing && selectedParentDetails) {
        // Pre-fill the form with existing data
        handleParentSelection('parent', selectedParentDetails)
      }
    }, [isEditing, selectedParentDetails])

    useEffect(() => {
      if (isEditing && selectedStudentDetails) {
        handleStudentSelection({
          target: { value: selectedStudentDetails.user.uuid }
        })
      }
    }, [selectedStudentDetails])

    const onChange = (name, values) => {
      handleParentSelection(name, values)
    }

    return (
      <Styles.FormContainer>
        <ThemeProvider theme={modifiedTheme}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            className="parent-selection">
            <AutoComplete
              label="Parent"
              placeholder="Enter Parent Mobile Number"
              minCharactersToSearch={10}
              required
              async
              disabled={isParentLocked || isEditing}
              fetchOnMount={false}
              searchWithEmptyValue={false}
              clearOnBlur={false}
              fetchValues={fetchParentDetails}
              onChange={onChange}
              emptyValue={clearValue}
              {...(!isEmpty(selectedParentDetails)
                ? { defaultValue: selectedParentDetails }
                : null)}
            />

            {inputError.parent && (
              <FormHelperText error>{inputError.parent}</FormHelperText>
            )}
            {!allowAddingNewUser && !isEditing ? (
              <AddNewUser user="parent" onClick={onClickAddParent} />
            ) : null}
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            className="student-selection"
            required>
            <Styles.Wrapper>
              <InputLabel id="student-select">Child</InputLabel>
              <Select
                label="Child"
                labelId="student-select"
                value={
                  !isEmpty(selectedStudentDetails)
                    ? selectedStudentDetails?.user?.uuid
                    : ''
                }
                onChange={handleStudentSelection}
                disabled={isEmpty(selectedParentDetails) || isEditing}>
                {students.map((item) => (
                  <MenuItem
                    key={item.user.uuid}
                    value={item.user.uuid}
                    disabled={item.isDisabled}>
                    {`${item.user.full_name} (${`${
                      item?.standard?.name ?? 'No grade found!'
                    }`})`}
                    {` ${item?.message ? item?.message : ''}`}
                  </MenuItem>
                ))}
              </Select>
            </Styles.Wrapper>

            {inputError.student && (
              <FormHelperText error>{inputError.student}</FormHelperText>
            )}
            {!allowAddingNewUser && !isEditing ? (
              <AddNewUser
                user="child"
                onClick={onClickAddStudent}
                disabled={isEmpty(selectedParentDetails)}
              />
            ) : null}
          </FormControl>
          {studentConflictDetails?.message ? (
            <Styles.ErrorModal
              unlocked={studentConflictDetails?.isStudentValid}>
              <Styles.BoldText>
                {`${
                  conflictMessages?.[studentConflictDetails?.message]?.title
                    ? conflictMessages?.[studentConflictDetails?.message]
                        ?.title + ': '
                    : `There is a conflict of type: ${
                        studentConflictDetails?.message ?? `UNKNOWN`
                      }: `
                }
                `}
              </Styles.BoldText>
              {studentConflictDetails?.message !== conflictTypes.LEVEL_CONFLICT
                ? conflictMessages?.[studentConflictDetails?.message]?.text1 ??
                  ''
                : studentConflictDetails?.description ?? ''}
              <br />
              <br />
              {conflictMessages?.[studentConflictDetails?.message]?.text2 ?? ''}
            </Styles.ErrorModal>
          ) : null}

          {!isNil(schema) ? (
            <Form
              ref={ref}
              schema={schema}
              uiSchema={uiSchema}
              onSubmit={onSubmit}
              transformErrors={getTransformErrors(schema)}
              noHtml5Validate
              showErrorList={false}
              onChange={onChangeForm}
              formData={formData}
              extraErrors={errorSchema}>
              <React.Fragment />
            </Form>
          ) : null}
        </ThemeProvider>
      </Styles.FormContainer>
    )
  }
)

export { ParentChildForm }
