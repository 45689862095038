import styled, { css } from 'styled-components'

export const Container = styled.div`
  min-height: 100vh;
  height: max-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: var(--oh-light-cream);

  .button-dashboard {
    background-color: var(--oh-orange);
    text-transform: lowercase;
  }

  .button-dashboard:hover {
    background-color: var(--oh-dark-orange);
  }
`

export const Dropdown = styled.div`
  width: 40%;
`
export const Header = styled.div`
  display: flex;
  gap: 20px;
`

export const StatusIcon = styled.div`
  display: flex;
  gap: 8px;
  align-items: centre;
`

export const Widgets = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

export const Icon = styled.img`
  height: 24px;
  width: 24px;
`

export const StarIcon = styled.img``

export const RowFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
`
export const Content = styled.div`
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  // text-transform: lowercase;
  color: var(--oh-black-primary);
  ${(props) =>
    props.plan &&
    css`
      font-family: AvenirDemi;
      font-size: 40px;
    `}

  ${(props) =>
    props.subheading &&
    css`
      font-family: AvenirMedium;
      font-size: 14px;
    `}
    
        ${(props) =>
    props.heading &&
    css`
      font-family: AvenirMedium;
      font-size: 20px;
      line-height: 24px;
    `}
  
    ${(props) =>
    props.regular &&
    css`
      font-family: Avenir;
      font-size: 12px;
    `}

      ${(props) =>
    props.type &&
    css`
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : props.type === 'medium'
        ? 'var(--oh-black-secondary)'
        : 'var(--oh-black-primary)'};
    `}

    ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`
export const ButtonWrapper = styled.div`
  display: contents;
`

export const ButtonLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
