import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { createDemoSchedule, fetchBatch } from 'api'
import { apiEndpoints } from 'api/common'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import { resetDemoSchedulingData } from 'app/store/actions/demoScheduling'
import { makeQueryStringFromObject } from 'app/utils'

import AppBar from './AppBar'
import CurrentSelection from './components/CurrentSelection'
import Content from './Content'
import SideBar from './SideBar'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  }
}))

const validBatchRequestKeys = ['standard_uuid']

const DemoSchedulingScreen = () => {
  const demoSchedulingUserData = useSelector(
    (state) => state.demoSchedulingReducer.demoSchedulingUserData
  )
  const [queryData, setQueryData] = React.useState({})
  const [isCurrentSelectionOpen, setIsCurrentSelectionOpen] = React.useState(
    false
  )

  const [batch, setBatch] = React.useState({
    isLoading: false,
    isLoaded: false,
    data: []
  })

  const classes = useStyles()
  const dispatch = useDispatch()

  const fetchAllBatches = React.useCallback(async (queries) => {
    try {
      setBatch((state) => ({
        ...state,
        isLoading: true
      }))

      const response = await fetchBatch({
        url: `${apiEndpoints.batch}?${queries}`
      })

      setBatch((state) => ({
        ...state,
        isLoading: false,
        isLoaded: true,
        data: response.results
      }))
    } catch (error) {
      console.log(error)
    }
  }, [])

  React.useEffect(() => {
    if (!isEmpty(queryData)) {
      let haveAllMandatoryKeyValue = true

      for (let i = 0; i < validBatchRequestKeys.length; i++) {
        if (isEmpty(queryData[validBatchRequestKeys[i]])) {
          haveAllMandatoryKeyValue = false
          break
        }
      }

      if (haveAllMandatoryKeyValue) {
        // now fetch batches

        let modifiedQueryData = {}

        for (const [key, value] of Object.entries({
          ...queryData
        })) {
          modifiedQueryData = {
            ...modifiedQueryData,
            [key]: Array.isArray(value) ? value.join(',') : value
          }
        }
        const queries = makeQueryStringFromObject(modifiedQueryData)
        fetchAllBatches(queries)
      }
    }
  }, [queryData, fetchAllBatches])

  const handleOnChange = React.useCallback((event) => {
    const { name, value } = event.target

    setQueryData((state) => {
      return {
        ...state,
        [name]: value
      }
    })
  }, [])

  const onCurrentSelectionClick = React.useCallback((e) => {
    e.preventDefault()
    setIsCurrentSelectionOpen(true)
  }, [])

  const handleCurrentSelectionModalClose = React.useCallback(() => {
    setIsCurrentSelectionOpen(false)
  }, [])

  const handleFinalBatchesSaveClick = React.useCallback(
    async (ids, status, nurturing) => {
      if (Array.isArray(ids) && !isEmpty(ids)) {
        try {
          await Promise.all(
            ids.map((id) => {
              return createDemoSchedule({
                user_uuid: demoSchedulingUserData.uuid,
                demo_scheduled: id,
                mini_course: status,
                send_nurturing_message: nurturing
              })
            })
          )
          dispatch(resetDemoSchedulingData())
          eventEmitter.emit(constants.IS_SCHEDULING_REQUEST_FINISHED)
          setIsCurrentSelectionOpen(false)
          eventAlert(
            `${status ? 'Mini Course Scheduled' : 'Demo Scheduled'}`,
            constants.SUCCESS
          )
        } catch (error) {
          console.log('error in creating demo schedule :- ', error)
          eventEmitter.emit(constants.IS_SCHEDULING_REQUEST_FINISHED)
          eventAlert(error.message || constants.ERROR_MESSAGE, constants.ERROR)
        }
      } else {
        alert(`No ${status ? 'Mini Course' : 'Demo'} To Schedule`)
      }
    },
    [dispatch, demoSchedulingUserData]
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SideBar onCurrentSelectionClick={onCurrentSelectionClick} />
      <AppBar queryData={queryData} handleOnChange={handleOnChange} />
      <Content batch={batch} />
      <CurrentSelection
        isModalOpen={isCurrentSelectionOpen}
        handleModalClose={handleCurrentSelectionModalClose}
        handleOnSaveClick={handleFinalBatchesSaveClick}
      />
    </div>
  )
}

export default DemoSchedulingScreen
