import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchDispatchData } from 'app/store/actions/paymentEntity'

import { columns } from './config'

const Dispatch = () => {
  const dispatchData = useSelector(
    (state) => state.paymentEntityReducer.dispatchData
  )

  return (
    <BaseEntity
      entity={constants.DISPATCH}
      label={constants.DISPATCH_LABEL}
      fetchData={fetchDispatchData}
      data={dispatchData}
      columns={columns}
      readOnly
    />
  )
}

export default Dispatch
