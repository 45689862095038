/* eslint-disable react/prop-types */
import { Divider } from '@material-ui/core'
import dayjs from 'dayjs'
import { isEmpty, isNil } from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  fetchCanceledMemberPayment,
  fetchPausedMemberPayment,
  fetchPlans
} from 'api'
import { formatCoins } from 'app/utils'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'
import { WalletCancel } from '../WalletCancel'

export const Wallet = (props) => {
  const { selectedParentDetails } = props
  const [cancelPauseData, setCancelPauseData] = useState({})

  const user = selectedParentDetails?.user
  const validity_date = user?.coin_summary?.expires_at
  const validity_date_dayjs = dayjs(validity_date)
  const formatted_validity_date = validity_date_dayjs.format('D MMM, YYYY')

  const diffInDays = validity_date_dayjs.diff(dayjs(), 'day')

  const credit = user?.credits
  const active_subscription = user?.active_subscription
  const coin_summary = user?.coin_summary
  const active = user?.status === 'customer'

  useEffect(() => {
    ;(async () => {
      let cancelData

      if (user) {
        if (user?.member_status === 'cancelled') {
          cancelData = await fetchCanceledMemberPayment({
            url: null,
            queries: { parent_phone_number: user?.phone_number }
          })
        } else {
          cancelData = await fetchPausedMemberPayment({
            url: null,
            queries: { parent_phone_number: user?.phone_number }
          })
        }

        setCancelPauseData(cancelData.results[0])
      }
    })()
  }, [user])

  return user?.member_status !== 'cancelled' &&
    user?.member_status !== 'pause' ? (
    <styles.Body>
      <styles.ColumnFlexCenter>
        <TitleDescription
          heading="Current balance"
          size="medium"
          align="center"
          description={
            <styles.Coins>
              {active ? <styles.CoinImg></styles.CoinImg> : null}
              {active
                ? formatCoins(coin_summary?.remaining_coins)
                : `${
                    credit === 1
                      ? `${credit} free trial`
                      : `${credit} free trials`
                  }`}
            </styles.Coins>
          }
        />
        {!isNil(validity_date) ? (
          <TitleDescription
            heading="Current validity"
            size="small"
            align="center"
            description={`${formatted_validity_date}(in ${diffInDays} days)`}
          />
        ) : null}
      </styles.ColumnFlexCenter>
    </styles.Body>
  ) : (
    <WalletCancel cancelData={cancelPauseData} />
  )
}
