import dayjs from 'dayjs'
import _, { isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  createConversationhistory,
  updateConversationhistory,
  getSliderDetails,
  updateSibling,
  getSiblingDetails,
  getDemoScheduledDetails,
  getEnrolmentDetails,
  getTribeEnrolments
} from 'api/user'
import { navigationPaths } from 'app/config'
import { getAccessToken, notify } from 'app/helpers'
import {
  getAttendanceSessions,
  getEnrolmentExtensions,
  getRatingData,
  getSessionDetails,
  sortCompare
} from 'app/pages/Dashboard/ParentDashboard/helpers'
import { fetchStudentData } from 'app/store/actions/userEntity'
import { decodeJWT, sanitize } from 'app/utils'
import { validateName, validateNotEmpty } from 'app/validators'
import CancelledAlfredIcon from 'assets/images/cancelled_alfred.svg'
import CancelledAppIcon from 'assets/images/cancelled_app.svg'
import EmptyImage from 'assets/images/empty.svg'
import NoScheduleImage from 'assets/images/noschedule.svg'
import SkippedIcon from 'assets/images/skipped.svg'
import StarIcon from 'assets/images/star.svg'

import * as styles from '../../../pages/Dashboard/ParentDashboard/components/SummaryBlock/components/ClassroomExperience/styles'

import './history.css'
import { Checkbox, Button } from '@mui/material'

const History = (props) => {
  const [formData, setFormData] = useState({})
  const [addMode, setAddMode] = useState(false)
  const [editMode, setEditMode] = useState(-1)
  const { full_name, phone_number, uuid } = props?.userInfo
  const [activityLogs, setActivityLogs] = useState()
  const [historyData, setHistoryData] = useState([])
  const [headerData, setHeaderData] = useState([])
  const [userUUID, setUserUUID] = useState('')
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    const data = decodeJWT(getAccessToken())
    setUserUUID(data.sub)
    switch (props.type) {
      case 'ENROLMENTS':
        fetchEnrolmentData()
        break

      case 'CHILDREN':
        fetchChildrentData()
        break

      case 'CLASSROOM_EXPERIENCE':
        fetchRatingData()
        break

      case 'ATTENDANCE':
        fetchAttendanceData()
        break
      case 'PAST_ATTENDANCE':
        fetchAttendanceData()
        break
      case 'FUTURE_ATTENDANCE':
        fetchAttendanceData()
        break

      case 'STUDENT_ATTENDANCE':
        fetchStudentAttendanceData()
        break
      case 'FUTURE_STUDENT_ATTENDANCE':
        fetchStudentAttendanceData()
        break

      case 'SUBJECT_DETAILS':
        getEnrolmentDetails(uuid)
          .then((res) => {
            setHistoryData(res.results)
          })
          .catch((err) => new Error(err))
        break

      case 'DEMO_SCHEDULED':
        getDemoScheduledDetails(uuid)
          .then((res) => {
            setHistoryData(res)
          })
          .catch((err) => new Error(err))
        break

      case 'CONVERSATIONS':
        getSliderDetails('CONVERSATION', data.sub, uuid)
          .then((res) => {
            setHistoryData(res)
          })
          .catch((err) => new Error(err))
        break

      case 'CONTACTS':
        getSliderDetails('CONTACT', data.sub, uuid)
          .then((res) => {
            setHistoryData(res)
          })
          .catch((err) => new Error(err))
        break

      case 'SIBLINGS':
        fetchSiblingData()
        break

      case 'TRIBE_EROLMENTS':
        getTribeEnrolments(uuid)
          .then((res) => {
            setHistoryData(res)
          })
          .catch((err) => new Error(err))
        break
      case 'ENROLMENTS_EXTENSION':
        fetchEnrolmentExtensionsData(uuid)
        break
      case 'ACTIVE_STUDENTS':
        fetchActiveStudentsData()
        break
      case 'DROPOUTS':
        fetchDropoutStudentsData()
        break
      case 'DASHBOARD_STUDENT_ATTENDANCE':
        fetchDashboardStudentAttendanceData()
        break
      case 'INTERACTION_HISTORY':
        fetchInteractionHistoryData()
        break
      case 'TICKETS_LOG':
        fetchTicketsLogData()
        break
      case 'WALLET':
        fetchWalletData()
        break
      default:
        setHistoryData(props.data)
        break
    }
  }, [props.data])

  const fetchSiblingData = () => {
    if (!isEmpty(uuid)) {
      getSiblingDetails(uuid)
        .then((res) => {
          const siblingData = res.map((sib) => {
            return {
              uuid: sib.uuid,
              name: sib.first_name + ' ' + sib.last_name,
              standard: sib.standard,
              school: sib.school,
              email: sib.email_address,
              phone_number: sib.phone_number
            }
          })
          setHistoryData(siblingData)
        })
        .catch((err) => new Error(err))
    }
  }

  const fetchAttendanceData = () => {
    if (!isEmpty(props.data)) {
      let data = props.data
      let attendance = data.map((val, index) => {
        const sourceApp = val.cancellation_source === 'member_app'
        const flags = val?.enrolment?.program_enrolment?.journey?.flags
        val.student = (
          <div>
            <div className="flexDiv">
              {val.cancelled && (
                <img
                  src={sourceApp ? CancelledAppIcon : CancelledAlfredIcon}
                  alt="cancelled"
                />
              )}
              <div className="headerHeading">
                {val?.enrolment?.student?.user?.full_name}
              </div>
              {val?.enrolment?.type === 'make_up_session' && (
                <div className="error">{`(make-up)`}</div>
              )}
              {props.type === 'FUTURE_ATTENDANCE' && val.cancelled && (
                <div className="error">{`(cancelled from ${
                  val.cancellation_source || ''
                })`}</div>
              )}
            </div>

            {/* Flags on the next line */}
            <div
              className="flagsDiv"
              style={{ marginLeft: val.cancelled ? 40 : 0 }}>
              {flags?.map((flag) =>
                flag?.show_on_alfred === true ? (
                  <img
                    src={flag.icon}
                    alt={flag.value}
                    title={flag.value} // Title gives the flag a hover label
                    key={flag.uuid}
                    height={24}
                    width={24}
                  />
                ) : null
              )}
            </div>
          </div>
        )

        val.attendance =
          props.type === 'PAST_ATTENDANCE' ? (
            val.attended ? (
              <div className="success">{`present`}</div>
            ) : val.cancelled ? (
              <div className="error">{`cancelled from ${
                val.cancellation_source || ''
              }`}</div>
            ) : (
              <div className="error">{`absent (no-show)`}</div>
            )
          ) : (
            val.attended
          )
        return val
      })
      setHistoryData(attendance)
      if (!isEmpty(props.headers)) setHeaderData(props.headers)
    }
  }

  const fetchStudentAttendanceData = () => {
    const schedules = props.data?.map((value) => {
      return {
        course: value?.enrolment?.batch?.course?.name,
        timings: value?.schedule
          ? `${dayjs('1/1/1 ' + value?.schedule?.start_time).format(
              'h:mma'
            )} - ${dayjs('1/1/1 ' + value?.schedule?.end_time).format('h:mma')}`
          : '',
        teacher: value?.enrolment?.batch?.teacher?.full_name,
        uuid: value?.uuid,
        attended: value ? value?.attended : null
      }
    })
    if (props?.data[0]?.uuid) setHistoryData(schedules)
    else setHistoryData([])
    if (!isEmpty(props.headers)) setHeaderData(props.headers)
  }

  const fetchEnrolmentData = () => {
    if (!isEmpty(props.data)) setHistoryData(props.data)
  }

  const fetchRatingData = () => {
    if (!isEmpty(props.data)) setHistoryData(props.data)
    if (props.headerData) {
      const result = getRatingData(props.data)
      const headers = [
        {
          heading: 'Overall rating',
          value: (
            <styles.RowFlexCenter>
              <styles.Icon src={StarIcon} alt="star" />
              <styles.Content heading type="dark">
                {result.overallRating}
              </styles.Content>
            </styles.RowFlexCenter>
          )
        }
      ]
      setHeaderData(headers)
    }
  }

  const fetchChildrentData = async () => {
    if (!isEmpty(uuid)) {
      const res = await props.data
      const enrolmentData = res?.map((enrolment) => {
        return {
          sessions_attended: enrolment?.attendances ? (
            `${enrolment?.attendances?.filter((el) => el.attended)?.length}/${
              enrolment.attendances?.length
            }`
          ) : (
            <styles.Icon src={SkippedIcon} alt="skipped" />
          ),
          sessions_canceled: enrolment?.attendances ? (
            `${enrolment?.attendances?.filter((el) => el.cancelled)?.length}/${
              enrolment.attendances?.length
            }`
          ) : (
            <styles.Icon src={SkippedIcon} alt="skipped" />
          ),
          sessions_absent: enrolment?.attendances ? (
            `${
              enrolment?.attendances?.filter(
                (el) => !el.attended && el.processed
              )?.length
            }/${enrolment?.attendances?.length}`
          ) : (
            <styles.Icon src={SkippedIcon} alt="skipped" />
          ),
          type: enrolment?.batch?.type
            ? enrolment?.batch?.type
            : enrolment?.program?.type,
          course: enrolment?.batch?.course?.name
            ? enrolment?.batch?.course?.name
            : enrolment?.program?.name,
          status: enrolment?.status,
          batch_timings: enrolment?.batch?.cadence ? (
            enrolment?.batch?.cadence
              ?.sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
              .map((item) => {
                const start = item.start_time.split('-').join(':')
                const end = item.end_time.split('-').join(':')
                return `${dayjs()
                  .day(
                    item.weekday >= 0 && item.weekday < 7 ? item.weekday + 1 : 7
                  )
                  .format('ddd')}, ${dayjs('1/1/1 ' + start).format(
                  'h:mma'
                )} - ${dayjs('1/1/1 ' + end).format('h:mma')}`
              })
              .join(' | ')
          ) : (
            <styles.Icon src={SkippedIcon} alt="skipped" />
          ),
          start_date: enrolment.start_date,
          end_date: enrolment.end_date,
          termination_notes: enrolment.notes
        }
      })

      if (props.userInfo.parent) {
        const sessions = await getSessionDetails(
          undefined,
          props.userInfo.parent,
          { limit: 2000 }
        )
        const results = getAttendanceSessions(
          sessions.filter((el) => el?.enrolment?.student?.user_id === uuid)
        )
        const blocks = [
          {
            heading: 'upcoming session',
            value: results.upcoming,
            subtitle: results.upcomingCourse
          },
          {
            heading: 'last attended session',
            value: results.lastAttended,
            subtitle: results.lastAttendedCourse
          }
        ]
        setHeaderData(blocks)
        setHistoryData(enrolmentData)
      }
    }
  }

  const fetchEnrolmentExtensionsData = (uuid) => {
    if (!isEmpty(uuid)) {
      const extensionData = props.data?.extensionsRemaining?.map((item) => {
        return {
          upcoming_extension: item?.end_date,
          student_name: item?.student?.user?.full_name,
          sku: item?.batch?.course?.name,
          sessions_remaining: item?.sessions_remaining
        }
      })
      setHistoryData(extensionData)
    }
  }

  const fetchActiveStudentsData = () => {
    setHistoryData(props?.data?.activeStudentsData)
  }

  const fetchDropoutStudentsData = () => {
    setHistoryData(props?.data?.dropoutStudentsData)
  }

  const fetchDashboardStudentAttendanceData = () => {
    setHistoryData(props?.data?.studentsData)
  }

  const fetchInteractionHistoryData = () => {
    const interactions = props?.data?.map((int) => {
      const interactionSummary = (
        <div style={{ maxWidth: 500, whiteSpace: 'normal' }}>
          {int?.interaction_topics.map((topic, index) => (
            <div key={index}>
              <div
                style={{
                  borderRadius: 100,
                  backgroundColor: '#ffe1d9',
                  color: '#ef5c34',
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 1,
                  paddingBottom: 1,
                  maxWidth: 'fit-content',
                  fontWeight: 700
                }}>
                {topic.topic}
              </div>
              <div dangerouslySetInnerHTML={{ __html: topic.summary }} />
              {index < int.interaction_topics.length - 1 && <br />}
            </div>
          ))}
        </div>
      )

      return {
        interaction_date: int?.interaction_date,
        interaction_topics: int?.interaction_topics_list.join(' | ') || 'N/A',
        interaction_summary: interactionSummary || 'N/A',
        interaction_channel: int?.interaction_channel || 'N/A',
        created_by: int?.created_by
      }
    })
    setHistoryData(interactions)
    const parent_id = props?.selectedParentDetails.uuid
    const headers = [
      {
        heading: (
          <styles.RowFlexCenter>
            <Button
              color="primary"
              id={'Button_add_interaction'}
              variant="contained"
              onClick={() => {
                window.open(
                  `${window.location.origin}${navigationPaths.interactionHistory}?openModalForm=1&parent=${parent_id}`
                )
              }}
              className="button-interaction">
              add interaction
            </Button>
          </styles.RowFlexCenter>
        )
      }
    ]
    setHeaderData(headers)
  }

  const fetchTicketsLogData = () => {
    const tickets = props?.data
    setHistoryData(tickets)

    const parent_id = props?.userInfo.uuid
    const headers = [
      {
        heading: (
          <styles.RowFlexCenter>
            <Button
              color="primary"
              id={'Button_add_interaction'}
              variant="contained"
              onClick={() => {
                window.open(
                  `${window.location.origin}${navigationPaths.ticket}?openModalForm=1&parent=${parent_id}`
                )
              }}
              className="button-interaction">
              Raise a ticket
            </Button>
          </styles.RowFlexCenter>
        )
      }
    ]
    setHeaderData(headers)
  }

  const fetchWalletData = () => {
    const walletData = props?.data
    setHistoryData(walletData)

    const validity_date_str = props?.userInfo?.coin_summary?.expires_at
    const validity_date = dayjs(validity_date_str)
    const formatted_validity_date = !isNil(validity_date_str)
      ? validity_date.format('D MMM, YYYY')
      : '-'

    const headers = [
      {
        heading: 'Current wallet validity',
        value: formatted_validity_date
      }
    ]
    setHeaderData(headers)
  }

  const enableAddMode = () => {
    // fill default value here itself if required
    const newFormData = {}
    props.tableHeadings.forEach((tableHeading, index) => {
      if (typeof tableHeading.defaultValue === 'function') {
        const defaultValue = tableHeading.defaultValue()

        // get key name for form element and then set value
        const keyName = props.accessors[index]
        newFormData[keyName] = defaultValue
      }
      if (tableHeading.type === 'date') {
        const keyName = props.accessors[index]
        newFormData[keyName] = dayjs(Date()).format('YYYY-MM-DD')
      } else if (tableHeading.type === 'time') {
        const keyName = props.accessors[index]
        newFormData[keyName] = dayjs(Date()).format('HH:mm')
      } else if (tableHeading.type === 'select') {
        const keyName = props.accessors[index]
        newFormData[keyName] = props.selectOptions[0]
      }
    })

    if (!isEmpty(newFormData)) {
      setFormData({
        ...formData,
        ...newFormData
      })
    }
    setAddMode(true)
  }

  const validateSibling = (createUser) => {
    for (const key in createUser) {
      if (key === 'phone_number' || key === 'email_address') {
        continue
      } else if (
        validateNotEmpty({
          isRequired: true
        })(createUser[key]) ||
        isNil(createUser[key])
      ) {
        setError(`${key} cannot be empty`)
        return true
      } else if (key === 'last_name' || key === 'first_name') {
        if (validateName({ isRequired: true })(createUser[key])) {
          setError(`Please enter valid ${key}`)
          return true
        }
      }
    }
    setError('')
    return false
  }

  const validateHistoryData = (data) => {
    for (const key in data) {
      if (isNil(data[key]) || isEmpty(data[key])) {
        setError(`${key} cannot be empty`)
        return true
      }
    }
    setError('')
    return false
  }

  const addDetails = () => {
    if (props.type === 'SIBLINGS') {
      const { name, school, standard, email, phone_number } = formData
      const [firstName, lastName] =
        typeof name === 'string' ? name.split(' ') : []

      const createUser = {
        first_name: firstName || '',
        last_name: lastName || '',
        phone_number,
        email_address: email,
        school,
        standard
      }
      if (validateSibling(createUser)) {
        return
      }
      createUser.student_uuid = uuid
      updateSibling(createUser)
        .then((res) => {
          fetchSiblingData()
          resetStates()
        })
        .catch((error) => {
          notify(error.message, '', 'info')
        })
    } else {
      const { date, start_time, medium, agenda, content } = formData
      const payload = {
        date,
        start_time,
        medium,
        end_time: null,
        agenda,
        content,
        type: 'CONVERSATION',
        receipients: [uuid]
      }
      if (validateHistoryData({ date, start_time, medium, content })) {
        return
      }
      createConversationhistory(payload)
        .then((res) => {
          return getSliderDetails('CONVERSATION', userUUID, uuid)
            .then((res) => {
              setHistoryData(res)
              resetStates()
            })
            .catch((error) => {
              notify(error.message, '', 'info')
            })
        })
        .catch((error) => {
          notify(error.message, '', 'info')
        })
    }
  }

  const resetStates = () => {
    setEditMode(-1)
    setFormData({})
    setAddMode(false)
  }

  const saveDetails = () => {
    if (addMode) {
      addDetails()
    } else {
      editDetails()
    }
  }

  const editDetails = () => {
    if (props.type === 'SIBLINGS') {
      const { name, school, standard, email, phone_number } = formData
      const payload = {
        user: '',
        school,
        standard
      }
      updateSibling(payload.user, payload)
    } else {
      const { date, start_time, authors, medium, agenda } = formData
      const editUuid = historyData[editMode].uuid
      const payload = {
        uuid: editUuid,
        medium,
        date,
        start_time,
        end_time: null,
        agenda,
        content: null,
        type: 'CONVERSATION',
        receipients: [uuid]
      }
      if (validateHistoryData({ date, start_time, medium, agenda })) {
        return
      }
      updateConversationhistory(editUuid, payload)
        .then((res) => {
          return getSliderDetails('CONVERSATION', userUUID, uuid)
            .then((res) => {
              setHistoryData(res)
              dispatch(fetchStudentData({}, 'student'))
              resetStates()
            })
            .catch((error) => {
              notify(error.message, '', 'info')
            })
        })
        .catch((error) => {
          notify(error.message, '', 'info')
        })
    }
  }

  const enableEdit = (index) => {
    if (!addMode) {
      setEditMode(index)
      setFormData(historyData[index])
    }
  }

  const readInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const [expandedRows, setExpandedRows] = React.useState({})
  const toggleRows = (rowsId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowsId]: !prevExpandedRows[rowsId]
    }))
  }

  const renderData = (el, index) => {
    return props.accessors.map((element, elIndex) => {
      let data = null
      if (Array.isArray(el[element])) {
        if (
          props.type === 'DEMO_SCHEDULED' ||
          props.type === 'SUBJECT_DETAILS'
        ) {
          data = el[element][0]
        } else {
          data = el[element][0]?.name
        }
      } else {
        data = el[element]
      }
      if (element === 'date' && !isEmpty(data)) {
        data = dayjs(data).format('DD-MMM-YY')
      }
      if (element === 'start_time' && !isEmpty(data)) {
        data = data.substring(0, 5)
      }
      if (props.type === 'FUTURE_ATTENDANCE' && element === 'parent_name') {
        data = el?.enrolment?.student?.parent?.user?.full_name
      }
      if (props.type === 'FUTURE_ATTENDANCE' && element === 'parent_number') {
        data = el?.enrolment?.student?.parent?.user?.phone_number
      }
      if (props.tableHeadings[elIndex]?.type === 'checkbox') {
        data = (
          <div key={'checkbox' + el.uuid} className="flexDiv">
            <Checkbox
              type="checkbox"
              color="success"
              name="attended"
              disabled={props.componentDisabled}
              checked={el[element]}
              value={el[element]}
              onChange={(e) => props.componentChange(e, 'attended', el)}
            />
            {historyData[index].cancelled ? (
              <div className="error">{`(cancelled from ${
                historyData[index].cancellation_source || ''
              })`}</div>
            ) : null}
          </div>
        )
      }
      if (props.tableHeadings[elIndex]?.type === 'button') {
        data = (
          <div key={'button' + el.uuid} className="flexDiv">
            {el[element]}
          </div>
        )
      }
      if (props.tableHeadings[elIndex]?.type === 'text-link') {
        data = (
          <div>
            <a href={el[element].link}>{el[element].text}</a>
          </div>
        )
      }
      if (props.tableHeadings[elIndex]?.type === 'truncated-text') {
        const fullData = data
        const truncatedData = fullData?.slice(0, 100)
        const isTruncated = fullData?.length > truncatedData?.length
        const isExpanded = expandedRows[elIndex]
        const displayData = isExpanded ? fullData : truncatedData

        data = (
          <div
            style={{
              minWidth: '400px',
              maxWidth: '400px',
              whiteSpace: 'pre-wrap'
            }}>
            <span>
              {!displayData
                ? 'n/a'
                : displayData?.length < 100
                ? displayData
                : !isExpanded
                ? displayData + '...'
                : displayData}
              <br />
              {isTruncated && (
                <div style={{ textAlign: 'right' }}>
                  <a
                    href="#"
                    onClick={() => toggleRows(elIndex)}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}>
                    {isExpanded ? 'show less' : 'show more'}
                  </a>
                </div>
              )}
            </span>
          </div>
        )
      }

      return (
        <td key={element}>
          <div> {data}</div>
        </td>
      )
    })
  }

  const renderEditableData = (el) => {
    return props.accessors.map((element, index) => {
      let data = null
      if (Array.isArray(formData[element])) {
        data = formData[element][0].name
      } else {
        data = formData[element]
      }

      // first we need to get type of field
      const { type, disabled } = props.tableHeadings[index]
      return (
        <td key={element}>
          <input
            className="table-input"
            type={type || 'text'}
            onChange={readInput}
            name={element}
            value={data}
            disabled={disabled === true}
          />
        </td>
      )
    })
  }

  const addDataRow = () => {
    return (
      addMode &&
      editMode === -1 && (
        <>
          <tr key={'edit'}>
            {props.tableHeadings?.map((el, index) => {
              const name = props.accessors[index]
              if (el.type === 'select') {
                return (
                  <td
                    className={`${props.type === 'SIBLINGS' ? '' : 'add-col'}`}
                    key={index}>
                    <select
                      onChange={readInput}
                      name={name}
                      value={formData[name]}>
                      {props.selectOptions?.map((el) => (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      ))}
                    </select>
                  </td>
                )
              } else if (el.type == 'textarea') {
                return (
                  <td className="add-col" key={index}>
                    <textarea
                      rows="7"
                      col="50"
                      onChange={readInput}
                      name={name}
                      value={formData[name]}></textarea>
                  </td>
                )
              }
              return (
                <td
                  className={`${props.type === 'SIBLINGS' ? '' : 'add-col'}`}
                  key={index}>
                  <input
                    disabled={el.disabled === true}
                    className="table-input"
                    type={el.type}
                    onChange={readInput}
                    name={name}
                    value={formData[name]}
                    placeholder={name}
                  />
                </td>
              )
            })}
          </tr>
          <tr>
            <div>{error}</div>
          </tr>
        </>
      )
    )
  }

  const modifyDataOptions = () => {
    return (
      props.editableDetails && (
        <div>
          {addMode || editMode !== -1 ? (
            <>
              <button className="border-none" onClick={resetStates}>
                Cancel
              </button>
              <button onClick={saveDetails}>Save</button>
            </>
          ) : (
            <button onClick={enableAddMode}>Add</button>
          )}
        </div>
      )
    )
  }

  const getheaderData = () => {
    return (
      props.headerData && (
        <div className="headerWrapper">
          {headerData?.map((el, index) => {
            return (
              <div className="headerBlocks" key={'headerBlock' + index}>
                <div className="headerHeading">{el.heading}</div>
                <div className="headerValue">{el.value}</div>
              </div>
            )
          })}
        </div>
      )
    )
  }

  const page = React.useMemo(() => {
    return (
      <>
        {props.sliderMode && (
          <>
            {props.headerInfo ? (
              <>
                <h1 className="p-l"> {props.headerInfo}</h1>
                <h4 className="mb-0 p-l p-b divider"></h4>
              </>
            ) : (
              <>
                <h1 className="p-l">{full_name}</h1>
                <h4 className="mb-0 p-l p-b divider">{phone_number}</h4>
              </>
            )}
          </>
        )}
        <div>
          {/* {historyData?.length > 0 || !props.readOnly || props.customComponentData ? ( */}
          <>
            <div className="heading">
              <div className="headerDataHeading">
                <div>{props.heading}</div>
                {props.headerData && <div>{getheaderData()}</div>}
              </div>
              <div className="customComponentHeading">
                {props.customComponentData && props.customComponent()}
              </div>
              {modifyDataOptions()}
            </div>
            {historyData?.length > 0 || !props.readOnly ? (
              <div className="table-placement">
                <table>
                  <thead>
                    <tr className="table-head">
                      {props.tableHeadings.map((el, index) => (
                        <th key={index}>{el.label}</th>
                      ))}
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {addDataRow()}
                    {historyData.map((el, index) => {
                      if (editMode === index && !addMode) {
                        return (
                          <>
                            <tr key={index}>
                              {renderEditableData(el)}
                              {/* <td></td> */}
                            </tr>
                            <tr>
                              <div>{error !== '' && error}</div>
                            </tr>
                          </>
                        )
                      }
                      return <tr key={index}>{renderData(el, index)}</tr>
                    })}
                    {!isEmpty(activityLogs) &&
                      activityLogs[0].activity_log.map((el, index) => {
                        return (
                          <tr key={index}>
                            <td>{el.activity_date}</td>
                            <td>
                              {el.activities.map((element, index) => {
                                const data = element.split(',')
                                return (
                                  <div key="index" className="p-10">
                                    {data.map((dt) => (
                                      <div key={dt}>{dt}</div>
                                    ))}
                                  </div>
                                )
                              })}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            ) : props.type === 'ENROLMENTS' ||
              props.type === 'CHILDREN' ||
              props.type === 'CLASSROOM_EXPERIENCE' ? (
              <div className="emptyWrapper">
                <img src={EmptyImage} width="20%" />
                <div className="empty">
                  Oops! This user has no enrolments yet.
                </div>
              </div>
            ) : props.type === 'STUDENT_ATTENDANCE' ||
              props.type === 'FUTURE_STUDENT_ATTENDANCE' ? (
              <div className="emptyWrapper">
                <img src={NoScheduleImage} width="20%" />
                <div className="empty">
                  Oops! This user has no schedules today.
                </div>
              </div>
            ) : props.type === 'RENEWALS' ? (
              <div className="emptyWrapper">
                <img src={EmptyImage} width="20%" />
                <div className="empty">
                  Oops! This user has no renewals yet.
                </div>
              </div>
            ) : props.type === 'WALLET' ? (
              <div className="emptyWrapper">
                <img src={EmptyImage} width="20%" />
                <div className="empty">
                  Oops! This user does not have a membership.
                </div>
              </div>
            ) : props.type === 'ENROLMENTS_EXTENSION' ? (
              <div className="emptyWrapper">
                <img src={EmptyImage} width="20%" />
                <div className="empty">
                  Oops! This user does not have any pending extensions.
                </div>
              </div>
            ) : props.type === 'ACTIVE_STUDENTS' ? (
              <div className="emptyWrapper">
                <img src={EmptyImage} width="20%" />
                <div className="empty">Oops! There is no data to show.</div>
              </div>
            ) : props.type === 'DROPOUTS' ? (
              <div className="emptyWrapper">
                <img src={EmptyImage} width="20%" />
                <div className="empty">Oops! There is no data to show.</div>
              </div>
            ) : props.type === 'DASHBOARD_STUDENT_ATTENDANCE' ? (
              <div className="emptyWrapper">
                <img src={EmptyImage} width="20%" />
                <div className="empty">Oops! There is no data to show.</div>
              </div>
            ) : null}
          </>
          {/* ) : null} */}
        </div>
      </>
    )
  }, [props, historyData])

  return <>{page}</>
}

History.defaultProps = {
  apiCall: false,
  data: [],
  editableDetails: true,
  addDetails: true,
  readOnly: false,
  selectOptions: [],
  sliderMode: true,
  headerData: false,
  customComponentData: false
}

History.propTypes = {
  heading: PropTypes.string,
  data: PropTypes.any,
  selectOptions: PropTypes.array,
  type: PropTypes.string,
  tableHeadings: PropTypes.array,
  accessors: PropTypes.array,
  userInfo: PropTypes.any,
  apiCall: PropTypes.bool,
  editableDetails: PropTypes.bool,
  readOnly: PropTypes.bool,
  sliderMode: PropTypes.bool,
  headerData: PropTypes.bool,
  customComponentData: PropTypes.bool
}

export default History
