import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Field } from 'formik'
import { Autocomplete as AutocompleteComponent } from 'formik-material-ui-lab'
import _, { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'

import { makeRequestUrl } from 'app/helpers'
import { useDebounce } from 'app/hooks/useDebounce'

import { axiosInstance, post } from '../../../../../request'

const multipleSelectProps = {
  multiple: true,
  filterSelectedOptions: true
}

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1)
  }
}))

const AutoComplete = (props) => {
  const [options, setOptions] = React.useState([])
  const [value, setValue] = React.useState(props.multiple ? [] : {})
  const [searchText, setSearchText] = React.useState('')

  const classes = useStyles()

  const {
    data,
    minCharactersToSearch,
    placeholder,
    apiOptions,
    getStructuredValues,
    getOptionLabelKey,
    getOptionSelectedKey,
    resultStructure,
    multiple,
    formData,
    setFieldValue,
    disabled,
    debounceTime,
    mappedOptions
  } = props

  //Add debounce to the search text
  const debouncedsearchText = useDebounce(searchText, debounceTime)

  React.useEffect(() => {
    if (formData.uuid || formData.user?.uuid) {
      if (!isEmpty(get(formData, resultStructure.keyName))) {
        const formDataValue = get(formData, resultStructure.keyName)
        if (
          (Array.isArray(formDataValue) &&
            typeof formDataValue[0] === 'string') ||
          typeof formDataValue === 'string'
        ) {
          if (!isEmpty(data) && isEmpty(options)) {
            const structuredResult = getStructuredValues(
              get(data, resultStructure.keyName)
            )
            setOptions(structuredResult)
            setValue(multiple ? structuredResult : structuredResult[0])
          }
        } else {
          const values = getStructuredValues(
            get(formData, resultStructure.keyName)
          )
          setOptions(values)
          setValue(multiple ? values : values[0])
        }
      }
    } else if (!isEmpty(apiOptions)) {
      ;(async () => {
        try {
          let response = await axiosInstance({
            method: apiOptions.method,
            url: makeRequestUrl(apiOptions.url, { search: searchText })
          })
          if (typeof getStructuredValues === 'function') {
            if (!isEmpty(get(formData, resultStructure.keyName))) {
              response = await axiosInstance({
                method: apiOptions.method,
                url: `${apiOptions.url}?${getOptionSelectedKey}=${get(
                  formData,
                  resultStructure.keyName
                )}`
              })
              const structuredResult = getStructuredValues(response.data)
              setOptions(structuredResult)
              const formDataValue = get(formData, resultStructure.keyName)
              if (
                (Array.isArray(formDataValue) &&
                  typeof formDataValue[0] === 'string') ||
                typeof formDataValue === 'string'
              ) {
                setValue(
                  structuredResult.find((el) => el.uuid === formDataValue)
                )
              }
            }
          } else {
            setOptions(response.data)
            if (!isEmpty(get(formData, resultStructure.keyName))) {
              const formDataValue = get(formData, resultStructure.keyName)
              setValue(formDataValue)
            }
          }
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, []) // eslint-disable-line
  // don't put any dependency here, as it is only meant to run on component did mount to set default value

  React.useEffect(() => {
    if (!isEmpty(apiOptions) && searchText.length >= minCharactersToSearch) {
      ;(async () => {
        try {
          const response = await axiosInstance({
            method: apiOptions.method,
            url: makeRequestUrl(apiOptions.url, { search: searchText })
          })
          if (typeof getStructuredValues === 'function') {
            const structuredResult = getStructuredValues(response.data)
            setOptions(structuredResult)
          } else {
            setOptions(response.data)
          }
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [debouncedsearchText, minCharactersToSearch])

  React.useEffect(() => {
    if (mappedOptions) {
      const filteredOptions = getStructuredValues(mappedOptions)?.filter(
        (option) => option
      )
      setOptions(filteredOptions)
      if (!isEmpty(get(formData, resultStructure.keyName))) {
        const formDataValue = get(formData, resultStructure.keyName)
        const valueOption = filteredOptions?.filter((el) =>
          Array.isArray(formDataValue)
            ? formDataValue.includes(el.uuid)
            : formDataValue === el.uuid
        )
        setValue(multiple ? valueOption : valueOption[0])
      }
    }
  }, [mappedOptions])

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value)
  }

  const handleAutoCompleteData = React.useCallback(
    async (event, values, resultStructure) => {
      if (values) {
        if (!isEmpty(resultStructure)) {
          if (Array.isArray(values)) {
            const value = values.map((value) => value[resultStructure.keyValue])
            return value
          } else {
            const value = values[resultStructure.keyValue]
            return value
          }
        }
      }
    },
    []
  )

  const createNewEntry = async (name) => {
    try {
      const { data } = await post(`${apiOptions.url}`, {
        name
      })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChange = React.useCallback(
    async (event, values) => {
      let finalValues = []
      if (typeof values === 'string') {
        finalValues = await createNewEntry(values)
      } else if (Array.isArray(values)) {
        const checkedValues = values?.map(async (el) => {
          if (typeof el === 'string') {
            const data = await createNewEntry(el)
            const { name, uuid } = data
            return { name, uuid }
          }
          return el
        })
        finalValues = isEmpty(checkedValues)
          ? []
          : await Promise.all(checkedValues)
      } else {
        finalValues = values
      }
      const value = await handleAutoCompleteData(
        event,
        finalValues,
        resultStructure
      )
      setValue(finalValues)
      if (typeof setFieldValue === 'function') {
        setFieldValue(props.name, value, true) // passing true to validate
      }
    },
    [resultStructure, props.name, setFieldValue, handleAutoCompleteData]
  )

  return (
    <Field
      name={props.name}
      disabled={disabled}
      component={AutocompleteComponent}
      options={options}
      getOptionLabel={(option) => option[getOptionLabelKey] || ''}
      getOptionSelected={(option, value) => {
        return option?.[getOptionSelectedKey] === value?.[getOptionSelectedKey]
      }}
      blurOnSelect
      value={value}
      freeSolo={props.newValueToCreate}
      className={classes.root}
      onChange={handleOnChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${placeholder}`}
          variant="outlined"
          onChange={handleSearchTextChange}
          required={props.required}
          autoComplete="off"
        />
      )}
      {...(multiple ? multipleSelectProps : null)}
      {...(props.validate !== undefined ? { validate: props.validate } : null)}
    />
  )
}

AutoComplete.defaultProps = {
  minCharactersToSearch: 3,
  multiple: false,
  newValueToCreate: false,
  disabled: false,
  debounceTime: 0,
  mappedOptions: null
}

AutoComplete.propTypes = {
  data: PropTypes.object.isRequired,
  minCharactersToSearch: PropTypes.number,
  placeholder: PropTypes.string,
  apiOptions: PropTypes.object.isRequired,
  getStructuredValues: PropTypes.func,
  getOptionLabelKey: PropTypes.string.isRequired,
  getOptionSelectedKey: PropTypes.string.isRequired,
  resultStructure: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  formData: PropTypes.object.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.func,
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  newValueToCreate: PropTypes.bool,
  disabled: PropTypes.bool,
  debounceTime: PropTypes.number,
  mappedOptions: PropTypes.array
}

export default AutoComplete
