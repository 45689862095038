import Switch from '@material-ui/core/Switch'
import React from 'react'
import { useSelector } from 'react-redux'

import { updateAttendance } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { showToast } from 'app/helpers'
import {
  fetchEventRegistrationData,
  updateEventData
} from 'app/store/actions/eventEntity'

import { columns } from './config'

const Registration = () => {
  const eventRegistrationData = useSelector(
    (state) => state.eventEntityReducer.eventRegistrationData
  )

  const onChangeAttended = React.useCallback(
    (data) => {
      updateAttendance({ uuid: data.attendance?.uuid })
        .then((response) => {
          showToast('Attendance updated successfully', constants.SUCCESS)
        })
        .catch((error) => {
          fetchEventRegistrationData({}, constants.EVENT_REGISTRATION)
        })
    },
    [eventRegistrationData]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'attendance.attended',
        label: 'Attendance',
        disabledInForm: true,
        options: {
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const data = eventRegistrationData.data[dataIndex]
            return data.attendance ? (
              <Switch
                key={data.uuid}
                defaultChecked={data.attendance.attended}
                color="primary"
                onChange={(event) =>
                  onChangeAttended(data, event.target.checked)
                }
              />
            ) : null
          }
        }
      }
    ]
  }, [onChangeAttended])

  return (
    <BaseEntity
      entity={constants.EVENT_REGISTRATION}
      label={constants.EVENT_REGISTRATION_LABEL}
      columns={modifiedColumns}
      data={eventRegistrationData}
      fetchData={fetchEventRegistrationData}
      updateEntityData={updateEventData}
    />
  )
}

export default Registration
