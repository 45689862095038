import styled from 'styled-components'

export const DetailsContainer = styled.div`
  flex: 0.45;

  h3 {
    min-width: 170px;
    margin: 0.75em 0;
    margin-right: 0.5em;
  }
`

export const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
`

export const Value = styled.h3`
  display: flex;
  margin: 0.2em 0;
`

export const Key = styled(Value)`
  font-weight: normal;
  margin-right: 1em;
`
