import Button from '@material-ui/core/Button'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { cancelSingleSession } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import { useStyles } from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { cancellationActions, tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import {
  fetchMakeUpSessionData,
  updateClassData
} from 'app/store/actions/classEntity'

import CancelModal from './components/Cancel/CancelModal'
import { columns } from './config'

dayjs.extend(weekday)
const MakeUp = () => {
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [cancelId, setCancelId] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)

  const makeUpData = useSelector(
    (state) => state.classEntityReducer.makeUpSessionData
  )

  const dispatch = useDispatch()

  const closeCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'cancel') {
      setAction(action)
      setEditIndex(dataIndex)
      setIsCancelModalOpen(true)
    }
  }

  const onSaveClick = async (newData, action) => {
    const { attendances: attendances } = makeUpData.data[editIndex]
    try {
      if (action === 'cancel') {
        const response = await cancelSingleSession({
          sessionId: attendances[0]?.uuid,
          queries: {
            attendance: {
              reason: !isEmpty(newData?.notes?.trim())
                ? `${newData.reason}##${newData.notes}`
                : `${newData.reason}`
            }
          }
        })
        if (response.success) {
          eventEmitter.emit(constants.CLOSE_MODAL_FORM)
          eventAlert('Session canceled Successfully', constants.SUCCESS)
        }
        closeCancelModal()
      }

      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchMakeUpSessionData(
          { ...queries },
          false,
          false,
          constants.ENROLMENT
        )
      )
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const classes = useStyles()

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 12),
      {
        name: 'batch.cadence',
        label: 'Slot',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'explore_slot',
          disableFilter: true,
          customBodyRender: (value, index) => (
            <span>
              {Array.isArray(value) &&
                value
                  .filter(
                    (el) =>
                      (el.weekday >= 0 && el.weekday < 6
                        ? el.weekday + 1
                        : 0) ===
                      dayjs(
                        makeUpData.data[index.rowIndex].start_date
                      ).weekday()
                  )
                  .sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
                  .map((item) => {
                    const start = item.start_time.split('-').join(':')
                    const end = item.end_time.split('-').join(':')
                    const date = makeUpData.data[index.rowIndex].start_date

                    return `${dayjs()
                      .day(
                        item.weekday >= 0 && item.weekday < 7
                          ? item.weekday + 1
                          : 7
                      )
                      .format('ddd')} ${dayjs(date).format('DD MMM')}, ${dayjs(
                      '1/1/1 ' + start
                    ).format('h:mma')} - ${dayjs('1/1/1 ' + end).format(
                      'h:mma'
                    )}`
                  })
                  .join(' | ')}
            </span>
          )
        }
      },
      ...columns.slice(12),
      {
        name: '',
        label: 'actions',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { status } = makeUpData?.data[dataIndex] || {}

            return (
              status !== 'terminated' &&
              status !== 'expired' && (
                <ButtonGroup>
                  {Object.keys(cancellationActions).map((action, index) => {
                    return (
                      <Button
                        key={'MakeUp' + index}
                        title={cancellationActions[action]}
                        className={classes.button}
                        onClick={(e) => {
                          onActionButtonClick(e, action, dataIndex)
                        }}
                      >
                        {cancellationActions[action]}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              )
            )
          }
        }
      }
    ]
  }, [makeUpData])

  return (
    <>
      <BaseEntity
        entity={constants.MAKE_UP_SESSION}
        label={constants.MAKE_UP_SESSION_LABEL}
        columns={modifiedColumns}
        data={makeUpData}
        fetchData={fetchMakeUpSessionData}
        updateEntityData={updateClassData}
        createDependency
        disableDelete
        readOnly
      />
      <CancelModal
        isModalOpen={isCancelModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeCancelModal}
        action={action}
      />
    </>
  )
}

export default MakeUp
