import { interactionSettingsStore } from '@fullcalendar/react'
import { MuiThemeProvider } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import BaseModal from 'app/components/BaseModal/BaseModal'
import { DateRangePicker } from 'app/components/generic/DateRangePicker'
import { tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import { fetchClassroomMomentsData } from 'app/store/actions/communityEntity'
import { validateNotEmpty } from 'app/validators'

import { CommentsTable } from './components/CommentsTable'
import { LikesTable } from './components/LikesTable'
import { VisibilityTable } from './components/VisibilityTable'
import { columns } from './config'
import { Link, tableTheme, ModalContent } from './styles'

const modalTableMap = {
  visibility: VisibilityTable,
  comments: CommentsTable,
  likes: LikesTable
}

const Membership = () => {
  const [openTable, setOpenTable] = React.useState({ table: '', props: {} })
  const [expandedRows, setExpandedRows] = React.useState({})
  const toggleRows = (rowsId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowsId]: !prevExpandedRows[rowsId]
    }))
  }
  const momentsData = useSelector(
    (state) => state.communityEntityReducer.momentsData
  )

  const modifiedColumns = React.useMemo(() => {
    if (!momentsData.data.length) return columns

    const updatedColumns = [
      {
        name: 'body',
        label: 'Caption',
        editable: true,
        validate: validateNotEmpty,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          setCellProps: () => ({
            style: {
              minWidth: '400px',
              maxWidth: '400px',
              whiteSpace: 'pre-wrap'
            }
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = momentsData.data[dataIndex].body
            const truncatedData = data.slice(0, 100)
            const isTruncated = data.length > truncatedData.length
            const isExpanded = expandedRows[dataIndex]
            const displayData = isExpanded ? data : truncatedData

            return (
              <div>
                <span>
                  {displayData.length < 100
                    ? displayData
                    : !isExpanded
                    ? displayData + '...'
                    : displayData}
                  <br />
                  {isTruncated && (
                    <div style={{ textAlign: 'right' }}>
                      <a
                        href="#"
                        onClick={() => toggleRows(dataIndex)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {isExpanded ? 'show less' : 'show more'}
                      </a>
                    </div>
                  )}
                </span>
              </div>
            )
          }
        }
      },
      ...columns
    ]

    return updatedColumns.concat([
      {
        name: 'schedule_date',
        label: 'Session Date',
        widget: 'datepicker',
        options: {
          ...tableColumnDefaultOption,
          headerWidget: DateRangePicker,
          headerWidgetInputType: 'daterange',
          customBodyRenderLite: (dataIndex) => {
            const sessionDate = momentsData.data[dataIndex]?.schedule?.date
            return <span>{sessionDate}</span>
          }
        }
      },
      {
        name: 'visibility',
        label: 'Visibility',
        options: {
          ...tableColumnDefaultOption,
          customBodyRenderLite: (dataIndex) => {
            const visibility = momentsData.data[dataIndex]?.visibility
            return (
              <span>
                {visibility}
                <Link
                  href="#"
                  onClick={() =>
                    setOpenTable({
                      table: 'visibility',
                      props: {
                        momentId: momentsData.data[dataIndex].uuid,
                        title: `${visibility} students`
                      }
                    })
                  }
                >
                  view students
                </Link>
              </span>
            )
          }
        }
      },
      {
        name: 'view_count',
        label: 'Views',
        editable: false,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true
        }
      },
      {
        name: 'comments_count',
        label: 'Comments',
        options: {
          ...tableColumnDefaultOption,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const value = momentsData.data[dataIndex]?.comments_count
            if (value === 0) return <span>{value}</span>
            return (
              <span>
                {value}
                <Link
                  href="#"
                  onClick={() =>
                    setOpenTable({
                      table: 'comments',
                      props: {
                        momentId: momentsData.data[dataIndex].uuid,
                        title: 'Comments'
                      }
                    })
                  }
                >
                  view comments
                </Link>
              </span>
            )
          }
        }
      },
      {
        name: 'likes_count',
        label: 'Likes',
        options: {
          ...tableColumnDefaultOption,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const value = momentsData.data[dataIndex]?.likes_count
            if (value === 0) return <span>{value}</span>
            return (
              <span>
                {value}
                <Link
                  href="#"
                  onClick={() =>
                    setOpenTable({
                      table: 'likes',
                      props: {
                        momentId: momentsData.data[dataIndex].uuid,
                        title: `Liked by`
                      }
                    })
                  }
                >
                  view likes
                </Link>
              </span>
            )
          }
        }
      },
      {
        name: 'share_count',
        label: 'Share Count',
        options: {
          ...tableColumnDefaultOption,
          disableFilter: true
        }
      },
      {
        name: 'share_view_count',
        label: 'Share View Count',
        options: {
          ...tableColumnDefaultOption,
          disableFilter: true
        }
      }
    ])
  }, [momentsData, expandedRows])

  const Table = modalTableMap[openTable.table]

  const closeModal = () => {
    setOpenTable({ table: '', props: {} })
  }

  const innerTable = Table && (
    <MuiThemeProvider theme={tableTheme}>
      <Table {...openTable.props} />
    </MuiThemeProvider>
  )

  return (
    <>
      <BaseModal open={!!Table} onCloseModal={closeModal}>
        {innerTable}
      </BaseModal>

      <BaseEntity
        entity={constants.MOMENTS}
        label={constants.MOMENTS}
        columns={modifiedColumns}
        data={momentsData}
        fetchData={fetchClassroomMomentsData}
        readOnly
      />
    </>
  )
}

export default Membership
