import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'invoice_count',
    label: 'Invoice Count',
    ...tableColumnDefaultOption
  },
  {
    name: 'author',
    label: 'Dispatched by',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'author_name'
    }
  },
  {
    name: 'teacher_name',
    label: 'Teacher Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    }
  },
  {
    name: 'value',
    label: 'Value',
    ...commonAttributes.amount
  },
  {
    name: 'notes',
    label: 'Notes',
    ...tableColumnDefaultOption
  },
  {
    name: 'mode',
    label: 'Mode of Dispatch',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'utr_id',
    label: 'UTR ID',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  }
]
