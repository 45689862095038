import { apiEndpoints } from 'api/common'
import { tableColumnDefaultOption, commonAttributes } from 'app/config'
import { validateNotEmpty, validateName, validateMobile } from 'app/validators'

export const columns = [
  {
    name: 'uuid',
    // The data created from modal form
    formDataKey: 'uuid',
    label: 'Id',
    editable: false,
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('tribe'),
    options: {
      display: false
    },
    conditionalRender: (data) => {
      return (
        new URLSearchParams(window.location.search).get('action') !==
        'AddToTribe'
      )
    }
  },
  {
    name: 'uuid',
    formDataKey: 'uuid',
    label: 'Tribe',
    defaultValue: () => new URLSearchParams(window.location.search).get('club'),
    options: {
      display: false
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.tribe}/alfred`
    },
    widget: 'autocomplete',
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          tribe: `${item.name || 'N/A'} - ${item.club.name} - ${
            item?.leader?.user?.name ||
            `${item?.leader?.user?.first_name || ''} ${
              item?.leader?.user?.last_name || ''
            }`
          }`
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      }

      return data.results.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    resultStructure: {
      keyName: 'uuid',
      keyValue: 'uuid'
    },
    getOptionLabelKey: 'tribe',
    getOptionSelectedKey: 'uuid',
    conditionalRender: (data) => {
      return (
        new URLSearchParams(window.location.search).get('action') ===
        'AddToTribe'
      )
    }
  },
  {
    name: 'tribe.club.name',
    label: 'Club',
    editable: false,
    validate: validateNotEmpty,
    defaultValue: () => new URLSearchParams(window.location.search).get('club'),
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'club_name'
    },
    conditionalRender: (data) => {
      return (
        new URLSearchParams(window.location.search).get('action') !==
        'AddToTribe'
      )
    }
  },
  {
    name: 'tribe.name',
    label: 'Name',
    editable: false,
    validate: validateNotEmpty,
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('tribeName'),
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'name'
    },
    conditionalRender: (data) => {
      return (
        new URLSearchParams(window.location.search).get('action') !==
        'AddToTribe'
      )
    }
  },
  {
    name: 'tribe.leader.user.first_name',
    label: 'Leader First Name',
    validate: validateName,
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('firstName') || '',
    editable: false,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_first_name'
    },
    conditionalRender: (data) => {
      return (
        new URLSearchParams(window.location.search).get('action') !==
        'AddToTribe'
      )
    }
  },
  {
    name: 'tribe.leader.user.last_name',
    label: 'Leader Last Name',
    validate: validateName,
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('lastName') || '',
    editable: false,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_last_name'
    },
    conditionalRender: (data) => {
      return (
        new URLSearchParams(window.location.search).get('action') !==
        'AddToTribe'
      )
    }
  },
  {
    name: 'tribe.leader.user.phone_number',
    label: 'Leader Phone Number',
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('phoneNumber') || '',
    editable: false,
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_phone_number'
    },
    conditionalRender: (data) => {
      return (
        new URLSearchParams(window.location.search).get('action') !==
        'AddToTribe'
      )
    }
  },
  {
    name: 'member.user.first_name',
    label: 'Member First Name',
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'member_first_name'
    }
  },
  {
    name: 'member.user.last_name',
    label: 'Member Last Name',
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'member_last_name'
    }
  },
  {
    name: 'member.user.phone_number',
    label: 'Member Phone Number',
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'member_phone_number'
    }
  },
  {
    name: 'start_date',
    label: 'Start Date',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'start_date'
    }
  },
  {
    name: 'end_date',
    label: 'End Date',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'end_date'
    }
  },
  {
    name: 'status',
    label: 'Status',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'status'
    }
  },
  {
    name: 'source',
    label: 'Source',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'source'
    }
  },
  {
    name: 'notes',
    label: 'Notes',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'notes'
    }
  }
]
