import styled from 'styled-components'

export const ModalContent = styled.div`
  width: 70vw;
  height: 70vh;
  border-radius: 4px;
  overflow: hidden scroll;
`

export const modalSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const closeIconSx = {
  position: 'absolute',
  right: 12,
  top: 12,
  color: (theme) => theme.palette.grey[500],
  zIndex: 1
}

export const boxSx = {
  position: 'relative'
}
