import React from 'react'
import * as styles from './styles'
import { Tooltip } from '@material-ui/core'

const Pill = (
  { url,
    text,
    pillStyles,
    selectedPillStyles,
    isSelected,
    truncationLength = 100,
    showPillIcon = true,
    onClick
  }) => {

  const isTextLong = text?.length > truncationLength
  const truncatedText = isTextLong ? `${text.substring(0, truncationLength).trim()}...` : text

  const handleClick = () => {
    if (onClick) onClick()
  }

  const pillContent = (
    <div
      style={{
        ...styles.PillStyles,
        ...pillStyles,
        ...(isSelected && { ...styles.SelectedPillStyle, ...selectedPillStyles }),
      }}
      onClick={handleClick}
    >
      {showPillIcon && <styles.Icon src={url} />}
      {truncatedText}
    </div>
  )

  return isTextLong ? (
    <Tooltip title={text} arrow>
      {pillContent}
    </Tooltip>
  ) : (
    pillContent
  )
};

export default Pill
