import styled, { css } from 'styled-components'

export const Card = styled.div`
  border: 1px solid var(--oh-black-tertiary);
  border-radius: 10px;
  padding: 12px 12px;
  margin: 0.75em;
  margin-right: 2em;
  margin-top: 1.5em;
`

export const RowFlexGap = styled.div`
  display: flex;
  direction: row;
  gap: 2em;
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
`
