import { isEmpty } from 'lodash'

import { sentryLogger } from 'sentry'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, post } from '../request'

const fetchProgramEnrolments = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.programs}/enrolments`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching program enrolments')
    throw error
  }
}

const getExplorationStandards = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'EXPLORATION_STANDARDS'
  })

  try {
    const response = await get(requestUrl)

    return response.data.results[0].value
  } catch (error) {
    sentryLogger(error, 'error in fetching exploration standards')
    throw error
  }
}

const getProgramsList = async () => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.programs}/programs`)

  try {
    const response = await get(requestUrl)

    return response.data.results
  } catch (error) {
    sentryLogger(error, 'error in fetching programs list')
    throw error
  }
}

const getProgramCost = async ({ queries }) => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.programs}/cost`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching programs cost')
    throw error
  }
}

const terminateProgramEnrolment = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.programs}/enrolment/terminate`,
      {
        end_date: data?.endDate,
        program_enrolment_uuid: data.enrolment_uuid,
        termination_reason: data.reason,
        termination_notes: data.notes
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in terminate enrolment')
    throw error
  }
}

const fetchProgramCapacity = async (data) => {
  try {
    const requestUrl = makeRequestUrl(
      `${apiEndpoints.programs}/enrolment/capacity`,
      data
    )
    const response = await get(requestUrl)
    return response.data.results[0]
  } catch (error) {
    sentryLogger(error, 'error in fetching program capacity')
    throw error
  }
}

export {
  fetchProgramCapacity,
  fetchProgramEnrolments,
  getExplorationStandards,
  getProgramCost,
  getProgramsList,
  terminateProgramEnrolment
}
