import { isEmpty } from 'lodash'

import constants from 'app/constants'
import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl, showToast } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const batchRequestStructure = (data) => ({
  teacher: data.teacher,
  course: data.course,
  fee: data.fee,
  coins_per_session: data.coins_per_session,
  coins_per_month: data.coins_per_month,
  batch_type: data.type,
  centre: data.centre,
  enrolment_capacity: data.enrolment_capacity,
  publish: data.publish,
  reason: data.reason,
  single_session_capacity: data.single_session_capacity,
  batch_activities: data?.batch_activities
})

const fetchBatch = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.fetchBatch, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    // showToast('Oops, Error in fetching batch', constants.ERROR)

    sentryLogger(error, 'error in fetching batch')
    throw error
  }
}

const showRecordings = async (data) => {
  try {
    const response = await get(
      makeRequestUrl(`${apiEndpoints.oh_schedules}/dashboard`, {
        batch_uuid: data.uuid
      })
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in show recording')
    throw error
  }
}

const createBatch = async (data) => {
  try {
    const response = await post(apiEndpoints.batch, batchRequestStructure(data))
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating batch')
    throw error
  }
}

const updateBatch = async (data) => {
  validateUpdateRequest(data)
  try {
    const response = await patch(
      `${apiEndpoints.batch}/${data.uuid}/`,
      batchRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating batch')
    throw error
  }
}

const updateBatchIsRecordable = async (data, is_recordable) => {
  try {
    const response = await patch(`${apiEndpoints.batch}/${data.uuid}/`, {
      is_recordable
    })
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating recording')
    throw error
  }
}

const deleteBatch = async (value) => {
  const { ids, queries } = value
  validateDeleteRequest(ids)

  try {
    if (ids.length === 1) {
      await remove(`${apiEndpoints.batch}/${ids[0]}/`, {}, queries)

      return ids
    } else {
      throw new Error('Only one batch can be deleted at a time')
    }
  } catch (error) {
    sentryLogger(error, 'error in deleting batch')
    throw error
  }
}

const getBatchData = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.batch, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching batch')
    throw error
  }
}

export {
  fetchBatch,
  showRecordings,
  createBatch,
  updateBatch,
  updateBatchIsRecordable,
  deleteBatch,
  getBatchData
}
