/* eslint-disable react/prop-types */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import _, { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  getAttendanceSessions,
  getSessionDetails
} from 'app/pages/Dashboard/ParentDashboard/helpers'

import * as styles from './styles'

import { NotFoundBlock } from '../../../../../components/NotFoundBlock'
import { TitleDescription } from '../../../../../components/TitleDescription'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

export const Attendance = (props) => {
  const { selectedParentDetails, attendanceData } = props

  const [attendanceBlocks, setAttendanceBlocks] = useState([])

  useEffect(() => {
    ;(async () => {
      if (!isEmpty(attendanceData) && selectedParentDetails.uuid) {
        const sessions = await getSessionDetails(
          undefined,
          selectedParentDetails.uuid,
          { limit: 2000 }
        )
        const results = getAttendanceSessions(sessions)

        const blocks = [
          {
            heading: 'upcoming session',
            value: results.upcoming,
            subtitle: results.upcomingCourse
          },
          {
            heading: 'last attended session',
            value: results.lastAttended,
            subtitle: results.lastAttendedCourse
          }
        ]

        setAttendanceBlocks(blocks)
      }
    })()
  }, [attendanceData])

  return !isEmpty(attendanceData) ? (
    <styles.Body>
      <styles.InfoBlock>
        <TitleDescription
          heading={`Attendance % (${dayjs().format('MMMM')})`}
          align="left"
          key={'attend'}
          description={
            attendanceData?.hasAttendees
              ? ` ${attendanceData?.attendancePercentage} %`
              : 'No sessions scheduled yet'
          }
        />
        {attendanceBlocks.map((el, index) => {
          return (
            <TitleDescription
              key={'attend' + index}
              heading={el.heading}
              description={el.value !== 'Invalid Date' ? el.value : 'NA'}
              // subtitle={el.subtitle}
            />
          )
        })}
      </styles.InfoBlock>
    </styles.Body>
  ) : (
    <NotFoundBlock message="Oh no! User has no enrolments." />
  )
}
