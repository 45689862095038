import React from 'react'
import * as styles from './styles'

const FlagChip = ({ flag, flagBoxStyles }) => {
  const { value, icon } = flag
  return (
    <div style={{ ...styles.FlagBox, ...flagBoxStyles }}>
      <img src={icon} height={14} width={14} />
      <styles.FlagText>{value}</styles.FlagText>
    </div>
  )
}

export default FlagChip
