import React from 'react'

import { commonAttributes, tableColumnDefaultOption } from 'app/config'
export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'provider_id',
    label: 'Receiver ID',
    ...tableColumnDefaultOption
  },
  {
    name: 'destination',
    label: 'Phone Number',
    ...tableColumnDefaultOption
  },
  {
    name: 'user',
    label: 'Name',
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) =>
        value ? (
          <span>
            {value?.first_name} {value?.last_name}
          </span>
        ) : null
    }
  }
]
