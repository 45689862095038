import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { get, isEmpty } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api'
import {
  commonAttributes,
  tableColumnDefaultOption,
  userStatusOptions
} from 'app/config'
import { TICKET_STATUS_OPTIONS } from 'app/constants'
import { validateNotEmpty } from 'app/validators'

dayjs.extend(isSameOrAfter)

export const columns = [
  {
    ...commonAttributes.date,
    modalEdit: false,
    disabledInForm: true,
    widget: 'datepicker',
    name: 'created',
    label: 'reported date',
    options: {
      ...commonAttributes.date.options,
      filterKey: 'reported_date'
    }
  },
  {
    name: 'user_phone_number',
    label: 'Phone Number',
    disabledInForm: true,
    modalEdit: false,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'user_phone_number'
    }
  },
  {
    name: 'user_type',
    label: 'user status',
    widget: 'select',
    isValueKey: true,
    widgetFilter: true,
    valueLabelMappings: userStatusOptions,
    disabledInForm: true,
    modalEdit: false,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'user_type',
      customBodyRender: (value) => {
        console.log(value)
        return value.toString() ? <span>{userStatusOptions[value]}</span> : null
      }
    }
  },
  {
    name: 'centre',
    label: 'Centre',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    disabledInForm: true,
    modalEdit: false,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
              uuid: item.uuid,
              name: item.name
            }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'centre_preference',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.centre
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_name',
      setCellProps: () => ({
        style: {
          minWidth: '240px',
          maxWidth: '240px'
        }
      }),
      customBodyRender: (value) => (value ? <span>{value?.name}</span> : null)
    }
  },
  {
    name: 'title',
    label: 'ticket title',
    modalEdit: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'mode',
    label: 'communication mode',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    modalEdit: true,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item ? { name: item, uuid: item } : null
      }
      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'mode',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CRM_INTERACTION_MODES`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'mode',
      customBodyRender: (value) => <span>{value ? value : 'N/A'}</span>,
      setCellProps: () => ({
        style: {
          minWidth: '240px',
          maxWidth: '240px'
        }
      })
    }
  },
  {
    name: 'source',
    label: 'raised on',
    modalEdit: false,
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'raised_on',
      customBodyRender: (value) => <span>{value ? value : 'N/A'}</span>
    }
  },
  {
    ...commonAttributes.date,
    widget: 'datepicker',
    name: 'follow_up_date',
    label: 'follow-up date',
    minDate: (data) => {
      return dayjs().format('YYYY-MM-DD')
    },
    maxDate: (data, setFieldValue, formData) => {
      if (
        data?.status === TICKET_STATUS_OPTIONS.RESOLVED &&
        data?.follow_up_date !== dayjs().format('YYYY-MM-DD') &&
        dayjs(get(data, 'follow_up_date')).isSameOrAfter(
          dayjs().format('YYYY-MM-DD')
        ) &&
        formData?.status !== TICKET_STATUS_OPTIONS.RESOLVED
      )
        setFieldValue('follow_up_date', dayjs().format('YYYY-MM-DD'))
      return data?.status === TICKET_STATUS_OPTIONS.RESOLVED &&
        formData?.status !== TICKET_STATUS_OPTIONS.RESOLVED &&
        dayjs(get(data, 'follow_up_date')).isSameOrAfter(
          dayjs().format('YYYY-MM-DD')
        )
        ? dayjs().format('YYYY-MM-DD')
        : undefined
    },
    modalEdit: true,
    disableInForm: false,
    options: {
      ...commonAttributes.date.options,
      filterKey: 'follow_up_date',
      customBodyRender: (value) => <span>{value ? value : 'N/A'}</span>
    }
  },
  {
    ...commonAttributes.date,
    widget: 'datepicker',
    name: 'resolved_on',
    label: 'resolved date',
    modalEdit: false,
    disableInForm: true,
    validate: validateNotEmpty,
    options: {
      ...commonAttributes.date.options,
      customBodyRender: (value) => <span>{value ? value : 'N/A'}</span>
    }
  }
]
