/* eslint-disable react/prop-types */
import React from 'react'

import { columns, tableOptions } from './config'
import { StyledMUIDataTable } from './styles'

export const DataTable = ({ title, data, options = {} }) => {
  return React.useMemo(
    () => (
      <StyledMUIDataTable
        title={title}
        columns={columns}
        data={data}
        options={{
          ...tableOptions,
          customFooter: () => <tfoot />,
          ...options
        }}
      />
    ),
    [data]
  )
}
