import constants from 'app/constants'

import {
  commonStructure,
  handleEntitiesUpdate,
  dataResponseHandler,
  dataFetchingHandler
} from './reducer.helper'

import * as types from '../types/communityEntity'

const initialState = {
  clubData: commonStructure(),
  referralCodeData: commonStructure(),
  clubRegistrationData: commonStructure(),
  tribeData: commonStructure(),
  membershipData: commonStructure(),
  inactiveTribeMemberData: commonStructure(),
  waitlistQueuedData: commonStructure(),
  waitlistTribeSelectionData: commonStructure(),
  waitlistConvertedData: commonStructure(),
  waitlistOthersData: commonStructure(),
  momentsData: commonStructure(),
  openbookData: commonStructure()
}

const communityEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLUB_DATA:
    case types.FETCH_TRIBE_DATA:
    case types.FETCH_TRIBE_MEMBER_DATA:
    case types.FETCH_INACTIVE_TRIBE_MEMBER_DATA:
    case types.FETCH_REFERRAL_CODE_DATA:
    case types.FETCH_CLUB_REGISTRATION_DATA:
    case types.FETCH_WAITLIST_QUEUED_DATA:
    case types.FETCH_WAITLIST_TRIBE_SELECTION_DATA:
    case types.FETCH_WAITLIST_CONVERTED_DATA:
    case types.FETCH_WAITLIST_OTHERS_DATA:
    case types.FETCH_CLASSROOM_MOMENTS_DATA:
    case types.FETCH_OPENBOOK_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_CLUB_DATA_SUCCESS:
    case types.FETCH_TRIBE_DATA_SUCCESS:
    case types.FETCH_TRIBE_MEMBER_DATA_SUCCESS:
    case types.FETCH_INACTIVE_TRIBE_MEMBER_DATA_SUCCESS:
    case types.FETCH_REFERRAL_CODE_DATA_SUCCESS:
    case types.FETCH_CLUB_REGISTRATION_DATA_SUCCESS:
    case types.FETCH_WAITLIST_QUEUED_DATA_SUCCESS:
    case types.FETCH_WAITLIST_TRIBE_SELECTION_DATA_SUCCESS:
    case types.FETCH_WAITLIST_CONVERTED_DATA_SUCCESS:
    case types.FETCH_WAITLIST_OTHERS_DATA_SUCCESS:
    case types.FETCH_CLASSROOM_MOMENTS_DATA_SUCCESS:
    case types.FETCH_OPENBOOK_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    case types.UPDATE_COMMUNITY_DATA_SUCCESS:
      return handleEntitiesUpdate(state, {
        ...action,
        entity:
          action?.entity === constants.MEMBERSHIP
            ? constants.TRIBE
            : action.entity
      })

    default:
      return state
  }
}

export { communityEntityReducer }
