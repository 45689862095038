import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { confirm, eventEmitter } from 'app/helpers'

import DeleteForm from './forms/DeleteForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const MasterForm = (props) => {
  const [formData, setFormData] = React.useState({
    uuid: '',
    reason: ''
  })

  const classes = useStyles()
  const formRef = React.useRef(null)

  const { isModalOpen, closeModal, onSaveClick, action, data } = props

  useEffect(() => {
    const tempData = { ...formData }
    tempData.uuid = data?.uuid
    setFormData(tempData)
  }, [data])

  const handleOnSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current
      try {
        const errors = await validateForm(values)

        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        } else {
          // form is valid
          try {
            const onSave = async () => {
              await onSaveClick(values, action)
            }
            const onError = () => {
              eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
            }
            if (action === 'delete') {
              const isConfirm = confirm(
                onSave,
                'This will delete the batch and all linked schedules, are you sure you want to delete this batch?',
                onError
              )
            } else onSave()
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log('error is:', error)
      }
    }
  }, [onSaveClick, action])

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {({}) => {
          return (
            <Form>
              <FormControl className={classes.formControl} fullWidth>
                {action === 'delete' ? (
                  <DeleteForm formData={formData} />
                ) : null}
              </FormControl>
            </Form>
          )
        }}
      </Formik>
    )
  }, [action, classes, formData])

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}
    >
      {formElement}
    </ModalForm>
  )
}

MasterForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default MasterForm
