import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 25px 50px;

  ${(props) =>
    props.disabled &&
    css`
      .MuiInput-underline.Mui-disabled:before {
        border-bottom: 0px;
      }
    `}

  h4 {
    font-weight: 600;
  }

  h5 {
    color: #ef5d34;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .brand-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .brand-leaf {
      width: 15%;
      height: 100%;
      margin-left: -2%;
    }

    .brand-logo {
      align-self: center;
    }
  }

  .brand-footer {
    margin: 100px 0px;
    height: 100px;
    border-bottom: 2px solid #adadada1;
    display: flex;
    justify-content: center;
    align-items: end;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 10px;

  .input {
    margin: 10px 0;
    display: flex;
    h6 {
      display: flex;
      align-items: end;
      flex-basis: 33%;
      font-weight: 600;
    }
  }
`

export const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0px;

  .cart-table {
    padding: 20px;
    background-color: #ffe1d9;
    > * {
      margin: 10px 0;
    }
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;

  .MuiButton-contained {
    border-radius: 2em;
    margin-top: 0;
    font-weight: bold;
    text-transform: capitalize;
  }
`

export const Headings = styled.div`
  display: flex;
  & > h6 {
    flex-basis: 25%;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    small {
      font-size: 14px;
    }
  }
`

export const CartItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & > h6 {
    flex-basis: 25%;
    text-align: center;
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    right: 0px;
    cursor: pointer;
  }

  & > svg:hover {
    color: red;
  }
`

export const Summary = styled.div`
  .summary {
    width: 100%;
    display: flex;
    flex-direction: column;

    .summary-item {
      display: flex;
      justify-content: space-between;
      margin: 5px 0px;

      h6:nth-child(1) {
        font-weight: bold;
        display: flex;
        align-items: center;
      }

      input {
        text-align: right;
        font-size: 1.25rem;
        color: #212121;
      }

      input:disabled {
        :before {
          border: 0px;
        }
      }
    }
  }
`
