import {
  FormControl as FC,
  FormLabel as FL,
  Typography as TG,
  Button as Btn,
  FormHelperText as FHT
} from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin: 15px 8px 8px 8px;
`

export const FormControl = styled(FC)`
  margin-bottom: 8px;
`

export const FormLabel = styled(FL)`
  margin-bottom: 3px !important;
`
export const Typography = styled(TG)`
  ${(props) =>
    css`
      ${props.$css}
    `}
`

export const Button = styled(Btn)`
  width: 200px;
  border-radius: 32px;
  padding: 8px;
  margin-bottom: 5px;
  margin-top: 14px;
  .MuiButton-label {
    text-align: center;
    font-size: 16px;
    text-transform: lowercase;
  }
  background-color: #f3c520;
`

export const RedirectCTA = styled.div`
  display: flex;
  flex-direction: column;
`
export const FieldGroup = styled.div`
  margin-bottom: 8px;
  flex-grow: 1;
`
export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Icon = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-left: 12px;
`
export const FormHelperText = styled(FHT)`
  color: #f44336;
`
