import styled from 'styled-components'

export const Wrapper = styled.div`
  border: ${(props) => (props.isToday ? '1px solid #EF5C34' : 'none')};
  border-radius: 8px;
  padding: 5px;
`

export const Date = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.56);
  margin: 0px 0px 2px 0px;
`
export const Day = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: ${(props) =>
    props.isToday ? 'rgba(0, 0, 0, 0.86)' : 'rgba(0, 0, 0, 0.56)'};
`
