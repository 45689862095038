import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FBF5ED;
  border-radius: 10px;
  border: 0.5px solid #6A6A6E;
  padding: 1.25em 1em;
  cursor : pointer;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const Icon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 0.25em;
`

export const Text = styled.div`
  font-size: 0.95em;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 0.27em;
  color: #EF5C34;
  margin-right: 0.25em;
`;

export const Count = styled.div`
  font-size: 0.9em;
  font-weight: bold;
  margin-left: 0.2em;
`;
