import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { isEmpty } from 'lodash'

import { updateAttendance } from 'api'
import { getAttendanceData } from 'api/attendance'
import { getBatchData } from 'api/batch'
import { AutoComplete } from 'app/components/generic/FormFields'
import History from 'app/components/generic/history/history'
import { Loading } from 'app/components/generic/LottieComponents'
import constants from 'app/constants'
import { confirm, eventAlert } from 'app/helpers'

import SliderCard from './components/SliderCard'
import { dataColumns, emptyStudent, studentType } from './config'
import {
  fetchParent,
  fetchSchedules,
  fetchStudent,
  getCentre,
  updateAttendanceData
} from './helpers'
import * as styles from './styles'

import BookDemoClass from '../Class/Inventory/components/ModalsAndActions/components/BookDemoClass'
import { useBatchSelection } from '../Class/Inventory/components/ModalsAndActions/hooks/useBatchSelection'
import { BookingTypes } from '../Class/Inventory/constants'

dayjs.extend(customParseFormat)
dayjs.extend(minMax)

const Attendance = () => {
  const [formData, setFormData] = React.useState({
    centre: undefined,
    student: undefined,
    parent: undefined
  })
  const handle = useFullScreenHandle()
  const [isLoading, setIsLoading] = useState(true)
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [schedule, setSchedule] = React.useState([])
  const [filterAttendance, setFilterAttendance] = React.useState([])
  const [sliderAttendance, setSliderAttendance] = React.useState({})
  const [startDate, setStartDate] = useState(new Date())
  const [attendanceType, setAttendanceType] = useState('present')
  const centreDefaultValue = localStorage.getItem('CENTRE_SELECT')
    ? JSON.parse(localStorage.getItem('CENTRE_SELECT'))
    : null
  const [isBookDemoClassModalOpen, setIsBookDemoClassModalOpen] =
    React.useState(false)
  const [bookingType, setBookingType] = React.useState(null)
  const [cartItems, setCartItems] = React.useState([])
  const { deselectBatch } = useBatchSelection()
  const [selectedBatchType, setSelectedBatchType] = React.useState()
  let timer

  const handleChange = (name, value) => {
    const data = {
      ...formData
    }

    switch (name) {
      case 'centre':
        data.centre = value
        setFormData(data)
        localStorage.setItem('CENTRE_SELECT', JSON.stringify(value))
        break
      case 'student':
        if (value) data.parent = undefined
        data.student = value
        setFormData(data)
        localStorage.setItem('STUDENT', JSON.stringify(value))
        break
      case 'parent':
        if (value) data.student = undefined
        data.parent = value
        setFormData(data)
        localStorage.setItem('PARENT', JSON.stringify(value))
        break
    }
  }

  React.useEffect(() => {
    // get centre name from url query
    const queries = new URLSearchParams(window.location.search)

    const centreName = queries.get('centre_name')
    if (centreName) {
      async function centres() {
        const response = await getCentre(centreName)
        if (response.length) handleChange('centre', response[0])
      }
      centres()
    }
  }, [])

  const fetchValues = async (type, id) => {
    let res
    if (type === 'parent' || type === 'student') {
      res = await fetchSchedules(
        type,
        id,
        setIsLoading,
        formData,
        setSchedule,
        setFilterAttendance,
        timer,
        startDate
      )
    } else {
      res = await fetchSchedules(
        null,
        null,
        setIsLoading,
        formData,
        setSchedule,
        setFilterAttendance,
        timer,
        startDate
      )
    }
    return res
  }

  React.useEffect(() => {
    setIsLoading(true)
    fetchValues(null, null)
  }, [formData.centre])

  React.useEffect(() => {
    if (formData.student?.uuid) {
      setIsLoading(true)
      fetchValues('student', formData.student?.uuid)
    } else fetchValues(null, null)
  }, [formData.student])

  React.useEffect(() => {
    if (formData.parent?.uuid) {
      setIsLoading(true)
      fetchValues('parent', formData.parent?.uuid)
    } else fetchValues(null, null)
  }, [formData.parent])

  React.useEffect(() => {
    if (startDate) {
      if (formData.parent?.uuid) {
        setIsLoading(true)
        fetchValues('parent', formData.parent?.uuid)
      } else if (formData.student?.uuid) {
        setIsLoading(true)
        fetchValues('student', formData.student?.uuid)
      } else fetchValues(null, null)
    }
  }, [startDate])

  const attendanceChange = async (e, type, el) => {
    if (type === 'attended') {
      setComponentDisabled(true)
      const onSave = async () => {
        let attendanceResponse
        try {
          attendanceResponse = await updateAttendance(el)
        } catch (error) {
          eventAlert(error.message, constants.ERROR)
        } finally {
          if (!isEmpty(attendanceResponse)) {
            updateAttendanceData(
              attendanceResponse,
              formData,
              filterAttendance,
              setFilterAttendance,
              schedule,
              setSchedule,
              sliderAttendance,
              setSliderAttendance
            )
          }
        }
      }
      setComponentDisabled(false)

      const onError = async () => {
        setComponentDisabled(false)
      }

      if (!dayjs(startDate).isToday()) {
        confirm(onSave, 'You are marking attendance in the past', onError)
      } else {
        onSave()
      }
    }
  }

  const onHandleChange = (update) => {
    setIsLoading(true)
    setStartDate(update)
    const type = dayjs(update).isAfter(dayjs(), 'day') ? 'future' : 'present'
    setAttendanceType(type)
  }

  const onHandleDemoClassCloseClick = () => {
    setIsBookDemoClassModalOpen(false)
    setBookingType(null)
    deselectBatch({ bulk: true })
  }

  const onBookingSuccess = () => {
    setCartItems([])
  }

  const fetchBatchData = async (key) => {
    let response = await getBatchData({
      queries: {
        uuid: key
      }
    })
    return response
  }

  const onSpotBookingHandler = async (value) => {
    const batchResponse = await fetchBatchData(value.batch.uuid)
    setCartItems(batchResponse?.results)
    setSelectedBatchType(value?.batch?.type)
    setBookingType(BookingTypes.EXPLORATION)
    setIsBookDemoClassModalOpen(true)
  }
  const page = React.useMemo(() => {
    return (
      <>
        <FullScreen handle={handle}>
          <styles.Page>
            <styles.Header>
              <styles.RightHeader>
                <styles.DropdownContainer>
                  <AutoComplete
                    name="centre"
                    label="Centre *"
                    size="small"
                    multiple={false}
                    fetchValues={getCentre}
                    onChange={handleChange}
                    initialSelectIndex={2}
                    {...(formData?.centre
                      ? { defaultValue: formData?.centre }
                      : centreDefaultValue?.value
                      ? { defaultValue: centreDefaultValue }
                      : null)}
                  />
                </styles.DropdownContainer>
                <styles.DropdownContainer>
                  <AutoComplete
                    async={true}
                    name="student"
                    label="Student *"
                    size="small"
                    multiple={false}
                    fetchValues={fetchStudent}
                    onChange={handleChange}
                    emptyValue={formData.student ? false : true}
                  />
                </styles.DropdownContainer>
                <styles.DropdownContainer>
                  <AutoComplete
                    async={true}
                    name="parent"
                    label="Parent *"
                    size="small"
                    multiple={false}
                    fetchValues={fetchParent}
                    onChange={handleChange}
                    emptyValue={formData.parent ? false : true}
                  />
                </styles.DropdownContainer>
              </styles.RightHeader>
              <styles.Container>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={onHandleChange}
                  placeholderText={'Date'}
                  className="custom-date-picker-input"
                />
              </styles.Container>
            </styles.Header>
            {isLoading ? (
              <styles.Loader>
                <Loading />
              </styles.Loader>
            ) : formData.student ? (
              <styles.TableContainer>
                <History
                  type={dataColumns[studentType[attendanceType]]?.type}
                  heading={dataColumns[studentType[attendanceType]]?.heading}
                  userInfo={formData.student?.user}
                  tableHeadings={
                    dataColumns[studentType[attendanceType]]?.tableHeadings
                  }
                  accessors={
                    dataColumns[studentType[attendanceType]]?.accessors
                  }
                  data={filterAttendance}
                  readOnly={true}
                  editableDetails={false}
                  addDetails={false}
                  headerData={true}
                  componentChange={attendanceChange}
                  componentDisabled={componentDisabled}
                />
              </styles.TableContainer>
            ) : formData.parent ? (
              <styles.ChildCards>
                <styles.TableContainer>
                  <History
                    type={dataColumns[studentType[attendanceType]]?.type}
                    heading={dataColumns[studentType[attendanceType]]?.heading}
                    userInfo={formData.parent?.students?.user || emptyStudent}
                    tableHeadings={
                      dataColumns[studentType[attendanceType]]?.tableHeadings
                    }
                    accessors={
                      dataColumns[studentType[attendanceType]]?.accessors
                    }
                    data={filterAttendance}
                    readOnly={true}
                    editableDetails={false}
                    addDetails={false}
                    componentChange={attendanceChange}
                    componentDisabled={componentDisabled}
                  />
                </styles.TableContainer>
              </styles.ChildCards>
            ) : (
              <styles.Body>
                {schedule.map((value, index) => {
                  return (
                    <SliderCard
                      key={'Slider card ' + value.uuid}
                      value={value}
                      index={index}
                      attendanceChange={attendanceChange}
                      componentDisabled={componentDisabled}
                      fetchValues={fetchValues}
                      attendance={sliderAttendance}
                      setAttendance={setSliderAttendance}
                      onSpotBookingHandler={() => onSpotBookingHandler(value)}
                    />
                  )
                })}
              </styles.Body>
            )}
          </styles.Page>
        </FullScreen>
        <BookDemoClass
          isModalOpen={isBookDemoClassModalOpen}
          bookingType={bookingType}
          cartItems={cartItems}
          onBookingSuccess={onBookingSuccess}
          onHandleDemoClassCloseClick={onHandleDemoClassCloseClick}
          setIsBookDemoClassModalOpen={setIsBookDemoClassModalOpen}
          selectedBatchType={selectedBatchType}
          setCartItems={setCartItems}
          setBookingType={setBookingType}
          isOnSpotBooking={true}
        />
      </>
    )
  }, [
    formData,
    isLoading,
    schedule,
    componentDisabled,
    startDate,
    onSpotBookingHandler,
    filterAttendance
  ])

  return <>{page}</>
}

export default Attendance
