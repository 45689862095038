import React, { useEffect } from 'react'
import { CartProvider } from 'react-use-cart'

import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

import { Schedule, Header, ModalsAndActions } from './components'
import { batchTypes, FilterTypes } from './constants'
import { Container, ScheduleWrapper } from './styles'

const Inventory = () => {
  // TODO - change selectedCentres default to [] after batch api supports multiple centre uuids
  const [selectedCentres, setSelectedCentres] = React.useState('')
  const [selectedGrades, setSelectedGrades] = React.useState([])
  const [selectedCategories, setSelectedCategories] = React.useState([])

  const localStorageBatchType = localStorage.getItem('SELECTED_BATCH_TYPE')
    ? JSON.parse(localStorage.getItem('SELECTED_BATCH_TYPE'))
    : null

  const [selectedBatchType, setSelectedBatchType] = React.useState(
    localStorageBatchType ? localStorageBatchType : batchTypes[0].uuid
  )
  const [selectedLevel, setSelectedLevel] = React.useState('')
  const [prevselectedBatchType, setPrevSelectedBatchType] = React.useState(
    batchTypes[0].uuid
  )

  React.useEffect(() => {
    switch (selectedBatchType) {
      case 'class':
        eventEmitter.emit(constants.BATCH_RESELECTION, { bulk_deselect: true })
        return
      case 'explore':
        eventEmitter.emit(constants.BATCH_RESELECTION, { bulk_deselect: true })
        return
      case 'event':
        eventEmitter.emit(constants.BATCH_RESELECTION, { bulk_deselect: true })
        return
      default:
        return
    }
  }, [selectedBatchType])

  const onChangeFilter = (name, value) => {
    switch (name) {
      case FilterTypes.CENTRE:
        setSelectedCentres(value)
        break

      case FilterTypes.GRADE:
        setSelectedGrades(value)
        break

      case FilterTypes.CATEGORY:
        let categories = value
        if (categories.find((el) => el.name.toLowerCase() === 'all subjects'))
          categories = categories.filter(
            (el) => el.name.toLowerCase() === 'all subjects'
          )
        setSelectedCategories(categories)
        break

      case FilterTypes.BATCH_TYPE:
        setPrevSelectedBatchType(selectedBatchType)
        setSelectedBatchType(value)
        break

      case FilterTypes.LEVEL:
        setSelectedLevel(value)
        break

      default:
        break
    }
  }

  return (
    <CartProvider>
      <Container>
        <Header
          selectedCentres={selectedCentres}
          selectedGrades={selectedGrades}
          selectedBatchType={selectedBatchType}
          selectedCategories={selectedCategories}
          selectedLevel={selectedLevel}
          onChangeFilter={onChangeFilter}
        />
        <ScheduleWrapper>
          <Schedule
            selectedCentres={selectedCentres}
            selectedGrades={selectedGrades}
            selectedBatchType={selectedBatchType}
            selectedCategories={selectedCategories}
            selectedLevel={selectedLevel}
          />
        </ScheduleWrapper>
        <ModalsAndActions selectedBatchType={selectedBatchType} />
      </Container>
    </CartProvider>
  )
}

export default Inventory
