import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'

import * as styles from '../styles'

export const DateTime = () => {
  var [date, setDate] = useState(new Date())

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }
  }, [])

  return (
    <styles.Today>
      {dayjs(date).format('ddd, D MMM')}&nbsp;&nbsp;&nbsp;
      {dayjs(date).format('hh:mm:ss A')}
    </styles.Today>
  )
}

export default DateTime
