import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1em;
`;

export const Text = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  color: white;
  margin-right: 1em;
`;

export const Description = styled.div`
  font-size: 1.25em;
  color: white;
  margin-right: 0.25em;
`;

export const CircleIcon = styled.div`
  color: white;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  border: 1px solid white;
  background-color: transparent;
  margin-right: 0.2em;
`;

