import { TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'
import React from 'react'

import CoinIcon from 'assets/images/coin.png'

import * as Styles from '../styles'

const BookWithCoins = ({
  summary,
  reasonForUsingAlfred,
  setReasonForUsingAlfredHandler,
  reasonError,
  showReasonField
}) => {
  const handleReasonChange = (event) => {
    const value = event.target.value
    setReasonForUsingAlfredHandler(value)
  }
  return (
    <>
      <Styles.ContentContainer>
        <Styles.CartValue>
          <span>cart value</span>
          <Styles.CoinsContainer>
            <Styles.TotalAmount>
              <Styles.CoinImg src={CoinIcon} alt="Coin" />
              {summary?.cost_price}
            </Styles.TotalAmount>
          </Styles.CoinsContainer>
        </Styles.CartValue>
        <Styles.Subheading>payment summary</Styles.Subheading>
        <Styles.PaymentSummary>
          <Styles.SummaryItem>
            <span>coin balance</span>
            <Styles.CoinsContainer>
              <Styles.CoinImg src={CoinIcon} />
              <div>{summary?.coin_balance}</div>
            </Styles.CoinsContainer>
          </Styles.SummaryItem>
          <Styles.SummaryItem>
            <span>cart value</span>
            <div>
              <Styles.CoinsContainer>
                <Styles.CoinImg src={CoinIcon} />
                <div style={{ color: 'red' }}>-{summary?.cost_price}</div>
              </Styles.CoinsContainer>
            </div>
          </Styles.SummaryItem>
          {summary?.admission_fee ? (
            <Styles.SummaryItem>
              <span>admission fee</span>
              <div>
                <Styles.CoinsContainer>
                  <Styles.CoinImg src={CoinIcon} />
                  <div style={{ color: 'red' }}>-{summary?.admission_fee}</div>
                </Styles.CoinsContainer>
              </div>
            </Styles.SummaryItem>
          ) : null}
          <Styles.TotalPayable>
            <span>remaining coin balance</span>
            <Styles.CoinsContainer>
              <Styles.CoinImg src={CoinIcon} />
              <div>{summary?.remaining_coin_balance}</div>
            </Styles.CoinsContainer>
          </Styles.TotalPayable>
        </Styles.PaymentSummary>
      </Styles.ContentContainer>

      {showReasonField ? (
        <TextField
          name="reason_for_using_alfred"
          label="Reason for using Alfred instead of the App"
          variant="outlined"
          fullWidth
          required
          value={reasonForUsingAlfred}
          onChange={handleReasonChange}
          error={!!reasonError}
          helperText={reasonError}
          style={{ paddingBottom: '10px' }}
        />
      ) : null}
    </>
  )
}

export default BookWithCoins
