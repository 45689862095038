import { columns as commonColumns } from '../common'

const property_paths = [
  'user.uuid',
  'user.phone_number',
  'user.first_name',
  'user.last_name',
  'user.address',
  'user.bio',
  'user.date_of_birth'
]

export const columns = [
  ...commonColumns.filter((item) => property_paths.includes(item.name))
]
