import { isEmpty } from 'lodash'

import constants from 'app/constants'
import { sentryLogger } from 'sentry'

import { apiEndpoints } from './common'

import { makeRequestUrl, showToast } from '../app/helpers'
import { get, post } from '../request'

const openbookRequestStructure = function (data) {
  return {
    title: data.title,
    body: data.body,
    media: data.media,
    icon: data.icon
  }
}

const mediaDetailsStructure = (data) => {
  return {
    url: data?.media?.secure_url.replace(
      '/upload/',
      `/upload/q_auto:${
        data?.media?.resource_type === 'video' ? 'good' : 'low'
      }/`
    ),
    original_url: data?.media?.secure_url,
    size: data?.media?.bytes,
    type: data?.media?.resource_type,
    provider: 'cloudinary',
    mime: `${data?.media?.resource_type}/${data?.media?.format}`,
    thumbnail:
      data?.media_thumbnail?.secure_url ||
      data?.media?.secure_url.replace('/upload/', '/upload/q_auto:low/')
  }
}

const finalPostObject = (data, mediaDetailsObject) => {
  return {
    icon: data?.title?.uuid,
    title: data?.title?.title,
    body: data?.body,
    media: [mediaDetailsObject?.data?.data?.uuid]
  }
}

const fetchOpenbook = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.openbook}`, { ...queries })

  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    showToast('Oops, Error in fetching openbook', constants.ERROR)
    sentryLogger(error, 'error in fetching openbook')
    throw error
  }
}

const createOpenbook = async (data) => {
  try {
    const media_details_object = mediaDetailsStructure(data)

    const mediaDetailsResponse = await post(
      apiEndpoints.openbookFiles,
      media_details_object
    )

    if (mediaDetailsResponse.data.success) {
      try {
        let final_post_object = finalPostObject(data, mediaDetailsResponse)

        const finalResponse = await post(
          apiEndpoints.openbook,
          openbookRequestStructure(final_post_object)
        )

        return finalResponse.data
      } catch (error) {
        sentryLogger(error, 'error in creating openbook')
        throw error
      }
    } else {
      console.log('Unable to create media details object')
    }
  } catch (error) {
    sentryLogger(error, 'error in creating openbook')
    throw error
  }
}

export { fetchOpenbook, createOpenbook }
