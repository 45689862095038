import TextField from '@material-ui/core/TextField'
import React from 'react'

const DatePicker = (props) => {
  return (
    <TextField
      id="date"
      type="date"
      InputLabelProps={{
        shrink: true
      }}
      {...props}
    />
  )
}

export { DatePicker }
