import { generateOtp as generateOtpApi } from 'api'
import constants from 'app/constants'
import { eventAlert } from 'app/helpers'

export const generateOtp = async (userData) => {
  try {
    const { phone_number: phoneNumber } = userData

    const response = await generateOtpApi({
      phone_number: phoneNumber
    })

    eventAlert(`Your OTP is ${response.otp} !`, constants.SUCCESS)
  } catch (error) {
    console.log(error)
    eventAlert(constants.ERROR_MESSAGE, constants.ERROR)
  }
}
