import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'

import { roundedDecimal } from 'app/helpers'

import { composeBatchDetails } from '../../../../../transformers'

export const getTransformErrors = (schema) => (errors) => {
  return errors.map((error) => {
    const { messages } = get(schema.definitions, error.property.slice(1)) || {}

    if (!isNil(messages)) {
      const errorMessage = get(messages, error.name)

      if (!isEmpty(errorMessage)) {
        return { ...error, message: errorMessage }
      }
    }

    return error
  })
}

export const calculateDiscountPercentage = ({
  isDiscountInPercentage,
  discountPercentage,
  actualPrice,
  customPricing
}) => {
  if (!isNumber(customPricing)) {
    customPricing = actualPrice
  }

  let discountPercentageValue =
    isNumber(actualPrice) && actualPrice > 0
      ? isDiscountInPercentage
        ? discountPercentage || 0
        : ((actualPrice - customPricing) / actualPrice) * 100
      : 0

  discountPercentageValue = isNumber(discountPercentageValue)
    ? Number.isInteger(discountPercentageValue)
      ? discountPercentageValue
      : discountPercentageValue.toFixed(2)
    : 0

  if (discountPercentageValue < 0) {
    discountPercentageValue = 0
  }

  return discountPercentageValue
}

export const calculateTotalPrice = (selectedBatches) => {
  return roundedDecimal(
    selectedBatches.reduce(
      (acc, curr) => acc + composeBatchDetails(curr).price || 0,
      0
    )
  )
}

export const calculateDiscountedPrice = (
  actualPrice,
  discountPercentageValue
) => Math.round(actualPrice * ((100 - discountPercentageValue) / 100))
