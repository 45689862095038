import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import LoginForm from 'app/components/LoginForm'
import { navigationPaths } from 'app/config'
import { isNullOrEmpty, isUserAuthenticated, notify } from 'app/helpers'
import { login, logout, sendOtp } from 'app/store/thunks/auth'

import {
  Body,
  FormContainer,
  Image,
  ImageContainer,
  LoginContainer
} from './styles'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidPhone: true,
      isValidOTP: true,
      loginDisabled: true,
      phone: '',
      otp: '',
      targetUrl: props.location?.state?.target_url,
      otpTimeLeft: 0
    }
    if (props.do_logout) this.props.logout()
  }

  setPhone = (phone) => {
    let regex = /^[6789]\d{9}$/
    this.setState({
      phone: phone,
      isValidPhone: regex.test(phone),
      loginDisabled:
        !regex.test(phone) ||
        !this.state.isValidOTP ||
        isNullOrEmpty(this.state.otp)
    })
  }

  setOTP = (otp) => {
    let regex = /^\d{6}$/
    this.setState({
      otp: otp,
      isValidOTP: regex.test(otp),
      loginDisabled:
        !regex.test(otp) ||
        !this.state.isValidPhone ||
        isNullOrEmpty(this.state.phone)
    })
  }

  sendOtp = () => {
    if (this.state.otpTimeLeft === 0) {
      if (!this.props.sendingOtp) {
        if (!isNullOrEmpty(this.state.phone) && this.state.isValidPhone) {
          this.props.sendOtp(this.state.phone)
          let timeLeft = 30
          let timer = setInterval(() => {
            this.setState({ otpTimeLeft: timeLeft })
            if (timeLeft === 0) clearInterval(timer)
            timeLeft--
          }, 1000)
        } else {
          notify('Oops!', 'Please check the number again!', 'info')
        }
      } else {
        notify('Slow down!', 'Otp sending request is under process', 'info')
      }
    }
  }

  sendLoginRequest = () => {
    if (!this.props.loggingIn) {
      if (
        !isNullOrEmpty([this.state.phone, this.state.otp]) &&
        this.state.isValidOTP &&
        this.state.isValidPhone
      ) {
        this.props.login(this.state.phone, this.state.otp)
      }
    } else {
      notify('Slow down!', 'Login request is under process', 'info')
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    if (this.props.do_logout) {
      return <Redirect to={navigationPaths.login} />
    } else if (this.props.isLoggedIn && isUserAuthenticated()) {
      return <Redirect to={from.pathname} />
    } else {
      return (
        <>
          <Body>
            <LoginContainer>
              <ImageContainer>
                <Image
                  alt="Login Image"
                  src="https://res.cloudinary.com/openhouse-study/image/upload/w_1200,f_auto/v1592899415/Dashboard%20Images/loginScreen.svg"
                ></Image>
              </ImageContainer>
              <FormContainer>
                <LoginForm
                  {...this.state}
                  otpSent={this.props.otpSent}
                  setPhone={this.setPhone}
                  setOTP={this.setOTP}
                  sendOtp={this.sendOtp}
                  sendLoginRequest={this.sendLoginRequest}
                  sendingOtp={this.props.sendingOtp}
                  loggingIn={this.props.loggingIn}
                  otpTimeLeft={this.state.otpTimeLeft}
                ></LoginForm>
              </FormContainer>
            </LoginContainer>
          </Body>
        </>
      )
    }
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    accessToken: state.authReducer.accessToken,
    refreshToken: state.authReducer.refreshToken,
    otpSent: state.authReducer.otpSent,
    isLoggedIn: state.authReducer.isLoggedIn,
    loggingIn: state.authReducer.loggingIn,
    sendingOtp: state.authReducer.sendingOtp
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    sendOtp: (phone) => dispatch(sendOtp(phone)),
    login: (phone, otp) => dispatch(login(phone, otp)),
    logout: () => dispatch(logout())
  }
}

LoginPage.propTypes = {
  location: PropTypes.object.isRequired,
  do_logout: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  sendingOtp: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  otpSent: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  sendOtp: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
