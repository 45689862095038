import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { pluralizeString } from '@openhouse-technologies/utils'
import { isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { AutoComplete } from 'app/components/generic/FormFields'
import Loading from 'app/components/generic/Loading'
import {
  subjectCategoryTypes,
  targetAudienceSelectionModes,
  bulkMessagingAvailableFilters,
  bulkMessagingFilterTypes
} from 'app/config'
import constants from 'app/constants'
import {
  dispatchHandleUpdateUserType,
  dispatchHandleFetchUsers,
  dispatchUpdateBulkMessagingUserFilters
} from 'app/store/dispatcher'
import {
  selectBulkMessagesUsers,
  selectBulkMessagesUserType,
  selectFieldErrors,
  selectTargetAudienceSelectionMode,
  selectUserFilters
} from 'app/store/selectors'

import { ExcludedUsers, IncludedUsers } from './components/SelectedUsers'
import { TargetAudienceSelectionMode } from './components/TargetAudienceSelectionMode'
import { FilterFields } from './config'
import * as Styles from './styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '48%'
  }
}))

const renderValues = (name) => {
  switch (name) {
    case bulkMessagingFilterTypes.CATEGORY:
      return Object.keys(subjectCategoryTypes).map((key) => {
        return {
          name: key,
          value: subjectCategoryTypes[key]
        }
      })

    default:
      return []
  }
}

const AddUsers = () => {
  const userType = useSelector(selectBulkMessagesUserType)
  const usersData = useSelector(selectBulkMessagesUsers)
  const fieldErrors = useSelector(selectFieldErrors)
  const targetAudienceSelectionMode = useSelector(
    selectTargetAudienceSelectionMode
  )
  const userFilters = useSelector(selectUserFilters)

  const [categories, setCategories] = React.useState([])

  const classes = useStyles()
  const theme = useTheme()

  const handleAutoCompleteOnChange = (name, values) => {
    dispatchUpdateBulkMessagingUserFilters(name, values)
    dispatchHandleFetchUsers()
  }

  const handleCategoryChange = (event) => {
    const { name, value } = event.target

    setCategories(value)

    dispatchUpdateBulkMessagingUserFilters(name, value)
    dispatchHandleFetchUsers()
  }

  const onUserTypeChange = (event) => {
    dispatchHandleUpdateUserType(event)
  }

  const getValue = (name) => {
    switch (name) {
      case bulkMessagingFilterTypes.CATEGORY:
        return categories

      default:
        return []
    }
  }

  const renderFields = () => {
    return (
      <>
        {FilterFields.map((field) => {
          if (bulkMessagingAvailableFilters[userType].includes(field.name)) {
            if (field.inputType === 'autocomplete') {
              return (
                <FormControl
                  key={field.name}
                  variant="outlined"
                  className={classes.formControl}>
                  <AutoComplete
                    async={field.async}
                    name={field.name}
                    label={field.label}
                    multiple={field.multiple}
                    fetchValues={field.fetchValues}
                    onChange={handleAutoCompleteOnChange}
                    initialSelectIndex={field.initialSelectIndex}
                    required={field.required}
                    {...(!isEmpty(userFilters[field.name])
                      ? { defaultValue: userFilters[field.name] }
                      : null)}
                  />
                </FormControl>
              )
            } else if (field.inputType === 'select') {
              return (
                <FormControl
                  key={field.name}
                  variant="outlined"
                  className={classes.formControl}>
                  <InputLabel>{field.label}</InputLabel>
                  <Select
                    value={getValue(field.name)}
                    name={field.name}
                    label={field.label}
                    onChange={handleCategoryChange}
                    renderValue={(selectedItems) => {
                      return selectedItems
                        .map((selectedItem) => {
                          return renderValues(field.name).find(
                            (item) => item.value === selectedItem
                          ).name
                        })
                        .join(', ')
                    }}
                    {...(field.multiple ? { multiple: true } : null)}>
                    {renderValues(field.name).map((value, index) => (
                      <MenuItem key={value.name} value={value.value}>
                        <Checkbox
                          color="primary"
                          checked={
                            getValue(field.name).indexOf(value.value) > -1
                          }
                        />
                        <ListItemText primary={value.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }
          }

          return null
        })}
      </>
    )
  }

  return (
    <Styles.Container>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Type of user</InputLabel>
        <Select
          value={userType}
          name="type_of_user"
          label="Type of user"
          onChange={onUserTypeChange}>
          <MenuItem value={constants.STUDENT}>Student</MenuItem>
          <MenuItem value={constants.TEACHER}>Teacher</MenuItem>
          <MenuItem value={constants.PARENT}>Parent</MenuItem>
        </Select>

        {fieldErrors.userType && (
          <FormHelperText error>{fieldErrors.userType}</FormHelperText>
        )}
      </FormControl>
      <TargetAudienceSelectionMode />

      {userType &&
      (targetAudienceSelectionMode === targetAudienceSelectionModes.AUTOMATIC ||
        targetAudienceSelectionMode ===
          targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION) ? (
        <>
          <Styles.FiltersContainer>{renderFields()}</Styles.FiltersContainer>

          <Styles.LoadingButtonContainer>
            <MuiThemeProvider theme={theme}>
              {usersData.isLoaded ? (
                <Styles.Result>
                  {pluralizeString(`filtered ${userType}`, usersData.count)}
                </Styles.Result>
              ) : usersData.isLoading ? (
                <Loading />
              ) : null}
            </MuiThemeProvider>
          </Styles.LoadingButtonContainer>
        </>
      ) : null}

      {userType &&
      targetAudienceSelectionMode ===
        targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION ? (
        <ExcludedUsers userType={userType} />
      ) : null}

      {userType &&
      targetAudienceSelectionMode === targetAudienceSelectionModes.MANUAL ? (
        <IncludedUsers userType={userType} />
      ) : null}
    </Styles.Container>
  )
}

export { AddUsers }
