import styled from 'styled-components'

export const Container = styled.div`
  padding: 5px 10px;

  div {
    display: flex;
  }
`

export const DragNDrop = styled.div`
  flex-basis: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 235px;
  border: 0.5px solid #0000008a;
  border-style: dashed;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    border-color: #000;
  }
  h4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 27px;
  }
  input {
    width: 100%;
    height: 100%;
  }
`

export const Assets = styled.div`
  flex-basis: 70%;
  display: flex;
  margin-left: 5px;
  height: 235px;
  overflow: scroll hidden;
  img,
  video {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
`
