import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { detachTribe } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import constants from 'app/constants'
import { confirm, showToast } from 'app/helpers'
import {
  fetchTribeMemberData,
  updateCommunityData
} from 'app/store/actions/communityEntity'

import { columns } from './config'

const Membership = () => {
  const dispatch = useDispatch()
  const tribeMemberData = useSelector(
    (state) => state.communityEntityReducer.membershipData
  )

  const terminate = React.useCallback(async (dataIndex) => {
    confirm(() => {
      const { uuid } = tribeMemberData.data[dataIndex]
      detachTribe(uuid).then((data) => {
        if (data.success) {
          showToast('Membership terminated successfully', constants.SUCCESS)
          dispatch(fetchTribeMemberData({}, constants.MEMBERSHIP))
        } else {
          showToast('Oops! Unable to terminate membership', constants.ERROR)
        }
      })
    }, 'You want to remove this member from this tribe?')
  })

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => terminate(dataIndex)}
              >
                remove from tribe
              </Button>
            )
          }
        }
      }
    ]
  }, [terminate])

  return (
    <BaseEntity
      entity={constants.MEMBERSHIP}
      label={constants.MEMBERSHIP_LABEL}
      columns={modifiedColumns}
      data={tribeMemberData}
      fetchData={fetchTribeMemberData}
      updateEntityData={updateCommunityData}
      readOnly
    />
  )
}

export default Membership
