import React, { createContext, useContext, useEffect } from 'react'
import { useSocketManager } from './SocketManager'
import { eventAlert, getAccessToken } from './helpers'

const WebSocketContext = createContext()

export const WebSocketProvider = ({ children }) => {
  const socketManager = useSocketManager()
  const accessToken = getAccessToken()

  useEffect(() => {
    if (accessToken && window.navigator.onLine) {
      socketManager.connect()
    }
    else eventAlert('Please check your connection and refresh the page in sometime', 'Error', 'Network connection failed!')

    //cleanup WS connection when the component unmounts
    return () => { socketManager.close() }
  }, [socketManager, accessToken])

  return (
    <WebSocketContext.Provider value={socketManager}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const useWebSocket = () => {
  return useContext(WebSocketContext)
}
