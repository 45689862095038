/* eslint-disable react/prop-types */
import {
  Input,
  Typography,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import React, { useState } from 'react'

import ModalForm from 'app/components/ModalForm'

import { ReactComponent as Leaf } from './leaf.svg'
import { ReactComponent as OhLogo } from './oh.logo.svg'
import { ReactComponent as Poh } from './poh.svg'
import {
  Container,
  InputWrapper,
  CartItems,
  ModalFooter,
  Headings,
  CartItemWrapper,
  Summary
} from './styles'

import { composeBatchDetails } from '../../../../transformers'

const headings = [
  {
    title: 'Classes',
    subtitle: ''
  },
  {
    title: 'Day/Time',
    subtitle: ''
  },
  {
    title: 'Hours'
  },
  {
    title: 'Coins Needed'
  }
]

export const CartItem = ({ cartItem, deleteItem }) => {
  if (!cartItem) return null
  const { cadence, coins_per_session } = cartItem

  const {
    alternateBatches,
    subjectsName,
    totalHoursPerSession,
    totalHours,
    type,
    totalSessions
  } = composeBatchDetails(cartItem)

  const totalTime =
    type === 'membership' ? totalSessions * totalHoursPerSession : totalHours

  return (
    <CartItemWrapper>
      <Typography variant="subtitle1">{subjectsName}</Typography>
      <Typography variant="subtitle1">
        {alternateBatches.map((altBatch) => (
          <div key={altBatch}>{altBatch}</div>
        ))}
      </Typography>
      <Typography variant="subtitle1">{totalTime}</Typography>
      <Typography variant="subtitle1">
        {coins_per_session * totalSessions}
      </Typography>
      <Delete onClick={deleteItem} fontSize="small" />
    </CartItemWrapper>
  )
}

const MembershipPreview = ({
  cartItems = [],
  isOpen = false,
  onClose = () => {},
  onDelete = () => {}
}) => {
  const printRef = React.useRef()

  const [parentName, setParentName] = useState('')
  const [isDisabled, setDisabled] = useState(false)

  const handleDownloadPdf = async () => {
    const element = printRef.current
    const canvas = await html2canvas(element)
    const data = canvas.toDataURL('image/png')

    const pdf = new jsPDF()
    const imgProperties = pdf.getImageProperties(data)
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
    pdf.save(`Oh-membership-${parentName.replace(' ', '-')}.pdf`)
  }

  const totalCoins = cartItems?.reduce((prev, cartItem) => {
    const { totalSessions } = composeBatchDetails(cartItem)
    const cartItemTotalCost = totalSessions * cartItem.coins_per_session
    return prev + cartItemTotalCost
  }, 0)

  return (
    <ModalForm
      isModalOpen={isOpen}
      enableSave={false}
      showCloseIcon
      onModalClose={onClose}
    >
      <Container ref={printRef} disabled={isDisabled}>
        <div className="brand-header">
          <Leaf className="brand-leaf" />
          <OhLogo className="brand-logo" />
        </div>
        <Typography variant="h4">
          <span style={{ color: '#EF5D34' }}>oh.</span> your membership journey
        </Typography>
        <InputWrapper>
          <div className="input">
            <Typography variant="subtitle1">Parent Name</Typography>
            <Input
              placeholder=""
              required
              value={parentName}
              onChange={(e) => setParentName(e.target.value)}
            />
          </div>
          <div className="input">
            <Typography variant="subtitle1">{"Child's"} name</Typography>
            <Input placeholder="" required />
          </div>
          <div className="input">
            <Typography variant="subtitle1">{"Child's"} age</Typography>
            <Input required type="number" min="3" max="18" />
          </div>
        </InputWrapper>

        <CartItems>
          <Typography variant="h5">
            {"Your child's afterschool plan"}
          </Typography>
          <div className="cart-table">
            <Headings>
              {headings.map((heading) => (
                <Typography variant="h6" key={heading}>
                  {heading.title}
                  <small>{heading.subtitle}</small>
                </Typography>
              ))}
            </Headings>
            {cartItems?.map((cartItem) => (
              <CartItem
                key={cartItem.uuid}
                cartItem={cartItem}
                deleteItem={() => onDelete(cartItem)}
              />
            ))}
          </div>
        </CartItems>

        <Summary>
          <Typography variant="h5">Summary</Typography>

          <div className="summary">
            <div className="summary-item">
              <Typography variant="h6">Coins Needed</Typography>
              <Typography variant="h6">{totalCoins}</Typography>
            </div>
            <div className="summary-item">
              <Typography variant="h6">Membership Plan</Typography>
              <Input placeholder="" required />
            </div>
            <div className="summary-item">
              <Typography variant="h6">Total Coins</Typography>
              <Input placeholder="" required />
            </div>
            <div className="summary-item">
              <Typography variant="h6">Early Bird Discount</Typography>
              <div>
                <FormControlLabel
                  label="Applicable"
                  control={<Checkbox color="primary" />}
                />
                <FormControlLabel
                  label="Not Applicable"
                  style={{ marginRight: 0 }}
                  control={<Checkbox color="primary" />}
                />
              </div>
            </div>
            <div className="summary-item">
              <Typography variant="h6">Membership Price + GST</Typography>
              <Input placeholder="" required />
            </div>
          </div>
        </Summary>
        {/* <div className="brand-footer">
          <Poh className="poh" />
        </div> */}
      </Container>
      <ModalFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setDisabled(true)
            setTimeout(() => {
              handleDownloadPdf()
              setDisabled(false)
            }, 500)
          }}
          disabled={!cartItems.length}
        >
          Export (as PDF)
        </Button>
      </ModalFooter>
    </ModalForm>
  )
}

export default MembershipPreview
