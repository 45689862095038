import AccessTimeIcon from '@material-ui/icons/AccessTime'
import FullScreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import { fetchCentre } from 'api'
import { fetchCentreSchedules } from 'api/centreSchedule'
import { AutoComplete } from 'app/components/generic/FormFields'
import Loading from 'app/components/generic/Loading'
import Dummy from 'app/components/generic/LottieComponents/Dummy'
import { centreScheduleBatchTypes, subjectCategoryTypes } from 'app/config'

import DateTime from './components/DateTime'
import MarketSubjects from './components/MarketSubjects'
import { batchTypesColorList, scheduleColors } from './config'
import * as styles from './styles'

dayjs.extend(customParseFormat)
dayjs.extend(minMax)

const CentreSchedule = () => {
  const [formData, setFormData] = React.useState({
    centre: ''
  })
  const [limitNum, setLimitNum] = useState(12)
  const handle = useFullScreenHandle()
  const [isFull, setIsFull] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [schedule, setSchedule] = React.useState([])
  const ohLogo = require('assets/images/openhouse_logo.png')
  const defaultImage = require('assets/images/default.svg')
  const centreDefaultValue = localStorage.getItem('CENTRE_SELECT')
    ? JSON.parse(localStorage.getItem('CENTRE_SELECT'))
    : null

  let timer

  const fetchSchedules = async () => {
    clearTimeout(timer)
    try {
      if (formData.centre) {
        const response = await fetchCentreSchedules({
          centre_uuid: formData.centre.value,
          start_time: dayjs().format('YYYY-MM-DD HH:mm')
        })
        setIsLoading(false)
        if (response) {
          const extraClasses = response.results.filter((t, index) => {
            return (
              index > 11 &&
              dayjs(dayjs().format('YYYY-MM-DD ') + t.start_time).diff(
                dayjs(),
                'minute'
              ) <= 30
            )
          })
          let limit = limitNum
          if (extraClasses.length >= 1) limit = 15
          setLimitNum(limit)

          if (response.results.length) {
            const temp = [...response.results]
            const sorted = temp.sort((a, b) => {
              const first = dayjs(dayjs().format('YYYY-MM-DD ') + a.end_time)
              const second = dayjs(dayjs().format('YYYY-MM-DD ') + b.end_time)
              if (first.diff(second) < 0) {
                return -1
              }
              if (first.diff(second) > 0) {
                return 1
              }
              // a must be equal to b
              return 0
            })

            const end_time = sorted[0].end_time
            const delay = dayjs(dayjs().format('YYYY-MM-DD ') + end_time).diff(
              dayjs(),
              'millisecond'
            )

            timer = setTimeout(() => {
              setIsLoading(true)
              fetchSchedules()
            }, (delay < 0 ? -1 * delay : delay) + 60000)
          }

          const filteredSchedule = response.results.filter(
            (t, index) =>
              index < limit &&
              t.room?.name.split(' ')[0].toLowerCase() !== 'online'
          )

          function compare(a, b) {
            if (a.batch.type === 'class' || a.batch.type === 'membership') {
              return -1
            }
            if (a.batch.type === 'event' && b.batch.type === 'explore') {
              return -1
            }
            if (a.batch.type === 'explore') {
              return 1
            }
            return 0
          }

          let sortedSchedule = filteredSchedule.sort(compare)

          setSchedule(sortedSchedule)
        }
      }
    } catch (error) {
      console.log('error in fetchSchedules :- ', error)
      setIsLoading(false)
    }
  }

  const changeFullScreen = (val) => {
    setIsFull(val)
    if (val) handle.enter()
    else handle.exit()
  }

  const getCentre = async (searchedText) => {
    try {
      const queries = {
        limit: 1000
      }

      if (!isEmpty(searchedText)) {
        queries.name = searchedText
      }

      const response = await fetchCentre({ queries })

      return response.results.map((item) => ({
        value: item.uuid,
        name: item.name
      }))
    } catch (error) {
      console.log('Error in fetching centres :- ', error)
      throw error
    }
  }

  const handleCentreChange = (name, value) => {
    const data = {
      ...formData
    }
    if (name === 'centre') {
      data.centre = value
      setFormData(data)
    }
    localStorage.setItem('CENTRE_SELECT', JSON.stringify(value))
  }

  React.useEffect(() => {
    // get centre name from url query
    const queries = new URLSearchParams(window.location.search)

    const centreName = queries.get('centre_name')
    if (centreName) {
      async function centres() {
        const response = await getCentre(centreName)
        if (response.length) handleCentreChange('centre', response[0])
      }

      centres()
    }
  }, [])

  React.useEffect(() => {
    setIsLoading(true)
    fetchSchedules()
  }, [formData.centre])

  const page = React.useMemo(() => {
    return (
      <FullScreen handle={handle}>
        <styles.Page>
          <styles.Header>
            <img src={ohLogo} alt="oh logo" width="12%" />
            <Dummy height="10px" width="10px" />
            <DateTime />
            <styles.RightHeader>
              <styles.DropdownContainer>
                <AutoComplete
                  name="centre"
                  label="Centre *"
                  size="small"
                  multiple={false}
                  fetchValues={getCentre}
                  onChange={handleCentreChange}
                  {...(formData?.centre
                    ? { defaultValue: formData?.centre }
                    : centreDefaultValue?.value
                    ? { defaultValue: centreDefaultValue }
                    : null)}
                />
              </styles.DropdownContainer>
              {!isFull ? (
                <FullScreenIcon
                  color="primary"
                  onClick={() => changeFullScreen(true)}
                  alt="full screen"
                  fontSize="large"
                />
              ) : (
                <FullscreenExitIcon
                  color="primary"
                  alt="full screen exit"
                  fontSize="large"
                  onClick={() => changeFullScreen(false)}
                />
              )}
            </styles.RightHeader>
          </styles.Header>
          {isLoading ? (
            <styles.Loader>
              <Loading />
            </styles.Loader>
          ) : (
            <styles.Body>
              {schedule.map((value, index) => {
                return (
                  <styles.CardContainer key={index}>
                    <styles.CardHeader
                      width={limitNum}
                      batchTypeColor={batchTypesColorList[value?.batch?.type]}
                    >
                      <styles.Room>
                        <styles.Regularheading>room:</styles.Regularheading>
                        &nbsp;
                        <styles.Boldheading>
                          {value?.room?.name}
                        </styles.Boldheading>
                      </styles.Room>
                      <styles.Mediumheading>
                        {centreScheduleBatchTypes[value?.batch?.type]}
                      </styles.Mediumheading>
                    </styles.CardHeader>
                    <styles.CardBody width={limitNum}>
                      <styles.Info width={limitNum}>
                        {value?.batch?.course?.subjects[0]?.subject_category
                          ?.type === subjectCategoryTypes.academic ? (
                          <styles.Subtitle>
                            Class{' '}
                            {value?.batch?.course?.standards
                              ?.map((std) => std.name.split(' ')[1])
                              .join(', ')}
                          </styles.Subtitle>
                        ) : (
                          <styles.Subtitle>
                            Ages {value?.batch?.course?.min_age} -{' '}
                            {value?.batch?.course?.max_age}
                          </styles.Subtitle>
                        )}
                        <styles.Name color={scheduleColors[index]}>
                          {value?.batch?.course?.subjects
                            ?.map((b) => b.name)
                            .join(' ')}
                        </styles.Name>
                        <styles.Subheading>
                          with {value?.batch?.teacher?.full_name}
                        </styles.Subheading>
                      </styles.Info>
                      <styles.Time>
                        <styles.Icon>
                          <AccessTimeIcon color="primary" fontSize="inherit" />
                        </styles.Icon>

                        <styles.Boldtext>
                          {dayjs('1/1/1 ' + value?.start_time).format('h:mmA')}{' '}
                          - {dayjs('1/1/1 ' + value?.end_time).format('h:mmA')}
                        </styles.Boldtext>
                      </styles.Time>
                    </styles.CardBody>
                    <styles.Image
                      src={
                        value?.batch?.course?.subjects[0]?.image
                          ? value?.batch?.course?.subjects[0]?.image
                          : defaultImage
                      }
                      widthProp={limitNum}
                      alt={value?.batch?.course?.subjects[0]?.name}
                    />
                  </styles.CardContainer>
                )
              })}
              {schedule.length <= 4 && (
                <MarketSubjects
                  schedule={schedule}
                  formData={formData}
                  limit={limitNum}
                />
              )}
            </styles.Body>
          )}
        </styles.Page>
      </FullScreen>
    )
  }, [formData, isLoading, schedule])

  return <>{page}</>
}

export default CentreSchedule
