import { sentryLogger } from 'sentry'

import { apiEndpoints } from './common'

import { post } from '../request'

const { REACT_APP_CLOUDINARY_CLOUD_NAME = 'openhouse-study' } = process.env // fallback value if not available in env

const uploadFile = async (
  data,
  options,
  onUploadProgress,
  assetType = 'auto'
) => {
  const { uploadPreset, public_id } = options || {}

  const formData = new FormData()
  formData.append('file', data)
  formData.append('upload_preset', uploadPreset)
  formData.append('public_id', public_id)

  try {
    const response = await post(
      `${apiEndpoints.cloudFileStorage}/${REACT_APP_CLOUDINARY_CLOUD_NAME}/${assetType}/upload`,
      formData,
      {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        ...(typeof onUploadProgress === 'function'
          ? {
              onUploadProgress
            }
          : null)
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'Error in file upload')
    throw error
  }
}

const fileRequestStructure = (data) => ({
  url: data?.secure_url,
  provider: 'cloudinary',
  type: data?.resource_type,
  mime: `${data?.resource_type}/${data?.format}`,
  size: data?.bytes,
  original_url: data?.secure_url
})

const getFile = async (data) => {
  try {
    const response = await post(
      apiEndpoints.openbookFiles,
      fileRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in getting upload file info')
    throw error
  }
}

const uploadAndGetFile = async (
  data,
  options,
  onUploadProgress,
  assetType = 'auto'
) => {
  const { uploadPreset, public_id } = options || {}

  const formData = new FormData()
  formData.append('file', data)
  formData.append('upload_preset', uploadPreset)
  formData.append('public_id', public_id)

  try {
    const response = await post(
      `${apiEndpoints.cloudFileStorage}/${REACT_APP_CLOUDINARY_CLOUD_NAME}/${assetType}/upload`,
      formData,
      {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        ...(typeof onUploadProgress === 'function'
          ? {
              onUploadProgress
            }
          : null)
      }
    )

    const responseData = response.data

    const fileResponse = await getFile(responseData)
    console.log(fileResponse)

    return fileResponse?.data
  } catch (error) {
    sentryLogger(error, 'Error in file upload')
    throw error
  }
}

export { uploadFile, uploadAndGetFile }
