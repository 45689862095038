import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
`

export const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const Text = styled.div`
  margin-left: 0.25rem;
  margin-right: 1rem;
`

export const Icon = styled.img`
  height: 1.5rem;
  width: 1.5rem;
`