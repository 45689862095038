import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import {
  fetchClubData,
  updateCommunityData
} from 'app/store/actions/communityEntity'

import { columns } from './config'

const Club = () => {
  const clubData = useSelector((state) => state.communityEntityReducer.clubData)

  return (
    <BaseEntity
      entity={constants.CLUB}
      label={constants.CLUB_LABEL}
      columns={columns}
      data={clubData}
      fetchData={fetchClubData}
      updateEntityData={updateCommunityData}
    />
  )
}

export default Club
