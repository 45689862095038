import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import AbsentIcon from 'assets/images/absent.svg'
import CancelIcon from 'assets/images/cancel.svg'
import PresentIcon from 'assets/images/present.svg'
import SkippedIcon from 'assets/images/skipped.svg'
import StarIcon from 'assets/images/star.svg'

import * as styles from '../../SummaryBlock/components/ClassroomExperience/styles'

export const RatingTable = (props) => {
  const { data } = props

  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}
        >
          <TableRow>
            <TableCell>Date</TableCell>
            {/* <TableCell>Time</TableCell> */}
            <TableCell>Rating</TableCell>
            <TableCell>Attendance</TableCell>
            {/* <TableCell>Review notes</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data)
            ? data.map(
                (session, index) =>
                  session && (
                    <TableRow key={index}>
                      <TableCell>
                        {session?.attendance?.schedule?.date}
                      </TableCell>
                      {/* <TableCell>{session?.start_time}</TableCell> */}
                      <TableCell>
                        {session.status ? (
                          <styles.Icon src={SkippedIcon} alt="skipped" />
                        ) : (
                          <styles.RowFlex>
                            <styles.Icon src={StarIcon} alt="star" />
                            <styles.Content subheading type="dark">
                              {session?.rating}
                            </styles.Content>
                          </styles.RowFlex>
                        )}
                      </TableCell>
                      <TableCell>
                        {session?.attendance.cancelled ? (
                          <styles.Icon src={CancelIcon} alt="cancel" />
                        ) : session?.attendance.attended ? (
                          <styles.Icon src={PresentIcon} alt="present" />
                        ) : (
                          <styles.Icon src={AbsentIcon} alt="absent" />
                        )}
                      </TableCell>
                      {/* <TableCell>{session?.review ? session?.review : ''}</TableCell> */}
                    </TableRow>
                  )
              )
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

RatingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
