import MuiDialogActions from '@material-ui/core/DialogActions'
import { withStyles, useTheme } from '@material-ui/core/styles'
import BackIcon from '@material-ui/icons/ArrowBack'
import NextIcon from '@material-ui/icons/ArrowForward'
import SendIcon from '@material-ui/icons/Send'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { pluralizeString } from '@openhouse-technologies/utils'
import React from 'react'
import { useSelector } from 'react-redux'

import ModalForm from 'app/components/ModalForm'
import { targetAudienceSelectionModes } from 'app/config'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'
import {
  dispatchUpdateSendMessageModalState,
  dispatchHandleOnBulkMessagesSend,
  dispatchHandleBulkMessagesCreateValidation,
  dispatchResetBulkMessagingCreationData
} from 'app/store/dispatcher'
import {
  selectIsSendMessageModalOpen,
  selectBulkMessagesUsers,
  selectTargetAudienceSelectionMode,
  selectBulkMessagesUserType,
  selectExcludedUsers,
  selectIncludedUsers,
  selectIsBulkMessageRequestInProgress
} from 'app/store/selectors'

import { AddUsers } from './components/AddUsers'
import { MessageTemplate } from './components/MessageTemplate'
import * as Styles from './styles'

const steps = ['Define Target Audience', 'Create Message', 'Click On Send']

const DialogActions = withStyles((theme) => ({
  root: {
    height: 64,
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    justifyContent: 'space-between'
  }
}))(MuiDialogActions)

const SendBulkMessage = () => {
  const isSendMessageModalOpen = useSelector(selectIsSendMessageModalOpen)
  const usersData = useSelector(selectBulkMessagesUsers)
  const targetAudienceSelectionMode = useSelector(
    selectTargetAudienceSelectionMode
  )

  const userType = useSelector(selectBulkMessagesUserType)
  const excludedUsers = useSelector(selectExcludedUsers)
  const includedUsers = useSelector(selectIncludedUsers)
  const isBulkMessageRequestInProgress = useSelector(
    selectIsBulkMessageRequestInProgress
  )

  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})
  const [isMessageValid, setIsMessageValid] = React.useState(false)

  const theme = useTheme()

  const onModalClose = React.useCallback(() => {
    setActiveStep(0)
    setCompleted({})
    dispatchUpdateSendMessageModalState(false)
    dispatchResetBulkMessagingCreationData()
  }, [])

  React.useEffect(() => {
    const handler = () => {
      setIsMessageValid(true)
      setActiveStep((state) => state + 1)
    }

    eventEmitter.addListener(constants.BULK_MESSAGING_MESSAGE_VALID, handler)

    return () => {
      eventEmitter.removeListener(
        constants.BULK_MESSAGING_MESSAGE_VALID,
        handler
      )
    }
  }, [])

  React.useEffect(() => {
    eventEmitter.addListener(constants.CLOSE_BULK_MESSAGING_MODAL, onModalClose)

    return () => {
      eventEmitter.removeListener(
        constants.CLOSE_BULK_MESSAGING_MODAL,
        onModalClose
      )
    }
  }, [onModalClose])

  const onSendButtonClick = () => {
    dispatchHandleOnBulkMessagesSend()
  }

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const totalUsers =
    targetAudienceSelectionMode === targetAudienceSelectionModes.AUTOMATIC
      ? usersData.count || 0
      : targetAudienceSelectionMode ===
        targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION
      ? (usersData.count || 0) - excludedUsers.length >= 0
        ? (usersData.count || 0) - excludedUsers.length
        : 0
      : targetAudienceSelectionMode === targetAudienceSelectionModes.MANUAL
      ? includedUsers.length
      : 0

  const DialogActionsElement = (
    <DialogActions>
      <MuiThemeProvider theme={theme}>
        <Styles.LoadingButton
          loading={false}
          loadingPosition="start"
          variant="contained"
          color="primary"
          onClick={handleBack}
          startIcon={<BackIcon />}
          disabled={activeStep === 0}
        >
          back
        </Styles.LoadingButton>
      </MuiThemeProvider>

      <Styles.NextButtonWrapper>
        {userType ? (
          <Styles.TotalUsers>
            {pluralizeString(`targeted ${userType}`, totalUsers)}
          </Styles.TotalUsers>
        ) : null}

        <MuiThemeProvider theme={theme}>
          {activeStep === 0 || activeStep === 1 ? (
            <Styles.LoadingButton
              loading={false}
              loadingPosition="start"
              variant="contained"
              color="primary"
              startIcon={<NextIcon />}
              {...(activeStep === 0
                ? {
                    onClick: handleNext,
                    disabled: usersData.isLoading || totalUsers < 1
                  }
                : null)}
              {...(activeStep === 1
                ? {
                    onClick: dispatchHandleBulkMessagesCreateValidation
                  }
                : null)}
            >
              next
            </Styles.LoadingButton>
          ) : (
            <Styles.LoadingButton
              loading={isBulkMessageRequestInProgress}
              loadingPosition="start"
              variant="contained"
              color="primary"
              onClick={onSendButtonClick}
              startIcon={<SendIcon />}
              disabled={!isMessageValid || totalUsers < 1}
            >
              send
            </Styles.LoadingButton>
          )}
        </MuiThemeProvider>
      </Styles.NextButtonWrapper>
    </DialogActions>
  )

  const renderStep = () => {
    if (activeStep === 0) {
      return <AddUsers />
    } else if (activeStep === 1 || activeStep === 2) {
      return <MessageTemplate disabled={activeStep === 2} />
    }

    return null
  }

  return (
    <ModalForm
      isModalOpen={isSendMessageModalOpen}
      enableSave={false}
      showCloseIcon
      modalTitle="Bulk Messaging"
      disableBackdropClick
      onModalClose={onModalClose}
      modalCss={`
        .MuiDialog-paperScrollPaper {
            overflow-y: unset;
        }
      `}
      DialogActions={DialogActionsElement}
    >
      <Styles.Container>
        <Styles.FormContainer>
          <MuiThemeProvider theme={theme}>
            <Stepper
              activeStep={activeStep}
              orientation="horizontal"
              color="inherit"
            >
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {renderStep()}
          </MuiThemeProvider>
        </Styles.FormContainer>
      </Styles.Container>
    </ModalForm>
  )
}

export { SendBulkMessage }
