import styled from 'styled-components'

export const Title = styled.div`
    font-weight: bold;
    margin-top: ${props => props.backgroundColor ? '20px' : '10px'};
    margin-bottom: 0px;
    background-color: ${props => props.backgroundColor || '#ffffff'};
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;
    padding: 12px;
    padding-left: ${props => props.backgroundColor ? '25px' : '0px'};
`

export const TableWrapper = styled.div`
  margin-left: 8px;
  margin-bottom: 2em;
`