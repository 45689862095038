import styled from 'styled-components';

export const Heading = styled.div`
    font-family: AvenirMedium;
    font-size: 0.8em;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: 'var(--oh-black-secondary)'
`

export const Description = styled.div`
    font-family: AvenirDemi;
    font-size: 1em;
    color: 'var(--oh-black-primary)';
    margin-top: 0.2em;
`