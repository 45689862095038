import React from 'react'
import { useDispatch } from 'react-redux'

import { createUser } from 'api'
import { Loading } from 'app/components/generic/LottieComponents'
import constants from 'app/constants'
import { updateDemoSchedulingUserData } from 'app/store/actions/demoScheduling'

import DemoSchedulingScreen from './DemoSchedulingScreen'

const DemoScheduling = () => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [isValidUser, setIsValidUser] = React.useState(false)

  const dispatch = useDispatch()

  React.useEffect(() => {
    const handleUserCreation = async () => {
      // get first_name, last_name and phone_number from url query
      const queries = new URLSearchParams(window.location.search)

      const firstName = queries.get('first_name')
      const lastName = queries.get('last_name')
      const phoneNumber = queries.get('phone_number')

      try {
        const response = await createUser(
          {
            user: {
              first_name: firstName,
              last_name: lastName,
              phone_number: phoneNumber
            }
          },
          constants.STUDENT
        )

        setIsLoading(false)
        if (response.success) {
          setIsValidUser(true)

          // store user id in cookie
          dispatch(updateDemoSchedulingUserData({ uuid: response.user_uuid }))
        }
      } catch (error) {
        console.log('error in handleUserCreation :- ', error)
        setIsLoading(false)
      }
    }

    handleUserCreation()
  }, [dispatch])

  if (!isLoading && !isValidUser) {
    return (
      <div>
        Invalid User Details. Please Make Sure:
        <div>1. Lead has First Name Entered.</div>
        <div>
          2. Lead has Last Name Entered. Lead has a valid 10 digit phone number
          entered.
        </div>
        <div>
          3. Phone Number For This Lead, Is Not Being Used By Any Other Student
          / Teacher At Openhouse.
        </div>
      </div>
    )
  } else if (!isLoading && !isValidUser) {
  }

  return isLoading ? <Loading /> : <DemoSchedulingScreen />
}

export default DemoScheduling
