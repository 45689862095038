/* eslint-disable react/prop-types */
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import { formatDate } from 'app/pages/Dashboard/ParentDashboard/helpers'
import { formatCoins } from 'app/utils'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const WalletCancel = (props) => {
  const { cancelData } = props
  const [walletCancelBlocks, setWalletCancelBlocks] = useState([])

  const balance = cancelData?.coins_remaining
  const remaining = cancelData?.user?.coin_summary?.remaining_coins

  useEffect(() => {
    ;(async () => {
      if (!isEmpty(cancelData)) {
        const canceled_by = cancelData?.cancelled_by
        const blocks = [
          {
            heading:
              cancelData?.status == 'pause' ? 'Paused on' : 'Cancelled on',
            value: formatDate(cancelData?.cancellation_date)
          },
          {
            heading:
              cancelData?.status == 'pause' ? 'Paused by' : 'Cancelled by',
            value: `${canceled_by?.first_name} ${canceled_by?.last_name}`
          },
          {
            heading: 'Refund',
            value: cancelData?.refund ? 'Done' : 'Pending'
          },
          ...(cancelData?.status === 'pause'
            ? [
                {
                  heading: 'Follow-up',
                  value: formatDate(cancelData?.follow_up_date)
                }
              ]
            : [])
          // {
          //   heading: 'Reason',
          //   value: cancelData?.cancellation_reason,
          // },
        ]

        setWalletCancelBlocks(blocks)
      }
    })()
  }, [cancelData])

  return (
    <styles.Body>
      {/* <styles.ColumnFlexGap> */}
      <styles.RowFlexSpace>
        <TitleDescription
          heading={'Current balance'}
          description={
            <styles.Coins>
              <styles.CoinImg></styles.CoinImg>
              {formatCoins(remaining)}
            </styles.Coins>
          }
        />
        <TitleDescription
          heading={'Closing balance'}
          description={
            <styles.Coins>
              <styles.CoinImg></styles.CoinImg>
              {formatCoins(balance)}
            </styles.Coins>
          }
        />
        {walletCancelBlocks?.map((item, index) => {
          return (
            <TitleDescription
              key={'WalletCancel' + index}
              heading={item.heading}
              description={item.value}
            />
          )
        })}
      </styles.RowFlexSpace>

      {/* </styles.ColumnFlexGap> */}
    </styles.Body>
  )
}
