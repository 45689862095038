import dayjs from 'dayjs'
import { get } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api'
import {
  statusMap,
  commonAttributes,
  navigationPaths,
  studentSourceTypes,
  tableColumnDefaultOption,
  batchTypesMap,
  levelMap,
  level,
  yesNoOptions
} from 'app/config'
import { sanitize } from 'app/utils'
import { validateName, validateNotEmpty, validateFee } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid,
    options: {
      ...commonAttributes.uuid.options,
      display: false
    }
  },
  {
    name: 'batch.uuid',
    formDataKey: 'batch',
    label: 'Batch',
    editable: false,
    resultStructure: {
      keyName: 'batch',
      keyValue: 'uuid'
    },
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('batch'),
    options: {
      display: false
    }
  },
  {
    name: 'student.user.uuid',
    label: 'Student uuid',
    editable: false,
    options: {
      display: false
    }
  },
  {
    name: 'student.user.first_name',
    label: 'Student First Name',
    disabledInFormOnEdit: true,
    validate: validateName,
    options: {
      display: false
    }
  },
  {
    name: 'student.user.last_name',
    label: 'Student Last Name',
    disabledInFormOnEdit: true,
    validate: validateName,
    options: {
      display: false
    }
  },
  {
    name: 'student.user',
    label: 'Student Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => (
        <span>{`${(value && value.first_name) || ''} ${
          (value && value.last_name) || ''
        }`}</span>
      ),
      filterKey: 'student_name'
    }
  },
  {
    name: 'student.parent.user',
    label: 'Parent Name',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_name',
      customBodyRender: (value) =>
        value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}
            >{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null,
      ...tableColumnDefaultOption.options
    }
  },
  {
    name: 'student.parent.user.phone_number',
    label: 'Parent Number',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_phone_number',
      ...tableColumnDefaultOption.options
    }
  },
  {
    name: 'batch.teacher.full_name',
    label: 'Teacher Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    },
    disabledInForm: true
  },
  {
    ...commonAttributes.subject,
    name: 'batch.course.subjects',
    disabledInForm: true
  },
  {
    ...commonAttributes.level,
    name: 'batch',
    label: 'batch level',
    disabledInForm: true,
    options: {
      filter: true,
      sort: true,
      filterKey: 'batch_level',
      customBodyRender: (value) => {
        return (
          <span>{value?.type === 'excel' ? levelMap[value.level] : 'NA'}</span>
        )
      }
    }
  },
  {
    ...commonAttributes.standard,
    name: 'batch.course.standards',
    disabledInForm: true
  },
  {
    name: 'batch.cadence',
    label: 'Batch Timings',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      // filterKey: 'batch_timings',
      disableFilter: true,
      customBodyRender: (value) => {
        return value ? (
          <span>
            {value
              ?.sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
              .map((item) => {
                const start = item.start_time.split('-').join(':')
                const end = item.end_time.split('-').join(':')
                return `${dayjs()
                  .day(
                    item.weekday >= 0 && item.weekday < 7 ? item.weekday + 1 : 7
                  )
                  .format('ddd')}, ${dayjs('1/1/1 ' + start).format(
                  'h:mma'
                )} - ${dayjs('1/1/1 ' + end).format('h:mma')}`
              })
              .join(' | ')}
          </span>
        ) : null
      }
    }
  },
  {
    ...commonAttributes.centre,
    name: 'batch.centre.name',
    label: 'Centre',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre'
    }
  },
  {
    name: 'status',
    label: 'Status',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: statusMap,
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'auto_booking',
    label: 'Auto Book Status',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'auto_booking',
      customBodyRender: (value) => {
        return <div>{value ? 'IN' : 'OUT'}</div>
      }
    }
  },
  {
    name: 'batch.type',
    label: 'Batch Type',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: (() => {
      // create a copy of batchTypesMap and remove the unwanted keys i.e exploration and exploration_camp
      const copyBatchType = { ...batchTypesMap }
      delete copyBatchType.exploration
      delete copyBatchType.exploration_camp
      delete copyBatchType.excel
      delete copyBatchType.explore
      return copyBatchType
    })(),
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'batch_type',
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    name: 'student_type',
    label: 'Student source',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: studentSourceTypes,
    ...tableColumnDefaultOption
  },
  {
    ...commonAttributes.date,
    name: 'start_date',
    label: 'Start Date',
    widget: 'datepicker',
    validate: validateNotEmpty
  },
  {
    ...commonAttributes.date,
    name: 'end_date',
    label: 'OG End Date',
    disabledInForm: true
  },
  {
    ...commonAttributes.date,
    name: 'adjusted_end_date',
    label: 'New End Date',
    disabledInFormOnEdit: true
  },
  {
    name: 'payment_mode',
    label: 'Payment Mode',
    disabledInFormOnEdit: true,
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: {
      NO_FEE: 'No Fee',
      PRO_RATED_FEE: 'Pro Rated Fee',
      CUSTOM_FEE: 'Custom Fee'
    },
    options: {
      display: false
    }
  },
  {
    name: 'fee',
    label: 'Fee',
    validate: validateFee,
    defaultValue: () => new URLSearchParams(window.location.search).get('fee'),
    conditionalRender: (data) => {
      return data['payment_mode'] === 'CUSTOM_FEE'
    },
    options: {
      display: false
    }
  },
  {
    name: 'program_enrolment.is_discovery',
    label: 'Is discovery?',
    widget: 'select',
    widgetFilter: true,
    validate: validateNotEmpty,
    valueLabelMappings: yesNoOptions,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'is_discovery'
    },
    defaultValue: (data) => {
      return get(data, 'discovery_enabled') || false
    }
  },
  {
    name: 'is_termination_requested',
    label: 'Termination Requested',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div>{value ? 'Yes' : 'No'}</div>
      }
    }
  },
  {
    name: 'termination_requested_on',
    label: 'Termination Requested On',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    name: 'terminated_by',
    label: 'Terminated By',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        if (!value) return <div>NA</div>
        return <div>{value?.full_name}</div>
      }
    }
  },
  {
    name: 'reason',
    label: 'Termination Reason',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      },
      setCellProps: () => ({
        style: {
          minWidth: '200px',
          maxWidth: '200px',
          whiteSpace: 'pre-wrap'
        }
      })
    }
  },
  {
    name: 'notes',
    label: 'Termination Notes',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      },
      setCellProps: () => ({
        style: {
          minWidth: '500px',
          maxWidth: '500px',
          whiteSpace: 'pre-wrap'
        }
      })
    }
  },
  {
    name: 'termination_source',
    label: 'Termination Source',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  }
]
