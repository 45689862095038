import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty, validateCount, validateMobile } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Name',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'number',
    label: 'Number',
    validate: validateMobile,
    ...tableColumnDefaultOption
  },
  {
    name: 'grade',
    label: 'Grade',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'city',
    label: 'City',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'referral_code',
    label: 'Referral Code',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'usage_count',
    label: '# of times used',
    disabledInForm: true,
    ...tableColumnDefaultOption
  }
]

export const validKeysMapping = {
  name: 'name',
  number: 'number',
  referralcode: 'referral_code',
  referralcodes: 'referral_code',
  standard: 'grade', // fallback for grade
  grade: 'grade',
  city: 'city',
  count: 'count'
}

export const optionalKeys = ['count']
