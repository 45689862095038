import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import styled, { css } from 'styled-components'
export const Wrapper = styled.div`
  // margin: 20px 10px;
`
export const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 20%;
`

export const Phone = styled.div`
  font-family: AvenirDemi;
  font-size: 14px;
  margin-right: 5px;
`
export const Icon = styled.img``

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
`

export const Centre = styled.div`
  font-family: AvenirDemi;
  font-size: 14px;
  display: flex;
  margin-left: 5px;
  min-width: max-content;
`
export const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

export const AccordionWrapper = styled.div`
  margin-bottom: 10px;
`
export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`
export const AccordionHeader = styled.div`
  font-family: 'Avenir';
  font-weight: 450;
  font-size: 16px;
`
export const Accordion = styled(MuiAccordion)``

export const AccordionSummary = styled(MuiAccordionSummary)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  flex-direction: row;
  & .muiaccordionsummary-expandiconwrapper.mui-expanded: {
    transform: rotate(180deg);
  }
  border-radius: 4px;
`

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 16px 0;
  margin-top: 10px;
`
