import { generateUUID } from '@openhouse-technologies/utils'
import clsx from 'clsx'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { apiEndpoints, getIndianStates } from 'api/common'
import { getGradeDetails } from 'api/user'
import { generateOtp } from 'app/common'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ModalForm from 'app/components/ModalForm'
import { StudentDetails } from 'app/components/shared/StudentDetails'
import {
  commonAttributes,
  indianStatesJson,
  tableColumnDefaultOption,
  uploadPresets
} from 'app/config'
import constants from 'app/constants'
import { fetchStudentData, updateUserData } from 'app/store/actions/userEntity'
import {
  childCustomerDataTransformer,
  studentsParentBasicDetailsTransformer
} from 'app/transformer'
import {
  validateMobile,
  validateNotEmpty,
  validateName,
  validateNotNil,
  validatePostalCode
} from 'app/validators'

import History from '../../../components/generic/history/history'
import Slider from '../../../components/generic/Slider/slider'

import './student.css'

const Student = () => {
  const studentData = useSelector(
    (state) => state.userEntityReducer.studentData
  )
  const [grades, setGrade] = useState({})
  const [states, setStates] = useState({})
  const [parentDetails, setParentDetails] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [studentConfigData, setStudentConfigData] = React.useState([])
  const [studentBasicData, setStudentBasicData] = React.useState([])

  const [sessionToken, setSessionToken] = useState('')

  const getParentDetails = async (parent, childData) => {
    setStudentBasicData(childData)

    const parentData = studentsParentBasicDetailsTransformer(parent, states)
    setParentDetails(parentData)

    const studentData = await childCustomerDataTransformer(childData)
    setStudentConfigData(studentData)

    setIsModalOpen(true)
  }

  useEffect(() => {
    getGradeDetails().then((res) => setGrade(res))
    ;(async () => {
      const { data: indianStates } = await getIndianStates()
      const statesMap = {}
      indianStates.forEach((state) => {
        statesMap[state[0]] = state[1]
      })
      setStates(statesMap)
    })()
  }, [])

  const newColumns = [
    {
      ...commonAttributes.uuid,
      name: 'user.uuid'
    },
    {
      ...commonAttributes.date,
      name: 'created',
      label: 'created at',
      modalEdit: false,
      viewOnlyInModal: true,
      options: {
        ...commonAttributes.date.options,
        filterKey: 'created',
        fixedColumn: false,
        download: {
          serverSide: true,
          type: 'JM_DASHBOARD'
        },
        setCellProps: () => ({
          className: clsx({
            student: true
          })
        }),
        customBodyRender: (value) => {
          const date = new Date(value)
          return <span>{date.toLocaleString()}</span>
        }
      }
    },
    {
      name: 'parent',
      label: 'Parent',
      widget: 'autocomplete',
      minCharactersToSearch: 10,
      modalEdit: true,
      viewOnlyInModal: true,
      widget: 'autocomplete',
      defaultValue: () =>
        new URLSearchParams(window.location.search).get('parent'),
      validate: validateNotNil,
      getStructuredValues: (data) => {
        const getItemStructure = (item) => {
          if (Object.prototype.hasOwnProperty.call(item, 'user')) {
            const { uuid, first_name, last_name, phone_number } = item?.user

            return {
              uuid: uuid,
              name: `${phone_number} (${first_name} ${last_name})`
            }
          } else {
            return {
              uuid: item.uuid,
              name: item.name
            }
          }
        }
        if (Array.isArray(data) && !isEmpty(data)) {
          return [getItemStructure(data[0])]
        } else if (Object.prototype.hasOwnProperty.call(data, 'user')) {
          return [getItemStructure(data)]
        } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
          return data.results.map((item) => {
            return getItemStructure(item)
          })
        } else {
          return data.map((item) => {
            return getItemStructure(item)
          })
        }
      },
      getFormDataValue: (data) => {
        const getItemStructure = (item) => {
          if (Object.prototype.hasOwnProperty.call(item, 'user')) {
            return item.user.uuid
          }
        }
        if (Object.prototype.hasOwnProperty.call(data, 'user')) {
          return getItemStructure(data)
        }
      },
      getOptionLabelKey: 'name',
      getOptionSelectedKey: 'uuid',
      resultStructure: {
        keyName: 'parent',
        keyValue: 'uuid'
      },
      apiOptions: {
        method: 'get',
        url: apiEndpoints.parent
      },
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      }
    },
    {
      name: 'user.phone_number',
      label: 'Phone Number',
      viewOnlyInModal: true,
      modalEdit: true,
      validate: validateMobile,
      options: {
        ...tableColumnDefaultOption.options,
        filterKey: 'phone_number'
      },
      conditionalRender: (data) => {
        return (
          Object.prototype.hasOwnProperty.call(data, 'no_phone_number') &&
          get(data, 'no_phone_number') === false
        )
      }
    },
    {
      name: 'no_phone_number',
      label: 'No phone number! Use parent number for communication',
      widget: 'checkbox',
      viewOnlyInModal: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      },
      defaultValue: new URLSearchParams(window.location.search).get(
        'no_phone_number'
      )
        ? () =>
            new URLSearchParams(window.location.search).get('no_phone_number')
        : (data) => get(data, 'no_phone_number') === true
    },
    {
      name: 'user.full_name',
      label: 'student name',
      modalEdit: false,
      viewOnlyInModal: true,
      options: {
        ...tableColumnDefaultOption.options,
        filterKey: 'name',
        fixedColumn: true,
        download: {
          serverSide: true,
          type: 'JM_DASHBOARD'
        },
        setCellProps: () => ({
          className: clsx({
            student: true
          })
        })
      }
    },
    {
      name: 'user.first_name',
      label: 'first name',
      validate: validateName,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        filterKey: 'name',
        display: false
      }
    },
    {
      name: 'user.last_name',
      label: 'last name',
      modalEdit: true,
      validate: validateName,
      options: {
        ...tableColumnDefaultOption.options,
        filterKey: 'name',
        display: false
      }
    },
    {
      name: 'standard.uuid',
      label: 'grade',
      validate: validateNotEmpty,
      modalEdit: true,
      widgetFilter: true,
      widget: 'select',
      valueLabelMappings: grades,
      options: {
        ...tableColumnDefaultOption.options,
        filterKey: 'grade',
        customBodyRender: (value) => {
          return <span>{grades[value]}</span>
        }
      }
    },
    {
      ...tableColumnDefaultOption,
      name: 'user.state',
      label: 'state',
      widget: 'select',
      valueLabelMappings: states,
      validate: validateNotNil,
      defaultValue: () =>
        new URLSearchParams(window.location.search).get('state'),
      modalEdit: true,
      viewOnlyInModal: true,
      options: {
        ...tableColumnDefaultOption.options,
        filterKey: 'state',
        customBodyRender: (value) => <span>{states[value]}</span>,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const { user } = studentData.data[dataIndex]
          return <span>{states[user.state]}</span>
        },
        display: false
      }
    },
    {
      ...commonAttributes.image,
      name: 'user.image',
      label: 'Profile Picture',
      fileUrlOnly: true,
      modalEdit: true,
      uploadPreset: uploadPresets[constants.USER]
    },
    {
      name: 'session_token',
      label: 'session_token',
      modalEdit: false,
      viewOnlyInModal: true,
      disabledInFormOnCreate: true,
      editable: false,
      options: { display: false },
      defaultValue: (data) => {
        let newSessionToken = generateUUID()
        setSessionToken(newSessionToken)
        return newSessionToken
      }
    },
    {
      name: 'school',
      label: 'school',
      widget: 'autocomplete',
      modalEdit: true,
      minCharactersToSearch: 4,
      debounceTime: 500,
      viewOnlyInModal: true,
      validate: validateNotNil,
      getStructuredValues: (data) => {
        const getItemStructure = (item) => {
          return item
            ? {
                uuid: item.google_place_id || item.place_id,
                name: item.formatted_address
                  ? `${item.name} (${item.formatted_address})`
                  : item.name,
                place_id: item.google_place_id || item.place_id
              }
            : null
        }

        if (Array.isArray(data)) {
          return data?.map((item) => {
            return getItemStructure(item)
          })
        } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
          return data?.results?.map((item) => {
            return getItemStructure(item)
          })
        }

        return [getItemStructure(data)]
      },
      getFormDataValue: (data) => {
        const getItemStructure = (item) => {
          return item.google_place_id || item.place_id
        }
        return getItemStructure(data)
      },
      getOptionLabelKey: 'name',
      getOptionSelectedKey: 'uuid',
      resultStructure: {
        keyName: 'school',
        keyValue: 'place_id'
      },
      apiOptions: {
        method: 'get',
        url: `${apiEndpoints.googlePlaces}/textsearch/?type=school&location=lat,long&session_token=${sessionToken}`
      },
      multiple: false,
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      },
      conditionalRender: (data) => {
        setSessionToken(get(data, 'session_token'))

        return (
          Object.prototype.hasOwnProperty.call(
            data,
            'does_not_attend_school'
          ) &&
          Object.prototype.hasOwnProperty.call(data, 'school_not_found') &&
          get(data, 'does_not_attend_school') === false &&
          get(data, 'school_not_found') === false
        )
      }
    },
    {
      name: 'does_not_attend_school',
      label: 'Check this box if the child does not go to school yet',
      widget: 'checkbox',
      viewOnlyInModal: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      },
      defaultValue: (data) => {
        return get(data, 'does_not_attend_school') || false
      },
      disabled: (data) => {
        return get(data, 'school_not_found') === true
      }
    },
    {
      name: 'school_not_found',
      label: 'Check this box if the school was not found',
      widget: 'checkbox',
      viewOnlyInModal: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      },
      defaultValue: (data) => {
        return get(data, 'school_not_found') || false
      },
      disabled: (data) => {
        return get(data, 'does_not_attend_school') === true
      }
    },
    {
      name: 'temporary_school_name',
      label: 'Please type in the school name you were looking for',
      validate: validateName,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      },
      conditionalRender: (data) => get(data, 'school_not_found') === true
    },
    {
      name: 'temporary_school_postal_code',
      label: 'Pincode',
      validate: validatePostalCode,
      modalEdit: true,
      optional: true,
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      },
      conditionalRender: (data) => get(data, 'school_not_found') === true
    },
    {
      name: 'temporary_school_city',
      label: 'City',
      validate: validateName,
      modalEdit: true,
      optional: true,
      options: {
        ...tableColumnDefaultOption.options,
        display: false
      },
      conditionalRender: (data) => get(data, 'school_not_found') === true,
      message: {
        text: "*your school will be added in 6-7 days, and we'll automatically update it for you.",
        style: { color: 'black', marginTop: '-4px' }
      }
    },
    {
      name: 'additional_information.AREAS_OF_DEVELOPMENT',
      label: 'Areas of development',
      widget: 'autocomplete',
      optional: true,
      multiple: true,
      modalEdit: true,
      minCharactersToSearch: 0,
      validate: validateNotEmpty,
      getStructuredValues: (data) => {
        const getItemStructure = (item) => {
          return item
            ? {
                name: item.text,
                uuid: item
              }
            : null
        }

        if (Array.isArray(data)) {
          return data?.map((item) => {
            return {
              name: item.text,
              uuid: item
            }
          })
        } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
          return data?.results[0]?.value
            ?.filter((el) => el.key === 'AREAS_OF_DEVELOPMENT')[0]
            ?.options?.map((item) => {
              return getItemStructure(item)
            })
        }

        return [
          {
            name: data.AREAS_OF_DEVELOPMENT,
            uuid: data.AREAS_OF_DEVELOPMENT
          }
        ]
      },
      getFormDataValue: (data) => {
        const getItemStructure = (item) => {
          return item.uuid ? item.uuid : item ? item : ''
        }

        return data.map((item) => {
          return getItemStructure(item)
        })
      },
      getOptionLabelKey: 'name',
      getOptionSelectedKey: 'uuid',
      resultStructure: {
        keyName: 'additional_information.AREAS_OF_DEVELOPMENT',
        keyValue: 'uuid'
      },
      apiOptions: {
        method: 'get',
        url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_STUDENT&app_name=alfred`
      },
      options: {
        ...tableColumnDefaultOption.options,
        display: false,
        disableFilter: true
      }
    },
    {
      name: 'additional_information.AREAS_OF_DEVELOPMENT_OTHERS',
      label: 'Other areas of development',
      optional: true,
      validate: validateNotEmpty,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.EXPECTATIONS',
      label: 'Expectations from Openhouse',
      widget: 'autocomplete',
      optional: true,
      modalEdit: true,
      multiple: true,
      minCharactersToSearch: 0,
      validate: validateNotEmpty,
      getStructuredValues: (data) => {
        const getItemStructure = (item) => {
          return item
            ? {
                name: item.text,
                uuid: item
              }
            : null
        }

        if (Array.isArray(data)) {
          return data?.map((item) => {
            return {
              name: item.text,
              uuid: item
            }
          })
        } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
          return data?.results[0]?.value
            ?.filter((el) => el.key === 'EXPECTATIONS')[0]
            ?.options?.map((item) => {
              return getItemStructure(item)
            })
        }

        return [
          {
            name: data.EXPECTATIONS,
            uuid: data.EXPECTATIONS
          }
        ]
      },
      getFormDataValue: (data) => {
        const getItemStructure = (item) => {
          return item.uuid ? item.uuid : item ? item : ''
        }

        return data.map((item) => {
          return getItemStructure(item)
        })
      },
      getOptionLabelKey: 'name',
      getOptionSelectedKey: 'uuid',
      resultStructure: {
        keyName: 'additional_information.EXPECTATIONS',
        keyValue: 'uuid'
      },
      apiOptions: {
        method: 'get',
        url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_STUDENT&app_name=alfred`
      },
      options: {
        ...tableColumnDefaultOption.options,
        display: false,
        disableFilter: true
      }
    },
    {
      name: 'additional_information.EXPECTATIONS_OTHERS',
      label: 'Other expectations from Openhouse',
      optional: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.ALLERGIES',
      label: 'Allergies (if any)',
      optional: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.MEDICAL_CONDITIONS',
      label: 'Medical Conditions (if any)',
      optional: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.FAVOURITE_COLOUR',
      label: 'Favourite colour',
      optional: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.FAVOURITE_FICTIONAL_CHARACTER',
      label: 'Favourite fictional character',
      optional: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.FAVOURITE_HOBBIES',
      label: 'Favourite hobbies',
      optional: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.OTHER_ACTIVITIES',
      label: 'Other activities outside school',
      widget: 'autocomplete',
      optional: true,
      multiple: true,
      modalEdit: true,
      minCharactersToSearch: 0,
      validate: validateNotEmpty,
      getStructuredValues: (data) => {
        const getItemStructure = (item) => {
          return item
            ? {
                name: item.text,
                uuid: item
              }
            : null
        }

        if (Array.isArray(data)) {
          return data?.map((item) => {
            return {
              name: item.text,
              uuid: item
            }
          })
        } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
          return data?.results[0]?.value
            ?.filter((el) => el.key === 'OTHER_ACTIVITIES')[0]
            ?.options?.map((item) => {
              return getItemStructure(item)
            })
        }

        return [
          {
            name: data.OTHER_ACTIVITIES,
            uuid: data.OTHER_ACTIVITIES
          }
        ]
      },
      getFormDataValue: (data) => {
        const getItemStructure = (item) => {
          return item.uuid ? item.uuid : item ? item : ''
        }

        return data.map((item) => {
          return getItemStructure(item)
        })
      },
      getOptionLabelKey: 'name',
      getOptionSelectedKey: 'uuid',
      resultStructure: {
        keyName: 'additional_information.OTHER_ACTIVITIES',
        keyValue: 'uuid'
      },
      apiOptions: {
        method: 'get',
        url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_STUDENT&app_name=alfred`
      },
      options: {
        ...tableColumnDefaultOption.options,
        display: false,
        disableFilter: true
      }
    },
    {
      name: 'additional_information.NAME_OF_ACTIVITIES',
      label: 'Name of the activities',
      optional: true,
      modalEdit: true,
      options: {
        ...tableColumnDefaultOption.options,
        disableFilter: true,
        display: false
      }
    },
    {
      name: 'additional_information.LANGUAGES',
      label: 'Languages the child can understand',
      widget: 'autocomplete',
      multiple: true,
      optional: true,
      modalEdit: true,
      minCharactersToSearch: 0,
      validate: validateNotEmpty,
      getStructuredValues: (data) => {
        const getItemStructure = (item) => {
          return item
            ? {
                name: item,
                uuid: item
              }
            : null
        }

        if (Array.isArray(data)) {
          return data?.map((item) => {
            return {
              name: item,
              uuid: item
            }
          })
        } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
          return data?.results[0]?.value
            ?.filter((el) => el.key === 'LANGUAGES')[0]
            ?.options?.map((item) => {
              return getItemStructure(item)
            })
        }

        return [
          {
            name: data.LANGUAGES,
            uuid: data.LANGUAGES
          }
        ]
      },
      getFormDataValue: (data) => {
        const getItemStructure = (item) => {
          return item.uuid ? item.uuid : item ? item : ''
        }

        return data.map((item) => {
          return getItemStructure(item)
        })
      },
      getOptionLabelKey: 'name',
      getOptionSelectedKey: 'uuid',
      resultStructure: {
        keyName: 'additional_information.LANGUAGES',
        keyValue: 'uuid'
      },
      apiOptions: {
        method: 'get',
        url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_STUDENT&app_name=alfred`
      },
      options: {
        ...tableColumnDefaultOption.options,
        display: false,
        disableFilter: true
      }
    },
    {
      name: 'additional_information.RETURN_TIME_FROM_SCHOOL',
      label: 'Return time from school',
      widget: 'autocomplete',
      optional: true,
      modalEdit: true,
      minCharactersToSearch: 0,
      validate: validateNotEmpty,
      getStructuredValues: (data) => {
        const getItemStructure = (item) => {
          return item
            ? {
                name: item,
                uuid: item
              }
            : null
        }

        if (Array.isArray(data)) {
          return data?.map((item) => {
            return {
              name: item,
              uuid: item
            }
          })
        } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
          return data?.results[0]?.value
            ?.filter((el) => el.key === 'RETURN_TIME_FROM_SCHOOL')[0]
            ?.options?.map((item) => {
              return getItemStructure(item)
            })
        }

        return [getItemStructure(data)]
      },
      getFormDataValue: (data) => {
        const getItemStructure = (item) => {
          return item.uuid ? item.uuid : item ? item : ''
        }

        return getItemStructure(data)
      },
      getOptionLabelKey: 'name',
      getOptionSelectedKey: 'uuid',
      resultStructure: {
        keyName: 'additional_information.RETURN_TIME_FROM_SCHOOL',
        keyValue: 'uuid'
      },
      apiOptions: {
        method: 'get',
        url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_STUDENT&app_name=alfred`
      },
      options: {
        ...tableColumnDefaultOption.options,
        display: false,
        disableFilter: true
      }
    },
    {
      name: '',
      disabledInForm: true,
      options: {
        display: false,
        customBodyRenderLite: (dataIndex) => {
          const { user } = studentData.data[dataIndex]
          return (
            <div>
              <Button fullWidth={true} onClick={() => generateOtp(user)}>
                Generate Otp
              </Button>
            </div>
          )
        }
      }
    },
    {
      name: '',
      disabledInForm: true,
      options: {
        customBodyRenderLite: (dataIndex) => {
          const { user, parent } = studentData?.data[dataIndex]
          const childData = studentData?.data[dataIndex]
          return parent?.user_id ? (
            <Button onClick={() => getParentDetails(parent, childData)}>
              See profile Details
            </Button>
          ) : null
        }
      }
    }
  ]

  return (
    <>
      <BaseEntity
        entity={constants.STUDENT}
        label={constants.STUDENT_LABEL}
        columns={newColumns}
        data={studentData}
        fetchData={fetchStudentData}
        updateEntityData={updateUserData}
        disableDelete
      />
      <ModalForm
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        enableSave={false}>
        <StudentDetails
          parentDetails={parentDetails}
          studentBasicData={studentBasicData}
          studentData={studentConfigData?.configData}
        />
      </ModalForm>
    </>
  )
}

export default Student
