/* eslint-disable react/prop-types */
/* eslint-disable import/order */

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import React from 'react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { DayHeaderContent } from './components/DayHeaderContent'
import { ScheduleCard } from './components/ScheduleCard'
// import { events } from './mocks/events'
import { Container } from './styles'
import { fetchInventorySchedules } from 'api'
import constants from 'app/constants'
import { eventEmitter, showToast } from 'app/helpers'
import { isEmpty } from 'lodash'
import { composeBatchDetails } from '../../transformers'

dayjs.extend(customParseFormat)

const Schedule = ({
  selectedBatchType,
  selectedLevel = '',
  selectedGrades = [],
  selectedCategories = [],
  selectedCentres = []
}) => {
  const fcRef = React.useRef(null)

  return (
    <Container>
      <FullCalendar
        ref={fcRef}
        plugins={[dayGridPlugin]}
        initialView="dayGridWeek"
        firstDay="1" // monday
        headerToolbar={{ left: 'prev next title', center: '', right: '' }}
        titleFormat={{
          day: 'numeric',
          month: 'short'
        }}
        eventContent={(props) => <ScheduleCard {...props} />}
        dayHeaderContent={DayHeaderContent}
        events={async (dateRange, successCB, failureCB) => {
          try {
            if (isEmpty(selectedCentres) || isEmpty(selectedCategories))
              return successCB([])

            showToast('Fetching Schedule...', constants.INFO)

            const { results } = await fetchInventorySchedules({
              queries: {
                limit: 9999,
                centre_uuid:
                  // TODO - uncomment after batch api supports multiple centre uuids
                  // selectedCentres.map((item) => item.value).join(',') ||
                  selectedCentres.value || undefined,
                standards_uuid:
                  selectedGrades.map((item) => item.value).join(',') ||
                  undefined,
                subjects_uuid:
                  selectedCategories.map((item) => item.value).join(',') ||
                  undefined,
                type: selectedBatchType,
                level: selectedLevel,
                date_gte: dayjs(dateRange.start).format('YYYY-MM-DD'),
                date_lte: dayjs(dateRange.end)
                  .subtract(1, 'day')
                  .format('YYYY-MM-DD')
              }
            })
            const schedule = []
            const dateStart = dayjs(dateRange.start).format('YYYY-MM-DD')

            results.forEach((batch) => {
              const { name, uuid, cadence, start_date, end_date } = batch

              cadence.forEach((cad) => {
                const startTime = dayjs(cad.start_time, 'HH-mm').format(
                  'hh:mm:ss a'
                )
                const startDateTime = dayjs(
                  `${dateStart} ${startTime}`,
                  'YYYY-MM-DD hh:mm:ss a',
                  false
                ).add(cad.weekday, 'day')

                let isWithinRange =
                  startDateTime.isSameOrAfter(start_date) &&
                  (startDateTime.isSame(end_date, 'day') ||
                    startDateTime.isBefore(end_date))

                let isDisabled = false

                if (selectedBatchType === 'explore') {
                  isDisabled =
                    startDateTime.isSameOrAfter(start_date) &&
                    (startDateTime.isSame(end_date, 'day') ||
                      startDateTime.isBefore(end_date)) &&
                    startDateTime.isSame(dayjs(), 'day') &&
                    startDateTime.isBefore(dayjs(), 'minute')
                }

                if (!isWithinRange) return

                const { alternateBatches: timings } = composeBatchDetails(batch)

                schedule.push({
                  id: uuid,
                  title: name,
                  start: startDateTime.toDate(),
                  extendedProps: {
                    ...batch,
                    isDisabled: isDisabled,
                    timings
                  }
                })
              })
            })

            if (isEmpty(schedule)) {
              showToast('Uh.oh! No batches found', constants.ERROR)
            }
            successCB(schedule)
            eventEmitter.emit(constants.BATCH_RESELECTION)
          } catch (e) {
            failureCB([])
            showToast('Oh.oh! Something went wrong', constants.ERROR)
          }
        }}
      />
    </Container>
  )
}

export default Schedule
