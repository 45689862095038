import { all, fork } from 'redux-saga/effects'

import { watchHandleLogout } from './auth'
import {
  watchFetchClassEntity,
  watchUpdateClassEntity,
  watchCreateDemoClassBooking,
  watchCreateClassBooking,
  watchCreateExcelBooking
} from './classEntity'
import { watchFetchGeographicalEntities } from './common'
import {
  watchFetchCommunicationEntity,
  watchHandleBulkMessagingEvents,
  watchHandleBulkMessagingFetchEvents,
  watchUpdateCommunicationEntity
} from './communicationEntity'
import {
  watchFetchCommunityEntity,
  watchUpdateCommunityEntity
} from './communityEntity'
import { watchFetchEventEntity, watchUpdateEventEntity } from './eventEntity'
import { watchFetchMockTest, watchUpdateMockTest } from './mockTestEntity'
import { watchFetchOrdersEntity } from './ordersEntity'
import {
  watchFetchPaymentEntity,
  watchUpdatePaymentEntity
} from './paymentEntity'
import { watchFetchSpaceEntity, watchUpdateSpaceEntity } from './spaceEntity'
import { watchFetchUserEntity, watchUpdateUserEntity } from './userEntity'

const sagas = [
  fork(watchHandleLogout),
  fork(watchFetchUserEntity),
  fork(watchUpdateUserEntity),
  fork(watchFetchClassEntity),
  fork(watchUpdateClassEntity),
  fork(watchCreateDemoClassBooking),
  fork(watchCreateClassBooking),
  fork(watchCreateExcelBooking),
  fork(watchFetchSpaceEntity),
  fork(watchUpdateSpaceEntity),
  fork(watchFetchPaymentEntity),
  fork(watchUpdatePaymentEntity),
  fork(watchFetchCommunicationEntity),
  fork(watchUpdateCommunicationEntity),
  fork(watchHandleBulkMessagingEvents),
  fork(watchHandleBulkMessagingFetchEvents),
  fork(watchFetchCommunityEntity),
  fork(watchUpdateCommunityEntity),
  fork(watchFetchEventEntity),
  fork(watchUpdateEventEntity),
  fork(watchFetchMockTest),
  fork(watchUpdateMockTest),
  fork(watchFetchGeographicalEntities),
  fork(watchFetchOrdersEntity)
]

export default function* rootSaga() {
  yield all(sagas)
}
