import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchRoomData, updateSpaceData } from 'app/store/actions/spaceEntity'

import { columns } from './config'

const Room = () => {
  const roomData = useSelector((state) => state.spaceEntityReducer.roomData)

  return (
    <BaseEntity
      entity={constants.ROOM}
      label={constants.ROOM_LABEL}
      columns={columns}
      data={roomData}
      fetchData={fetchRoomData}
      updateEntityData={updateSpaceData}
      disableDelete
    />
  )
}

export default Room
