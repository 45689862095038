import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import {
  fetchWaitlistOthersData,
  updateCommunityData
} from 'app/store/actions/communityEntity'

import { columns } from './config'

const Others = () => {
  const waitlistOthersData = useSelector(
    (state) => state.communityEntityReducer.waitlistOthersData
  )

  return (
    <>
      <BaseEntity
        disableEditButton
        entity={constants.WAITLIST_OTHERS}
        label={constants.WAITLIST_OTHERS_LABEL}
        fetchData={fetchWaitlistOthersData}
        data={waitlistOthersData}
        columns={columns}
        updateEntityData={updateCommunityData}
        readOnly
      />
    </>
  )
}

export default Others
