import styled from 'styled-components'

export const Container = styled.div`
  .header {
    font-size: 2rem;
    color: var(--oh-black-primary);
    font-weight: 600;
    font-family: 'Avenir';
  }
  .arrival {
    max-height: 2.5rem;
    width: 200px;
    font-family: 'Avenir';
  }
  .present {
    color: var(--oh-teal);
  }
  .absent {
    color: var(--oh-red);
  }
  .bold {
    font-weight: 600;
  }
  .topHeader {
    overflow-x: hidden;
  }
  .teacherDetails {
    width: 200px;
  }
`
