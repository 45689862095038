import {
  commonStructure,
  handleEntitiesUpdate,
  dataResponseHandler,
  dataFetchingHandler
} from './reducer.helper'

import * as types from '../types/mockTest'

const initialState = {
  paperData: commonStructure(),
  pricingData: commonStructure(),
  mockTestReportCard: commonStructure()
}

const mockTestEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MOCK_PAPER_DATA:
    case types.FETCH_MOCK_PRICING_DATA:
    case types.FETCH_MOCK_TEST_REPORT_CARD_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_MOCK_PAPER_DATA_SUCCESS:
    case types.FETCH_MOCK_PRICING_DATA_SUCCESS:
    case types.FETCH_MOCK_TEST_REPORT_CARD_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    case types.UPDATE_MOCK_PAPER_DATA_SUCCESS:
      return handleEntitiesUpdate(state, action)

    default:
      return state
  }
}

export { mockTestEntityReducer }
