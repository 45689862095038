import styled from 'styled-components';
import colors from 'app/theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.5rem;
`

export const ClickableTitle = styled.a`
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors['oh-orange']} !important;
    text-decoration: underline !important;
  }
`

export const NonClickableTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`

export const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

