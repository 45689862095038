/* eslint-disable react/prop-types */
import React from 'react'

import {
  batchTypesMap,
  BookingTypes
} from 'app/pages/Class/Inventory/constants'
import { composeBatchDetails } from 'app/pages/Class/Inventory/transformers'
import { formatAmount } from 'app/utils'

import * as Styles from './styles'

import {
  calculateDiscountPercentage,
  calculateTotalPrice,
  calculateDiscountedPrice
} from '../../formConfig/helpers'

const PricingDetails = ({
  cartItems,
  formData,
  batchInfo,
  bookingType,
  selectedParentDetails,
  selectedBatchType
}) => {
  const {
    is_discount_in_percentage: isDiscountInPercentage,
    discount_percentage: discountPercentage,
    custom_pricing: customPricing
  } = formData

  const [totalCoinsUsed, setTotalCoinsUsed] = React.useState(0)
  const [totalCreditsUsed, setTotalCreditsUsed] = React.useState(0)

  const actualPrice = calculateTotalPrice(cartItems)
  const firstTimeFee = Object.values(batchInfo).reduce(
    (acc, curr) => acc + (curr.firstTimeFee || 0),
    0
  )

  let discountPercentageValue = calculateDiscountPercentage({
    isDiscountInPercentage,
    discountPercentage,
    actualPrice,
    customPricing
  })

  const discountedPrice = calculateDiscountedPrice(
    actualPrice,
    discountPercentageValue
  )

  React.useEffect(() => {
    const getTotal = (acc, curr) => {
      const coins =
        selectedBatchType === 'membership' ||
        selectedBatchType === 'event' ||
        selectedBatchType === batchTypesMap.explore ||
        selectedBatchType === batchTypesMap.excel
          ? composeBatchDetails(curr)?.coinsTotal || 0
          : composeBatchDetails(curr)?.coins || 0
      return acc + coins
    }
    const getTotalCredits = (acc, curr) => {
      const credits =
        selectedBatchType === batchTypesMap.explore
          ? composeBatchDetails(curr)?.creditsApplied || 0
          : 0
      return acc + credits
    }
    const coinsUsed = Object.values(cartItems).reduce(getTotal, 0)
    const creditsUsed = Object.values(cartItems).reduce(getTotalCredits, 0)
    setTotalCoinsUsed(coinsUsed)
    setTotalCreditsUsed(creditsUsed)
  }, [cartItems])

  const totalCoins = selectedParentDetails?.coins || 0
  const totalCredits = selectedParentDetails?.credits || 0

  return bookingType !== BookingTypes.EXPLORATION ? (
    bookingType === BookingTypes.CLASS ? (
      <Styles.Container>
        <Styles.LeftContainer>
          <p>First Month Fee</p>
          <p>Recurring Discount</p>
          <h3>Recurring Amount</h3>
        </Styles.LeftContainer>
        <Styles.RightContainer>
          <p>{formatAmount(firstTimeFee)}</p>
          <p>{discountPercentageValue}%</p>
          <h3>
            {discountPercentageValue > 0 ? (
              <del>{formatAmount(actualPrice)}</del>
            ) : null}
            {formatAmount(discountedPrice)}
          </h3>
        </Styles.RightContainer>{' '}
      </Styles.Container>
    ) : selectedBatchType === batchTypesMap.explore && totalCoinsUsed ? (
      <Styles.Container>
        <Styles.LeftContainer>
          <p>Total in wallet</p>
          <p>Total left</p>
          <h3>Total used</h3>
        </Styles.LeftContainer>
        <Styles.RightContainer>
          <p>{`${totalCoins} coins & ${totalCredits} free trial`}</p>
          <p>{`${totalCoins - totalCoinsUsed} coins & ${
            totalCredits - totalCreditsUsed
          } free trial`}</p>
          <h3>{`${totalCoinsUsed} coins & ${totalCreditsUsed} free trial`}</h3>
        </Styles.RightContainer>
      </Styles.Container>
    ) : selectedBatchType === batchTypesMap.explore ? (
      <Styles.Container>
        {totalCreditsUsed ? (
          <>
            <Styles.LeftContainer>
              <p>Free trials in wallet</p>
              <p>Free trials left</p>
              <h3>Free trials used</h3>
            </Styles.LeftContainer>
            <Styles.RightContainer>
              <p>{`${totalCredits} free trial`}</p>
              <p>{`${totalCredits - totalCreditsUsed} free trial`}</p>
              <h3>{`${totalCreditsUsed} free trial`}</h3>
            </Styles.RightContainer>
          </>
        ) : null}
      </Styles.Container>
    ) : (
      <Styles.Container>
        <Styles.LeftContainer>
          <p>Coins in wallet</p>
          <p>Balance left</p>
          <h3>Total Deduction</h3>
        </Styles.LeftContainer>
        <Styles.RightContainer>
          <p>{totalCoins}</p>
          <p>{totalCoins - totalCoinsUsed}</p>
          <h3>{totalCoinsUsed}</h3>
        </Styles.RightContainer>
      </Styles.Container>
    )
  ) : null
}

export { PricingDetails }
