import React from 'react'
import * as styles from './styles'
import colors from 'app/theme/colors'
import { emptyDataIcon } from 'app/pages/Communication/Chat/constants'

const EmptyDataIcon = ({ message = 'messages' }) => {
  return (
    <styles.Container>
      <styles.Icon src={emptyDataIcon} />
      <styles.Text>
        <span style={{ color: colors['oh-orange'], fontWeight: 600 }}>oh! </span>
        <span>{`No ${message} to show`}</span>
      </styles.Text>
    </styles.Container>
  )
}

export default EmptyDataIcon