import { Typography as TG } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin: 8px;

  & .formControlStyles:not(:last-of-type) {
    margin-bottom: 15px;
  }
`

export const Typography = styled(TG)`
  ${(props) =>
    css`
      ${props.$css}
    `}
`
