import React from 'react'
import { useSelector } from 'react-redux'

import AssetUploadWidget from 'app/components/AssetUploadWidget'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { navigationPaths } from 'app/config'
import constants from 'app/constants'
import { fetchCentreData, updateSpaceData } from 'app/store/actions/spaceEntity'

import { columns } from './config'

const Centre = () => {
  const centreData = useSelector((state) => state.spaceEntityReducer.centreData)

  const showRooms = React.useCallback(
    (dataIndex) => {
      const { uuid } = centreData.data[dataIndex]

      window.open(
        `${window.location.origin}${navigationPaths.room}?centre_uuid=${uuid}&filterResult=1`,
        '_blank'
      )
    },
    [centreData]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <ButtonGroup>
                <Button onClick={() => showRooms(dataIndex)}>Show Rooms</Button>
              </ButtonGroup>
            )
          }
        }
      },
      {
        name: 'images',
        label: 'Upload image(s)',
        viewOnlyInModal: true,
        modalEdit: true,
        optional: true,
        widget: AssetUploadWidget,
        options: {
          display: false,
          disableFilter: true
        }
      },
      {
        name: 'videos',
        label: 'Upload video(s)',
        viewOnlyInModal: true,
        modalEdit: true,
        optional: true,
        widget: (props) => <AssetUploadWidget assetType="video" {...props} />,
        options: {
          display: false,
          disableFilter: true
        }
      }
    ]
  })

  return (
    <BaseEntity
      entity={constants.CENTRE}
      label={constants.CENTRE_LABEL}
      columns={modifiedColumns}
      data={centreData}
      fetchData={fetchCentreData}
      updateEntityData={updateSpaceData}
    />
  )
}

export default Centre
