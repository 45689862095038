import { isEmpty } from 'lodash'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const roomRequestStructure = (data) => ({
  name: data.name,
  centre: data.centre,
  capacity: data.capacity
})

const fetchRoom = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.room, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching room :- ', error.message)
    throw error
  }
}

const createRoom = async (data) => {
  try {
    const response = await post(apiEndpoints.room, roomRequestStructure(data))

    return response.data
  } catch (error) {
    console.log('error in creating room :- ', error.message)
    throw error
  }
}

const updateRoom = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.room}/${data.uuid}/`,
      roomRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in updating room :- ', error.message)
    throw error
  }
}

const deleteRoom = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.room}/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting room :- ', error.message)
    throw error
  }
}

export { fetchRoom, createRoom, updateRoom, deleteRoom }
