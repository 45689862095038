import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color:  ${({ authorType }) => (authorType === 'admin' ? '#E27557' : authorType === 'parent' ? '#F7EEE9' : authorType === 'NOT ME' ? '#F7EEE9' : '#EECDC1')};
  color: ${({ authorType }) => (authorType === 'admin' ? 'white' : 'black')};
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  border-bottom-left-radius: ${({ authorType }) => (authorType !== 'admin' ? '2px' : '12px')};
  border-bottom-right-radius: ${({ authorType }) => (authorType === 'admin' ? '2px' : '12px')};
`

export const Icon = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
`

export const Text = styled.div`
  margin-right: 0.25rem;
`