import ButtonComponent from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    textTransform: 'lowercase'
  }
}))

const Button = (props) => {
  const classes = useStyles()

  return (
    <ButtonComponent
      variant="contained"
      {...props}
      className={classes.button}
    />
  )
}

export default Button
