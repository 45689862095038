import { get, isEmpty } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api/common'
import NestedFieldWidget from 'app/components/NestedFieldWIdget'
import {
  commonAttributes,
  tableColumnDefaultOption,
  uploadPresets,
  yesNoOptions
} from 'app/config'
import constants from 'app/constants'
import { sanitize } from 'app/utils'
import { validateCount, validateNotEmpty } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'subject_category.name',
    label: 'Subject Category Name',
    formDataKey: 'subject_category',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data) && !isEmpty(data)) {
        return [getItemStructure(data[0])]
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'subject_category',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.subjectCategory
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'subject_category_name'
    }
  },
  {
    name: 'name',
    label: 'Subject Name',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'subject'
    }
  },
  {
    name: 'internal_name',
    label: 'Internal Subject Name',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'internal_name'
    }
  },
  {
    name: 'description',
    label: 'Description',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'description',
      setCellProps: () => ({
        style: { minWidth: '500px', maxWidth: '500px', whiteSpace: 'pre-wrap' }
      })
    }
  },
  {
    name: 'progress_enabled',
    label: 'Enable journey',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: yesNoOptions,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    },
    defaultValue: (data) => {
      return get(data, 'progress_enabled') || false
    }
  },
  {
    name: 'discovery_enabled',
    label: 'Enable discovery',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: yesNoOptions,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    },
    defaultValue: (data) => {
      return get(data, 'discovery_enabled') || false
    }
  },
  {
    name: 'centres',
    label: 'Centres enabled for',
    formDataKey: 'centres',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'centres',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.centre
    },
    multiple: true,
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centres',
      display: false
    }
  },
  {
    name: 'certifications',
    label: 'Certifications',
    formDataKey: 'certifications',
    widget: 'autocomplete',
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.title
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'certifications',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.certifications
    },
    multiple: true,
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <span>{value?.map((el) => el.title)?.join(',')}</span>
      }
    },
    conditionalRender: (data) => {
      return (
        get(data, 'progress_enabled') === 'true' ||
        get(data, 'progress_enabled') === true
      )
    }
  },
  {
    name: 'standard_group',
    label: 'Grade group',
    widget: 'autocomplete',
    optional: true,
    modalEdit: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item ? { name: item, uuid: item } : null
      }
      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'standard_group',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=SUBJECT_STANDARD_GROUPS`
    },
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'coins_per_month',
    label: 'Coins per month',
    optional: true,
    defaultValue: (data) => 0,
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        return <span>{value}</span>
      }
    }
  },
  {
    name: 'subject_media',
    label: 'sneak peak',
    optional: true,
    widget: NestedFieldWidget,
    viewOnlyInModal: true,
    //config contains all fields that are present under interaction_topics
    config: {
      ctaLabel: '+ another media',
      fields: [
        {
          name: 'caption',
          label: 'Caption',
          editable: true,
          optional: true,
          maxCharacters: 40,
          options: {
            ...tableColumnDefaultOption.options,
            disableFilter: true,
            display: false
          }
        },
        {
          ...commonAttributes.files,
          type: 'fileupload',
          name: 'file',
          label: 'Media',
          newMedia: true, //Adding this until the deprecated file upload can be removed
          uploadPreset: uploadPresets[constants.OPENBOOK],
          optional: false,
          options: {
            disableFilter: true
          }
        }
      ]
    },
    disabledInForm: false,
    modalEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    ...commonAttributes.image,
    label: 'Image*',
    fileUrlOnly: true,
    validate: validateNotEmpty,
    uploadPreset: uploadPresets[constants.SUBJECT]
  },
  {
    ...commonAttributes.image,
    name: 'app_image',
    label: 'App Image*',
    fileUrlOnly: true,
    validate: validateNotEmpty,
    uploadPreset: uploadPresets[constants.SUBJECT]
  },
  {
    ...commonAttributes.files,
    name: 'subject_ratings[2].media_url',
    label: 'File for 1 star',
    uploadPreset: uploadPresets[constants.SUBJECT],
    fileUrlOnly: true,
    options: {
      display: false
    }
  },
  {
    ...commonAttributes.files,
    name: 'subject_ratings[1].media_url',
    label: 'File for 2 star',
    uploadPreset: uploadPresets[constants.SUBJECT],
    fileUrlOnly: true,
    options: {
      display: false
    }
  },
  {
    ...commonAttributes.files,
    name: 'subject_ratings[0].media_url',
    label: 'File for 3 star',
    uploadPreset: uploadPresets[constants.SUBJECT],
    fileUrlOnly: true,
    options: {
      display: false
    }
  }
]
