import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import { fetchCancelledSession } from 'api'
import { fetchMakeUpSession } from 'api/makeUpSession'
import { enrolmentTypes } from 'app/config'
import { EXCLUDE_FROM_OPENDAY_COURSE_ID } from 'app/constants'
import { sentryLogger } from 'sentry'

import { batchTypesMap } from '../../Inventory/constants'

export const sortCompare = (a, b) => {
  const aTime = `${a?.schedule?.date}`
  const bTime = `${b?.schedule.date}`
  if (dayjs(aTime).isBefore(dayjs(bTime))) return 1
  if (dayjs(aTime).isAfter(dayjs(bTime))) return -1
  return 0
}

export const fetchCancelledSessions = async (value) => {
  try {
    const queries = {
      cancelled: true,
      student: value,
      limit: 200,
      enrolment_type: `${enrolmentTypes.PROGRAM}`,
      batch_type: batchTypesMap.excel,
      is_makeup_allowed: true
    }
    const response = await fetchCancelledSession({ queries })
    if (
      Array.isArray(response?.results?.results) &&
      !isEmpty(response?.results?.results)
    ) {
      //filtering out already booked make-ups
      const filteredList = response?.results?.results
        .filter((item) => {
          if (isEmpty(item?.makeup_enrolment)) {
            return item
          }
        })
        .filter((obj) => {
          const date = dayjs(obj.schedule.date)
          const month = date.month() + 1
          const day = date.date()

          // Exclude objects for the 23rd and 24th of September except for phonics
          if (
            month === 9 &&
            day >= 23 &&
            day <= 24 &&
            obj?.enrolment?.batch?.course?.uuid !==
              EXCLUDE_FROM_OPENDAY_COURSE_ID &&
            obj.enrolment?.type !== enrolmentTypes.MAKE_UP_SESSION
          ) {
            return false
          }

          return true
        })

      return filteredList?.sort(sortCompare).map((item) => {
        const batch = item?.enrolment?.batch
        const centre = batch?.centre
        const course = batch?.course
        const teacher = batch?.teacher?.full_name
        const batchDate = dayjs(item.schedule.date).format('DD-MMM-YY, ddd')
        const batchTime = dayjs(
          `${item.schedule.date} ${item.schedule.start_time}`
        ).format('h:mm A')
        return {
          uuid: item.uuid,
          data: `${centre.name}, ${course.name}, ${teacher}, ${batchDate} ${batchTime}`,
          value: {
            batch_type: batch.type,
            centre_uuid: centre.uuid,
            course_uuid: course.uuid,
            date_gte: item.schedule.date,
            uuid: item.uuid,
            reason: item.reason
          }
        }
      })
    }
  } catch (error) {
    sentryLogger(error, 'Error in fetching canclled session')
    throw error
  }
}

export const fetchNoShowSessions = async (value) => {
  try {
    const queries = {
      cancelled: false,
      attended: false,
      date_lte: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      student: value,
      limit: 200,
      enrolment_type: `${enrolmentTypes.PROGRAM}`,
      batch_type: batchTypesMap.excel,
      is_makeup_allowed: true
    }
    const response = await fetchCancelledSession({ queries })
    if (
      Array.isArray(response?.results?.results) &&
      !isEmpty(response?.results?.results)
    ) {
      const filteredList = response?.results?.results.filter((item) => {
        if (isEmpty(item?.makeup_enrolment)) {
          return item
        }
      })

      return filteredList?.sort(sortCompare).map((item) => {
        const batch = item?.enrolment?.batch
        const centre = batch?.centre
        const course = batch?.course
        const teacher = batch?.teacher?.full_name
        const batchDate = dayjs(item.schedule.date).format('DD-MMM-YY, ddd')
        const batchTime = dayjs(
          `${item.schedule.date} ${item.schedule.start_time}`
        ).format('h:mm A')
        return {
          uuid: item.uuid,
          data: `${centre.name}, ${course.name}, ${teacher}, ${batchDate} ${batchTime}`,
          value: {
            batch_type: batch.type,
            centre_uuid: centre.uuid,
            course_uuid: course.uuid,
            date_gte: item.schedule.date,
            uuid: item.uuid,
            reason: item.reason
          }
        }
      })
    }
  } catch (error) {
    sentryLogger(error, 'Error in fetching no-show session')
    throw error
  }
}

export const fetchMakeUpSessions = async (value, selectedStudentDetails) => {
  try {
    const finalDate = dayjs().isBefore(dayjs(value.date_gte))
      ? value.date_gte
      : dayjs().format('YYYY-MM-DD')

    const queries = {
      student_uuid: selectedStudentDetails,
      course_uuid: value?.course_uuid,
      centre_uuid: value?.centre_uuid,
      batch_type: value?.batch_type,
      attendance_uuid: value?.uuid,
      date_gte: finalDate,
      limit: 8
    }

    const response = await fetchMakeUpSession({ queries })
    if (
      Array.isArray(response.results.results) &&
      !isEmpty(response.results.results)
    ) {
      const filteredList = response.results.results.filter(
        (item) => item?.single_session_vacancy > 0
      )

      return filteredList.map((item) => {
        if (isEmpty(item.makeup_enrolment)) {
          const batch = item.batch
          const centre = batch.centre
          const course = batch.course
          const teacher = batch.teacher.full_name
          const batchDate = dayjs(item.date).format('DD-MMM ddd')
          const batchTime = dayjs(`${item.date} ${item.start_time}`).format(
            'h:mm A'
          )
          return {
            uuid: item.uuid,
            data: `${centre.name}, ${course.name}, ${teacher}, ${batchDate} ${batchTime}`,
            value: {
              schedule_uuid: item.uuid,
              date_gte: item.date
            }
          }
        }
      })
    } else {
      throw new Error('No make-up sessions found for the selected user')
    }
  } catch (error) {
    console.log('Error in fetching canclled session :- ', error)
    throw error
  }
}
