export const selectSelectedMessageTemplate = (state) =>
  state.communicationEntityReducer.bulkMessaging.selectedMessageTemplate

export const selectSelectedMessageTemplateParameters = (state) =>
  state.communicationEntityReducer.bulkMessaging.selectedMessageTemplate
    .parameters

export const selectBulkMessagesUserType = (state) =>
  state.communicationEntityReducer.bulkMessaging.userType

export const selectBulkMessagesUsers = (state) =>
  state.communicationEntityReducer.bulkMessaging.users

export const selectIsSendMessageModalOpen = (state) =>
  state.communicationEntityReducer.bulkMessaging.isSendMessageModalOpen

export const selectIsCreateMessageTemplateModalOpen = (state) =>
  state.communicationEntityReducer.bulkMessaging
    .isCreateMessageTemplateModalOpen

export const selectIsDeliveryStatsModalOpen = (state) =>
  state.communicationEntityReducer.bulkMessaging.isDeliveryStatsModalOpen

export const selectDeliveryType = (state) =>
  state.communicationEntityReducer.bulkMessaging.deliveryType

export const selectDeliveryStatsData = (state) =>
  state.communicationEntityReducer.bulkMessaging.deliveryStatsData

export const selectFieldErrors = (state) =>
  state.communicationEntityReducer.bulkMessaging.errors.fields

export const selectTargetAudienceSelectionMode = (state) =>
  state.communicationEntityReducer.bulkMessaging.targetAudienceSelectionMode

export const selectExcludedUsers = (state) =>
  state.communicationEntityReducer.bulkMessaging.excludedUsers

export const selectIncludedUsers = (state) =>
  state.communicationEntityReducer.bulkMessaging.includedUsers

export const selectUserFilters = (state) =>
  state.communicationEntityReducer.bulkMessaging.userFilters

export const selectIsBulkMessageRequestInProgress = (state) =>
  state.communicationEntityReducer.bulkMessaging.isBulkMessageRequestInProgress
