import { Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchDemoSchedule } from 'api'
import Loading from 'app/components/generic/Loading'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { updateDemoSchedulingData } from 'app/store/actions/demoScheduling'

const columns = [
  {
    name: 'date',
    label: 'Date'
  },
  {
    name: 'start_time',
    label: 'Start'
  },
  {
    name: 'end_time',
    label: 'End'
  },
  {
    name: 'location',
    label: 'Location'
  },
  {
    name: 'topic',
    label: 'Topic'
  }
]

const useStyles = makeStyles(() => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  table: {
    width: '100%',
    whiteSpace: 'nowrap',
    tableLayout: 'auto'
  },
  tableCell: {
    minWidth: 115
  },
  container: {
    overflowX: 'auto'
  },
  contentContainer: {
    marginTop: 16
  },
  buttonContainer: {
    display: 'flex',
    width: 240,
    justifyContent: 'space-between'
  },
  placeButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

const ScheduleForm = (props) => {
  const [scheduleTimings, setScheduleTimings] = React.useState({
    isLoaded: false,
    data: []
  })

  const demoSchedulingData = useSelector(
    (state) => state.demoSchedulingReducer.demoSchedulingData
  )

  const { isModalOpen, handleModalClose, selectedTimingBatch } = props

  const dispatch = useDispatch()
  const classes = useStyles()

  const fetchDemoScheduleTiming = React.useCallback(async (id) => {
    setScheduleTimings({
      isLoaded: false,
      data: []
    })
    const minDate = new Dayjs().startOf('month').format('YYYY-MM-DD')
    const maxDate = new Dayjs()
      .add(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')

    const queries = {
      date_gte: minDate,
      date_lte: maxDate,
      limit: 7,
      batch_uuid: id
    }

    try {
      const response = await fetchDemoSchedule({
        queries
      })
      setScheduleTimings({
        isLoaded: true,
        data: response.results
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  React.useEffect(() => {
    // fetch schedule for selected batch
    if (!isEmpty(selectedTimingBatch)) {
      fetchDemoScheduleTiming(selectedTimingBatch.uuid)
    }
  }, [selectedTimingBatch, fetchDemoScheduleTiming])
  const selectAll = () => {
    scheduleTimings.data.forEach((scheduleTiming, index) => {
      let value = get(scheduleTiming, 'uuid')
      dispatch(
        updateDemoSchedulingData(
          constants.CREATED,
          { batchId: selectedTimingBatch.uuid },
          scheduleTimings.data.find((item) => get(item, 'uuid') === value)
        )
      )
    })
  }
  const clearAll = () => {
    scheduleTimings.data.forEach((scheduleTiming, index) => {
      let value = get(scheduleTiming, 'uuid')
      dispatch(
        updateDemoSchedulingData(
          constants.REMOVED,
          { batchId: selectedTimingBatch.uuid },
          scheduleTimings.data.find((item) => get(item, 'uuid') === value)
        )
      )
    })
  }

  const addDemoSchedule = (event) => {
    dispatch(
      updateDemoSchedulingData(
        demoSchedulingData?.[selectedTimingBatch.uuid]?.[event.target.value]
          ? constants.REMOVED
          : constants.CREATED,
        { batchId: selectedTimingBatch.uuid },
        scheduleTimings.data.find(
          (item) => get(item, 'uuid') === event.target.value
        )
      )
    )
  }

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      modalTitle="Demo Timings"
      onModalClose={handleModalClose}
      enableSave={false}
    >
      {!isEmpty(selectedTimingBatch) ? (
        <>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.headingContainer}
          >
            <div>
              {get(selectedTimingBatch, 'course.teacher.user.first_name')}{' '}
              {get(selectedTimingBatch, 'course.teacher.user.last_name')} |{' '}
              {get(selectedTimingBatch, 'course.subject.name')}
            </div>
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.headingContainer}
          >
            <div>{get(selectedTimingBatch, 'course.standard.name')}</div>
          </Typography>
          <div className={classes.contentContainer}>
            <div className={classes.placeButtons}>
              <div className={classes.buttonContainer}>
                <Button variant="contained" color="primary" onClick={selectAll}>
                  Select All
                </Button>
                <Button variant="contained" color="primary" onClick={clearAll}>
                  Clear All
                </Button>
              </div>
            </div>
            {scheduleTimings.isLoaded ? (
              <>
                {!isEmpty(scheduleTimings.data) ? (
                  <TableContainer
                    className={classes.container}
                    component={Paper}
                  >
                    <Table stickyHeader className={classes.table} size="small">
                      <TableHead>
                        <TableRow>
                          {columns.map((column, index) => (
                            <TableCell
                              className={classes.tableCell}
                              key={index}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                          <TableCell className={classes.tableCell}>
                            Select
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {scheduleTimings.data.map((scheduleTiming, index) => (
                          <TableRow key={index}>
                            {columns.map((column, index) => (
                              <TableCell
                                className={classes.tableCell}
                                key={index}
                              >
                                {scheduleTiming[column.name] || ''}
                              </TableCell>
                            ))}
                            <TableCell className={classes.tableCell}>
                              <FormControl component="fieldset">
                                <FormControlLabel
                                  value={get(scheduleTiming, 'uuid')}
                                  control={
                                    <Checkbox
                                      checked={
                                        (
                                          get(
                                            demoSchedulingData[
                                              get(
                                                scheduleTiming,
                                                'schedule_event.batch.uuid'
                                              )
                                            ],
                                            get(scheduleTiming, 'uuid')
                                          ) || {}
                                        )?.uuid?.length > 0 || ''
                                      }
                                      color="primary"
                                    />
                                  }
                                  label={get(scheduleTiming, 'scheduleTimings')}
                                  onChange={addDemoSchedule}
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className={classes.headingContainer}>No Schedule</div>
                )}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </>
      ) : (
        []
      )}
    </ModalForm>
  )
}

ScheduleForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleTimingSelect: PropTypes.func.isRequired,
  selectedTimingBatch: PropTypes.object.isRequired
}

export default ScheduleForm
