/* eslint-disable react/prop-types */
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { fetchEnrolment } from 'api'
import History from 'app/components/generic/history/history'
import Slider from 'app/components/generic/Slider/slider'
import { dataColumns } from 'app/pages/Dashboard/ParentDashboard/config'

import * as styles from './styles'

import { NotFoundBlock } from '../../../../../components/NotFoundBlock'

export const Children = (props) => {
  const { selectedParentDetails, childrenData, enrolmentData } = props

  const getEnrolmentData = (uuid) => {
    const enrolments = enrolmentData?.data?.filter(
      (el) => el?.student?.user?.uuid === uuid
    )
    return enrolments
  }

  return (
    <styles.Body>
      <styles.ColumnFlexGap>
        {!isEmpty(childrenData) ? (
          childrenData?.map((block, index) => {
            const enrolmentData = getEnrolmentData(block?.user?.uuid)
            return (
              <Slider
                width="50%"
                key={'SliderChild' + index}
                placeholder={
                  <styles.ColumnFlexStart key={'Child' + index}>
                    <styles.RowFlexSpace>
                      <styles.ColumnFlexStart>
                        <styles.Heading>{block.user?.full_name}</styles.Heading>
                        <styles.RowFlexStart>
                          {block.user?.date_of_birth ? (
                            <styles.Content subheading type="medium">
                              {`Age ${dayjs().diff(
                                block.user?.date_of_birth,
                                'year'
                              )}`}
                            </styles.Content>
                          ) : null}
                          {block.user?.date_of_birth && block.standard?.name ? (
                            <styles.Content subheading type="light">
                              {`|`}
                            </styles.Content>
                          ) : null}
                          {block.standard?.name ? (
                            <styles.Content subheading type="medium">
                              {`${block.standard?.name}`}
                            </styles.Content>
                          ) : null}
                          {block.school?.name && block.standard?.name ? (
                            <styles.Content subheading type="light">
                              {`|`}
                            </styles.Content>
                          ) : null}
                          {block.school?.name ? (
                            <styles.Content subheading type="medium">
                              {block.school?.name}
                            </styles.Content>
                          ) : null}
                        </styles.RowFlexStart>
                      </styles.ColumnFlexStart>
                      <styles.ColumnFlexStart>
                        <span className="fc-icon fc-icon-chevron-right"></span>
                      </styles.ColumnFlexStart>
                    </styles.RowFlexSpace>
                    {/* <Divider variant="fullWidth" /> */}
                  </styles.ColumnFlexStart>
                }
                blockType={'children'}
                studentId={block?.user?.uuid}
                childComponent={
                  <History
                    type={dataColumns['children']?.type}
                    heading={dataColumns['children']?.heading}
                    userInfo={{
                      ...block?.user,
                      parent: selectedParentDetails.uuid
                    }}
                    data={enrolmentData}
                    tableHeadings={dataColumns['children']?.tableHeadings}
                    accessors={dataColumns['children']?.accessors}
                    readOnly={true}
                    editableDetails={false}
                    addDetails={false}
                    headerData={true}
                  />
                }
              />
            )
          })
        ) : (
          <NotFoundBlock message="Oh no! No children added." />
        )}
      </styles.ColumnFlexGap>
    </styles.Body>
  )
}
