import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

export const PastTransactionsTable = (props) => {
  const { data } = props

  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}
        >
          <TableRow>
            <TableCell>Transaction type</TableCell>
            <TableCell>Date of transaction</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Coins</TableCell>
            <TableCell>Closing balance</TableCell>
            <TableCell>Course</TableCell>
            <TableCell>Kid</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data)
            ? data.map(
                (transaction, index) =>
                  transaction && (
                    <TableRow key={index}>
                      <TableCell>{transaction?.status_icon}</TableCell>
                      <TableCell>{transaction?.transaction_date}</TableCell>
                      <TableCell>{transaction?.type}</TableCell>
                      <TableCell>{transaction?.coins}</TableCell>
                      <TableCell>{transaction?.value}</TableCell>
                      <TableCell>{transaction?.course}</TableCell>
                      <TableCell>{transaction?.kid}</TableCell>
                      <TableCell>{transaction?.notes}</TableCell>
                    </TableRow>
                  )
              )
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PastTransactionsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
