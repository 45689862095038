import styled, { css } from 'styled-components'

export const Page = styled.div`
  min-height: 100vh;
  background-color: var(--oh-light-orange);
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 2vh 0vw 4vh 0vw;
  margin: 0 2.286vw;
`

export const RightHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`

export const SubjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 4vh;
  width: 100%;
`

export const DropdownContainer = styled.div`
  width: 18.5vw;
  margin-right: 1vw;
`

export const TableContainer = styled.div`
  width: 95%;
  background-color: #fff;
  margin: 0 2.286vw;
  padding-top: 2vh;
`
export const TableSmallContainer = styled.div`
  width: max-content;
  background-color: #fff;
  // margin: 0 2.286vw;
  padding-top: 2vh;
`

export const LoadingViewText = styled.span`
  font-size: 2.49vmin;
  font-family: AvenirDemi;
  margin-bottom: 40px;
  font-weight: 600;
`

export const Loader = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

export const ErrorModalTitle = styled.span`
  font-family: AvenirDemi;
  font-weight: 600;
`

export const Body = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 2.286vw;
  grid-gap: 2.67vh 1.6vw;
  gap: 2.67vh 1.6vw;
  padding-bottom: 2.67vh;
  transition: all 2s ease-in-out;
  -webkit-animation: fadeinout 2s ease-in-out forwards;
  animation: fadeinout 2s ease-in-out forwards;

  ${(props) =>
    props.nomargin &&
    css`
      margin: unset;
    `}

  @-webkit-keyframes fadeinout {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }

  @keyframes fadeinout {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
`

export const BottomPage = styled.div`
  margin-top: 25vh;
`

export const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0.278vw 0.278vw 0.556vw rgb(0 0 0 / 25%);
  border-radius: 1.11vw;
  position: relative;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1.11vh 1.11vw;
  min-height: 2vh;

  background: var(--oh-orange);
  border-radius: 1.11vw 1.11vw 0px 0px;
  color: var(--white);
  width: 17.5vw;

  ${(props) =>
    props.batchTypeColor &&
    css`
      background: ${props.batchTypeColor};
    `}
`

export const Room = styled.div``

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.78vh 1.11vw;
  // height: 18vh;
  background: var(--white);
  border-radius: 0px 0px 1.11vw 1.11vw;
  width: 17.5vw;
  grid-gap: 4vh;
`

export const SubjectCardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.11vh 1.11vw;
  height: 22vh;
  background: var(--white);
  border-radius: 1.11vw;
  width: 17.5vw;
`

export const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 0.44vh;
  max-width: 15vw;
`

export const Attendance = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 0.8vh;
  width: 100%;
`

export const ChildCards = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 2vh;
  width: 100%;
`

export const Time = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.11vw;
  margin-right: 0.5vw;
`

export const Image = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 16vh;
  width: 13vw;
`

export const SubjectImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15vw;
`

export const Today = styled.span`
  font-size: 2.48vmin;
  font-family: AvenirDemi;
  font-weight: 600;
`

export const Boldheading = styled.span`
  font-size: 2.2vmin;
  font-family: AvenirDemi;
  font-weight: 600;
  text-transform: lowercase;
  color: var(--oh-red);
`

export const Mediumheading = styled.span`
  font-size: 1.7vmin;
  font-family: Avenir;
  font-weight: 600;
  text-transform: lowercase;
  white-space: nowrap;
`

export const Regularheading = styled.span`
  font-size: 1.5vmin;
  font-family: Avenir;
  font-weight: 600;
  color: var(--oh-black-tertiary);
  align-self: end;
`

export const Subtitle = styled.span`
  font-size: 1.33vmin;
  font-family: Avenir;
  font-style: italic;
  font-weight: 600;
`

export const Name = styled.span`
  font-size: 2.25vmin;
  font-family: AvenirDemi;
  font-weight: 600;
  text-transform: lowercase;
  ${(props) =>
    css`
      color: ${props.color};
    `}
`

export const Subheading = styled.span`
  font-size: 1.56vmin;
  font-family: Avenir;
`

export const Boldtext = styled.span`
  font-size: 1.5vmin;
  font-family: AvenirDemi;
  font-weight: 600;
  white-space: nowrap;
`

export const Container = styled.div`
  width: 100%;
  min-width: 200px;

  input.custom-date-picker-input {
    height: 40px;
    font-size: 16px;
    padding: 18.5px 14px;
    background: transparent;
    border-color: var(--oh-black-tertiary);

    &:hover {
      border-color: #212121;
    }

    &:focus-visible {
      outline-color: var(--oh-orange);
    }
  }
`
