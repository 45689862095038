import React from 'react'

import { Loading } from 'app/components/generic/LottieComponents'
import ModalForm from 'app/components/ModalForm/ModalForm.js'
import { navigationPaths } from 'app/config.js'
import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock/index.js'

import * as styles from './styles.js'

import PageTitleDescription from '../../../components/generic/PageTitleDescription/PageTitleDescription.js'
import TableTitleModal from '../../../components/generic/TableTitleModal/TableTitleModal.js'
import { backIcon } from '../constants'
import { getJourneyReportsData, getLevel, getMilestone } from '../helpers.js'
import JourneyCard from '../JourneyCard/index.js'

const ChildCategoryJourney = () => {
  const [journeyReportsData, setJourneyReportsData] = React.useState([])
  const [isProgressModalOpen, setIsProgressModalOpen] = React.useState(false)
  const [progressModalData, setProgressModalData] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    ;(async () => {
      const journey_uuid = new URLSearchParams(window.location.search).get(
        'journey'
      )
      const data = await getJourneyReportsData(journey_uuid)
      setJourneyReportsData(data)
      setLoading(false)
    })()
  }, [])

  const headerTitle = journeyReportsData[0]?.journey?.student?.user?.full_name
  const headerSubheading =
    journeyReportsData[0]?.journey?.subject?.name +
    ' | ' +
    getLevel(journeyReportsData[0]?.journey?.current_level) +
    ' | ' +
    getMilestone(journeyReportsData[0]?.journey?.current_milestone)
  const iconNavigationUrl = `${navigationPaths.journeySelection}?parent=${journeyReportsData[0]?.journey?.student?.parent?.user_id}`
  const abilitesTableTitle =
    progressModalData[0]?.course +
    ' | ' +
    progressModalData[0]?.level +
    ' | ' +
    progressModalData[0]?.milestone
  const abilitiesTableSubtitle = `Progress for the month of ${progressModalData[0]?.month}, ${progressModalData[0]?.year}`
  const columns = [
    {
      name: 'description',
      label: 'Abilities'
    },
    {
      name: 'old_status',
      label: 'Previous Status'
    },
    {
      name: 'new_status',
      label: 'New Status'
    }
  ]

  return loading ? (
    <styles.LoadingMain>
      <Loading />
    </styles.LoadingMain>
  ) : journeyReportsData?.length > 0 ? (
    <>
      <styles.Main>
        <PageTitleDescription
          title={headerTitle}
          subheading={headerSubheading}
          iconUrl={backIcon}
          iconNavigationUrl={iconNavigationUrl}
        />
        {journeyReportsData.map((item, index, array) => (
          <JourneyCard
            data={item}
            setIsProgressModalOpen={setIsProgressModalOpen}
            setProgressModalData={setProgressModalData}
            smallCard={true}
          />
        ))}
      </styles.Main>

      <ModalForm
        isModalOpen={isProgressModalOpen}
        onModalClose={() => setIsProgressModalOpen(false)}
        enableSave={false}
        showCloseIcon>
        <styles.TableTitle>{abilitesTableTitle}</styles.TableTitle>
        <styles.TableSubtitle>{abilitiesTableSubtitle}</styles.TableSubtitle>
        <TableTitleModal columns={columns} data={progressModalData} />
      </ModalForm>
    </>
  ) : (
    <styles.Main>
      <styles.Block>
        <NotFoundBlock message="Oh no! No reports found yet." />
      </styles.Block>
    </styles.Main>
  )
}

export default ChildCategoryJourney
