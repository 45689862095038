import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import React, { useEffect } from 'react'

import ModalForm from 'app/components/ModalForm'
import { getRatingData } from 'app/pages/Dashboard/ParentDashboard/helpers'
import StarIcon from 'assets/images/star.svg'

import { RatingTable } from './RatingTable'

import * as styles from '../../SummaryBlock/components/ClassroomExperience/styles'

dayjs.extend(isSameOrAfter)

const RatingSessions = (props) => {
  const { isModalOpen, closeModal, data } = props
  const [sessionsData, setSessionsData] = React.useState('')
  const [headerData, setHeaderData] = React.useState([])
  const [titleHeaderData, setTitleHeaderData] = React.useState([])

  const subtitle = (
    <styles.ColumnFlexStart>
      <styles.Content
        subheading
        type="dark"
      >{`${data?.kid} - ${data?.course_name} by ${data?.teacher}`}</styles.Content>
    </styles.ColumnFlexStart>
  )

  const getSessionData = React.useCallback(() => {
    const getCount = (val) => {
      return data.sessions.filter((el) => el.rating === val).length
    }

    const getHeading = (val) => {
      return (
        <styles.RowFlex>
          <styles.Icon src={StarIcon} alt="star" />
          <styles.Content subheading type="dark">
            {val}
          </styles.Content>
        </styles.RowFlex>
      )
    }

    const blocks = [
      {
        heading: getHeading('5'),
        value: getCount(5)
      },
      {
        heading: getHeading('4'),
        value: getCount(4)
      },
      {
        heading: getHeading('3'),
        value: getCount(3)
      },
      {
        heading: getHeading('2'),
        value: getCount(2)
      },
      {
        heading: getHeading('1'),
        value: getCount(1)
      }
    ]
    setHeaderData(blocks)
    setSessionsData(data.sessions)
  }, [data?.uuid])

  useEffect(() => {
    getSessionData()
  }, [data])

  return (
    <>
      <ModalForm
        containerStyles={{ borderRadius: 10 }}
        className="border"
        isModalOpen={isModalOpen}
        onModalClose={closeModal}
        enableSave={false}
        modalTitle="Sessions ratings"
        modalSubtitle={subtitle}
        showCloseIcon
        headerData={headerData}
        titleHeaderData={titleHeaderData}
      >
        <RatingTable data={sessionsData} />
      </ModalForm>
    </>
  )
}

export default RatingSessions
