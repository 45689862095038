import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => props.isCheckoutMode && `margin-top: 10px;`}

  .MuiButton-contained {
    border-radius: 2em;
    margin: 0 0.5em;
    padding: 0.5em 2em;
    font-weight: bold;
    text-transform: capitalize;
  }
`

export const Wrapper = styled.div`
  color: #ef5c34;
  width: 34rem;
  margin-left: 1rem;
  font-weight: bold;
  line-height: 1.5;

  p {
    margin: unset;
  }
`

export const ButtonWapper = styled.div`
  padding-top: 15px;
`
