import * as actionTypes from '../types/WebSocket'

export const updateObject = (oldObject, newValues) => {
  return { ...oldObject, ...newValues };
};

const initialState = {
  chatMsg: null,
  chatMessages: [],
  ttmMsg: null,
  roomStateMsg: null,
  socket: null,
  msg: null,
  audioMsg: null,
  noteMsg: [],
  isIframeShared: false,
  iframeShareUrl: { teacher_url: '', student_url: '' },
  userId: '',
  whiteboardUrl: ''
}

const receiveAllMessage = (state, action) => {
  return updateObject(state, {
    msg: action.msg
  })
}

const setSocket = (state, action) => {
  return updateObject(state, {
    socket: action.socket
  })
}

const resetState = () => initialState

const resetGeneralMessages = (state) => {
  return updateObject(state, {
    msg: null
  })
}

const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALL_MSG:
      return receiveAllMessage(state, action)
    case actionTypes.SET_SOCKET:
      return setSocket(state, action)
    case actionTypes.RESET_GENERAL_MESSAGES:
      return resetGeneralMessages(state)
    case actionTypes.RESET_WEB_SOCKET_STATE:
      return resetState()
    default:
      return state
  }
}

export { websocketReducer }
