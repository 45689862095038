/* eslint-disable react/prop-types */
import { isEmpty, isNaN } from 'lodash'
import React from 'react'

import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const StudentAttendance = (props) => {
  const { studentAttendanceData } = props
  const attendancePercentage =
    studentAttendanceData?.studentsExpectedCount > 0
      ? Math.round(
          (100 * studentAttendanceData?.studentsAttendedCount) /
            studentAttendanceData?.studentsExpectedCount
        )
      : 0

  return !isEmpty(studentAttendanceData) ? (
    <styles.Body>
      <styles.RowFlexCenter>
        <TitleDescription
          heading="attendance percentage"
          description={
            !isNaN(attendancePercentage) ? `${attendancePercentage}%` : '0%'
          }
          size="big"
          align="center"
        />
      </styles.RowFlexCenter>
    </styles.Body>
  ) : (
    <NotFoundBlock message="Oh no! User has no student data." />
  )
}
