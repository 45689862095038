import styled from 'styled-components'

export const ItemContainer = styled.div`
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background: var(--oh-light-cream);
`

export const CourseContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const CourseIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 8px;
`

export const CourseDetails = styled.div`
  flex-grow: 1;
`

export const CourseName = styled.div`
  font-family: AvenirDemi;
  font-size: 16px;
`

export const CourseType = styled.div`
  font-size: 14px;
  color: #666;
`

export const ActionButtons = styled.div`
  display: flex;
`

export const IconButtonLeft = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border: 1px solid #36383c;
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
`
export const IconButtonRight = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
`

export const InfoGrid = styled.div`
  background: #ffffff;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
`

export const InfoItem = styled.div``

export const InfoLabel = styled.div`
  font-size: 12px;
  color: #000;
  font-family: AvenirDemi;
`

export const InfoValue = styled.div`
  font-size: 14px;
`

export const PriceContainer = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PriceLabel = styled.div`
  font-size: 14px;
  font-family: AvenirDemi;
`

export const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  border: 1px solid var(--oh-orange);
  border-radius: 24px;
  padding: 0px 6px;
  font-family: AvenirDemi;
  color: var(--oh-orange);
`

export const CoinImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  margin-top: -2px;
`
