import React from 'react'
import { useSelector } from 'react-redux'

import { createSuperproviderDispatch } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import { fetchSuperproviderSummaryData } from 'app/store/actions/paymentEntity'

import { columns } from './config'
import SuperproviderDispatchForm from './SuperproviderDispatchForm'

const Superprovider = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [editIndex, setEditIndex] = React.useState(null)
  const [formData, setFormData] = React.useState({
    count: '',
    value: '',
    notes: '',
    mode: '',
    utr_id: ''
  })

  const superproviderSummaryData = useSelector(
    (state) => state.paymentEntityReducer.superproviderSummaryData
  )

  const openModal = React.useCallback((dataIndex) => {
    setIsModalOpen(true)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
    handleFormData({})
  }

  const onSaveClick = async (newData) => {
    try {
      await createSuperproviderDispatch({
        action: 'confirm',
        notes: newData.notes,
        mode: newData.mode,
        utr_id: newData.utr_id,
        superprovider_uuid: superproviderSummaryData.data[editIndex].user.uuid
      })

      eventEmitter.emit(constants.CLOSE_MODAL_FORM)
      eventAlert('Dispatched Successfully', constants.SUCCESS)
      closeModal()
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const handleFormData = React.useCallback((value) => {
    setFormData((state) => ({
      ...state,
      ...value
    }))
  }, [])

  const handleDispatch = React.useCallback(
    async (e, dataIndex) => {
      e.preventDefault()

      try {
        // first initiate api with process false
        const response = await createSuperproviderDispatch({
          superprovider_uuid:
            superproviderSummaryData.data[dataIndex].user.uuid,
          process: false
        })

        handleFormData(response)
        openModal(dataIndex)
      } catch (error) {
        console.log(error)
        eventAlert(error.message, constants.ERROR)
      }
    },
    [superproviderSummaryData, openModal, handleFormData]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        options: {
          customBodyRenderLite: (dataIndex) => {
            if (!superproviderSummaryData.data[dataIndex].holding_amount) {
              return null
            }

            return (
              <Button onClick={(e) => handleDispatch(e, dataIndex)}>
                Dispatch
              </Button>
            )
          }
        }
      }
    ]
  }, [handleDispatch, superproviderSummaryData])

  return (
    <>
      <BaseEntity
        entity={constants.SUPERPROVIDER_SUMMARY}
        label={constants.SUPERPROVIDER_SUMMARY_LABEL}
        data={superproviderSummaryData}
        columns={modifiedColumns}
        fetchData={fetchSuperproviderSummaryData}
        readOnly
      />
      <SuperproviderDispatchForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        formData={formData}
        handleFormData={handleFormData}
      />
    </>
  )
}

export default Superprovider
