import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Loading, NoData } from 'app/components/generic/LottieComponents'

import BatchTiming from './components/BatchTiming'
import SubjectResult from './components/SubjectResult'

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}))

const Content = (props) => {
  const [currentMenu, setCurrentMenu] = React.useState('subjectListing') // subjectListing
  const [selectedSubject, setSelectedSubject] = React.useState([])

  const classes = useStyles()

  const { batch } = props

  const handleOnSubjectClick = React.useCallback((subjectName) => {
    // go to batch timings
    setCurrentMenu('batchTiming')
    setSelectedSubject(subjectName)
  }, [])

  const handleBatchListingClose = React.useCallback((e) => {
    e.preventDefault()
    setCurrentMenu('subjectListing')
  }, [])

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div>
        {currentMenu === 'subjectListing' ? (
          <>
            {batch.isLoading ? (
              <Loading />
            ) : (
              <>
                {batch.isLoaded && isEmpty(batch.data) ? (
                  <NoData />
                ) : (
                  <SubjectResult
                    batch={batch.data}
                    onSubjectClick={handleOnSubjectClick}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            {currentMenu === 'batchTiming' ? (
              <BatchTiming
                selectedSubject={selectedSubject}
                batch={batch.data}
                handleBatchListingClose={handleBatchListingClose}
              />
            ) : null}
          </>
        )}
      </div>
    </main>
  )
}

Content.propTypes = {
  batch: PropTypes.object.isRequired
}

export default Content
