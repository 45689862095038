import { isEmpty } from 'lodash'

import constants from 'app/constants'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl, showToast } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const boardRequestStructure = (data) => ({
  name: data.name
})

const fetchBoard = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.board, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    // showToast('Oops, Error in fetching board', constants.ERROR)

    console.log('error in fetching board :- ', error.message)
    throw error
  }
}

const createBoard = async (data) => {
  try {
    const response = await post(apiEndpoints.board, boardRequestStructure(data))

    return response.data
  } catch (error) {
    console.log('error in creating board :- ', error.message)
    throw error
  }
}

const updateBoard = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.board}/${data.uuid}/`,
      boardRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in updating board :- ', error.message)
    throw error
  }
}

const deleteBoard = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.board}/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting board :- ', error.message)
    throw error
  }
}

export { fetchBoard, createBoard, updateBoard, deleteBoard }
