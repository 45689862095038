import dayjs from 'dayjs'
import React from 'react'

import {
  statusMap,
  commonAttributes,
  navigationPaths,
  studentSourceTypes,
  tableColumnDefaultOption
} from 'app/config'
import { sanitize } from 'app/utils'
import { validateName, validateNotEmpty, validateFee } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid,
    options: {
      ...commonAttributes.uuid.options,
      display: false
    }
  },
  {
    name: 'batch.uuid',
    formDataKey: 'batch',
    label: 'Batch',
    editable: false,
    resultStructure: {
      keyName: 'batch',
      keyValue: 'uuid'
    },
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('batch'),
    options: {
      display: false
    }
  },
  {
    name: 'student.user.uuid',
    label: 'Student uuid',
    editable: false,
    options: {
      display: false
    }
  },
  {
    name: 'student.user.first_name',
    label: 'Student First Name',
    disabledInFormOnEdit: true,
    validate: validateName,
    options: {
      display: false
    }
  },
  {
    name: 'student.user.last_name',
    label: 'Student Last Name',
    disabledInFormOnEdit: true,
    validate: validateName,
    options: {
      display: false
    }
  },
  {
    name: 'student.user',
    label: 'Student Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => (
        <span>{`${(value && value.first_name) || ''} ${
          (value && value.last_name) || ''
        }`}</span>
      ),
      filterKey: 'student_name'
    }
  },
  {
    name: 'student.parent.user',
    label: 'Parent Name',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_name',
      customBodyRender: (value) =>
        value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}
            >{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null,
      ...tableColumnDefaultOption.options
    }
  },
  {
    name: 'student.parent.user.phone_number',
    label: 'Parent Number',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_phone_number',
      ...tableColumnDefaultOption.options
    }
  },
  {
    name: 'batch.teacher.full_name',
    label: 'Teacher Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    },
    disabledInForm: true
  },
  {
    ...commonAttributes.subject,
    name: 'batch.course.subjects',
    disabledInForm: true
  },
  {
    ...commonAttributes.standard,
    name: 'batch.course.standards',
    disabledInForm: true
  },
  {
    ...commonAttributes.boards,
    name: 'batch.course.boards',
    disabledInForm: true
  },
  {
    ...commonAttributes.centre,
    name: 'batch.centre.name',
    label: 'Centre',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre'
    }
  },
  {
    name: 'source',
    label: 'Booking Source',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: statusMap,
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'terminated_by',
    label: 'Cancelled By',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        if (!value) return <div>NA</div>
        return <div>{value?.full_name}</div>
      }
    }
  },
  {
    name: 'notes',
    label: 'Cancellation Notes',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      }
    }
  },
  {
    name: 'termination_source',
    label: 'Cancellation Source',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  }
]
