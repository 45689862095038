import { tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    name: 'student.name',
    label: 'Student Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_name'
    }
  },
  {
    name: 'student.phone_number',
    label: 'Student Number',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_phone_number'
    }
  },
  {
    name: 'teacher',
    label: 'Teacher Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    }
  },
  {
    name: 'mock_subject',
    label: 'mock subject',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'mock_subject'
    }
  },
  {
    name: 'created',
    label: 'created at',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'created'
    }
  },
  {
    name: 'author',
    label: 'created by',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'author'
    }
  }
]
