import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    display: 'inline-block',
    margin: theme.spacing(1),
    cursor: 'pointer',
    minHeight: 275
  },
  subjectContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  teachersNamecontainer: {
    marginTop: 16
  }
}))

const SubjectCard = (props) => {
  const classes = useStyles()
  const { subjectName, subjectDetails, onSubjectClick } = props

  const teachersName = React.useMemo(() => {
    const teacherNames = []

    subjectDetails.forEach((subjectDetail) => {
      const teacher = subjectDetail.course.teacher.user
      if (teacherNames.findIndex((item) => item.uuid === teacher.uuid) < 0) {
        teacherNames.push(teacher)
      }
    })

    return teacherNames.map((teacher, index) => {
      return (
        <Typography key={index} variant="subtitle2" gutterBottom>
          {teacher.first_name} {teacher.last_name}
        </Typography>
      )
    })
  }, [subjectDetails])

  return (
    <Card className={classes.root} onClick={() => onSubjectClick(subjectName)}>
      <CardContent>
        <div className={classes.subjectContainer}>
          <Typography variant="h6" gutterBottom>
            {subjectName}
          </Typography>
        </div>
        <Divider />
        <div className={classes.teachersNamecontainer}>{teachersName}</div>
      </CardContent>
    </Card>
  )
}

SubjectCard.propTypes = {
  subjectName: PropTypes.string.isRequired,
  subjectDetails: PropTypes.array.isRequired,
  onSubjectClick: PropTypes.func.isRequired
}

export default SubjectCard
