import dayjs from 'dayjs'
import _ from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api/common'
import { tableColumnDefaultOption, commonAttributes } from 'app/config'
import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    name: 'uuid',
    label: 'Id',
    disabledInFormOnCreate: true,
    editable: false,
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      display: false
    }
  },
  {
    name: 'start_date',
    label: 'from date',
    widget: 'datepicker',
    widgetFilter: true,
    minDate: (data) => {
      return dayjs().add(1, 'day').format('YYYY-MM-DD')
    },
    validate: validateNotEmpty,
    options: {
      ...commonAttributes.date.options,
      filterKey: 'start_date',
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    name: 'centre',
    label: 'Centre',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
              uuid: item.uuid,
              name: item.name
            }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'centre',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.centre
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre',
      customBodyRender: (value) => (value ? <span>{value?.name}</span> : null)
    }
  },
  {
    name: 'subjects',
    label: 'Subjects',
    formDataKey: 'subjects',
    widget: 'autocomplete',
    widgetFilter: true,
    validate: validateNotEmpty,
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      }

      return data.results.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'subjects',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.subject}?limit=500&offset=0`
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'subject',
      customBodyRender: (value) => {
        return (
          <span>
            {Array.isArray(value) && value.map((item) => item.name).join(', ')}
          </span>
        )
      }
    }
  }
]
