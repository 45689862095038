/* eslint-disable react/prop-types */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _, { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import { getRatingData } from 'app/pages/Dashboard/ParentDashboard/helpers'
import NotFound from 'assets/images/not_found.svg'
import StarIcon from 'assets/images/star.svg'

import * as styles from './styles'

import { NotFoundBlock } from '../../../../../components/NotFoundBlock'
import { TitleDescription } from '../../../../../components/TitleDescription'

dayjs.extend(customParseFormat)

export const ClassroomExperience = (props) => {
  const { classroomExperienceData } = props
  const [attended, setAttended] = useState(0)
  const [rated, setRated] = useState(0)
  const [overallRating, setOverallRating] = useState(0)
  const [percentage, setPercentage] = useState(0)
  const [hoursSpent, setHoursSpent] = useState(0)

  useEffect(() => {
    if (!isEmpty(classroomExperienceData)) {
      const result = getRatingData(classroomExperienceData)
      setHoursSpent(result.hours)
      setOverallRating(result.overallRating)
      setAttended(result.attended)
      setRated(result.rated)
      setPercentage(result.percentage)
    }
  }, [classroomExperienceData])

  return !isEmpty(classroomExperienceData) ? (
    <styles.Body>
      <styles.ColumnFlexCenter>
        <TitleDescription
          heading="overall rating"
          description={
            <styles.RowFlexCenter>
              <styles.Icon src={StarIcon} alt="star" />
              <styles.Content heading type="dark">
                {overallRating}
              </styles.Content>
            </styles.RowFlexCenter>
          }
          size="medium"
          align="center"
        />
      </styles.ColumnFlexCenter>
      <styles.ColumnFlexCenter>
        <TitleDescription
          heading="no. of sessions rated "
          description={rated}
          align="center"
          size="medium"
        />
      </styles.ColumnFlexCenter>
      {/* <styles.RowFlexSpace>
        <styles.ColumnFlexStart>
          {/* <TitleDescription
            heading="sessions attended"
            description={attended}
          /> */}
      {/* <TitleDescription
            heading="hours spent /week"
            description={hoursSpent}
          /> */}
      {/* <TitleDescription
            heading="% sessions rated"
            description={
              percentage
              // Math.round(((rated / attended) * 100 + Number.EPSILON) * 100) /
              // 100
            }
          /> */}
      {/* </styles.ColumnFlexStart> */}
      {/* </styles.RowFlexSpace> */}
    </styles.Body>
  ) : (
    <NotFoundBlock message="Oh no! User has not opted for any classes." />
  )
}
