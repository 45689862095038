import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchPaidData } from 'app/store/actions/paymentEntity'

import { columns } from './config'

const Paid = () => {
  const paidData = useSelector((state) => state.paymentEntityReducer.paidData)

  const CustomToolbarElement = (
    <div>Total : ₹ {paidData?.paid ?? 0}</div>
  )

  return (
    <BaseEntity
      entity={constants.PAID}
      label={constants.PAID_LABEL}
      fetchData={fetchPaidData}
      data={paidData}
      columns={columns}
      CustomToolbarElement={CustomToolbarElement}
      readOnly
    />
  )
}

export default Paid
