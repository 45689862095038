import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ isRightAligned }) => (isRightAligned ? 'row-reverse' : 'row')};
  margin-right: ${({ isRightAligned }) => (isRightAligned ? '0rem' : '1rem')};
  align-items: center;
`

export const Icon = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  margin-left: ${({ isRightAligned }) => (isRightAligned ? '0.25rem' : '0rem')};
`