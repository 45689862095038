import { takeLatest } from 'redux-saga/effects'

import { fetchOrders } from 'api/orders'
import constants from 'app/constants'
import * as types from 'app/store/types/ordersEntity'

import { handleFetch } from './saga.helper'

import {
  fetchOrdersData,
  fetchOrdersDataSuccess
} from '../actions/ordersEntity'

const mapping = {
  [constants.ORDERS]: {
    api: {
      fetch: fetchOrders
    },
    action: {
      fetch: fetchOrdersData,
      fetchSuccess: fetchOrdersDataSuccess
    }
  }
}

export function* _fetchOrdersEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* watchFetchOrdersEntity() {
  yield takeLatest([types.FETCH_ORDERS_DATA], _fetchOrdersEntity)
}
