import {
  getValueFromLocalStorage,
  isEmpty,
  setValueInLocalStorage
} from '@openhouse-technologies/utils'
import React, { useState, useEffect } from 'react'
import { useCart } from 'react-use-cart'

import { handleBookEnrolment } from 'api'
import ModalForm from 'app/components/ModalForm'
import { navigationPaths } from 'app/config'
import { alertDialog } from 'app/helpers'
import { BOOKING_PAYMENT_TYPES } from 'app/pages/Class/Inventory/constants'
import MasterForm from 'app/pages/User/Parent/MasterForm'

import BookWithCoins from './components/BookWithCoins'
import BookWithRupees from './components/BookWithRupees'
import * as Styles from './styles'
const CheckoutModal = ({
  isModalOpen,
  onModalClose,
  checkoutType,
  paymentOptions,
  coinSummary
}) => {
  const activeOption = paymentOptions.find(
    (option) => option.payment_type === checkoutType
  )
  const { cartTotal, emptyCart, items: cartItems } = useCart()

  const selectedParentDetails = getValueFromLocalStorage('CART_PARENT')
  const parent = selectedParentDetails?.uuid
  const coinBalance = coinSummary?.remaining_coins
  const [formData, setFormData] = useState({
    user: selectedParentDetails,
    uuid: parent,
    cart_type: 'booking',
    payment_summary: activeOption?.summary,
    coin_summary: {},
    reason: '',
    coins: 0,
    use_referral_code: false,
    is_policy_acknowledged_alfred: false,
    reason_for_using_alfred: '',
    show_reason_field: true
  })
  useEffect(() => {
    setFormData({
      user: selectedParentDetails,
      uuid: selectedParentDetails?.uuid,
      cart_type: 'topup_booking',
      payment_summary: activeOption?.summary,
      coin_summary: coinSummary,
      reason: '',
      coins: 0,
      use_referral_code: false,
      is_policy_acknowledged_alfred: false,
      show_reason_field: showReasonField
    })
  }, [parent, activeOption, coinSummary, showReasonField])

  const onModalCloseLocal = () => {
    setReasonError('')
    setReasonForUsingAlfred('')
    onModalClose()
  }

  const summary = activeOption?.summary ?? {}
  const summary_with_coins = activeOption?.summary_with_coins ?? {}

  const [useCoins, setUseCoins] = useState(false)
  const [reasonForUsingAlfred, setReasonForUsingAlfred] = useState('')
  const [reasonError, setReasonError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const setReasonForUsingAlfredHandler = (val) => {
    setReasonForUsingAlfred(val)
    if (!isEmpty(val)) {
      setReasonError('')
    }
  }

  const validateReason = () => {
    if (showReasonField) {
      if (isEmpty(reasonForUsingAlfred)) {
        setReasonError('This field is required')
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (isModalOpen) {
      setUseCoins(false)
    }
  }, [isModalOpen])

  const setUseCoinsHandler = (val) => setUseCoins(val)

  const bookWithCoinsExists = paymentOptions?.some(
    (opt) => opt.payment_type === BOOKING_PAYMENT_TYPES.BOOK_WITH_COINS
  )

  const showReasonField = !cartItems?.some(
    (item) =>
      item?.batchDetails?.course?.subjects?.[0]?.progress_enabled === false
  )

  const disableUseCoins = bookWithCoinsExists || coinBalance === 0

  const activeSummary = useCoins ? summary_with_coins : summary

  const renderContent = () => {
    switch (checkoutType) {
      case BOOKING_PAYMENT_TYPES.BOOK_WITH_COINS:
        return (
          <BookWithCoins
            summary={summary}
            reasonForUsingAlfred={reasonForUsingAlfred}
            setReasonForUsingAlfredHandler={setReasonForUsingAlfredHandler}
            reasonError={reasonError}
            showReasonField={showReasonField}
          />
        )
      case BOOKING_PAYMENT_TYPES.BOOK_WITH_RUPEES:
        return (
          <BookWithRupees
            activeSummary={activeSummary}
            useCoins={useCoins}
            disableUseCoins={disableUseCoins}
            setUseCoinsHandler={setUseCoinsHandler}
            reasonForUsingAlfred={reasonForUsingAlfred}
            setReasonForUsingAlfredHandler={setReasonForUsingAlfredHandler}
            reasonError={reasonError}
            showReasonField={showReasonField}
          />
        )
      case BOOKING_PAYMENT_TYPES.RECHARGE_AND_PAY:
        // Implement Recharge and Pay UI
        return

      default:
        return <p>Invalid checkout type</p>
    }
  }

  const handleBookWithCoins = async () => {
    if (!validateReason()) {
      return
    }

    try {
      setSubmitting(true)
      const result = await handleBookEnrolment({
        summary: activeSummary,
        parent,
        cartItems,
        cartTotal,
        payment_type: checkoutType,
        useCoins,
        coinBalance,
        reason_for_using_alfred: reasonForUsingAlfred
      })
      if (result?.success) {
        emptyCart()
        setValueInLocalStorage('CART_PARENT', {})
        window.open(`${window.location.origin}${navigationPaths.orders}`)
      }
    } catch (error) {
      alertDialog({ text: error?.message, title: '', icon: 'error' })
    } finally {
      setSubmitting(false)
    }
    onModalCloseLocal()
  }

  const handleRechargeAndPay = async (newData, action) => {
    try {
      const result = await handleBookEnrolment({
        ...newData,
        cartItems,
        payment_type: checkoutType,
        summary: activeSummary
      })
      if (result?.success) {
        emptyCart()
        setValueInLocalStorage('CART_PARENT', {})
        window.open(`${window.location.origin}${navigationPaths.orders}`)
      }
    } catch (error) {
      alertDialog({ text: error?.message, title: '', icon: 'error' })
    }
    onModalCloseLocal()
  }

  const renderActions = () => (
    <Styles.ActionsContainer>
      {checkoutType === BOOKING_PAYMENT_TYPES.BOOK_WITH_COINS && (
        <Styles.ActionButton
          disabled={submitting}
          onClick={handleBookWithCoins}>
          confirm booking
        </Styles.ActionButton>
      )}
      {checkoutType === BOOKING_PAYMENT_TYPES.BOOK_WITH_RUPEES && (
        <Styles.ActionButton
          disabled={submitting}
          onClick={handleBookWithCoins}>
          generate payment link
        </Styles.ActionButton>
      )}
      {checkoutType === BOOKING_PAYMENT_TYPES.RECHARGE_AND_PAY && null}
    </Styles.ActionsContainer>
  )

  return checkoutType === BOOKING_PAYMENT_TYPES.RECHARGE_AND_PAY ? (
    <MasterForm
      isModalOpen={isModalOpen}
      onSaveClick={handleRechargeAndPay}
      closeModal={onModalCloseLocal}
      action={'topup'}
      cartType={'booking'}
      coinSummary={coinSummary}
      data={formData}
      buttonLabel="generate payment link"
      showReasonField={showReasonField}
    />
  ) : (
    <ModalForm
      isModalOpen={isModalOpen}
      enableSave={false}
      showCloseIcon
      onModalClose={onModalCloseLocal}
      modalTitle="checkout"
      containerStyles={{ padding: 12 }}>
      <Styles.Container>
        {renderContent()}
        {renderActions()}
      </Styles.Container>
    </ModalForm>
  )
}

export default CheckoutModal
