import { InputLabel, MenuItem, Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import _, { isEmpty } from 'lodash'
import React from 'react'

import { TextField } from 'app/components/generic/FormElements'
import {
  general_summer_explore_batch_list,
  special_summer_explore_batch_list
} from 'app/config'
import constants from 'app/constants'
import { eventAlert } from 'app/helpers'
import { formatTimeTo12 } from 'app/utils'
import { validateNotEmpty } from 'app/validators'

import * as styles from './styles'

import { getScheduleSlots } from '../../helper'
const ExplorationCampForm = (props) => {
  const { formData } = props
  const students = formData?.students
  const centre = formData?.centre
  const { errors, setFieldValue } = useFormikContext()
  const [batchData, setBatchData] = React.useState([])
  const [selectedStudentDetails, setSelectedStudentDetails] = React.useState({})
  const [durationValue, setDurationValue] = React.useState('')
  const [timeSlotValue, setTimeSlotValue] = React.useState('')

  React.useEffect(() => {
    if (isEmpty(students)) eventAlert('No student found', constants.ERROR)
  }, [])

  const resetDurationValue = () => {
    setFieldValue('duration', '')
    setDurationValue('')
  }

  const resetTimeSlotValue = () => {
    setFieldValue('time_slot', '')
    setTimeSlotValue('')
    setBatchData([])
  }

  const studentSelected = (e) => {
    setFieldValue('student', e.target?.value)
    setSelectedStudentDetails(e.target?.value)
    resetDurationValue()
    resetTimeSlotValue()
  }

  const handleDurationSelection = async (e) => {
    setFieldValue('duration', e.target?.value)
    setDurationValue(e.target?.value)
    resetTimeSlotValue()
    if (!isEmpty(selectedStudentDetails)) {
      const data = await getScheduleSlots(
        e.target?.value,
        selectedStudentDetails,
        centre?.uuid
      )
      if (!isEmpty(data)) {
        setBatchData(data)
      } else {
        eventAlert('No slots found', constants.ERROR)
      }
    }
  }

  const studentTimeSlot = (e) => {
    setFieldValue('time_slot', e.target?.value)
    setTimeSlotValue(e.target?.value)
  }

  const summer_explore_batch_list =
    formData?.centre?.uuid === 'e8428a50-d2cd-4234-9c4f-e27a3049709d' ||
    formData?.centre?.uuid === 'cd2da90a-340c-4402-99f6-1b8641b5dd56'
      ? special_summer_explore_batch_list
      : general_summer_explore_batch_list

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.preferred_centre) ? { error: true } : null)}
        required
      >
        <TextField
          name={'preferred_centre'}
          label={'Preferred Centre'}
          variant="outlined"
          value={centre?.name}
          required
          disabled
        />
        {!isEmpty(errors.preferred_centre) && (
          <FormHelperText>{errors.preferred_centre}</FormHelperText>
        )}
      </FormControl>

      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.student) ? { error: true } : null)}
        required
      >
        <InputLabel>Child Name</InputLabel>
        <Select
          name={'student'}
          label="Child Name"
          validate={validateNotEmpty({ isRequired: true })}
          setFieldValue={setFieldValue}
          onChange={studentSelected}
        >
          {students.map((item) => (
            <MenuItem key={item.user.uuid} value={item}>
              {`${item.user.full_name} (${`${
                item.standard?.name ? item.standard.name : ''
              }`})`}
            </MenuItem>
          ))}
        </Select>
        {!isEmpty(errors.student) && (
          <FormHelperText>{errors.student}</FormHelperText>
        )}
      </FormControl>

      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.duration) ? { error: true } : null)}
        required
      >
        <InputLabel>Summer Explore Duration</InputLabel>
        <Select
          name={'duration'}
          label="Summer Explore Duration"
          validate={validateNotEmpty({ isRequired: true })}
          setFieldValue={setFieldValue}
          onChange={handleDurationSelection}
          value={durationValue}
        >
          {summer_explore_batch_list?.map((item) => (
            <MenuItem key={item?.uuid} value={item}>
              {item?.title}
            </MenuItem>
          ))}
        </Select>
        {!isEmpty(errors.duration) && (
          <FormHelperText>{errors.duration}</FormHelperText>
        )}
      </FormControl>

      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.time_slot) ? { error: true } : null)}
        required
      >
        <InputLabel>Summer Explore Time Slot</InputLabel>
        <Select
          name={'time_slot'}
          label="Summer Explore Time Slot"
          validate={validateNotEmpty({ isRequired: true })}
          setFieldValue={setFieldValue}
          onChange={studentTimeSlot}
          value={timeSlotValue}
        >
          {batchData.map((item) => (
            <MenuItem key={item.batchId} value={item}>
              {formatTimeTo12(item?.start_time)} -{' '}
              {formatTimeTo12(item?.end_time)} | {item?.vacancy} seats left |{' '}
              {item?.teacher_name} | {item?.grade}
            </MenuItem>
          ))}
        </Select>
        {!isEmpty(errors.time_slot) && (
          <FormHelperText>{errors.time_slot}</FormHelperText>
        )}
      </FormControl>
      <styles.MessageWrapper>
        please note: upon confirming session will be auto-booked for this child
        for the selected slot within program dates.
      </styles.MessageWrapper>
    </>
  )
}

export default ExplorationCampForm
