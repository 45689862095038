import styled, { css } from 'styled-components'

export const ColumnFlexStart = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
`

export const ColumnFlexGap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;
`

export const RowFlexStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`

export const RowFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  cursor: pointer;
`

export const Heading = styled.div`
  font-family: AvenirMedium;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 8px;
  width: 20%;
`

export const Description = styled.div`
  font-family: AvenirDemi;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 8px;
  width: 20%;
`

export const Link = styled.div`
  font-family: AvenirMedium;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 8px;
  color: var(--oh-orange);
  width: 20%;
`


export const Icon = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`