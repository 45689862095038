import React from 'react'
import { useSelector } from 'react-redux'

import { dispatchUpdateIncludedUsers } from 'app/store/dispatcher'
import { selectIncludedUsers } from 'app/store/selectors'

import { SelectedUsersBase } from '../Base'

const IncludedUsers = ({ userType }) => {
  const includedUsers = useSelector(selectIncludedUsers)

  return (
    <SelectedUsersBase
      userType={userType}
      data={includedUsers}
      title="Users Included"
      dispatchUpdateUsers={dispatchUpdateIncludedUsers}
    />
  )
}

export { IncludedUsers }
