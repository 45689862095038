import { FormHelperText } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Remove } from '@material-ui/icons'
import Add from '@material-ui/icons/Add'
import { ToggleButton } from '@mui/material'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

import { ToggleButtonGroup } from 'app/components/generic/FormElements'
import { TextField } from 'app/components/generic/FormElements/TextField'
import { getValue } from 'app/pages/Dashboard/ParentDashboard/helpers'
import { formatAmount } from 'app/utils'
import { validateFee, validateNotEmpty } from 'app/validators'
import CloseIcon from 'assets/images/close.svg'

import * as styles from './styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  fieldControl: {
    width: '65%'
  }
}))

const EditFormElements = (props) => {
  const classes = useStyles()

  const { formData, setFormData, action, itemTypes } = props

  const { errors, values, setFieldValue } = useFormikContext()

  const handleAdd = () => {
    let data = { ...values }
    const misc = itemTypes?.find(
      (el) => el.type === 'miscellaneous_debit' && el.status === 'debit'
    )
    data.misc_charges.push({
      item_type: misc,
      name: '',
      value: 0,
      notes: null,
      action: 'create'
    })
    setFieldValue('misc_charges', data.misc_charges)
    setFormData(data)
  }

  const handleRemove = (index) => {
    let data = { ...values }
    const item = data.misc_charges[index]
    if (item.uuid) data.misc_charges[index].action = 'delete'
    else data.misc_charges.splice(index, 1)
    setFieldValue('misc_charges', data.misc_charges)
    setFormData(data)
  }

  useEffect(() => {
    let data = { ...values }
    const final = data.misc_charges.reduce(
      (prev, curr) =>
        getValue(
          parseFloat(prev),
          parseFloat(curr.value),
          curr.item_type?.status
        ),
      0
    )
    if (final) setFieldValue('final_amount', final)
  }, [values.misc_charges])

  const formElement = React.useMemo(() => {
    return (
      <FormControl className={classes.formControl} fullWidth>
        {action === 'edit' ? (
          <styles.ColumnFlexGap>
            <styles.RowFlexStart>
              <styles.Heading>Total Sessions amount:</styles.Heading>
              <styles.Description>
                {formatAmount(formData?.sessions_amount)}
              </styles.Description>
            </styles.RowFlexStart>
            {formData.misc_charges
              ?.filter((item) => item?.action !== 'delete')
              ?.map((val, ind) => {
                if (val?.item_type?.type === 'sessions') return
                const misc_status =
                  val.item_type?.type !== 'miscellaneous_debit' &&
                  val.item_type?.type !== 'miscellaneous_credit'

                return (
                  <styles.ColumnFlexStart key={'Misc' + ind}>
                    <styles.RowFlexStart>
                      {misc_status ? (
                        <styles.Heading>{`${val.name}:`}</styles.Heading>
                      ) : (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            name={`misc_charges[${ind}].name`}
                            label={`Misc. charges name`}
                            variant="outlined"
                            required
                            validate={validateNotEmpty({ isRequired: true })}
                          />
                        </FormControl>
                      )}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <ToggleButtonGroup
                          name={`misc_charges[${ind}].item_type.status`}
                          type="checkbox"
                          exclusive
                          color="primary"
                          disabled={misc_status}
                          sx={{ width: '20%' }}
                          validate={validateNotEmpty({ isRequired: true })}
                        >
                          <ToggleButton value="credit">
                            <Add />
                          </ToggleButton>
                          <ToggleButton value="debit">
                            <Remove />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={`${classes.formControl} ${classes.fieldControl}`}
                      >
                        <TextField
                          name={`misc_charges[${ind}].value`}
                          label={
                            formData.misc_charges[ind].item_type?.type
                              ? `${formData.misc_charges[ind].item_type?.type} amount`
                              : `Misc. charges amount`
                          }
                          variant="outlined"
                          required
                          validate={validateNotEmpty({ isRequired: true })}
                        />
                      </FormControl>
                      {!misc_status && (
                        <styles.Icon
                          src={CloseIcon}
                          alt="close"
                          onClick={() => handleRemove(ind)}
                        />
                      )}
                    </styles.RowFlexStart>

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <TextField
                        name={`misc_charges[${ind}].notes`}
                        label={
                          formData.misc_charges[ind].item_type?.type
                            ? `${formData.misc_charges[ind].item_type?.type} notes`
                            : `Misc. charges notes`
                        }
                        variant="outlined"
                        required
                        validate={validateNotEmpty({ isRequired: true })}
                      />
                    </FormControl>
                  </styles.ColumnFlexStart>
                )
              })}
            <styles.RowFlexDiv onClick={() => handleAdd()}>
              <Add color="primary" />
              <styles.Link>Add miscellaneous charge</styles.Link>
            </styles.RowFlexDiv>
            <styles.RowFlexStart>
              <styles.Heading>Final amount:</styles.Heading>
              <styles.Description>
                {formatAmount(values.final_amount)}
              </styles.Description>
            </styles.RowFlexStart>
          </styles.ColumnFlexGap>
        ) : null}
      </FormControl>
    )
  }, [action, classes, formData, values])

  return <>{formElement}</>
}

export default EditFormElements
