import styled from 'styled-components'

export const Body = styled.div`
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const LoginContainer = styled.div`
  margin-top: 50px;
  width: 70%;
  height: 70%;
  background-color: white;
  border-radius: 4px;
  padding: 5%;
  box-shadow: 0px 8px 16px #e8eff4;
  display: flex;
  box-sizing: initial;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    height: unset;
    width: 100vw;
    padding: 0;
  }
`
export const ImageContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
    padding: 10% 10% 0 10%;
  }
`
export const Image = styled.img`
  width: 90%;
  height: auto;
  @media (max-width: 500px) {
    width: 80%;
    margin-top: 60px;
  }
`
export const FormContainer = styled.div`
  width: 30%;
  padding: 0 5%;
  margin-top: 8%;
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 10% 10% 10%;
  }
`
