import styled from 'styled-components'

export const Container = styled.div`
  .table-cell-custom {
    border-bottom: unset;
    padding: 8px;
  }

  .value-cell-custom {
    color: rgba(0, 0, 0, 0.87);
    padding: 0px;
    padding-bottom: 8px;
    text-align: center;
    font-size: 1rem;
  }

  .bold {
    font-weight: 600;
  }

  .header {
    width: 100%;
  }
`
