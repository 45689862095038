import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

export const Image = styled.img`
  padding: 1rem 2rem 1rem 2rem;
  height: 18rem;
  width: 18rem;
`