import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchClubRegistrationData } from 'app/store/actions/communityEntity'

import { columns } from './config'

const Registration = () => {
  const clubRegistrationData = useSelector(
    (state) => state.communityEntityReducer.clubRegistrationData
  )

  return (
    <BaseEntity
      entity={constants.CLUB_REGISTRATION}
      label={constants.CLUB_REGISTRATION_LABEL}
      fetchData={fetchClubRegistrationData}
      data={clubRegistrationData}
      columns={columns}
      readOnly
    />
  )
}

export default Registration
