import dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api'
import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.date,
    modalEdit: true,
    widget: 'datepicker',
    name: 'interaction_date',
    label: 'interaction date',
    maxDate: dayjs().format('YYYY-MM-DD'),
    validate: validateNotEmpty,
    options: {
      ...commonAttributes.date.options
    }
  },
  {
    name: 'connected_status',
    label: 'Connected Status',
    widget: 'autocomplete',
    minCharactersToSearch: 0,
    modalEdit: true,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item ? { name: item, uuid: item } : null
      }
      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'connected_status',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CRM_INTERACTION_CONNECTED_STATUSES`
    },
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'parent_phone_number',
    label: 'Phone Number',
    disabledInForm: true,
    modalEdit: false,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_phone_number'
    }
  },
  {
    name: 'centre',
    label: 'Centre',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    disabledInForm: true,
    modalEdit: false,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
              uuid: item.uuid,
              name: item.name
            }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'centre_preference',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.centre
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_name',
      setCellProps: () => ({
        style: {
          minWidth: '240px',
          maxWidth: '240px'
        }
      }),
      customBodyRender: (value) => (value ? <span>{value?.name}</span> : null)
    }
  },
  {
    name: 'interaction_mode',
    label: 'interaction channel',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    modalEdit: true,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item ? { name: item, uuid: item } : null
      }
      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'interaction_mode',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CRM_INTERACTION_MODES`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'mode',
      customBodyRender: (value) => <span>{value ? value : 'N/A'}</span>,
      setCellProps: () => ({
        style: {
          minWidth: '240px',
          maxWidth: '240px'
        }
      })
    },
    conditionalRender: (data) => {
      return get(data, 'connected_status') === 'Connected'
    }
  },
  {
    name: 'interaction_topics_list',
    label: 'interaction topics',
    widget: 'autocomplete',
    widgetFilter: true,
    modalEdit: false,
    disabledInForm: true,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item ? { name: item, uuid: item } : null
      }
      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'interaction_topics',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CRM_INTERACTION_TOPICS`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'topic',
      customBodyRender: (value) => (
        <span>
          {Array.isArray(value)
            ? value.length > 0
              ? value.join(' | ')
              : 'N/A'
            : null}
        </span>
      )
    }
  },
  {
    name: 'created_by.full_name',
    label: 'interaction spoc',
    modalEdit: false,
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'created_by'
    }
  }
]
