import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import PropTypes from 'prop-types'
import React from 'react'

import { getGroupedResult } from 'app/utils'

import BatchTimingCard from './BatchTimingCard'
import ScheduleForm from './ScheduleForm'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    alignItems: 'center'
  }
}))

const BatchTiming = (props) => {
  const [selectedSubjectBatches, setSelectedSubjectBatches] = React.useState([])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedTimingBatch, setSelectedTimingBatch] = React.useState({})

  const { selectedSubject, batch, handleBatchListingClose } = props

  const classes = useStyles()

  const handleModalClose = React.useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleModalOpen = React.useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleTimingSelect = React.useCallback(
    (id) => {
      if (id) {
        setSelectedTimingBatch(batch.find((item) => item.uuid === id))
        setIsModalOpen(true)
      }
    },
    [batch]
  )

  React.useEffect(() => {
    setSelectedSubjectBatches(
      batch.filter(
        (eachbatch) => eachbatch.course.subject.name === selectedSubject
      )
    )
  }, [batch, selectedSubject])

  const batchesElement = React.useMemo(() => {
    const selectedSubjectBatchesGroupedByTeacher = getGroupedResult(
      selectedSubjectBatches,
      'course.teacher.user.uuid'
    )

    return Object.keys(selectedSubjectBatchesGroupedByTeacher).map(
      (item, index) => {
        const teacherDetailObject = selectedSubjectBatches.find(
          (subjectBatch) => subjectBatch.course.teacher.user.uuid === item
        )

        const teacherDetail = teacherDetailObject.course.teacher.user

        return (
          <BatchTimingCard
            key={index}
            handleModalOpen={handleModalOpen}
            teacherDetail={teacherDetail}
            selectedSubjectBatch={selectedSubjectBatchesGroupedByTeacher[item]}
            handleTimingSelect={handleTimingSelect}
            selectedTimingBatch={selectedTimingBatch}
          />
        )
      }
    )
  }, [
    selectedSubjectBatches,
    selectedTimingBatch,
    handleModalOpen,
    handleTimingSelect
  ])

  return (
    <>
      <div className={classes.container}>
        <IconButton onClick={handleBatchListingClose}>
          <KeyboardBackspaceIcon />
        </IconButton>

        <Typography
          variant="h6"
          gutterBottom
          className={classes.headingContainer}
        >
          {selectedSubject}
        </Typography>
      </div>

      {batchesElement}
      <ScheduleForm
        isModalOpen={isModalOpen}
        selectedTimingBatch={selectedTimingBatch}
        handleModalClose={handleModalClose}
        handleTimingSelect={handleTimingSelect}
      />
    </>
  )
}

BatchTiming.propTypes = {
  selectedSubject: PropTypes.string.isRequired,
  batch: PropTypes.array.isRequired,
  handleBatchListingClose: PropTypes.func.isRequired
}

export default BatchTiming
