import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  gap: 20px;
`

export const CardColumns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`
export const NoDataWrapper = styled.div`
  margin: 15px;
  font-weight: 600;
  font-size: 16px;
`
