/* eslint-disable react/prop-types */
import React from 'react'

import { fetchMomentConsumer } from 'api'
import InfiniteScroll from 'app/components/generic/InfiniteScroll/InfiniteScroll'

export const LikesTable = ({ title, momentId }) => {
  const columns = [
    {
      name: 'user.full_name',
      label: 'Name',
      options: {
        filter: false
      }
    },
    {
      name: 'user.phone_number',
      label: 'Phone',
      options: {
        filter: false
      }
    },
    {
      name: 'user_type',
      label: 'User type',
      options: {
        filter: false
      }
    }
  ]

  return (
    <InfiniteScroll
      title={title}
      columns={columns}
      fetchData={fetchMomentConsumer}
      queryParameters={{
        moment: momentId,
        liked: true
      }}
    />
  )
}
