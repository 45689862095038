import { TextField } from '@material-ui/core'
import React from 'react'

const TextArea = (props) => {
  return (
    <TextField
      fullWidth
      multiline
      rows={7}
      variant="outlined"
      autoComplete="off"
      {...props}
    />
  )
}

export { TextArea }
