import { commonAttributes } from 'app/config'

import config from '../../common/config'

export const columns = [
  {
    ...commonAttributes.date,
    name: 'paid_on',
    label: 'Payment Date'
  },
  config.parentName,
  config.parentNumber,
  { ...config.centre, name: 'parent.centre_preference.name' },
  config.plan,
  config.invoiceAmount,
  config.invoiceType,
  config.invoiceGeneratedBy,
  config.collectedBy,
  config.mode,
  config.notes
]
