import { isEmpty } from 'lodash'

import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const referralCodeRequestStructure = (data) => ({
  name: data.name,
  number: data.number,
  grade: data.grade,
  city: data.city,
  referral_code: data.referral_code,
  count: data.count || 50
})

const fetchReferralCode = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.referralCode, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching referral code')
    throw error
  }
}

const createReferralCode = async (data) => {
  try {
    const response = await post(
      apiEndpoints.referralCode,
      referralCodeRequestStructure(data)
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating referral code')
    throw error
  }
}

const updateReferralCode = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.referralCode}/${data.uuid}/`,
      referralCodeRequestStructure(data)
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating referral code')
    throw error
  }
}

const deleteReferralCode = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.referralCode}/${id}`)
      })
    )

    return ids
  } catch (error) {
    sentryLogger(error, 'error in deleting referral code')
    throw error
  }
}

export {
  fetchReferralCode,
  createReferralCode,
  updateReferralCode,
  deleteReferralCode
}
