import * as types from '../types/mockTest'

export const fetchMockPaperData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_MOCK_PAPER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchMockPaperDataSuccess = (value, entity) => ({
  type: types.FETCH_MOCK_PAPER_DATA_SUCCESS,
  value,
  entity
})

export const fetchMockTestReportCardData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_MOCK_TEST_REPORT_CARD_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchMockTestReportCardSuccess = (value, entity) => ({
  type: types.FETCH_MOCK_TEST_REPORT_CARD_DATA_SUCCESS,
  value,
  entity
})

export const updateMockPaperData = (entity, event, value) => ({
  type: types.UPDATE_MOCK_PAPER_DATA,
  entity,
  event,
  value
})

export const updateMockPaperDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_MOCK_PAPER_DATA_SUCCESS,
  entity,
  event,
  value
})

export const fetchMockPricingData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_MOCK_PRICING_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchMockPricingDataSuccess = (value, entity) => ({
  type: types.FETCH_MOCK_PRICING_DATA_SUCCESS,
  value,
  entity
})

export const updateMockPricingData = (entity, event, value) => ({
  type: types.UPDATE_MOCK_PRICING_DATA,
  entity,
  event,
  value
})

export const updateMockPricingDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_MOCK_PRICING_DATA_SUCCESS,
  entity,
  event,
  value
})
