import {
  FormControl as FC,
  FormLabel as FL,
  Typography as TG,
  Button as Btn
} from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin: 15px 8px 8px 8px;
`

export const FromControl = styled(FC)``

export const FormLabel = styled(FL)`
  margin-bottom: 3px !important;
`
export const Typography = styled(TG)`
  ${(props) =>
    css`
      ${props.$css}
    `}
`

export const Button = styled(Btn)`
  width: 129px;
  height: 39px;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 5px;
  .MuiButton-label {
    text-align: center;
    font-size: 13px;
    text-transform: capitalize;
  }
`

export const RedirectCTA = styled.div`
  display: flex;
  flex-direction: column;
`

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  ${FromControl} {
    flex-basis: 40%;
  }

  ${RedirectCTA} {
    flex-basis: 30%;
  }
`
