import React from 'react'

import CartItem from './components/CartItem'
import * as Styles from './styles'

const CartItems = ({ groupedItems, onEditHandler, onDeleteHandler }) => {
  return (
    <Styles.CartItemsContainer>
      {Object.entries(groupedItems).map(([studentId, items]) => (
        <Styles.StudentGroup key={studentId}>
          <Styles.StudentName>
            {items[0]?.userInfo?.student?.user?.full_name}
            <Styles.StudentClass>{`(${items[0]?.userInfo?.student?.standard?.name})`}</Styles.StudentClass>
          </Styles.StudentName>
          {items.map((item) => (
            <CartItem
              key={item.id}
              onEdit={onEditHandler}
              onDelete={onDeleteHandler}
              item={item}
            />
          ))}
        </Styles.StudentGroup>
      ))}
    </Styles.CartItemsContainer>
  )
}

export { CartItems }
