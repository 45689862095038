import { takeEvery, takeLatest } from 'redux-saga/effects'

import { createUser, fetchUser, updateUser } from 'api'
import constants from 'app/constants'
import {
  fetchAdminData,
  fetchAdminDataSuccess,
  fetchParentData,
  fetchParentDataSuccess,
  fetchStudentData,
  fetchStudentDataSuccess,
  fetchSuperproviderData,
  fetchSuperproviderDataSuccess,
  fetchTeacherData,
  fetchTeacherDataSuccess,
  updateUserDataSuccess
} from 'app/store/actions/userEntity'
import * as types from 'app/store/types/userEntity'

import { handleFetch, handleUpdate } from './saga.helper'

const mapping = {
  [constants.STUDENT]: {
    api: {
      fetch: fetchUser,
      create: createUser,
      update: updateUser
    },
    action: {
      fetch: fetchStudentData,
      fetchSuccess: fetchStudentDataSuccess
    }
  },
  [constants.TEACHER]: {
    api: {
      fetch: fetchUser,
      create: createUser,
      update: updateUser
    },
    action: {
      fetch: fetchTeacherData,
      fetchSuccess: fetchTeacherDataSuccess
    }
  },
  [constants.SUPERPROVIDER]: {
    api: {
      fetch: fetchUser,
      create: createUser,
      update: updateUser
    },
    action: {
      fetch: fetchSuperproviderData,
      fetchSuccess: fetchSuperproviderDataSuccess
    }
  },
  [constants.ADMIN]: {
    api: {
      fetch: fetchUser,
      create: createUser,
      update: updateUser
    },
    action: {
      fetch: fetchAdminData,
      fetchSuccess: fetchAdminDataSuccess
    }
  },
  [constants.PARENT]: {
    api: {
      fetch: fetchUser,
      create: createUser,
      update: updateUser
    },
    action: {
      fetch: fetchParentData,
      fetchSuccess: fetchParentDataSuccess
    }
  }
}

export function* _fetchUser({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updateUser({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(mappingData, entity, event, value, updateUserDataSuccess)
}

export function* watchFetchUserEntity() {
  yield takeLatest(
    [
      types.FETCH_STUDENT_DATA,
      types.FETCH_TEACHER_DATA,
      types.FETCH_SUPERPROVIDER_DATA,
      types.FETCH_ADMIN_DATA,
      types.FETCH_PARENT_DATA
    ],
    _fetchUser
  )
}

export function* watchUpdateUserEntity() {
  yield takeEvery(types.UPDATE_USER_DATA, _updateUser)
}
