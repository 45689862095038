import { takeEvery, takeLatest } from 'redux-saga/effects'

import {
  createCentre,
  createRoom,
  deleteCentre,
  deleteRoom,
  fetchCentre,
  fetchRoom,
  updateCentre,
  updateRoom
} from 'api'
import constants from 'app/constants'
import {
  fetchCentreData,
  fetchCentreDataSuccess,
  fetchRoomData,
  fetchRoomDataSuccess,
  updateSpaceDataSuccess
} from 'app/store/actions/spaceEntity'
import * as types from 'app/store/types/spaceEntity'

import { handleFetch, handleUpdate } from './saga.helper'

const mapping = {
  [constants.CENTRE]: {
    api: {
      fetch: fetchCentre,
      create: createCentre,
      update: updateCentre,
      delete: deleteCentre
    },
    action: {
      fetch: fetchCentreData,
      fetchSuccess: fetchCentreDataSuccess
    }
  },
  [constants.ROOM]: {
    api: {
      fetch: fetchRoom,
      create: createRoom,
      update: updateRoom,
      delete: deleteRoom
    },
    action: {
      fetch: fetchRoomData,
      fetchSuccess: fetchRoomDataSuccess
    }
  }
}

export function* _fetchSpaceEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updateSpaceEntity({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(mappingData, entity, event, value, updateSpaceDataSuccess)
}

export function* watchFetchSpaceEntity() {
  yield takeLatest(
    [types.FETCH_CENTRE_DATA, types.FETCH_ROOM_DATA],
    _fetchSpaceEntity
  )
}

export function* watchUpdateSpaceEntity() {
  yield takeEvery(types.UPDATE_SPACE_DATA, _updateSpaceEntity)
}
