import { takeEvery, takeLatest } from 'redux-saga/effects'

import { fetchStates, fetchCities } from 'api'
import {
  fetchStatesSuccess,
  fetchCitiesSuccess
} from 'app/store/actions/common'
import * as types from 'app/store/types/common'

import { handleFetch } from './saga.helper'

export function* _fetchGeographicalEntities({ type, queries, entity } = {}) {
  if (type === types.FETCH_STATES) {
    yield handleFetch(
      {
        api: { fetch: fetchStates },
        action: { fetchSuccess: fetchStatesSuccess }
      },
      queries,
      entity
    )
  } else if (type === types.FETCH_CITIES) {
    yield handleFetch(
      {
        api: { fetch: fetchCities },
        action: { fetchSuccess: fetchCitiesSuccess }
      },
      queries,
      entity
    )
  }
}

export function* watchFetchGeographicalEntities() {
  yield takeEvery(
    [types.FETCH_STATES, types.FETCH_CITIES],
    _fetchGeographicalEntities
  )
}
