import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import { Menu, MenuItem, TableCell } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { Link } from 'react-router-dom'

import {
  getBatchChangeEligibility,
  getPauseEligibility,
  getUnsubscribeEligibility,
  manageEnrolmentActions
} from 'api/journey'
import Button from 'app/components/generic/Button'
import { Loading } from 'app/components/generic/LottieComponents'
import { navigationPaths } from 'app/config'
import constants, { SUBSCRIPTION_STATUSES } from 'app/constants'
import { alertDialog, confirm, eventAlert, eventEmitter } from 'app/helpers'
import { getJourneyData } from 'app/pages/Dashboard/ParentDashboard/helpers'

import * as styles from './styles'

import PageTitleDescription from '../../../components/generic/PageTitleDescription'
import TableTitleModal from '../../../components/generic/TableTitleModal'
import {
  backIcon,
  manageEnrolmentOptions,
  discoveryEnrolmentOptions,
  discoveryEnrolmentReportSubmittedOptions,
  journeyFormActions
} from '../constants'
import JourneyStatusCard from '../JourneyStatusCard'
import MasterForm from '../MasterForm'

const JourneySelection = () => {
  const [loading, setLoading] = React.useState(true)
  const parent_uuid = new URLSearchParams(window.location.search).get('parent')
  const [journeyData, setJourneyData] = React.useState({})
  const students = journeyData?.students
  const status_count = journeyData?.status_count

  React.useEffect(() => {
    ;(async () => {
      const data = await getJourneyData(parent_uuid)
      setJourneyData(data)
      setLoading(false)
    })()
  }, [])

  const [formData, setFormData] = React.useState({})
  const [isFormOpen, setIsFormOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [extraFormData, setExtraFormData] = React.useState({})

  const [openDropdownId, setOpenDropdownId] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget)
    setOpenDropdownId(data.uuid)
    setFormData({ ...data })
  }

  const handleClose = () => {
    setOpenDropdownId(null)
    setAnchorEl(null)
  }

  const onActionButtonClick = async (e, action, data) => {
    handleClose()
    openForm(e, action, data)
  }

  const openForm = React.useCallback(async (e, action, data) => {
    if (e) {
      e.preventDefault()
    }
    if (action === journeyFormActions.PAUSE_ENROLMENT) {
      try {
        const eligibilityData = await getPauseEligibility({
          journeyId: data?.uuid
        })
        if (eligibilityData?.success === true) {
          setIsFormOpen(true)
          setAction(action)
          setExtraFormData({
            ...extraFormData,
            monthList: eligibilityData?.data
          })
        } else {
          alertDialog(
            'Pause not eligible',
            eligibilityData.message ||
              'You are not eligible to pause this enrollment.'
          )
        }
      } catch (error) {
        console.error('Error checking pause eligibility:', error)
        alertDialog({
          title: 'Error',
          text: error
        })
      }
    } else if (action === journeyFormActions.UNSUBSCRIBE) {
      try {
        const eligibilityData = await getUnsubscribeEligibility({
          journeyId: data?.uuid
        })

        if (eligibilityData?.success === true) {
          setIsFormOpen(true)
          setAction(action)
          setExtraFormData({
            ...extraFormData,
            month: eligibilityData?.data?.month,
            year: eligibilityData?.data?.year,
            last_session_date: eligibilityData?.data?.last_session_date
              ? dayjs(eligibilityData?.data?.last_session_date).format(
                  'YYYY-MM-DD'
                )
              : null
          })
        } else {
          alertDialog(
            'Cant pause',
            eligibilityData.message || 'Parent is not eligible to unsubscribe.'
          )
        }
      } catch (error) {
        console.error('Error checking pause eligibility:', error)
        alertDialog({
          title: 'Error',
          text: error
        })
      }
    } else if (action === journeyFormActions.CHANGE_BATCH_TIMINGS) {
      try {
        const eligibilityData = await getBatchChangeEligibility({
          journeyId: data?.uuid
        })

        if (eligibilityData?.success === true) {
          setIsFormOpen(true)
          setAction(action)
          setExtraFormData({
            ...extraFormData,
            last_session_date: eligibilityData?.data?.last_session_date,
            batches: eligibilityData?.data?.batches
          })
        } else {
          alertDialog(
            'Cant change batch',
            eligibilityData.message || 'Parent is not eligible to change batch.'
          )
        }
      } catch (error) {
        console.error('Error checking batch change eligibility:', error)
        alertDialog({
          title: 'Error',
          text: error
        })
      }
    } else {
      setIsFormOpen(true)
      setAction(action)
      setExtraFormData(data)
    }
  }, [])

  const closeForm = () => {
    setIsFormOpen(false)
  }

  const onSaveClick = async (newData, action) => {
    try {
      const formData = { ...extraFormData, ...newData }
      let res
      res = await manageEnrolmentActions(formData, action)
      if (
        res?.success ||
        res?.subscription?.uuid ||
        res?.uuid ||
        res?.user_id ||
        res?.title
      ) {
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Done', constants.SUCCESS)
        closeForm()
      }
    } catch (error) {
      console.log(error)

      eventAlert(error.message, constants.ERROR)

      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
    }
  }

  const columns = [
    {
      name: 'course_name',
      label: 'Course',
      max_width: 400,
      options: {
        customBodyRender: (data) => {
          return (
            <div style={{ padding: 16 }}>
              {data?.course_name ?? data?.subject_name ?? 'N/A'}
            </div>
          )
        }
      }
    },
    {
      name: 'current_subscription_status_display_name',
      label: 'Status',
      options: {
        customBodyRender: (data) => {
          return (
            <TableCell
              style={{
                minWidth: '8rem',
                fontFamily: 'Avenir'
              }}>
              <div style={{ flexDirection: 'column' }}>
                <div style={{ width: '100%' }}>
                  {data?.current_subscription_status_display_name || 'N/A'}
                </div>
                {data?.upcoming_subscription_status ===
                SUBSCRIPTION_STATUSES.UNSUBSCRIBED ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: 12,
                      fontFamily: 'Avenir'
                    }}>
                    unsubscribed from{' '}
                    {dayjs(data?.next_status_change_at).format('DD MMM')}
                  </div>
                ) : data?.current_subscription_status ===
                  SUBSCRIPTION_STATUSES.PAUSED ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: 12,
                      fontFamily: 'Avenir'
                    }}>
                    resuming on{' '}
                    {dayjs(data?.next_status_change_at).format('DD MMM')}
                  </div>
                ) : null}
              </div>
            </TableCell>
          )
        }
      }
    },
    {
      name: 'level',
      label: 'Level',
      max_width: '10em'
    },
    {
      name: 'milestone',
      label: 'Milestone',
      max_width: '10em'
    },
    {
      name: 'actions',
      label: 'Actions',
      max_width: '10em',
      options: {
        customBodyRender: (data) => {
          const isOpen = openDropdownId === data?.uuid
          return (
            <styles.BtnGroup>
              {data?.progress_enabled ? (
                <Link
                  to={`${navigationPaths.childCategoryJourney}?journey=${data.uuid}`}>
                  <Button>View Journey</Button>
                </Link>
              ) : null}
              {data?.discovery_enabled ? (
                <>
                  <Button
                    id={'Button' + data?.uuid}
                    aria-controls={isOpen ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isOpen ? 'true' : undefined}
                    variant="contained"
                    onClick={(e) => {
                      handleClick(e, data)
                    }}
                    disabled={
                      data?.current_subscription_status ===
                      SUBSCRIPTION_STATUSES.UNSUBSCRIBED
                    }
                    endIcon={<KeyboardArrowDownIcon />}
                    className="button-dashboard">
                    manage
                  </Button>
                  <Menu
                    id={'Menu' + data?.uuid}
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button'
                    }}
                    anchorEl={isOpen ? anchorEl : null}
                    open={isOpen}
                    onClose={handleClose}>
                    {Object.entries(data?.dropdown_options).map(
                      ([action, label]) => (
                        <MenuItem
                          onClick={(e) => {
                            onActionButtonClick(e, action, data)
                          }}
                          disableRipple
                          key={action}>
                          {label}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </>
              ) : null}
            </styles.BtnGroup>
          )
        }
      }
    }
  ]

  return loading ? (
    <Loading />
  ) : (
    <>
      <styles.Header>
        <PageTitleDescription
          title={'All Journeys'}
          subheading={`${journeyData?.parent?.name} (${journeyData?.parent?.number})`}
          iconUrl={backIcon}
          iconNavigationUrl={`${navigationPaths.parentdashboard}?parent=${parent_uuid}`}
        />
        <JourneyStatusCard data={status_count} />
      </styles.Header>
      <styles.Table>
        {students?.map((student, index) => (
          <TableTitleModal
            backgroundColor={'#F4E5D2'}
            title={student?.name}
            title_number={student?.journeys?.length}
            columns={columns}
            data={student?.journeys}
          />
        ))}
      </styles.Table>

      <MasterForm
        isModalOpen={isFormOpen}
        onSaveClick={onSaveClick}
        closeModal={closeForm}
        action={action}
        data={formData}
        extraFormData={extraFormData}
      />
    </>
  )
}

export default JourneySelection
