import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty } from 'app/validators'

import { Link } from './styles'

export const columns = [
  {
    name: 'uuid',
    formDataKey: 'uuid',
    label: 'Id',
    editable: false,
    options: {
      display: false,
      filterKey: 'uuid'
    }
  },
  {
    name: 'file.type',
    label: 'Type',
    editable: false,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'file_type'
    }
  },
  {
    name: 'file.url',
    label: 'Media',
    editable: false,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (fileUrl) => {
        return (
          <Link href={fileUrl} target="_blank">
            {fileUrl?.slice(0, 20).concat('....')}
          </Link>
        )
      }
    }
  },
  {
    ...commonAttributes.centre,
    name: 'schedule.batch.centre.name',
    label: 'Centre',
    editable: false,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_name'
    }
  },
  {
    name: 'posted_by.full_name',
    label: 'Teacher name',
    editable: false,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'posted_by_name'
    }
  },
  {
    name: 'posted_by.phone_number',
    label: 'Teacher Phone Number',
    editable: false,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'posted_by_phone_number'
    }
  },
  {
    name: 'schedule.batch.course.name',
    label: 'Category name',
    editable: false,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'course_name'
    }
  }
]

export const tableOptions = (isLoading) => ({
  enableNestedDataAccess: '.',
  filter: false,
  search: false,
  sort: false,
  print: false,
  download: false,
  delete: false,
  viewColumns: false,
  customToolbar: null,
  responsive: 'vertical',
  selectableRowsHideCheckboxes: true,
  customFooter: () => (isLoading ? <CircularProgress /> : <tfoot />),
  rowsPerPage: Infinity,
  textLabels: {
    body: {
      noMatch: !isLoading && (
        <span style={{ color: 'rgba(0, 0, 8, 0.5)', fontSize: '18px' }}>
          No data!
        </span>
      )
    }
  }
})
