import { isEmpty } from 'lodash'

import constants from 'app/constants'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl, showToast } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const centreRequestStructure = (data) => ({
  name: data.name,
  postal_address: data.postal_address,
  postal_code: data.postal_code,
  city: data.city,
  gmap_link: data.gmap_link,
  images: data.images || [],
  videos: data.videos || [],
  phone_number: data.phone_number
})

const fetchCentre = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.centre, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    // showToast('Oops, Error in fetching centre', constants.ERROR)
    console.log('error in fetching centre :- ', error.message)
    throw error
  }
}

const createCentre = async (data) => {
  try {
    const response = await post(
      apiEndpoints.centre,
      centreRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating centre :- ', error.message)
    throw error
  }
}

const updateCentre = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.centre}/${data.uuid}/`,
      centreRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in updating centre :- ', error.message)
    throw error
  }
}

const deleteCentre = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.centre}/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting centre :- ', error.message)
    throw error
  }
}

export { fetchCentre, createCentre, updateCentre, deleteCentre }
