import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

export const SessionsTable = (props) => {
  const { data } = props
  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Start time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data)
            ? data.map(
                (enrolment, index) =>
                  enrolment && (
                    <TableRow key={index}>
                      <TableCell>{enrolment?.date}</TableCell>
                      <TableCell>{enrolment?.start_time}</TableCell>
                      <TableCell>{enrolment?.end_time}</TableCell>
                      <TableCell>{enrolment?.action}</TableCell>
                    </TableRow>
                  )
              )
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

SessionsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
