import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import SubjectIcon from '@material-ui/icons/Subject'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Profile from 'app/components/Logout'
import ThemeSwitcher from 'app/components/ThemeSwitcher'
import { drawerWidth } from 'app/config'
import Logo from 'assets/images/openhouse_logo.png'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.dark
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  selected: {
    color: theme.palette.primary.main
  },
  logo: {
    height: 56
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 72
  },
  button: {
    margin: 16,
    cursor: 'pointer'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  divider: {
    backgroundColor: theme.palette.background.light
  }
}))

const menus = [
  {
    displayName: 'All Subjects',
    path: '/request'
  }
]

const SideBar = (props) => {
  const classes = useStyles()
  const [path, setPath] = React.useState('')

  const location = useLocation()

  const { onCurrentSelectionClick } = props

  React.useEffect(() => {
    setPath(location.pathname)
  }, [location])

  const activeRoute = React.useCallback(
    (route) => {
      return route === path
    },
    [path]
  )

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      <div className={`${classes.toolbar} ${classes.logoContainer}`}>
        <img src={Logo} className={classes.logo} alt="openhouse-logo" />
      </div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.drawerContainer}
      >
        {menus.map((menu, index) => {
          const selected = activeRoute(menu.path)
          return (
            <MenuItem
              key={index}
              button
              component={Link}
              to={menu.path}
              className={selected ? classes.selected : null}
              selected={selected}
            >
              <ListItemIcon>
                <SubjectIcon className={selected ? classes.selected : null} />
              </ListItemIcon>
              <ListItemText primary={menu.displayName} />
            </MenuItem>
          )
        })}
      </List>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onCurrentSelectionClick}
      >
        Current Selection
      </Button>

      <ButtonGroup
        className={classes.buttonsContainer}
        color="primary"
        variant="outlined"
        aria-label="outlined primary button group"
      >
        <Profile color="primary" />
        <ThemeSwitcher color="primary" />
      </ButtonGroup>
    </Drawer>
  )
}

SideBar.propTypes = {
  onCurrentSelectionClick: PropTypes.func.isRequired
}

export default SideBar
