import React, { useState, useEffect } from 'react'
import { useCart } from 'react-use-cart'

import { CART_NUDGE_VALUES } from 'app/pages/Class/Inventory/constants'
import CoinIcon from 'assets/images/coin.png'

import * as Styles from './styles'

const TotalAndActionsComponent = ({
  checkoutButtonClickHandler,
  ctaOptions,
  coinSummary
}) => {
  const { cartTotal } = useCart()
  const handleCtaClick = (action) => {
    checkoutButtonClickHandler(action)
  }

  if (ctaOptions.length < 1) {
    return null
  }
  const isRenderButton = cartTotal > 0

  return (
    <Styles.TotalAndActionsContainer>
      <Styles.TotalOuterContainer>
        <Styles.TotalContainer>
          <Styles.TotalText>Total</Styles.TotalText>
          <Styles.TotalAmount>
            <Styles.CoinImage src={CoinIcon} alt="Coin" />
            {cartTotal}
          </Styles.TotalAmount>
        </Styles.TotalContainer>
      </Styles.TotalOuterContainer>

      <Styles.ButtonsContainer isRenderButton={isRenderButton}>
        {isRenderButton &&
          ctaOptions?.map((button, index) => (
            <Styles.CtaButton
              key={index}
              onClick={() => handleCtaClick(button.payment_type)}
              primary={button.type === 'primary'}
              lastButton={index === ctaOptions?.length - 1}>
              {button.text}
            </Styles.CtaButton>
          ))}
      </Styles.ButtonsContainer>

      {ctaOptions?.length > 1 &&
        coinSummary?.nudges?.includes(
          CART_NUDGE_VALUES.IS_DUE_FOR_RECHARGE
        ) && (
          <Styles.RechargeNote>
            <Styles.NoteHeading>
              <Styles.ErrorText>NOTE: </Styles.ErrorText> The parent is{' '}
              <Styles.BoldText>due for recharge </Styles.BoldText> this month.
            </Styles.NoteHeading>
            <Styles.NoteText>
              <Styles.BoldText>Recharge & pay </Styles.BoldText>with coins to
              avoid multiple payments and conversations.
            </Styles.NoteText>
          </Styles.RechargeNote>
        )}
      {ctaOptions?.length > 1 &&
        coinSummary?.nudges?.includes(
          CART_NUDGE_VALUES.WILL_BE_DUE_FOR_RECHARGE
        ) && (
          <Styles.RechargeNote>
            <Styles.NoteHeading>
              <Styles.ErrorText>NOTE: </Styles.ErrorText> The parent{' '}
              <Styles.BoldText>will be due for recharge</Styles.BoldText> for
              this month after this payment.
            </Styles.NoteHeading>
            <Styles.NoteText>
              <Styles.BoldText>Recharge & pay </Styles.BoldText>with coins to
              avoid multiple payments and conversations.
            </Styles.NoteText>
          </Styles.RechargeNote>
        )}
    </Styles.TotalAndActionsContainer>
  )
}

export default TotalAndActionsComponent
