/* eslint-disable react/prop-types */
import FormControl from '@material-ui/core/FormControl'
import React, { useState } from 'react'

import { AutoComplete } from 'app/components/generic/FormFields'
import { fetchTeacher, flattenObjectArray } from 'app/helpers'

import { Container } from './styles'

export const BatchActivitiesWidget = ({
  fieldData: batchActivities,
  ...props
}) => {
  const flattenedBatchActivitiesData = flattenObjectArray(batchActivities, {
    teacher: 'teacher.user.uuid',
    teacher_name: 'teacher.user.full_name',
    subject: 'subject.uuid',
    subject_name: 'subject.name',
    start_time: 'start_time',
    end_time: 'end_time'
  })
  const [fieldValueData, setFieldValueData] = useState(
    flattenedBatchActivitiesData
  )

  const handleChange = (name, teacher) => {
    const arrayIndex = parseInt(name.split('-')[1])
    let newData = fieldValueData
    if (fieldValueData[arrayIndex]) {
      newData[arrayIndex].teacher = teacher.value ? teacher.value : teacher
      newData[arrayIndex].teacher_name = teacher.name ? teacher.name : teacher
      props.setFieldValue('batch_activities', newData)
      setFieldValueData(newData)
    }
  }

  return (
    <Container>
      {fieldValueData.map((batchActivity, index) => {
        const teacherDetails = {
          name: batchActivity?.teacher_name,
          value: batchActivity?.teacher
        }

        return (
          <React.Fragment key={index}>
            <FormControl
              variant="outlined"
              fullWidth
              className="formControlStyles"
            >
              <AutoComplete
                async={true}
                name={`teacher-${index}`}
                label={`Subject ${index + 1} - ${
                  batchActivity?.subject_name
                } - Teacher`}
                multiple={false}
                fetchValues={fetchTeacher}
                defaultValue={teacherDetails}
                onChange={handleChange}
              />
            </FormControl>
          </React.Fragment>
        )
      })}
    </Container>
  )
}
