import styled, { css } from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 3px 20px 5px 0;

  .button-dashboard {
    background-color: var(--oh-orange);
    text-transform: lowercase;
    font-weight: 600;
    font-family: 'AvenirDemi';
  }

  .button-dashboard:hover {
    background-color: var(--oh-dark-orange);
  }
`
