import { fetchStates, fetchCities } from 'app/store/actions/common'
import { store } from 'app/store/store'

export const dispatchFetchStates = () => {
  store.dispatch(fetchStates())
}

export const dispatchFetchCities = () => {
  store.dispatch(fetchCities())
}
