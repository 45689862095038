import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: '#292E3B',
    color: '#FFFFFF',
    fontWeight: 'bold'
  }
}))

const TableModal = (props) => {
  const classes = useStyles()
  const { columns, data } = props

  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell style={{ maxWidth: column.max_width }}>
                {column?.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!isEmpty(data)
            ? data.map((rowData, rowIndex) => (
                <TableRow>
                  {columns?.map((column, columnIndex) =>
                    typeof column?.options == 'object' ? (
                      column?.options?.customBodyRender(rowData)
                    ) : (
                      <TableCell
                        style={{
                          maxWidth: column.max_width ? column.max_width : null
                        }}>
                        {rowData[column?.name]}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableModal
