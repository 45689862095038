import dayjs from 'dayjs'
import React from 'react'

import {
  commonAttributes,
  navigationPaths,
  tableColumnDefaultOption
} from 'app/config'
import { validateMobile, validateNotEmpty } from 'app/validators'

// Membership Plan

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'enrolment.student.user',
    label: 'Student Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => (
        <span>{`${(value && value.first_name) || ''} ${
          (value && value.last_name) || ''
        }`}</span>
      ),
      filterKey: 'student_name'
    }
  },
  {
    name: 'enrolment.student.parent.user',
    label: 'Parent Name',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_name',
      customBodyRender: (value) =>
        value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}
            >{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null,
      ...tableColumnDefaultOption.options
    }
  },
  {
    name: 'enrolment.student.parent.user.phone_number',
    label: 'Parent Number',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_phone_number',
      ...tableColumnDefaultOption.options
    }
  },
  {
    name: 'enrolment.batch.course.name',
    label: 'Course Name',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'course_name'
    }
  },
  {
    ...commonAttributes.centre,
    name: 'enrolment.batch.centre.name',
    label: 'Centre',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_name'
    }
  },
  {
    name: 'enrolment.created',
    label: 'Booked on',
    options: {
      ...commonAttributes.date.options,
      filterKey: 'created',
      customBodyRender: (value) => {
        const date = new Date(value)
        return <span>{date.toLocaleString()}</span>
      }
    }
  },
  {
    name: 'enrolment.start_date',
    label: 'Session Date',
    options: {
      ...commonAttributes.date.options,
      filterKey: 'date'
    }
  },
  {
    name: 'enrolment.batch.teacher.full_name',
    label: 'Teacher',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    }
  }
]
