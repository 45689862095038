import React from 'react'
import TableModal from 'app/components/generic/TableModal'
import { TableWrapper, Title } from './styles'

const TableTitleModal = (props) => {
    const { columns, data, backgroundColor } = props
    const title = props?.title
    const title_number = props?.title_number

    return (
        <TableWrapper>
            {title ? <Title backgroundColor={backgroundColor}>{title} {title_number ? `(${title_number})` : null} </Title> : null}
            <TableModal columns={columns} data={data} />
        </TableWrapper>
    )
}

export default TableTitleModal