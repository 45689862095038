/* eslint-disable react/prop-types */
import React from 'react'

import { fetchMomentConsumer } from 'api'
import InfiniteScroll from 'app/components/generic/InfiniteScroll/InfiniteScroll'

import { Link } from '../../styles'

export const VisibilityTable = ({ title, momentId }) => {
  const columns = [
    {
      name: 'student.user.full_name',
      label: 'Student Name',
      options: {
        filter: false
      }
    },
    {
      name: 'student.parent.user.full_name',
      label: 'Parent Name',
      options: {
        filter: false
      }
    },
    {
      name: 'student.parent.user.phone_number',
      label: 'Parent Phone',
      options: {
        filter: false
      }
    },
    {
      name: 'share_view_count',
      label: 'Share View Count',
      options: {
        filter: false
      }
    },
    {
      name: 'share_url',
      label: 'Share url',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <Link href={value} target="_blank">
              {value.slice(0, 25).concat('....')}
            </Link>
          )
        }
      }
    }
  ]

  return (
    <InfiniteScroll
      title={title}
      columns={columns}
      fetchData={fetchMomentConsumer}
      queryParameters={{
        moment: momentId,
        student_isnull: false
      }}
    />
  )
}
