import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 14px;

  padding: calc(max(1.5em, 24px));

  .MuiDialog-paperWidthMd {
    max-width: 1080px;
  }
`

export const Heading = styled.h2`
  margin: 0;
  font-size: calc(max(1.5em, 24px));
  font-weight: bold;
  margin-bottom: 0.1em;
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 0.5em 0;
`

export const SubTitle = styled.h4`
  font-weight: normal;
  font-size: 1.1em;
  margin: 0;
`

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const DescriptionLeftContainer = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  margin: 1.5em 0 1.5em 0;

  p {
    font-size: 1.1em;
    margin: 0 0 1.5em 0;
  }

  h4 {
    min-width: 150px;
    font-size: 1.1em;
  }
  pre {
    white-space: pre-wrap;
    font-family: Avenir;
  }
`

export const DescriptionRightContainer = styled.div`
  display: flex;
  flex: 0.5;
  margin: 1.5em 0 1.5em 0;

  justify-content: flex-end;
  margin-right: 10%;

  h2 {
    min-width: 50px;
  }

  span {
    font-weight: normal;
    margin-right: 4px;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
`

export const Attributes = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1em;
`

export const KeyValue = styled.div`
  display: flex;
  margin-bottom: 8px;

  div {
    display: flex;
    align-items: center;
  }

  .group {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Value = styled.h4`
  display: flex;
  margin: 0.2em 0;
  font-style: bold;
`

export const Key = styled(Value)`
  font-weight: normal;
  margin-right: 1em;
  font-size: 1.1em;
`
