import React from 'react'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const ScheduleSuggestion = (props) => {
  const { suggestionData } = props
  const count = suggestionData?.data.length
  return (
    <styles.Body>
      <styles.ColumnFlexCenter>
        <TitleDescription
          heading="Total Suggestions"
          size="medium"
          description={`${count}`}
        />
      </styles.ColumnFlexCenter>
    </styles.Body>
  )
}
