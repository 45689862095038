import { makeStyles, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React from 'react'

import { fetchUser } from 'api'
import { createSubscriptions } from 'api/parentMembership'
import { navigationPaths } from 'app/config'
import constants from 'app/constants'
import { eventAlert, validateParentDetails } from 'app/helpers'

import ParentForm from './ParentForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(2)
    }
  },
  form: {
    width: '40%'
  },
  heading: {
    display: 'flex',
    margin: '20px'
  }
}))

const initialData = {
  user: '',
  plan: '',
  start_date: '',
  referral_code: '',
  dummy_first_cycle: false
}

const ParentMembership = () => {
  const classes = useStyles()
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [formData, setFormData] = React.useState(initialData)

  const formRef = React.useRef(null)

  const onSaveClick = async (newData) => {
    try {
      const response = await createSubscriptions({
        user: newData.user,
        plan: newData.plan,
        start_date: newData.start_date,
        referral_code: newData.referral_code,
        dummy_first_cycle: newData.dummy_first_cycle
      })

      if (response.uuid) {
        eventAlert('Membership created Successfully', constants.SUCCESS)
        return response
      }
    } catch (error) {
      eventAlert(error.message, constants.ERROR)
    }
  }

  const handleOnSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      setLoadingSubmit(true)
      const { submitForm, validateForm, values, resetForm } = formRef.current

      try {
        const errors = await validateForm(values)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          setLoadingSubmit(false)
        } else {
          // form is valid
          try {
            const saved = await onSaveClick(values)
            setLoadingSubmit(false)
            resetForm()
            setFormData({ ...initialData })
            if (saved.uuid) window.location.reload(false)
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [onSaveClick])

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {() => {
          return (
            <Form>
              <ParentForm
                formData={formData}
                handleOnSaveClick={handleOnSaveClick}
                loadingSubmit={loadingSubmit}
              />
            </Form>
          )
        }}
      </Formik>
    )
  }, [formData])

  return (
    <div className={classes.form}>
      <div className={classes.heading}>
        {' '}
        <Typography variant="h6">Parent Membership</Typography>
      </div>
      {formElement}
    </div>
  )
}

export default ParentMembership
