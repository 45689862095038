import React from 'react'

import {
  commonAttributes,
  navigationPaths,
  tableColumnDefaultOption,
  programList
} from 'app/config'
import { sanitize } from 'app/utils'

export const columns = [
  {
    name: 'attendance.enrolment.student.user.full_name',
    label: 'Student Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_name'
    }
  },
  {
    ...commonAttributes.standard,
    name: 'attendance.enrolment.student.standard.name',
    label: 'Student Grade',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'standard_name'
    }
  },
  {
    name: 'attendance.enrolment.student.parent.user',
    label: 'Parent Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_name',
      customBodyRender: (value) => {
        return value ? (
          <span>
            <a href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}>
              {value?.full_name}
            </a>
          </span>
        ) : null
      }
    }
  },
  {
    ...commonAttributes.centre,
    name: 'attendance.enrolment.student.parent.centre_preference.name',
    label: 'Centre',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_name'
    }
  },
  {
    name: 'teacher.user.full_name',
    label: 'Teacher Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    }
  },
  {
    ...commonAttributes.subject,
    name: 'schedule_activity.subject.name',
    label: 'Subject Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'subject_name'
    }
  },
  {
    name: 'schedule_activity.schedule.batch.type',
    label: 'Batch type',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: programList,
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'batch_type'
    }
  },
  {
    name: 'schedule_activity.details',
    label: 'Session Activity',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'session_activity',
      customBodyRender: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      },
      setCellProps: () => ({
        style: { minWidth: '500px', maxWidth: '500px', whiteSpace: 'pre-wrap' }
      })
    }
  },
  {
    name: 'rating.rating',
    label: 'How well did the child do',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'rating'
    }
  },
  {
    name: 'mood.name',
    label: 'Stand out emotion',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'mood'
    }
  },
  {
    name: 'parent_message',
    label: 'Message from Parent',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      setCellProps: () => ({
        style: { minWidth: '500px', maxWidth: '500px', whiteSpace: 'pre-wrap' }
      })
    }
  }
]
