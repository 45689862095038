import { isEmpty } from 'lodash'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get } from '../request'

const fetchCentreSchedules = async (queries = {}) => {
  const requestUrl = makeRequestUrl(apiEndpoints.centreSchedules, queries)
  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching centre schedule :- ', error.message)
    throw error
  }
}

export { fetchCentreSchedules }
