/* eslint-disable react/prop-types */
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import React from 'react'

import { yesNoOptions } from 'app/config'

export const EnrolmentTypeSelection = ({
  value,
  batchId,
  handleOnChange,
  isCheckoutMode,
  errorSchema
}) => {
  const onChange = (e) => {
    handleOnChange(batchId, e.target.name, e.target.value)
  }

  return !isCheckoutMode ? (
    <FormControl
      variant="standard"
      size="small"
      {...(!isEmpty(errorSchema.isDiscover) && errorSchema.isDiscover[batchId]
        ? { error: true }
        : null)}
      required>
      <InputLabel>{'Is this a discovery enrolment?'}</InputLabel>
      <Select
        name="isDiscover"
        defaultValue={''}
        value={value}
        label={'Is this a discovery enrolment?'}
        onChange={onChange}>
        {Object.keys(yesNoOptions)?.map((val, index) => (
          <MenuItem key={index} value={val}>
            {yesNoOptions[val]}
          </MenuItem>
        ))}
      </Select>
      {!isEmpty(errorSchema.isDiscover) && errorSchema.isDiscover[batchId] && (
        <FormHelperText>{errorSchema.isDiscover[batchId]}</FormHelperText>
      )}
    </FormControl>
  ) : (
    <>
      {value !== undefined ? (
        <Typography variant="subtitle2">
          {`This `}
          <Box display="inline" sx={{ fontWeight: 'bold' }}>
            {value === 'true' ? 'is' : 'is not'}
          </Box>
          {` a discovery enrolment`}
        </Typography>
      ) : null}
    </>
  )
}
