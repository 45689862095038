import { takeEvery, takeLatest } from 'redux-saga/effects'

import {
  createClub,
  createReferralCode,
  deleteClub,
  deleteReferralCode,
  fetchClub,
  fetchReferralCode,
  updateClub,
  updateReferralCode,
  fetchClubRegistration,
  fetchTribe,
  deleteTribe,
  createTribe,
  addTribeMember,
  fetchActiveTribeMember,
  fetchInactiveTribeMember,
  fetchWaitlistQueued,
  fetchWaitlistTribeSelection,
  fetchWaitlistConverted,
  fetchWaitlistOthers,
  createWaitlist,
  updateTribe,
  updateWaitlist,
  deleteWaitlist,
  updateNotes,
  fetchMoments,
  fetchOpenbook,
  createOpenbook
} from 'api'
import constants from 'app/constants'
import {
  fetchClubData,
  fetchClubDataSuccess,
  fetchReferralCodeData,
  fetchReferralCodeDataSuccess,
  fetchClubRegistrationData,
  fetchClubRegistrationDataSuccess,
  updateCommunityDataSuccess,
  fetchTribeData,
  fetchTribeDataSuccess,
  fetchTribeMemberData,
  fetchTribeMemberDataSuccess,
  fetchInactiveTribeMemberData,
  fetchInactiveTribeMemberDataSuccess,
  fetchWaitlistQueuedDataSuccess,
  fetchWaitlistQueuedData,
  fetchWaitlistTribeSelectionData,
  fetchWaitlistTribeSelectionDataSuccess,
  fetchWaitlistConvertedData,
  fetchWaitlistConvertedDataSuccess,
  fetchWaitlistOthersData,
  fetchWaitlistOthersDataSuccess,
  fetchClassroomMomentsData,
  fetchClassroomMomentsDataSuccess,
  fetchOpenbookData,
  fetchOpenbookDataSuccess
} from 'app/store/actions/communityEntity'
import * as types from 'app/store/types/communityEntity'

import { handleFetch, handleUpdate } from './saga.helper'

const mapping = {
  [constants.CLUB]: {
    api: {
      fetch: fetchClub,
      create: createClub,
      update: updateClub,
      delete: deleteClub
    },
    action: {
      fetch: fetchClubData,
      fetchSuccess: fetchClubDataSuccess
    }
  },
  [constants.TRIBE]: {
    api: {
      fetch: fetchTribe,
      delete: deleteTribe,
      create: createTribe,
      update: updateTribe
    },
    action: {
      fetch: fetchTribeData,
      fetchSuccess: fetchTribeDataSuccess
    }
  },
  [constants.MEMBERSHIP]: {
    api: {
      fetch: fetchActiveTribeMember,
      create: addTribeMember,
      update: updateNotes
    },
    action: {
      fetch: fetchTribeMemberData,
      fetchSuccess: fetchTribeMemberDataSuccess
    }
  },
  [constants.INACTIVE_TRIBE_MEMBERSHIP]: {
    api: {
      fetch: fetchInactiveTribeMember
    },
    action: {
      fetch: fetchInactiveTribeMemberData,
      fetchSuccess: fetchInactiveTribeMemberDataSuccess
    }
  },
  [constants.REFERRAL_CODE]: {
    api: {
      fetch: fetchReferralCode,
      create: createReferralCode,
      update: updateReferralCode,
      delete: deleteReferralCode
    },
    action: {
      fetch: fetchReferralCodeData,
      fetchSuccess: fetchReferralCodeDataSuccess
    }
  },
  [constants.CLUB_REGISTRATION]: {
    api: {
      fetch: fetchClubRegistration
    },
    action: {
      fetch: fetchClubRegistrationData,
      fetchSuccess: fetchClubRegistrationDataSuccess
    }
  },
  [constants.WAITLIST_QUEUED]: {
    api: {
      fetch: fetchWaitlistQueued,
      create: createWaitlist,
      update: updateWaitlist,
      delete: deleteWaitlist
    },
    action: {
      fetch: fetchWaitlistQueuedData,
      fetchSuccess: fetchWaitlistQueuedDataSuccess
    }
  },
  [constants.WAITLIST_TRIBE_SELECTION]: {
    api: {
      fetch: fetchWaitlistTribeSelection
    },
    action: {
      fetch: fetchWaitlistTribeSelectionData,
      fetchSuccess: fetchWaitlistTribeSelectionDataSuccess
    }
  },
  [constants.WAITLIST_CONVERTED]: {
    api: {
      fetch: fetchWaitlistConverted
    },
    action: {
      fetch: fetchWaitlistConvertedData,
      fetchSuccess: fetchWaitlistConvertedDataSuccess
    }
  },
  [constants.WAITLIST_OTHERS]: {
    api: {
      fetch: fetchWaitlistOthers
    },
    action: {
      fetch: fetchWaitlistOthersData,
      fetchSuccess: fetchWaitlistOthersDataSuccess
    }
  },
  [constants.MOMENTS]: {
    api: {
      fetch: fetchMoments
    },
    action: {
      fetch: fetchClassroomMomentsData,
      fetchSuccess: fetchClassroomMomentsDataSuccess
    }
  },
  [constants.OPENBOOK]: {
    api: {
      fetch: fetchOpenbook,
      create: createOpenbook
    },
    action: {
      fetch: fetchOpenbookData,
      fetchSuccess: fetchOpenbookDataSuccess
    }
  }
}

export function* _fetchCommunityEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updateCommunityEntity({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(
    mappingData,
    entity,
    event,
    value,
    updateCommunityDataSuccess
  )
}

export function* watchFetchCommunityEntity() {
  yield takeLatest(
    [
      types.FETCH_CLUB_DATA,
      types.FETCH_TRIBE_DATA,
      types.FETCH_TRIBE_MEMBER_DATA,
      types.FETCH_INACTIVE_TRIBE_MEMBER_DATA,
      types.FETCH_REFERRAL_CODE_DATA,
      types.FETCH_CLUB_REGISTRATION_DATA,
      types.FETCH_WAITLIST_QUEUED_DATA,
      types.FETCH_WAITLIST_TRIBE_SELECTION_DATA,
      types.FETCH_WAITLIST_CONVERTED_DATA,
      types.FETCH_WAITLIST_OTHERS_DATA,
      types.FETCH_CLASSROOM_MOMENTS_DATA,
      types.FETCH_OPENBOOK_DATA
    ],
    _fetchCommunityEntity
  )
}

export function* watchUpdateCommunityEntity() {
  yield takeEvery(types.UPDATE_COMMUNITY_DATA, _updateCommunityEntity)
}
