import React from 'react'

import ErrorImage from 'assets/images/error.svg'

import * as Styles from './styles'

const Error = ({ error }) => {
  return (
    <Styles.Container>
      <Styles.ErrorImage src={ErrorImage} alt="error" />
      <Styles.ErrorMessage>{error}</Styles.ErrorMessage>
    </Styles.Container>
  )
}

export { Error }
