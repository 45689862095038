import React from 'react'

import * as styles from './styles'

import IconTitleDescription from '../../../components/generic/IconTitleDescription/index'
import AbilitiesCard from '../AbilitiesCard'
import { teacher, parent, child, JourneyReportStatus } from '../constants'
import HeaderDetailsCard from '../HeaderDetailsCard'
import HeaderTitle from '../HeaderTitle'
import {
  getMonthName,
  getLevel,
  getMilestone,
  getReportStatusText,
  getReportStatusIcon,
  getProgressDetails,
  getAbilitiesData,
  getAbilitiesProgressData
} from '../helpers'
import JourneyMonthIcon from '../JourneyMonthIcon'
import ProgressDetails from '../ProgressDetails'

const JourneyCard = (props) => {
  const { data } = props
  const report_uuid = data?.uuid
  const reportStatus = data?.status

  //get card header data
  const inactiveCard = data?.card_details?.smallCard
  const status = data?.card_details?.status
  const color = data?.card_details?.color
  const collapsedCard = data?.card_details?.collapsedCard

  //get report dates data
  const month = getMonthName(data?.month)
  const year = data?.year
  const end_month = getMonthName(data?.end_month)
  const end_year = data?.end_year
  const show_end_month = data?.card_details?.showEndMonth

  //get card body data
  const teacher_feedback = data?.teacher_feedback
  const parent_feedback = data?.parent_feedback
  const child_interest = data?.child_interest

  //get report status data
  const report_status = getReportStatusText(data)
  const report_status_icon = getReportStatusIcon(data)

  //get milestone and level data
  const current_milestone = getMilestone(
    data?.pre_progress_snapshot?.current_milestone
  )
  const current_level = getLevel(data?.pre_progress_snapshot?.current_level)

  //get sessions attended details
  const regular = data?.regular_attended
  const makeup = data?.makeup_attended
  const total_attended = regular + makeup
  const total_sessions =
    data?.total_regular_sessions + data?.total_makeup_sessions

  //get progress details
  const progressDetails = getProgressDetails(data)
  const {
    progress_title,
    show_progress_difference,
    progress_difference_after,
    progress_difference_before,
    progress_difference_color,
    progress_title_icon
  } = progressDetails

  //get abilities count data
  const abilitiesData = getAbilitiesData(data)

  const { setIsProgressModalOpen } = props
  const { setProgressModalData } = props

  const handleAbilitiesCardClick = async (report_uuid) => {
    const data = await getAbilitiesProgressData(report_uuid)
    setProgressModalData(data)
    setIsProgressModalOpen((prevState) => !prevState)
  }

  return (
    <styles.CardMonth>
      <styles.Month>
        <JourneyMonthIcon
          month_start={month}
          year_start={year}
          month_end={end_month}
          year_end={end_year}
          color={color}
          show_end_month={show_end_month}
        />
      </styles.Month>

      <styles.Card>
        <styles.HeaderContainer collapsedCard={collapsedCard} color={color}>
          <HeaderTitle
            status={status}
            details={current_level + ' | ' + current_milestone}
          />
          <styles.HeaderExtraData>
            <HeaderDetailsCard
              title={'report status'}
              titleColor={'black'}
              description={report_status}
              numberText={null}
              iconUrl={report_status_icon}
              displayIcon={true}
              displayNumberText={false}
            />
            {!(
              reportStatus === JourneyReportStatus.INACTIVE && !total_sessions
            ) ? (
              <HeaderDetailsCard
                title={'sessions attended'}
                titleColor={'#228BEC'}
                description={`${regular} (Regular) ${makeup} (Makeup)`}
                numberText={`${total_attended}/${total_sessions}`}
                iconUrl={null}
                displayIcon={false}
                displayNumberText={true}
              />
            ) : null}
          </styles.HeaderExtraData>
        </styles.HeaderContainer>

        {inactiveCard ? (
          <styles.smallCardContainer>
            <styles.smallCardIcon src={data?.card_details?.iconUrl} />
            <styles.smallCardText>
              {data?.card_details?.text}
            </styles.smallCardText>
          </styles.smallCardContainer>
        ) : collapsedCard ? null : (
          <styles.BodyContainer>
            <styles.BodyColumn>
              <ProgressDetails
                iconUrl={progress_title_icon}
                title={progress_title}
                show_progress_difference={show_progress_difference}
                difference_color={progress_difference_color}
                difference_before={progress_difference_before}
                difference_after={progress_difference_after}
                handleTitleClick={() => handleAbilitiesCardClick(report_uuid)}
              />
              <IconTitleDescription
                iconUrl={teacher}
                title={'teacher feedback'}
                description={teacher_feedback ? teacher_feedback : 'N/A'}
                isShowMore={true}
              />
            </styles.BodyColumn>

            <styles.BodyColumn>
              <div onClick={() => handleAbilitiesCardClick(report_uuid)}>
                <AbilitiesCard abilitiesData={abilitiesData} />
              </div>
              <IconTitleDescription
                iconUrl={parent}
                title={'parent feedback'}
                description={parent_feedback ? parent_feedback : 'N/A'}
                isShowMore={true}
              />
              <IconTitleDescription
                iconUrl={child}
                title={'child interest'}
                description={
                  child_interest === 1
                    ? 'Yes'
                    : child_interest === -1
                    ? 'No'
                    : 'N/A'
                }
                isShowMore={false}
              />
            </styles.BodyColumn>
          </styles.BodyContainer>
        )}
      </styles.Card>
    </styles.CardMonth>
  )
}

export default JourneyCard
