import styled, { css } from 'styled-components'

export const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .MuiDivider-root {
    width: 100%;
  }
`

export const ColumnFlexCenter = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
`
