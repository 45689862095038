/* eslint-disable react/prop-types */
import dayjs from 'dayjs'
import React from 'react'

import { Wrapper, Date, Day } from './styles'

export const DayHeaderContent = (props) => {
  if (!props.date) return null
  const date = dayjs(props.date).format('D MMM').toUpperCase()
  const day = dayjs(props.date).format('ddd').toUpperCase()
  return (
    <Wrapper isToday={props.isToday}>
      <Date>
        <small>{date}</small>
      </Date>
      <Day isToday={props.isToday}>{day}</Day>
    </Wrapper>
  )
}
