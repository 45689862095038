import React from 'react';
import * as styles from './styles';

const JourneyMonthIcon = (props) => {
  const {month_start, year_start, month_end, year_end, color, show_end_month} = props

  return (
    <styles.JourneyMonthIconContainer>
      <styles.Month>{show_end_month ? month_end: month_start}</styles.Month>
      <styles.Year>{show_end_month ? year_end: year_start}</styles.Year>
      <styles.TimelineDot color={color}/>
      <styles.TimelineLine color={color}/>
      {show_end_month && <styles.TimelineDot color={color}/>}
      {show_end_month && <styles.Month>{month_start}</styles.Month>}
      {show_end_month && <styles.Year>{year_start}</styles.Year>}
    </styles.JourneyMonthIconContainer>
  );
}

export default JourneyMonthIcon;
