import React from 'react'

import EditIcon from 'assets/images/edit.svg'

import * as styles from './styles'
export const UserEditButton = ({ buttonText, onClick, disabled = false }) => {
  return (
    <styles.NoUser>
      <styles.Icon src={EditIcon} />
      <styles.Button onClick={onClick} disabled={disabled}>
        <span>{buttonText}</span>
      </styles.Button>
    </styles.NoUser>
  )
}
