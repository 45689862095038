import dayjs from 'dayjs'
import React from 'react'

import {
  cartStatusTypes,
  cartTypes,
  commonAttributes,
  navigationPaths,
  sourceTypes,
  tableColumnDefaultOption
} from 'app/config'

export const columns = [
  // {
  //   name: 'uuid',
  //   label: 'Order ID'
  // },
  {
    ...commonAttributes.date,
    name: 'created_date',
    label: 'Order Date',
    options: {
      ...commonAttributes.date.options,
      customBodyRender: (val) => dayjs(val).format('DD MMM, YYYY'),
      filterKey: 'created_date'
    }
  },
  {
    name: 'created_time',
    label: 'Order Time',
    options: {
      disableFilter: true
    }
  },
  {
    ...commonAttributes.centre,
    name: 'parent.centre_preference.name',
    label: 'Centre',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre'
    }
  },
  {
    name: 'parent.user',
    label: 'Parent Name',
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) =>
        value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}>{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null,
      filterKey: 'parent_name'
    }
  },
  {
    name: 'parent.user.phone_number',
    label: 'Parent Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_phone_number'
    }
  },
  {
    name: 'type_display_name',
    label: 'Cart Type',
    widget: 'select',
    widgetFilter: true,
    isValueKey: true,
    valueLabelMappings: cartTypes,
    options: { ...tableColumnDefaultOption.options, filterKey: 'type' }
  },
  {
    name: 'status',
    label: 'Cart Status',
    widget: 'select',
    widgetFilter: true,
    isValueKey: true,
    valueLabelMappings: cartStatusTypes,
    options: { ...tableColumnDefaultOption.options, filterKey: 'status' }
  },
  {
    name: 'payment_mode',
    label: 'Payment method',
    options: { disableFilter: true }
  },
  {
    name: 'payment_link',
    label: 'Payment Link',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) =>
        value ? (
          <a target="_blank" rel="noopener noreferrer" href={value}>
            {value}
          </a>
        ) : null
    }
  },
  {
    name: 'total_cart_amount',
    label: 'Total Cart Amount',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) =>
        value ? <span>{parseFloat(value).toFixed(2)}</span> : null
    }
  },
  {
    name: 'coin_payment',
    label: 'Coins',
    options: {
      disableFilter: true,
      customBodyRender: (val) => (val ? val : '0')
    }
  },
  {
    name: 'value',
    label: 'Rupees',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) =>
        value ? <span>{parseFloat(value).toFixed(2)}</span> : null
    }
  },
  {
    name: 'gst',
    label: 'GST',
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) =>
        value ? <span>{parseFloat(value).toFixed(2)}</span> : null
    }
  },
  {
    name: 'discount_value',
    label: 'discount',
    options: { disableFilter: true }
  },
  {
    name: 'author.full_name',
    label: 'operator',
    options: { ...tableColumnDefaultOption.options, filterKey: 'author' }
  },
  {
    name: 'source_display_name',
    label: 'source',
    widget: 'select',
    widgetFilter: true,
    isValueKey: true,
    valueLabelMappings: sourceTypes,
    options: { ...tableColumnDefaultOption.options, filterKey: 'source' }
  }
  //add actions
]
