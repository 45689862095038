import React from 'react'

import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import {
  validateCount,
  validateBio,
  validateEmail,
  validateName,
  validateURL,
  validateMobile,
  validateIfsc,
  validateNotEmpty
} from 'app/validators'

import { TeacherWidget } from './TeacherWidget'

export const columns = [
  {
    ...commonAttributes.uuid,
    name: 'uuid'
  },
  {
    name: 'name',
    label: 'Institute Name',
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'name'
    }
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'phone_number'
    }
  },
  {
    name: 'email',
    label: 'Email',
    // optional: true,
    validate: validateEmail,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'email'
    }
  },
  {
    name: 'postal_address',
    label: 'Address',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'postal_address'
    }
  },
  {
    name: 'biography',
    label: 'Biography',
    validate: validateBio,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'bio',
      disableFilter: true
    }
  },
  {
    name: 'online_presence',
    label: 'Online presence (url)',
    validate: validateURL,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'url',
      disableFilter: true
    }
  },
  {
    name: 'bank_account_holder_name',
    label: 'Bank Account holder name',
    optional: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'bank_account_number',
    label: 'Bank Account number',
    optional: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'bank_ifsc_code',
    label: 'Bank IFSC code',
    optional: true,
    validate: validateIfsc,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'dispatch_rate_per_session_ts',
    label: 'Dispatch Rate (Source institute)',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'dispatch_rate_per_session_ts',
      disableFilter: true
    }
  },
  {
    name: 'dispatch_rate_per_session_ohs',
    label: 'Dispatch Rate (Source oh)',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'dispatch_rate_per_session_ohs',
      disableFilter: true
    }
  },
  {
    name: 'teachers',
    label: 'Teachers data',
    disabledInForm: true,
    viewOnlyInModal: false,
    modalEdit: false,
    optional: true,
    widget: TeacherWidget,
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  }
]
