export const SEND_OTP = 'SEND_OTP'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const SEND_OTP_FAILED = 'SEND_OTP_FAILED'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const LOGIN_WITH_COOKIE = 'LOGIN_WITH_COOKIE'
export const LOGIN_WITH_COOKIE_SUCCESS = 'LOGIN_WITH_COOKIE_SUCCESS'
export const LOGIN_WITH_COOKIE_FAILED = 'LOGIN_WITH_COOKIE_FAILED'
