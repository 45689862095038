import { generateUUID } from '@openhouse-technologies/utils'

import {
  targetAudienceSelectionModes,
  bulkMessagingAvailableFilters
} from 'app/config'
import constants from 'app/constants'
import { apiFormattedResult } from 'app/helpers'

import {
  commonStructure,
  dataResponseHandler,
  dataFetchingHandler,
  handleEntitiesUpdate
} from './reducer.helper'

import * as types from '../types/communicationEntity'

const getNumberOfParams = (text) => {
  return (text?.match(/{{\d}}/g) || []).length
}

const bulkMessagingInitialState = {
  mode: 'whatsapp',
  isSendMessageModalOpen: false,
  isCreateMessageTemplateModalOpen: false,
  isDeliveryStatsModalOpen: false,
  isBulkMessageRequestInProgress: false,
  selectedMessageTemplate: {
    value: null,
    name: null,
    parameters: [],
    rawTemplate: null,
    formattedTemplate: null
  },
  userType: constants.STUDENT,
  users: commonStructure(),
  userFilters: {},
  targetAudienceSelectionMode: targetAudienceSelectionModes.AUTOMATIC,
  includedUsers: [],
  excludedUsers: [],
  data: commonStructure(),
  errors: {
    fields: {
      messageTemplate: null,
      userType: null,
      parameters: {}
    }
  },
  deliveryType: null,
  deliveryStatsData: {
    id: null,
    isLoading: false,
    isLoaded: false,
    data: {},
    error: null
  }
}

const chatMessagesHeaderInitialState = {
  chatMessagesHeaderData: {}
}

const parentChatMessagesHeaderInitialState = {
  parentChatMessagesHeaderData: {}
}

const initialState = {
  reportCardData: commonStructure(),
  bulkMessaging: {
    ...bulkMessagingInitialState
  },
  interactionHistoryData: commonStructure(),
  ticketData: commonStructure(),
  teacherListData: commonStructure(),
  studentListData: commonStructure(),
  parentListData: commonStructure(),
  chatMessagesData: commonStructure(),
  parentChatMessagesData: commonStructure(),
  chatMessagesHeaderData: { ...chatMessagesHeaderInitialState },
  parentChatMessagesHeaderData: { ...parentChatMessagesHeaderInitialState }
}

const bulkMessagingFetchHandler = (state, action) => {
  return {
    ...state,
    bulkMessaging: {
      ...state.bulkMessaging,
      data: {
        ...state.bulkMessaging.data,
        isLoading: !action.isFiltering && !action.isPaginating,
        isLoaded: false,
        isPaginating: action.isPaginating,
        isFiltering: action.isFiltering,
        data:
          !action.isFiltering && !action.isPaginating
            ? []
            : state.bulkMessaging.data.data,
        error: null
      }
    }
  }
}

const bulkMessagingResponseHandler = (state, action) => {
  return {
    ...state,
    bulkMessaging: {
      ...state.bulkMessaging,
      data: {
        isLoading: false,
        isLoaded: true,
        isPaginating: false,
        isFiltering: false,
        ...(action.error
          ? { error: action.error }
          : apiFormattedResult(action.value))
      }
    }
  }
}

const updateSelectedMessageTemplate = (state, action) => {
  const { name, components } = action.value || {}

  if (name && components) {
    const template = components[0]?.text
    const totalParameters = getNumberOfParams(template)

    if (name === state.bulkMessaging.selectedMessageTemplate?.name) {
      return state
    }

    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        selectedMessageTemplate: {
          value: action.value,
          name: name,
          rawTemplate: template,
          formattedTemplate: template,
          parameters: new Array(totalParameters).fill().map((item) => ({
            uuid: generateUUID(),
            isDynamic: true,
            value: '',
            staticValue: ''
          }))
        }
      }
    }
  } else {
    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        selectedMessageTemplate: {
          name: null,
          parameters: [],
          rawTemplate: null,
          formattedTemplate: null
        }
      }
    }
  }
}

const updateMessageTemplateParameter = (state, action) => {
  const {
    data: { index, data, formattedTemplate }
  } = action

  return {
    ...state,
    bulkMessaging: {
      ...state.bulkMessaging,
      selectedMessageTemplate: {
        ...state.bulkMessaging.selectedMessageTemplate,
        parameters: [
          ...state.bulkMessaging.selectedMessageTemplate.parameters.slice(
            0,
            index
          ),
          data,
          ...state.bulkMessaging.selectedMessageTemplate.parameters.slice(
            index + 1
          )
        ],
        formattedTemplate
      }
    }
  }
}

const updateUsers = (state, action) => {
  if (action.event === constants.FETCHING) {
    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        users: {
          ...state.bulkMessaging.users,
          isLoading: true,
          isLoaded: false,
          data: []
        }
      }
    }
  } else if (action.event === constants.UPDATED) {
    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        users: {
          ...state.bulkMessaging.users,
          isLoading: false,
          isLoaded: true,
          count: action.value.count,
          data: action.value.data
        },
        excludedUsers: state.bulkMessaging.excludedUsers.filter(
          (excludedUser) =>
            action.value.data.findIndex(
              (item) => item.uuid === excludedUser.user.uuid
            ) > -1
        )
      }
    }
  }

  return state
}

const updateExcludedUsers = (state, action) => {
  if (action.event === constants.CREATED) {
    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        excludedUsers: [...state.bulkMessaging.excludedUsers, action.value]
      }
    }
  } else if (action.event === constants.REMOVED) {
    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        excludedUsers: [
          ...state.bulkMessaging.excludedUsers.filter(
            (item) => item?.user?.uuid !== action.value
          )
        ]
      }
    }
  }
}

const updateIncludedUsers = (state, action) => {
  if (action.event === constants.CREATED) {
    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        includedUsers: [...state.bulkMessaging.includedUsers, action.value]
      }
    }
  } else if (action.event === constants.REMOVED) {
    return {
      ...state,
      bulkMessaging: {
        ...state.bulkMessaging,
        includedUsers: [
          ...state.bulkMessaging.includedUsers.filter(
            (item) => item?.user?.uuid !== action.value
          )
        ]
      }
    }
  }

  return state
}

const updateUserType = (state, action) => {
  let newFilters = {}

  const userType = action.value

  const { userFilters } = state.bulkMessaging

  bulkMessagingAvailableFilters[userType].forEach((filterName) => {
    if (Object.prototype.hasOwnProperty.call(userFilters, filterName)) {
      newFilters = {
        ...newFilters,
        [filterName]: userFilters[filterName]
      }
    }
  })

  return {
    ...state,
    bulkMessaging: {
      ...state.bulkMessaging,
      userType,
      userFilters: newFilters,
      users: commonStructure(),
      excludedUsers: [],
      includedUsers: []
    }
  }
}

const handleDeliveryStatsData = (state, action) => {
  switch (action.type) {
    case types.FETCH_DELIVERY_STATS_DATA:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          deliveryStatsData: {
            ...state.bulkMessaging.deliveryStatsData,
            id: action.id,
            isLoading: true,
            isLoaded: false
          }
        }
      }

    case types.FETCH_DELIVERY_STATS_DATA_SUCCESS:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          deliveryStatsData: {
            ...state.bulkMessaging.deliveryStatsData,
            id: action.id,
            isLoading: false,
            isLoaded: true,
            data: action.data,
            error: null
          }
        }
      }

    default:
      return state
  }
}

const handleErrors = (state, action) => {
  switch (action.type) {
    case types.UPDATE_FIELD_ERRORS:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          errors: {
            ...state.bulkMessaging.errors,
            fields: action.errors
          }
        }
      }

    default:
      return state
  }
}

const handleResetBulkMessagingCreatiionData = (state, action) => {
  return {
    ...state,
    bulkMessaging: {
      ...bulkMessagingInitialState,
      data: state.bulkMessaging.data
    }
  }
}

const updateBulkMessagingUserFilters = (state, action) => {
  const { name, value } = action

  return {
    ...state,
    bulkMessaging: {
      ...state.bulkMessaging,
      userFilters: {
        ...state.bulkMessaging.userFilters,
        [name]: value
      }
    }
  }
}

const communicationEntityReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.FETCH_REPORT_CARD_DATA:
    case types.FETCH_INTERACTION_HISTORY_DATA:
    case types.FETCH_TICKETS_DATA:
    case types.FETCH_TEACHER_LIST_DATA:
    case types.FETCH_STUDENT_LIST_DATA:
    case types.FETCH_PARENT_LIST_DATA:
    case types.FETCH_CHAT_MESSAGES_DATA:
    case types.FETCH_PARENT_CHAT_MESSAGES_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_REPORT_CARD_DATA_SUCCESS:
    case types.FETCH_INTERACTION_HISTORY_DATA_SUCCESS:
    case types.UPDATE_INTERACTION_HISTORY_DATA_SUCCESS:
    case types.FETCH_TICKETS_DATA_SUCCESS:
    case types.UPDATE_TICKET_DATA_SUCCESS:
    case types.FETCH_TEACHER_LIST_DATA_SUCCESS:
    case types.FETCH_STUDENT_LIST_DATA_SUCCESS:
    case types.FETCH_PARENT_LIST_DATA_SUCCESS:
    case types.FETCH_CHAT_MESSAGES_DATA_SUCCESS:
    case types.FETCH_PARENT_CHAT_MESSAGES_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    case types.FETCH_BULK_MESSAGING_DATA:
      return bulkMessagingFetchHandler(state, action)

    case types.FETCH_BULK_MESSAGING_DATA_SUCCESS:
      return bulkMessagingResponseHandler(state, action)

    case types.UPDATE_SELECTED_MESSAGE_TEMPLATE:
      return updateSelectedMessageTemplate(state, action)

    case types.UPDATE_MESSAGE_TEMPLATE_PARAMETER:
      return updateMessageTemplateParameter(state, action)

    case types.UPDATE_USERS:
      return updateUsers(state, action)

    case types.UPDATE_EXCLUDED_USERS:
      return updateExcludedUsers(state, action)

    case types.UPDATE_INCLUDED_USERS:
      return updateIncludedUsers(state, action)

    case types.UPDATE_USER_TYPE:
      return updateUserType(state, action)

    case types.FETCH_DELIVERY_STATS_DATA:
    case types.FETCH_DELIVERY_STATS_DATA_SUCCESS:
      return handleDeliveryStatsData(state, action)

    case types.UPDATE_FIELD_ERRORS:
      return handleErrors(state, action)

    case types.UPDATE_BULK_MESSAGING_USER_FILTERS:
      return updateBulkMessagingUserFilters(state, action)

    case types.UPDATE_SEND_MESSAGE_MODAL_STATE:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          isSendMessageModalOpen: action.value
        }
      }

    case types.UPDATE_CREATE_MESSAGE_TEMPLATE_MODAL_STATE:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          isCreateMessageTemplateModalOpen: action.value
        }
      }

    case types.UPDATE_DELIVERY_STATS_MODAL_STATE:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          isDeliveryStatsModalOpen: action.value
        }
      }

    case types.UPDATE_DELIVERY_TYPE:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          deliveryType: action.deliveryType
        }
      }

    case types.UPDATE_TARGET_AUDIENCE_SELECTION_MODE:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          targetAudienceSelectionMode: action.value
        }
      }

    case types.UPDATE_BULK_MESSAGING_SEND_API_PROGRESS_STATUS:
      return {
        ...state,
        bulkMessaging: {
          ...state.bulkMessaging,
          isBulkMessageRequestInProgress: action.value
        }
      }

    case types.RESET_BULK_MESSAGING_CREATION_DATA:
      return handleResetBulkMessagingCreatiionData(state, action)

    case types.SET_CHAT_MESSAGES_HEADER_DATA:
      return {
        ...state,
        chatMessagesHeaderData: {
          ...state.data,
          [`${action.entity}Data`]: action.value,
        },
      }

    case types.SET_PARENT_CHAT_MESSAGES_HEADER_DATA:
      return {
        ...state,
        parentChatMessagesHeaderData: {
          ...state.data,
          [`${action.entity}Data`]: action.value,
        },
      }

    default:
      return state
  }
}

export { communicationEntityReducer }
