import { makeStyles } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { useSelector } from 'react-redux'

const Table = (props) => {
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn)
  const useStyle = makeStyles(() => ({
    table: {
      display: 'flex',
      flexDirection: 'column',
      height: props.disableFullHeight ? 'auto' : '100vh',
      width: !isLoggedIn ? 'calc(100vw-270px)' : null
    }
  }))
  const classes = useStyle()

  return <MUIDataTable className={classes.table} {...props} />
}

Table.defaultProps = {
  disableFullHeight: false
}

export default Table
