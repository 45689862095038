import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'

import { getUpcomingMakeupClassesInBatch } from 'api/journey'
import { Select, TextField } from 'app/components/generic/FormElements'
import { validateNotNil } from 'app/validators'

import { formatDateToLong, formatMakeupDatesList } from '../../helpers'
import {
  getUpcomingMakeupsQueryParams,
  transformMakeupSessions
} from '../../transformer'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  nudge: {
    color: theme.palette.error.dark
  },
  bold: {
    fontWeight: 600
  },
  errorBox: {
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.error.dark}`,
    backgroundColor: '#FAE6E9',
    borderRadius: 4
  }
}))

const ChangeBatchTimingsForm = (props) => {
  const classes = useStyles()
  const { formData } = props
  const { errors, values, setFieldValue, validateField } = useFormikContext()

  const [batches, setBatches] = useState([])
  const [makeupDates, setMakeupDates] = useState([])

  React.useEffect(() => {
    setBatches(formData?.batches || [])
  }, [formData])

  const batchChangeHandler = async (event) => {
    const selectedBatchValue = event.target.value
    const selectedBatch = batches.find(
      (batch) => batch.batch === selectedBatchValue
    )
    if (selectedBatch) {
      setFieldValue(
        'first_new_session_date',
        formatDateToLong(selectedBatch?.first_session_date)
      )
      setFieldValue(
        'last_current_session_date',
        formatDateToLong(selectedBatch?.current_batch_last_session_date)
      )
      setFieldValue('new_batch', selectedBatchValue, false)
      setFieldValue('new_timings', selectedBatch?.timings, false)

      const params = getUpcomingMakeupsQueryParams({
        batch: selectedBatchValue,
        ...formData
      })

      const makeupSessions = await getUpcomingMakeupClassesInBatch(params)
      setMakeupDates(transformMakeupSessions(makeupSessions))

      // Validate the month field after setting its value
      setTimeout(() => validateField('new_batch'), 0)
    }
  }
  const formElement = React.useMemo(() => {
    return (
      <>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}>
          <TextField
            name={'student_name'}
            label={'Student Name'}
            variant="outlined"
            defaultValue={formData.student_name}
            disabled
            required
          />
          {!isEmpty(errors.student_name) && (
            <FormHelperText>{errors.student_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}>
          <TextField
            name="course_name"
            label={'Course'}
            variant="outlined"
            required
            defaultValue={formData.subject_name}
            disabled
          />
          {!isEmpty(errors.subject_name) && (
            <FormHelperText>{errors.subject_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.level) ? { error: true } : null)}>
          <TextField
            name="level"
            label={'Level'}
            variant="outlined"
            required
            defaultValue={formData.level}
            disabled
          />
          {!isEmpty(errors.level) && (
            <FormHelperText>{errors.level}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.timings) ? { error: true } : null)}>
          <TextField
            name="timings"
            label={'Current Batch'}
            variant="outlined"
            required
            defaultValue={formData?.timings}
            disabled
          />
          {!isEmpty(errors.timings) && (
            <FormHelperText>{errors.timings}</FormHelperText>
          )}
        </FormControl>
        {!isEmpty(values?.last_current_session_date) && (
          <div
            className={
              classes.nudge
            }>{`Last session in current batch: ${formatDateToLong(
            values?.last_current_session_date
          )}`}</div>
        )}
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.new_batch) ? { error: true } : null)}>
          <InputLabel>{'New Batch'}</InputLabel>
          <Select
            name={'new_batch'}
            defaultValue={''}
            label={'New Batch'}
            validate={validateNotNil({ isRequired: true })}
            onChange={batchChangeHandler}>
            {batches?.map((batch, index) => (
              <MenuItem
                key={index}
                value={batch.batch}
                disabled={!batch.is_eligible}>
                {`${batch.timings.join(' | ')} (${batch.teacher_name})`}
              </MenuItem>
            ))}
          </Select>
          {!isEmpty(errors.new_batch) && (
            <FormHelperText>{errors.new_batch}</FormHelperText>
          )}
        </FormControl>
        {!isEmpty(values?.first_new_session_date) && (
          <div
            className={
              classes.nudge
            }>{`First session in new batch: ${formatDateToLong(
            values?.first_new_session_date
          )}`}</div>
        )}
        {makeupDates.length > 0 ? (
          <div className={classes.errorBox}>
            <span className={classes.bold}>Please note: </span>
            {` The selected batch has ${makeupDates.length} make-up class${
              makeupDates.length > 1 ? 'es' : ''
            } booked for ${formatMakeupDatesList(makeupDates)}.`}
            <br />
            Either select a different batch OR the booked make-up classes will
            automatically be deleted and the parent can book them again for a
            different batch.
          </div>
        ) : null}
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.reason) ? { error: true } : null)}>
          <TextField
            name={'reason'}
            label={'Reason'}
            variant="outlined"
            defaultValue={formData?.reason || ''}
          />
          {!isEmpty(errors.reason) && (
            <FormHelperText>{errors.reason}</FormHelperText>
          )}
        </FormControl>
      </>
    )
  }, [formData, errors, values, batches, makeupDates])

  return <>{formElement}</>
}

export default ChangeBatchTimingsForm
