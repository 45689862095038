import { isEmpty } from 'lodash'
import { call, put } from 'redux-saga/effects'

import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'

import { updateServerDataFetchError } from '../actions/app'

export const pluginQueriesFromURL = (queries) => {
  const modifiedQueries = {
    ...queries,
    queries: { ...(queries?.queries || {}) }
  }

  const params = new URLSearchParams(window.location.search)

  if (params.get('filterResult') === '1') {
    for (const [key, value] of params.entries()) {
      if (!isEmpty(key) && key !== 'filterResult') {
        modifiedQueries.queries[key] = value
      }
    }
  }
  return modifiedQueries
}

export function* handleFetch(mappingData, queries, entity = '') {
  try {
    if (mappingData) {
      const response = yield call(
        mappingData.api.fetch,
        pluginQueriesFromURL(queries),
        entity
      )
      yield put(mappingData.action.fetchSuccess(response, entity))
      if (!isEmpty(queries)) {
        // probably a case of filtering or searching
        eventEmitter.emit(constants.REFETCHING_STOP)
      } else {
        yield put(updateServerDataFetchError({ entity, error: false }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(
      updateServerDataFetchError({
        entity,
        error: true,
        message: error.message
      })
    )
  }
}

export function* handleUpdate(
  mappingData,
  entity,
  event,
  value,
  updateSuccess
) {
  if (mappingData) {
    try {
      let response
      switch (event) {
        case constants.CREATED:
          response = yield call(mappingData.api.create, value, entity)
          break

        case constants.UPDATED:
          response = yield call(mappingData.api.update, value, entity)
          break

        case constants.REMOVED:
          response = yield call(mappingData.api.delete, value)
          break

        default:
          break
      }

      yield put(updateSuccess(entity, event, response))

      if ([constants.CREATED, constants.UPDATED].indexOf(event) > -1) {
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)

        eventAlert(
          value?.successMessage || constants.DATA_SAVED,
          constants.SUCCESS
        )

        try {
          if (process.env.NODE_ENV !== 'test') {
            // FIXME: dependent on test env because testing is failing bcz of multiple action yielding
            if (entity === 'membership' && event === 'updated') {
              yield put(
                mappingData.action.fetch(
                  {
                    queries: {
                      school:
                        new URLSearchParams(window.location.search).get(
                          'school'
                        ) || ''
                    }
                  },
                  false,
                  false,
                  entity
                )
              )
            } else {
              if (event === constants.UPDATED) {
                const params = new URLSearchParams(window.location.search)

                if (
                  params.get('editMode') === '1' &&
                  !isEmpty(params.get('uuid'))
                ) {
                  if (window.history) {
                    // remove query params from url after update
                    window.history.pushState(
                      null,
                      '',
                      `${window.location.origin}${window.location.pathname}`
                    )
                  }
                }
              }

              let queries = {}
              if (event === constants.UPDATED) {
                queries = JSON.parse(localStorage.getItem('filterQueries'))
                queries.queries = {
                  ...queries.queries,
                  offset:
                    parseInt(
                      JSON.parse(localStorage.getItem(constants.PAGE_NO))
                    ) * 25
                }
              } else if (event === constants.CREATED)
                eventEmitter.emit(constants.REMOVE_ALL_FILTERS)
              yield put(
                mappingData.action.fetch({ ...queries }, false, false, entity)
              ) // passing no query
            }
          }
        } catch (error) {
          console.log(error)
        }
      } else if (event === constants.REMOVED) {
        eventAlert(constants.DATA_DELETED, constants.SUCCESS)
        eventEmitter.emit(constants.REMOVE_SELECTED_ROWS)
      }
    } catch (error) {
      console.log(error)

      if ([constants.CREATED, constants.UPDATED].indexOf(event) > -1) {
        eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      }

      eventAlert(error.message, constants.ERROR)
    }
  }
}
