import { isEmpty, orderBy } from 'lodash'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const standardRequestStructure = (data) => ({
  name: data.name
})

const fetchStandard = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.standard, queries)

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: orderBy(response.data.results, 'age', 'asc')
    }
  } catch (error) {
    console.log('error in fetching standard :- ', error.message)
    throw error
  }
}

const createStandard = async (data) => {
  try {
    const response = await post(
      apiEndpoints.standard,
      standardRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating standard :- ', error.message)
    throw error
  }
}

const updateStandard = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.standard}/${data.uuid}/`,
      standardRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in updating standard :- ', error.message)
    throw error
  }
}

const deleteStandard = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.standard}/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting standard :- ', error.message)
    throw error
  }
}

export { fetchStandard, createStandard, updateStandard, deleteStandard }
