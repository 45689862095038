import { isEmpty } from 'lodash'

import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const trialSessionsRequestStructure = (data) => ({
  student_name: data.student_name,
  student_phone_number: data.student_phone_number,
  parent_name: data.parent_name,
  parent_phone_number: data.parent_phone_number,
  course_name: data.course_name,
  created: data.created,
  start_date: data.start_date,
  teacher: data.teacher,
  explore_slot: data.explore_slot
})

const fetchTrialSessions = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.trialSessions, {
        ...queries,
        batch_type: 'explore',
        desc: true
      })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching enrolment')
    throw error
  }
}

const createTrialSessions = async (data) => {
  const requestUrl = makeRequestUrl(apiEndpoints.trialSessions, {
    batch_type: 'explore'
  })

  try {
    const response = await post(requestUrl, trialSessionsRequestStructure(data))

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating enrolment')
    throw error
  }
}

const updateTrialSessions = async (data) => {
  validateUpdateRequest(data)

  try {
    const queries = trialSessionsRequestStructure(data)

    const response = await patch(
      `${apiEndpoints.trialSessions}/${data.uuid}/`,
      {
        ...queries,
        batch_type: 'explore'
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating enrolment')
    throw error
  }
}

const deleteTrialSessions = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.trialSessions}/${id}`)
      })
    )

    return ids
  } catch (error) {
    sentryLogger(error, 'error in deleting enrolment')
    throw error
  }
}

const changeTrialSessionsBatch = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.ohClasses}/change_enrolment_batch`,
      {
        enrolment_uuid: data.enrolment_uuid,
        batch_uuid: data.batch_uuid
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in changing batch for enrolment')
    throw error
  }
}

const terminateTrialSessions = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.ohClasses}/terminate_enrolment`,
      {
        enrolment_uuid: data.enrolment_uuid,
        amount_to_charge: data.amount_to_charge,
        notes: data.notes
        // reason: data.reason
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in terminate enrolment')
    throw error
  }
}

const getTrialSessions = async ({ url, queries, batchId } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.batch}/${batchId}/`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in getting enrolment')
    throw error
  }
}

export {
  fetchTrialSessions,
  createTrialSessions,
  updateTrialSessions,
  deleteTrialSessions,
  changeTrialSessionsBatch,
  terminateTrialSessions,
  getTrialSessions
}
