import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { styled } from '@material-ui/styles'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { isEmpty } from 'lodash'
import React from 'react'

import { centreScheduleBatchTypes, programsMap } from 'app/config'

import { batchTypesColorList, scheduleColors } from '../config'
import * as styles from '../styles'

dayjs.extend(isToday)

const BorderLinearProgress = styled(LinearProgress)((props) => {
  return {
    height: '1vh!important',
    borderRadius: 5,
    width: '100%',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        props.iscolorenabled === 'true' ? 'var(--oh-red)' : 'var(--oh-gray)'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: 'var(--oh-teal)'
    }
  }
})

export const Card = (props) => {
  const { value, index, iscolorenabled } = props
  const isShowcase = !isEmpty(value?.showcase_schedule)
  const reservedCount = value?.reserved_count
  const attendedCount = value?.attended_count

  let teacher
  if (
    programsMap.includes(value?.batch?.type) &&
    !isEmpty(value?.schedule_activities)
  ) {
    if (value?.schedule_activities.length === 1) {
      teacher = value?.schedule_activities[0]?.teacher?.user?.full_name
    } else {
      teacher = value?.schedule_activities?.reduce((prev, curr, currIndex) => {
        prev += curr?.teacher?.user?.full_name
          ? (currIndex > 0 ? ' & ' : '') + `${curr?.teacher?.user?.full_name}`
          : ''
        return prev
      }, '')
    }
  } else {
    teacher = value?.batch?.teacher?.full_name
  }
  const percentageEnrolments = attendedCount / reservedCount

  return (
    <styles.CardContainer key={index}>
      <styles.CardHeader
        batchTypeColor={
          batchTypesColorList[iscolorenabled ? 'disabled' : value?.batch?.type]
        }
      >
        <styles.Room>
          <styles.Time>
            <styles.Icon>
              <AccessTimeIcon fontSize="inherit" />
            </styles.Icon>
            <styles.Boldtext>
              {dayjs('1/1/1 ' + value?.start_time).format('h:mmA')} -{' '}
              {dayjs('1/1/1 ' + value?.end_time).format('h:mmA')}
            </styles.Boldtext>
          </styles.Time>
        </styles.Room>
        <styles.Mediumheading>
          {centreScheduleBatchTypes[value?.batch?.type]}
        </styles.Mediumheading>
      </styles.CardHeader>
      <styles.CardBody>
        <styles.Info>
          <styles.Subtitle>
            Class{' '}
            {value?.batch?.course?.standards
              ?.map((std) =>
                std.name.split(' ')[0] === 'Class'
                  ? std.name.split(' ')[1]
                  : std.name
              )
              .join(', ')}
          </styles.Subtitle>
          <styles.Name color={scheduleColors[index]}>
            {value?.batch?.course?.subjects?.map((b) => b.name).join(' &  ')}
            {isShowcase ? ' showcase' : null}
          </styles.Name>
          {
            <styles.Subheading>
              {teacher ? `with ${teacher}` : null}
            </styles.Subheading>
          }
        </styles.Info>
        {value?.cancelled ? (
          <styles.Boldheading>{`session cancelled`}</styles.Boldheading>
        ) : (
          <styles.Attendance>
            <styles.Mediumheading>
              {`attendance -  ${attendedCount} / ${reservedCount}`}
            </styles.Mediumheading>
            <BorderLinearProgress
              variant="determinate"
              value={
                reservedCount
                  ? percentageEnrolments * 100 <= 100
                    ? percentageEnrolments * 100
                    : 100
                  : 0
              }
              iscolorenabled={iscolorenabled.toString()}
            />
            <styles.Regularheading>
              {`total enrolments ${reservedCount}`}
            </styles.Regularheading>
          </styles.Attendance>
        )}
      </styles.CardBody>
    </styles.CardContainer>
  )
}

export default Card
