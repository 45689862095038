/* eslint-disable react/prop-types */
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { isEmpty, isNil } from 'lodash'
import React from 'react'

import { fetchUser } from 'api'
import { AutoComplete } from 'app/components/generic/FormFields'
import constants from 'app/constants'

import { DataTable } from './components/DataTable'
import {
  Container,
  FromControl,
  FormLabel,
  SearchBox,
  Typography,
  RedirectCTA,
  Button
} from './styles'

const renderString = (string) => (isNil(string) ? '' : string)

const radioButtons = [
  {
    value: 'phone_number',
    control: <Radio />,
    label: 'Phone'
  },
  {
    value: 'first_name',
    control: <Radio />,
    label: 'First Name'
  },
  {
    value: 'last_name',
    control: <Radio />,
    label: 'Last Name'
  }
]

export const ParentOrChildrenWidget = ({ fieldData, ...props }) => {
  const [searchBy, setSearchBy] = React.useState(radioButtons[0].value)
  const handleRadioButtonChange = (e) => {
    setSearchBy(e.target.value)
  }

  const [data, setData] = React.useState(fieldData?.length ? fieldData : [])

  const autoCompleteProps = {
    async: true,
    multiple: false,
    fetchOnMount: false,
    name: searchBy,
    label: radioButtons.find((radBtn) => radBtn.value === searchBy).label,
    fetchValues: async (searchedText) => {
      try {
        const queries = {
          limit: 1000
        }

        if (!isEmpty(searchedText)) {
          queries[searchBy] = searchedText
        }

        const response = await fetchUser({ queries }, constants.STUDENT)

        return response.results.map((student) => {
          const {
            user: { uuid, full_name, phone_number, date_of_birth },
            grade
          } = student
          return {
            value: uuid,
            name: `${renderString(full_name)} ${renderString(
              phone_number
            )} ${renderString(grade)} ${renderString(date_of_birth)}`,
            raw: student
          }
        })
      } catch (error) {
        console.log('Error in fetching centres :- ', error)
        throw error
      }
    },
    onChange: (_, values) => {
      !isNil(values?.raw) &&
        setData((data) => {
          /* check if user object is already added */
          if (
            data.findIndex(
              ({ user }) => user.uuid === values?.raw?.user?.uuid
            ) > -1
          )
            return data

          return [...data, values?.raw]
        })
    },
    minCharactersToSearch: 4
  }

  return (
    <Container>
      <Typography variant="h5" $css={`margin-bottom: 15px;`}>
        Link Students
      </Typography>
      <SearchBox>
        <FromControl>
          <FormLabel component="legend">Search Existing Students</FormLabel>
          <RadioGroup
            row
            aria-label="student-search-by"
            name="row-radio-buttons-group"
            value={searchBy}
            onChange={handleRadioButtonChange}
          >
            {radioButtons.map((radioButton) => (
              <FormControlLabel key={radioButton.value} {...radioButton} />
            ))}
          </RadioGroup>
          <AutoComplete {...autoCompleteProps} />
        </FromControl>
        <Typography
          variant="h6"
          $css={`flex-basis: 15%;text-align: center; color:rgba(0, 0, 8, 0.5);`}
        >
          OR
        </Typography>
        <RedirectCTA>
          <Button
            variant="outlined"
            href="/user/student?source=parent&openModalForm=1"
            target="_blank"
          >
            Add new student
          </Button>
          <Typography
            $css={`font-size: 14px;margin-left: 2px;color:rgba(0, 0, 8, 0.5);`}
          >
            Search for the student here after adding
          </Typography>
        </RedirectCTA>
      </SearchBox>
      <DataTable data={data} {...props} title="Children" />
    </Container>
  )
}
