import Dayjs from 'dayjs'
import React from 'react'

import { DatePicker, AutoComplete } from 'app/components/generic/FormFields'

const CustomDatePicker = (props) => {
  const today = Dayjs()

  const minDate = today.subtract(18, 'year').format('YYYY-MM-DD')
  const maxDate = today.subtract(3, 'year').format('YYYY-MM-DD')

  const inputProps = { min: minDate, max: maxDate }

  return <DatePicker {...props} inputProps={inputProps} />
}

const ParentNumber = (props) => {
  return <AutoComplete minCharactersToSearch={10} />
}

export const commonUiSchema = {}

export const demoUiSchema = { ...commonUiSchema }

export const classUiSchema = {
  ...commonUiSchema,
  is_discount_in_percentage: {
    'ui:widget': 'radio',
    'ui:options': {
      inline: true
    }
  },
  payment_frequency: {
    'ui:disabled': true
  },
  discount_percentage: {
    'ui:placeholder': 'Discount Percentage (eg. 15)'
  }
}
