export const FETCH_DUE_DATA = 'FETCH_DUE_DATA'
export const FETCH_DUE_DATA_SUCCESS = 'FETCH_DUE_DATA_SUCCESS'

export const FETCH_PAID_DATA = 'FETCH_PAID_DATA'
export const FETCH_PAID_DATA_SUCCESS = 'FETCH_PAID_DATA_SUCCESS'

export const FETCH_DUE_MEMBER_DATA = 'FETCH_DUE_MEMBER_DATA'
export const FETCH_DUE_MEMBER_DATA_SUCCESS = 'FETCH_DUE_MEMBER_DATA_SUCCESS'

export const FETCH_PAID_MEMBER_DATA = 'FETCH_PAID_MEMBER_DATA'
export const FETCH_PAID_MEMBER_DATA_SUCCESS = 'FETCH_PAID_MEMBER_DATA_SUCCESS'

export const FETCH_DUE_RENEWAL_DATA = 'FETCH_DUE_RENEWAL_DATA'
export const FETCH_DUE_RENEWAL_DATA_SUCCESS = 'FETCH_DUE_RENEWAL_DATA_SUCCESS'

export const FETCH_PAID_RENEWAL_DATA = 'FETCH_PAID_RENEWAL_DATA'
export const FETCH_PAID_RENEWAL_DATA_SUCCESS = 'FETCH_PAID_RENEWAL_DATA_SUCCESS'

export const FETCH_CANCELED_MEMBER_DATA = 'FETCH_CANCELED_MEMBER_DATA'
export const FETCH_CANCELED_MEMBER_DATA_SUCCESS =
  'FETCH_CANCELED_MEMBER_DATA_SUCCESS'

export const FETCH_PAUSED_MEMBER_DATA = 'FETCH_PAUSED_MEMBER_DATA'
export const FETCH_PAUSED_MEMBER_DATA_SUCCESS =
  'FETCH_PAUSED_MEMBER_DATA_SUCCESS'

export const FETCH_STUDENT_SUMMARY_DATA = 'FETCH_STUDENT_SUMMARY_DATA'
export const FETCH_STUDENT_SUMMARY_DATA_SUCCESS =
  'FETCH_STUDENT_SUMMARY_DATA_SUCCESS'

export const FETCH_TEACHER_SUMMARY_DATA = 'FETCH_TEACHER_SUMMARY_DATA'
export const FETCH_TEACHER_SUMMARY_DATA_SUCCESS =
  'FETCH_TEACHER_SUMMARY_DATA_SUCCESS'

export const FETCH_SUPERPROVIDER_SUMMARY_DATA =
  'FETCH_SUPERPROVIDER_SUMMARY_DATA'
export const FETCH_SUPERPROVIDER_SUMMARY_DATA_SUCCESS =
  'FETCH_SUPERPROVIDER_SUMMARY_DATA_SUCCESS'

export const FETCH_INDEPENDENT_SUMMARY_DATA = 'FETCH_INDEPENDENT_SUMMARY_DATA'
export const FETCH_INDEPENDENT_SUMMARY_DATA_SUCCESS =
  'FETCH_INDEPENDENT_SUMMARY_DATA_SUCCESS'

export const FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA =
  'FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA'
export const FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA_SUCCESS =
  'FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA_SUCCESS'

export const FETCH_DISPATCH_DATA = 'FETCH_DISPATCH_DATA'
export const FETCH_DISPATCH_DATA_SUCCESS = 'FETCH_DISPATCH_DATA_SUCCESS'

export const UPDATE_PAYMENT_DATA = 'UPDATE_PAYMENT_DATA'
export const UPDATE_PAYMENT_DATA_SUCCESS = 'UPDATE_PAYMENT_DATA_SUCCESS'
