import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock'
import { sanitize } from 'app/utils'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const Interactions = (props) => {
  const { interactionsData } = props

  const LatestInteractionSummary = (
    <styles.InteractionSummaryContainer>
      {interactionsData?.data?.[0]?.latest_interaction_summary?.map(
        (summary, index) => (
          <div key={index}>
            <styles.BoldSpan>{summary?.topic}</styles.BoldSpan>{' '}
            {summary?.summary?.includes('<') ? (
              <div
                className="custom-root"
                dangerouslySetInnerHTML={{ __html: summary.summary }}
              />
            ) : (
              <div>{summary.summary}</div>
            )}
            {index !==
            interactionsData?.data?.[0]?.latest_interaction_summary.length -
              1 ? (
              <br />
            ) : null}
          </div>
        )
      )}
    </styles.InteractionSummaryContainer>
  )

  return !isEmpty(interactionsData?.data) ? (
    <styles.Body>
      <styles.InfoBlock>
        <TitleDescription
          heading="Last Interaction Date"
          description={dayjs(
            interactionsData?.data[0]?.latest_interaction_date
          ).format('DD MMM, YYYY')}
        />
      </styles.InfoBlock>
      <styles.secondInfoBlock>
        <TitleDescription
          heading="Last Interaction Summary"
          description={LatestInteractionSummary}
        />
      </styles.secondInfoBlock>
    </styles.Body>
  ) : (
    <NotFoundBlock message="Oh no! No interactions with parent." />
  )
}
