import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { onConfirmScheduleSave } from 'api/schedules'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { LoadingButton } from 'app/components/generic/LoadingButton'
import { navigationPaths, commonAttributes } from 'app/config'
import constants from 'app/constants'
import { confirm, eventAlert, eventEmitter } from 'app/helpers'
import {
  fetchOpendayScheduleData,
  updateClassData
} from 'app/store/actions/classEntity'
import { validateDateRange, validateNotEmpty } from 'app/validators'

import { columns } from './config'
import * as Styles from './styles'

dayjs.extend(isSameOrBefore)
const OpendaySchedule = () => {
  const opendayScheduleData = useSelector(
    (state) => state.classEntityReducer.opendayScheduleData
  )
  const [isLoading, setIsLoading] = React.useState({})
  const [selectedStartDate, setSelectedStartDate] = React.useState(null)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const schedules = opendayScheduleData?.data
    let scheduleLoader = {}
    if (!isEmpty(schedules)) {
      schedules.map((item) => {
        scheduleLoader[item.uuid] = false
      })
    }
    setIsLoading(scheduleLoader)
  }, [opendayScheduleData])

  const showSlots = React.useCallback(
    (dataIndex) => {
      const scheduleData = opendayScheduleData?.data[dataIndex]
      const centre_uuid = scheduleData?.centre?.uuid
      const centre_name = scheduleData?.centre?.name
      const schedule_uuid = scheduleData?.uuid
      const end_date = scheduleData?.end_date

      window.open(
        `${window.location.origin}${navigationPaths.opendaySlots}?centreUuid=${centre_uuid}&scheduleUuid=${schedule_uuid}&centreName=${centre_name}&endDate=${end_date}&filterResult=1`,
        '_blank'
      )
    },
    [opendayScheduleData]
  )

  const onError = () => {
    eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
  }

  const onScheduleConfirm = async (data) => {
    let loaders = { ...isLoading }
    loaders[data.uuid] = true
    setIsLoading(loaders)
    const response = await onConfirmScheduleSave(data)
    if (response.success) {
      loaders[data.uuid] = false
      setIsLoading(false)
      eventAlert('Schedule confirmed Successfully', constants.SUCCESS)
      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchOpendayScheduleData(
          { ...queries },
          false,
          false,
          constants.OPENDAY_SCHEDULE
        )
      )
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 2),
      {
        name: 'end_date',
        label: 'to date',
        widget: 'datepicker',
        minDate: (data) => {
          return dayjs(data?.start_date).add(1, 'day').format('YYYY-MM-DD')
        },
        widgetFilter: true,
        validate: validateDateRange,
        options: {
          ...commonAttributes.date.options,
          filterKey: 'end_date',
          customBodyRender: (value) => {
            return <div>{value}</div>
          }
        }
      },
      ...columns.slice(2),
      {
        name: 'status',
        label: 'confirmation',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { uuid, status, start_date, end_date, centre, subjects } =
              opendayScheduleData?.data[dataIndex] || {}

            const handleConfirmClick = async () => {
              const data = {
                uuid,
                start_date,
                end_date,
                centre: centre?.uuid,
                subjects: subjects?.map((subject) => subject.uuid) || [],
                status: 'confirmed'
              }

              confirm(
                () => {
                  onScheduleConfirm(data)
                },
                'You will NOT be able to undo the dates once you confirm.',
                onError
              )
            }
            return status === 'confirmed' ? (
              <div>confirmed</div>
            ) : (
              <ButtonGroup>
                <Styles.LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmClick}
                  loading={isLoading[uuid]}
                >
                  {isLoading[uuid] ? 'Processing' : 'Confirm'}
                </Styles.LoadingButton>
              </ButtonGroup>
            )
          }
        }
      },
      {
        name: 'actions',
        label: 'actions',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { status } = opendayScheduleData?.data[dataIndex] || {}
            const isEnabled = status === 'confirmed'
            return (
              <ButtonGroup>
                <Button
                  onClick={() => showSlots(dataIndex)}
                  disabled={!isEnabled}
                >
                  Slots
                </Button>
              </ButtonGroup>
            )
          }
        }
      }
    ]
  }, [opendayScheduleData, isLoading, selectedStartDate])

  return (
    <>
      <BaseEntity
        entity={constants.OPENDAY_SCHEDULE}
        label={constants.OPENDAY_SCHEDULE_LABEL}
        columns={modifiedColumns}
        data={opendayScheduleData}
        fetchData={fetchOpendayScheduleData}
        updateEntityData={updateClassData}
        disableDelete
      />
    </>
  )
}

export default OpendaySchedule
