import { takeEvery, takeLatest } from 'redux-saga/effects'

import {
  fetchEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  fetchEventRegistration,
  createEventRegistration,
  updateEventRegistration,
  deleteEventRegistration
} from 'api'
import constants from 'app/constants'
import {
  fetchEventData,
  fetchEventDataSuccess,
  fetchEventRegistrationData,
  fetchEventRegistrationDataSuccess,
  updateEventDataSuccess
} from 'app/store/actions/eventEntity'
import * as types from 'app/store/types/eventEntity'

import { handleFetch, handleUpdate } from './saga.helper'

const mapping = {
  [constants.EVENT]: {
    api: {
      fetch: fetchEvent,
      create: createEvent,
      update: updateEvent,
      delete: deleteEvent
    },
    action: {
      fetch: fetchEventData,
      fetchSuccess: fetchEventDataSuccess
    }
  },
  [constants.EVENT_REGISTRATION]: {
    api: {
      fetch: fetchEventRegistration,
      create: createEventRegistration,
      update: updateEventRegistration,
      delete: deleteEventRegistration
    },
    action: {
      fetch: fetchEventRegistrationData,
      fetchSuccess: fetchEventRegistrationDataSuccess
    }
  }
}

export function* _fetchEventEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updateEventEntity({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(mappingData, entity, event, value, updateEventDataSuccess)
}

export function* watchFetchEventEntity() {
  yield takeLatest(
    [types.FETCH_EVENT_DATA, types.FETCH_EVENT_REGISTRATION_DATA],
    _fetchEventEntity
  )
}

export function* watchUpdateEventEntity() {
  yield takeEvery(types.UPDATE_EVENT_DATA, _updateEventEntity)
}
