import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchCanceledMemberData } from 'app/store/actions/paymentEntity'

import { columns } from './config'

const Canceled = () => {
  const canceledMemberData = useSelector(
    (state) => state.paymentEntityReducer.canceledMemberData
  )

  // const CustomToolbarElement = (
  //   <div>Total : ₹ {canceledMemberData?.paid ?? 0}</div>
  // )

  return (
    <BaseEntity
      entity={constants.CANCELED_MEMBER}
      label={constants.CANCELED_MEMBER_LABEL}
      fetchData={fetchCanceledMemberData}
      data={canceledMemberData}
      columns={columns}
      // CustomToolbarElement={CustomToolbarElement}
      readOnly
    />
  )
}

export default Canceled
