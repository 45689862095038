import { tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    name: 'created',
    label: 'Date',
    ...tableColumnDefaultOption.options
  },
  {
    name: 'club.name',
    label: 'Club Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'club_name'
    }
  },
  {
    name: 'referral_code.name',
    label: 'Referrer Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'referral_name'
    }
  },
  {
    name: 'referral_code.number',
    label: 'Referrer Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'referral_number'
    }
  },
  {
    name: 'name',
    label: 'Registrant Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registrant_name'
    }
  },
  {
    name: 'number',
    label: 'Registrant Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registrant_number'
    }
  },
  {
    name: 'referral_code.referral_code',
    label: 'Referral Code',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'referral_code'
    }
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'email'
    }
  },
  {
    name: 'school_name',
    label: 'School Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'school_name'
    }
  },
  {
    name: 'standard.name',
    label: 'Standard',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'standard'
    }
  },
  {
    name: 'time_commitment_per_week',
    label: 'Time Commitment',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'time_commitment_per_week'
    }
  }
]
