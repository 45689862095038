import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchPaidRenewalData } from 'app/store/actions/paymentEntity'

import { columns } from './config'

const PaidRenewal = () => {
  const paidRenewalData = useSelector(
    (state) => state.paymentEntityReducer.paidRenewalData
  )

  const CustomToolbarElement = <div>Total : ₹ {paidRenewalData?.paid ?? 0}</div>

  return (
    <BaseEntity
      entity={constants.PAID_RENEWAL}
      label={constants.PAID_RENEWAL_LABEL}
      fetchData={fetchPaidRenewalData}
      data={paidRenewalData}
      columns={columns}
      CustomToolbarElement={CustomToolbarElement}
      readOnly
    />
  )
}

export default PaidRenewal
