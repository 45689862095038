import React from 'react'
import * as styles from './styles'
import TitleDescription from '../../../components/generic/HeadingDescription'

const JourneyStatusCard = (props) => {
    const { data } = props

    return (
        <styles.Card>
            <styles.RowFlexGap>
                {data?.map(item => (
                    <styles.InfoBlock>
                        <TitleDescription heading={item?.heading} description={item?.count} />
                    </styles.InfoBlock>
                ))}
            </styles.RowFlexGap>
        </styles.Card>
    )

}

export default JourneyStatusCard