import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import AttendanceSessions from './ModalComponents/AttendanceModal/AttendanceSessions'
import PastWalletTransactions from './ModalComponents/PastWalletModal/PastWalletTransactions'
import RatingSessions from './ModalComponents/RatingModal/RatingSessions'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const MasterModal = (props) => {
  const classes = useStyles()

  const {
    isModalOpen,
    closeModal,
    onSaveClick,
    action,
    data,
    parentData,
    enrolmentData
  } = props

  const formElement = React.useMemo(() => {
    return action === 'attendance' ? (
      <AttendanceSessions
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={data || {}}
      />
    ) : action === 'classroom experience' ? (
      <RatingSessions
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={data || {}}
      />
    ) : action === 'wallet' ? (
      <PastWalletTransactions
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={data || {}}
        parentData={parentData}
        enrolmentData={enrolmentData}
      />
    ) : null
  }, [
    action,
    classes,
    isModalOpen,
    closeModal,
    data,
    parentData,
    enrolmentData
  ])

  return <>{formElement}</>
}

MasterModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default MasterModal
