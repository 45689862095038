import { Button } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { batchTypes } from 'app/config'

const defaultTeacherImage = require('assets/images/teacher.png')

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  paper: {
    marginTop: 16,
    padding: 16
  },
  profileImageContainer: {
    width: 150,
    height: 150,
    borderRadius: 75,
    backgroundSize: 'cover !important'
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  tearcherName: {
    marginTop: 16
  },
  batchTypeStyle: {
    fontWeight: 'bold'
  }
}))

const BatchTimingCard = (props) => {
  const classes = useStyles()

  const {
    teacherDetail,
    selectedSubjectBatch,
    handleTimingSelect,
    selectedTimingBatch
  } = props

  const batchesElement = React.useMemo(() => {
    return selectedSubjectBatch.map((batch, index) => {
      return (
        <React.Fragment key={index}>
          {index === 0 ? (
            <Grid item xs={6} sm={3} className={classes.centerContainer}>
              <Paper
                className={classes.profileImageContainer}
                style={{
                  background: `url('${
                    !isEmpty(get(batch, 'course.teacher.user.image'))
                      ? get(batch, 'course.teacher.user.image')
                      : defaultTeacherImage
                  }')`
                }}
              ></Paper>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.tearcherName}
              >
                {`${teacherDetail.first_name} ${teacherDetail.last_name}`}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6} sm={3} />
          )}
          <Grid item xs={6} sm={3} className={classes.centerContainer}>
            <Typography variant="subtitle2" gutterBottom>
              <div>
                {get(batch, 'course.subject.name')} -{' '}
                {get(batch, 'course.standard.name')}
              </div>
              <div>Fees: {get(batch, 'course.fee')}</div>
              <div className={classes.batchTypeStyle}>
                Batch Type: {batchTypes[get(batch, 'batch_type')] || ''}
              </div>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.centerContainer}>
            <Typography variant="subtitle2" gutterBottom>
              <FormControl component="fieldset">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleTimingSelect(get(batch, 'uuid'))}
                >
                  view details
                </Button>
              </FormControl>
              <div></div>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.centerContainer}>
            <Typography variant="subtitle2" gutterBottom>
              <div>
                {get(batch, 'course.boards')
                  .map((item) => item.name)
                  .join(' and ')}
              </div>
            </Typography>
          </Grid>
        </React.Fragment>
      )
    })
  }, [
    selectedSubjectBatch,
    handleTimingSelect,
    selectedTimingBatch,
    teacherDetail,
    classes
  ])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          {batchesElement}
        </Grid>
      </Paper>
    </div>
  )
}

BatchTimingCard.propTypes = {
  teacherDetail: PropTypes.object.isRequired,
  selectedSubjectBatch: PropTypes.array.isRequired,
  handleTimingSelect: PropTypes.func.isRequired,
  selectedTimingBatch: PropTypes.object.isRequired
}

export default BatchTimingCard
