import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

export const AttendanceTable = (props) => {
  const { data } = props

  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: '#202020',
            color: '#fff'
          }}
        >
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Start time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Attendance</TableCell>
            <TableCell>Cancelled From</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(data)
            ? data.map(
              (session, index) =>
                session && (
                  <TableRow key={index}>
                    <TableCell>{session?.date}</TableCell>
                    <TableCell>{session?.start_time}</TableCell>
                    <TableCell>{session?.end_time}</TableCell>
                    <TableCell>{session?.attendance}</TableCell>
                    <TableCell>{session?.cancelled_from}</TableCell>
                  </TableRow>
                )
            )
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

AttendanceTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
