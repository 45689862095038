export const FETCH_CLUB_DATA = 'FETCH_CLUB_DATA'
export const FETCH_CLUB_DATA_SUCCESS = 'FETCH_CLUB_DATA_SUCCESS'

export const FETCH_TRIBE_DATA = 'FETCH_TRIBE_DATA'
export const FETCH_TRIBE_DATA_SUCCESS = 'FETCH_TRIBE_DATA_SUCCESS'

export const FETCH_TRIBE_MEMBER_DATA = 'FETCH_TRIBE_MEMBER_DATA'
export const FETCH_TRIBE_MEMBER_DATA_SUCCESS = 'FETCH_TRIBE_MEMBER_DATA_SUCCESS'

export const FETCH_INACTIVE_TRIBE_MEMBER_DATA =
  'FETCH_INACTIVE_TRIBE_MEMBER_DATA'
export const FETCH_INACTIVE_TRIBE_MEMBER_DATA_SUCCESS =
  'FETCH_INACTIVE_TRIBE_MEMBER_DATA_SUCCESS'

export const FETCH_REFERRAL_CODE_DATA = 'FETCH_REFERRAL_CODE_DATA'
export const FETCH_REFERRAL_CODE_DATA_SUCCESS =
  'FETCH_REFERRAL_CODE_DATA_SUCCESS'

export const FETCH_CLUB_REGISTRATION_DATA = 'FETCH_CLUB_REGISTRATION_DATA'
export const FETCH_CLUB_REGISTRATION_DATA_SUCCESS =
  'FETCH_CLUB_REGISTRATION_DATA_SUCCESS'

export const UPDATE_COMMUNITY_DATA = 'UPDATE_COMMUNITY_DATA'
export const UPDATE_COMMUNITY_DATA_SUCCESS = 'UPDATE_COMMUNITY_DATA_SUCCESS'

export const FETCH_WAITLIST_QUEUED_DATA = 'FETCH_WAITLIST_QUEUED_DATA'
export const FETCH_WAITLIST_QUEUED_DATA_SUCCESS =
  'FETCH_WAITLIST_QUEUED_DATA_SUCCESS'

export const FETCH_WAITLIST_TRIBE_SELECTION_DATA =
  'FETCH_WAITLIST_TRIBE_SELECTION_DATA'
export const FETCH_WAITLIST_TRIBE_SELECTION_DATA_SUCCESS =
  'FETCH_WAITLIST_TRIBE_SELECTION_DATA_SUCCESS'

export const FETCH_WAITLIST_CONVERTED_DATA = 'FETCH_WAITLIST_CONVERTED_DATA'
export const FETCH_WAITLIST_CONVERTED_DATA_SUCCESS =
  'FETCH_WAITLIST_CONVERTED_DATA_SUCCESS'

export const FETCH_WAITLIST_OTHERS_DATA = 'FETCH_WAITLIST_OTHERS_DATA'
export const FETCH_WAITLIST_OTHERS_DATA_SUCCESS =
  'FETCH_WAITLIST_OTHERS_DATA_SUCCESS'

export const FETCH_CLASSROOM_MOMENTS_DATA = 'FETCH_CLASSROOM_MOMENTS_DATA'
export const FETCH_CLASSROOM_MOMENTS_DATA_SUCCESS =
  'FETCH_CLASSROOM_MOMENTS_DATA_SUCCESS'

export const FETCH_OPENBOOK_DATA = 'FETCH_OPENBOOK_DATA'
export const FETCH_OPENBOOK_DATA_SUCCESS = 'FETCH_OPENBOOK_DATA_SUCCESS'
