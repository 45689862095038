import { FormControl } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { fetchUser } from 'api'
import { Error } from 'app/components/Error'
import { AutoComplete } from 'app/components/generic/FormFields'
import History from 'app/components/generic/history/history'
import { Loading } from 'app/components/generic/LottieComponents'
import Slider from 'app/components/generic/Slider/slider'
import { indianStatesJson } from 'app/config'
import constants from 'app/constants'

import { SummaryBlock } from './components/SummaryBlock'
import { blocks, dataColumns } from './config'
import {
  fetchActiveStudentsData,
  fetchDropoutStudentsData,
  getTeacherDashboardData,
  fetchStudentAttendanceData
} from './helpers'
import * as styles from './styles'

import { getIcon } from '../ParentDashboard/helpers'

const TeacherDashboard = () => {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [selectedTeacherDetails, setSelectedTeacherDetails] = React.useState({})
  const [summaryData, setSummaryData] = useState(blocks)
  const [isBlockLoading, setIsBlockLoading] = useState(false)
  let globalSummaryData = summaryData

  const resetData = () => {
    setData('active student', [])
    setData('dropouts', [])
    setData('student attendance', [])
  }

  useEffect(() => {
    ;(async () => {
      if (selectedTeacherDetails.uuid) {
        const overallStudentsData = await getTeacherDashboardData(
          selectedTeacherDetails.uuid,
          0
        )

        const [activeStudentsData, dropoutStudentsData, studentAttendanceData] =
          await Promise.all([
            fetchActiveStudentsData(
              selectedTeacherDetails.uuid,
              overallStudentsData
            ),
            fetchDropoutStudentsData(
              selectedTeacherDetails.uuid,
              overallStudentsData
            ),
            fetchStudentAttendanceData(
              selectedTeacherDetails.uuid,
              overallStudentsData
            )
          ])

        setData('active student', activeStudentsData)
        setData('dropouts', dropoutStudentsData)
        setData('student attendance', studentAttendanceData)
        setIsBlockLoading(false)
      } else {
        resetData()
        setIsBlockLoading(false)
      }
    })()
  }, [selectedTeacherDetails.uuid])

  const setData = (type, data) => {
    const icon = getIcon(type)
    const tempSummaryData = [...globalSummaryData]
    const currentIndex = tempSummaryData.findIndex((el) => el.type === type)

    if (currentIndex !== -1) {
      if (tempSummaryData[currentIndex].type === type) {
        tempSummaryData[currentIndex].data = data
        tempSummaryData[currentIndex].icon = icon
        globalSummaryData = tempSummaryData
        return setSummaryData(tempSummaryData)
      }
    } else {
      tempSummaryData.push({
        type: type,
        data: data,
        slider: ['active students', 'dropouts', 'student attendance'].includes(
          type
        ),
        icon: icon
      })
      globalSummaryData = tempSummaryData

      return setSummaryData(tempSummaryData)
    }
  }
  const fetchTeacherDetails = async (searchedText) => {
    setError(null)
    searchedText = searchedText.trim()

    try {
      var numberReg = /^\d+$/
      var aplhaReg = /^[a-zA-Z ]*$/
      var uuidReg =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

      let queries = { limit: 150 }

      if (numberReg.test(searchedText))
        queries = { ...queries, phone_number: searchedText }
      else if (aplhaReg.test(searchedText))
        queries = { ...queries, name: searchedText }
      else if (uuidReg.test(searchedText))
        queries = { ...queries, uuid: searchedText }

      const response = await fetchUser({ queries }, constants.TEACHER)

      if (response.results.length > 0) {
        return response.results.map((item) => {
          const { uuid, full_name, phone_number } = item?.user
          const { user } = item
          setLoading(false)

          return {
            uuid,
            value: uuid,
            name: `${phone_number} ${full_name}`,
            phone_number,
            teacherState: item?.state,
            user
          }
        })
      } else if (response.results.length > 1 && searchedText.length === 10) {
        throw new Error('Multiple user found with same number')
      } else if (response.results.length === 0) {
        setError('Teacher Not Found')
        return []
      } else {
        return []
      }
    } catch (error) {
      setLoading(false)
      console.log('Error in fetching user :- ', error)
      throw error
    }
  }
  const handleTeacherSelection = (name, value) => {
    setIsBlockLoading(true)
    const teacherStateName = value?.teacherState
      ? indianStatesJson[value?.teacherState]
      : ''
    setSelectedTeacherDetails({ ...value, teacherStateName } || {})
    localStorage.setItem('teacher', JSON.stringify(value || {}))
  }

  const formElement = React.useMemo(() => {
    const prevTeacher = JSON.parse(localStorage.getItem('teacher'))

    return (
      <FormControl variant="outlined" fullWidth required={true}>
        <AutoComplete
          async={true}
          name={'user'}
          label="Search Teacher with name or number"
          multiple={false}
          fetchValues={fetchTeacherDetails}
          onChange={handleTeacherSelection}
          initialSelectIndex={0}
          selectOnFocus={true}
          defaultValue={prevTeacher}
          size="small"
          minCharactersToSearch={0}
          fetchOnMount={true}
        />
      </FormControl>
    )
  }, [fetchTeacherDetails])

  return (
    <styles.Container>
      <styles.Header>
        <styles.Dropdown>{formElement}</styles.Dropdown>
      </styles.Header>

      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Loading />
      ) : (
        <styles.Widgets>
          {summaryData?.map((data, index) => {
            return (
              <Slider
                width="50%"
                key={'Slide' + index}
                placeholder={
                  <SummaryBlock
                    keyIndex={'Summary' + index}
                    selectedTeacherDetails={selectedTeacherDetails}
                    summaryData={data}
                    isLoading={isBlockLoading}
                  />
                }
                childComponent={
                  <History
                    type={dataColumns[data.type]?.type}
                    heading={dataColumns[data.type]?.heading}
                    userInfo={selectedTeacherDetails?.user}
                    data={data.data}
                    tableHeadings={dataColumns[data.type]?.tableHeadings}
                    accessors={dataColumns[data.type]?.accessors}
                    readOnly={true}
                    editableDetails={false}
                    addDetails={false}
                    headerData={data.headerData}
                  />
                }
              />
            )
          })}
        </styles.Widgets>
      )}
    </styles.Container>
  )
}

export default TeacherDashboard
