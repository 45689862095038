import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import {
  fetchSubjectCategoryData,
  updateClassData
} from 'app/store/actions/classEntity'

import { columns } from './config'

const SubjectCategory = () => {
  const subjectCategoryData = useSelector(
    (state) => state.classEntityReducer.subjectCategoryData
  )

  return (
    <BaseEntity
      entity={constants.SUBJECT_CATEGORY}
      label={constants.SUBJECT_CATEGORY_LABEL}
      columns={columns}
      data={subjectCategoryData}
      fetchData={fetchSubjectCategoryData}
      updateEntityData={updateClassData}
    />
  )
}

export default SubjectCategory
