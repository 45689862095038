import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { attachTribe } from 'api'
import { fetchTribeViaUniqueCode } from 'api/tribe'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import constants from 'app/constants'
import { eventEmitter, showToast } from 'app/helpers'
import {
  fetchWaitlistTribeSelectionData,
  updateCommunityData
} from 'app/store/actions/communityEntity'

import { columns } from './config'
import TribeSelectionActionForm from './TribeSelectionActionForm'

const Queued = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)
  const [tribes, setTribes] = React.useState({})
  const dispatch = useDispatch()

  const waitlistTribeSelectionData = useSelector(
    (state) => state.communityEntityReducer.waitlistTribeSelectionData
  )

  const openModal = React.useCallback((action, dataIndex = 0) => {
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const chooseTribe = React.useCallback(
    async (dataIndex) => {
      const { unique_code } = waitlistTribeSelectionData.data[dataIndex]
      try {
        fetchTribeViaUniqueCode(unique_code).then((response) => {
          const { other_tribes, recommended_tribes } = response
          setTribes([...other_tribes, ...recommended_tribes])
          openModal(constants.ATTACH_TRIBE, dataIndex)
        })
      } catch (error) {
        console.log(error)
        showToast(constants.ERROR_MESSAGE, constants.ERROR)
      }
    },
    [waitlistTribeSelectionData, tribes, openModal]
  )

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onSaveClick = async (newData, action) => {
    try {
      if (action === constants.ATTACH_TRIBE) {
        const { unique_code } = waitlistTribeSelectionData.data[editIndex]
        await attachTribe(unique_code, newData.tribe_uuid)
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        showToast('Assinged Tribe Successfully', constants.SUCCESS)
        dispatch(
          fetchWaitlistTribeSelectionData(
            {},
            constants.WAITLIST_TRIBE_SELECTION
          )
        )
        closeModal()
      }
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      showToast(error.message, constants.ERROR)
    }
  }

  const CustomToolbarElement = (
    <Button onClick={(e) => openModal(constants.CONVERT_N)}>Convert N</Button>
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => chooseTribe(dataIndex)}
                >
                  Assign Tribe
                </Button>
              </ButtonGroup>
            )
          }
        }
      }
    ]
  }, [chooseTribe])

  return (
    <>
      <BaseEntity
        disableEditButton
        entity={constants.WAITLIST_TRIBE_SELECTION}
        label={constants.WAITLIST_TRIBE_SELECTION_LABEL}
        fetchData={fetchWaitlistTribeSelectionData}
        data={waitlistTribeSelectionData}
        columns={modifiedColumns}
        updateEntityData={updateCommunityData}
        CustomToolbarElement={CustomToolbarElement}
        readOnly
      />
      <TribeSelectionActionForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        tribes={tribes}
      />
    </>
  )
}

export default Queued
