import { TextField, FormControl, FormHelperText } from '@material-ui/core'
import { isEmpty, isNumber } from 'lodash'
import React from 'react'

import { formatAmount } from 'app/utils'

import { SubTitle } from '../../styles'

const style = {
  fontSize: '14px'
}

const FirstTimeFee = ({
  value,
  handleOnChange,
  batchId,
  max,
  isCheckoutMode
}) => {
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (!isNumber(value)) {
      handleOnChange(batchId, 'firstTimeFee', 0)
    }
  }, [])

  const onChange = (e) => {
    const { value } = e.target

    const fee = Number(value)

    if (fee >= 0 && fee <= max) {
      setError(null)
    } else {
      setError('Invalid Fee Amount')
    }

    handleOnChange(batchId, 'firstTimeFee', isEmpty(value) ? value : fee)
  }

  return (
    <>
      {isCheckoutMode ? (
        <SubTitle>
          <small>First Month Fee: </small>
          {formatAmount(value)}
        </SubTitle>
      ) : (
        <FormControl fullWidth size="small" required style={style}>
          <TextField
            variant="standard"
            name={batchId}
            value={value}
            max={max}
            type="number"
            label="First Month Fee"
            onChange={onChange}
            InputLabelProps={{ shrink: true, style }}
            style={style}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}
    </>
  )
}

export { FirstTimeFee }
