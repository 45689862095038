import { isEmpty } from 'lodash'
import React from 'react'

import './Scheduler.css'

const url = process.env.REACT_APP_SCHEDULER_URL

const Scheduler = () => {
  return (
    <>
      {!isEmpty(url) ? (
        <iframe src={url} width="100%" id="scheduler" title="Scheduler" />
      ) : null}
    </>
  )
}

export default Scheduler
