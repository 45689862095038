import React from 'react'
import sanitize from 'sanitize-html'

import {
  tableColumnDefaultOption,
  commonAttributes,
  indianStatesJson
} from 'app/config'
import { validateNotEmpty, validateName, validateMobile } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'club_details.name',
    label: 'Club',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },

  {
    name: 'first_name',
    label: 'First Name',
    editable: true,
    disabledInForm: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'first_name'
    }
  },
  {
    name: 'last_name',
    label: 'Last Name',
    editable: true,
    disabledInForm: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'last_name'
    }
  },
  {
    name: 'phone_number',
    label: 'Student Number',
    editable: true,
    disabledInForm: true,
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'phone_number'
    }
  },
  {
    name: 'standard',
    label: 'Standard',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'standard'
    }
  },
  {
    name: 'email',
    label: 'Email',
    editable: true,
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'email'
    }
  },
  {
    name: 'parent_first_name',
    label: 'Parent First Name',
    editable: true,
    disabledInForm: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_first_name'
    }
  },
  {
    name: 'parent_last_name',
    label: 'Parent Last Name',
    editable: true,
    disabledInForm: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_last_name'
    }
  },
  {
    name: 'parent_number',
    label: 'Parent Number',
    editable: true,
    disabledInForm: true,
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_number'
    }
  },
  {
    name: 'state',
    label: 'State',
    editable: true,
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'state',
      customBodyRender: (item) => {
        return indianStatesJson[item] ?? ''
      }
    }
  },
  {
    name: 'time_slot',
    label: 'Time Slot',
    editable: true,
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'time_slot'
    }
  },
  {
    name: 'school_code',
    label: 'School Code',
    validate: validateName,
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'school_code'
    }
  },
  {
    name: 'invoices',
    label: 'Value',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (item) => {
        return item.length > 0 && `₹ ${item[0].value}`
      }
    }
  }
]
