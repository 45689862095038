import styled, { css } from 'styled-components'

const breakpoint = '1024px'
const shadow = '10px 10px 32px -8px rgb(0 0 0 / 20%)' //'10px 10px 33px -16px rgba(0, 0, 0, 0.75)'

export const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
`

export const Content = styled.div`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: lowercase;

  ${(props) =>
    props.heading &&
    props.type &&
    css`
      font-family: AvenirMedium;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${props.type === 'light'
        ? 'var(--oh-black-secondary)'
        : 'var(--oh-black-primary)'};
    `}

  ${(props) =>
    props.description &&
    props.type &&
    css`
      font-family: AvenirDemi;
      font-size: 14px;
      line-height: 24px;
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : 'var(--oh-black-primary)'};
    `}

    ${(props) =>
    props.subtitle &&
    props.type &&
    css`
      font-family: AvenirMedium;
      font-size: 12px;
      line-height: 20px;
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : 'var(--oh-black-secondary)'};
    `}


    ${(props) =>
    props.size &&
    css`
      font-family: AvenirDemi;
      font-size: ${props.size === 'medium'
        ? '20px'
        : props.size === 'small'
        ? '12px'
        : '24px'};
      line-height: ${props.size === 'medium'
        ? '24px'
        : props.size === 'small'
        ? '16px'
        : '32px'};
    `}

      ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

export const InfoBlock = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  ${(props) =>
    props.flexType &&
    css`
      flex-direction: ${props.flexType};
      align-items: center;
      gap: 4px;
    `}

  ${(props) =>
    props.align &&
    css`
      align-items: ${props.align};
    `}
`
