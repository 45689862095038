import Delete from '@material-ui/icons/Delete'
import { isEmpty, isNil } from '@openhouse-technologies/utils'
import React from 'react'

import CoinIcon from 'assets/images/coin.png'

import * as Styles from './styles'

const CartHeader = ({
  selectedCentreName,
  selectedParentDetails,
  onEmptyCartClick,
  coinSummary
}) => {
  return (
    <Styles.HeaderContainer>
      <Styles.TopContainer>
        <Styles.LeftContainer>
          <Styles.HeadingText>cart</Styles.HeadingText>
          <Styles.SubHeadingText>{selectedCentreName}</Styles.SubHeadingText>
        </Styles.LeftContainer>
        <Styles.RightContainer>
          <Styles.EmptyCartButton onClick={onEmptyCartClick}>
            <Delete fontSize="inherit" />
            empty cart
          </Styles.EmptyCartButton>
        </Styles.RightContainer>
      </Styles.TopContainer>
      <Styles.BottomContainer>
        {!isEmpty(selectedParentDetails) && (
          <>
            <Styles.ParentDetailsText>
              {`${selectedParentDetails?.first_name} ${selectedParentDetails?.last_name}`}
              <Styles.ParentNumber>{` (${selectedParentDetails?.phone_number})`}</Styles.ParentNumber>
            </Styles.ParentDetailsText>
            <Styles.CoinsContainer>
              <Styles.CoinImg src={CoinIcon} />
              <div>{coinSummary?.remaining_coins}</div>
            </Styles.CoinsContainer>
          </>
        )}
      </Styles.BottomContainer>
    </Styles.HeaderContainer>
  )
}

export { CartHeader }
