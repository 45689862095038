import styled from 'styled-components';

export const MessageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-left: 0px;
  max-width: 43%;
  min-width: 43%;
  height: 100vh;
  overflow: hidden;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #ccc;
  padding: 1rem;
  overflow-y: auto;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  z-index: 1;
  position: sticky;
  top: 0;
  min-height: 20vh;
  max-height: 20vh;
`

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeaderText = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
`

export const HeaderDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.25rem;
`

export const PhoneLink = styled.a`
  color: 'colors['oh-orange']';        
  text-decoration: underline;
  cursor: pointer;    
`

export const avatarImageStyles = {
  height: '2.5rem',
  width: '2.5rem',
  borderRadius: '50%',
}

export const avatarTextStyles = {
  width: '2.5rem',
  height: '2.5rem',
  backgroundColor: '#F5A066',
  marginRight: '0.5rem',
  flexShrink: '0'
}

export const PillStyles = {
  border: '1px solid #FFE1D9',
  backgroundColor: 'white'
}