import {
  commonAttributes,
  tableColumnDefaultOption,
  uploadPresets
} from 'app/config'
import constants from 'app/constants'
import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Name',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'description',
    label: 'Description',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    ...commonAttributes.image,
    uploadPreset: uploadPresets[constants.CLUB]
  }
]
