import { isArray } from 'lodash'
import React from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { Container } from './styles'

const DateRangePicker = ({
  name,
  value,
  placeholder,
  onChange,
  selectsRange = true,
  headerWidgetInputType
}) => {
  const [dateRange, setDateRange] = React.useState([null, null])
  const [startDate, endDate] = dateRange

  const onHandleChange = (update) => {
    let dates = update
    if (!isArray(update)) {
      dates = [update]
    }
    setDateRange(dates)
    onChange(name, headerWidgetInputType, {
      startDate: dates[0],
      endDate: dates[dates.length - 1]
    })
  }

  return (
    <Container>
      <DatePicker
        showYearDropdown
        showMonthDropdown
        dateFormat="dd/MM/yyyy"
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        onChange={onHandleChange}
        isClearable={true}
        placeholderText={placeholder}
        value={value}
        headerWidgetInputType={headerWidgetInputType}
        className="custom-date-picker-input"
      />
    </Container>
  )
}

export { DateRangePicker }
