import { useState, useEffect, useRef } from 'react'
import { getAccessToken, showToast } from './helpers'
import { confirm } from './helpers'

export const useSocketManager = () => {

  const serverURL = process.env.REACT_APP_WSS_BASE_URL
  const accessToken = getAccessToken()
  const HEARTBEAT_INTERVAL = 10000
  const PRESENCE_INTERVAL = 5000
  const attemptsRef = useRef(0)

  const [isSocketOpen, setIsSocketOpen] = useState(true)

  const ws = useRef(null)
  const reconnectTimeout = useRef(null)
  const sendHeartBeatMessageTimer = useRef(null)
  const sendPresenceMessageTimer = useRef(null)
  const openEventListeners = useRef([])
  const messageListeners = useRef([])

  const connect = () => {
    ws.current = new WebSocket(
      `${serverURL}/?token=${accessToken}`
    )
    ws.current.onopen = onOpen
    ws.current.onclose = onClose
    ws.current.onmessage = onMessage
    ws.current.onerror = onError
  }

  const onOpen = () => {
    attemptsRef.current = 0;
    setIsSocketOpen(true)
    onOpenListener()
    // showToast('websocket connected', 'success')
    sendHeartBeatMessageTimer.current = window.setTimeout(
      sendHeartBeatMessage,
      HEARTBEAT_INTERVAL
    )
  }

  const onClose = (event) => {
    console.log('WS onClose called')
    setIsSocketOpen(false)
    clearTimers()
    removeSocketListeners()

    if (attemptsRef.current < 3 && window.navigator.onLine) {
      attemptsRef.current += 1
      setTimeout(connect, 2000)
    } else {
      confirm(
        () => window.location.reload(),
        'There seems to have been a connectivity issue. Please try refreshing.',
        () => {
          console.error('WS connectivity issue')
          console.log('WS connectivity issue')
        },
        {
          title: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Refresh now'
        }
      )
      console.error('WebSocket reconnection failed')
    }
  }

  const onMessage = (event) => {
    onMessageListener(event.data)
  }

  const onError = (event) => {
    console.error('Socket error', event)
  }

  const clearTimers = () => {
    clearTimeout(sendHeartBeatMessageTimer.current)
    clearTimeout(sendPresenceMessageTimer.current)
    console.log('WS clear timeouts called')
  }

  const clearPresenceTimer = () => {
    clearTimeout(sendPresenceMessageTimer.current)
    console.log('WS clear presence message timeout called')
  }

  const close = () => {
    console.log('WS closed')
    clearTimers()
    removeSocketListeners()
    if (ws.current) ws.current.close(1000, 'WS Deliberately closed')
    // showToast('websocket closed', 'info')
  }

  const reconnect = () => {
    close()
    connect()
  }

  useEffect(() => {
    console.log('is WS open', isSocketOpen)
  }, [isSocketOpen])


  const sendHeartBeatMessage = () => {
    if (!isSocketOpen) return

    const data = { heartbeat: true }
    sendDataOnSocket(data)
    sendHeartBeatMessageTimer.current = window.setTimeout(
      sendHeartBeatMessage,
      HEARTBEAT_INTERVAL
    )
  }

  const sendPresenceMessage = (uuid) => {
    if (!isSocketOpen) return

    const data = { presence: 'true', channel_id: uuid }

    if (uuid) {
      sendDataOnSocket(data)

      //to clear any previous timers
      if (sendPresenceMessageTimer.current) {
        clearTimeout(sendPresenceMessageTimer.current)
      }

      sendPresenceMessageTimer.current = window.setTimeout(
        () => sendPresenceMessage(uuid),
        PRESENCE_INTERVAL
      )
    }
    else {
      console.log('Unable to send presence. Channel uuid is undefined')
    }
  }

  const sendDataOnSocket = (data) => {
    try {
      if (ws.current) {
        ws.current.send(JSON.stringify(data))
      } else {
        console.log('Socket not created')
      }
    } catch (e) {
      console.error('Socket not open or error sending data', e)
    }
  }

  const addMessageListener = (listener) => {
    messageListeners.current.push(listener)
  }

  const removeMessageListener = (listener) => {
    messageListeners.current = messageListeners.current.filter(
      (item) => item !== listener
    )
  }

  const onMessageListener = (msg) => {
    messageListeners.current.forEach((listener) => listener(msg))
  }

  const addOpenListener = (listener) => {
    openEventListeners.current.push(listener)
  }

  const removeOpenListener = (listener) => {
    openEventListeners.current = openEventListeners.current.filter(
      (item) => item !== listener
    )
  }

  const onOpenListener = () => {
    openEventListeners.current.forEach((listener) => listener())
  }

  const removeSocketListeners = () => {
    if (ws.current) {
      ws.current.onopen = null
      ws.current.onclose = null
      ws.current.onmessage = null
      ws.current.onerror = null
    }
  }

  return {
    isSocketOpen,
    sendDataOnSocket,
    connect,
    close,
    addMessageListener,
    removeMessageListener,
    addOpenListener,
    removeOpenListener,
    sendPresenceMessage,
    clearPresenceTimer
  }
}
