import { isEmpty } from 'lodash'

import constants from 'app/constants'

import { apiEndpoints, validateDeleteRequest } from './common'
import { fetchReferralCode } from './referralCode'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const waitlistQueuedStructure = ({
  clubs,
  first_name,
  last_name,
  phone_number,
  email,
  parent_first_name,
  parent_last_name,
  parent_number,
  school_name,
  time_commitment_per_week,
  referral_code,
  position,
  notes
}) => ({
  club_uuids: clubs,
  first_name,
  last_name,
  phone_number,
  email,
  parent_first_name,
  parent_last_name,
  parent_number,
  school_name,
  time_commitment_per_week,
  referral_code,
  position,
  notes,
  source: 'alfred'
})

const waitlistUpdateRequestStructure = (data) => ({
  notes: data.notes
})

const fetchWaitlistQueued = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.clubRegistrations, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching club :- ', error.message)
    throw error
  }
}

const fetchWaitlistTribeSelection = async ({ url, queries } = {}) => {
  queries = {
    ...queries,
    status: constants.TRIBE_SELECTION
  }
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.waitlist, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching club :- ', error.message)
    throw error
  }
}

const fetchWaitlistConverted = async ({ url, queries } = {}) => {
  queries = {
    ...queries,
    status: constants.CONVERTED
  }
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.waitlist, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching club :- ', error.message)
    throw error
  }
}

const fetchWaitlistOthers = async ({ url, queries } = {}) => {
  queries = {
    ...queries,
    status: constants.OTHERS
  }
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.waitlist, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching club :- ', error.message)
    throw error
  }
}

const getReferralCodeUUID = async (code) => {
  const { results } = await fetchReferralCode()
  return results.filter(({ referral_code }) => referral_code === code)[0]?.uuid
}

const createWaitlist = async (data) => {
  const referralCodeUUID = await getReferralCodeUUID(
    data?.referral_code?.referral_code
  )
  try {
    const response = await post(
      `${apiEndpoints.website}/waitlist/create`,
      waitlistQueuedStructure(data, referralCodeUUID)
    )
    return response.data
  } catch (error) {
    console.log('error in creating club :- ', error.message)
    throw error
  }
}

const convertWaitlistToUUID = async (waitlist_uuid, send_message = false) => {
  const requestUrl = `${apiEndpoints.waitlist}/convert`
  const data = {
    waitlist_uuid,
    send_message
  }
  try {
    const response = await post(requestUrl, data)
    return response.data
  } catch (error) {
    console.log('error in creating club :- ', error.message)
    throw error
  }
}

const convertWaitlistToPosition = async (to_position) => {
  const requestUrl = `${apiEndpoints.waitlist}/convert/top`
  const data = {
    to_position
  }
  try {
    const response = await post(requestUrl, data)
    return response.data
  } catch (error) {
    console.log('error in creating club :- ', error.message)
    throw error
  }
}

const attachTribe = async (unique_code, tribe_uuid) => {
  const requestUrl = `${apiEndpoints.website}/tribe/enrolment/create`
  const data = {
    unique_code,
    tribe_uuid
  }
  try {
    const response = await post(requestUrl, data)
    return response.data
  } catch (error) {
    console.log('error in creating club :- ', error.message)
    throw error
  }
}

const convertRegistration = async (tribe_uuid, registration_request_uuid) => {
  const requestUrl = `${apiEndpoints.clubRegistrationConvert}`
  const data = {
    tribe_uuid,
    registration_request_uuid
  }
  try {
    const response = await post(requestUrl, data)
    return response.data
  } catch (error) {
    console.log('error in creating club :- ', error.message)
    throw error
  }
}

const detachTribe = async (tribe_membership_uuid) => {
  const requestUrl = `${apiEndpoints.tribe}/terminate-membership`
  const data = {
    tribe_membership_uuid
  }
  try {
    const response = await post(requestUrl, data)
    return response.data
  } catch (error) {
    console.log('error in creating club :- ', error.message)
    throw error
  }
}

const deleteWaitlist = async ({ ids }) => {
  try {
    await Promise.all(
      ids.map((id) => remove(`${apiEndpoints.clubRegistrations}/${id}`))
    )

    return ids
  } catch (error) {
    throw error
  }
}

const jumpUpInWaitlist = async (waitlist_uuid) => {
  try {
    const data = {
      waitlist_uuid,
      jump_sign: 'up'
    }
    const response = await post(`${apiEndpoints.waitlist}/jump`, data)
    return response.data
  } catch (error) {
    throw error
  }
}

const jumpDownInWaitlist = async (waitlist_uuid) => {
  try {
    const data = {
      waitlist_uuid,
      jump_sign: 'down'
    }
    const response = await post(`${apiEndpoints.waitlist}/jump`, data)
    return response.data
  } catch (error) {
    throw error
  }
}

const updateWaitlist = async (data) => {
  try {
    const response = await patch(
      `${apiEndpoints.waitlistNotes}/${data.uuid}/`,
      waitlistUpdateRequestStructure(data)
    )
    return response.data
  } catch (error) {
    console.log('error in updating waitlist :- ', error.message)
    throw error
  }
}

export {
  fetchWaitlistQueued,
  fetchWaitlistTribeSelection,
  fetchWaitlistConverted,
  fetchWaitlistOthers,
  createWaitlist,
  deleteWaitlist,
  jumpUpInWaitlist,
  jumpDownInWaitlist,
  convertWaitlistToUUID,
  convertWaitlistToPosition,
  attachTribe,
  convertRegistration,
  detachTribe,
  updateWaitlist
}
