import { apiEndpoints } from './common'

import { post } from '../request'

const headers = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

const uploadInstructions = async (data) => {
  // Create an object of formData
  const formData = new FormData()
  // Update the formData object
  formData.append('instructions', data, data.name)
  try {
    const response = await post(
      `${apiEndpoints.portfolio}/instruction/upload/`,
      formData,
      headers
    )

    return response.data
  } catch (error) {
    throw error
  }
}

const uploadAssignments = async (data) => {
  // Create an object of formData
  const formData = new FormData()
  // Update the formData object
  formData.append('assignments', data, data.name)
  try {
    const response = await post(
      `${apiEndpoints.portfolio}/assignment/upload/`,
      formData,
      headers
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export { uploadInstructions, uploadAssignments }
