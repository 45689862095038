import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import FlashOffIcon from '@material-ui/icons/FlashOff'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateTheme } from 'app/store/actions/app'

const useStyle = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

const ThemeSwitcher = (props) => {
  const currentTheme = useSelector((state) => state.appReducer.theme)
  const dispatch = useDispatch()

  const classes = useStyle()

  const handleThemeChange = (event) => {
    event.preventDefault()

    const newTheme = currentTheme === 'light' ? 'dark' : 'light'
    dispatch(updateTheme(newTheme))

    // save to storage for later use
    localStorage.setItem('theme', newTheme)
  }

  return (
    <IconButton
      onClick={handleThemeChange}
      className={classes.button}
      color={props.color}
    >
      {currentTheme === 'light' ? <FlashOffIcon /> : <FlashOnIcon />}
    </IconButton>
  )
}

ThemeSwitcher.defaultProps = {
  color: 'inherit'
}

ThemeSwitcher.propTypes = {
  color: PropTypes.string.isRequired
}

export default ThemeSwitcher
