import IconButton from '@material-ui/core/IconButton'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { pluralizeString } from '@openhouse-technologies/utils'
import { debounceSearchRender } from 'mui-datatables'
import React from 'react'
import { useSelector } from 'react-redux'
import isNumeric from 'validator/lib/isNumeric'

import { fetchUser } from 'api'
import LoadingProgress from 'app/components/generic/Loading'
import { CustomToolbar } from 'app/components/generic/Table'
import {
  tableColumnDefaultOption,
  targetAudienceSelectionModes
} from 'app/config'
import constants from 'app/constants'
import { computeBulkMessagingUserFiltersQueries } from 'app/helpers'
import { selectUserFilters } from 'app/store/selectors'

import { columns } from './config'
import * as Styles from './styles'

import { tableStyleOverridesOption } from '../../../../../../../config'
import { Table } from '../../../../Table'

const getMuiTheme = () =>
  createTheme({
    overrides: {
      ...tableStyleOverridesOption
    }
  })

const MIN_CHARACTERS_TO_SEARCH = 3
const ROWS_LIMIT = 50

const SelectedUsersBase = ({
  userType,
  data,
  title,
  dispatchUpdateUsers,
  selectionMode
}) => {
  const userFilters = useSelector(selectUserFilters)

  const [search, setSearch] = React.useState(false)
  const [searchedUsers, setSearchedUsers] = React.useState({
    isLoading: false,
    data: []
  })
  const [searchText, setSearchText] = React.useState('')

  React.useEffect(() => {
    if (userType) {
      setSearchedUsers({
        isLoading: false,
        data: []
      })
    }
  }, [userType])

  const onSearchChange = async (value) => {
    const trimmedValue = value?.trim() || ''

    setSearchText(trimmedValue)

    if (trimmedValue?.length >= MIN_CHARACTERS_TO_SEARCH) {
      // set loading state
      setSearchedUsers((state) => ({
        ...state,
        data: [],
        isLoading: true
      }))

      const isSearchByPhone = isNumeric(trimmedValue)

      // fetch data
      try {
        const response = await fetchUser(
          {
            queries: {
              limit: ROWS_LIMIT,
              basic: true,
              ...(isSearchByPhone
                ? { phone_number: trimmedValue }
                : { name: trimmedValue }),
              ...(selectionMode ===
              targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION
                ? computeBulkMessagingUserFiltersQueries(userFilters)
                : null)
            }
          },
          userType
        )

        setSearchedUsers((state) => ({
          ...state,
          isLoading: false,
          data: response.results
        }))
      } catch (error) {
        console.log('Error in fetching users: ', error)
      } finally {
        setSearchedUsers((state) => ({
          ...state,
          isLoading: false
        }))
      }
    }
  }

  const tableOptions = {
    customToolbar: () => (
      <CustomToolbar
        additionalElement={
          <div>
            {pluralizeString(userType, data.length)}{' '}
            {selectionMode ===
            targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION
              ? 'excluded'
              : 'included'}
          </div>
        }
      />
    ),
    search: true,
    searchText,
    rowsPerPage: ROWS_LIMIT,
    serverSide: true,
    onSearchChange,
    searchPlaceholder: 'Search by name or number',
    searchOpen: search,
    onSearchOpen: () => setSearch(true),
    customSearchRender: debounceSearchRender(1000),
    onSearchClose: () => {
      setSearchedUsers({
        isLoading: false,
        data: []
      })
      setSearch(false)
    },
    textLabels: {
      body: {
        noMatch:
          search && searchedUsers.isLoading ? (
            <Styles.LoadingContainer>
              <LoadingProgress />
            </Styles.LoadingContainer>
          ) : (
            'Sorry, there is no data to display'
          )
      }
    }
  }

  const modifiedColumns = [
    ...columns,
    {
      name: '',
      label: search ? 'Add / Remove' : 'Remove',
      options: {
        ...tableColumnDefaultOption,
        customBodyRenderLite: (dataIndex) => {
          const user = search ? searchedUsers.data[dataIndex] : data[dataIndex]

          if (
            search &&
            data.findIndex((item) => item?.user?.uuid === user?.user?.uuid) < 0
          ) {
            return (
              <IconButton
                aria-label="add"
                onClick={() => dispatchUpdateUsers(user, constants.CREATED)}
              >
                <AddIcon />
              </IconButton>
            )
          }

          return (
            <IconButton
              aria-label="delete"
              onClick={() =>
                dispatchUpdateUsers(user?.user?.uuid, constants.REMOVED)
              }
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      }
    }
  ]

  const modifiedData = search ? searchedUsers.data : data

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Styles.TableContainer>
        <Table
          title={title}
          data={modifiedData}
          columns={modifiedColumns}
          tableOptions={tableOptions}
        />
      </Styles.TableContainer>
    </ThemeProvider>
  )
}

export { SelectedUsersBase }
