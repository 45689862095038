import { ThemeProvider } from '@material-ui/core'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  createIndependentDispatch,
  createIndependentEditsDispatch,
  fetchEditsItemTypes,
  fetchSessionsPaymentSummary
} from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import ModalForm from 'app/components/ModalForm'
import { commonAttributes, independantTeacherActions } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter, showToast } from 'app/helpers'
import { fetchIndependentSummaryData } from 'app/store/actions/paymentEntity'
import { formatAmount } from 'app/utils'
import AbsentIcon from 'assets/images/absent.svg'
import PresentIcon from 'assets/images/present.svg'

import { AdminEdits } from './components/AdminEdits'
import { CalculationsTable } from './components/CalculationsTable'
import { DispatchForm } from './components/DispatchForm'
import EditForm from './components/EditForm'
import { columns } from './config'
import * as styles from './styles'

const Independent = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
  const [editIndex, setEditIndex] = React.useState(null)
  const [action, setAction] = React.useState('')
  const [payoutModalOpen, setPayoutModalOpen] = React.useState(false)
  const [adminEditsModalOpen, setAdminEditsModalOpen] = React.useState(false)
  const [payoutData, setPayoutData] = React.useState(null)
  const [editsData, setEditsData] = React.useState('')
  const [subtitle, setSubtitle] = React.useState('')
  const [itemTypes, setItemTypes] = React.useState([])

  const independentSummaryData = useSelector(
    (state) => state.paymentEntityReducer.independentSummaryData
  )
  const dispatch = useDispatch()

  const openModal = React.useCallback((e, action, dataIndex) => {
    e.preventDefault()
    if (action === 'edit') {
      setIsEditModalOpen(true)
    } else setIsModalOpen(true)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
  }

  const closePayoutModal = () => {
    setPayoutModalOpen(false)
    setPayoutData(null)
  }

  const getPayoutData = React.useCallback(
    async (dataIndex) => {
      const id = independentSummaryData.data[dataIndex].uuid
      setPayoutModalOpen(true)
      const res = await fetchSessionsPaymentSummary({
        url: null,
        queries: { uuid: id }
      })
      const data = res.results

      if (!isEmpty(data)) {
        setSubtitle(
          `Total sessions: ${data?.length} , Total amount: ${formatAmount(
            data?.reduce((prev, curr) => prev + curr.payable_amount, 0)
          )}`
        )
        setPayoutData(
          data?.map((session) => {
            return {
              course: session?.schedule?.batch?.course?.name,
              batch_type: session?.schedule?.batch?.type,
              date: dayjs(session?.schedule?.date).format('DD MMM, YY'),
              timing: `${dayjs(`1/1/1 ${session?.schedule?.start_time}`).format(
                'H:mma'
              )} - ${dayjs(`1/1/1 ${session?.schedule?.end_time}`).format(
                'H:mma'
              )}`,
              centre: session?.schedule?.batch?.centre?.name,
              students: `${
                session?.schedule?.reserved_count +
                session?.schedule?.cancelled_count
              } (Total) - ${session?.schedule?.cancelled_count} (Canld.) = ${
                session?.schedule?.reserved_count
              }`,
              // coinsPerSession: '',
              // calculation: '',
              amount: session?.payable_amount,
              teacher_attendance: session?.attended ? (
                <styles.Icon src={PresentIcon} alt="present" />
              ) : (
                <styles.Icon src={AbsentIcon} alt="absent" />
              ),
              teacher_arrival: session.attended ? session?.arrival : ''
            }
          })
        )
      } else {
        setPayoutData([])
      }
    },
    [independentSummaryData]
  )

  const getEditsData = React.useCallback(
    (dataIndex) => {
      const data = independentSummaryData.data[dataIndex]

      if (!isEmpty(data)) {
        setEditsData([
          ...data?.payout_items?.map((session) => {
            return {
              status: session.item_type.status,
              name: session.name,
              value: session.value,
              notes: session.notes
            }
          }),
          {
            status: null,
            name: 'Final Amount',
            value: data.value,
            notes: null
          }
        ])
        setAdminEditsModalOpen(true)
      } else {
        showToast('Oops, Unable to fetch data', constants.ERROR)
      }
    },
    [independentSummaryData]
  )

  const onActionButtonClick = async (e, action, dataIndex) => {
    setAction(action)
    setEditIndex(dataIndex)
    if (action === 'calculation') {
      getPayoutData(dataIndex)
    } else if (action === 'dispatch') {
      openModal(e, action, dataIndex)
    } else if (action === 'approve') {
      onSaveClick({ status: 'approved' }, 'status', dataIndex)
    } else if (action === 'unapprove') {
      onSaveClick({ status: 'pending' }, 'status', dataIndex)
    } else if (action === 'admin_edits') {
      getEditsData(dataIndex)
    } else if (action === 'edit') {
      const allItemTypes = await fetchEditsItemTypes()
      console.log('items', allItemTypes)
      setItemTypes(allItemTypes?.results)
      openModal(e, action, dataIndex)
    } else openModal(e, action, dataIndex)
  }

  const onSaveClick = async (newData, action, dataIndex, data) => {
    try {
      if (action === 'dispatch') {
        await createIndependentDispatch({
          action: 'confirm',
          tax: newData.tax,
          notes: newData.notes,
          mode: newData.mode,
          utr_id: newData.utr_id,
          status: 'dispatched',
          independent_uuid: data.data[dataIndex].uuid
        })
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Dispatched Successfully', constants.SUCCESS)
        closeModal()
      }

      if (action === 'edit') {
        await createIndependentEditsDispatch({
          action: 'edit',
          items: newData.misc_charges,
          independent_uuid: independentSummaryData.data[editIndex].uuid
        })
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Edit Successful', constants.SUCCESS)
        closeEditModal()
      }

      if (action === 'status') {
        await createIndependentDispatch({
          action: 'status',
          status: newData.status,
          independent_uuid: independentSummaryData.data[dataIndex].uuid
        })
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert(
          newData.status === 'approved'
            ? 'Approve Successful'
            : 'Unapprove Successful',
          constants.SUCCESS
        )
        closeModal()
        setAdminEditsModalOpen(false)
      }

      dispatch(
        fetchIndependentSummaryData(
          {},
          false,
          false,
          constants.INDEPENDENT_SUMMARY
        )
      )
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 3),
      {
        name: 'payout_items',
        label: 'Sessions Amount',
        ...commonAttributes.amount,
        options: {
          ...commonAttributes.amount.options,
          filterKey: 'sessions_value',
          customBodyRenderLite: (dataIndex) => {
            const value = independentSummaryData.data[
              dataIndex
            ].payout_items.find((item) => item.item_type.type === 'sessions')
            return value ? (
              <span>
                {`${formatAmount(value.value)} `}
                <a
                  onClick={(e) => {
                    onActionButtonClick(e, 'calculation', dataIndex)
                  }}
                >
                  view details
                </a>
              </span>
            ) : null
          }
        }
      },
      {
        name: 'value',
        label: 'Amount after edit',
        ...commonAttributes.amount,
        options: {
          ...commonAttributes.amount.options,
          filterKey: 'value',
          customBodyRenderLite: (dataIndex) => {
            const value = independentSummaryData.data[dataIndex].value
            return value ? (
              <span>
                {`${formatAmount(value)} `}
                <a
                  onClick={(e) => {
                    onActionButtonClick(e, 'admin_edits', dataIndex)
                  }}
                >
                  view details
                </a>
              </span>
            ) : null
          }
        }
      },
      ...columns.slice(3, columns.length),
      {
        name: 'actions',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const status = independentSummaryData.data[dataIndex].status
            return (
              <ButtonGroup>
                {Object.keys(independantTeacherActions)
                  .filter((el) =>
                    (status === 'pending' && el === 'unapprove') ||
                    ((status === 'approved' || status === 'dispatched') &&
                      el === 'approve')
                      ? false
                      : true
                  )
                  ?.map((action, index) => (
                    <Button
                      onClick={(e) => {
                        onActionButtonClick(e, action, dataIndex)
                      }}
                      key={index}
                      disabled={
                        (status === 'pending' && action === 'dispatch') ||
                        (status === 'approved' && action === 'edit') ||
                        status === 'dispatched'
                      }
                    >
                      {independantTeacherActions[action]}
                    </Button>
                  ))}
              </ButtonGroup>
            )
          }
        }
      }
    ]
  }, [onActionButtonClick])

  return (
    <>
      <BaseEntity
        entity={constants.INDEPENDENT_SUMMARY}
        label={constants.INDEPENDENT_SUMMARY_LABEL}
        data={independentSummaryData}
        columns={modifiedColumns}
        fetchData={fetchIndependentSummaryData}
        readOnly
      />
      <EditForm
        isModalOpen={isEditModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeEditModal}
        action={action}
        data={independentSummaryData}
        editIndex={editIndex}
        itemTypes={itemTypes}
      />
      <ThemeProvider>
        <ModalForm
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={payoutModalOpen}
          onModalClose={closePayoutModal}
          enableSave={false}
          modalTitle="Payout Calculation"
          modalSubtitle={subtitle}
        >
          <CalculationsTable data={payoutData} />
        </ModalForm>
      </ThemeProvider>
      <ThemeProvider>
        <ModalForm
          containerStyles={{ borderRadius: 10, width: '40%' }}
          className="border"
          isModalOpen={adminEditsModalOpen}
          onModalClose={() => setAdminEditsModalOpen(false)}
          enableSave={
            independentSummaryData?.data[editIndex]?.status !== 'dispatched'
          }
          buttonLabel={
            independentSummaryData?.data[editIndex]?.status === 'pending'
              ? 'Approve'
              : 'Unapprove'
          }
          modalTitle="Admin Edits"
          onSaveClick={() =>
            onSaveClick(
              {
                status:
                  independentSummaryData?.data[editIndex]?.status === 'pending'
                    ? 'approved'
                    : 'pending'
              },
              'status',
              editIndex
            )
          }
        >
          <AdminEdits data={editsData} />
        </ModalForm>
      </ThemeProvider>
      <DispatchForm
        isModalOpen={isModalOpen}
        action={action}
        onSaveClick={onSaveClick}
        data={independentSummaryData}
        editIndex={editIndex}
        closeModal={closeModal}
      />
    </>
  )
}

export default Independent
