import { generateUUID } from '@openhouse-technologies/utils'
import { isEmpty, isNil } from 'lodash'

import constants from 'app/constants'
import { sentryLogger } from 'sentry'

import { apiEndpoints, apiURLOrigin, validateUpdateRequest } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, put } from '../request'

const validateData = (value) => {
  if (isNil(value) || isEmpty(value)) {
    return null
  } else if (Array.isArray(value)) {
    return value[0]
  } else {
    return value
  }
}

const userRequestStructure = (data, role) => {
  if (role == constants.STUDENT) {
    const {
      school,
      session_token,
      school_not_found,
      does_not_attend_school,
      temporary_school_name,
      temporary_school_postal_code,
      temporary_school_city,
      user,
      no_phone_number,
      parent,
      standard,
      additional_information
    } = data
    const no_school = does_not_attend_school || school_not_found
    const payload = {
      user: {
        first_name: user.first_name,
        last_name: user.last_name,
        bio: user.bio,
        user_type: 'student',
        state: user.state,
        date_of_birth: validateData(user.date_of_birth),
        image: user.image,
        email_address: user.email_address,
        phone_number: no_phone_number ? null : user.phone_number
      },
      place_id: no_school ? null : school,
      additional_information: additional_information,
      standard: standard.uuid,
      parent: parent,
      additional_information,
      temporary_school_name: school_not_found ? temporary_school_name : null,
      temporary_school_city: school_not_found ? temporary_school_city : null,
      temporary_school_postal_code: school_not_found
        ? temporary_school_postal_code
        : null,
      school_not_found: school_not_found,
      does_not_attend_school: does_not_attend_school,
      session_token: session_token
    }
    return payload
  }

  if (role === constants.SUPERPROVIDER) {
    return {
      uuid: data.uuid,
      name: data.name,
      phone_number: data.phone_number,
      email: data.email,
      postal_address: data.postal_address,
      biography: data.biography,
      online_presence: data.online_presence,
      number_of_centres: data.number_of_centres,
      categories: data.categories,
      dispatch_rate_per_session_ts: data.dispatch_rate_per_session_ts,
      dispatch_rate_per_session_ohs: data.dispatch_rate_per_session_ohs,
      bank_account_holder_name: data.bank_account_holder_name,
      bank_account_number: data.bank_account_number,
      bank_ifsc_code: data.bank_ifsc_code,
      minimum_guarantee: data.minimum_guarantee ? data.minimum_guarantee : 0
    }
  }

  const _data = {
    first_name: data.user.first_name,
    last_name: data.user.last_name,
    phone_number: data.user.phone_number,
    email_address: data.user.email_address,
    address: data.user.address,
    bio: data.user.bio,
    date_of_birth: data.user.date_of_birth,
    gender: data.user.gender,
    image: data.user_image,
    state: data.user.state,
    app_status: data.user.app_status
  }

  if (role === constants.TEACHER) {
    return {
      ..._data,
      state: data.state,
      teacher_type:
        data.teacher_type === 'independent' ? null : data.teacher_type,
      super_provider: data.super_provider || null,
      dispatch_rate_per_session_ohs: data.dispatch_rate_per_session_ohs,
      dispatch_rate_per_session_ts: data.dispatch_rate_per_session_ts,
      subjects_you_teach: data.subjects_you_teach,
      oh_share_ts: data.oh_share_ts,
      oh_share_ohs: data.oh_share_ohs,
      bank_account_holder_name: data.bank_account_holder_name,
      bank_account_number: data.bank_account_number,
      bank_ifsc_code: data.bank_ifsc_code,
      minimum_guarantee: data.minimum_guarantee ? data.minimum_guarantee : 0
    }
  }

  return _data
}

const fetchUser = async ({ url, queries } = {}, role) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.user}/${role}${role === 'admin' ? '_user' : ''}`,
        queries
      )
  try {
    const response = await get(requestUrl)

    if (role === constants.STUDENT) {
      return {
        ...response.data,
        results: response.data.results.map((result) => ({
          ...result,
          no_phone_number: isEmpty(result?.user?.phone_number),
          does_not_attend_school: isEmpty(result?.school)
            ? !result?.does_not_attend_school && !result?.school_not_found
              ? true
              : result?.does_not_attend_school
            : false,
          school_not_found: result?.school_not_found || false,
          temporary_school_name: isEmpty(result?.school)
            ? result?.temporary_school_name
            : null,
          temporary_school_city: isEmpty(result?.school)
            ? result?.temporary_school_city
            : null,
          temporary_school_postal_code: isEmpty(result?.school)
            ? result?.temporary_school_postal_code
            : null,

          session_token: generateUUID()
        }))
      }
    }
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching user')
    throw error
  }
}

const getSingleParent = async (data) => {
  try {
    const response = await get(`${apiEndpoints.user}/parent/${data.id}`)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching parent')
    throw error
  }
}

const createParent = async (data) => {
  data.user.source = data?.user?.source ? data?.user?.source : null
  const payload = {
    ...data,
    students: data.students.length
      ? data.students.map(({ user }) => user.uuid)
      : []
  }

  try {
    const response = await post(`${apiEndpoints.user}/parent`, payload)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating parent')
    throw error
  }
}

const createUser = async (data, role) => {
  if (role === constants.PARENT) return createParent(data)

  try {
    const response = await post(
      `${apiEndpoints.user}/${
        role === constants.ADMIN || role === constants.TEACHER ? 'create_' : ''
      }${role}${
        role === constants.ADMIN
          ? '_user'
          : role === constants.SUPERPROVIDER
          ? '/'
          : ''
      }`,
      userRequestStructure(data, role)
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating user')
    throw error
  }
}

const updateStudentData = async (data) => {
  const {
    school,
    session_token,
    school_not_found,
    does_not_attend_school,
    temporary_school_name,
    temporary_school_postal_code,
    temporary_school_city,
    standard,
    user,
    siblings,
    no_phone_number,
    parent,
    additional_information
  } = data
  const no_school = school_not_found || does_not_attend_school
  const payload = {
    user: {
      first_name: user.first_name,
      last_name: user.last_name,
      bio: user.bio,
      user_type: 'student',
      email_address: validateData(user.email_address),
      phone_number: no_phone_number ? null : user.phone_number,
      state: user.state,
      date_of_birth: validateData(user.date_of_birth),
      address_detail: user.address_detail
    },
    place_id: no_school ? null : school,
    standard: standard.uuid,
    siblings: siblings?.map((el) => el.uuid),
    parent: parent,
    additional_information: additional_information,
    temporary_school_name: school_not_found ? temporary_school_name : null,
    temporary_school_city: school_not_found ? temporary_school_city : null,
    temporary_school_postal_code: school_not_found
      ? temporary_school_postal_code
      : null,
    school_not_found: school_not_found,
    does_not_attend_school: does_not_attend_school,
    session_token: session_token
  }
  try {
    const response = await patch(
      `${apiURLOrigin}/oh_users/student/${user.uuid}/`,
      payload
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating user')
    throw error
  }
}

const updateParentData = async (data) => {
  const payload = {
    ...data,
    user: {
      ...data.user,
      referral_code: null,
      referral_datetime: null,
      referred_by: null,
      gender: null
    },
    students: data.students.length
      ? data.students.map(({ user }) => user.uuid)
      : []
  }

  try {
    const response = await put(
      `${apiURLOrigin}/oh_users/parent/${data.user.uuid}/`,
      payload
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating user')
    throw error
  }
}

const updateSuperproviderData = async (data) => {
  const payload = {
    ...data
  }

  try {
    const response = await put(
      `${apiURLOrigin}/oh_users/superprovider/${data.uuid}/`,
      payload
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating user')
    throw error
  }
}

const getSuperproviderData = async (data) => {
  try {
    const response = await get(
      `${apiURLOrigin}/oh_users/superprovider/${data.uuid}/`
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating user')
    throw error
  }
}

const updateUser = async (data, role) => {
  validateUpdateRequest(role === constants.SUPERPROVIDER ? data : data.user)

  if (role === constants.STUDENT) {
    return updateStudentData(data)
  }

  if (role === constants.PARENT) {
    return updateParentData(data)
  }

  if (role === constants.SUPERPROVIDER) {
    return updateSuperproviderData(data)
  }

  try {
    const response = await post(`${apiURLOrigin}/base/edit_user`, {
      ...userRequestStructure(data, role),
      uuid: data.user.uuid
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating user ')
    throw error
  }
}

const getEnrolmentDetails = (uuid) => {
  return new Promise((resolve, reject) => {
    get(`${apiEndpoints.journeyMentor}/get_subjects?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getEnrolmentDetails')
        reject(error)
      })
  })
}

const getTribeEnrolments = (uuid) => {
  return new Promise((resolve, reject) => {
    get(`${apiEndpoints.journeyMentor}/get_tribes?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data.results)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getEnrolmentDetails')
        reject(error)
      })
  })
}

const getDemoScheduledDetails = (uuid) => {
  return new Promise((resolve, reject) => {
    get(`${apiEndpoints.journeyMentor}/get_demos?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data.results)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getEnrolmentDetails')
        reject(error)
      })
  })
}

const getActivityLogs = (uuid) => {
  return new Promise((resolve, reject) => {
    get(`${apiEndpoints.journeyMentor}/get_activity_log?uuid=${uuid}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getEnrolmentDetails')
        reject(error)
      })
  })
}

const createConversationhistory = async (payload) => {
  return new Promise((resolve, reject) => {
    post(`${apiEndpoints.communication}`, payload)
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching createConversationhistory')
        reject(error)
      })
  })
}

const getSliderDetails = (type, userId, studenId) => {
  return new Promise((resolve, reject) => {
    get(`${apiEndpoints.communication}?receipient=${studenId}`)
      .then((res) => {
        resolve(res.data.results)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getEnrolmentDetails')
        reject(error)
      })
  })
}

const updateConversationhistory = async (uuid, payload) => {
  return new Promise((resolve, reject) => {
    patch(`${apiEndpoints.communication}/${uuid}/`, payload)
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getEnrolmentDetails ')
        reject(error)
      })
  })
}

const createSibling = async (payload) => {
  try {
    const response = await post(`${apiEndpoints.createSibling}`, payload)
    console.log('createSibling res', response)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in addSibling user')
    throw error
  }
}

const addSibling = async (uuid, payload) => {
  try {
    const response = await patch(`${apiEndpoints.student}/${uuid}/`, payload)
    console.log('addSibling res', response)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in addSibling user')
    throw error
  }
}

const updateSibling = (payload) => {
  return new Promise((resolve, reject) => {
    post(`${apiEndpoints.common}/commons/sibling`, payload)
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getEnrolmentDetails')
        reject(error)
      })
  })
}

const getSiblingDetails = (uuid) => {
  return new Promise((resolve, reject) => {
    get(`${apiEndpoints.common}/commons/sibling?student=${uuid}`)
      .then((res) => {
        resolve(res.data.results)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching getSiblingDetails')
        reject(error)
      })
  })
}

const updateStudentUser = (uuid, payload) => {
  return new Promise((resolve, reject) => {
    patch(`${apiEndpoints.student}/${uuid}`, payload)
      .then((res) => {
        resolve(res.data.results)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching updateStudentUser')
        reject(error)
      })
  })
}

const getGradeDetails = () => {
  return new Promise((resolve, reject) => {
    get(`${apiEndpoints.common}/commons/standard?sanitized=true`)
      .then((res) => {
        let obj = {}
        res.data.results.map((el) => {
          obj[el.uuid] = el.name
        })
        resolve(obj)
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching updateStudentUser')
        reject(error)
      })
  })
}

const downloadJMDashBoard = (payload) => {
  return new Promise((resolve, reject) => {
    post(`${apiEndpoints.common}/oh_journey_mentors/csv_generator`, payload)
      .then((res) => {
        window.open(res.data.url, '_blank')
      })
      .catch((error) => {
        sentryLogger(error, 'error in fetching downloadJMDashBoard')
        reject(error)
      })
  })
}

const validateReferral = async (payload) => {
  try {
    const url = apiEndpoints.validateReferral
    const response = await post(`${url}`, payload)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in referral validation')
    throw error
  }
}

export {
  addSibling,
  createConversationhistory,
  createSibling,
  createUser,
  downloadJMDashBoard,
  fetchUser,
  getActivityLogs,
  getDemoScheduledDetails,
  getEnrolmentDetails,
  getGradeDetails,
  getSiblingDetails,
  getSingleParent,
  getSliderDetails,
  getSuperproviderData,
  getTribeEnrolments,
  updateConversationhistory,
  updateSibling,
  updateStudentUser,
  updateUser,
  validateReferral
}
