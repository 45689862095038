import styled, { css } from 'styled-components'

import { categoryColorMap } from '../../constants'

const breakpoint = '1024px'
const shadow = '10px 10px 33px -16px rgba(0, 0, 0, 0.75)'

export const Body = styled.div`
  height: 100%;
  padding: 7px 7px 0px 7px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

export const Content = styled.div`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  ${(props) =>
    props.highlight &&
    css`
      font-weight: 700;
      font-size: 14px;
      order: -1;
      text-transform: uppercase;
    `}

  ${(props) =>
    props.subheading &&
    css`
      font-size: 10px;
      order: -1;
      text-transform: uppercase;
    `}

    ${(props) =>
    props.subContent &&
    css`
      font-size: 14px;
      font-weight: 700;
      order: -1;
    `}

  ${(props) =>
    css`
      ${props.css}
    `}
`

export const SlotsLeft = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.86);
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 1);
  @media only screen and (max-width: ${breakpoint}) {
    display: none;
  }
`

export const Coins = styled.div`
  position: absolute;
  top: 35px;
  right: 10px;
  padding: 2px 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: rgba(0, 0, 0, 1);
  @media only screen and (max-width: ${breakpoint}) {
    display: none;
  }
`

export const Footer = styled.div`
  height: 24px;
  border-radius: 0px 0px 7px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
`
export const Details = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 5px;
  cursor: pointer;

  ${Content} {
    text-decoration: underline;
  }

  span {
    font-size: 16px;
  }

  @media only screen and (max-width: ${breakpoint}) {
    right: unset;
    left: 5px;
  }
`

export const TimeSlots = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${breakpoint}) {
    display: none;
  }
`

export const ExtraInfo = styled.div`
  width: 69%;
`

export const Wrapper = styled.div`
  position: relative;
  width: 192px;
  height: 190px;
  background: ${(props) =>
    categoryColorMap[props?.category]?.secondary ||
    categoryColorMap.fallback.secondary};
  border: 1px solid
    ${(props) =>
      categoryColorMap[props?.category]?.primary ||
      categoryColorMap.fallback.primary};
  color: ${(props) =>
    categoryColorMap[props?.category]?.text || categoryColorMap.fallback.text};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  transition: all 0.5s ease;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    box-shadow: ${shadow};
  }

  ${(props) =>
    props.isSelected &&
    css`
      box-shadow: ${shadow};
    `}

  ${Footer} {
    background: ${(props) =>
      categoryColorMap[props?.category]?.primary ||
      categoryColorMap.fallback.primary};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      background: ${categoryColorMap.disabled?.secondary};
      border: 1px solid ${categoryColorMap.disabled?.primary};
      color: ${categoryColorMap.disabled.text};
      cursor: not-allowed;

      ${Footer} {
        background: ${categoryColorMap.disabled?.primary};
      }
    `}

  .selected {
    position: absolute;
    top: -12px;
    left: -12px;
  }
`
