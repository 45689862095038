import styled, { css } from 'styled-components'
export const NoUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Icon = styled.img`
  height: 20px;
  width: 20px;
`

export const Button = styled.button`
  border: none;
  padding: unset;
  span {
    color: var(--oh-orange);
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
      span {
        color: rgba(0, 0, 8, 0.5);
      }
    `};
`
