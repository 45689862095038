import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const WYSIWYG = React.memo(({ field, form }) => {
  const { name, value } = field
  const { setFieldValue } = form
  const editorRef = useRef(null)

  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(value || '')
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      return EditorState.createWithContent(contentState)
    }
    return EditorState.createEmpty()
  })

  useEffect(() => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    if (value !== html) {
      setFieldValue(name, html)
    }
  }, [editorState, name, setFieldValue, value])

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const styles = {
    height: '25vh'
  }

  return (
    <Editor
      ref={editorRef}
      editorStyle={styles}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      name={name}
    />
  )
})

WYSIWYG.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}

export default WYSIWYG
