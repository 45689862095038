import Switch from '@material-ui/core/Switch'
import React from 'react'

import { commonAttributes, uploadPresets } from 'app/config'
import constants from 'app/constants'

const renderValue = (value) => <span>{value?.name || value}</span>

const renderFormDataValues = {
  getStructuredValues: (data) => {
    const getItemStructure = (item) => {
      return {
        uuid: item.uuid,
        name: item.name
      }
    }
    if (Array.isArray(data)) {
      return data.map((item) => {
        return getItemStructure(item)
      })
    } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
      return [getItemStructure(data)]
    }

    return data.results.map((item) => {
      return getItemStructure(item)
    })
  },
  getFormDataValue: (data) => {
    const getItemStructure = (item) => {
      return item.uuid
    }
    return getItemStructure(data)
  }
}

const defaultModalOptions = {
  viewOnlyInModal: true,
  modalEdit: true,
  multiple: false
}

export const getColumns = (paperData, onChangeVisibility) => [
  {
    ...commonAttributes.uuid
  },
  {
    // subject
    ...commonAttributes.subject,
    ...renderFormDataValues,
    ...defaultModalOptions,
    options: {
      ...commonAttributes.subject.options,
      customBodyRender: (value) => renderValue(value)
    }
  },
  {
    // standard
    ...commonAttributes.standard,
    ...defaultModalOptions,
    options: {
      ...commonAttributes.standard.options,
      customBodyRender: (value) => renderValue(value)
    }
  },
  {
    // board
    ...commonAttributes.boards,
    ...renderFormDataValues,
    ...defaultModalOptions,
    name: 'board',
    resultStructure: {
      keyName: 'board',
      keyValue: 'uuid'
    },
    options: {
      ...commonAttributes.boards.options,
      filterKey: 'board',
      customBodyRender: (value) => renderValue(value)
    }
  },
  {
    // visible on website
    name: 'visibility_on_website',
    label: 'Visible on website',
    disabledInForm: true,
    options: {
      disableFilter: true,
      customBodyRenderLite: (dataIndex) => {
        const data = paperData.data[dataIndex]
        return (
          <Switch
            key={data.uuid}
            defaultChecked={data.visibility_on_website}
            color="primary"
            onChange={(event) => onChangeVisibility(data, event.target.checked)}
          />
        )
      }
    }
  },
  {
    ...commonAttributes.image,
    ...defaultModalOptions,
    name: 'thumbnail',
    label: 'Thumbnail',
    uploadPreset: uploadPresets[constants.MOCK_TEST_THUMBNAIL]
  },
  {
    ...defaultModalOptions,
    name: 'sample_paper',
    label: 'PDF file',
    uploadPreset: uploadPresets[constants.MOCK_TEST_PDF],
    widget: 'fileupload',
    contentType: 'link'
  }
]
