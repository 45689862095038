import React from 'react'

import CartCloseArrowIcon from 'assets/images/close_cart_arrow.svg'

import * as Styles from './styles'
const CartFooter = ({ closeSlider }) => {
  return (
    <Styles.FooterContainer>
      <Styles.CloseIcon src={CartCloseArrowIcon} onClick={closeSlider} />
    </Styles.FooterContainer>
  )
}

export { CartFooter }
