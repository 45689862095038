import PropTypes from 'prop-types'
import React from 'react'
import { getStatus } from '../../helpers'
import * as styles from './styles'

export const AdminEdits = (props) => {
  const { data } = props

  return (
    <styles.ColumnFlexGap>
      {data.map((val, index) => {
        return (
          <styles.ColumnFlexStart key={'edits' + index}>
            <styles.RowFlexSpace>
              <styles.Heading>{val.name}</styles.Heading>
              <styles.Description>{`${val.value ? getStatus(val.status) : ''} ${val.value}`}</styles.Description>
            </styles.RowFlexSpace>
            <styles.Body>{val.notes ? `Notes: ${val.notes}` : ''}</styles.Body>
          </styles.ColumnFlexStart>
        )
      })
      }
    </styles.ColumnFlexGap>
  )
}

AdminEdits.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
