import constants from 'app/constants'
import { deletePropertyFromObject } from 'app/utils'

import * as types from '../types/demoScheduling'

const handleDemoScheduling = (state, stateName, action) => {
  if (action.event === constants.CREATED) {
    return {
      ...state,
      [stateName]: {
        ...state[stateName],
        [action.data.batchId]: {
          ...state[stateName][action.data.batchId],
          [action.value.uuid]: action.value
        }
      }
    }
  } else if (action.event === constants.REMOVED) {
    return {
      ...state,
      [stateName]: {
        ...state[stateName],
        [action.data.batchId]: deletePropertyFromObject(
          action.value.uuid,
          state[stateName][action.data.batchId]
        )
      }
    }
  }

  return state
}

const initialState = {
  demoSchedulingData: {},
  demoSchedulingUserData: {}
}

const demoSchedulingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_DEMO_SCHEDULING_DATA:
      return handleDemoScheduling(state, 'demoSchedulingData', action)

    case types.RESET_DEMO_SCHEDULING_DATA:
      return {
        ...state,
        demoSchedulingData: {}
      }

    case types.UPDATE_DEMO_SCHEDULING_USER_DATA:
      return {
        ...state,
        demoSchedulingUserData: action.value
      }

    default:
      return state
  }
}

export { demoSchedulingReducer }
