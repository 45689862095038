import styled from 'styled-components'

export const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .MuiDivider-root {
    width: 100%;
  }
`

export const InfoBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;
`
export const secondInfoBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`
export const InteractionSummaryContainer = styled.div`
  margin-top: 2px;
  font-family: AvenirMedium;
`
export const BoldSpan = styled.span`
  font-family: AvenirDemi;
  border-radius: 100px;
  background: #ffe1d9;
  color: #ef5c34;
  padding: 1px 12px;
  max-width: fit-content;
  font-weight: 700;
`
