import React, { isValidElement } from 'react'

import * as styles from './styles'

export const TitleDescription = (props) => {
  const { heading, description, size, color, flexType, subtitle, align } = props

  return (
    <styles.Body>
      <styles.InfoBlock flexType={flexType} align={align}>
          <styles.Content heading type="light">
            {heading}
          </styles.Content>
          <styles.Content description type="dark" size={size} color={color}>
            {description}
          </styles.Content>
          {subtitle && (
            <styles.Content subtitle type="medium">
              {subtitle}
            </styles.Content>
        )}
      </styles.InfoBlock>
    </styles.Body>
  )
}
