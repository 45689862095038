import { Checkbox, TextField } from '@material-ui/core'
import { numberWithCommas } from '@openhouse-technologies/utils'
import React from 'react'

import CoinIcon from 'assets/images/coin.png'

import * as Styles from '../styles'

const BookWithRupees = ({
  activeSummary,
  useCoins,
  setUseCoinsHandler,
  disableUseCoins = true,
  reasonForUsingAlfred,
  setReasonForUsingAlfredHandler,
  reasonError,
  showReasonField
}) => {
  const handleReasonChange = (event) => {
    const value = event.target.value
    setReasonForUsingAlfredHandler(value)
  }

  return (
    <>
      <Styles.ContentContainer>
        <Styles.CartValue>
          <span>cart value</span>
          <Styles.CoinsContainer>
            <Styles.TotalAmount>
              <Styles.CoinImg src={CoinIcon} alt="Coin" />
              {activeSummary?.cost_price}
            </Styles.TotalAmount>
          </Styles.CoinsContainer>
        </Styles.CartValue>
        <Styles.Subheading>payment summary</Styles.Subheading>
        <Styles.PaymentSummary>
          {!disableUseCoins && (
            <Styles.SummaryItemEnd>
              <Styles.CheckboxContainer>
                <Checkbox
                  color="#EF5C34"
                  checked={useCoins}
                  onChange={(e) => setUseCoinsHandler(e.target.checked)}
                />
                <span>Use coin balance</span>
              </Styles.CheckboxContainer>
            </Styles.SummaryItemEnd>
          )}
          <Styles.SummaryItem>
            <span>cart value</span>
            <span>₹{numberWithCommas(activeSummary?.cost_price)}</span>
          </Styles.SummaryItem>
          {useCoins && (
            <>
              <Styles.SummaryItem>
                <span>coin balance</span>
                <Styles.CoinsContainer>
                  <Styles.CoinImg src={CoinIcon} />
                  <div style={{ color: 'red' }}>
                    -{numberWithCommas(activeSummary?.coin_balance)}
                  </div>
                </Styles.CoinsContainer>
              </Styles.SummaryItem>
              <Styles.Separator></Styles.Separator>

              <Styles.SummaryItem>
                <span>subtotal</span>
                <span>₹{numberWithCommas(activeSummary?.sub_total)}</span>
              </Styles.SummaryItem>
            </>
          )}
          <Styles.SummaryItem>
            <span>18% gst</span>
            <span>₹{numberWithCommas(activeSummary?.tax_value)}</span>
          </Styles.SummaryItem>
          <Styles.TotalPayable>
            <span>total payable</span>
            <span>₹{numberWithCommas(activeSummary?.total_payable)}</span>
          </Styles.TotalPayable>
        </Styles.PaymentSummary>
      </Styles.ContentContainer>
      {showReasonField ? (
        <TextField
          name="reason_for_using_alfred"
          label="Reason for using Alfred instead of the App"
          variant="outlined"
          fullWidth
          required
          value={reasonForUsingAlfred}
          onChange={handleReasonChange}
          error={!!reasonError}
          helperText={reasonError}
          style={{ paddingBottom: '10px' }}
        />
      ) : null}
    </>
  )
}

export default BookWithRupees
