import styled from 'styled-components'

export const CartItemsContainer = styled.div`
  padding-top: 16px;
`

export const StudentGroup = styled.div`
  margin-bottom: 24px;
`

export const StudentName = styled.div`
  font-family: AvenirDemi;
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
`

export const StudentClass = styled.span`
  font-family: Avenir;
  font-size: 14px;
  color: #666;
  margin-left: 8px;
`
