import dayjs from 'dayjs'
import React from 'react'

import { commonAttributes, tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    ...commonAttributes.teacher,
    name: 'teacher.user',
    label: 'Name',
    options: {
      ...commonAttributes.teacher.options,
      filterKey: 'teacher_name'
    }
  },
  {
    name: 'teacher.user.phone_number',
    label: 'Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_phone_number'
    }
  },
  {
    name: 'start_date',
    label: 'Cycle',
    options: {
      disableFilter: true,
      customBodyRender: (value) => (
        <span>{`${(value && dayjs(value).format('MMMM, YY')) || ''}`}</span>
      )
    }
  }
]
