import React from 'react'

import { tableColumnDefaultOption, commonAttributes } from 'app/config'
import { validateNotEmpty, validateName, validateMobile } from 'app/validators'

export const columns = [
  {
    name: 'position',
    label: 'Position',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'position'
    }
  },
  {
    ...commonAttributes.club,
    name: 'clubs',
    label: 'Clubs',
    formDataKey: 'clubs',
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item?.uuid,
          name: item?.name
        }
      }
      if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    resultStructure: {
      keyName: 'clubs',
      keyValue: 'uuid'
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item?.uuid
      }

      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    multiple: true,
    options: {
      ...commonAttributes.club.options,
      customBodyRender: (value) => {
        return (
          <span>
            {Array.isArray(value) && value.map((item) => item.name).join(', ')}
          </span>
        )
      }
    }
  },
  {
    name: 'first_name',
    label: 'First Name',
    editable: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'first_name'
    }
  },
  {
    name: 'last_name',
    label: 'Last Name',
    editable: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'last_name'
    }
  },
  {
    name: 'email',
    label: 'Email',
    editable: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'email'
    }
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
    editable: true,
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'phone_number'
    }
  },
  {
    name: 'parent_first_name',
    label: 'Parent First Name',
    validate: validateName,
    editable: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_first_name'
    }
  },
  {
    name: 'parent_last_name',
    label: 'Parent Last Name',
    validate: validateName,
    editable: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_last_name'
    }
  },
  {
    name: 'parent_number',
    label: 'Parent Number',
    editable: true,
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_number'
    }
  },
  {
    name: 'school_name',
    label: 'School Name',
    validate: validateName,
    editable: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'school_name'
    }
  },

  {
    name: 'time_commitment_per_week',
    label: 'Time Commitment Per Week',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'time_commitment_per_week'
    }
  },
  {
    name: 'my_referral_code.referral_code',
    label: 'My Referral Code',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'my_referral_code'
    }
  },
  {
    name: 'referral_code_used.referral_code',
    label: 'Referral Code Used',
    formDataKey: 'referral_code',
    editable: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'referral_code_used'
    }
  },
  {
    name: 'unique_code',
    label: 'Unique Code',
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'created',
    label: 'Registration Date',
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'notes',
    label: 'Notes',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'notes'
    }
  },
  {
    name: 'jump',
    label: 'Jump',
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'source',
    label: 'Source',
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'status',
    label: 'Status',
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'eta',
    label: 'ETA',
    disabledInForm: true,
    ...tableColumnDefaultOption
  }
]
