import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'

import { logoutStart } from 'app/store/actions/auth'

const useStyle = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

const Logout = (props) => {
  const dispatch = useDispatch()

  const classes = useStyle()

  const doLogout = () => {
    dispatch(logoutStart())
  }

  return (
    <IconButton
      onClick={doLogout}
      className={classes.button}
      color={props.color}
    >
      <PowerSettingsNewIcon />
    </IconButton>
  )
}

Logout.defaultProps = {
  color: 'inherit'
}

Logout.propTypes = {
  color: PropTypes.string.isRequired
}

export default Logout
