import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import * as styles from './styles';

const IconNavigationButton = (props) => {
    const navigationLink = props?.navigationLink
    const iconUrl = props?.iconUrl

    return (
        <IconButton
            component={Link}
            to={navigationLink}>
            <styles.Icon src={iconUrl} />
        </IconButton>
    );
}

export default IconNavigationButton