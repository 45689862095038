import { colors } from '@material-ui/core'
import { styled as muiStyled } from '@mui/material/styles'

import { LoadingButton as LoadingButtonComponent } from 'app/components/generic/LoadingButton'

export const LoadingButton = muiStyled(LoadingButtonComponent)(({ theme }) => {
  return {
    backgroundColor: '#F3C520',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
    fontFamily: 'Avenir',
    textTransform: 'lowercase',
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      backgroundColor: '#d5d5d5'
    }
  }
})
