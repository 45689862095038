import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import MuiAutoComplete from '@material-ui/lab/Autocomplete'
import { isEmpty, isFunction, throttle, isString, isUndefined } from 'lodash'
import React from 'react'

const multipleSelectProps = {
  multiple: true,
  filterSelectedOptions: true
}

const getOptionSelected = (option, value) => option.value === value?.value
const getOptionLabel = (option) => (isString(option) ? option : option.name)

const AutoComplete = React.forwardRef((props, ref) => {
  const [options, setOptions] = React.useState([])
  const [value, setValue] = React.useState(props.multiple ? [] : null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isEmptyResultsOnSearch, setIsEmptyResultsOnSearch] =
    React.useState(false)

  const isValueSelected = !isEmpty(value)

  const {
    defaultValue,
    freeSolo,
    autoSelect,
    className,
    name,
    async,
    fetchValues,
    label,
    minCharactersToSearch,
    placeholder,
    multiple,
    disabled,
    required,
    onChange,
    fetchOnMount,
    initialSelectIndex,
    searchWithEmptyValue,
    clearOnBlur,
    noResultsPlaceholder,
    size,
    initialValue,
    searchText,
    selectOnFocus,
    emptyValue,
    mappedOptions
  } = props

  React.useEffect(() => {
    if (!isEmpty(defaultValue)) {
      setOptions(Array.isArray(defaultValue) ? defaultValue : [defaultValue])

      handleOnChange(
        null,
        multiple && !Array.isArray(defaultValue) ? [defaultValue] : defaultValue
      )
    }
  }, []) // don't put any dependency here

  React.useEffect(() => {
    if (!isEmpty(initialValue) && isEmpty(searchText)) {
      setOptions(Array.isArray(initialValue) ? initialValue : [initialValue])

      handleOnChange(
        null,
        multiple && !Array.isArray(initialValue) ? [initialValue] : initialValue
      )
    }
  }, [initialValue, searchText])

  React.useEffect(() => {
    if (!isEmpty(mappedOptions)) setOptions(mappedOptions)
  }, [mappedOptions])

  const handleFetchValues = React.useCallback(
    async ({ searchedText = '', fetchOnMount = false, paramText = '' }) => {
      if (isFunction(fetchValues)) {
        setIsLoading(true)

        try {
          const results = await fetchValues(paramText || searchedText)
          if (Array.isArray(results)) {
            setOptions(results)

            setIsEmptyResultsOnSearch(
              isEmpty(results) && !isEmpty(paramText || searchedText)
            )

            if (
              paramText ||
              (fetchOnMount &&
                isUndefined(defaultValue) &&
                isUndefined(initialValue) &&
                initialSelectIndex >= 0 &&
                results[initialSelectIndex])
            ) {
              handleOnChange(
                null,
                multiple
                  ? [results[initialSelectIndex]]
                  : results[initialSelectIndex]
              )
            }
          }
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [fetchValues, searchText]
  )

  React.useImperativeHandle(
    ref,
    () => ({
      fetchOptions: () => {
        setValue(multiple ? [] : null)
        handleFetchValues({ fetchOnMount })
      }
    }),
    [handleFetchValues]
  )

  const throttledHandleFetchValues = React.useMemo(
    () => throttle((value) => handleFetchValues({ searchedText: value }), 5000),
    [name, handleFetchValues]
  )

  React.useEffect(() => {
    if (searchText && fetchOnMount) {
      handleFetchValues({ paramText: searchText, fetchOnMount })
    } else if (fetchOnMount) {
      handleFetchValues({ fetchOnMount })
    }
  }, [])

  const handleSearchTextChange = async (event) => {
    const value = event.target.value

    if (
      async &&
      ((searchWithEmptyValue && isEmpty(value)) ||
        value.length >= minCharactersToSearch)
    ) {
      setIsLoading(true)
      await throttledHandleFetchValues(value)
    } else {
      setIsEmptyResultsOnSearch(false)
    }
  }

  const handleOnChange = (event, values) => {
    setValue(values)
    if (isFunction(onChange)) {
      onChange(name, values)
    }
  }

  const renderInput = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder={
        placeholder && !isValueSelected
          ? placeholder
          : (!async && (disabled || isValueSelected)) ||
            minCharactersToSearch === 0
          ? ''
          : `Type Min ${minCharactersToSearch} Characters`
      }
      size={size || 'medium'}
      variant="outlined"
      onChange={handleSearchTextChange}
      required={required}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="primary" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  )

  return (
    <MuiAutoComplete
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      disabled={disabled}
      value={emptyValue ? null : value}
      onChange={handleOnChange}
      selectOnFocus={selectOnFocus}
      loading={isLoading}
      disableClearable={isLoading}
      disableCloseOnSelect={multiple}
      clearOnBlur={clearOnBlur}
      autoComplete={false}
      className={className}
      freeSolo={freeSolo}
      autoSelect={autoSelect || freeSolo}
      {...(multiple ? multipleSelectProps : null)}
      {...(isEmptyResultsOnSearch
        ? { noOptionsText: noResultsPlaceholder }
        : null)}
    />
  )
})

AutoComplete.defaultProps = {
  minCharactersToSearch: 0,
  multiple: false,
  required: false,
  async: false,
  disableCloseOnSelect: true,
  fetchOnMount: true,
  initialSelectIndex: null,
  searchWithEmptyValue: true,
  clearOnBlur: true,
  freeSolo: false,
  autoSelect: false,
  noResultsPlaceholder: 'No results'
}

export { AutoComplete }
