import styled from 'styled-components'

export const FooterContainer = styled.div`
  margin: 16px;
  margin-top: 0;
  padding-top: 10px;
  border-top: 1px solid #ccc;
`
export const CloseIcon = styled.img`
  cursor: pointer;
`
