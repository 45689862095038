import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

import { cancelSingleSession, getSessions } from 'api'
import Button, { useStyles } from 'app/components/generic/Button'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { confirm, eventAlert, eventEmitter, showToast } from 'app/helpers'

import { SessionsTable } from './SessionsTable'

dayjs.extend(isSameOrAfter)

const CancelSessions = (props) => {
  const {
    isModalOpen,
    closeModal,
    data,
    onActionButtonClick,
    setCancelId,
    isProgramEnrolment = false
  } = props
  const [sessionsData, setSessionsData] = React.useState('')
  const [refresh, setRefresh] = React.useState(false)
  const classes = useStyles()

  const subtitle = `${
    data?.student?.user?.full_name
  } - ${data?.batch?.course?.subjects
    ?.map((item) => item.name)
    .join(', ')} | ${data?.batch?.course?.standards
    ?.map((item) => item.name)
    .join(', ')}`

  const onCancelClick = (e, uuid) => {
    onActionButtonClick(e, 'cancel')
    setCancelId(uuid)
  }

  const sortFunc = (a, b) => {
    if (dayjs(a?.schedule.date).isBefore(dayjs(b?.schedule.date))) return -1
    if (dayjs(a?.schedule.date).isAfter(dayjs(b?.schedule.date))) return 1
    return 0
  }

  const getSessionDetails = React.useCallback(() => {
    getSessions({
      queries: isProgramEnrolment
        ? { program_enrolment: data?.uuid }
        : { enrolment: data?.uuid }
    })
      .then((data) => {
        if (!isEmpty(data)) {
          const sessions = data?.results
            ?.filter((el) =>
              dayjs(el?.schedule.date).isAfter(
                dayjs().subtract(1, 'day'),
                'day'
              )
            ) //subtracted day for same day cancelation
            ?.sort(sortFunc)
            ?.map((sessions, index) => {
              return {
                date: dayjs(sessions?.schedule.date).format('DD MMM YY'),
                start_time: dayjs(
                  `${sessions?.schedule.date} ${sessions?.schedule.start_time}`
                ).format('H:mma'),
                end_time: dayjs(
                  `${sessions?.schedule.date} ${sessions?.schedule.end_time}`
                ).format('H:mma'),
                action: sessions?.cancelled ? (
                  'canceled'
                ) : (
                  <Button
                    key={'Cancel' + index}
                    className={classes.button}
                    onClick={(e) => {
                      onCancelClick(e, sessions?.uuid)
                    }}>
                    Cancel
                  </Button>
                )
              }
            })
          setSessionsData(sessions)
        } else {
          showToast('Oops, Unable to fetch data', constants.ERROR)
        }
      })
      .catch((e) => {
        showToast(
          e.message ? e.message : 'Unable to fetch sessions',
          constants.ERROR
        )
      })
  }, [data?.uuid])

  useEffect(() => {
    getSessionDetails()
  }, [data, refresh])

  return (
    <>
      <ModalForm
        containerStyles={{ borderRadius: 10 }}
        className="border"
        isModalOpen={isModalOpen}
        onModalClose={closeModal}
        enableSave={false}
        modalTitle="Cancel Sessions"
        modalSubtitle={subtitle}
        showCloseIcon>
        <SessionsTable data={sessionsData} />
      </ModalForm>
    </>
  )
}

export default CancelSessions
