import { List } from '@mui/material'
import React from 'react'

const RenewEarlyDescription = () => {
  return (
    <div>
      <p>
        When do we <strong> use </strong> Renew Early:
      </p>
      <ol>
        <li>
          Renew Early is used to renew a parent’s membership{' '}
          <strong> before their renewal date.</strong>
        </li>
        <li>
          This will <strong> renew their next membership cycle early.</strong>
        </li>
      </ol>
      <p>
        When to <strong> not use </strong> Renew Early:
      </p>
      <ol>
        <li>
          Renew Early cannot be used for a member who has a renewal due invoice
        </li>
        <li>
          On <strong> cancelling </strong> the renewal due invoice from payment
          > renewal > due page, their membership cycle will extend by 1 month
          extra
          <strong>
            (Cancel Due Invoice + Renew Early = 2 months extension)
          </strong>
        </li>
        <li>
          Quarterly, Semi Annually and Annual plans will increase by 2 cycles (6
          months, 12, months, 2 years respectively)
        </li>
      </ol>
    </div>
  )
}

export default RenewEarlyDescription
