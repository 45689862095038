import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Select, TextField } from 'app/components/generic/FormElements'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'
import { validateNotEmpty } from 'app/validators'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const TribeSelectionActionForm = (props) => {
  const [formData] = React.useState({
    position: '',
    tribe_uuid: ''
  })

  const classes = useStyles()
  const formRef = React.useRef(null)

  const { isModalOpen, closeModal, onSaveClick, action, tribes } = props

  const handleOnSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current
      try {
        const errors = await validateForm(values)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        } else {
          // form is valid
          try {
            await onSaveClick(values, action)
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [onSaveClick, action])

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {({ errors }) => (
          <Form>
            <FormControl className={classes.formControl} fullWidth>
              {action === constants.ATTACH_TRIBE ? (
                <>
                  <InputLabel>{'Tribe'}</InputLabel>
                  <Select
                    name={'tribe_uuid'}
                    label={'Tribe'}
                    validate={validateNotEmpty({ isRequired: true })}
                  >
                    {tribes.map((item, index) => (
                      <MenuItem key={index} value={item.tribe_uuid}>
                        {`Tribe Name : ${item.tribe_name} |
                          Tribe Leader : ${item.tribe_leader_first_name} ${item.tribe_leader_last_name} |
                          Registrations : ${item.tribe_registration_count} |
                          Empty Seats : ${item.tribe_empty_seats} |
                          Timings: ${item.tribe_timings}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {!isEmpty(errors.tribe_uuid) && (
                    <FormHelperText>{errors.tribe_uuid}</FormHelperText>
                  )}
                </>
              ) : null}
            </FormControl>
          </Form>
        )}
      </Formik>
    )
  }, [action, classes, formData])

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}
    >
      {formElement}
    </ModalForm>
  )
}

TribeSelectionActionForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  tribes: PropTypes.object
}

export default TribeSelectionActionForm
