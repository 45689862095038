import { Box, CircularProgress, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { apiEndpoints } from 'api/common'
import { navigationPaths } from 'app/config'
import { notify } from 'app/helpers'
import { validateName, validateMobile } from 'app/validators'

import { get, post } from '../../../request'

const textFieldItems = [
  {
    name: 'first_name',
    label: 'first name',
    validate: validateName,
    optional: false
  },
  {
    name: 'last_name',
    label: 'last name',
    validate: validateName,
    optional: false
  },
  {
    name: 'parent_first_name',
    label: 'parent first name',
    optional: true,
    validate: validateName
  },
  {
    name: 'parent_last_name',
    label: 'parent last name',
    optional: true,
    validate: validateName
  },
  {
    name: 'parent_phone_number',
    label: 'parent phone number',
    optional: true,
    validate: validateMobile
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  textField: {
    margin: theme.spacing(1)
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'left',
    textTransform: 'lowercase',
    margin: theme.spacing(1)
  }
}))

const DemoSchedulingForm = () => {
  const history = useHistory()

  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [isNewStudent, setIsNewStudent] = React.useState(true)
  const [phoneOptions, setPhoneOptions] = React.useState([])
  const [schoolOptions, setSchoolOptions] = React.useState([])
  const [standardOptions, standardSetOptions] = React.useState([])

  const onPhoneFieldChange = async (value) => {
    const response = await get(
      `${apiEndpoints.getStudentParent}?search=${value}`
    )
    const users = response.data.results

    const getItemStructure = (item) => {
      return {
        uuid: item.user.uuid,
        first_name: item.user.first_name,
        last_name: item.user.last_name,
        phone_number: item.user.phone_number,
        parent: item?.parents[0],
        school: item.school,
        standard: item.standard,
        display: `${item.user.phone_number} - ${item.user.full_name}`
      }
    }
    setPhoneOptions(
      Object.keys(users).map((key) => getItemStructure(users[key]))
    )
  }

  const onStandardFieldChange = async (value) => {
    const response = await get(`${apiEndpoints.standard}?search=${value}`)
    const standards = response.data.results
    const getItemStructure = (item) => {
      return {
        uuid: item.uuid,
        name: item.name
      }
    }
    standardSetOptions(
      Object.keys(standards).map((key) => getItemStructure(standards[key]))
    )
  }

  const onSchoolFieldChange = async (value) => {
    const response = await get(`${apiEndpoints.school}?search=${value}`)
    const schools = response.data.results
    const getItemStructure = (item) => {
      return {
        uuid: item.uuid,
        name: item.name
      }
    }
    setSchoolOptions(
      Object.keys(schools).map((key) => getItemStructure(schools[key]))
    )
  }

  const setFormField = React.useCallback((key, value) => {
    setFormData((data) => {
      return {
        ...data,
        [key]: value
      }
    })
  }, [])

  const setFormErrorField = React.useCallback((key, value) => {
    setFormErrorData((data) => {
      return {
        ...data,
        [key]: value
      }
    })
  }, [])

  const onTextFieldChange = React.useCallback(
    (name, value, validate) => {
      setFormField(name, value)
      const error = validate(value)
      setFormErrorField(name, error || '')
    },
    [setFormField, setFormErrorField]
  )

  const [formData, setFormData] = React.useState({
    phone_number: '',
    first_name: '',
    last_name: '',
    standard: '',
    parent_first_name: '',
    parent_last_name: '',
    parent_phone_number: '',
    school: ''
  })

  const [formErrorData, setFormErrorData] = React.useState({
    phone_number: '',
    first_name: '',
    last_name: '',
    standard: '',
    parent_first_name: '',
    parent_last_name: '',
    parent_phone_number: '',
    school: ''
  })

  const saveForm = React.useCallback(async () => {
    setLoadingSubmit(true)

    const redirectToDemoScheduling = (first_name, last_name, phone_number) => {
      history.push({
        pathname: navigationPaths.demoScheduling,
        search: `?first_name=${first_name}&last_name=${last_name}&phone_number=${phone_number}`
      })
    }

    if (!isNewStudent) {
      const { first_name, last_name, phone_number } = formData
      redirectToDemoScheduling(first_name, last_name, phone_number)
      return
    }

    const validateForm = (form) => {
      const { phone_number, standard } = form

      const validatedForm = {}
      let isValidated = true

      // phone_number
      const phoneNumberError = validateMobile({ isRequired: true })(
        phone_number
      )
      if (phoneNumberError) {
        setFormErrorField('phone_number', phoneNumberError)
        isValidated = false
      } else {
        validatedForm['phone_number'] = phone_number
      }

      if (standard.length === 0) {
        setFormErrorField('standard', 'Please select standard')
        isValidated = false
      } else {
        validatedForm['standard'] = standard
      }

      ;[
        'parent_first_name',
        'parent_last_name',
        'parent_phone_number',
        'school',
        'first_name',
        'last_name'
      ].forEach((item) => {
        if (!formErrorData[item] && form[item].length > 0) {
          validatedForm[item] = form[item]
        } else {
          if (
            ![
              'parent_first_name',
              'parent_last_name',
              'parent_phone_number',
              'school'
            ].includes(item)
          ) {
            setFormErrorField(item, 'Its a required field')
            isValidated = false
          }
        }
      })

      return isValidated && validatedForm
    }

    const validatedForm = validateForm(formData)

    if (validatedForm) {
      try {
        const lsq_param = { create_lsq_lead: true }
        const response = await post(`${apiEndpoints.createStudent}`, {
          ...validatedForm,
          ...lsq_param
        })
        const { success } = response.data
        if (success) {
          const { first_name, last_name, phone_number } = response.data
          redirectToDemoScheduling(first_name, last_name, phone_number)
        }
      } catch (e) {
        notify('Oops!', e.message, 'danger')
      }
    }
    setLoadingSubmit(false)
  }, [formData, isNewStudent, formErrorData, history, setFormErrorField])

  const updateFields = ({ isNew = true, data: studentData }) => {
    setIsNewStudent(isNew)
    if (isNew) {
      setFormData((data) => {
        return {
          ...data,
          first_name: '',
          last_name: '',
          standard: '',
          parent_first_name: '',
          parent_last_name: '',
          parent_phone_number: '',
          school: ''
        }
      })
    } else {
      setFormData((data) => {
        return {
          ...data,
          first_name: studentData?.first_name,
          last_name: studentData?.last_name,
          standard: studentData?.standard?.uuid || '',
          parent_first_name: studentData?.parent?.first_name || '',
          parent_last_name: studentData?.parent?.last_name || '',
          parent_phone_number: studentData?.parent?.phone_number || '',
          school: studentData?.school?.uuid || ''
        }
      })
    }
  }

  const classes = useStyles()

  const titleComponent = React.useMemo(() => {
    return (
      <div className={classes.tableHeader}>
        <Typography variant="h6">book demo</Typography>
      </div>
    )
  }, [classes])

  const phoneForm = React.useMemo(() => {
    let phoneOptionsValue = phoneOptions
    if (formData.phone_number) {
      const value = phoneOptions.find(
        (phoneItem) => phoneItem.phone_number === formData.phone_number
      )
      if (!value) {
        const newValue = { phone_number: formData.phone_number, type: 'new' }
        phoneOptionsValue = [newValue, ...phoneOptions]
      }
    }

    const onChange = (ev, value, reason) => {
      if (reason === 'clear') {
        setFormField('phone_number', '')
        updateFields({ isNew: true })
        setFormErrorField('phone_number', '')
      }
      if (reason === 'select-option') {
        setFormField('phone_number', value ? value?.phone_number : '')
        if (value.type === 'new') {
          updateFields({ isNew: true })
          const error = validateMobile({ isRequired: true })(
            value?.phone_number
          )
          setFormErrorField('phone_number', error || '')
        } else {
          updateFields({ isNew: false, data: value })
        }
      }
    }

    const getOptionLabel = (option) => {
      if (option.type && option.type === 'new') return `${option.phone_number}`
      return `${option.display}`
    }

    const renderInput = (params) => (
      <TextField
        {...params}
        value={formData.phone_number}
        required
        label="Phone"
        variant="outlined"
        onChange={(ev) => {
          if (ev.target.value !== '' || ev.target.value !== null) {
            onPhoneFieldChange(ev.target.value)
            setFormField('phone_number', ev.target.value)
          }
        }}
        error={!!formErrorData['phone_number']}
        helperText={formErrorData['phone_number']}
      />
    )

    return (
      <Autocomplete
        name="phone_number"
        fullWidth
        className={classes.textField}
        getOptionSelected={(option, value) =>
          option.phone_number === value.phone_number
        }
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        options={phoneOptionsValue}
        renderInput={renderInput}
      />
    )
  }, [
    phoneOptions,
    classes.textField,
    formData.phone_number,
    setFormField,
    formErrorData,
    setFormErrorField
  ])

  const standardForm = React.useMemo(() => {
    const name = 'standard'

    const standardValue =
      standardOptions.find((standard) => standard.uuid === formData.standard) ||
      null

    const onChange = (ev, value, reason) =>
      setFormField(name, value ? value?.uuid : '')

    const renderInput = (params) => (
      <TextField
        {...params}
        label="Standard"
        variant="outlined"
        required
        onChange={(ev) => {
          if (ev.target.value !== '' || ev.target.value !== null) {
            onStandardFieldChange(ev.target.value)
          }
        }}
        error={!!formErrorData[name]}
        helperText={formErrorData[name]}
      />
    )

    return (
      <Autocomplete
        disabled={!isNewStudent}
        name={name}
        fullWidth
        value={standardValue}
        className={classes.textField}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        onChange={onChange}
        options={standardOptions}
        renderInput={renderInput}
      />
    )
  }, [
    standardOptions,
    classes.textField,
    formData.standard,
    setFormField,
    isNewStudent,
    formErrorData
  ])

  const schoolForm = React.useMemo(() => {
    const name = 'school'

    const schoolValue =
      schoolOptions.find((school) => school.uuid === formData.school) || null

    const onChange = (ev, value, reason) =>
      setFormField(name, value ? value?.uuid : '')

    const renderInput = (params) => (
      <TextField
        {...params}
        label="School"
        variant="outlined"
        onChange={(ev) => {
          if (ev.target.value !== '' || ev.target.value !== null) {
            onSchoolFieldChange(ev.target.value)
          }
        }}
      />
    )

    return (
      <Autocomplete
        disabled={!isNewStudent}
        name={name}
        fullWidth
        value={schoolValue}
        className={classes.textField}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        onChange={onChange}
        options={schoolOptions}
        renderInput={renderInput}
      />
    )
  }, [
    schoolOptions,
    classes.textField,
    formData.school,
    setFormField,
    isNewStudent
  ])

  const bookDemoButton = React.useMemo(() => {
    let disabled = false
    Object.keys(formErrorData).forEach((formErrorKey) => {
      const error = formErrorData[formErrorKey]
      if (!!error && !isNewStudent) {
        disabled = true
      }
    })
    return (
      <Button
        disabled={loadingSubmit || disabled}
        className={classes.textField}
        color="primary"
        variant="contained"
        onClick={saveForm}
      >
        {loadingSubmit ? <CircularProgress color="primary" /> : 'Book Demo'}
      </Button>
    )
  }, [classes.textField, saveForm, formErrorData, isNewStudent, loadingSubmit])

  const textFieldsForm = React.useMemo(() => {
    return textFieldItems.map((formItem) => {
      const onChange = (ev) => {
        if (ev.target.value !== null) {
          onTextFieldChange(
            formItem.name,
            ev.target.value,
            formItem.validate({ isRequired: !formItem.optional })
          )
        }
      }
      return (
        <TextField
          disabled={!isNewStudent}
          id={formItem.name}
          label={formItem.label}
          required={!formItem.optional}
          value={formData[formItem.name]}
          key={formItem.name}
          fullWidth
          className={classes.textField}
          variant="outlined"
          onChange={onChange}
          error={!!formErrorData[formItem.name]}
          helperText={formErrorData[formItem.name]}
        />
      )
    })
  }, [
    formData,
    formErrorData,
    classes.textField,
    isNewStudent,
    onTextFieldChange
  ])

  const demoForm = React.useMemo(() => {
    return (
      <>
        {phoneForm}
        {standardForm}
        {textFieldsForm}
        {schoolForm}
        {bookDemoButton}
      </>
    )
  }, [textFieldsForm, schoolForm, standardForm, phoneForm, bookDemoButton])

  return (
    <Box className={classes.root}>
      {titleComponent}
      <Box width="100%" maxWidth="800px">
        {demoForm}
      </Box>
    </Box>
  )
}

export default DemoSchedulingForm
