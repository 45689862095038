import React from 'react'

import {
  commonAttributes,
  navigationPaths,
  tableColumnDefaultOption
} from 'app/config'

export const columns = [
  {
    ...commonAttributes.date,
    name: 'cancellation_date',
    label: 'Cancelation Date'
  },
  {
    name: 'user',
    label: 'Parent Name',
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => {
        return value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.phone}`}
            >{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null
      },
      filterKey: 'parent_name'
    }
  },
  {
    name: 'user.phone',
    label: 'Parent Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'parent_phone_number'
    }
  },
  {
    ...commonAttributes.centre,
    name: 'centre.name',
    label: 'Centre',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre'
    }
  },
  {
    name: 'plan',
    label: 'Plan',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'current_plan',
      customBodyRender: (value) => {
        return value ? <span>{value?.name}</span> : null
      }
    }
  },
  // {
  //   name: 'coins_remaining',
  //   label: 'Wallet (before)',
  //   ...tableColumnDefaultOption
  // },
  // {
  //   name: 'user.coin_summary.remaining_coins',
  //   label: 'Wallet (after)',
  //   ...tableColumnDefaultOption
  // },
  // {
  //   name: 'refund_amount',
  //   label: 'Refund amount',
  //   ...tableColumnDefaultOption
  // },
  // {
  //   name: 'due_amount',
  //   label: 'Due amount',
  //   ...tableColumnDefaultOption
  // },
  {
    name: 'cancelled_by',
    label: 'Cancelled by',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'cancelled_by',
      customBodyRender: (value) =>
        value ? <span>{`${value.first_name} ${value.last_name}`}</span> : null
    }
  },
  {
    name: 'cancellation_reason',
    label: 'Reason',
    ...tableColumnDefaultOption
  }
]
