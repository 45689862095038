import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchStudentSummaryData } from 'app/store/actions/paymentEntity'

import { columns } from './config'

const Student = () => {
  const studentSummaryData = useSelector(
    (state) => state.paymentEntityReducer.studentSummaryData
  )

  return (
    <BaseEntity
      entity={constants.STUDENT_SUMMARY}
      label={constants.STUDENT_SUMMARY_LABEL}
      data={studentSummaryData}
      columns={columns}
      fetchData={fetchStudentSummaryData}
      readOnly
    />
  )
}

export default Student
