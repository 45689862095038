import config from '../../common/config'

export const columns = [
  config.studentName,
  config.studentNumber,
  config.centre,
  config.subjects,
  config.teacher,
  config.batchTimings,
  config.startDate,
  config.endDate,
  config.invoiceAmount,
  {
    ...config.link,
    name: 'payment_link',
    label: 'Payment Link'
  }
]
