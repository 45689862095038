import styled from 'styled-components';

export const SelectedCardContainer = styled.div`
  border: ${({ isSelected }) => isSelected ? '2px solid #CEE8FF' : 'none'};
  border-bottom: ${({ isSelected, lastListItem }) =>
    lastListItem
      ? isSelected
        ? '2px solid #CEE8FF'
        : 'none'
      : isSelected
        ? '2px solid #CEE8FF'
        : '1px solid #ccc'
  };
  background-color: ${({ isSelected }) => isSelected ? '#EEF7FF' : 'none'};
  background-color: ${({ isSelected }) => isSelected ? '#EEF7FF' : 'none'};
  border-radius: ${({ isSelected }) => isSelected ? '8px' : 'none'};
`

export const InnerCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.5rem;
  flex-grow: 1;
`

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-right: 1rem;
`

export const Time = styled.div`
  font-size: 12px;
`


export const TextStyles = {
  fontSize: '12px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export const CustomLastMessageTextStyles = {
  fontSize: '12px',
  fontStyle: 'italic',
  color: 'grey'
}