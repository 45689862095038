import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import { DateRangePicker } from 'app/components/generic/DateRangePicker'
import {
  commonAttributes,
  tableColumnDefaultOption,
  uploadPresets
} from 'app/config'
import constants from 'app/constants'
import {
  fetchOpenbookData,
  updateCommunityData
} from 'app/store/actions/communityEntity'
import { validateNotEmpty, validateOpenbookThumbnail } from 'app/validators'

import { columns } from './config'
import { Link } from './styles'

const validExtensions = ['.mp4', '.mov', '.wmv', '.avi']

const Openbook = () => {
  const [expandedRows, setExpandedRows] = React.useState({})
  const toggleRows = (rowsId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowsId]: !prevExpandedRows[rowsId]
    }))
  }
  const openbookData = useSelector(
    (state) => state.communityEntityReducer.openbookData
  )

  const modifiedColumns = React.useMemo(() => {
    const updatedColumns = [
      {
        name: 'body',
        label: 'Caption',
        editable: true,
        optional: true,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          setCellProps: () => ({
            style: {
              minWidth: '400px',
              maxWidth: '400px',
              whiteSpace: 'pre-wrap'
            }
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = openbookData?.data[dataIndex]?.body
            const truncatedData = data?.slice(0, 100)
            const isTruncated = data?.length > truncatedData?.length
            const isExpanded = expandedRows[dataIndex]
            const displayData = isExpanded ? data : truncatedData

            return (
              <div>
                <span>
                  {displayData?.length < 100
                    ? displayData
                    : !isExpanded
                    ? displayData + '...'
                    : displayData}
                  <br />
                  {isTruncated && (
                    <div style={{ textAlign: 'right' }}>
                      <a
                        href="#"
                        onClick={() => toggleRows(dataIndex)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {isExpanded ? 'show less' : 'show more'}
                      </a>
                    </div>
                  )}
                </span>
              </div>
            )
          }
        }
      },
      {
        name: 'media_type',
        label: 'Media Type',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'media_type',
          customBodyRenderLite: (dataIndex) => {
            const files = openbookData?.data[dataIndex]?.media
            return (
              <div>
                {files?.map((item, index) => {
                  //returns comma separated file types incase of multiple files
                  return (
                    <span key={`file${index}`}>
                      {(index ? ', ' : '') + item.type}
                    </span>
                  )
                })}
              </div>
            )
          }
        }
      },
      {
        ...commonAttributes.files,
        name: 'media',
        label: 'Media*',
        validate: validateNotEmpty,
        uploadPreset: uploadPresets[constants.OPENBOOK],
        optional: false,
        options: {
          disableFilter: true,
          customBodyRender: (values) => {
            return (
              <div>
                {values?.map((item, index) => {
                  //returns comma separated file urls incase of multiple files
                  return (
                    <>
                      {index ? <span>,</span> : null}
                      <Link
                        key={`file${index}`}
                        href={item.url}
                        target="_blank"
                      >
                        {'Link ' + (index + 1)}
                      </Link>
                    </>
                  )
                })}
              </div>
            )
          }
        }
      },
      {
        ...commonAttributes.files,
        name: 'media_thumbnail',
        label: 'Media Thumbnail*',
        validate: validateOpenbookThumbnail,
        optional: false,
        uploadPreset: uploadPresets[constants.OPENBOOK],
        conditionalRender: (data) => {
          return validExtensions.includes(
            data['media']?.secure_url?.toLowerCase().slice(-4)
          )
        },
        options: {
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const files = openbookData?.data[dataIndex]?.media
            return (
              <div>
                {files?.map((item, index) => {
                  //returns comma separated file thumbnails incase of multiple files
                  return item.thumbnail ? (
                    <>
                      {index ? <span>,</span> : null}
                      <Link
                        key={`file${index}`}
                        href={item.thumbnail}
                        target="_blank"
                      >
                        {'Link ' + (index + 1)}
                      </Link>
                    </>
                  ) : (
                    //displaying comma separated NA's if thumbnail doesn't exist
                    (index ? ', ' : '') + 'NA'
                  )
                })}
              </div>
            )
          }
        }
      },
      ...columns,
      {
        name: 'created',
        label: 'Posted On',
        widget: 'datepicker',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption,
          headerWidget: DateRangePicker,
          headerWidgetInputType: 'daterange',
          customBodyRenderLite: (dataIndex) => {
            const sessionDate = openbookData.data[dataIndex]?.created
            return <span>{sessionDate?.slice(0, 10)}</span>
          }
        }
      },
      {
        name: 'view_count',
        label: 'Views',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'view_count',
          disableFilter: false,
          customBodyRenderLite: (dataIndex) => {
            const value = openbookData.data[dataIndex]?.view_count
            return <span>{value}</span>
          }
        }
      },
      {
        name: 'likes_count',
        label: 'Likes',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption,
          filterKey: 'likes_count',
          disableFilter: false,
          customBodyRenderLite: (dataIndex) => {
            const value = openbookData.data[dataIndex]?.likes_count
            return <span>{value}</span>
          }
        }
      }
    ]
    return updatedColumns
  }, [openbookData, expandedRows])

  return (
    <BaseEntity
      entity={constants.OPENBOOK}
      label={constants.OPENBOOK}
      columns={modifiedColumns}
      data={openbookData}
      fetchData={fetchOpenbookData}
      updateEntityData={updateCommunityData}
      disableEditButton={true}
      disableDelete={true}
    />
  )
}

export default Openbook
