import React from 'react'
import IconNavigationButton from '../IconNavigationButton'
import * as styles from './styles'


const PageTitleDescription = (props) => {
  const { title, subheading, iconUrl, iconNavigationUrl} = props

  return (
      <styles.Container>
        <IconNavigationButton navigationLink={iconNavigationUrl} iconUrl={iconUrl}/>
        <div>
          <styles.Heading>{title}</styles.Heading>
          <styles.SubHeading>
            {subheading}
          </styles.SubHeading>
        </div>
      </styles.Container>
  )
}

export default PageTitleDescription