import { takeEvery, takeLatest } from 'redux-saga/effects'

import {
  fetchDuePayment,
  fetchPaidPayment,
  fetchDueMemberPayment,
  fetchPaidMemberPayment,
  fetchDueRenewalPayment,
  fetchPaidRenewalPayment,
  fetchCanceledMemberPayment,
  fetchPausedMemberPayment,
  fetchStudentPaymentSummary,
  fetchTeacherPaymentSummary,
  fetchSuperproviderPaymentSummary,
  fetchIndependentPaymentSummary,
  fetchIndependentOngoingPaymentSummary,
  fetchDispatch
} from 'api'
import constants from 'app/constants'
import {
  fetchDueData,
  fetchDueDataSuccess,
  fetchPaidData,
  fetchPaidDataSuccess,
  fetchDueMemberData,
  fetchDueMemberDataSuccess,
  fetchPaidMemberData,
  fetchPaidMemberDataSuccess,
  fetchDueRenewalData,
  fetchDueRenewalDataSuccess,
  fetchPaidRenewalData,
  fetchPaidRenewalDataSuccess,
  fetchCanceledMemberData,
  fetchCanceledMemberDataSuccess,
  fetchPausedMemberData,
  fetchPausedMemberDataSuccess,
  fetchStudentSummaryData,
  fetchStudentSummaryDataSuccess,
  fetchTeacherSummaryData,
  fetchTeacherSummaryDataSuccess,
  fetchSuperproviderSummaryData,
  fetchSuperproviderSummaryDataSuccess,
  fetchIndependentSummaryData,
  fetchIndependentSummaryDataSuccess,
  fetchIndependentOngoingSummaryData,
  fetchIndependentOngoingSummaryDataSuccess,
  fetchDispatchData,
  fetchDispatchDataSuccess,
  updatePaymentDataSuccess
} from 'app/store/actions/paymentEntity'
import * as types from 'app/store/types/paymentEntity'

import { handleFetch, handleUpdate } from './saga.helper'

const mapping = {
  [constants.DUE]: {
    api: {
      fetch: fetchDuePayment
    },
    action: {
      fetch: fetchDueData,
      fetchSuccess: fetchDueDataSuccess
    }
  },
  [constants.PAID]: {
    api: {
      fetch: fetchPaidPayment
    },
    action: {
      fetch: fetchPaidData,
      fetchSuccess: fetchPaidDataSuccess
    }
  },
  [constants.DUE_MEMBER]: {
    api: {
      fetch: fetchDueMemberPayment
    },
    action: {
      fetch: fetchDueMemberData,
      fetchSuccess: fetchDueMemberDataSuccess
    }
  },
  [constants.PAID_MEMBER]: {
    api: {
      fetch: fetchPaidMemberPayment
    },
    action: {
      fetch: fetchPaidMemberData,
      fetchSuccess: fetchPaidMemberDataSuccess
    }
  },
  [constants.DUE_RENEWAL]: {
    api: {
      fetch: fetchDueRenewalPayment
    },
    action: {
      fetch: fetchDueRenewalData,
      fetchSuccess: fetchDueRenewalDataSuccess
    }
  },
  [constants.PAID_RENEWAL]: {
    api: {
      fetch: fetchPaidRenewalPayment
    },
    action: {
      fetch: fetchPaidRenewalData,
      fetchSuccess: fetchPaidRenewalDataSuccess
    }
  },
  [constants.CANCELED_MEMBER]: {
    api: {
      fetch: fetchCanceledMemberPayment
    },
    action: {
      fetch: fetchCanceledMemberData,
      fetchSuccess: fetchCanceledMemberDataSuccess
    }
  },
  [constants.PAUSED_MEMBER]: {
    api: {
      fetch: fetchPausedMemberPayment
    },
    action: {
      fetch: fetchPausedMemberData,
      fetchSuccess: fetchPausedMemberDataSuccess
    }
  },
  [constants.STUDENT_SUMMARY]: {
    api: {
      fetch: fetchStudentPaymentSummary
    },
    action: {
      fetch: fetchStudentSummaryData,
      fetchSuccess: fetchStudentSummaryDataSuccess
    }
  },
  [constants.TEACHER_SUMMARY]: {
    api: {
      fetch: fetchTeacherPaymentSummary
    },
    action: {
      fetch: fetchTeacherSummaryData,
      fetchSuccess: fetchTeacherSummaryDataSuccess
    }
  },
  [constants.SUPERPROVIDER_SUMMARY]: {
    api: {
      fetch: fetchSuperproviderPaymentSummary
    },
    action: {
      fetch: fetchSuperproviderSummaryData,
      fetchSuccess: fetchSuperproviderSummaryDataSuccess
    }
  },
  [constants.INDEPENDENT_SUMMARY]: {
    api: {
      fetch: fetchIndependentPaymentSummary
    },
    action: {
      fetch: fetchIndependentSummaryData,
      fetchSuccess: fetchIndependentSummaryDataSuccess
    }
  },
  [constants.INDEPENDENT_ONGOING_SUMMARY]: {
    api: {
      fetch: fetchIndependentOngoingPaymentSummary
    },
    action: {
      fetch: fetchIndependentOngoingSummaryData,
      fetchSuccess: fetchIndependentOngoingSummaryDataSuccess
    }
  },
  [constants.DISPATCH]: {
    api: {
      fetch: fetchDispatch
    },
    action: {
      fetch: fetchDispatchData,
      fetchSuccess: fetchDispatchDataSuccess
    }
  }
}

export function* _fetchPaymentEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updatePaymentEntity({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(
    mappingData,
    entity,
    event,
    value,
    updatePaymentDataSuccess
  )
}

export function* watchFetchPaymentEntity() {
  yield takeLatest(
    [
      types.FETCH_DUE_DATA,
      types.FETCH_PAID_DATA,
      types.FETCH_DUE_MEMBER_DATA,
      types.FETCH_PAID_MEMBER_DATA,
      types.FETCH_DUE_RENEWAL_DATA,
      types.FETCH_PAID_RENEWAL_DATA,
      types.FETCH_CANCELED_MEMBER_DATA,
      types.FETCH_PAUSED_MEMBER_DATA,
      types.FETCH_STUDENT_SUMMARY_DATA,
      types.FETCH_TEACHER_SUMMARY_DATA,
      types.FETCH_SUPERPROVIDER_SUMMARY_DATA,
      types.FETCH_INDEPENDENT_SUMMARY_DATA,
      types.FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA,
      types.FETCH_DISPATCH_DATA
    ],
    _fetchPaymentEntity
  )
}

export function* watchUpdatePaymentEntity() {
  yield takeEvery(types.UPDATE_PAYMENT_DATA, _updatePaymentEntity)
}
