import * as types from '../types/communicationEntity'

export const fetchBulkMessagingData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_BULK_MESSAGING_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchBulkMessagingDataSuccess = (value, entity) => ({
  type: types.FETCH_BULK_MESSAGING_DATA_SUCCESS,
  value,
  entity
})

export const fetchReportCardData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_REPORT_CARD_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchReportCardDataSuccess = (value, entity) => ({
  type: types.FETCH_REPORT_CARD_DATA_SUCCESS,
  value,
  entity
})

export const handleUpdateSelectedMessageTemplate = (value) => ({
  type: types.HANDLE_UPDATE_SELECTED_MESSAGE_TEMPLATE,
  value
})

export const updateSelectedMessageTemplate = (value) => ({
  type: types.UPDATE_SELECTED_MESSAGE_TEMPLATE,
  value
})

export const handleUpdateMessageTemplateParameter = (event) => ({
  type: types.HANDLE_UPDATE_MESSAGE_TEMPLATE_PARAMETER,
  event
})

export const updateMessageTemplateParameter = (data) => ({
  type: types.UPDATE_MESSAGE_TEMPLATE_PARAMETER,
  data
})

export const handleBulkMessagesCreateValidation = () => ({
  type: types.HANDLE_BULK_MESSAGES_CREATE_VALIDATION
})

export const handleOnBulkMessagesSend = () => ({
  type: types.HANDLE_ON_BULK_MESSAGES_SEND
})

export const handleUpdateUserType = (event) => ({
  type: types.HANDLE_UPDATE_USER_TYPE,
  event
})

export const updateUserType = (value) => ({
  type: types.UPDATE_USER_TYPE,
  value
})

export const updateSendMessageModalState = (value) => ({
  type: types.UPDATE_SEND_MESSAGE_MODAL_STATE,
  value
})

export const updateCreateMessageTemplateModalState = (value) => ({
  type: types.UPDATE_CREATE_MESSAGE_TEMPLATE_MODAL_STATE,
  value
})

export const updateDeliveryStatsModalState = (value) => ({
  type: types.UPDATE_DELIVERY_STATS_MODAL_STATE,
  value
})

export const updateUsers = (value, event) => ({
  type: types.UPDATE_USERS,
  value,
  event
})

export const updateExcludedUsers = (value, event) => ({
  type: types.UPDATE_EXCLUDED_USERS,
  value,
  event
})

export const updateIncludedUsers = (value, event) => ({
  type: types.UPDATE_INCLUDED_USERS,
  value,
  event
})

export const handleFetchUsers = (queries) => ({
  type: types.HANDLE_FETCH_USERS,
  queries
})

export const handleFetchDeliveryStatsData = (id, deliveryType) => ({
  type: types.HANDLE_FETCH_DELIVERY_STATS_DATA,
  id,
  deliveryType
})

export const fetchDeliveryStatsData = (id) => ({
  type: types.FETCH_DELIVERY_STATS_DATA,
  id
})

export const fetchDeliveryStatsDataSuccess = (id, data) => ({
  type: types.FETCH_DELIVERY_STATS_DATA_SUCCESS,
  id,
  data
})

export const updateDeliveryType = (deliveryType) => ({
  type: types.UPDATE_DELIVERY_TYPE,
  deliveryType
})

export const updateFieldErrors = (errors) => ({
  type: types.UPDATE_FIELD_ERRORS,
  errors
})

export const resetBulkMessagingCreationData = () => ({
  type: types.RESET_BULK_MESSAGING_CREATION_DATA
})

export const updateTargetAudienceSelectionMode = (value) => ({
  type: types.UPDATE_TARGET_AUDIENCE_SELECTION_MODE,
  value
})

export const updateBulkMessagingUserFilters = (name, value) => ({
  type: types.UPDATE_BULK_MESSAGING_USER_FILTERS,
  name,
  value
})

export const updateBulkMessagingSendApiProgressStatus = (value) => ({
  type: types.UPDATE_BULK_MESSAGING_SEND_API_PROGRESS_STATUS,
  value
})

export const fetchInteractionHistoryData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_INTERACTION_HISTORY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchInteractionHistoryDataSuccess = (value, entity) => ({
  type: types.FETCH_INTERACTION_HISTORY_DATA_SUCCESS,
  value,
  entity
})

export const updateInteractionHistoryData = (entity, event, value) => ({
  type: types.UPDATE_INTERACTION_HISTORY_DATA,
  entity,
  event,
  value
})

export const updateInteractionHistoryDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_INTERACTION_HISTORY_DATA_SUCCESS,
  entity,
  event,
  value
})

export const fetchTicketData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_TICKETS_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchTicketDataSuccess = (value, entity) => ({
  type: types.FETCH_TICKETS_DATA_SUCCESS,
  value,
  entity
})

export const updateTicketData = (entity, event, value) => ({
  type: types.UPDATE_TICKET_DATA,
  entity,
  event,
  value
})

export const updateTicketDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_TICKET_DATA_SUCCESS,
  entity,
  event,
  value
})

export const fetchStudentListData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_STUDENT_LIST_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchStudentListDataSuccess = (value, entity) => ({
  type: types.FETCH_STUDENT_LIST_DATA_SUCCESS,
  value,
  entity
})

export const fetchTeacherListData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_TEACHER_LIST_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchTeacherListDataSuccesss = (value, entity) => ({
  type: types.FETCH_TEACHER_LIST_DATA_SUCCESS,
  value,
  entity
})

export const fetchChatMessagesData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_CHAT_MESSAGES_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchChatMessagesDataSuccess = (value, entity) => ({
  type: types.FETCH_CHAT_MESSAGES_DATA_SUCCESS,
  value,
  entity
})

export const setChatMessagesHeaderData = (value, entity) => ({
  type: types.SET_CHAT_MESSAGES_HEADER_DATA,
  value,
  entity
})

export const fetchParentListData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PARENT_LIST_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchParentListDataSuccess = (value, entity) => ({
  type: types.FETCH_PARENT_LIST_DATA_SUCCESS,
  value,
  entity
})

export const fetchParentChatMessagesData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PARENT_CHAT_MESSAGES_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchParentChatMessagesDataSuccess = (value, entity) => ({
  type: types.FETCH_PARENT_CHAT_MESSAGES_DATA_SUCCESS,
  value,
  entity
})

export const setParentChatMessagesHeaderData = (value, entity) => ({
  type: types.SET_PARENT_CHAT_MESSAGES_HEADER_DATA,
  value,
  entity
})