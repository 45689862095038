/* eslint-disable react/prop-types */
import { CircularProgress, IconButton } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  border: 0.5px solid #0000008a;
  margin: 2px;
`

export const UploadButton = styled(IconButton)`
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  z-index: 10;
  border-radius: 50%;
  ${(props) =>
    props.success &&
    css`
      background-color: ${green[500]};
      :hover {
        background-color: ${green[700]};
      }
    `};
`

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
`

export const Progress = styled(CircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`
