import React from 'react';
import * as styles from './styles';
import { forwardIcon } from '../constants';

const ProgressDifference = (props) => {
    const { color, before, after } = props;

    return (
        <>
            <styles.Difference color={color}>
                <styles.Text>{before}</styles.Text>
                <styles.Icon src={forwardIcon}/>
                <styles.Text>{after}</styles.Text>
            </styles.Difference>
        </>
    );
};

export default ProgressDifference
