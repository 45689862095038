import { commonAttributes, tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    ...commonAttributes.teacher,
    name: 'user',
    label: 'Name',
    options: {
      ...commonAttributes.teacher.options,
      filterKey: 'name'
    }
  },
  {
    name: 'user.phone_number',
    label: 'Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'phone_number'
    }
  },
  {
    name: 'dispatched_amount',
    label: 'Total Dispatched',
    ...commonAttributes.amount
  },
  {
    name: 'holding_amount',
    label: 'Pending Dispatch',
    ...commonAttributes.amount
  }
]
