import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 1em;
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Heading = styled(Typography)`
  font-size: 2.25em;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 1em;
`

export const ItemsContainer = styled.div`
  flex: 0.5;
`
