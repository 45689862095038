import dayjs from 'dayjs'
import React from 'react'

import {
  statusMap,
  commonAttributes,
  navigationPaths,
  studentSourceTypes,
  tableColumnDefaultOption,
  programList
} from 'app/config'
import { sanitize } from 'app/utils'
import { validateName, validateNotEmpty, validateFee } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid,
    options: {
      ...commonAttributes.uuid.options,
      display: false
    }
  },
  {
    name: 'student.user.uuid',
    label: 'Student uuid',
    editable: false,
    options: {
      display: false
    }
  },
  {
    name: 'student.user',
    label: 'Student Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      customBodyRender: (value) => (
        <span>{`${(value && value.first_name) || ''} ${
          (value && value.last_name) || ''
        }`}</span>
      ),
      filterKey: 'student_name'
    }
  },
  {
    name: 'student.standard.name',
    label: 'Student Grade',
    editable: false,
    options: {
      filterKey: 'standard'
    }
  },
  {
    name: 'student.parent.user',
    label: 'Parent Name',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_name',
      customBodyRender: (value) =>
        value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}>{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null,
      ...tableColumnDefaultOption.options
    }
  },
  {
    name: 'student.parent.user.phone_number',
    label: 'Parent Number',
    disabledInForm: true,
    validate: validateNotEmpty,
    options: {
      filterKey: 'parent_phone_number',
      ...tableColumnDefaultOption.options
    }
  },
  {
    ...commonAttributes.centre,
    name: 'student.parent.centre_preference.name',
    label: 'Centre',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre'
    }
  },
  {
    name: 'status',
    label: 'Status',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: (() => {
      // create a copy of batchTypesMap and remove the unwanted keys i.e due and lapsed
      const copyStatusMap = { ...statusMap }
      delete copyStatusMap.due
      delete copyStatusMap.lapsed
      return copyStatusMap
    })(),
    disabledInForm: true,
    ...tableColumnDefaultOption
  },
  {
    name: 'program.type',
    label: 'Program Type',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: (() => {
      const copyTypeMap = { ...programList }
      delete copyTypeMap.excel
      return copyTypeMap
    })(),
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'batch_type',
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    ...commonAttributes.date,
    name: 'start_date',
    label: 'Start Date',
    widget: 'datepicker',
    validate: validateNotEmpty
  },
  {
    ...commonAttributes.date,
    name: 'end_date',
    label: 'OG End Date',
    disabledInForm: true
  },
  {
    ...commonAttributes.date,
    name: 'adjusted_end_date',
    label: 'New End Date',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true
  },
  {
    name: 'payment_mode',
    label: 'Payment Mode',
    disabledInFormOnEdit: true,
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: {
      NO_FEE: 'No Fee',
      PRO_RATED_FEE: 'Pro Rated Fee',
      CUSTOM_FEE: 'Custom Fee'
    },
    options: {
      display: false
    }
  },
  {
    name: 'is_termination_requested',
    label: 'Termination Requested',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div>{value ? 'true' : 'false'}</div>
      }
    }
  },
  {
    name: 'termination_requested_on',
    label: 'Termination Requested On',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    name: 'terminated_by',
    label: 'Terminated By',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        if (!value) return <div>NA</div>
        return <div>{value?.full_name}</div>
      }
    }
  },
  {
    name: 'termination_reason',
    label: 'Termination Reason',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      },
      setCellProps: () => ({
        style: {
          minWidth: '200px',
          maxWidth: '200px',
          whiteSpace: 'pre-wrap'
        }
      })
    }
  },
  {
    name: 'termination_notes',
    label: 'Termination Notes',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      },
      setCellProps: () => ({
        style: {
          minWidth: '500px',
          maxWidth: '500px',
          whiteSpace: 'pre-wrap'
        }
      })
    }
  },
  {
    name: 'termination_source',
    label: 'Termination Source',
    validate: validateNotEmpty,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  }
]
