import React from 'react';
import ProgressDifference from '../ProgressDifference';
import * as styles from './styles';

const ProgressDetails = (props) => {
    const { iconUrl, title, show_progress_difference, difference_before, difference_after, difference_color, handleTitleClick } = props;

    return (
        <>
            <styles.Text>Progress</styles.Text>
            <styles.ProgressContainer>
                <styles.Icon src={iconUrl} />
                <div onClick={handleTitleClick}>
                    <styles.Title>{title}</styles.Title>
                </div>
                {show_progress_difference && <ProgressDifference color={difference_color} before={difference_before} after={difference_after}/>}
            </styles.ProgressContainer>
        </>
    );
};

export default ProgressDetails;
