import isEmpty from 'lodash/isEmpty'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get } from '../request'

const fetchTeacherDashboardData = async ({ url, teacherId, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.teacher}/${teacherId}/dashboard`, queries)

  try {
    const response = await get(requestUrl)
    return {
      results: response.data
    }
  } catch (error) {
    console.log('error in fetching teacher dashboard data :- ', error.message)
    throw error
  }
}

const fetchStudentsListData = async ({ url, teacherId, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.teacher}/${teacherId}/students`, queries)

  try {
    const response = await get(requestUrl)
    return {
      results: response.data
    }
  } catch (error) {
    console.log('error in fetching students list data :- ', error.message)
    throw error
  }
}

const fetchStudentsAttendanceData = async ({
  url,
  teacherId,
  studentId,
  queries
} = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.teacher}/${teacherId}/students/${studentId}`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return {
      results: response.data
    }
  } catch (error) {
    console.log('error in fetching students attendance data :- ', error.message)
    throw error
  }
}

export {
  fetchTeacherDashboardData,
  fetchStudentsListData,
  fetchStudentsAttendanceData
}
