import { isEmpty } from 'lodash'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const subjectCategoryRequestStructure = (data) => ({
  name: data.name,
  type: data.type,
  icon: data.icon || null,
  color_code: data.color_code || null,
  description: data.description || null
})

const fetchSubjectCategory = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.subjectCategory, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching subject category :- ', error.message)
    throw error
  }
}

const createSubjectCategory = async (data) => {
  try {
    const response = await post(
      apiEndpoints.subjectCategory,
      subjectCategoryRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating subject category :- ', error.message)
    throw error
  }
}

const updateSubjectCategory = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.subjectCategory}/${data.uuid}/`,
      subjectCategoryRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in updating subject category :- ', error.message)
    throw error
  }
}

const deleteSubjectCategory = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.subjectCategory}/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting subject category :- ', error.message)
    throw error
  }
}

export {
  fetchSubjectCategory,
  createSubjectCategory,
  updateSubjectCategory,
  deleteSubjectCategory
}
