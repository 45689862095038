/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import { getSuperproviderData } from 'api/user'

import { DataTable } from './components/DataTable'
import { Container } from './styles'

export const TeacherWidget = ({ fieldData, formData, ...props }) => {
  const [data, setData] = React.useState(fieldData?.length ? fieldData : [])

  useEffect(() => {
    ;(async () => {
      const superprovider = await getSuperproviderData({ uuid: formData.uuid })
      setData(superprovider?.teachers)
    })()
  }, [formData])

  return (
    <Container>
      <DataTable data={data} {...props} title="Teachers" />
    </Container>
  )
}
