import { notify } from 'app/helpers'

import * as types from '../types/auth'

const initialState = {
  accessToken: null,
  refreshToken: null,
  error: null,
  user: {},
  otpSent: false,
  isLoggedIn: false,
  sendingOtp: false,
  loggingIn: false,
  loginProcessStart: false,
  userNumber: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_OTP:
      return {
        ...state,
        sendingOtp: true
      }
    case types.SEND_OTP_SUCCESS:
      notify('OTP sent successfully', ' ', 'success')
      return {
        ...state,
        user: action.user,
        isStudent: action.user && action.user.is_student,
        uuid: action.user && action.user.uuid,
        otpSent: true,
        isLoggedIn: false,
        sendingOtp: false
      }
    case types.SEND_OTP_FAILED:
      notify('Oops!', action.error, 'danger')
      return {
        ...state,
        error: action.error,
        otpSent: true,
        isLoggedIn: false,
        sendingOtp: false
      }
    case types.LOGIN_START:
      return {
        ...state,
        loggingIn: true
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.data.access_token,
        refreshToken: action.data.refresh_token,
        isLoggedIn: action.data.isValidAdmin,
        loggingIn: false,
        userNumber: action.data.number
      }
    case types.LOGIN_FAILED:
      notify('Oops!', action.error, 'danger')
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        loggingIn: false
      }
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null,
        userNumber: null
      }
    case types.LOGIN_WITH_COOKIE:
      return {
        ...state,
        loginProcessStart: true
      }
    case types.LOGIN_WITH_COOKIE_SUCCESS:
      console.log('login with cookie success', action.data.number)
      return {
        ...state,
        error: action.error,
        isLoggedIn: true,
        accessToken: action.data.access_token,
        refreshToken: action.data.refresh_token,
        userNumber: action.data.number,
        loginProcessStart: false
      }
    case types.LOGIN_WITH_COOKIE_FAILED:
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null,
        loginProcessStart: false,
        userNumber: null
      }

    default:
      return state
  }
}

export { authReducer }
