import { commonStructure, dataResponseHandler } from './reducer.helper'

import * as types from '../types/common'

const initialState = {
  states: commonStructure(),
  cities: commonStructure()
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_STATES_SUCCESS:
      return dataResponseHandler(state, 'states', action)

    case types.FETCH_CITIES_SUCCESS:
      return dataResponseHandler(state, 'cities', action)

    default:
      return state
  }
}

export { commonReducer }
