export default {
  'oh-orange': 'rgba(242, 94, 53, 1)',
  'oh-white': '#f5f5f5',
  'oh-off-white': '#f8f8f8',
  'oh-black': '#555555',
  'oh-black-light': '#717171',
  'oh-blue': '#66b5de',
  'oh-teal': '#95c48c',
  'oh-disabled': '#d3d3d3',
  'oh-red': '#ef5c34',
  'oh-green': '#a3c996',
  'oh-vl-blue': 'rgba(102, 181, 222, 0.1)',
  'oh-border-gray': 'rgba(0, 0, 0, 0.05)',
  'oh-shadow-gray': 'rgba(64, 64, 64, 0.3)',
  'oh-box-gray': '#fafafa',
  'oh-game-black': '#15202c',
  'oh-brand-1': 'rgba(242, 183, 5, 1)',
  'oh-brand-vl-1': 'rgba(242, 183, 5, 0.1)',
  'oh-brand-2': 'rgba(140, 86, 55, 1)',
  'oh-brand-vl-2': 'rgba(140, 86, 55, 0.1)',
  'oh-brand-3': 'rgba(132, 108, 217, 1)',
  'oh-brand-vl-3': 'rgba(132, 108, 217, 0.1)',
  'oh-brand-4': 'rgba(191, 99, 105, 1)',
  'oh-brand-vl-4': 'rgba(191, 99, 105, 0.1)',
  'oh-brand-5': 'rgba(147, 204, 88, 1)',
  'oh-brand-vl-5': 'rgba(147, 204, 88, 0.1)',
  'oh-brand-6': 'rgba(94, 174, 1, 1)',
  'oh-brand-vl-6': 'rgba(94, 174, 1, 0.1)',
  'oh-brand-7': 'rgba(11, 158, 217, 1)',
  'oh-brand-vl-7': 'rgba(11, 158, 217, 0.1)',
  'oh-brand-8': 'rgba(255, 118, 107, 1)',
  'oh-brand-vl-8': 'rgba(255, 118, 107, 0.1)',
  'oh-brand-9': 'rgba(68, 137, 171, 1)',
  'oh-brand-vl-9': 'rgba(68, 137, 171, 0.1)',
  'oh-default-shadow': '0px 8px 16px rgba(0, 0, 0, 0.05)'
}
