import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { TextField } from 'app/components/generic/FormElements/TextField'
import { validateFee } from 'app/validators'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const TaxAmountForm = (props) => {
  const classes = useStyles()

  const { action, formData } = props
  const { values, setFieldValue } = useFormikContext()

  React.useEffect(() => {
    if (values.tax)
      setFieldValue('final_dispatch_amount', values.value - values.tax)
  }, [values.tax])

  const formElement = React.useMemo(() => {
    return (
      <FormControl className={classes.formControl} fullWidth>
        {action === 'dispatch' ? (
          <>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'value'}
                label={'Total amount after edits'}
                variant="outlined"
                disabled
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'tax'}
                label={'Tax amount'}
                variant="outlined"
                required
                validate={validateFee({ isRequired: true })}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'final_dispatch_amount'}
                label={'Final dispatch amount'}
                variant="outlined"
                disabled
              />
            </FormControl>
          </>
        ) : null}
      </FormControl>
    )
  }, [action, classes, formData])

  return (
    <div>{formElement}</div>
  )
}

TaxAmountForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default TaxAmountForm
