import {
  createMuiTheme,
  ThemeProvider,
  createTheme
} from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  showTribeRecordings,
  updateTribeIsRecordable,
  updateTribeVisibleOnWebsite
} from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import ModalForm from 'app/components/ModalForm'
import { navigationPaths } from 'app/config'
import constants from 'app/constants'
import { showToast } from 'app/helpers'
import {
  updateCommunityData,
  fetchTribeData
} from 'app/store/actions/communityEntity'
import { validateNotEmpty } from 'app/validators'

import { columns } from './config'
import { RecordingsTable } from './RecordingsTable'

const theme = createTheme({
  overrides: {
    Dialog: {
      backgroundColor: 'red'
    }
  }
})

const Tribe = () => {
  const tribeData = useSelector(
    (state) => state.communityEntityReducer.tribeData
  )
  const [modalOpen, setModalOpen] = React.useState(false)
  const [errors, setErrors] = React.useState('')
  const [tableData, setTableData] = React.useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  const addMembers = React.useCallback(
    (dataIndex) => {
      const {
        uuid,
        name,
        club: { name: clubName },
        leader
      } = tribeData.data[dataIndex]

      let first_name = '',
        last_name = '',
        phone_number = ''

      if (
        !isEmpty(leader) &&
        Object.prototype.hasOwnProperty.call(leader, 'user') &&
        !isEmpty(leader.user)
      ) {
        const {
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber
        } = leader.user

        first_name = firstName || ''
        last_name = lastName || ''
        phone_number = phoneNumber || ''
      }

      history.push({
        pathname: navigationPaths.membership,
        search: `?tribe=${uuid}&tribeName=${name}&club=${clubName}&firstName=${first_name}&lastName=${last_name}&phoneNumber=${phone_number}&openModalForm=1`
      })
    },
    [tribeData, history]
  )

  const onChangeRecordable = React.useCallback(
    (row, is_recordable) => {
      updateTribeIsRecordable(row, is_recordable)
        .then((data) => {
          if (data.success) {
            showToast('Data saved successfully', constants.SUCCESS)
          } else {
            dispatch(fetchTribeData({}, constants.TRIBE))
            showToast('Oops, Unable to save data', constants.ERROR)
          }
        })
        .catch((e) => {
          dispatch(fetchTribeData({}, constants.TRIBE))
          showToast(
            e.message ? e.message : 'Unable to save recordings',
            constants.ERROR
          )
        })
    },
    [dispatch, updateTribeIsRecordable]
  )

  const onChangeVisibility = React.useCallback(
    (row, visible) => {
      updateTribeVisibleOnWebsite(row, visible)
        .then((data) => {
          showToast('Data saved successfully', constants.SUCCESS)
        })
        .catch((e) => {
          dispatch(fetchTribeData({}, constants.TRIBE))
          showToast(
            e.message ? e.message : 'Unable to save visible on website',
            constants.ERROR
          )
        })
    },
    [dispatch, updateTribeVisibleOnWebsite]
  )

  const handleShowRecordings = React.useCallback(
    (dataIndex) => {
      const _data = tribeData.data[dataIndex]
      showTribeRecordings(_data)
        .then((data) => {
          if (data.results) {
            setTableData(
              data.results.map((schedule) => ({
                date: schedule.date,
                recordingUrl: schedule.recording_url
              }))
            )
            setModalOpen(true)
          } else {
            showToast('Oops, Unable to fetch data', constants.ERROR)
          }
        })
        .catch((e) => {
          dispatch(fetchTribeData({}, constants.TRIBE))
          setErrors(e.message ? e.message : 'Unable to fetch recordings')
          showToast(
            e.message ? e.message : 'Unable to fetch recordings',
            constants.ERROR
          )
        })
    },
    [tribeData, showTribeRecordings, dispatch]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const data = tribeData.data[dataIndex]
            return data.mode === 'oh_meets' ? (
              <Button onClick={() => handleShowRecordings(dataIndex)}>
                Show Recordings
              </Button>
            ) : null
          }
        }
      },
      {
        name: 'visible_on_website',
        label: 'Visible on website',
        disabledInForm: true,
        // widget: 'select',
        // valueLabelMappings: {
        //   true: 'Yes',
        //   false: 'No'
        // },
        options: {
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const data = tribeData.data[dataIndex]
            return (
              <Switch
                key={data.uuid}
                defaultChecked={data.visible_on_website}
                color="primary"
                onChange={(event) =>
                  onChangeVisibility(data, event.target.checked)
                }
              />
            )
          }
        }
      },
      {
        name: '',
        label: 'Recorded',
        disabledInForm: true,
        options: {
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const data = tribeData.data[dataIndex]
            return data.mode === 'oh_meets' ? (
              <Switch
                key={data.uuid}
                defaultChecked={data.is_recordable}
                color="primary"
                onChange={(event) =>
                  onChangeRecordable(data, event.target.checked)
                }
              />
            ) : null
          }
        }
      }
    ]
  }, [addMembers])

  return (
    <>
      <BaseEntity
        entity={constants.TRIBE}
        label={constants.TRIBE_LABEL}
        columns={modifiedColumns}
        data={tribeData}
        fetchData={fetchTribeData}
        updateEntityData={updateCommunityData}
      />
      <ThemeProvider theme={theme}>
        <ModalForm
          disableBackdropClick={!errors}
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={modalOpen}
          onModalClose={() => setModalOpen(false)}
          enableSave={false}
          modalTitle="Recordings Data"
        >
          <RecordingsTable data={tableData} />
        </ModalForm>
      </ThemeProvider>
    </>
  )
}

export default Tribe
