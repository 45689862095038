import React from 'react'

import {
  commonAttributes,
  navigationPaths,
  tableColumnDefaultOption,
  uploadPresets
} from 'app/config'
import constants from 'app/constants'
import {
  validateEmail,
  validatePhoneNumber,
  validateName,
  validateBio
} from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid,
    name: 'user.uuid'
  },
  {
    name: 'user.phone_number',
    label: 'Phone Number',
    validate: validatePhoneNumber,
    disabledInFormOnEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'phone_number'
    }
  },
  {
    name: 'user',
    label: 'Full Name',
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'name',
      customBodyRender: (value) => {
        return value ? (
          <span>
            <a
              href={`${navigationPaths.parentdashboard}?parent=${value.uuid}`}>{`${value.first_name} ${value.last_name}`}</a>
          </span>
        ) : null
      }
    }
  },
  {
    name: 'user.first_name',
    label: 'First Name',
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'first_name'
    }
  },
  {
    name: 'user.last_name',
    label: 'Last Name',
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'last_name'
    }
  },
  {
    name: 'user.email_address',
    label: 'Email',
    optional: true,
    validate: validateEmail,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'email'
    }
  },
  {
    name: 'user.address',
    label: 'Address',
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'address'
    }
  },
  {
    name: 'user.bio',
    label: 'Biography',
    // optional: true,
    validate: validateBio,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'bio',
      disableFilter: true,
      setCellProps: () => ({
        style: { minWidth: '500px', maxWidth: '500px', whiteSpace: 'pre-wrap' }
      })
    }
  },
  {
    name: 'user.date_of_birth',
    widget: 'datepicker',
    label: 'Date Of Birth',
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'date_of_birth'
    }
  },
  // {
  //   name: 'user.gender',
  //   label: 'Gender',
  //   widget: 'select',
  //   optional: true,
  //   valueLabelMappings: {
  //     male: 'Male',
  //     female: 'Female'
  //   },
  //   options: {
  //     ...tableColumnDefaultOption.options,
  //     filterKey: 'gender'
  //   }
  // },
  {
    ...commonAttributes.image,
    name: 'user.image',
    fileUrlOnly: true,
    optional: false,
    uploadPreset: uploadPresets[constants.USER]
  }
]
