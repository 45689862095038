import React from 'react'

import History from 'app/components/generic/history/history'
import Slider from 'app/components/generic/Slider/slider'
import { TICKET_STATUS_OPTIONS } from 'app/constants.js'
import { dataColumns } from 'app/pages/Dashboard/ParentDashboard/config'

import * as styles from './styles.js'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const TicketsLog = (props) => {
  const { ticketsData, selectedParentDetails } = props
  const allTickets = ticketsData?.data
  const openTickets = ticketsData?.data?.filter(
    (ticket) => ticket.ticket_status !== TICKET_STATUS_OPTIONS.RESOLVED
  )

  return (
    <styles.Body>
      <styles.ColumnFlexCenter>
        <Slider
          width="50%"
          key={'SliderTicketTotal'}
          placeholder={
            <TitleDescription
              heading="Total Issues Raised"
              description={allTickets?.length}
              align="center"
            />
          }
          blockType={'tickets log'}
          childComponent={
            <History
              type={dataColumns['tickets log']?.type}
              heading={dataColumns['tickets log']?.heading}
              data={allTickets}
              userInfo={selectedParentDetails?.user}
              tableHeadings={dataColumns['tickets log']?.tableHeadings}
              accessors={dataColumns['tickets log']?.accessors}
              readOnly={true}
              editableDetails={false}
              addDetails={false}
              headerData={true}
            />
          }
        />
        <Slider
          width="50%"
          key={'SliderTicketOpen'}
          placeholder={
            <TitleDescription
              heading="Open Issues"
              description={openTickets?.length}
              align="center"
            />
          }
          blockType={'tickets log'}
          childComponent={
            <History
              type={dataColumns['tickets log']?.type}
              heading={dataColumns['tickets log']?.heading}
              data={openTickets}
              userInfo={selectedParentDetails?.user}
              tableHeadings={dataColumns['tickets log']?.tableHeadings}
              accessors={dataColumns['tickets log']?.accessors}
              readOnly={true}
              editableDetails={false}
              addDetails={false}
              headerData={true}
            />
          }
        />
      </styles.ColumnFlexCenter>
    </styles.Body>
  )
}
