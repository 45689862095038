import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-right: 1rem;
`

export const Icon = styled.img`
  margin-right: 0.25rem;
  margin-bottom: 1rem;
`

export const Text = styled.div`
  font-size: 1rem;
`
