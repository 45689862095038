import { ThemeProvider } from '@material-ui/core'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { fetchSessionsPaymentSummary } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import ModalForm from 'app/components/ModalForm'
import { commonAttributes } from 'app/config'
import constants from 'app/constants'
import { showToast } from 'app/helpers'
import { fetchIndependentOngoingSummaryData } from 'app/store/actions/paymentEntity'
import { formatAmount } from 'app/utils'
import AbsentIcon from 'assets/images/absent.svg'
import PresentIcon from 'assets/images/present.svg'

import { columns } from './config'

import { CalculationsTable } from '../Independent/components/CalculationsTable'
import * as styles from '../Independent/styles'

const IndependentOngoing = () => {
  const [payoutModalOpen, setPayoutModalOpen] = React.useState(false)
  const [payoutData, setPayoutData] = React.useState(null)
  const [subtitle, setSubtitle] = React.useState('')

  const independentOngoingSummaryData = useSelector(
    (state) => state.paymentEntityReducer.independentOngoingSummaryData
  )

  const closePayoutModal = () => {
    setPayoutModalOpen(false)
    setPayoutData(null)
  }

  const getPayoutData = React.useCallback(
    async (dataIndex) => {
      const id = independentOngoingSummaryData.data[dataIndex].uuid
      setPayoutModalOpen(true)
      const res = await fetchSessionsPaymentSummary({
        url: null,
        queries: { uuid: id }
      })
      const data = res.results

      if (!isEmpty(data)) {
        setSubtitle(
          `Total sessions: ${data?.length} , Total amount: ${formatAmount(
            data?.reduce((prev, curr) => prev + curr.payable_amount, 0)
          )}`
        )
        setPayoutData(
          data?.map((session) => {
            return {
              course: session?.schedule?.batch?.course?.name,
              batch_type: session?.schedule?.batch?.type,
              date: dayjs(session?.schedule?.date).format('DD MMM, YY'),
              timing: `${dayjs(`1/1/1 ${session?.schedule?.start_time}`).format(
                'H:mma'
              )} - ${dayjs(`1/1/1 ${session?.schedule?.end_time}`).format(
                'H:mma'
              )}`,
              centre: session?.schedule?.batch?.centre?.name,
              students: `${
                session?.schedule?.reserved_count +
                session?.schedule?.cancelled_count
              } (Total) - ${session?.schedule?.cancelled_count} (Cancld.) = ${
                session?.schedule?.reserved_count
              }`,
              // coinsPerSession: '',
              // calculation: '',
              amount: session?.payable_amount,
              teacher_attendance: session?.attended ? (
                <styles.Icon src={PresentIcon} alt="present" />
              ) : (
                <styles.Icon src={AbsentIcon} alt="absent" />
              ),
              teacher_arrival: session.attended ? session?.arrival : ''
            }
          })
        )
      } else {
        setPayoutData([])
      }
    },
    [independentOngoingSummaryData]
  )

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'calculation') {
      getPayoutData(dataIndex)
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'payout_items',
        label: 'Sessions Amount',
        ...commonAttributes.amount,
        options: {
          ...commonAttributes.amount.options,
          filterKey: 'sessions_value',
          customBodyRenderLite: (dataIndex) => {
            const value = independentOngoingSummaryData.data[
              dataIndex
            ].payout_items.find((item) => item.item_type.type === 'sessions')
            return value ? (
              <span>
                {`${formatAmount(value.value)} `}
                <a
                  onClick={(e) => {
                    onActionButtonClick(e, 'calculation', dataIndex)
                  }}
                >
                  view details
                </a>
              </span>
            ) : null
          }
        }
      }
    ]
  }, [onActionButtonClick])

  return (
    <>
      <BaseEntity
        entity={constants.INDEPENDENT_ONGOING_SUMMARY}
        label={constants.INDEPENDENT_ONGOING_SUMMARY_LABEL}
        data={independentOngoingSummaryData}
        columns={modifiedColumns}
        fetchData={fetchIndependentOngoingSummaryData}
        readOnly
      />
      <ThemeProvider>
        <ModalForm
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={payoutModalOpen}
          onModalClose={closePayoutModal}
          enableSave={false}
          modalTitle="Payout Calculation"
          modalSubtitle={subtitle}
        >
          <CalculationsTable data={payoutData} />
        </ModalForm>
      </ThemeProvider>
    </>
  )
}

export default IndependentOngoing
