import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createReferralCode } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import CircularProgress from 'app/components/generic/CircularProgress'
import FileUpload from 'app/components/generic/FileUpload'
import constants from 'app/constants'
import { eventAlert } from 'app/helpers'
import {
  fetchReferralCodeData,
  updateCommunityData
} from 'app/store/actions/communityEntity'
import { csvToJson, randomNumber } from 'app/utils'

import { columns, optionalKeys, validKeysMapping } from './config'

const initialState = {
  showProgress: false,
  successCount: 0,
  failedCount: 0,
  processed: 0,
  total: 0
}

const ReferralCode = () => {
  const referralCodeData = useSelector(
    (state) => state.communityEntityReducer.referralCodeData
  )
  const [uploadData, setUploadData] = React.useState(initialState)

  const dispatch = useDispatch()

  const onFinishUploading = React.useCallback(() => {
    setTimeout(async () => {
      eventAlert('Data Saved!', constants.SUCCESS)

      try {
        dispatch(fetchReferralCodeData({}, constants.REFERRAL_CODE)) // passing no query and entity
        setUploadData(initialState)
      } catch (error) {
        console.log(error)
      }
    }, 1000)
  }, [dispatch])

  const handleUploadStart = (total) => {
    setUploadData({
      ...initialState,
      showProgress: true,
      total
    })
  }

  const onModalClose = () => {
    setUploadData({
      ...initialState
    })
  }

  const progressUploadSuccess = () => {
    setUploadData((state) => ({
      ...state,
      successCount: state.successCount + 1,
      processed: state.processed + 1
    }))
  }

  const progressUploadFailed = () => {
    setUploadData((state) => ({
      ...state,
      failedCount: state.failedCount + 1,
      processed: state.processed + 1
    }))
  }

  const generateValidReferralData = (data) => {
    let validData = []

    data.forEach((row) => {
      const cleanedData = {
        isValid: true,
        data: {}
      }

      for (const [key, value] of Object.entries(row)) {
        const validKey = key.trim().replace(/ /g, '').toLowerCase()

        const mappedKeyValue = validKeysMapping[validKey]

        if (
          optionalKeys.indexOf(validKey) < 0 &&
          (isEmpty(value) || isEmpty(mappedKeyValue))
        ) {
          cleanedData.isValid = false
          break
        } else {
          cleanedData.data[mappedKeyValue] = value.trim()
        }
      }

      if (cleanedData.isValid) {
        validData.push(cleanedData.data)
      }
    })

    // now make referral code if na is there
    validData = validData.map((item) => {
      if (item.referral_code === 'na') {
        item.referral_code = `${item.name.slice(0, 4)}${randomNumber(100, 999)}`
      }

      return item
    })

    return validData
  }

  const onUpload = React.useCallback(
    async (e) => {
      try {
        const data = await csvToJson(e.target.files[0], {
          header: true,
          delimiter: ','
        })

        const validData = generateValidReferralData(data)

        if (!isEmpty(validData)) {
          handleUploadStart(validData.length)

          const uploadPromises = validData.map((referralData) => {
            return createReferralCode(referralData)
          })

          // iterating each promises to track progress
          uploadPromises.forEach((uploadPromise) => {
            uploadPromise.then(progressUploadSuccess).catch((error) => {
              console.log(error)
              progressUploadFailed()
            })
          })

          // waiting for all to finish
          Promise.all(uploadPromises)
            .then(onFinishUploading)
            .catch((error) => console.log(error))
        } else {
          alert('no valid data')
        }
      } catch (error) {
        console.log('error in parsing json from csv :- ', error)
      }
    },
    [onFinishUploading]
  )

  const CustomToolbarElement = (
    <FileUpload label="Upload Codes" onUpload={onUpload} />
  )

  const uploadDonepercentage =
    uploadData.total > 0
      ? Math.floor((uploadData.successCount / uploadData.total) * 100)
      : 0

  return (
    <>
      {uploadData.showProgress ? (
        <CircularProgress
          percentage={uploadDonepercentage}
          isModalOpen={uploadData.showProgress}
          failedCount={uploadData.failedCount}
          total={uploadData.total}
          onModalClose={onModalClose}
        />
      ) : null}
      <BaseEntity
        entity={constants.REFERRAL_CODE}
        label={constants.REFERRAL_CODE_LABEL}
        columns={columns}
        data={referralCodeData}
        fetchData={fetchReferralCodeData}
        updateEntityData={updateCommunityData}
        CustomToolbarElement={CustomToolbarElement}
      />
    </>
  )
}

export default ReferralCode
