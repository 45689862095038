import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import _, { isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { apiEndpoints, getBatchData } from 'api'
import { fetchOpendayShowcaseSchedule } from 'api/schedules'
import { getShowcaseSlotsBatches, getShowcaseSlotsStudents } from 'api/slots'
import BaseEntity from 'app/components/BaseEntity'
import ModalForm from 'app/components/ModalForm'
import {
  tableColumnDefaultOption,
  commonAttributes,
  OPENDAY_SLOTS_MINUTE_INTERVAL
} from 'app/config'
import constants from 'app/constants'
import {
  getOpendayTimeSlots,
  formatCadence,
  formatLevel,
  getMonthYear,
  formatTimeString,
  showToast,
  makeRequestUrl
} from 'app/helpers'
import {
  fetchOpendaySlotsData,
  updateClassData
} from 'app/store/actions/classEntity'
import { sanitize } from 'app/utils'
import { validateNotEmpty } from 'app/validators'

import { BatchDetailsTable } from './BatchDetailsTable'
import { columns } from './config'
import { StudentDetailsTable } from './StudentDetailsTable'

const theme = createTheme({
  overrides: {
    Dialog: {
      backgroundColor: 'red'
    }
  }
})

const OpendaySlots = () => {
  const opendaySlotsData = useSelector(
    (state) => state.classEntityReducer.opendaySlotsData
  )

  const [expandedRows, setExpandedRows] = React.useState({})
  const [studentDetailsData, setStudentDetailsData] = React.useState('')
  const [batchDetailsData, setBatchDetailsData] = React.useState('')
  const [studentDetailsModalOpen, setStudentDetailsModalOpen] =
    React.useState(false)
  const [batchDetailsModalOpen, setBatchDetailsModalOpen] =
    React.useState(false)
  const [errors, setErrors] = React.useState('')
  const [dateOptions, setDateOptions] = React.useState({})
  const [subjectOptions, setSubjectOptions] = React.useState({})
  const [selectedSubject, setSelectedSubject] = React.useState('')
  const [batchOptions, setBatchOptions] = React.useState({})
  const [opendayTimeSlots, setOpendayTimeSlots] = React.useState({})
  const [selectedStartTime, setSelectedStartTime] = React.useState('')
  const [opendayEndTimeSlots, setOpendayEndTimeSlots] = React.useState({})
  const [selectedBatches, setSelectedBatches] = React.useState([])
  const [selectedSlot, setSelectedSlot] = React.useState('')

  const centre_name = new URLSearchParams(window.location.search).get(
    'centreName'
  )
  const centre_uuid = new URLSearchParams(window.location.search).get(
    'centreUuid'
  )
  const end_date = new URLSearchParams(window.location.search).get('endDate')
  const schedule_uuid = new URLSearchParams(window.location.search).get(
    'scheduleUuid'
  )

  const currentDate = new Date()
  const formattedCurrentDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`

  const toggleRows = (rowsId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowsId]: !prevExpandedRows[rowsId]
    }))
  }

  React.useEffect(() => {
    ;(async () => {
      fetchScheduleData()
    })()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      fetchTimeSlots()
    })()
  }, [])

  React.useEffect(() => {
    const splitTime = selectedStartTime.split(':')
    let startHour = Number(splitTime[0])
    splitTime[0] = startHour.toString().padStart(2, '0')
    const startTime = splitTime.join(':')
    ;(async () => {
      fetchEndTimeSlots(startTime)
    })()
  }, [selectedStartTime])

  React.useEffect(() => {
    ;(async () => {
      opendaySlotsData && fetchBatchData()
    })()
  }, [selectedSubject])

  React.useEffect(() => {
    ;(async () => {
      selectedSubject && opendaySlotsData && fetchBatchData()
    })()
  }, [selectedSlot])

  const fetchTimeSlots = async () => {
    const slots = await getOpendayTimeSlots()
    setOpendayTimeSlots(slots)
  }

  const fetchEndTimeSlots = async (start) => {
    const slots = await getOpendayTimeSlots(start)
    setOpendayEndTimeSlots(slots)
  }

  const fetchScheduleData = async () => {
    try {
      const response = await fetchOpendayShowcaseSchedule({
        schedule_uuid: schedule_uuid
      })

      if (response) {
        const dateOptionsArray = response?.dates
        const subjectOptionsArray = response.subjects

        const dateOptionsMapping =
          dateOptionsArray?.length > 0
            ? dateOptionsArray.reduce((acc, item) => {
                acc[item] = item
                return acc
              }, {})
            : {}

        const subjectOptionsMapping =
          subjectOptionsArray?.length > 0
            ? subjectOptionsArray.map((item) => {
                return {
                  uuid: item.uuid,
                  name: item.name
                }
              })
            : {}

        setDateOptions(dateOptionsMapping)
        setSubjectOptions(subjectOptionsMapping)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error.toString())
    }
  }

  const fetchBatchData = async () => {
    try {
      const response = await getBatchData({
        queries: {
          type: 'excel',
          end_date: formattedCurrentDate,
          centre_uuid: centre_uuid,
          subjects_uuid: selectedSubject,
          limit: 50
        }
      })

      if (response) {
        let batchOptionsArray = response.results

        const slots = opendaySlotsData?.data?.filter(
          (val) => val.uuid !== selectedSlot.toString()
        )
        const usedBatches = _.flatten(
          slots.map((item) => {
            return item.batches
          })
        )
        batchOptionsArray = batchOptionsArray.filter((item) => {
          return usedBatches.findIndex((val) => val.uuid === item.uuid) === -1
        })
        const batchOptionsMapping = batchOptionsArray.map((item) => {
          return {
            uuid: item.uuid,
            name:
              formatLevel(item?.level) +
              ' | ' +
              item?.course?.name +
              ' | ' +
              formatCadence(item?.cadence) +
              ' (' +
              item?.teacher?.full_name +
              ')'
          }
        })
        setBatchOptions(batchOptionsMapping)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error.toString())
    }
  }

  const getStudentShowcaseDetails = React.useCallback(
    async (dataIndex) => {
      try {
        const data = opendaySlotsData?.data[dataIndex]
        const fetchedData = await getShowcaseSlotsStudents({
          slotUuid: data.uuid
        })

        if (!isEmpty(fetchedData)) {
          const studentData = fetchedData.results.map((result) => ({
            student_name: result.user.full_name,
            parent_name: result.parent.user.full_name,
            phone_number: result.parent.user.phone_number,
            parent_id: result.parent.user_id
          }))

          setStudentDetailsData(studentData)
          setStudentDetailsModalOpen(true)
        } else {
          showToast(
            'Oops, unable to fetch student showcase slot details',
            constants.ERROR
          )
        }
      } catch (e) {
        setErrors(
          e.message ? e.message : 'Unable to fetch student showcase slot'
        )
        console.log('Unable to fetch student showcase slot details')
        throw e
      }
    },
    [opendaySlotsData]
  )

  const getStudentShowcaseBatchDetails = React.useCallback(
    async (dataIndex) => {
      try {
        const data = opendaySlotsData?.data[dataIndex]
        const fetchedData = await getShowcaseSlotsBatches({
          slotUuid: data.uuid
        })

        if (!isEmpty(fetchedData)) {
          const batchData = fetchedData?.results.map((result) => ({
            teachers: result?.teacher?.full_name,
            batch_level: formatLevel(result?.level),
            enrolment_count: result?.showcase_slot_enrolment_count,
            batch_timing: formatCadence(result?.cadence),
            grades: result?.course?.standards
              ?.map((standard) => standard.name)
              .join(', ')
          }))
          setBatchDetailsData(batchData)
          setBatchDetailsModalOpen(true)
        } else {
          showToast(
            'Oops, unable to fetch showcase slots batch details',
            constants.ERROR
          )
        }
      } catch (e) {
        setErrors(
          e.message
            ? e.message
            : 'Unable to fetch batch list details for showcase slot'
        )
        console.log('Unable to fetch batch list details for showcase slot')
        throw e
      }
    },
    [opendaySlotsData]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      {
        name: 'start_time',
        label: 'start time',
        widget: 'select',
        valueLabelMappings: opendayTimeSlots,
        widgetFilter: true,
        validate: validateNotEmpty,
        isValueKey: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'start_time',
          customBodyRender: (value) => {
            const time = formatTimeString(value)
            return <div>{time}</div>
          }
        }
      },
      {
        name: 'end_time',
        label: 'end time',
        widget: 'select',
        valueLabelMappings: opendayEndTimeSlots,
        widgetFilter: true,
        validate: validateNotEmpty,
        isValueKey: true,
        conditionalRender: (data) => {
          setSelectedStartTime(data?.start_time)
          return !!data?.start_time
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'end_time',
          customBodyRender: (value) => {
            const time = formatTimeString(value)
            return <div>{time}</div>
          }
        }
      },
      {
        name: 'date',
        label: 'date',
        widgetFilter: true,
        widget: 'select',
        valueLabelMappings: dateOptions,
        isValueKey: true,
        validate: validateNotEmpty,
        options: {
          ...commonAttributes.date.options,
          filterKey: 'date',
          customBodyRender: (value) => {
            return <div>{value}</div>
          }
        }
      },
      {
        name: 'subject',
        label: 'subject',
        formDataKey: 'subject',
        widget: 'autocomplete',
        isValueKey: true,
        valueLabelMappings: subjectOptions,
        showDisabledInFormOnEdit: true,
        widgetFilter: true,
        validate: validateNotEmpty,
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return item
              ? {
                  uuid: item.uuid,
                  name: item.name
                }
              : null
          }

          if (Array.isArray(data)) {
            return data?.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'subjects')) {
            return data?.results?.map((item) => {
              return getItemStructure(item)
            })
          }

          return [getItemStructure(data)]
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            return item.uuid
          }
          return getItemStructure(data)
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'subject',
          keyValue: 'uuid'
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'subject',
          customBodyRender: (value) =>
            value ? <span>{value?.name}</span> : null
        }
      },
      {
        name: 'batches',
        label: 'batches',
        formDataKey: 'batches',
        widget: 'autocomplete',
        widgetFilter: true,
        valueLabelMappings: batchOptions,
        validate: validateNotEmpty,
        conditionalRender: (data) => {
          const subjectData = data?.subject
          const batchData = data?.batches
          setSelectedSubject(subjectData)
          setSelectedBatches(batchData)
          setSelectedSlot(data?.uuid)
          return !!subjectData
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'batches',
          keyValue: 'uuid'
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            return item.uuid
          }
          return data.map((item) => {
            return getItemStructure(item)
          })
        },
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return item
              ? {
                  uuid: item.uuid,
                  name: item?.name
                    ? item?.name
                    : formatLevel(item?.level) +
                      ' | ' +
                      item?.course?.name +
                      ' | ' +
                      formatCadence(item?.cadence) +
                      ' (' +
                      item?.teacher?.full_name +
                      ')'
                }
              : null
          }
          if (Array.isArray(data)) {
            return data?.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
            return data?.results?.map((item) => {
              return getItemStructure(item)
            })
          }

          return [getItemStructure(data)]
        },
        multiple: true,
        options: {
          ...tableColumnDefaultOption.options,
          display: false
        }
      },
      ...columns,
      {
        name: 'description',
        label: 'session details',
        widget: 'wysiwyg',
        widgetFilter: true,
        validate: validateNotEmpty,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          setCellProps: () => ({
            style: {
              minWidth: '400px',
              maxWidth: '400px',
              whiteSpace: 'pre-wrap'
            }
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = opendaySlotsData?.data[dataIndex]?.description
            const truncatedData = data?.slice(0, 100)
            const isTruncated = data?.length > truncatedData?.length
            const isExpanded = expandedRows[dataIndex]
            const displayData = isExpanded ? data : truncatedData

            return (
              <div>
                <span>
                  {!displayData ? (
                    'N/A'
                  ) : displayData?.length < 100 ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sanitize(displayData)
                      }}
                    />
                  ) : !isExpanded ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sanitize(displayData + '...')
                      }}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sanitize(displayData)
                      }}
                    />
                  )}
                  <br />
                  {isTruncated && (
                    <div style={{ textAlign: 'right' }}>
                      <a
                        href="#"
                        onClick={() => toggleRows(dataIndex)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {isExpanded ? 'show less' : 'show more'}
                      </a>
                    </div>
                  )}
                </span>
              </div>
            )
          }
        }
      },
      {
        name: 'batches.length',
        label: 'batch count',
        widgetFilter: true,
        disabledInForm: true,
        validate: validateNotEmpty,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <div
                onClick={() => getStudentShowcaseBatchDetails(dataIndex)}
                style={{
                  color: 'var(--oh-orange)',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                view batches
              </div>
            )
          }
        }
      },
      {
        name: 'student_count',
        label: 'students',
        disabledInForm: true,
        widgetFilter: true,
        validate: validateNotEmpty,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <div
                onClick={() => getStudentShowcaseDetails(dataIndex)}
                style={{
                  color: 'var(--oh-orange)',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                view students
              </div>
            )
          }
        }
      },
      {
        name: 'showcase_schedule',
        label: 'schedule_id',
        editable: false,
        optional: true,
        disabledInFormOnCreate: true,
        options: {
          ...tableColumnDefaultOption.options,
          display: false
        },
        defaultValue: () =>
          new URLSearchParams(window.location.search).get('scheduleUuid')
      }
    ]
  }, [
    opendaySlotsData,
    selectedSubject,
    expandedRows,
    batchOptions,
    selectedStartTime,
    opendayEndTimeSlots
  ])

  return (
    <>
      <BaseEntity
        entity={constants.OPENDAY_SLOTS}
        label={
          constants.OPENDAY_SLOTS_LABEL +
          ' for ' +
          getMonthYear(end_date) +
          ' in ' +
          centre_name
        }
        columns={modifiedColumns}
        data={opendaySlotsData}
        fetchData={fetchOpendaySlotsData}
        updateEntityData={updateClassData}
        selectableRows="single"
      />
      <ThemeProvider theme={theme}>
        <ModalForm
          disableBackdropClick={!errors}
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={batchDetailsModalOpen}
          onModalClose={() => setBatchDetailsModalOpen(false)}
          enableSave={false}
          modalTitle="Batch Details"
        >
          <BatchDetailsTable data={batchDetailsData} />
        </ModalForm>
      </ThemeProvider>

      <ThemeProvider theme={theme}>
        <ModalForm
          disableBackdropClick={!errors}
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={studentDetailsModalOpen}
          onModalClose={() => setStudentDetailsModalOpen(false)}
          enableSave={false}
          modalTitle="Student Details"
        >
          <StudentDetailsTable data={studentDetailsData} />
        </ModalForm>
      </ThemeProvider>
    </>
  )
}

export default OpendaySlots
