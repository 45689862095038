/* eslint-disable react/prop-types */
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock'
import OhIcon from 'assets/images/oh_logo_transparent.svg'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const Dropout = (props) => {
  const { dropoutStudentsData } = props
  return !isEmpty(dropoutStudentsData) ? (
    <styles.Body>
      <styles.RowFlexCenter>
        <TitleDescription
          heading="overall"
          description={
            dropoutStudentsData?.overallDropoutStudentsCount
              ? dropoutStudentsData?.overallDropoutStudentsCount
              : 0
          }
          size="big"
          align="center"
        />
      </styles.RowFlexCenter>
    </styles.Body>
  ) : (
    <NotFoundBlock message="Oh no! User has no dropout student data." />
  )
}
