import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchPausedMemberData } from 'app/store/actions/paymentEntity'

import { columns } from './config'

const Pause = () => {
  const pausedMemberData = useSelector(
    (state) => state.paymentEntityReducer.pausedMemberData
  )

  console.log(pausedMemberData);

  return (
    <BaseEntity
      entity={constants.PAUSED_MEMBER}
      label={constants.PAUSED_MEMBER_LABEL}
      fetchData={fetchPausedMemberData}
      data={pausedMemberData}
      columns={columns}
      readOnly
    />
  )
}

export default Pause
