import styled, { css } from 'styled-components'

export const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Heading = styled.div`
  font-family: AvenirDemi;
  font-size: 28px;
  margin: 10px;
  margin-bottom: 0;
`
export const SubWrapper = styled.div`
  display: flex;
  margin-left: 8px;
`

export const Icon = styled.img`
  height: 6px;
  width: 6px;
  margin-top: 7px;
`

export const TextWrapper = styled.div`
  font-size: 14px;
  font-family: Avenir;
  margin: 0 5px;
  font-weight: 600;
`

export const SchoolWrapper = styled.div`
  font-size: 14px;
  font-family: Avenir;
  margin: 5px 12px;
`
export const ParentWrapper = styled.div`
  width: 40%;
`

export const NoDataWrapper = styled.div`
  margin: 15px;
  font-weight: 600;
  font-size: 16px;
`
export const EditButtonWrapper = styled.div`
  display: flex;
`
