// using unstable_createMuiStrictModeTheme instead of createMuiTheme because of Warnings in strict mode : findDOMNode is deprecated in StrictMode
// https://github.com/mui-org/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'

import { getPalette } from './palette'

export const getTheme = (theme) => {
  const palette = getPalette(theme)

  return createTheme({
    typography: {
      fontFamily: ['Avenir'].join(',')
    },
    palette,
    overrides: {
      MuiListItem: {
        root: {
          color: palette.primary.dark,
          '&$selected': {
            backgroundColor: `${palette.secondary.light} !important`
          },
          '&:hover': {
            backgroundColor: `${palette.secondary.light} !important`
          }
        }
      },

      MuiTable: {
        root: {
          margin: 16,
          zIndex: 0
        }
      },
      MUIDataTable: {
        tableRoot: {
          marginTop: 0
        },
        paper: {
          '@media (min-width: 640px)': {
            width: 'calc(100vw - 270px)' // to fix table width , as drawer width is also fixed,
          }
        },
        responsiveBase: {
          display: 'flex !important',
          flexGrow: '1 !important',
          overflow: 'auto !important'
        }
      },
      MuiTableCell: {
        root: {
          whiteSpace: 'nowrap',
          height: 56,
          borderBottom: 'none'
        }
      },
      MUIDataTableSelectCell: {
        root: {
          minWidth: 50,
          backgroundColor: palette.background.default
        }
      },
      MUIDataTableHeadCell: {
        data: {
          fontWeight: 'bold',
          color: palette.primary.main,
          fontSize: '1.1em'
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:hover': {
            backgroundColor: `${palette.background.light} !important`
          },
          '&:nth-child(even)': {
            backgroundColor:
              theme === 'dark'
                ? palette.background.secondary
                : palette.secondary.light
          }
        }
      },
      MUIDataTableFooter: {
        root: {
          backgroundColor: palette.background.dark,
          margin: 0
        }
      },
      MUIDataTablePagination: {
        root: {
          color: palette.secondary.main
        },
        navContainer: {
          display: 'flex !important',
          justifyContent: 'center !important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          flex: 'none'
        }
      },
      MuiIconButton: {
        root: {
          '&.Mui-disabled': {
            color: palette.secondary.disabled
          }
        }
      },
      MuiTablePagination: {
        selectIcon: {
          color: palette.secondary.main
        }
      }
    }
  })
}
