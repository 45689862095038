import React from 'react';
import * as styles from './styles';

const AbilitiesCard = (props) => {
  const { abilitiesData } = props

  return (
    <styles.CardContainer>
      {abilitiesData?.map((item) => (
        <styles.Item>
          <styles.Icon src={item?.icon_url} />
          <styles.Text>{item?.ability_status}</styles.Text>
          <styles.Count>{item?.count}</styles.Count>
        </styles.Item>
      ))
      }
    </styles.CardContainer>
  )
}

export default AbilitiesCard;
