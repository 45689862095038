
import React from 'react'
import * as styles from './styles'
import { NotFoundBlock } from '../../../../../components/NotFoundBlock'
import { TitleDescription } from '../../../../../components/TitleDescription'
import { isEmpty } from 'lodash'


export const Journeys = (props) => {
  const { journeyData } = props
  const status_count = journeyData?.status_count

  return !isEmpty(journeyData?.students) ? (
    <styles.Body>
      <styles.ColumnFlexGap>
        <styles.ColumnFlexStart>
          <styles.RowFlexGap>
            {status_count?.map(item => (
              <TitleDescription
                heading={item?.heading}
                description={item?.count}
              />
            ))}
          </styles.RowFlexGap>
        </styles.ColumnFlexStart>
      </styles.ColumnFlexGap>
    </styles.Body>
  ) : <NotFoundBlock message="Oh no! No journeys found." />
}

