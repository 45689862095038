import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PrivateRoute from 'app/components/PrivateRoute'
import {
  navigationMenus,
  publicNavigationPaths,
  additionalNavigationPaths,
  mainLayoutNavigationPaths
} from 'app/config'
import MainLayout from 'app/layouts/MainLayout'
import RoutePages from 'app/routes/RoutePages'
import { WebSocketProvider } from 'app/WebSocketProvider'

const RouteHandler = () => {
  const buildMainRoutes = React.useCallback((menu) => {
    return menu.map((item) => {
      if (item.subMenus) {
        return buildMainRoutes(item.subMenus)
      } else if (item.path) {
        return (
          <PrivateRoute
            exact
            key={item.path}
            path={item.path}
            component={RoutePages[item.component || null]}
          />
        )
      }

      return null
    })
  }, [])

  const mainRoutes = React.useMemo(() => {
    return buildMainRoutes(navigationMenus)
  }, [buildMainRoutes])

  const [
    publicNavigationPathsArray,
    mainLayoutNavigationPathsArray,
    additionalNavigationPathsArray
  ] = React.useMemo(() => {
    return [
      Object.values(publicNavigationPaths),
      Object.values(mainLayoutNavigationPaths),
      Object.values(additionalNavigationPaths)
    ]
  }, [])

  return (
    <Switch>
      <Route exact path={publicNavigationPathsArray}>
        <Route exact path="/">
          <Redirect to={mainLayoutNavigationPaths.parentdashboard} />
        </Route>

        <React.Suspense fallback={null}>
          <Route
            exact
            path={publicNavigationPaths.login}
            component={RoutePages['LoginPage']}
          />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <Route
            exact
            path={publicNavigationPaths.dashboard}
            component={RoutePages['SchoolDashboardPage']}
          />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <Route
            exact
            path={publicNavigationPaths.schoolAttendance}
            component={RoutePages['SchoolAttendancePage']}
          />
        </React.Suspense>
      </Route>

      <Route exact path={mainLayoutNavigationPathsArray}>
        <WebSocketProvider>
          <MainLayout>
            <Switch>
              <React.Suspense fallback={null}>{mainRoutes}</React.Suspense>
            </Switch>
          </MainLayout>
        </WebSocketProvider>
      </Route>

      <Route exact path={additionalNavigationPathsArray}>
        <React.Suspense fallback={null}>
          <PrivateRoute
            exact
            path={additionalNavigationPaths.demoScheduling}
            component={RoutePages['DemoSchedulingPage']}
          />

          {/* <PrivateRoute
            exact
            path={additionalNavigationPaths.parentdashboard}
            component={RoutePages['ParentDashboardPage']}
          /> */}

          <PrivateRoute
            exact
            path={additionalNavigationPaths.scheduler}
            component={RoutePages['SchedulerPage']}
          />

          <PrivateRoute
            exact
            path={additionalNavigationPaths.schedule}
            component={RoutePages['CentreSchedulePage']}
          />
        </React.Suspense>
      </Route>

      <Route exact path={publicNavigationPaths.notFound} component={null} />
    </Switch>
  )
}

export default RouteHandler
