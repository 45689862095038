import styled from 'styled-components';


export const Item = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: row;
  justify-content: center;
`

export const Icon = styled.img`
  height: 1.25em;
  width: 1.25em;
  margin: 0 0.25em;
`

export const Text = styled.div`
  font-size: 0.9em;
  color: black;
`

export const Difference = styled.div`
  background-color: ${props => props.color};
  border-radius: 20px;
  border: 0.5px solid #6A6A6E;
  display: flex;
  flex-direction: row;
  padding: 0.25em 1em;
`
