import styled from 'styled-components'

export const Container = styled.div`
  padding: 1em;
`

export const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 1em;
  text-transform: capitalize;
`
