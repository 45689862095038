import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

import { apiEndpoints } from 'api'
import { tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    name: 'title',
    label: 'Author',
    formDataKey: 'title',
    widget: 'autocomplete',
    validate: validateNotEmpty,
    minCharactersToSearch: 0,
    widgetFilter: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'author'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=OPENBOOK_AUTHORS`
    },
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          value: { uuid: item.uuid, title: item.title },
          name: item.title
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      }

      return data.results[0]?.value?.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item
      }
      return data.results[0]?.value?.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'name',
      keyValue: 'value'
    }
  }
]

export const tableOptions = (isLoading) => ({
  enableNestedDataAccess: '.',
  filter: false,
  search: false,
  sort: false,
  print: false,
  download: false,
  delete: false,
  viewColumns: false,
  customToolbar: null,
  responsive: 'vertical',
  selectableRowsHideCheckboxes: true,
  customFooter: () => (isLoading ? <CircularProgress /> : <tfoot />),
  rowsPerPage: Infinity,
  textLabels: {
    body: {
      noMatch: !isLoading && (
        <span style={{ color: 'rgba(0, 0, 8, 0.5)', fontSize: '18px' }}>
          No data!
        </span>
      )
    }
  }
})
