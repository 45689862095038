import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React from 'react'

import { TextField } from 'app/components/generic/FormElements'
import { validateNotEmpty } from 'app/validators'
const ChangePhoneNumber = (props) => {
  const { errors, setFieldValue } = useFormikContext()

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.parent_number) ? { error: true } : null)}
        required
      >
        <TextField
          name={'parent_number'}
          label={'Present Number'}
          variant="outlined"
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.parent_number) && (
          <FormHelperText>{errors.parent_number}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.new_phone_number) ? { error: true } : null)}
        required
      >
        <TextField
          name={'new_phone_number'}
          label={'New Number'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
        />
      </FormControl>
    </>
  )
}

export default ChangePhoneNumber
