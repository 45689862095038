import styled from 'styled-components'

export const CardMonth = styled.div`
  margin: 1.5em;
  margin-left: 3em;
  margin-right: 2em;
  display: flex;
  flex-direction: row;
`

export const Month = styled.div`
  width: '8%';
  height: inherit;
`

export const Card = styled.div`
  margin-left: 1em;
  width: 100%;
`

export const HeaderContainer = styled.div`
  background-color: ${(props) => props.color};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  ${(props) =>
    props.collapsedCard
      ? `
    border-radius: 12px;
  `
      : ``}
  padding: 0.5em;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
`

export const HeaderExtraData = styled.div`
  display: flex;
  flex-direction: row;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 1em;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
`

export const BodyColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 0.75em;
  justify-content: start;
`

export const BodyRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const smallCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 1.25em;
  padding-bottom: 1.5em;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  align-items: center;
`

export const smallCardIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 0.25em;
`

export const smallCardText = styled.div`
  font-size: 1em;
`
