import { isFunction, isNil } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseTable from 'app/components/BaseTable'
import { Error } from 'app/components/Error'
import constants from 'app/constants'
import { confirm, eventEmitter } from 'app/helpers'

const BaseEntity = (props) => {
  const {
    entity,
    label,
    columns,
    data,
    fetchData,
    updateEntityData,
    disableDelete,
    CustomToolbarElement,
    createDependency,
    readOnly,
    disableEditButton,
    startFetchingOnMount,
    query,
    customHandleDelete,
    selectableRows,
    beforeSaveFunction,
    setIsCreateClicked
  } = props

  const serverDataFetchError = useSelector(
    (state) => state.appReducer.serverDataFetchError
  )

  const dispatch = useDispatch()

  const handleFetchData = React.useCallback(
    ({ isFiltering = false, isPaginating = false, ...rest } = {}) => {
      if (isFiltering || isPaginating || (!data.isLoaded && !data.isLoading)) {
        dispatch(fetchData({ ...rest }, isPaginating, isFiltering, entity))
      }
    },
    [dispatch, fetchData, entity]
  )

  const onSaveClick = React.useCallback(
    (formData) => {
      const onSave = async () => {
        dispatch(
          updateEntityData(
            entity,
            formData.isNewEntry ? constants.CREATED : constants.UPDATED,
            formData
          )
        )
      }
      const coinsPerSession = data?.data?.filter((data) => {
        return data.uuid === formData.uuid
      })
      const previousCoinsPerSession = coinsPerSession[0]?.coins_per_session
      const presentCoinsPerSession = parseInt(formData?.coins_per_session)

      if (
        entity === constants.BATCH &&
        formData?.enrolment_count > 0 &&
        previousCoinsPerSession !== presentCoinsPerSession
      ) {
        const onError = () => {
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        }
        const isConfirm = confirm(
          onSave,
          `This batch has ${formData?.enrolment_count} students enrolled. The coins for these enrolled students will not change. Reach out to the parent to confirm new per session value.`,
          onError
        )
      } else if (typeof beforeSaveFunction === 'function') {
        beforeSaveFunction(formData, onSave)
      } else onSave()
    },
    [entity, data]
  )

  const handleDelete = React.useCallback(
    (rowsDeleted) => {
      const handlerFunction = (queries) => {
        const idsToBeDeleted = rowsDeleted.data.map((item) => {
          return data.data[item.dataIndex].uuid || data.data[item.dataIndex].id
        })

        dispatch(
          updateEntityData(entity, constants.REMOVED, {
            ids: idsToBeDeleted,
            queries
          })
        )
        return false // returning false will not remove data from table immediately, table rows will depend on success delete response
      }

      if (isFunction(customHandleDelete)) {
        customHandleDelete(handlerFunction, rowsDeleted)
        return false
      } else {
        handlerFunction()
        return false
      }
    },
    [data, dispatch, entity, updateEntityData]
  )

  return (
    <>
      {!isNil(serverDataFetchError[entity]) ? (
        <Error error={serverDataFetchError[entity]} />
      ) : (
        <BaseTable
          data={data}
          fetchData={handleFetchData}
          onSaveClick={onSaveClick}
          handleDelete={handleDelete}
          columns={columns}
          label={label}
          disableDelete={disableDelete}
          CustomToolbarElement={CustomToolbarElement}
          createDependency={createDependency}
          readOnly={readOnly}
          disableEditButton={disableEditButton}
          startFetchingOnMount={startFetchingOnMount}
          query={query}
          modalCss={props.modalCss}
          selectableRows={selectableRows}
          setIsCreateClicked={setIsCreateClicked}
        />
      )}
    </>
  )
}

BaseEntity.defaultProps = {
  disableDelete: false,
  disableEditButton: false,
  createDependency: false,
  readOnly: false,
  startFetchingOnMount: true,
  query: {},
  setIsCreateClicked: null
}

BaseEntity.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  entity: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  updateEntityData: PropTypes.func,
  disableDelete: PropTypes.bool,
  CustomToolbarElement: PropTypes.object,
  disableEditButton: PropTypes.bool.isRequired,
  createDependency: PropTypes.bool.isRequired, // this is the case if new data creation is happening on click on another table
  startFetchingOnMount: PropTypes.bool,
  query: PropTypes.object,
  customHandleDelete: PropTypes.func,
  selectableRows: PropTypes.string,
  beforeSaveFunction: PropTypes.func,
  setIsCreateClicked: PropTypes.func
}

export default BaseEntity
