import {
  handleUpdateSelectedMessageTemplate,
  handleUpdateMessageTemplateParameter,
  handleOnBulkMessagesSend,
  handleUpdateUserType,
  updateSendMessageModalState,
  updateCreateMessageTemplateModalState,
  updateDeliveryStatsModalState,
  updateUsers,
  updateExcludedUsers,
  updateIncludedUsers,
  handleFetchUsers,
  handleFetchDeliveryStatsData,
  resetBulkMessagingCreationData,
  updateTargetAudienceSelectionMode,
  updateBulkMessagingUserFilters,
  handleBulkMessagesCreateValidation
} from 'app/store/actions/communicationEntity'
import { store } from 'app/store/store'

export const dispatchHandleUpdateSelectedMessageTemplate = (value) => {
  store.dispatch(handleUpdateSelectedMessageTemplate(value))
}

export const dispatchHandleUpdateMessageTemplateParameter = (event) => {
  store.dispatch(handleUpdateMessageTemplateParameter(event))
}

export const dispatchHandleBulkMessagesCreateValidation = () => {
  store.dispatch(handleBulkMessagesCreateValidation())
}

export const dispatchHandleOnBulkMessagesSend = () => {
  store.dispatch(handleOnBulkMessagesSend())
}

export const dispatchHandleUpdateUserType = (event) => {
  store.dispatch(handleUpdateUserType(event))
}

export const dispatchUpdateSendMessageModalState = (value) => {
  store.dispatch(updateSendMessageModalState(value))
}

export const dispatchUpdateCreateMessageTemplateModalState = (value) => {
  store.dispatch(updateCreateMessageTemplateModalState(value))
}

export const dispatchUpdateDeliveryStatsModalState = (value) => {
  store.dispatch(updateDeliveryStatsModalState(value))
}

export const dispatchUpdateUsers = (value, event) => {
  store.dispatch(updateUsers(value, event))
}

export const dispatchUpdateExcludedUsers = (value, event) => {
  store.dispatch(updateExcludedUsers(value, event))
}

export const dispatchUpdateIncludedUsers = (value, event) => {
  store.dispatch(updateIncludedUsers(value, event))
}

export const dispatchHandleFetchUsers = () => {
  store.dispatch(handleFetchUsers())
}

export const dispatchHandleFetchDeliveryStatsData = (id, deliveryType) => {
  store.dispatch(handleFetchDeliveryStatsData(id, deliveryType))
}

export const dispatchResetBulkMessagingCreationData = () => {
  store.dispatch(resetBulkMessagingCreationData())
}

export const dispatchUpdateTargetAudienceSelectionMode = (value) => {
  store.dispatch(updateTargetAudienceSelectionMode(value))
}

export const dispatchUpdateBulkMessagingUserFilters = (name, value) => {
  store.dispatch(updateBulkMessagingUserFilters(name, value))
}
