import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchBoardData } from 'app/store/actions/classEntity'

import { columns } from './config'

const Board = () => {
  const boardData = useSelector((state) => state.classEntityReducer.boardData)

  return (
    <BaseEntity
      entity={constants.BOARD}
      label={constants.BOARD_LABEL}
      columns={columns}
      data={boardData}
      fetchData={fetchBoardData}
      readOnly
    />
  )
}

export default Board
