import React from 'react';
import * as styles from './styles';

const HeaderTitle = (props) => {
  const { status, details } = props

  return (
    <styles.Container>
      <styles.CircleIcon />
      <styles.Text>{status}</styles.Text>
      <styles.Description>{details}</styles.Description>
    </styles.Container>
  );
}

export default HeaderTitle;
