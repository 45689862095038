import React from 'react'

import ModalForm from 'app/components/ModalForm'

import { MomentsTable } from '../MomentsTable'

export const MomentModal = (props) => {
  const { isModalOpen, onModalClose, data, modalType } = props
  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      enableSave={false}
    >
      {modalType === 'show_Moments' ? <MomentsTable data={data} /> : null}
    </ModalForm>
  )
}
