import React from 'react'

import { commonAttributes } from 'app/config'

import config from '../../common/config'

export const columns = [
  {
    ...commonAttributes.date,
    name: 'paid_on',
    label: 'Payment Date'
  },
  config.studentName,
  config.studentNumber,
  config.centre,
  config.subjects,
  config.teacher,
  config.batchTimings,
  {
    ...config.link,
    name: 'invoice_pdf_url',
    label: 'Invoice Link',
    options: {
      ...config.link.options,
      customBodyRender: (value) =>
        value ? (
          <a target="_blank" rel="noopener noreferrer" href={value}>
            Download
          </a>
        ) : null
    }
  },
  config.invoiceAmount,
  {
    name: 'oh_share',
    label: 'OH Share',
    options: {
      ...commonAttributes.amount.options,
      filterKey: 'oh_share'
    }
  },
  {
    name: 'partner_share',
    label: 'Teacher Share',
    options: {
      ...commonAttributes.amount.options,
      filterKey: 'partner_share'
    }
  },
  {
    name: 'gst',
    label: 'GST',
    options: {
      ...commonAttributes.amount.options,
      filterKey: 'gst'
    }
  },
  config.startDate,
  config.endDate,
  {
    name: 'mode',
    label: 'Payment Method',
    options: {
      ...commonAttributes.options,
      filterKey: 'mode'
    }
  },
  {
    name: 'dispatch_status',
    label: 'Dispatch Status',
    options: {
      ...commonAttributes.options,
      filterKey: 'dispatch_status'
    }
  },
  {
    name: 'notes',
    label: 'Dispatch Notes',
    options: {
      ...commonAttributes.options,
      disableFilter: true,
      filterKey: 'notes'
    }
  }
]
