/* eslint-disable react/prop-types */
import React from 'react'

import OhIcon from 'assets/images/oh_logo_transparent.svg'

import FlagsComponent from './FlagsComponent'
import * as styles from './styles'

import { TitleDescription } from '../../../components/TitleDescription'

export const MembershipBlock = (props) => {
  const { selectedParentDetails } = props

  const crmFlags = selectedParentDetails?.crm_flags

  const cardWidth = crmFlags?.length > 1 ? 548 : 264
  return (
    <styles.Wrapper width={cardWidth}>
      <styles.Body>
        <styles.InfoBlock>
          <TitleDescription
            heading="name"
            description={`${selectedParentDetails?.first_name} ${selectedParentDetails?.last_name}`}
          />
          {selectedParentDetails?.centre?.name ? (
            <TitleDescription
              heading="centre"
              description={
                <styles.Heading>
                  <styles.Icon src={OhIcon} alt="oh-icon" />
                  {`${selectedParentDetails?.centre?.name?.toLowerCase()}`}
                </styles.Heading>
              }
            />
          ) : null}
        </styles.InfoBlock>

        <styles.InfoBlock>
          <TitleDescription
            heading="FLAGS"
            description={<FlagsComponent flags={crmFlags} />}
          />
        </styles.InfoBlock>

        <styles.InfoBlock>
          {/* <TitleDescription
            heading="current membership status"
            description={selectedParentDetails?.member_status}
          /> */}
          {/* <TitleDescription
            heading="membership purchase"
            description={purchased_at ? dayjs(purchased_at).format('D MMM, YY') : 'NA'}
          /> */}
          {/* <TitleDescription
            heading="membership start"
            description={
              start_date ? dayjs(start_date).format('D MMM, YY') : 'NA'
            }
          /> */}
        </styles.InfoBlock>
      </styles.Body>
    </styles.Wrapper>
  )
}
