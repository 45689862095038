import * as types from '../types/userEntity'

export const fetchStudentData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_STUDENT_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchStudentDataSuccess = (value, entity) => ({
  type: types.FETCH_STUDENT_DATA_SUCCESS,
  value,
  entity
})

export const fetchSuperproviderData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_SUPERPROVIDER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchSuperproviderDataSuccess = (value, entity) => ({
  type: types.FETCH_SUPERPROVIDER_DATA_SUCCESS,
  value,
  entity
})

export const fetchTeacherData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_TEACHER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchTeacherDataSuccess = (value, entity) => ({
  type: types.FETCH_TEACHER_DATA_SUCCESS,
  value,
  entity
})

export const fetchAdminData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_ADMIN_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchAdminDataSuccess = (value, entity) => ({
  type: types.FETCH_ADMIN_DATA_SUCCESS,
  value,
  entity
})

export const fetchParentData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PARENT_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchParentDataSuccess = (value, entity) => ({
  type: types.FETCH_PARENT_DATA_SUCCESS,
  value,
  entity
})

export const updateUserData = (entity, event, value) => ({
  type: types.UPDATE_USER_DATA,
  entity,
  event,
  value
})

export const updateUserDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_USER_DATA_SUCCESS,
  entity,
  event,
  value
})
