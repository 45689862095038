import Dayjs from 'dayjs'
import React from 'react'

import { tableColumnDefaultOption, commonAttributes } from 'app/config'
import constants from 'app/constants'

const getTargetAudienceType = (value) => {
  if (Object.prototype.hasOwnProperty.call(value, 'students'))
    return constants.STUDENT
  else if (Object.prototype.hasOwnProperty.call(value, 'teachers'))
    return constants.TEACHER
  else if (Object.prototype.hasOwnProperty.call(value, 'parents'))
    return constants.PARENT

  return null
}

const getUserFilters = (filters) => {
  const modifiedFilters = []
  const filtersValues =
    filters?.students || filters?.teachers || filters?.parents || {}

  if (Object.prototype.hasOwnProperty.call(filtersValues, 'centres'))
    modifiedFilters.push(filtersValues.centres.join(', '))

  if (Object.prototype.hasOwnProperty.call(filtersValues, 'category'))
    modifiedFilters.push(
      Array.isArray(filtersValues.category)
        ? filtersValues.category.join(', ')
        : filtersValues.category
    )

  if (Object.prototype.hasOwnProperty.call(filtersValues, 'subjects'))
    modifiedFilters.push(filtersValues.subjects.join(', '))

  if (Object.prototype.hasOwnProperty.call(filtersValues, 'teachers'))
    modifiedFilters.push(filtersValues.teachers.join(', '))

  return modifiedFilters.join(' | ')
}

export const startingColumns = [
  { ...commonAttributes.uuid },
  {
    name: 'template',
    label: 'Template Name',
    ...tableColumnDefaultOption
  },
  {
    name: 'total',
    label: 'Sent to',
    options: {
      ...tableColumnDefaultOption,
      disableFilter: true
    }
  }
]

export const endingColumns = [
  {
    name: 'created',
    label: 'Date sent',
    options: {
      ...tableColumnDefaultOption,
      customBodyRender: (value) => {
        return (
          <span>
            {value ? <>{Dayjs(value).format('DD - MM - YYYY')}</> : null}
          </span>
        )
      }
    }
  },
  {
    name: 'send_by',
    label: 'Sent by',
    options: {
      ...tableColumnDefaultOption,
      customBodyRender: (value) => {
        return (
          <span>
            {value?.first_name} {value?.last_name}
          </span>
        )
      }
    }
  },
  {
    name: 'filters',
    label: 'User type',
    options: {
      ...tableColumnDefaultOption,
      customBodyRender: (value) => (
        <span>{value && getTargetAudienceType(value)}</span>
      )
    }
  },
  {
    name: 'parsed_filters',
    label: 'User Filters',
    options: {
      ...tableColumnDefaultOption,
      customBodyRender: (value) => {
        if (value) {
          return getUserFilters(value)
        }

        return null
      }
    }
  }
]

export const tableStyleOverridesOption = {
  MuiToolbar: {
    gutters: { paddingLeft: '0 !important', paddingRight: '0 !important' }
  },
  MuiTypography: {
    root: { fontFamily: 'Avenir !important' }
  },
  MuiTableCell: {
    root: {
      width: '33% !important',
      textAlign: 'center',
      fontFamily: 'Avenir'
    },
    head: {
      backgroundColor: '#282E3C !important',
      color: '#fff'
    }
  }
}

export const deliveryTypes = {
  DELIVERED: 'delivered',
  PENDING: 'pending',
  FAILED: 'failed'
}

export const deliveryStatuses = {
  [deliveryTypes.DELIVERED]: ['delivered', 'read', 'sent'],
  [deliveryTypes.PENDING]: ['pending', 'active', 'accepted'],
  [deliveryTypes.FAILED]: ['rejected']
}
