import { tableColumnDefaultOption, commonAttributes } from 'app/config'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'user.full_name',
    label: 'Name',
    ...tableColumnDefaultOption
  },
  {
    name: 'user.phone_number',
    label: 'Phone Number',
    ...tableColumnDefaultOption
  }
]
