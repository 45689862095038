import styled, { css } from 'styled-components'

export const SeparationContainer = styled.div`
  flex: 0.1;
  justify-content: center;
  align-items: center;

  hr {
    margin: auto;
  }

  ${(props) =>
    css`
      ${props.$css}
    `}
`
