import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

import { Transition } from 'app/components/common'

import 'react-circular-progressbar/dist/styles.css'

const styles = (theme) => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const useStyles = makeStyles((theme) => ({
  progessContainer: {
    padding: 100
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, failedCount, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {failedCount && onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const CircularProgress = (props) => {
  const { percentage, failedCount, total, isModalOpen } = props

  const classes = useStyles()
  const theme = useTheme()

  const handleClose = () => {
    if (props.onModalClose) {
      props.onModalClose()
    }
  }

  const progressBarStyle = {
    text: {
      fill: theme.palette.background.dark
    },
    path: {
      stroke: theme.palette.background.dark
    },
    ...(failedCount > 0
      ? { trail: { stroke: theme.palette.primary.main } }
      : null)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      TransitionComponent={Transition}
      data-testid="modal-form"
      fullWidth
    >
      <DialogTitle onClose={handleClose} failedCount={failedCount}>
        Hang On !
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.progessContainer}>
          <CircularProgressbar
            value={percentage}
            text={`${percentage} %`}
            styles={progressBarStyle}
          />
        </div>

        {failedCount > 0 ? (
          <Typography
            variant="subtitle2"
            gutterBottom
            className={classes.headingContainer}
            style={{
              color: theme.palette.primary.main
            }}
          >
            {failedCount} of {total} Failed
          </Typography>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

CircularProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  failedCount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onModalClose: PropTypes.func.isRequired
}

export default CircularProgress
