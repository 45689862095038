import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'

import { TextField } from 'app/components/generic/FormElements'

import { formatDateToLong, getMonthName } from '../../helpers'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  nudge: {
    color: theme.palette.error.main
  }
}))

const UnsubscribeForm = (props) => {
  const classes = useStyles()
  const { formData } = props
  const { errors, values, setFieldValue } = useFormikContext()

  React.useEffect(() => {
    setFieldValue(
      'month_display',
      getMonthName(formData?.month, true) + ' ' + formData?.year,
      false
    )
    setFieldValue('month', formData?.month, false) //needed for confirmation dialog
  }, [formData])

  const validateReason = (value) => {
    if (isEmpty(value)) {
      return 'Reason cannot be empty.'
    }
  }

  const formElement = React.useMemo(() => {
    return (
      <>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}>
          <TextField
            name={'student_name'}
            label={'Student Name'}
            variant="outlined"
            defaultValue={formData.student_name}
            disabled
            required
          />
          {!isEmpty(errors.student_name) && (
            <FormHelperText>{errors.student_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}>
          <TextField
            name="subject_name"
            label={'Subject'}
            variant="outlined"
            required
            defaultValue={formData.subject_name}
            disabled
          />
          {!isEmpty(errors.subject_name) && (
            <FormHelperText>{errors.subject_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.month) ? { error: true } : null)}>
          <TextField
            name={'month_display'}
            label={'Month'}
            variant="outlined"
            disabled
            required
          />
          {!isEmpty(errors.month) && (
            <FormHelperText>{errors.month}</FormHelperText>
          )}
          {formData?.last_session_date && (
            <FormHelperText
              className={classes.nudge}>{`Last session date: ${formatDateToLong(
              formData?.last_session_date
            )}`}</FormHelperText>
          )}
        </FormControl>

        <FormControl variant="outlined">
          <TextField
            name={'reason'}
            label={'Reason *'}
            variant="outlined"
            validate={validateReason}
            defaultValue={formData?.reason || ''}
          />
        </FormControl>
      </>
    )
  }, [formData, errors, values])

  return <>{formElement}</>
}

export default UnsubscribeForm
