export const arrivalStatus = [
  {
    id: '1a35cc51-af41-4c3c-ac26-dfc2dbdf85b7',
    title: 'Early ( > 15 mins)',
    value: 'early'
  },
  {
    id: '845fc617-dcea-4424-8221-2b74a76c1a79',
    title: 'On Time (0-15 mins)',
    value: 'on_time'
  },
  { id: '0e504a67-9124-49aa-8ee7-6ef9c487b253', title: 'Late', value: 'late' }
]
