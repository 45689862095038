import styled from 'styled-components'

export const Parent = styled.div`
  width: 100%;
`
export const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  color: var(--oh-orange);
  @media (max-width: 500px) {
    text-align: center;
    font-size: 18px;
  }
`
export const SubTitle = styled.div`
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
  color: var(--oh-black);
  margin-bottom: 20px;
  @media (max-width: 500px) {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 12px;
  }
`
export const MobileLabel = styled.div`
  font-size: 16px;
  color: var(--oh-black);
  margin-bottom: 8px;
  text-align: left;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
export const MobileNumberBox = styled.div`
  display: flex;
  justify-content: space-between;
`
export const MobileInput = styled.input`
  height: 30px;
  width: 60%;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 18px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  border: 1px solid ${(p) => (p.invalid ? 'var(--oh-red)' : 'var(--oh-blue)')};
  background: rgba(102, 181, 222, 0.05);
  @media (max-width: 500px) {
  }
`
export const OtpButton = styled.div`
  background: ${(p) =>
    p.isDisabled ? 'var(--oh-disabled)' : 'var(--oh-blue)'};
  border: 1px solid rgba(102, 181, 222, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
export const MobileInputValidation = styled.div`
  color: var(--oh-red);
  font-size: 10px;
  margin-top: 4px;
  text-align: left;
  display: ${(p) => (p.invalid ? 'block' : 'none')};
`
export const OtpLabel = styled.div`
  margin-top: 15px;
  font-size: 16px;
  color: var(--oh-black);
  margin-bottom: 8px;
  text-align: left;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
export const OtpInput = styled.input`
  width: 93%;
  height: 30px;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 18px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  border: 1px solid ${(p) => (p.invalid ? 'var(--oh-red)' : 'var(--oh-blue)')};
  background: rgba(102, 181, 222, 0.05);
`
export const OTPValidation = styled.div`
  color: var(--oh-red);
  font-size: 10px;
  margin-top: 4px;
  text-align: left;
  display: ${(p) => (p.invalid ? 'block' : 'none')};
`
export const LoginButton = styled.div`
  background: ${(p) =>
    p.isDisabled ? 'var(--oh-disabled)' : 'var(--oh-teal)'};
  border: 1px solid rgba(102, 181, 222, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`
export const Footer = styled.div`
  margin-top: 50px;
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 30px;
  }
`
export const FooterTitle = styled.div`
  font-size: 14px;
  color: var(--oh-black);
  @media (max-width: 500px) {
    text-align: center;
    font-size: 12px;
  }
`
export const FooterLink = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: var(--oh-blue);
  text-decoration-line: underline;
  cursor: pointer;
  @media (max-width: 500px) {
    text-align: center;
    font-size: 12px;
  }
`
export const SupportLink = styled.div`
  text-align: center;
`
