import Dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { isArray, isEmpty } from 'lodash'
import isNumber from 'lodash/isNumber'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchDemoSchedule, getEnrolment } from 'api'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'
import { batchTypesMap, levelMap } from 'app/pages/Class/Inventory/constants'
import { fetchScheduleSessionActivityData } from 'app/store/actions/classEntity'
import { selectScheduleActivityDetails } from 'app/store/selectors/classEntity/scheduleActivity'
import { formatAmount } from 'app/utils'

import { StudentsList } from './components/StudentsList'
import * as Styles from './styles'

import { composeBatchDetails } from '../../../../transformers'

Dayjs.extend(customParseFormat)

const BatchDetails = () => {
  const [batchDetails, setBatchDetails] = React.useState({})
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [studentsData, setStudentsData] = React.useState({
    isLoading: false,
    data: []
  })

  const scheduleActivity = useSelector(selectScheduleActivityDetails)

  const dispatch = useDispatch()

  React.useEffect(() => {
    const fetchStudents = async (batchId) => {
      setStudentsData((studentsData) => ({
        ...studentsData,
        isLoading: true
      }))

      try {
        const data = await getEnrolment({ batchId })
        setStudentsData({
          data: data.enrolment,
          isLoading: false
        })
      } catch (error) {
        console.log('error in fetching students data: ', error)
        setStudentsData((studentsData) => ({
          ...studentsData,
          isLoading: false
        }))
      }
    }

    const handler = async (batch) => {
      const queries = {
        batch_uuid: batch.uuid,
        date: batch.start_date
      }
      setBatchDetails(composeBatchDetails(batch))
      setIsModalOpen(true)
      await fetchStudents(batch.uuid)
      dispatch(
        fetchScheduleSessionActivityData(
          { url: null, queries: { ...queries } },
          false,
          false,
          constants.SCHEDULE_SESSION_ACTIVITY
        )
      )
    }
    eventEmitter.addListener(constants.BATCH_DETAILS_MODAL, handler)
    return () => {
      eventEmitter.removeListener(constants.BATCH_DETAILS_MODAL, handler)
    }
  }, [])

  const {
    subject,
    teacherName,
    timing,
    alternateBatches,
    location,
    enrolmentCapacity,
    standards,
    price,
    vacancy,
    coins,
    type,
    level,
    subjectsName,
    subjectsDetail,
    coins_monthly
  } = batchDetails

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      enableSave={false}
      maxWidth="lg"
      showCloseIcon
      onModalClose={() => {
        setIsModalOpen(false)
      }}>
      <Styles.Container>
        {subjectsName && <Styles.Heading>{subjectsName}</Styles.Heading>}
        {teacherName && <Styles.SubTitle>by {teacherName}</Styles.SubTitle>}
        <Styles.DescriptionContainer>
          <Styles.DescriptionLeftContainer>
            {subjectsDetail && <pre>{subjectsDetail}</pre>}
            <Styles.Title>Session Details</Styles.Title>
            {scheduleActivity?.data &&
              scheduleActivity?.data[0]?.schedule_activities?.map((item) => {
                return (
                  <>
                    <Styles.KeyValue>
                      <Styles.Key>{item?.subject?.name}:</Styles.Key>
                      <Styles.Value>{item?.subject?.description}</Styles.Value>
                    </Styles.KeyValue>
                  </>
                )
              })}
            <Styles.Title>Batch Info</Styles.Title>
            <Styles.DetailsContainer>
              <Styles.Attributes>
                {timing && (
                  <Styles.KeyValue>
                    <Styles.Key>Time:</Styles.Key>
                    <Styles.Value>{timing}</Styles.Value>
                  </Styles.KeyValue>
                )}

                {alternateBatches && (
                  <Styles.KeyValue>
                    <Styles.Key>Batch Timings:</Styles.Key>
                    <Styles.Value>
                      {alternateBatches.reduce((prev, curr, index) => [
                        prev,
                        <br key={index} />,
                        curr
                      ])}
                    </Styles.Value>
                  </Styles.KeyValue>
                )}

                {location && (
                  <Styles.KeyValue>
                    <Styles.Key>Centre:</Styles.Key>
                    <Styles.Value>{location}</Styles.Value>
                  </Styles.KeyValue>
                )}

                <Styles.KeyValue>
                  <Styles.Key>Batch strength:</Styles.Key>
                  <Styles.Value>
                    {enrolmentCapacity > 0
                      ? enrolmentCapacity
                      : `${enrolmentCapacity} (closed batch)`}
                  </Styles.Value>
                </Styles.KeyValue>

                <Styles.KeyValue>
                  <Styles.Key>Vacancy:</Styles.Key>
                  <Styles.Value>{vacancy > 0 ? vacancy : 0}</Styles.Value>
                </Styles.KeyValue>
              </Styles.Attributes>
            </Styles.DetailsContainer>
          </Styles.DescriptionLeftContainer>
          <Styles.DescriptionRightContainer>
            <Styles.DetailsContainer>
              <Styles.Attributes>
                {standards && (
                  <Styles.KeyValue>
                    <Styles.Key>Grades:</Styles.Key>
                    <Styles.Value>
                      {standards.map((item) => item.short_code).join(',')}
                    </Styles.Value>
                  </Styles.KeyValue>
                )}

                {level && (
                  <Styles.KeyValue>
                    <Styles.Key>Batch Level:</Styles.Key>
                    <Styles.Value>{levelMap[level]}</Styles.Value>
                  </Styles.KeyValue>
                )}

                {type === batchTypesMap.excel && coins_monthly ? (
                  <Styles.KeyValue>
                    <Styles.Key>Coins per month:</Styles.Key>
                    <Styles.Value>{coins_monthly}</Styles.Value>
                  </Styles.KeyValue>
                ) : type !== batchTypesMap.exploration ? (
                  isNumber(price) && (
                    <Styles.KeyValue>
                      <Styles.Key>Price:</Styles.Key>
                      <div className="group">
                        <div>
                          <span>Monthly Fee - </span>
                          <Styles.Value>{formatAmount(price)}</Styles.Value>
                        </div>
                        <div>
                          <span>Coins per session - </span>
                          <Styles.Value>{coins}</Styles.Value>
                        </div>
                      </div>
                    </Styles.KeyValue>
                  )
                ) : null}
              </Styles.Attributes>
            </Styles.DetailsContainer>
          </Styles.DescriptionRightContainer>
        </Styles.DescriptionContainer>

        <StudentsList studentsData={studentsData} />
      </Styles.Container>
    </ModalForm>
  )
}

export default BatchDetails
