import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import { makeStyles } from '@material-ui/styles'
import { Button, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIndianStates, topUpSubscription, membershipActions } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import ModalForm from 'app/components/ModalForm'
import { ParentDetails } from 'app/components/shared/ParentDetails'
import {
  app_statuses,
  commonAttributes,
  nonMemberActions,
  parentActions,
  tableColumnDefaultOption
} from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import { fetchParentData, updateUserData } from 'app/store/actions/userEntity'
import { parentDataTransformer } from 'app/transformer'
import { validateNotEmpty } from 'app/validators'

import { columns } from './config'
import MasterForm from './MasterForm'
import './styles.css'

const Parent = () => {
  const parentData = useSelector((state) => state.userEntityReducer.parentData)
  const [states, setStates] = React.useState({})
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState([])
  const [profileDetailsModalOpen, setProfileDetailsModalOpen] =
    React.useState(false)
  const [parentDetails, setParentDetails] = React.useState([])
  const handleClick = (event, id) => {
    let anchorEls = [...anchorEl]
    anchorEls[id] = event.target
    setAnchorEl(anchorEls)
  }
  const handleClose = (id) => {
    let anchorEls = [...anchorEl]
    anchorEls[id] = null
    setAnchorEl(anchorEls)
  }

  const profileButtonOnclickHandler = async (dataIndex) => {
    const parentDetailsData = await parentDataTransformer(
      parentData.data[dataIndex]
    )
    setParentDetails(parentDetailsData)
    setProfileDetailsModalOpen(true)
  }

  React.useEffect(() => {
    ;(async () => {
      const { data: indianStates } = await getIndianStates()
      const statesMap = {}
      indianStates.forEach((state) => {
        statesMap[state[0]] = state[1]
      })
      setStates(statesMap)
    })()
  }, [])

  const openModal = React.useCallback((e, action, dataIndex) => {
    e.preventDefault()
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }
  const closeProfileDetailsModal = () => {
    setProfileDetailsModalOpen(false)
    setParentDetails([])
  }
  const onActionButtonClick = async (e, action, dataIndex) => {
    handleClose(dataIndex)
    openModal(e, action, dataIndex)
  }

  const onSaveClick = async (newData, action) => {
    try {
      const res = await membershipActions(newData, action)
      if (
        res?.success ||
        res?.subscription?.uuid ||
        res?.uuid ||
        res?.user_id
      ) {
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Done', constants.SUCCESS)
        closeModal()
        const queries = JSON.parse(localStorage.getItem('filterQueries'))
        dispatch(
          fetchParentData({ ...queries }, false, false, constants.PARENT)
        )
      }
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 6),
      {
        ...tableColumnDefaultOption,
        name: 'user.state',
        label: 'State',
        widget: 'select',
        valueLabelMappings: states,
        defaultValue: () =>
          new URLSearchParams(window.location.search).get('state'),
        modalEdit: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'state',
          disableFilter: true,
          customBodyRender: (value) => <span>{states[value]}</span>,
          customBodyRenderLite: (dataIndex) => {
            const { state } = parentData.data[dataIndex]?.user || {}
            return <span>{states[state]}</span>
          }
        }
      },
      ...columns.slice(6),
      {
        ...commonAttributes.date,
        modalEdit: false,
        disabledInForm: true,
        name: 'user.active_subscription.current_cycle.end_date',
        label: 'Plan renewal date',
        options: {
          ...commonAttributes.date.options,
          filterKey: 'plan_renewal_date',
          fixedColumn: false,
          customBodyRenderLite: (dataIndex) => {
            const { active_subscription } =
              parentData?.data[dataIndex]?.user || {}
            return <span>{active_subscription?.current_cycle?.end_date}</span>
          }
        }
      },
      {
        modalEdit: false,
        disabledInForm: true,
        name: 'user.app_status',
        label: 'App status',
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'app_status',
          fixedColumn: false
        }
      },
      {
        name: 'user.member_status',
        label: 'Member status',
        widget: 'select',
        modalEdit: false,
        disabledInForm: true,
        validate: validateNotEmpty,
        valueLabelMappings: app_statuses,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          customBodyRender: (value) => <span>{value ? value : null}</span>
        }
      },
      {
        name: 'actions',
        disabledInForm: true,
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { user } = parentData.data[dataIndex]
            const status = user?.member_status

            const userActions = status ? parentActions : nonMemberActions

            return (
              <div key={dataIndex}>
                <Button
                  aria-controls={
                    Boolean(anchorEl[dataIndex])
                      ? 'demo-customized-menu'
                      : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={
                    Boolean(anchorEl[dataIndex]) ? 'true' : undefined
                  }
                  variant="contained"
                  onClick={() => profileButtonOnclickHandler(dataIndex)}
                  className="button-alfred">
                  view profile details
                </Button>
                <Button
                  id={'Button' + dataIndex}
                  aria-controls={
                    Boolean(anchorEl[dataIndex])
                      ? 'demo-customized-menu'
                      : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={
                    Boolean(anchorEl[dataIndex]) ? 'true' : undefined
                  }
                  variant="contained"
                  onClick={(e) => handleClick(e, dataIndex)}
                  endIcon={<KeyboardArrowDownIcon />}
                  className="button-alfred">
                  Options
                </Button>
                <Menu
                  id={'Menu' + dataIndex}
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                  }}
                  anchorEl={anchorEl[dataIndex]}
                  open={Boolean(anchorEl[dataIndex])}
                  onClose={() => handleClose(dataIndex)}>
                  {Object.keys(userActions)
                    .filter((el) =>
                      el === 'extend' && status === 'due' ? false : true
                    )
                    .map((action, index) => {
                      return (
                        <MenuItem
                          onClick={(e) => {
                            onActionButtonClick(e, action, dataIndex)
                          }}
                          disableRipple
                          key={index}>
                          {userActions[action]}
                        </MenuItem>
                      )
                    })}
                </Menu>
              </div>
            )
          },
          disableFilter: true
        }
      }
    ]
  }, [states, parentData, anchorEl])

  return (
    <>
      <BaseEntity
        entity={constants.PARENT}
        label={constants.PARENT_LABEL}
        columns={modifiedColumns}
        data={parentData}
        fetchData={fetchParentData}
        updateEntityData={updateUserData}
        disableDelete
        modalCss={`
        .MuiDialogContent-root {
            overflow-y: unset;
        }
      `}
      />
      <MasterForm
        cartType="recharge"
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={parentData?.data[editIndex]}
      />
      <ModalForm
        isModalOpen={profileDetailsModalOpen}
        onModalClose={closeProfileDetailsModal}
        enableSave={false}>
        <ParentDetails parentDetails={parentDetails} />
      </ModalForm>
    </>
  )
}

export default Parent
