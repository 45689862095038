import React from 'react'

export const columns = [
  {
    name: 'user.uuid',
    label: 'uuid',
    options: {
      filter: true,
      display: 'excluded'
    }
  },
  {
    name: 'user.full_name',
    label: 'Name',
    options: {
      filter: true
    }
  },
  {
    name: 'user.phone_number',
    label: 'Phone Number',
    options: {
      filter: false
    }
  }
]

export const tableOptions = {
  enableNestedDataAccess: '.',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: 'none',
  customToolbar: null,
  responsive: 'vertical',
  textLabels: {
    body: {
      noMatch: (
        <span style={{ color: 'rgba(0, 0, 8, 0.5)', fontSize: '18px' }}>
          No teachers added!
        </span>
      )
    }
  }
}
