import { isEmpty } from 'lodash'

import constants from 'app/constants'

import { apiEndpoints } from './common'

import { makeRequestUrl, showToast } from '../app/helpers'
import { get } from '../request'

const fetchMoments = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.moments}`, { ...queries })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    showToast('Oops, Error in fetching moments', constants.ERROR)
    console.log('error in fetching moments :- ', error.message)
    throw error
  }
}

const fetchMomentComments = async (
  { url, momentId, queries } = { queries: {} }
) => {
  try {
    const requestUrl = !isEmpty(url)
      ? url
      : makeRequestUrl(`${apiEndpoints.moments}/${momentId}/comments`, {
          ...queries
        })
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    showToast('Oops, Error in fetching comments', constants.ERROR)
    console.log('error in fetching comments :- ', error.message)
    throw error
  }
}

const fetchMomentConsumer = async ({ url, queries } = { queries: {} }) => {
  try {
    const requestUrl = !isEmpty(url)
      ? url
      : makeRequestUrl(`${apiEndpoints.momentConsumer}`, {
          ...queries
        })

    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    showToast('Oops, Error in fetching moment consumers', constants.ERROR)
    console.log('error in fetching moment consumers :- ', error.message)
    throw error
  }
}

export { fetchMoments, fetchMomentComments, fetchMomentConsumer }
