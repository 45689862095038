import * as types from '../types/paymentEntity'

export const fetchDueData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_DUE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchDueDataSuccess = (value, entity) => ({
  type: types.FETCH_DUE_DATA_SUCCESS,
  value,
  entity
})

export const fetchPaidData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_PAID_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchPaidDataSuccess = (value, entity) => ({
  type: types.FETCH_PAID_DATA_SUCCESS,
  value,
  entity
})

export const fetchDueMemberData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_DUE_MEMBER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchDueMemberDataSuccess = (value, entity) => ({
  type: types.FETCH_DUE_MEMBER_DATA_SUCCESS,
  value,
  entity
})

export const fetchPaidMemberData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PAID_MEMBER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchPaidMemberDataSuccess = (value, entity) => ({
  type: types.FETCH_PAID_MEMBER_DATA_SUCCESS,
  value,
  entity
})

export const fetchDueRenewalData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_DUE_RENEWAL_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchDueRenewalDataSuccess = (value, entity) => ({
  type: types.FETCH_DUE_RENEWAL_DATA_SUCCESS,
  value,
  entity
})

export const fetchPaidRenewalData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PAID_RENEWAL_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchPaidRenewalDataSuccess = (value, entity) => ({
  type: types.FETCH_PAID_RENEWAL_DATA_SUCCESS,
  value,
  entity
})

export const fetchCanceledMemberData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_CANCELED_MEMBER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchCanceledMemberDataSuccess = (value, entity) => ({
  type: types.FETCH_CANCELED_MEMBER_DATA_SUCCESS,
  value,
  entity
})

export const fetchPausedMemberData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PAUSED_MEMBER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchPausedMemberDataSuccess = (value, entity) => ({
  type: types.FETCH_PAUSED_MEMBER_DATA_SUCCESS,
  value,
  entity
})

export const fetchStudentSummaryData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_STUDENT_SUMMARY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchStudentSummaryDataSuccess = (value, entity) => ({
  type: types.FETCH_STUDENT_SUMMARY_DATA_SUCCESS,
  value,
  entity
})

export const fetchTeacherSummaryData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_TEACHER_SUMMARY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchTeacherSummaryDataSuccess = (value, entity) => ({
  type: types.FETCH_TEACHER_SUMMARY_DATA_SUCCESS,
  value,
  entity
})

export const fetchSuperproviderSummaryData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_SUPERPROVIDER_SUMMARY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchSuperproviderSummaryDataSuccess = (value, entity) => ({
  type: types.FETCH_SUPERPROVIDER_SUMMARY_DATA_SUCCESS,
  value,
  entity
})

export const fetchIndependentSummaryData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_INDEPENDENT_SUMMARY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchIndependentSummaryDataSuccess = (value, entity) => ({
  type: types.FETCH_INDEPENDENT_SUMMARY_DATA_SUCCESS,
  value,
  entity
})

export const fetchIndependentOngoingSummaryData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchIndependentOngoingSummaryDataSuccess = (value, entity) => ({
  type: types.FETCH_INDEPENDENT_ONGOING_SUMMARY_DATA_SUCCESS,
  value,
  entity
})

export const fetchDispatchData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_DISPATCH_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchDispatchDataSuccess = (value, entity) => ({
  type: types.FETCH_DISPATCH_DATA_SUCCESS,
  value,
  entity
})

export const updatePaymentData = (entity, event, value) => ({
  type: types.UPDATE_PAYMENT_DATA,
  entity,
  event,
  value
})

export const updatePaymentDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_PAYMENT_DATA_SUCCESS,
  entity,
  event,
  value
})
