import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import _, { isEmpty } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api'
import { AutoComplete } from 'app/components/generic/FormElements'
import { makeRequestUrl } from 'app/helpers'
import { validateNotEmpty } from 'app/validators'

const ProgramSelectionForm = (props) => {
  const { formData } = props
  const { errors, setFieldValue } = useFormikContext()
  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.program) ? { error: true } : null)}
        required
      >
        <AutoComplete
          name={'program'}
          placeholder="Select program"
          minCharactersToSearch={0}
          getStructuredValues={(data) => {
            const getItemStructure = (item) => {
              return item
                ? {
                    uuid: item,
                    name: item.name
                  }
                : null
            }

            if (Array.isArray(data)) {
              return data?.map((item) => {
                return getItemStructure(item)
              })
            } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
              return data?.results?.map((item) => {
                return getItemStructure(item)
              })
            }

            return [getItemStructure(data)]
          }}
          getFormDataValue={(data) => {
            const getItemStructure = (item) => {
              return item.uuid
            }
            return getItemStructure(data)
          }}
          getOptionLabelKey="name"
          getOptionSelectedKey="uuid"
          resultStructure={{
            keyName: 'program',
            keyValue: 'uuid'
          }}
          apiOptions={{
            method: 'get',
            url: makeRequestUrl(`${apiEndpoints.programs}/programs`, {
              exclude: 'excel'
            })
          }}
          required={true}
          setFieldValue={setFieldValue}
          formData={formData}
          validate={validateNotEmpty({ isRequired: true })}
        />
        {!isEmpty(errors.program) && (
          <FormHelperText>{errors.program}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default ProgramSelectionForm
