import { isEmpty } from 'lodash'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, post } from '../request'

const demoSchedulingRequestStructure = (data) => ({
  user_uuid: data.user_uuid,
  demo_scheduled: data.demo_scheduled,
  mini_course: data.mini_course,
  send_nurturing_message: data.send_nurturing_message
})

const fetchDemoSchedule = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.schedule, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching demo schedule :- ', error.message)
    throw error
  }
}

const createDemoSchedule = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.growth}/schedule_new_demo`,
      demoSchedulingRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating demo schedule :- ', error.message)
    throw error
  }
}

export { fetchDemoSchedule, createDemoSchedule }
