import {
  commonStructure,
  dataResponseHandler,
  dataFetchingHandler
} from './reducer.helper'

import * as types from '../types/ordersEntity'

const initialState = {
  ordersData: commonStructure()
}

const ordersEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDERS_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_ORDERS_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    default:
      return state
  }
}

export { ordersEntityReducer }
