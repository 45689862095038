import qs from 'querystring'

import { isMobilePhone } from '@openhouse-technologies/utils'
import Dayjs from 'dayjs'
import dayjs from 'dayjs'
import EventEmitter from 'eventemitter3'
import { isEmpty, isObject, isUndefined, get } from 'lodash'
import { store as notificationStore } from 'react-notifications-component'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'

import { getNewAccessToken as getNewAccessTokenApi, fetchUser } from 'api'
import {
  OPENDAY_SLOTS_MINUTE_INTERVAL,
  bulkMessagingFilterTypes
} from 'app/config'
import constants, { RECHARGE_REASON } from 'app/constants'
import { decodeJWT, isTokenExpired } from 'app/utils'
import { sentryLogger } from 'sentry'

const _eventEmitter = new EventEmitter()
export const eventEmitter = {
  addListener: (event, func) => _eventEmitter.addListener(event, func),
  removeListener: (event, func) => _eventEmitter.removeListener(event, func),
  emit: (event, data) => _eventEmitter.emit(event, data)
}

// freezing eventEmitter, so that later it won't get modified by any chance
Object.freeze(eventEmitter)

export const cookies = new Cookies()

const defaultCookieOption = {
  path: '/',
  secure: process.env.NODE_ENV !== 'development', // not secure only in development env
  maxAge: 30 * 86400 // 30 days
}

const subdomainCookieOption = {
  sameSite: 'lax',
  ...(process.env.NODE_ENV !== 'development'
    ? { domain: '.openhouse.study' }
    : null)
}

const cookieOption = {
  ...defaultCookieOption,
  ...subdomainCookieOption
}

export const getCookie = (key) => {
  return cookies.get(key)
}

export const setCookie = (key, value) => {
  return cookies.set(key, value, cookieOption)
}

export const removeCookie = (key) => {
  cookies.remove(key, cookieOption)
  /**
   * try to remove cookie with defaultCookieOption as fallback
   * bcz remove config should be same as create config
   */
  cookies.remove(key, defaultCookieOption)
}

export const refreshAllCookies = () => {
  const allCookies = cookies.getAll()
  console.log('refreshing all cookies :- ', allCookies)

  for (const [cookieName, cookieValue] of Object.entries(allCookies)) {
    removeCookie(cookieName)
    setCookie(cookieName, cookieValue)
  }
}

export const getAccessToken = () => {
  const token = getCookie(constants.ACCESS_TOKEN_KEY)

  if (token && !isTokenExpired(token)) {
    return token
  }
}

export const setAccessToken = (value, name) => {
  setCookie(name, value)
}

export const setAccessTokenKey = (type) => {
  return `${type.toUpperCase()}-TOKEN-${process.env.REACT_APP_CUSTOM_NODE_ENV.toUpperCase()}`
}

export const redirectToOrigin = () => {
  window.location.href = window.location.origin
}

export const clearStorage = () => {
  try {
    const keys = [
      constants.ACCESS_TOKEN_KEY,
      constants.REFRESH_TOKEN_KEY,
      constants.REFRESH_TOKEN_LAST_UPDATED
    ]

    keys.forEach((key) => removeCookie(key))
  } catch (error) {
    console.log(error)
  }
}

export const getNewAccessToken = async () => {
  const accessToken = getAccessToken()
  const refreshToken = getCookie(constants.REFRESH_TOKEN_KEY)
  const refreshTokenLastUpdated = getCookie(
    constants.REFRESH_TOKEN_LAST_UPDATED
  )

  if (accessToken && refreshToken && refreshTokenLastUpdated) {
    // calculate difference in days
    const lastUpdatedAgoInDays = new Dayjs().diff(
      new Dayjs(refreshTokenLastUpdated),
      'days'
    )

    // fetch new token if its older than 25 days
    if (lastUpdatedAgoInDays > 25) {
      try {
        const response = await getNewAccessTokenApi({
          refresh_token: refreshToken
        })

        setAccessToken(response.access_token, constants.ACCESS_TOKEN_KEY)
        setAccessToken(response.refresh_token, constants.REFRESH_TOKEN_KEY)
        setCookie(
          constants.REFRESH_TOKEN_LAST_UPDATED,
          new Dayjs().format('YYYY/MM/DD HH:mm:ss')
        )
        return response.access_token
      } catch (error) {
        console.log('error in getting new access token :- ', error.message)
        sentryLogger(error)
        throw error
      }
    } else {
      return accessToken
    }
  }
}

export const isUserAuthenticated = () => {
  const accessToken = getCookie(constants.ACCESS_TOKEN_KEY)

  if (accessToken) {
    return isTokenExpired(accessToken) === false
  }
  return false
}

export const getLoggedInUserId = () => {
  try {
    const accessToken = decodeJWT(getAccessToken())

    if (accessToken && !isTokenExpired(accessToken)) {
      return accessToken.sub
    }
  } catch (error) {
    sentryLogger(error, 'error in getting logged in user id')
  }
}

export const getLoggedInUserName = () => {
  try {
    const accessTokenString = getAccessToken()
    const accessToken = decodeJWT(accessTokenString)

    if (accessToken && !isTokenExpired(accessTokenString)) {
      return accessToken.name
    }
  } catch (error) {
    console.log('error in getting logged in user name :- ', error)
  }
}

export const checkIfNetworkError = (error) => {
  return (
    error.isAxiosError &&
    isUndefined(error.response) &&
    error.message === constants.NETWORK_ERROR
  )
}

export const getNetworkResponseError = (error) => {
  const defaultError = {
    isError: true,
    fieldErrors: {},
    errorMessage: ''
  }

  try {
    if (checkIfNetworkError(error)) {
      return {
        ...defaultError,
        errorMessage: constants.NETWORK_ERROR
      }
    } else {
      if (error.response.data.error) {
        const errorMessage =
          error.response.data.errorMessage || error.response.data.message

        if (!isEmpty(errorMessage)) {
          return {
            ...defaultError,
            errorMessage
          }
        }
      } else if (!isEmpty(error.response.data)) {
        const fieldErrors = {}

        for (const [key, value] of Object.entries(error.response.data)) {
          fieldErrors[key] = Array.isArray(value) ? value.join('\n') : value
        }

        return {
          ...defaultError,
          errorMessage: '',
          fieldErrors
        }
      }
    }

    return defaultError
  } catch (error) {
    // returning defaultError in case of any exception above
    sentryLogger(error)
    return defaultError
  }
}

export const apiFormattedResult = (data) => {
  if (data) {
    return {
      count: data.count,
      data: !isUndefined(data.results) ? data.results : data.data,
      previous: data.previous,
      next: data.next,
      ...(data.label ? { label: data.label } : null),
      ...(data.due ? { due: data.due } : null),
      ...(data.paid ? { paid: data.paid } : null)
    }
  }
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export const showToast = (message, type) => {
  if (type === constants.SUCCESS) {
    Toast.fire({
      icon: 'success',
      title: message
    })
  } else if (type === constants.ERROR) {
    Toast.fire({
      icon: 'error',
      title: message
    })
  } else if (type === constants.INFO) {
    Toast.fire({
      icon: 'info',
      title: message
    })
  }
}
/**
 *
 * @param {*} successFunc which will be called if yes is pressed
 * @param {*} message the confirm message
 */
export const confirm = (
  successFunc,
  message = "You won't be able to revert this!",
  errorFunc,
  options = {}
) => {
  Swal.fire({
    title: 'Are you sure?',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    ...options
  }).then((result) => {
    if (result.isConfirmed) {
      successFunc()
    } else errorFunc()
  })
}

export const eventAlert = (message, type, title) => {
  if (type === constants.SUCCESS) {
    Swal.fire(title || 'Success', message, type)
  } else if (type === constants.ERROR) {
    Swal.fire(title || 'Error', message, type)
  }
}

export const confirmDialog = async ({
  text = "You won't be able to revert this!",
  confirmButtonText = 'Yes, delete it!',
  options = {}
}) => {
  return Swal.fire({
    title: 'Are you sure?',
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText,
    ...options
  })
}

export const alertDialog = async (options = {}) => {
  return Swal.fire({
    title: 'Alert',
    text: 'This is an alert',
    icon: 'warning',
    iconColor: 'rgba(184, 39, 65, 1)',
    showConfirmButton: false,
    ...options
  })
}

export const makeRequestUrl = (url, queries) => {
  if (!isEmpty(url) && !isEmpty(queries)) {
    const formattedQueries = {}
    Object.keys(queries).forEach((key) => {
      let queryPrefix = key
      if (queryPrefix.indexOf('.') > -1) {
        queryPrefix = queryPrefix.replace(/\./g, '__')
      }

      if (queries[key] !== undefined) {
        formattedQueries[queryPrefix] = queries[key]
      }
    })
    return `${url}${url.indexOf('?') > -1 ? '&' : '?'}${qs.stringify(
      formattedQueries
    )}`
  }
  return url
}

// below helpers taken from oh admin dashboard for login, modify later if needed
export function isNullOrEmpty(params, condition = 'or') {
  let isInvalid = false
  let counter = 0
  if (Array.isArray(params)) {
    if (params.length) {
      params.forEach((value, key) => {
        if (
          value === undefined ||
          value === null ||
          value === 0 ||
          value === false ||
          value.length === 0 ||
          value === ''
        ) {
          isInvalid = true
          counter++
        }
      })
    } else {
      isInvalid = true
    }
  } else if (
    params === undefined ||
    params === null ||
    params === '' ||
    params === 0 ||
    params === false ||
    params === []
  ) {
    isInvalid = true
  }
  if (Array.isArray(params) && condition === 'and') {
    if (counter === params.length) {
      return true
    }
    return false
  }
  return isInvalid
}

export function notify(title, message, type, duration = 3000) {
  try {
    message = message || ' '
    notificationStore.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration,
        onScreen: true
      },
      showIcon: false,
      pauseOnHover: true
    })
  } catch (e) {
    console.error(e)
  }
}

export const getJMName = () => localStorage.getItem('journey_mentor')

export const removeJMName = () => localStorage.removeItem('journey_mentor')

export const roundedDecimal = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export const computeBulkMessagingUserFiltersQueries = (userFilters) => {
  const getFilterName = {
    [bulkMessagingFilterTypes.CENTRE]: 'centre_uuids',
    [bulkMessagingFilterTypes.TEACHER]: 'teacher_uuids',
    [bulkMessagingFilterTypes.CATEGORY]: 'category',
    [bulkMessagingFilterTypes.SUBJECT]: 'subject_uuids'
  }

  const modifiedQueries = Object.fromEntries(
    Object.entries(userFilters)
      .filter(([_, value]) => !isEmpty(value))
      .map((item) => [
        getFilterName[item[0]],
        item[1].map((item) => (isObject(item) ? item.value : item)).join(',')
      ])
  )

  return modifiedQueries
}

export const modifyQueries = (queries = {}) => {
  if (queries?.auto_booking) {
    const autoBookingValMap = {
      in: true,
      out: false
    }
    queries.auto_booking = autoBookingValMap[queries.auto_booking.toLowerCase()]
  }

  return queries
}

export const userEditModalHandler = (path, uuid) => {
  window.open(`${window.location.origin}${path}?editMode=1&uuid=${uuid}`)
}

export const validateParentDetails = (details) => {
  const { centre_preference, user } = details || {}

  let isValid = false
  const invalidAttributes = []

  const isValidFirstName = !isEmpty(user?.first_name)
  const isValidLastName = !isEmpty(user?.last_name)
  const isValidMobile = isMobilePhone(user?.phone_number || '', 'en-IN')
  const isValidState = !isEmpty(user?.state)
  const isValidCentre = !isEmpty(centre_preference)

  if (
    isValidFirstName &&
    isValidLastName &&
    isValidMobile &&
    isValidState &&
    isValidCentre
  ) {
    isValid = true
  } else {
    isValid = false

    if (!isValidFirstName) invalidAttributes.push('first_name')
    if (!isValidLastName) invalidAttributes.push('last_name')
    if (!isValidMobile) invalidAttributes.push('phone_number')
    if (!isValidState) invalidAttributes.push('state')
    if (!isValidCentre) invalidAttributes.push('centre')
  }

  return {
    isValid,
    invalidAttributes
  }
}

export const createScheduleData = (date) => {
  const scheduleData = []
  const givenDate = new Date(date)
  for (let i = 0; i < 12; i++) {
    const nextDate = new Date(
      givenDate.getFullYear(),
      givenDate.getMonth() + i + 1,
      0
    )
    const month = (nextDate.getMonth() + 1).toString().padStart(2, '0')
    const day = nextDate.getDate().toString().padStart(2, '0')

    const dateData = {
      label: `${nextDate.getFullYear()}-${month}-${day}`,
      value: `${nextDate.getFullYear()}-${month}-${day}`
    }

    scheduleData.push(dateData)
  }

  return scheduleData
}

export const findMaxDate = (date1, date2) => {
  const [d1, d2] = [date1, date2].map(dayjs)
  return d1.isAfter(d2) ? d1 : d2
}

export const convertArrayToObject = (arr) => {
  return arr.reduce((obj, str) => {
    obj[str] = str
    return obj
  }, {})
}

export const fetchTeacher = async (searchedText) => {
  try {
    var numberReg = /^\d+$/
    var aplhaReg = /^[a-zA-Z ]*$/
    var uuidReg =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

    if (searchedText?.length >= 0) {
      let queries = {}

      if (numberReg.test(searchedText)) queries = { phone_number: searchedText }
      else if (aplhaReg.test(searchedText)) queries = { name: searchedText }
      else if (uuidReg.test(searchedText)) queries = { uuid: searchedText }

      const response = await fetchUser({ queries }, constants.TEACHER)

      if (response.results.length >= 0) {
        return response.results.map((item) => {
          const { uuid, full_name, phone_number } = item?.user

          return {
            value: uuid,
            name: `${full_name} (${phone_number})`
          }
        })
      } else if (response.results.length > 1 && searchedText.length === 10) {
        throw new Error('Multiple teacher found with same number')
      } else {
        return {
          value: '',
          name: `Teacher not found`
        }
      }
    }
  } catch (error) {
    console.log('Error in fetching teacher :- ', error)
    throw error
  }
}

export function flattenObjectArray(array, keysMap) {
  return array.map((element) => {
    let result = {}
    for (const [key, value] of Object.entries(keysMap)) {
      result[key] = get(element, value)
    }
    return result
  })
}

export const canAddReferralCode = (values) => {
  return (
    !isEmpty(values.addCoinsType) &&
    values.reason === RECHARGE_REASON.RECHARGE &&
    !values?.has_referred_by
  )
}

export const formatTimeString = (timeString) => {
  const [hours, minutes] = timeString?.split(':').map(Number) ?? [0, 0]
  const amPm = hours >= 12 ? 'pm' : 'am'
  const hour12 = hours % 12 === 0 ? 12 : hours % 12
  const formattedMinutes = minutes.toString().padStart(2, '0')
  return `${hour12}:${formattedMinutes} ${amPm}`
}

export const getOpendayTimeSlots = (start, end) => {
  const slots = {}
  const startingHour = start ? start.split(':')[0] : 0
  const endingHour = end ? end.split(':')[0] : 24
  let startingMinute = 0

  for (let hour = Number(startingHour); hour < Number(endingHour); hour++) {
    startingMinute = 0
    if (hour === Number(startingHour))
      startingMinute =
        Number(start?.split(':')[1]) + OPENDAY_SLOTS_MINUTE_INTERVAL
    for (
      let minute = startingMinute;
      minute < 60;
      minute += OPENDAY_SLOTS_MINUTE_INTERVAL
    ) {
      const key = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}:00`
      const value = formatTimeString(key)
      slots[key] = value
    }
  }

  return slots
}

export const formatCadence = (cadence) => {
  if (!Array.isArray(cadence)) {
    return ''
  }

  const formattedCadence = cadence
    .sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
    .map((item) => {
      const start = item.start_time.split('-').join(':')
      const end = item.end_time.split('-').join(':')
      return `${dayjs()
        .day(item.weekday >= 0 && item.weekday < 7 ? item.weekday + 1 : 7)
        .format('ddd')}, ${dayjs('1/1/1 ' + start).format('h:mma')} - ${dayjs(
          '1/1/1 ' + end
        ).format('h:mma')}`
    })
    .join(' | ')

  return formattedCadence
}

export const formatLevel = (level) => {
  if (level === '1') return 'Beginner'
  else if (level === '2') return 'Intermediate'
  else if (level === '3') return 'Advanced'
}

export const getMonthYear = (dateString) => {
  const dateObject = new Date(dateString)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const monthIndex = dateObject.getMonth()
  const year = dateObject.getFullYear()
  return `${monthNames[monthIndex]} ${year}`
}
