import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { fetchWalletData } from 'api'
import Button, { useStyles } from 'app/components/generic/Button'
import ModalForm from 'app/components/ModalForm'
import { getWalletv1Data } from 'app/pages/Dashboard/ParentDashboard/helpers'

import { PastTransactionsTable } from './PastTransactionsTable'

dayjs.extend(isSameOrAfter)

const PastWalletTransactions = (props) => {
  const { isModalOpen, closeModal, parentData, enrolmentData = null } = props
  const [transactionsData, setTransactionsData] = React.useState([])

  const subtitle = `${parentData?.first_name} ${parentData?.last_name} - ${parentData?.phone_number}`

  const getPastTransactionsData = React.useCallback(async () => {
    if (parentData.uuid && enrolmentData) {
      const walletData = await fetchWalletData({
        parent: parentData.uuid
      })
      const transactionData = getWalletv1Data(walletData, enrolmentData)
      setTransactionsData(transactionData)
    }
  }, [parentData?.uuid, enrolmentData])

  useEffect(() => {
    return () => {
      setTransactionsData([])
      enrolmentData = null
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (parentData.uuid && !isEmpty(enrolmentData))
        await getPastTransactionsData()
    })()
  }, [parentData.uuid, enrolmentData])

  return (
    <>
      <ModalForm
        containerStyles={{ borderRadius: 10 }}
        className="border"
        isModalOpen={isModalOpen}
        onModalClose={closeModal}
        enableSave={false}
        modalTitle="Opening Balance Transactions"
        modalSubtitle={subtitle}
        showCloseIcon
      >
        <PastTransactionsTable data={transactionsData} />
      </ModalForm>
    </>
  )
}

export default PastWalletTransactions
