import { isEmpty } from 'lodash'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get } from '../request'

const getRatings = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.feedback}`, { ...queries, limit: 100 })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting enrolment :- ', error.message)
    throw error
  }
}

export { getRatings }
