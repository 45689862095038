import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isMobilePhone from 'validator/lib/isMobilePhone'

import { createInvoiceAction, sendTemplateMessage } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { memberActions, topupActions } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import { fetchDueMemberData } from 'app/store/actions/paymentEntity'

import { columns } from './config'
import DueMemberActionForm from './DueMemberActionForm'

const sendPaymentReminder = async (invoiceId, type) => {
  if (type === 'subscription') {
    try {
      const response = await sendTemplateMessage({
        template_name: 'generic_membership_reminder',
        params: {
          invoice_uuid: invoiceId
        }
      })

      return response
    } catch (error) {
      throw error
    }
  }
}

const DueMember = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)

  const dueMemberData = useSelector(
    (state) => state.paymentEntityReducer.dueMemberData
  )
  const dispatch = useDispatch()

  const CustomToolbarElement = <div>Total : ₹ {dueMemberData?.due ?? 0}</div>

  const openModal = React.useCallback((e, action, dataIndex) => {
    e.preventDefault()
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onSaveClick = async (newData, action) => {
    try {
      await createInvoiceAction({
        ...newData,
        action,
        invoice: dueMemberData.data[editIndex].uuid
      })

      eventEmitter.emit(constants.CLOSE_MODAL_FORM)
      eventAlert('Done', constants.SUCCESS)
      closeModal()
      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchDueMemberData({ ...queries }, false, false, constants.DUE_MEMBER)
      )
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'remind') {
      const { uuid, student_phone_number, parent_phone_number, type } =
        dueMemberData?.data[dataIndex] || {}

      const isStudentPhoneAvailable = isMobilePhone(
        student_phone_number || '',
        'en-IN'
      )

      const isParentPhoneAvailable = isMobilePhone(
        parent_phone_number || '',
        'en-IN'
      )

      if (isStudentPhoneAvailable || isParentPhoneAvailable) {
        let userName = ''
        let userNumber = ''

        if (isParentPhoneAvailable) {
          userName = dueMemberData?.data[dataIndex]?.parent_name
          userNumber = parent_phone_number
        } else {
          userName = dueMemberData?.data[dataIndex]?.student_name
          userNumber = student_phone_number
        }

        try {
          await sendPaymentReminder(uuid, type)

          eventAlert(
            `Reminder sent to ${userName} (${userNumber})`,
            constants.SUCCESS
          )
        } catch (error) {
          eventAlert(error.message, constants.ERROR)
        }
      } else {
        eventAlert('No Phone Number Available', constants.ERROR)
      }
    } else {
      openModal(e, action, dataIndex)
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { type } = dueMemberData?.data[dataIndex]
            const actions = type === 'topup' ? topupActions : memberActions
            return (
              <ButtonGroup>
                {Object.keys(actions).map((action, index) => (
                  <Button
                    onClick={(e) => {
                      onActionButtonClick(e, action, dataIndex)
                    }}
                    key={index}
                  >
                    {actions[action]}
                  </Button>
                ))}
              </ButtonGroup>
            )
          }
        }
      }
    ]
  }, [onActionButtonClick])

  return (
    <>
      <BaseEntity
        entity={constants.DUE_MEMBER}
        label={constants.DUE_MEMBER_LABEL}
        fetchData={fetchDueMemberData}
        data={dueMemberData}
        columns={modifiedColumns}
        CustomToolbarElement={CustomToolbarElement}
        readOnly
      />
      <DueMemberActionForm
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
      />
    </>
  )
}

export default DueMember
