/* eslint-disable react/prop-types */
import { Delete, Check, Save } from '@material-ui/icons'
import { Tooltip } from '@mui/material'
import React from 'react'

import { uploadAndGetFile, uploadFile } from 'api'
import { uploadPresets } from 'app/config'
import constants from 'app/constants'

import { Container, UploadButton, Progress, DeleteButton } from './styles'

export const Asset = ({
  rawFile,
  assetType,
  onDelete,
  asset,
  onUpload,
  newMedia
}) => {
  const [progress, setProgress] = React.useState(0)
  const [success, setSuccess] = React.useState(rawFile ? false : true)
  const [loading, setLoading] = React.useState(false)

  const public_id = [
    asset?.alt.slice(0, asset?.alt.indexOf('.')),
    new Date().getTime()
  ].join('-')

  const handleUpload = async (file) => {
    try {
      setLoading(true)

      let getfile = ''
      if (newMedia) {
        const uploadedFile = await uploadAndGetFile(
          file,
          {
            uploadPreset: uploadPresets[constants.CENTRE],
            public_id: public_id
          },
          (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100.0) / progressEvent.total
            )
            setProgress(progress)
          },
          assetType // image / video
        )
        getfile = uploadedFile
      } else {
        const { secure_url } = await uploadFile(
          file,
          {
            uploadPreset: uploadPresets[constants.CENTRE],
            public_id: public_id
          },
          (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100.0) / progressEvent.total
            )
            setProgress(progress)
          },
          assetType // image / video
        )
        getfile = secure_url
      }
      setSuccess(true)
      onUpload(getfile)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Container>
      <Tooltip
        title={`upload ${assetType}`}
        placement="top"
        open={!success && !loading}
        arrow
      >
        <UploadButton
          success={success}
          size="small"
          color={success ? 'inherit' : 'primary'}
          aria-label={`upload ${assetType}`}
          component="span"
          onClick={() => {
            if (loading || success) return
            handleUpload(rawFile)
          }}
        >
          {success ? <Check /> : <Save />}
        </UploadButton>
      </Tooltip>
      {loading && <Progress size={32} variant="determinate" value={progress} />}
      <DeleteButton
        size="small"
        color="primary"
        aria-label={`delete ${assetType}`}
        component="span"
        disabled={loading}
        onClick={onDelete}
      >
        <Delete />
      </DeleteButton>
      {assetType === 'image' && <img {...asset} />}
      {assetType === 'video' && <video {...asset} controls />}
    </Container>
  )
}
