import styled from 'styled-components'

export const Container = styled.div`
  width: 31.5em;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  height: max-content;
`

export const InnerContainer = styled.div`
  padding: 10px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 5px 20px 5px;
  align-items: center;
`
export const Icon = styled.img`
  height: 16px;
  width: 16px;
`

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: Avenir;
  font-weight: 600;
  font-size: 14px;
  padding-left: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
`

export const CardWrapper = styled.div`
  justify-content: space-between;
  gap: 20px;
`
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
