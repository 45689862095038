import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { uploadAndGetFile, uploadFile } from 'api'
import FileUpload from 'app/components/generic/FileUpload'
import constants from 'app/constants'
import { notify } from 'app/helpers'

const useStyles = makeStyles((theme) => ({
  fileUploadContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  fileUploadDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  uploadMessage: {
    marginLeft: theme.spacing(2)
  }
}))

const initialState = {
  showProgress: false,
  fileName: '',
  progress: 0
}

const CloudFileUploader = (props) => {
  const [uploadData, setUploadData] = React.useState(initialState)
  const {
    label,
    onUpload,
    name,
    uploadedFileUrl,
    uploadPreset,
    setFieldValue,
    fileUrlOnly,
    useNewMediaUpload = false
  } = props

  const classes = useStyles()

  const handleFileUploadProgress = React.useCallback((progressEvent) => {
    const progress = Math.round(
      (progressEvent.loaded * 100.0) / progressEvent.total
    )
    setUploadData((state) => ({
      ...state,
      progress
    }))
  }, [])

  const handleFileUploadOnChange = React.useCallback(
    async (event) => {
      const { name, files } = event.target
      const public_id = [
        files[0].name.slice(0, files[0].name.indexOf('.')),
        new Date().getTime()
      ].join('-')

      // enable progress
      setUploadData((state) => ({
        ...state,
        fileName: files[0].name,
        showProgress: true
      }))

      try {
        if (useNewMediaUpload) {
          const response = await uploadAndGetFile(
            files[0],
            { uploadPreset, public_id },
            handleFileUploadProgress
          )

          if (typeof onUpload === 'function') {
            onUpload({ name, data: response })
          }

          if (typeof setFieldValue === 'function') {
            setFieldValue(name, response)
          }
        } else {
          const response = await uploadFile(
            files[0],
            { uploadPreset, public_id },
            handleFileUploadProgress
          )

          if (typeof onUpload === 'function') {
            onUpload({ name, data: response })
          }

          if (typeof setFieldValue === 'function') {
            const { secure_url } = response

            const fieldValue = fileUrlOnly ? secure_url : response
            setFieldValue(name, fieldValue)
          }
        }
      } catch (error) {
        console.log(error)
        notify(constants.ERROR_MESSAGE, '', 'info')
      }
    },
    [onUpload, handleFileUploadProgress, uploadPreset, setFieldValue]
  )

  return (
    <div className={classes.fileUploadContainer}>
      <FileUpload
        label={`${!isEmpty(uploadedFileUrl) ? 're' : ''}upload ${label}`}
        onUpload={handleFileUploadOnChange}
        name={name}
      />
      <div className={classes.fileUploadDescriptionContainer}>
        {!isEmpty(uploadedFileUrl) ? (
          <a href={uploadedFileUrl} rel="noopener noreferrer" target="_blank">
            <span className={classes.uploadMessage}>See Uploaded File</span>
          </a>
        ) : null}
        {uploadData.showProgress ? (
          <span className={classes.uploadMessage}>
            {uploadData.fileName} ( Uploaded - {uploadData.progress} % )
          </span>
        ) : null}
      </div>
    </div>
  )
}

CloudFileUploader.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  uploadedFileUrl: PropTypes.string.isRequired,
  uploadPreset: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  fileUrlOnly: PropTypes.bool
}

export default CloudFileUploader
