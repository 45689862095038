/* eslint-disable react/prop-types */
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { isArray, range, isEmpty } from 'lodash'
import React from 'react'

import { fetchSubject } from 'api'
import { AutoComplete } from 'app/components/generic/FormFields'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

import { HeaderFields } from './config'
import * as Styles from './styles'

import {
  batchTypes,
  BookingTypes,
  FilterTypes,
  levelList
} from '../../constants'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  }
}))

const ages = range(3, 19).map((item) => ({ uuid: item.toString(), name: item }))

const Header = ({
  selectedBatchType,
  selectedGrades,
  selectedCategories,
  selectedLevel,
  onChangeFilter
}) => {
  const classes = useStyles()

  /**
   * FIXME: this listener is only to force refresh new schedules data
   * after successful booking, remove this later if any better way available to do this
   */
  React.useEffect(() => {
    const handler = () => {
      onChangeFilter(FilterTypes.CATEGORY, [...selectedCategories])
    }

    eventEmitter.addListener(constants.INVENTORY_BOOKING_SUCCESS, handler)

    return () => {
      eventEmitter.removeListener(constants.INVENTORY_BOOKING_SUCCESS, handler)
    }
  }, [selectedCategories])

  const handleOnChange = (event) => {
    const { name, value } = event.target
    onChangeFilter(name, value)

    if (name === FilterTypes.BATCH_TYPE) {
      // store result in local storage
      localStorage.setItem('SELECTED_BATCH_TYPE', JSON.stringify(value))
    }
  }

  const handleAutoCompleteOnChange = async (name, values) => {
    onChangeFilter(name, values)

    if (name === FilterTypes.CENTRE) {
      // store result in local storage
      console.log('values are', values)
      localStorage.setItem('SELECTED_CENTRE', JSON.stringify(values))
      const options = await fetchSubjectValues()
    }
  }

  const getValue = (name) => {
    switch (name) {
      case FilterTypes.BATCH_TYPE:
        return selectedBatchType
      case FilterTypes.LEVEL:
        return selectedLevel

      default:
        return []
    }
  }

  const renderValues = (name) => {
    switch (name) {
      case FilterTypes.BATCH_TYPE:
        return batchTypes
      case FilterTypes.LEVEL:
        return levelList
      case FilterTypes.CATEGORY:
        return fetchSubjectValues

      default:
        return []
    }
  }

  const fetchSubjectValues = async (searchedText) => {
    try {
      const localStorageCentre = JSON.parse(
        localStorage.getItem('SELECTED_CENTRE')
      )

      const queries = {
        limit: 500,
        centre_uuid: localStorageCentre.value
      }

      if (!isEmpty(searchedText)) {
        queries.name = searchedText
      }

      const response = await fetchSubject({ queries })

      let values = response.results.map((item) => ({
        value: item.uuid,
        name: item.name
      }))

      values.unshift({ value: null, name: 'ALL SUBJECTS' })

      return values
    } catch (error) {
      console.log('Error in fetching subjects :- ', error)
      throw error
    }
  }

  const centreDefaultValue = localStorage.getItem('SELECTED_CENTRE')
    ? JSON.parse(localStorage.getItem('SELECTED_CENTRE'))
    : null

  const modifiedHeaderField =
    selectedBatchType === BookingTypes.EXCEL
      ? [
        ...HeaderFields,

        {
          name: FilterTypes.LEVEL,
          label: 'Batch Level',
          inputType: 'select'
        }
      ]
      : HeaderFields

  const renderFields = () => {
    return (
      <>
        {modifiedHeaderField.map((field) => {
          if (field.inputType === 'select') {
            return (
              <FormControl
                key={field.name}
                variant="outlined"
                className={classes.formControl}>
                <InputLabel>{field.label}</InputLabel>
                <Select
                  value={getValue(field.name)}
                  name={field.name}
                  label={field.label}
                  onChange={handleOnChange}
                  renderValue={(selectedItems) => {
                    return isArray(selectedItems)
                      ? selectedItems
                        .map((selectedItem) => {
                          return renderValues(field.name).find(
                            (item) => item.uuid === selectedItem
                          ).name
                        })
                        .join(', ')
                      : renderValues(field.name).find(
                        (item) => item.uuid === selectedItems
                      ).name
                  }}
                  {...(field.multiple ? { multiple: true } : null)}>
                  {renderValues(field.name).map((value, index) => (
                    <MenuItem key={index} value={value.uuid}>
                      {field.multiple && (
                        <Checkbox
                          color="primary"
                          checked={
                            getValue(field.name).indexOf(value.uuid) > -1
                          }
                        />
                      )}
                      <ListItemText primary={value.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          } else if (field.inputType === 'autocomplete') {
            return (
              <FormControl
                key={field.name}
                variant="outlined"
                className={classes.formControl}>
                <AutoComplete
                  async={field.async}
                  name={field.name}
                  label={field.label}
                  multiple={field.multiple}
                  fetchValues={field.fetchValues || renderValues(field.name)}
                  onChange={handleAutoCompleteOnChange}
                  initialSelectIndex={field.initialSelectIndex}
                  minCharactersToSearch={field.minCharactersToSearch}
                  searchWithEmptyValue={true}
                  {...(field.name === FilterTypes.CENTRE && centreDefaultValue
                    ? { defaultValue: centreDefaultValue }
                    : null)}
                />
              </FormControl>
            )
          }

          return null
        })}
      </>
    )
  }

  return (
    <Styles.Container>
      <Styles.Heading>Batches</Styles.Heading>
      <Styles.FormFields>{renderFields()}</Styles.FormFields>
    </Styles.Container>
  )
}

export default Header
