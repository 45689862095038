import isEmpty from 'lodash/isEmpty'

import constants from 'app/constants'
import { makeRequestUrl } from 'app/helpers'

import { apiEndpoints, validateUpdateRequest } from './common'

import { post, get, patch } from '../request'

const addTribeMemberRequestStructure = (data) => ({
  first_name: data?.member?.user?.first_name,
  last_name: data?.member?.user?.last_name,
  phone_number: data?.member?.user?.phone_number,
  tribe_uuid: data.uuid
})

const fetchActiveTribeMember = async ({ url, queries } = {}) => {
  queries = {
    ...queries,
    status: constants.ACTIVE
  }
  const tribeUUID =
    new URLSearchParams(window.location.search).get('tribe') || ''

  const school = new URLSearchParams(window.location.search).get('school') || ''

  const formattedUrl = school
    ? `${apiEndpoints.tribe}/school/membership`
    : tribeUUID
    ? `${apiEndpoints.tribe}/alfred_tribe_members?tribe=${tribeUUID}`
    : `${apiEndpoints.tribe}/alfred_tribe_members`

  const requestUrl = !isEmpty(url) ? url : makeRequestUrl(formattedUrl, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching batch :- ', error.message)
    throw error
  }
}

const fetchInactiveTribeMember = async ({ url, queries } = {}) => {
  queries = {
    ...queries,
    status: constants.OTHERS
  }
  const tribeUUID =
    new URLSearchParams(window.location.search).get('tribe') || ''

  const formattedUrl = tribeUUID
    ? `${apiEndpoints.tribe}/alfred_tribe_members?tribe=${tribeUUID}`
    : `${apiEndpoints.tribe}/alfred_tribe_members`

  const requestUrl = !isEmpty(url) ? url : makeRequestUrl(formattedUrl, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching batch :- ', error.message)
    throw error
  }
}

const addTribeMember = async (data) => {
  validateUpdateRequest(data)
  try {
    const response = await post(
      `${apiEndpoints.addTribe}`,
      addTribeMemberRequestStructure(data)
    )
    return response.data
  } catch (error) {
    console.log('error in updating club :- ', error.message)
    throw error
  }
}

const updateNotes = async (data) => {
  try {
    const response = await patch(`${apiEndpoints.updateNotes}/${data.uuid}/`, {
      notes: data.notes
    })
    return response.data
  } catch (error) {
    console.log('error in updating notes :- ', error.message)
    throw error
  }
}
export {
  addTribeMember,
  fetchActiveTribeMember,
  fetchInactiveTribeMember,
  updateNotes
}
