import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
`

export const Heading = styled.div`
  font-family: 'AvenirDemi';
  font-size: 22px;
  font-weight: 600;
  color: #282E3C;
  margin-top: 20px;
  margin-bottom: 0;
`

export const SubHeading = styled.div`
  display: flex;
  font-size: 16px;
  font-family: Avenir;
  font-weight: 400;
`