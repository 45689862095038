import Button from '@material-ui/core/Button'
import dayjs from 'dayjs'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchDueAmount,
  terminateEnrolment as terminateEnrolmentApi
} from 'api'
import BaseEntity from 'app/components/BaseEntity'
import { useStyles } from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import {
  batchTypesMap,
  enrolmentActions,
  tableColumnDefaultOption
} from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import {
  fetchTrialSessionsData,
  updateClassData
} from 'app/store/actions/classEntity'
import { getCurrentMonth } from 'app/utils'

import { columns } from './config'

import MasterModal from '../components/MasterModal'

const TrialSessions = () => {
  const trialSessionsData = useSelector(
    (state) => state.classEntityReducer.trialSessionsData
  )
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)
  const [dueAmount, setDueAmount] = React.useState({
    enrolment_uuid: '',
    data: [],
    dataIndex: null,
    due: 0
  })

  const dispatch = useDispatch()

  const openModal = React.useCallback((action, dataIndex) => {
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'terminate_enrolment') {
      terminateEnrolment(dataIndex)
    }
  }

  const terminateEnrolment = React.useCallback(
    async (dataIndex) => {
      const { enrolment } = trialSessionsData.data[dataIndex]
      const currentMonth = getCurrentMonth()

      try {
        const response = await fetchDueAmount({
          enrolment: enrolment.uuid,
          month: currentMonth
        })

        const dueAmount = {
          enrolment_uuid: enrolment.uuid,
          data: response.results,
          dataIndex: dataIndex,
          due: response.due
        }

        setDueAmount(dueAmount)

        if (dueAmount.data.length === 0 && dueAmount.dataIndex > -1) {
          openModal('terminate_enrolment', dueAmount.dataIndex)
        } else {
          eventAlert(
            'This enrolment has due invoices, please update status and try again',
            constants.ERROR
          )
        }
      } catch (error) {
        console.log(error)
        eventAlert(constants.ERROR_MESSAGE, constants.ERROR)
      }
    },
    [trialSessionsData, openModal]
  )

  const onSaveClick = async (newData, action) => {
    const { enrolment } = trialSessionsData.data[editIndex]
    try {
      if (action === 'terminate_enrolment') {
        await terminateEnrolmentApi({
          enrolment_uuid: enrolment.uuid,
          amount_to_charge: dueAmount.due,
          notes: newData.notes
        })

        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Enrolment Terminated Successfully', constants.SUCCESS)
        closeModal()
      }

      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchTrialSessionsData(
          { ...queries },
          false,
          false,
          constants.TRIAL_SESSIONS
        )
      )
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const classes = useStyles()

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'attendances',
        label: 'Attendance',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'attendance',
          disableFilter: true,
          customBodyRender: (value, index) => {
            const { end_date, batch } =
              trialSessionsData.data[index.rowIndex].enrolment
            const { attended, cancelled } =
              trialSessionsData.data[index.rowIndex]

            const currentDate = new Date()
            const sessionEndDate = new Date(end_date)
            const isSameDay =
              currentDate.toDateString() === sessionEndDate.toDateString()
            const isFutureDay = sessionEndDate > currentDate

            const end_time = batch.cadence[0].end_time
            const currentTime =
              currentDate.getHours() * 60 + currentDate.getMinutes()
            const endTime =
              Number(end_time.split(':')[0]) * 60 +
              Number(end_time.split(':')[1])
            const isTimeOver = currentTime > endTime

            if (attended) return <span>Present</span>
            if (cancelled) return <span>Cancelled</span>
            if (isFutureDay || (isSameDay && !isTimeOver))
              return <span>Upcoming</span>
            return <span>Absent</span>
          }
        }
      },
      {
        name: 'enrolment.batch.cadence',
        label: 'Slot',
        disabledInForm: true,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'explore_slot',
          disableFilter: true,
          customBodyRender: (value, index) => (
            <span>
              {Array.isArray(value) &&
                value
                  .sort((a, b) => (a.weekday > b.weekday ? 1 : -1))
                  .map((item) => {
                    const start = item.start_time.split('-').join(':')
                    const end = item.end_time.split('-').join(':')
                    const date =
                      trialSessionsData.data[index.rowIndex].start_date

                    return `${dayjs()
                      .day(
                        item.weekday >= 0 && item.weekday < 7
                          ? item.weekday + 1
                          : 7
                      )
                      .format('ddd')} ${dayjs(date).format('DD MMM')}, ${dayjs(
                      '1/1/1 ' + start
                    ).format('h:mma')} - ${dayjs('1/1/1 ' + end).format(
                      'h:mma'
                    )}`
                  })
                  .join(' | ')}
            </span>
          )
        }
      },
      {
        name: '',
        label: 'actions',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { enrolment } = trialSessionsData?.data[dataIndex] || {}
            const { status, batch } = enrolment
            return (
              status !== 'terminated' &&
              status !== 'expired' &&
              status !== 'lapsed' && (
                <ButtonGroup>
                  {Object.keys(enrolmentActions)
                    .filter(
                      (el) =>
                        !(
                          (batch?.type === batchTypesMap.event ||
                            batch?.type === batchTypesMap.explore) &&
                          el === 'cancel_sessions'
                        )
                    )
                    .map((action, index) => {
                      return (
                        <Button
                          key={'Enrolment' + index}
                          title={enrolmentActions[action]}
                          className={classes.button}
                          onClick={(e) => {
                            onActionButtonClick(e, action, dataIndex)
                          }}
                        >
                          {enrolmentActions[action]}
                        </Button>
                      )
                    })}
                </ButtonGroup>
              )
            )
          }
        }
      }
    ]
  }, [trialSessionsData])

  return (
    <>
      <BaseEntity
        entity={constants.TRIAL_SESSIONS}
        label={constants.TRIAL_SESSIONS_LABEL}
        columns={modifiedColumns}
        data={trialSessionsData}
        fetchData={fetchTrialSessionsData}
        updateEntityData={updateClassData}
        createDependency
        disableDelete
        readOnly
      />
      <MasterModal
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={trialSessionsData.data[editIndex] || {}}
        onActionButtonClick={onActionButtonClick}
      />
    </>
  )
}

export default TrialSessions
