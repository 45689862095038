import React from 'react'

import EditIcon from 'assets/images/edit.svg'

import * as styles from './styles'
export const EditButton = ({ editHandler }) => {
  return (
    <styles.Container onClick={editHandler}>
      <styles.Icon src={EditIcon} />{' '}
      <styles.TextWrapper>edit</styles.TextWrapper>
    </styles.Container>
  )
}
