import * as types from '../types/communityEntity'

export const fetchClubData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_CLUB_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchClubDataSuccess = (value, entity) => ({
  type: types.FETCH_CLUB_DATA_SUCCESS,
  value,
  entity
})

export const fetchTribeData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_TRIBE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchTribeDataSuccess = (value, entity) => ({
  type: types.FETCH_TRIBE_DATA_SUCCESS,
  value,
  entity
})

export const fetchTribeMemberData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_TRIBE_MEMBER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchTribeMemberDataSuccess = (value, entity) => ({
  type: types.FETCH_TRIBE_MEMBER_DATA_SUCCESS,
  value,
  entity
})

export const fetchInactiveTribeMemberData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_INACTIVE_TRIBE_MEMBER_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchInactiveTribeMemberDataSuccess = (value, entity) => ({
  type: types.FETCH_INACTIVE_TRIBE_MEMBER_DATA_SUCCESS,
  value,
  entity
})

export const fetchWaitlistQueuedData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_WAITLIST_QUEUED_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchWaitlistQueuedDataSuccess = (value, entity) => ({
  type: types.FETCH_WAITLIST_QUEUED_DATA_SUCCESS,
  value,
  entity
})

export const fetchWaitlistTribeSelectionData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_WAITLIST_TRIBE_SELECTION_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchWaitlistTribeSelectionDataSuccess = (value, entity) => ({
  type: types.FETCH_WAITLIST_TRIBE_SELECTION_DATA_SUCCESS,
  value,
  entity
})

export const fetchWaitlistConvertedData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_WAITLIST_CONVERTED_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchWaitlistConvertedDataSuccess = (value, entity) => ({
  type: types.FETCH_WAITLIST_CONVERTED_DATA_SUCCESS,
  value,
  entity
})

export const fetchWaitlistOthersData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_WAITLIST_OTHERS_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchWaitlistOthersDataSuccess = (value, entity) => ({
  type: types.FETCH_WAITLIST_OTHERS_DATA_SUCCESS,
  value,
  entity
})

export const fetchReferralCodeData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_REFERRAL_CODE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchReferralCodeDataSuccess = (value, entity) => ({
  type: types.FETCH_REFERRAL_CODE_DATA_SUCCESS,
  value,
  entity
})

export const fetchClubRegistrationData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_CLUB_REGISTRATION_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchClubRegistrationDataSuccess = (value, entity) => ({
  type: types.FETCH_CLUB_REGISTRATION_DATA_SUCCESS,
  value,
  entity
})

export const updateCommunityData = (entity, event, value) => ({
  type: types.UPDATE_COMMUNITY_DATA,
  entity,
  event,
  value
})

export const updateCommunityDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_COMMUNITY_DATA_SUCCESS,
  entity,
  event,
  value
})

export const fetchClassroomMomentsData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_CLASSROOM_MOMENTS_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchClassroomMomentsDataSuccess = (value, entity) => ({
  type: types.FETCH_CLASSROOM_MOMENTS_DATA_SUCCESS,
  value,
  entity
})

export const fetchOpenbookData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_OPENBOOK_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchOpenbookDataSuccess = (value, entity) => ({
  type: types.FETCH_OPENBOOK_DATA_SUCCESS,
  value,
  entity
})
