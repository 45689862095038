/* eslint-disable react/prop-types */
import { MenuItem, FormControl, Select, InputLabel } from '@material-ui/core'
import Dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { isEmpty } from 'lodash'
import React from 'react'

import Loading from 'app/components/generic/Loading'

import { SubTitle } from '../../styles'

const style = {
  fontSize: '14px'
}

const SchedulesDate = ({
  isCheckoutMode,
  batchId,
  value,
  handleOnChange,
  selectedBatchType,
  scheduleFunction,
  propertyChange,
  placeholder
}) => {
  const [scheduledEvents, setScheduledEvents] = React.useState({
    isLoaded: false,
    data: []
  })

  const handleScheduleData = async () => {
    setScheduledEvents((state) => ({ ...state, isLoaded: false }))
    try {
      const scheduleEvent =
        selectedBatchType === 'membership'
          ? scheduleFunction()
          : await scheduleFunction(selectedBatchType, batchId, handleOnChange)
      setScheduledEvents((state) => ({
        ...state,
        data: scheduleEvent
      }))
    } catch (error) {
      console.log('error in handling scheduled events :- ', error)
    } finally {
      setScheduledEvents((state) => ({ ...state, isLoaded: true }))
    }
  }

  React.useEffect(() => {
    handleScheduleData()
  }, [scheduleFunction])

  const onChange = (e) => {
    const { name: batchId, value } = e.target
    handleOnChange(batchId, propertyChange, {
      label: scheduledEvents.data.find((item) => item.value === value).label,
      value
    })
  }

  if (!scheduledEvents.isLoaded) {
    return <Loading />
  } else if (isEmpty(scheduledEvents.data)) return null

  return (
    <>
      {!isCheckoutMode ? (
        <FormControl variant="standard" size="small">
          <InputLabel>{placeholder}</InputLabel>
          <Select
            name={batchId}
            value={value?.value || ''}
            onChange={onChange}
            style={style}
          >
            {scheduledEvents?.data?.map((item) => (
              <MenuItem key={item.value} value={item.value} style={style}>
                {Dayjs(item.label).format('ddd, D MMM YYYY')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          {value?.label ? (
            <SubTitle>
              {propertyChange}: {Dayjs(value?.label).format('D MMM')}
            </SubTitle>
          ) : null}
        </>
      )}
    </>
  )
}

export { SchedulesDate }
