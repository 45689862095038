import styled from 'styled-components';

export const JourneyMonthIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-top: -1em;
`;

export const Month = styled.div`
`;

export const Year = styled.div`
`;

export const TimelineDot = styled.div`
  width: 10px;
  height: 10px;
  background-color:  ${(props) => props.color};
  border-radius: 30px;
  padding: 6px;
`;

export const TimelineLine = styled.div`
  height: 100%;
  border-left: 0.2em dashed ${(props) => props.color};
`;



