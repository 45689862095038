import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { detachTribe } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import constants from 'app/constants'
import { confirm, showToast } from 'app/helpers'
import {
  fetchInactiveTribeMemberData,
  updateCommunityData
} from 'app/store/actions/communityEntity'

import { columns } from './config'

const Membership = () => {
  const inactiveTribeMemberData = useSelector(
    (state) => state.communityEntityReducer.inactiveTribeMemberData
  )

  return (
    <BaseEntity
      entity={constants.INACTIVE_TRIBE_MEMBERSHIP}
      label={constants.INACTIVE_TRIBE_MEMBERSHIP_LABEL}
      columns={columns}
      data={inactiveTribeMemberData}
      fetchData={fetchInactiveTribeMemberData}
      updateEntityData={updateCommunityData}
      readOnly
    />
  )
}

export default Membership
