import {
  pluralizeString,
  isEmpty,
  deletePropertyFromObject,
  generateUUID
} from '@openhouse-technologies/utils'
import { isNil } from 'lodash'
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  delay
} from 'redux-saga/effects'

import {
  fetchReportCard,
  fetchBulkMessages,
  sendBulkMessages,
  fetchUser,
  getBulkMessage,
  fetchInteractionHistory,
  updateInteractionHistory,
  fetchTickets,
  createTicket,
  updateTicket
} from 'api'

import {
  fetchTeacherList,
  fetchStudentList,
  fetchChatMessages,
  fetchParentList,
  fetchParentChatMessages
} from 'api/chat'
import { targetAudienceSelectionModes } from 'app/config'
import constants from 'app/constants'
import {
  confirmDialog,
  eventAlert,
  eventEmitter,
  computeBulkMessagingUserFiltersQueries
} from 'app/helpers'
import {
  fetchReportCardData,
  fetchReportCardDataSuccess,
  fetchBulkMessagingData,
  fetchBulkMessagingDataSuccess,
  updateMessageTemplateParameter,
  updateUserType,
  updateUsers,
  updateDeliveryType,
  fetchDeliveryStatsData,
  fetchDeliveryStatsDataSuccess,
  updateDeliveryStatsModalState,
  updateFieldErrors,
  updateSelectedMessageTemplate,
  resetBulkMessagingCreationData,
  updateBulkMessagingSendApiProgressStatus,
  fetchInteractionHistoryData,
  fetchInteractionHistoryDataSuccess,
  updateInteractionHistoryData,
  updateInteractionHistoryDataSuccess,
  fetchTicketData,
  fetchTicketDataSuccess,
  fetchTeacherListData,
  fetchTeacherListDataSuccesss,
  fetchStudentListData,
  fetchStudentListDataSuccess,
  fetchChatMessagesData,
  fetchChatMessagesDataSuccess,
  fetchParentListData,
  fetchParentListDataSuccess,
  fetchParentChatMessagesData,
  fetchParentChatMessagesDataSuccess
} from 'app/store/actions/communicationEntity'
import * as types from 'app/store/types/communicationEntity'

import { handleFetch, handleUpdate } from './saga.helper'

import {
  selectSelectedMessageTemplate,
  selectBulkMessagesUserType,
  selectBulkMessagesUsers,
  selectDeliveryStatsData,
  selectFieldErrors,
  selectSelectedMessageTemplateParameters,
  selectUserFilters,
  selectExcludedUsers,
  selectIncludedUsers,
  selectTargetAudienceSelectionMode
} from '../selectors'

const createFormattedMessageTemplate = (template, parameters) => {
  let formattedTemplate = template

  parameters.forEach((parameter, index) => {
    if (
      (parameter.isDynamic && !isEmpty(parameter.value)) ||
      !isEmpty(parameter.staticValue)
    ) {
      formattedTemplate = formattedTemplate.replace(
        `{{${index + 1}}}`,
        parameter.staticValue
          ? `<span>${parameter.staticValue}</span>`
          : `<span>&lt;${parameter.value}&gt;</span>`
      )
    }
  })

  return formattedTemplate
}

const testParameter = (parametersErrors, parameter, index) => {
  let modifiedParametersErrors = { ...parametersErrors }

  if (
    (parameter.isDynamic && isEmpty(parameter.value)) ||
    (!parameter.isDynamic && isEmpty(parameter.staticValue))
  ) {
    modifiedParametersErrors[index + 1] = `Parameter ${index + 1} is invalid`
  } else {
    modifiedParametersErrors = deletePropertyFromObject(
      index + 1,
      parametersErrors
    )
  }

  return modifiedParametersErrors
}

const handleParametersValidation = (parameters) => {
  let parametersErrors = {}

  parameters.forEach((parameter, index) => {
    parametersErrors = testParameter(parametersErrors, parameter, index)
  })

  return {
    success: isEmpty(parametersErrors),
    parametersErrors
  }
}

function* handleValidateFields(action) {
  const errors = {
    messageTemplate: null,
    userType: null,
    parameters: {}
  }

  let success = false

  if (action.type === types.HANDLE_FETCH_USERS) {
    const userType = yield select(selectBulkMessagesUserType)

    if (isEmpty(userType)) {
      errors.userType = 'Select user type'
    } else {
      success = true
    }
  } else if (
    action.type === types.HANDLE_ON_BULK_MESSAGES_SEND ||
    action.type === types.HANDLE_BULK_MESSAGES_CREATE_VALIDATION
  ) {
    const { name, parameters } = yield select(selectSelectedMessageTemplate)

    if (isEmpty(name)) {
      errors.messageTemplate = 'Select message template'
    } else {
      // now check if parameters are valid

      const fieldErrors = yield select(selectFieldErrors)

      const { success: parametersFieldSuccess, parametersErrors } =
        handleParametersValidation(parameters)

      if (parametersFieldSuccess) {
        success = true
      } else {
        errors.parameters = { ...fieldErrors.parameters, ...parametersErrors }
      }
    }
  }

  if (!success) {
    // update errors
    yield put(updateFieldErrors(errors))
  }

  return {
    success
  }
}

const mapping = {
  [constants.BULK_MESSAGING]: {
    api: {
      fetch: fetchBulkMessages
    },
    action: {
      fetch: fetchBulkMessagingData,
      fetchSuccess: fetchBulkMessagingDataSuccess
    }
  },
  [constants.REPORT_CARD]: {
    api: {
      fetch: fetchReportCard
    },
    action: {
      fetch: fetchReportCardData,
      fetchSuccess: fetchReportCardDataSuccess
    }
  },
  [constants.INTERACTION_HISTORY]: {
    api: {
      fetch: fetchInteractionHistory,
      create: updateInteractionHistory
      // update: updateInteractionHistory,
    },
    action: {
      fetch: fetchInteractionHistoryData,
      fetchSuccess: fetchInteractionHistoryDataSuccess
    }
  },
  [constants.TICKET]: {
    api: {
      fetch: fetchTickets,
      create: createTicket,
      update: updateTicket
    },
    action: {
      fetch: fetchTicketData,
      fetchSuccess: fetchTicketDataSuccess
    }
  },
  [constants.TEACHER_LIST]: {
    api: {
      fetch: fetchTeacherList,
    },
    action: {
      fetch: fetchTeacherListData,
      fetchSuccess: fetchTeacherListDataSuccesss
    }
  },
  [constants.STUDENT_LIST]: {
    api: {
      fetch: fetchStudentList,
    },
    action: {
      fetch: fetchStudentListData,
      fetchSuccess: fetchStudentListDataSuccess
    }
  },
  [constants.CHAT_MESSAGES]: {
    api: {
      fetch: fetchChatMessages,
    },
    action: {
      fetch: fetchChatMessagesData,
      fetchSuccess: fetchChatMessagesDataSuccess
    }
  },
  [constants.PARENT_LIST]: {
    api: {
      fetch: fetchParentList,
    },
    action: {
      fetch: fetchParentListData,
      fetchSuccess: fetchParentListDataSuccess
    }
  },
  [constants.PARENT_CHAT_MESSAGES]: {
    api: {
      fetch: fetchParentChatMessages,
    },
    action: {
      fetch: fetchParentChatMessagesData,
      fetchSuccess: fetchParentChatMessagesDataSuccess
    }
  }
}

export function* _fetchCommunicationEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updateCommunicationEntity({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(
    mappingData,
    entity,
    event,
    value,
    updateInteractionHistoryDataSuccess
  )
}

export function* watchFetchCommunicationEntity() {
  yield takeLatest(
    [
      types.FETCH_BULK_MESSAGING_DATA,
      types.FETCH_REPORT_CARD_DATA,
      types.FETCH_INTERACTION_HISTORY_DATA,
      types.FETCH_TICKETS_DATA,
      types.FETCH_STUDENT_LIST_DATA,
      types.FETCH_TEACHER_LIST_DATA,
      types.FETCH_PARENT_LIST_DATA,
      types.FETCH_CHAT_MESSAGES_DATA,
      types.FETCH_PARENT_CHAT_MESSAGES_DATA
    ],
    _fetchCommunicationEntity
  )
}

export function* watchUpdateCommunicationEntity() {
  yield takeEvery(
    [types.UPDATE_INTERACTION_HISTORY_DATA, types.UPDATE_TICKET_DATA],
    _updateCommunicationEntity
  )
}

export function* handleBulkMessagingEvents(action) {
  function* handleUpdateSelectedMessageTemplate(action) {
    try {
      const { value } = action

      if (value) {
        const fieldErrors = yield select(selectFieldErrors)

        yield put(
          updateFieldErrors({
            ...fieldErrors,
            messageTemplate: null
          })
        )
      }

      yield put(updateSelectedMessageTemplate(value))
    } catch (error) {
      console.log(error)
    }
  }

  function* handleUpdateMessageTemplateParameter(action) {
    try {
      const {
        event: {
          target: { name, value }
        }
      } = action

      if (name && !isNil(value)) {
        const index = parseInt(name.split('-')[1], 10)

        // first get previous state of parameters
        const { parameters, rawTemplate } = yield select(
          selectSelectedMessageTemplate
        )

        if (name.startsWith('static')) {
          const newData = {
            ...parameters[index],
            staticValue: value
          }

          yield put(
            updateMessageTemplateParameter({
              index,
              data: newData,
              formattedTemplate: createFormattedMessageTemplate(rawTemplate, [
                ...parameters.slice(0, index),
                newData,
                ...parameters.slice(index + 1)
              ])
            })
          )
        } else {
          const isDynamic = value !== 'other'

          const newData = { isDynamic, value, staticValue: null }

          yield put(
            updateMessageTemplateParameter({
              index,
              data: {
                uuid: generateUUID(),
                isDynamic,
                value,
                staticValue: null
              },
              formattedTemplate: createFormattedMessageTemplate(rawTemplate, [
                ...parameters.slice(0, index),
                newData,
                ...parameters.slice(index + 1)
              ])
            })
          )
        }

        // delay for some time
        yield delay(500)

        const templateParameters = yield select(
          selectSelectedMessageTemplateParameters
        )

        const parameter = templateParameters[index]

        const fieldErrors = yield select(selectFieldErrors)

        const parametersErrors = testParameter(
          { ...fieldErrors.parameters },
          parameter,
          index
        )

        // update errors
        yield put(
          updateFieldErrors({
            ...fieldErrors,
            parameters: parametersErrors
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  function* handleUpdateUserType(action) {
    const {
      event: {
        target: { value }
      }
    } = action

    if (value) {
      const fieldErrors = yield select(selectFieldErrors)

      yield put(
        updateFieldErrors({
          ...fieldErrors,
          userType: null
        })
      )
    }

    yield put(updateUserType(value))
  }

  function* handleMessageValidation(action) {
    try {
      const { success } = yield* handleValidateFields(action)

      if (success) {
        eventEmitter.emit(constants.BULK_MESSAGING_MESSAGE_VALID)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function* handleOnBulkMessagesSend(action) {
    try {
      const { success } = yield* handleValidateFields(action)

      if (success) {
        // first select request data from state
        const userType = yield select(selectBulkMessagesUserType)
        const users = yield select(selectBulkMessagesUsers)
        const selectedMessageTemplate = yield select(
          selectSelectedMessageTemplate
        )
        // select user filters
        const userFilters = yield select(selectUserFilters)

        const excludedUsers = yield select(selectExcludedUsers)
        const includedUsers = yield select(selectIncludedUsers)
        const targetAudienceSelectionMode = yield select(
          selectTargetAudienceSelectionMode
        )

        const totalUsers =
          targetAudienceSelectionMode === targetAudienceSelectionModes.AUTOMATIC
            ? users.count || 0
            : targetAudienceSelectionMode ===
              targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION
              ? (users.count || 0) - excludedUsers.length
              : targetAudienceSelectionMode ===
                targetAudienceSelectionModes.MANUAL
                ? includedUsers.length
                : 0

        if (totalUsers === 0) {
          eventAlert('No User Selected', constants.ERROR)
        } else {
          try {
            const { isConfirmed } = yield call(confirmDialog, {
              text: `Send message to ${pluralizeString(
                'participant',
                totalUsers
              )}`,
              confirmButtonText: 'Yes',
              options: {
                confirmButtonClass: 'confirm-button',
                cancelButtonClass: 'cancel-button',
                icon: 'info'
              }
            })

            if (isConfirmed) {
              yield put(updateBulkMessagingSendApiProgressStatus(true))

              const otherValueParameters =
                selectedMessageTemplate.parameters.filter(
                  (parameter) => !parameter.isDynamic
                )

              const modifiedFilters =
                computeBulkMessagingUserFiltersQueries(userFilters)

              yield call(sendBulkMessages, {
                userType,
                template: selectedMessageTemplate.name,
                params: selectedMessageTemplate.parameters.map(
                  (item) => item.value
                ),
                filters: modifiedFilters,
                ...(!isEmpty(otherValueParameters)
                  ? {
                    others: otherValueParameters.map(
                      (item) => item.staticValue
                    )
                  }
                  : null),
                ...(targetAudienceSelectionMode ===
                  targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION
                  ? {
                    excludedUsers: excludedUsers.map(
                      (user) => user?.user?.uuid
                    )
                  }
                  : null),
                ...(targetAudienceSelectionMode ===
                  targetAudienceSelectionModes.MANUAL
                  ? {
                    includedUsers: includedUsers.map(
                      (user) => user?.user?.uuid
                    )
                  }
                  : null)
              })

              eventEmitter.emit(constants.CLOSE_BULK_MESSAGING_MODAL)
              eventAlert('Message Sent Successfully', constants.SUCCESS)

              // reset data
              yield put(resetBulkMessagingCreationData())

              // fetch new data
              yield put(
                fetchBulkMessagingData(
                  {},
                  false,
                  false,
                  constants.BULK_MESSAGING
                )
              )
            }
          } catch (error) {
            eventAlert(error.message, constants.ERROR)
          }
        }
      }
    } catch (error) {
      eventAlert(error.message, constants.ERROR)
    } finally {
      yield put(updateBulkMessagingSendApiProgressStatus(false))
    }
  }

  switch (action.type) {
    case types.HANDLE_UPDATE_SELECTED_MESSAGE_TEMPLATE:
      yield* handleUpdateSelectedMessageTemplate(action)
      break

    case types.HANDLE_UPDATE_MESSAGE_TEMPLATE_PARAMETER:
      yield* handleUpdateMessageTemplateParameter(action)
      break

    case types.HANDLE_UPDATE_USER_TYPE:
      yield* handleUpdateUserType(action)
      break

    case types.HANDLE_BULK_MESSAGES_CREATE_VALIDATION:
      yield* handleMessageValidation(action)
      break

    case types.HANDLE_ON_BULK_MESSAGES_SEND:
      yield* handleOnBulkMessagesSend(action)
      break

    default:
      break
  }
}

export function* watchHandleBulkMessagingEvents() {
  yield takeEvery(
    [
      types.HANDLE_UPDATE_SELECTED_MESSAGE_TEMPLATE,
      types.HANDLE_UPDATE_MESSAGE_TEMPLATE_PARAMETER,
      types.HANDLE_UPDATE_USER_TYPE,
      types.HANDLE_BULK_MESSAGES_CREATE_VALIDATION,
      types.HANDLE_ON_BULK_MESSAGES_SEND
    ],
    handleBulkMessagingEvents
  )
}

function* handleBulkMessagingFetchEvents(action) {
  function* handleFetchDeliveryStatsData(action) {
    const { id, deliveryType } = action

    try {
      // first update delivery type
      yield put(updateDeliveryType(deliveryType))

      // update modal state
      yield put(updateDeliveryStatsModalState(true))

      // now check if id is same as earlier fetched data
      const { id: prevId } = yield select(selectDeliveryStatsData)

      if (prevId !== id) {
        yield put(fetchDeliveryStatsData(id))

        const response = yield call(getBulkMessage, id)
        const { uuid, whatsapp_messages } = response

        yield put(fetchDeliveryStatsDataSuccess(uuid, whatsapp_messages))
      }
    } catch (error) {
      console.log(error)
    }
  }

  function* handleFetchUsers(action) {
    try {
      const { success } = yield* handleValidateFields(action)

      if (success) {
        // first get user type
        const userType = yield select(selectBulkMessagesUserType)

        // select user filters
        const userFilters = yield select(selectUserFilters)

        yield put(updateUsers(null, constants.FETCHING))

        const modifiedQueries =
          computeBulkMessagingUserFiltersQueries(userFilters)

        const usersResponse = yield call(
          fetchUser,
          { queries: { basic: true, ...modifiedQueries } },
          userType
        )

        if (userType === constants.PARENT) {
          yield put(
            updateUsers(
              {
                count: usersResponse.count,
                data: usersResponse.results.map((item) => ({
                  uuid: item.user.uuid,
                  name: `${item.user.first_name} ${item.user.last_name}`,
                  phone_number: item.user.phone_number
                }))
              },
              constants.UPDATED
            )
          )
        } else {
          yield put(
            updateUsers(
              {
                count: usersResponse.count,
                data: usersResponse.results.map((item) => ({
                  uuid: item.user.uuid,
                  name: item.user.full_name,
                  phone_number: item.user.phone_number
                }))
              },
              constants.UPDATED
            )
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  switch (action.type) {
    case types.HANDLE_FETCH_DELIVERY_STATS_DATA:
      yield* handleFetchDeliveryStatsData(action)
      break

    case types.HANDLE_FETCH_USERS:
      yield* handleFetchUsers(action)
      break

    default:
      break
  }
}

export function* watchHandleBulkMessagingFetchEvents() {
  yield takeLatest(
    [types.HANDLE_FETCH_DELIVERY_STATS_DATA, types.HANDLE_FETCH_USERS],
    handleBulkMessagingFetchEvents
  )
}
