import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import {
  fetchSuperproviderData,
  updateUserData
} from 'app/store/actions/userEntity'

import { columns } from './config'

const Superprovider = () => {
  const superproviderData = useSelector(
    (state) => state.userEntityReducer.superproviderData
  )

  return (
    <BaseEntity
      entity={constants.SUPERPROVIDER}
      label={constants.SUPERPROVIDER_LABEL}
      columns={columns}
      data={superproviderData}
      fetchData={fetchSuperproviderData}
      updateEntityData={updateUserData}
      disableDelete
    />
  )
}

export default Superprovider
