import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import {
  validateNotEmpty,
  validateName,
  validateMobile,
  validateCount
} from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'club.name',
    disabledInFormOnEdit: true,
    ...commonAttributes.club
  },
  {
    name: 'name',
    label: 'Name',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'tribe_name'
    }
  },
  {
    name: 'description',
    label: 'Description',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'description'
    }
  },
  {
    name: 'leader.user.first_name',
    label: 'Leader First Name',
    // disabledInForm: true,
    disabledInFormOnEdit: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_first_name'
    }
  },
  {
    name: 'leader.user.last_name',
    label: 'Leader Last Name',
    // disabledInForm: true,
    disabledInFormOnEdit: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_last_name'
    }
  },
  {
    name: 'leader.user.phone_number',
    label: 'Leader Phone Number',
    // disabledInForm: true,
    disabledInFormOnEdit: true,
    validate: validateMobile,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_phone_number'
    }
  },
  {
    name: 'leader.user.referral_code',
    label: 'Leader Referral Code',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_referral_code'
    }
  },
  {
    name: 'leader_description',
    label: 'Leader Description',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'leader_description'
    }
  },
  {
    name: 'membership_count',
    label: 'Registrations Count',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registrations_count'
    }
  },
  {
    name: 'max_seat',
    label: 'Max seat',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'max_seat'
    }
  },
  {
    name: 'empty_seats',
    label: 'Empty Seats',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'empty_seats'
    }
  },
  {
    name: 'timings',
    label: 'Timings',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'timings'
    }
  },
  {
    name: 'visible_on_website',
    label: 'Visible On Website',
    widget: 'select',
    disabledInFormOnEdit: true,
    validate: validateNotEmpty,
    valueLabelMappings: {
      [true]: 'Yes',
      [false]: 'No'
    },
    options: {
      display: false
    }
  }
]
