import React from 'react'
import Linkify from 'react-linkify'

import * as styles from './styles'

const MessageText = ({ text }) => {
  return (
    <styles.Container>
      <Linkify>{text}</Linkify>
    </styles.Container>
  )
}

export default MessageText
