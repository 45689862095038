
export const Count = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '#EB5757',
  color: '#FEFEFE',
  borderRadius: '50%',
  width: '1.5rem',
  height: '1.5rem',
  fontSize: '12px',
  fontWeight: '600'
}
