import { debounce } from 'lodash'
import MUIDataTable from 'mui-datatables'
import React, { useState, useEffect, useRef, useCallback } from 'react'

import { tableOptions } from './config'
import { scroll } from './styles'

const InfiniteScroll = ({
  title,
  columns,
  fetchData,
  queryParameters = {}
}) => {
  const tableRef = useRef()
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({})
  const [hasMore, setHasMore] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const fetchDataAndUpdateState = async (query) => {
    try {
      const { results, ...payload } = await fetchData(query)
      if (results?.length) {
        setData([...data, ...results])
        setMeta(payload)
        setHasMore(!!payload.next)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error)
      console.log(error.toString())
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      fetchDataAndUpdateState({ queries: queryParameters })
    })()
  }, [])

  const loadMore = async () => {
    if (!hasMore || isLoading) return
    setLoading(true)
    fetchDataAndUpdateState({ url: meta.next })
  }

  const scrollListener = useCallback(
    debounce(() => {
      if (tableRef.current) {
        const bottom =
          tableRef.current.scrollHeight - tableRef.current.clientHeight
        const distanceBottom = bottom * 0.2

        if (
          tableRef.current.scrollTop > bottom - distanceBottom &&
          hasMore &&
          !isLoading
        ) {
          loadMore()
        }
      }
    }, 300),
    [isLoading, hasMore]
  )

  useEffect(() => {
    const table = tableRef.current
    table.addEventListener('scroll', scrollListener)

    return () => {
      table.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  return (
    <div style={scroll} ref={tableRef}>
      <MUIDataTable
        title={title}
        columns={columns}
        data={data}
        options={tableOptions(isLoading)}
      />
    </div>
  )
}

export default InfiniteScroll
