import Dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const clubRequestStructure = (data) => ({
  name: data.name,
  description: data.description,
  image: data.image
})

const fetchClub = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.club, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching club :- ', error.message)
    throw error
  }
}

const fetchClubRegistration = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.club}_registration`, queries)
  try {
    const response = await get(requestUrl)
    return {
      ...response.data,
      results: response.data.results.map((item) => {
        return {
          ...item,
          created: !isEmpty(item?.created)
            ? new Dayjs(item?.created).format('YYYY-MM-DD HH:mm:ss')
            : ''
        }
      })
    }
  } catch (error) {
    console.log('error in fetching club :- ', error.message)
    throw error
  }
}

const createClub = async (data) => {
  try {
    const response = await post(apiEndpoints.club, clubRequestStructure(data))
    return response.data
  } catch (error) {
    console.log('error in creating club :- ', error.message)
    throw error
  }
}

const updateClub = async (data) => {
  validateUpdateRequest(data)
  try {
    const response = await patch(
      `${apiEndpoints.club}/${data.uuid}/`,
      clubRequestStructure(data)
    )
    return response.data
  } catch (error) {
    console.log('error in updating club :- ', error.message)
    throw error
  }
}

const deleteClub = async ({ ids }) => {
  validateDeleteRequest(ids)
  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.club}/${id}`)
      })
    )
    return ids
  } catch (error) {
    console.log('error in deleting club :- ', error.message)
    throw error
  }
}

export { fetchClub, fetchClubRegistration, createClub, updateClub, deleteClub }
