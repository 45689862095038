import { FormLabel, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { Formik, Form, Field } from 'formik'
import { get, set, isEmpty, isFunction, isNil } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import CloudFileUploader from 'app/components/CloudFileUploader'
import {
  AutoComplete,
  Select,
  TextField,
  Checkbox,
  RadioGroup
} from 'app/components/generic/FormElements'
import WYSIWYG from 'app/components/generic/WYSIWYG'
import ModalForm from 'app/components/ModalForm'
import { ageRange, getWidgetTypeName } from 'app/config'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

import History from '../../components/generic/history/history'
import { DateRangePicker } from '../generic/DateRangePicker'
import { DatePicker } from '../generic/FormFields'

const errorPropsDefault = {
  error: false,
  helperText: ''
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  rangeFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  hyphen: {
    margin: '0 20px'
  },
  error: {
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileUploadContainer: {
    marginTop: theme.spacing(1)
  },
  editor: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: theme.spacing(1)
  },
  title: {
    display: 'inline-block',
    marginBottom: theme.spacing(0.5)
  },
  disable: {
    pointerEvents: 'none'
  },
  heading: {
    display: 'flex',
    marginLeft: '10px',
    marginBottom: '10px'
  },
  subHeading: {
    marginLeft: '10px',
    marginBottom: '10px',
    color: theme.palette.primary.main
  }
}))

const BaseForm = (props) => {
  const {
    title,
    isModalOpen,
    formData,
    setFormData,
    onSaveClick,
    error,
    columns,
    handleModalClose,
    currentEditingData,
    viewMode,
    headerText
  } = props
  const formRef = React.useRef(null)
  const editorRef = React.useRef([])
  const classes = useStyles()
  const handleFormSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current

      const modifiedValues = {
        ...values
      }
      if (editorRef.current && !isEmpty(editorRef.current)) {
        editorRef.current.map((item) => {
          if (item) {
            set(
              modifiedValues,
              item.props.name,
              item.props.onContentStateChange()
            )
          }
        })
      }

      try {
        const errors = await validateForm(modifiedValues)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        } else {
          // form is valid
          setFormData(modifiedValues)
          onSaveClick(modifiedValues)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [onSaveClick, setFormData])

  const handleFileUploadOnChange = React.useCallback(
    (data) => {
      const {
        name,
        data: { secure_url }
      } = data

      setFormData(
        set(
          {
            ...formData
          },
          name,
          secure_url
        )
      )
    },
    [formData, setFormData]
  )

  const formElement = React.useMemo(() => {
    let renderColumns
    if (viewMode) {
      renderColumns = columns.filter((el) => el.viewOnlyInModal == true)
    } else if (columns.length > 0 && columns[1]?.modalEdit !== undefined) {
      renderColumns = columns.filter((el) => el?.modalEdit == true)
    } else {
      renderColumns = columns
    }

    // keep all required elements first , then optional in form
    renderColumns = [
      ...renderColumns.filter((renderColumn) => !renderColumn.optional),
      ...renderColumns.filter((renderColumn) => renderColumn.optional)
    ]

    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}>
        {({ errors, setFieldValue, values, touched }) => (
          <Form>
            {renderColumns.map((column, index) => {
              let errorMessage =
                (error &&
                  error.isError &&
                  get(error.fieldErrors, column.formDataKey || column.name)) ||
                ''

              const errorProps = {
                ...errorPropsDefault
              }

              const fieldError = get(errors, column.formDataKey || column.name)

              if (!isEmpty(errorMessage)) {
                errorProps.error = true
                errorProps.helperText = errorMessage
              } else if (!isEmpty(fieldError)) {
                errorProps.error = true
                errorProps.helperText = fieldError

                errorMessage = fieldError
              }

              if (
                column.disabledInForm ||
                (isEmpty(currentEditingData) &&
                  column.disabledInFormOnCreate) ||
                (!isEmpty(currentEditingData) && column.disabledInFormOnEdit) ||
                (typeof column.conditionalRender === 'function' &&
                  column.conditionalRender(values) === false)
              ) {
                return null
              }

              if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                typeof column.widget === 'function'
              ) {
                const CustomWidget = column.widget
                return (
                  <CustomWidget
                    key={index}
                    fieldData={get(formData, column.name)}
                    fieldName={column.name}
                    setFieldValue={setFieldValue}
                    label={column.label}
                    formData={formData}
                    formikFieldData={get(values, column.name)}
                    config={column?.config || {}}
                    {...(typeof column.validate === 'function'
                      ? {
                          validate: column.validate({
                            isRequired: !column.optional,
                            min: column.customMin,
                            max: column.customMax
                          })
                        }
                      : null)}
                    newMedia={column.newMedia}
                  />
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'select'
              ) {
                const mappings =
                  typeof column.valueLabelMappings === 'function'
                    ? column.valueLabelMappings(values)
                    : column.valueLabelMappings
                return (
                  <FormControl
                    variant="outlined"
                    key={index}
                    required={!column.optional}
                    className={classes.formControl}
                    fullWidth
                    {...(errorProps.error ? { error: true } : null)}>
                    <InputLabel>{column.label}</InputLabel>
                    <Select
                      name={column.name}
                      disabled={viewMode}
                      defaultValue={
                        get(formData, column.name) !== undefined
                          ? get(formData, column.name)
                          : ''
                      }
                      label={column.label}
                      {...(typeof column.validate === 'function'
                        ? {
                            validate: column.validate({
                              isRequired: !column.optional
                            })
                          }
                        : null)}>
                      {Object.keys(mappings).map((key, index) => (
                        <MenuItem key={index} value={key}>
                          {mappings[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    {errorProps.error && (
                      <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'autocomplete'
              ) {
                return (
                  <FormControl
                    variant="outlined"
                    key={index}
                    className={classes.formControl}
                    fullWidth
                    required={!column.optional}
                    {...(errorProps.error ? { error: true } : null)}>
                    <AutoComplete
                      newValueToCreate={column.newValueToCreate || false}
                      name={column.formDataKey || column.name}
                      placeholder={column.label}
                      disabled={
                        column.editable === false ||
                        (!isEmpty(currentEditingData) &&
                          column.showDisabledInFormOnEdit === true) ||
                        viewMode
                      }
                      minCharactersToSearch={column.minCharactersToSearch}
                      apiOptions={column.apiOptions}
                      getStructuredValues={column.getStructuredValues}
                      getOptionLabelKey={column.getOptionLabelKey}
                      getOptionSelectedKey={column.getOptionSelectedKey}
                      resultStructure={column.resultStructure}
                      multiple={column.multiple}
                      required={!column.optional}
                      setFieldValue={setFieldValue}
                      formData={formData}
                      data={currentEditingData}
                      mappedOptions={column.valueLabelMappings || null}
                      debounceTime={column.debounceTime}
                      {...(typeof column.validate === 'function'
                        ? {
                            validate: column.validate({
                              isRequired: !column.optional
                            })
                          }
                        : null)}
                    />
                    {errorProps.error && (
                      <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'fileupload'
              ) {
                const uploadedFileUrl =
                  get(formData, column.formDataKey || column.name) || ''

                return (
                  <FormControl
                    variant="outlined"
                    key={index}
                    required={!column.optional}
                    className={classes.formControl}
                    fullWidth
                    {...(errorProps.error ? { error: true } : null)}>
                    <Field
                      name={column.formDataKey || column.name}
                      component={({ field, form, ...props }) => (
                        <>
                          <CloudFileUploader
                            {...field}
                            {...props}
                            {...form}
                            fileUrlOnly={column.fileUrlOnly}
                            name={column.formDataKey || column.name}
                            label={column.label}
                            onUpload={handleFileUploadOnChange}
                            uploadedFileUrl={uploadedFileUrl}
                            uploadPreset={column.uploadPreset}
                          />
                          <FormHelperText>
                            {form.errors[column.formDataKey || column.name]}
                          </FormHelperText>
                        </>
                      )}
                      {...(typeof column.validate === 'function'
                        ? {
                            validate: column.validate({
                              isRequired: !column.optional
                            })
                          }
                        : null)}
                    />
                  </FormControl>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'table'
              ) {
                const {
                  apiCall,
                  heading,
                  type,
                  editableDetails,
                  accessors,
                  tableHeadings,
                  selectOptions
                } = column.data
                return (
                  <History
                    readOnly={viewMode}
                    sliderMode={false}
                    key={index}
                    apiCall={apiCall}
                    heading={heading}
                    selectOptions={selectOptions}
                    type={type}
                    editableDetails={editableDetails && !viewMode}
                    userInfo={formData?.user}
                    accessors={accessors}
                    tableHeadings={tableHeadings}
                  />
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'wysiwyg'
              ) {
                return (
                  <FormControl
                    variant="outlined"
                    key={index}
                    required={!column.optional}
                    className={classes.formControl}
                    fullWidth
                    {...(errorProps.error ? { error: true } : null)}>
                    <div
                      className={`${classes.editor} ${
                        viewMode ? classes.disable : ''
                      }`}>
                      <span className={classes.title}>
                        {column.label}
                        {!column.optional ? '*' : ''}
                      </span>
                      <Field
                        name={column.formDataKey || column.name}
                        {...(typeof column.validate === 'function'
                          ? {
                              validate: column.validate({
                                isRequired: !column.optional
                              })
                            }
                          : null)}>
                        {({ field, form }) => (
                          <>
                            <WYSIWYG field={field} form={form} {...props} />
                            <FormHelperText>
                              {form.errors[column.formDataKey || column.name]}
                            </FormHelperText>
                          </>
                        )}
                      </Field>
                    </div>
                  </FormControl>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'range'
              ) {
                const widgetType = getWidgetTypeName(column.widget)

                return (
                  <FormControl
                    key={index}
                    variant="outlined"
                    className={classes.formControl}
                    error={errorProps.error}>
                    <div className={classes.rangeFlex}>
                      <TextField
                        name={column.min_name}
                        label={column.min_label}
                        type={widgetType}
                        variant="outlined"
                        required={!column.optional}
                        disabled={column.editable === false || viewMode}
                        {...(typeof column.validate === 'function'
                          ? {
                              validate: column.validate({
                                isRequired: !column.optional,
                                min: ageRange.min,
                                max: ageRange.max,
                                ...(column.formValuesRequired
                                  ? { formValues: values }
                                  : null)
                              })
                            }
                          : null)}
                        {...(widgetType !== 'text'
                          ? {
                              InputLabelProps: {
                                shrink: true
                              }
                            }
                          : null)}
                        {...(errorProps.error ? errorProps : null)}
                      />
                      <div className={classes.hyphen}>-</div>
                      <TextField
                        name={column.max_name}
                        label={column.max_label}
                        type={widgetType}
                        variant="outlined"
                        required={!column.optional}
                        disabled={column.editable === false || viewMode}
                        {...(typeof column.validate === 'function'
                          ? {
                              validate: column.validate({
                                isRequired: !column.optional,
                                min: ageRange.min,
                                max: ageRange.max,
                                ...(column.formValuesRequired
                                  ? { formValues: values }
                                  : null)
                              })
                            }
                          : null)}
                        {...(widgetType !== 'text'
                          ? {
                              InputLabelProps: {
                                shrink: true
                              }
                            }
                          : null)}
                        {...(errorProps.error ? errorProps : null)}
                      />
                    </div>
                  </FormControl>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'checkbox'
              ) {
                return (
                  <FormControl
                    key={index}
                    required={!column.optional}
                    className={classes.formControl}
                    fullWidth
                    disabled={
                      typeof column.disabled === 'function'
                        ? column.disabled(values)
                        : !isNil(column.disabled)
                        ? column.disabled
                        : false
                    }>
                    <FormControlLabel
                      control={
                        <Checkbox
                          type="checkbox"
                          color="primary"
                          name={column.name}
                          defaultChecked={
                            isFunction(column.defaultValue)
                              ? column.defaultValue(formData)
                              : get(
                                  formData,
                                  column.formDataKey || column.name
                                ) === true
                          }
                        />
                      }
                      label={column.label}
                    />
                  </FormControl>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'radiobutton'
              ) {
                return (
                  <FormControl
                    key={index}
                    required={!column.optional}
                    className={classes.formControl}
                    component="fieldset"
                    fullWidth>
                    <FormLabel component="legend">{column.label}</FormLabel>
                    <RadioGroup
                      row
                      aria-label={column.label}
                      name={column.name}>
                      {column.radioButtons.map((radioButton) => (
                        <FormControlLabel
                          key={radioButton.value}
                          {...radioButton}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'text'
              ) {
                return (
                  <div key={index} className={classes.text}>
                    <span className={classes.title}>{column.label}</span>
                    <span className={classes.description}>
                      {get(values, column.formDataKey || column.name)}
                    </span>
                  </div>
                )
              } else if (
                Object.prototype.hasOwnProperty.call(column, 'widget') &&
                column.widget === 'string'
              ) {
                /* If column has a message attribute, the message is appended to the end of the text field */
                return column.message ? (
                  <FormHelperText style={column.message.style}>
                    {column.message.text}
                  </FormHelperText>
                ) : null
              } else {
                const widgetType = getWidgetTypeName(column.widget)

                return (
                  <FormControl
                    key={index}
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                    error={errorProps.error}>
                    <TextField
                      name={column.formDataKey || column.name}
                      label={column.label}
                      type={widgetType}
                      variant="outlined"
                      required={!column.optional}
                      disabled={column.editable === false || viewMode}
                      {...(typeof column.validate === 'function'
                        ? {
                            validate: column.validate({
                              isRequired: !column.optional,
                              ...(column.widget === 'datepicker'
                                ? {
                                    min:
                                      typeof column.minDate === 'function'
                                        ? column.minDate(
                                            values,
                                            setFieldValue,
                                            formData
                                          )
                                        : column.minDate,
                                    max:
                                      typeof column.maxDate === 'function'
                                        ? column.maxDate(
                                            values,
                                            setFieldValue,
                                            formData
                                          )
                                        : column.maxDate
                                  }
                                : null),
                              ...(column.formValuesRequired
                                ? { formValues: values }
                                : null)
                            })
                          }
                        : null)}
                      {...(widgetType !== 'text'
                        ? {
                            InputLabelProps: {
                              shrink: true
                            }
                          }
                        : null)}
                      {...(column.maxCharacters
                        ? {
                            inputProps: {
                              ...{ maxLength: column.maxCharacters }
                            }
                          }
                        : null)}
                      {...(errorProps.error ? errorProps : null)}
                      {...(column.widget === 'datepicker' &&
                      (column.minDate || column.maxDate)
                        ? {
                            inputProps: {
                              ...{
                                min:
                                  typeof column.minDate === 'function'
                                    ? column.minDate(
                                        values,
                                        setFieldValue,
                                        formData
                                      )
                                    : column.minDate,
                                max:
                                  typeof column.maxDate === 'function'
                                    ? column.maxDate(
                                        values,
                                        setFieldValue,
                                        formData
                                      )
                                    : column.maxDate
                              }
                            }
                          }
                        : null)}
                    />
                    {/* If column has a message attribute, the message is appended to the end of the text field */}
                    {column.message ? (
                      <FormHelperText style={column.message.style}>
                        {column.message.text}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )
              }
            })}
          </Form>
        )}
      </Formik>
    )
  }, [
    formData,
    classes,
    columns,
    currentEditingData,
    handleFileUploadOnChange,
    error
  ])

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={handleModalClose}
      onSaveClick={handleFormSaveClick}
      cancelButton={viewMode}
      modalCss={props.modalCss}>
      {error && error.isError && !isEmpty(error.errorMessage) ? (
        <div className={classes.error}>{error.errorMessage}</div>
      ) : null}
      {!currentEditingData?.uuid && !currentEditingData?.user?.uuid && title ? (
        <div className={classes.heading}>
          <Typography variant="h6">Add new</Typography>&nbsp;{title}
        </div>
      ) : null}
      {headerText ? (
        <div className={classes.subHeading}>
          <Typography variant="subtitle2">{headerText}</Typography>
        </div>
      ) : null}
      {formElement}
    </ModalForm>
  )
}

BaseForm.defaultProps = {
  viewMode: false
}

BaseForm.propTypes = {
  viewMode: PropTypes.bool,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  handleFormData: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  currentEditingData: PropTypes.object.isRequired,
  headerText: PropTypes.string
}

export default BaseForm
