import React from 'react';
import * as styles from './styles';

const MessagePoh = ({ iconUrl, messageText, timestamp, ctaText = '' }) => {
  return (
    <styles.PohMessageContainer>
      <styles.Line />
      <styles.AvatarMessage>
        <styles.Avatar src={iconUrl} alt="avatar" />
        <styles.PohMessageContent>
          <styles.MessageText>
            {messageText}
            <styles.CtaText>{ctaText.toLowerCase()}</styles.CtaText>
          </styles.MessageText>
          <styles.Timestamp>{timestamp}</styles.Timestamp>
        </styles.PohMessageContent>
      </styles.AvatarMessage>
      <styles.Line />
    </styles.PohMessageContainer>
  );
};

export default MessagePoh;
