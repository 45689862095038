/* eslint-disable react/prop-types */
import React from 'react'

import { fetchMomentComments } from 'api'
import InfiniteScroll from 'app/components/generic/InfiniteScroll/InfiniteScroll'

export const CommentsTable = ({ title, momentId }) => {
  const columns = [
    {
      name: 'user.full_name',
      label: 'Name',
      options: {
        filter: false
      }
    },
    {
      name: 'user.phone_number',
      label: 'Phone',
      options: {
        filter: false
      }
    },
    {
      name: 'user_type',
      label: 'User type',
      options: {
        filter: false
      }
    },
    {
      name: 'body',
      label: 'Comment',
      options: {
        filter: false
      }
    }
  ]

  return (
    <InfiniteScroll
      title={title}
      columns={columns}
      fetchData={fetchMomentComments}
      queryParameters={{
        moment: momentId
      }}
    />
  )
}
