import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  TableHead,
  Select
} from '@mui/material'
import { isEmpty, isNull } from 'lodash'
import React, { useEffect, useState } from 'react'

import { updateTeacherDetails } from 'api'
import { AutoComplete } from 'app/components/generic/FormFields'
import constants from 'app/constants'
import { eventAlert, fetchTeacher } from 'app/helpers'

import { arrivalStatus } from './config'
import * as styles from './styles'

const TeacherAttendance = (props) => {
  const { teacher, fetchValues, attendanceDay } = props
  const teacherAttendance = teacher?.teacher_attendance
  const actualTeacher = teacherAttendance?.actual_teacher?.user
  const scheduledTeacher = teacherAttendance?.scheduled_teacher?.user
  const [teacherId, setTeacherId] = useState(actualTeacher?.uuid)
  const [isSubstitute, setIsSubstitute] = useState(false)
  const [componentDisabled, setComponentDisable] = useState(false)
  const scheduledTeacherDetails = {
    value: scheduledTeacher?.uuid,
    name: `${scheduledTeacher?.full_name} (${scheduledTeacher?.phone_number})`
  }
  const actualTeacherDetails = {
    value: actualTeacher?.uuid,
    name: `${actualTeacher?.full_name} (${actualTeacher?.phone_number})`,
    attended: `${teacherAttendance?.attended ? 'present' : 'absent (no-show)'}`,
    arrival: teacherAttendance?.attended
      ? arrivalStatus.filter((item) => {
          return item.value === teacherAttendance?.arrival
        })[0].title
      : 'NA'
  }
  useEffect(() => {
    scheduledTeacher?.uuid === teacherId
      ? setIsSubstitute(false)
      : setIsSubstitute(true)
  }, [])

  const handleChange = async (name, value) => {
    let payload = {}
    switch (name) {
      case 'teacher':
        if (value && value?.value && value.value !== teacherId) {
          payload = { actual_teacher: value.value }
        } else return
        break
      case 'attended':
        payload = { attended: value }
        setComponentDisable(true)
        break
      case 'on_time':
        payload = { arrival: value }
        break
    }
    try {
      if (!isEmpty(payload)) {
        await updateTeacherDetails({
          uuid: teacherAttendance?.uuid,
          payload
        })
        let res = fetchValues(null, null)
        if (name === 'teacher' && res) {
          setTeacherId(value.value)
          scheduledTeacher?.uuid !== value.value
            ? setIsSubstitute(true)
            : setIsSubstitute(false)
        }
        if (res || res === false) setComponentDisable(false)
      }
    } catch (error) {
      eventAlert(error.message, constants.ERROR)
    }
  }

  return (
    <styles.Container>
      <TableContainer className="topHeader">
        <TableHead className="header">
          <TableRow>
            <TableCell>
              Primary Teacher : {scheduledTeacherDetails?.name}
            </TableCell>
          </TableRow>
        </TableHead>
        {attendanceDay !== 'future' ? (
          <Table
            sx={{
              width: 'max-content',
              margin: 0
            }}
          >
            {isSubstitute ? (
              <caption className="bold">
                Note : This session has {actualTeacherDetails.name} as the
                substitute teacher
              </caption>
            ) : (
              <caption />
            )}
            <TableHead
              sx={{
                backgroundColor: '#282e3c',
                color: '#fff'
              }}
            >
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Attendance</TableCell>
                <TableCell>Arrival Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceDay === 'present' ? (
                <TableRow>
                  <TableCell>
                    <AutoComplete
                      async={true}
                      name="teacher"
                      label="Teacher Details"
                      size="small"
                      className="teacherDetails"
                      multiple={false}
                      fetchValues={fetchTeacher}
                      defaultValue={actualTeacherDetails}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      type="checkbox"
                      color="success"
                      name="attended"
                      checked={teacherAttendance?.attended}
                      disabled={
                        componentDisabled || isNull(actualTeacherDetails.uuid)
                      }
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <InputLabel id="arrival-status">
                        Arrival Status
                      </InputLabel>
                      <Select
                        labelId="arrival-status"
                        name="on_time"
                        className="arrival"
                        id="arrival-status-select"
                        label="Arrival Status"
                        disabled={isNull(actualTeacherDetails.uuid)}
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        defaultValue={teacherAttendance?.arrival}
                      >
                        {arrivalStatus.map((item) => {
                          return (
                            <MenuItem value={item.value} key={item.id}>
                              {item.title}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell>{actualTeacherDetails?.name}</TableCell>
                  <TableCell
                    className={`${actualTeacherDetails?.attended} bold`}
                  >
                    {actualTeacherDetails.attended}
                  </TableCell>
                  <TableCell>{actualTeacherDetails?.arrival}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : null}
      </TableContainer>
    </styles.Container>
  )
}

export default TeacherAttendance
