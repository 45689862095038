import React from 'react'

import {
  commonAttributes,
  indianStatesJson,
  tableColumnDefaultOption,
  uploadPresets
} from 'app/config'
import constants from 'app/constants'
import {
  validateFee,
  validateIfsc,
  validateName,
  validateNotEmpty,
  validatePercentage
} from 'app/validators'

import { columns as commonColumns } from '../common'

const property_paths = [
  'user.uuid',
  'user.phone_number',
  'user.first_name',
  'user.last_name',
  'user.email_address',
  'user.address',
  'user.bio',
  'user.date_of_birth'
]

export const columns = [
  ...commonColumns.filter((item) => property_paths.includes(item.name)),
  {
    ...commonColumns.find((item) => ['user'].includes(item.name)),
    modalEdit: false,
    editable: false,
    disabledInFormOnEdit: true,
    disabledInFormOnCreate: true,
    options: {
      ...commonColumns.find((item) => ['user'].includes(item.name)).options,
      display: false
    }
  },
  {
    ...commonAttributes.image,
    name: 'user_image',
    label: 'Profile Picture',
    fileUrlOnly: true,
    uploadPreset: uploadPresets[constants.USER]
  },
  {
    ...tableColumnDefaultOption,
    name: 'state',
    label: 'state',
    widget: 'select',
    widgetFilter: true,
    valueLabelMappings: indianStatesJson,
    modalEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'state',
      customBodyRender: (value) => <span>{indianStatesJson[value]}</span>
    }
  },
  {
    name: 'minimum_guarantee',
    label: 'Minimum guarantee',
    optional: true,
    validate: validateFee,
    ...commonAttributes.amount
  },
  {
    name: 'bank_account_holder_name',
    label: 'Bank Account holder name',
    optional: true,
    validate: validateName,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'bank_account_number',
    label: 'Bank Account number',
    optional: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'bank_ifsc_code',
    label: 'Bank IFSC code',
    optional: true,
    validate: validateIfsc,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'oh_share_ts',
    label: 'Oh Share (Source teacher)',
    validate: validatePercentage,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'oh_share_ohs',
    label: 'Oh Share (Source oh)',
    validate: validatePercentage,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'dispatch_rate_per_session_ohs',
    label: 'Dispatch Rate (openhouse)',
    validate: validatePercentage,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'dispatch_rate_per_session_ts',
    label: 'Dispatch Rate (teacher)',
    validate: validatePercentage,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  }
]
