/* eslint-disable react/prop-types */
import React from 'react'

import { columns, tableOptions } from './config'
import { StyledMUIDataTable } from './styles'

export const DataTable = ({
  title,
  data,
  fieldName,
  setFieldValue,
  options = {}
}) => {
  return React.useMemo(
    () => (
      <StyledMUIDataTable
        title={title}
        columns={columns}
        data={data}
        options={{
          ...tableOptions,
          customFooter: () => <tfoot />,
          onTableChange: (action, tableState) => {
            if (['rowDelete', 'propsUpdate'].includes(action)) {
              const student = tableState.data.map(
                (student) => data[student.index]
              )
              setFieldValue(fieldName, student)
            }
          },
          ...options
        }}
      />
    ),
    [data]
  )
}
