import Dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, put } from '../request'

const fetchDuePayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.paymentInvoice}?status=due&type=enrolment`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting due payment :- ', error.message)
    throw error
  }
}

const fetchPaidPayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.paymentInvoice}?status=paid&type=enrolment`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: response.data.results.map((item) => {
        return {
          ...item,
          ...(item?.pay_in
            ? {
                pay_in: {
                  ...item.pay_in,
                  created: item?.pay_in?.created
                    ? new Dayjs(item?.pay_in?.created).format('YYYY-MM-DD')
                    : null
                }
              }
            : null)
        }
      })
    }
  } catch (error) {
    console.log('error in getting paid payment :- ', error.message)
    throw error
  }
}

const fetchDueMemberPayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.paymentInvoice}?status=due&type=subscription`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting due payment :- ', error.message)
    throw error
  }
}

const fetchPaidMemberPayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.paymentInvoice}?status=paid&type=subscription`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: response.data.results.map((item) => {
        return {
          ...item,
          ...(item?.pay_in
            ? {
                pay_in: {
                  ...item.pay_in,
                  created: item?.pay_in?.created
                    ? new Dayjs(item?.pay_in?.created).format('YYYY-MM-DD')
                    : null
                }
              }
            : null)
        }
      })
    }
  } catch (error) {
    console.log('error in getting paid payment :- ', error.message)
    throw error
  }
}

const fetchDueRenewalPayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.paymentInvoice}?status=due&type=subscription_renewal`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting due payment :- ', error.message)
    throw error
  }
}

const fetchPaidRenewalPayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.paymentInvoice}?status=paid&type=subscription_renewal`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: response.data.results.map((item) => {
        return {
          ...item,
          ...(item?.pay_in
            ? {
                pay_in: {
                  ...item.pay_in,
                  created: item?.pay_in?.created
                    ? new Dayjs(item?.pay_in?.created).format('YYYY-MM-DD')
                    : null
                }
              }
            : null)
        }
      })
    }
  } catch (error) {
    console.log('error in getting paid payment :- ', error.message)
    throw error
  }
}

const fetchTeacherPaymentSummary = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.user}/teacher/summary`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting teacher payment summary :- ', error.message)
    throw error
  }
}

const fetchSuperproviderPaymentSummary = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.user}/teacher/summary`, queries) //TODO: change url

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log(
      'error in getting superprovider payment summary :- ',
      error.message
    )
    throw error
  }
}

const fetchIndependentPaymentSummary = async ({ url, queries } = {}) => {
  const startDate = queries.start_date_lte
    ? Dayjs(queries.start_date_lte).startOf('month').format('YYYY-MM-DD')
    : null
  const modifiedQueries = {
    ...queries,
    start_date: startDate
  }
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.payment}/teacher_payout`, {
        ...modifiedQueries,
        status_ne: 'ongoing'
      })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log(
      'error in getting independent payment summary :- ',
      error.message
    )
    throw error
  }
}

const fetchSessionsPaymentSummary = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.payment}/teacher_payout/${queries.uuid}/attendances`,
        { ...queries, limit: 200 }
      )

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log(
      'error in getting independent payment summary :- ',
      error.message
    )
    throw error
  }
}

const fetchEditsItemTypes = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.payment}/payout_items_types`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting item types :- ', error.message)
    throw error
  }
}

const fetchIndependentOngoingPaymentSummary = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.payment}/teacher_payout`, {
        ...queries,
        status: 'ongoing'
      })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log(
      'error in getting independent payment ongoing summary :- ',
      error.message
    )
    throw error
  }
}

const fetchStudentPaymentSummary = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.user}/student/summary`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting student payment summary :- ', error.message)
    throw error
  }
}

const fetchDispatch = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.payment}/pay_out/alfred`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in getting dispatch :- ', error.message)
    throw error
  }
}

const createInvoiceAction = async (data) => {
  let requestData = {
    invoice: data.invoice,
    action: data.action
  }

  if (!isEmpty(data.invoice)) {
    switch (data.action) {
      case 'collect':
        requestData = {
          ...requestData,
          notes: `${data.payment_mode}, ${data.notes}` // here joining payment mode and extra notes in string as per api requirement
        }
        break
      case 'edit':
        requestData = {
          ...requestData,
          notes: data.notes,
          value: data.value
        }
        break
      case 'cancel':
        requestData = {
          ...requestData,
          notes: data.notes
        }
        break
      default:
        break
    }
  } else {
    throw new Error('invoice is missing / invalid')
  }

  try {
    await post(`${apiEndpoints.payment}/invoice_actions`, requestData)

    return data
  } catch (error) {
    console.log('error in updating invoice :- ', error.message)
    throw error
  }
}

const createTeacherDispatch = async (data) => {
  let requestData = {
    teacher_uuid: data.teacher_uuid,
    process: false
  }

  if (data.action === 'confirm') {
    requestData = {
      ...requestData,
      process: true,
      notes: data.notes,
      mode: data.mode,
      utr_id: data.utr_id
    }
  }

  try {
    const response = await post(
      `${apiEndpoints.payment}/process_partner_payout`,
      requestData
    )

    return response.data
  } catch (error) {
    console.log('error in teacher dispatch : -', error.message)
    throw error
  }
}

const createSuperproviderDispatch = async (data) => {
  let requestData = {
    superprovider_uuid: data.superprovider_uuid,
    process: false
  }

  if (data.action === 'confirm') {
    requestData = {
      ...requestData,
      process: true,
      notes: data.notes,
      mode: data.mode,
      utr_id: data.utr_id
    }
  }

  try {
    const response = await post(
      `${apiEndpoints.payment}/process_partner_payout`,
      requestData
    )

    return response.data
  } catch (error) {
    console.log('error in superprovider dispatch : -', error.message)
    throw error
  }
}

const createIndependentDispatch = async (data) => {
  let requestData = {}

  if (data.action === 'status') {
    requestData = {
      status: data.status
    }
  } else if (data.action === 'confirm') {
    requestData = {
      tax: parseFloat(data.tax),
      notes: data.notes,
      mode: data.mode,
      utr_id: data.mode === 'bank' ? data.utr_id : null,
      status: data.status
    }
  }

  try {
    const response = await patch(
      `${apiEndpoints.payment}/teacher_payout/${data.independent_uuid}`,
      requestData
    )

    return response.data
  } catch (error) {
    console.log('error in independent dispatch : -', error.message)
    throw error
  }
}

const createIndependentEditsDispatch = async (data) => {
  let requestData = []

  if (data.action === 'edit') {
    requestData = data.items
  }

  const items = requestData?.map((val) => {
    return {
      ...(val.uuid && { uuid: val.uuid }), //TeacherPayoutItem uuid if update / delete else null
      item_type: val.item_type.uuid, //PayoutItemType
      name: val.name,
      value: val.value, //number
      notes: val.notes, //string
      action: val.action //create / update / delete
    }
  })

  try {
    const response = await put(
      `${apiEndpoints.payment}/teacher_payout/${data.independent_uuid}/items`,
      items
    )

    return response.data
  } catch (error) {
    console.log('error in independent dispatch : -', error.message)
    throw error
  }
}

export {
  fetchDuePayment,
  fetchPaidPayment,
  fetchDueMemberPayment,
  fetchPaidMemberPayment,
  fetchDueRenewalPayment,
  fetchPaidRenewalPayment,
  fetchTeacherPaymentSummary,
  fetchStudentPaymentSummary,
  fetchIndependentPaymentSummary,
  fetchSessionsPaymentSummary,
  fetchEditsItemTypes,
  fetchIndependentOngoingPaymentSummary,
  fetchSuperproviderPaymentSummary,
  fetchDispatch,
  createInvoiceAction,
  createTeacherDispatch,
  createIndependentDispatch,
  createSuperproviderDispatch,
  createIndependentEditsDispatch
}
