import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { commonAttributes, progressTrackingActions } from 'app/config'
import constants from 'app/constants'
import { fetchExploreSessionFeedbackData } from 'app/store/actions/classEntity'
import { formatedDate } from 'app/utils'

import { columns } from './config'

import { MomentModal } from '../components/MomentsModal/MomentModal'

const ExploreSession = () => {
  const [isShowMomentsModalOpen, setIsShowMomentsModalOpen] = useState(false)
  const [modalType, setModalType] = useState('')
  const [showModalData, setShowMomentData] = useState([])
  const exploreSessionFeedbackData = useSelector(
    (state) => state.classEntityReducer.exploreSessionFeedbackData
  )

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'show_Moments') {
      setIsShowMomentsModalOpen(true)
      setModalType('show_Moments')
      setShowMomentData(exploreSessionFeedbackData?.data[dataIndex])
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      {
        ...commonAttributes.date,
        name: 'date',
        label: 'Session date',
        widget: 'datepicker',
        options: {
          ...commonAttributes.date.options,
          customBodyRenderLite: (dataIndex) => {
            const { attendance } = exploreSessionFeedbackData.data[dataIndex]
            return <span>{formatedDate(attendance.schedule.date)}</span>
          }
        }
      },
      ...columns,
      {
        name: '',
        label: 'actions',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { moment_consumers = [] } =
              exploreSessionFeedbackData.data[dataIndex]
            return (
              <ButtonGroup>
                {Object.keys(progressTrackingActions)
                  .filter(() => !isEmpty(moment_consumers))
                  .map((action, index) => {
                    return (
                      <Button
                        key={'Moment' + index}
                        title={progressTrackingActions[action]}
                        onClick={(e) => {
                          onActionButtonClick(e, action, dataIndex)
                        }}
                      >
                        {progressTrackingActions[action]}
                      </Button>
                    )
                  })}
              </ButtonGroup>
            )
          }
        }
      }
    ]
  }, [exploreSessionFeedbackData])

  return (
    <>
      <BaseEntity
        entity={constants.EXPLORE_SESSION_FEEDBACK}
        label={constants.EXPLORE_SESSION_FEEDBACK_LABEL}
        fetchData={fetchExploreSessionFeedbackData}
        data={exploreSessionFeedbackData}
        columns={modifiedColumns}
        readOnly
      />

      <MomentModal
        isModalOpen={isShowMomentsModalOpen}
        onModalClose={() => {
          setIsShowMomentsModalOpen(false)
        }}
        data={showModalData}
        modalType={modalType}
      />
    </>
  )
}

export default ExploreSession
