import { IconButton, Badge } from '@material-ui/core'
import { ShoppingCart } from '@material-ui/icons'
import {
  getValueFromLocalStorage,
  setValueInLocalStorage
} from '@openhouse-technologies/utils'
import React from 'react'
import { useCart } from 'react-use-cart'

import Slider from 'app/components/generic/Slider/slider'
import { confirm } from 'app/helpers'
import CartImage from 'assets/images/cart_image.png'

import { CartFooter } from './components/CartFooter'
import { CartHeader } from './components/CartHeader'
import { CartItems } from './components/CartIems'
import TotalAndActionsComponent from './components/TotalAndActions'
import * as Styles from './styles'

const CartSlider = ({
  onCartItemEditHandler,
  checkoutButtonClickHandler,
  coinSummary,
  ctaOptions
}) => {
  const {
    items: cartItems,
    totalUniqueItems: totalUniqueCartItems,
    emptyCart,
    removeItem: removeItemFromCart
  } = useCart()
  const selectedCentreName = getValueFromLocalStorage('SELECTED_CENTRE')?.name
  const selectedParentDetails = getValueFromLocalStorage('CART_PARENT')

  const cartIconButton = (
    <Styles.ActionContainer>
      <IconButton>
        <Badge badgeContent={totalUniqueCartItems} color="primary">
          <ShoppingCart color="action" />
        </Badge>
      </IconButton>
    </Styles.ActionContainer>
  )

  const emptyCartClickHandler = (closeSlider) => {
    if (cartItems.length > 0) {
      confirm(
        () => {
          setValueInLocalStorage('CART_PARENT', {})
          emptyCart()
          closeSlider()
        },
        'You will lose all the data in the cart!',
        () => {},
        { icon: '', confirmButtonText: 'Confirm' }
      )
    } else {
      closeSlider()
      setValueInLocalStorage('CART_PARENT', {})
    }
  }

  const onDeleteHandler = (item, closeSlider) => {
    confirm(
      () => {
        if (cartItems?.length > 1) removeItemFromCart(item.id)
        else {
          emptyCart()
          setValueInLocalStorage('CART_PARENT', {})
          closeSlider()
        }
      },
      `Are you sure you want to delete ${item?.batchDetails?.course?.name} for ${item?.userInfo?.student?.user?.full_name} from the cart?`,
      () => {},
      { icon: '', confirmButtonText: 'Delete' }
    )
  }

  const onEditHandler = (item) => {
    onCartItemEditHandler(item)
  }

  const groupItemsByStudent = (items) => {
    return items.reduce((acc, item) => {
      const studentId = item?.userInfo?.student?.user?.uuid
      if (!acc[studentId]) {
        acc[studentId] = []
      }
      acc[studentId].push(item)
      return acc
    }, {})
  }

  const CartContent = ({
    closeSlider,
    selectedParentDetails,
    checkoutButtonClickHandler
  }) => {
    const groupedItems = groupItemsByStudent(cartItems)

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CartHeader
          selectedCentreName={selectedCentreName}
          onEmptyCartClick={() => {
            emptyCartClickHandler(closeSlider)
          }}
          selectedParentDetails={selectedParentDetails}
          coinSummary={coinSummary}
        />
        {cartItems.length > 0 ? (
          <Styles.ScrollContainer>
            <TotalAndActionsComponent
              checkoutButtonClickHandler={checkoutButtonClickHandler}
              ctaOptions={ctaOptions}
              coinSummary={coinSummary}
            />

            <CartItems
              groupedItems={groupedItems}
              onEditHandler={onEditHandler}
              onDeleteHandler={(item) => {
                onDeleteHandler(item, closeSlider)
              }}
            />
          </Styles.ScrollContainer>
        ) : (
          <Styles.EmptyCartContainer>
            <Styles.EmptyCartSubcontainer>
              <Styles.CartImageContainer>
                <Styles.CartImage src={CartImage} alt="cart" />
              </Styles.CartImageContainer>
              <Styles.EmptyCartHeading>empty cart</Styles.EmptyCartHeading>
              <Styles.EmptyCartSubheading>
                select a batch to get started
              </Styles.EmptyCartSubheading>
            </Styles.EmptyCartSubcontainer>
          </Styles.EmptyCartContainer>
        )}

        <CartFooter closeSlider={closeSlider} />
      </div>
    )
  }

  return (
    <Slider
      placeholder={cartIconButton}
      childComponent={
        <CartContent
          selectedParentDetails={selectedParentDetails}
          checkoutButtonClickHandler={checkoutButtonClickHandler}
        />
      }
      width="380px"
      hideHeader
    />
  )
}

export default CartSlider
