import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { fetchPaidMemberData } from 'app/store/actions/paymentEntity'

import { columns } from './config'

const PaidMember = () => {
  const paidMemberData = useSelector(
    (state) => state.paymentEntityReducer.paidMemberData
  )

  const CustomToolbarElement = <div>Total : ₹ {paidMemberData?.paid ?? 0}</div>

  return (
    <BaseEntity
      entity={constants.PAID_MEMBER}
      label={constants.PAID_MEMBER_LABEL}
      fetchData={fetchPaidMemberData}
      data={paidMemberData}
      columns={columns}
      CustomToolbarElement={CustomToolbarElement}
      readOnly
    />
  )
}

export default PaidMember
