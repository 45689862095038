import { makeStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import React, { useState } from 'react'

import { fetchDeductionReasons } from 'api'
import { TextField, Select } from 'app/components/generic/FormElements'
import { validator, validateFee, validateNotEmpty } from 'app/validators'

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: '#212121',
    fontSize: '16px'
  },
  demiSpan: {
    fontFamily: 'AvenirDemi'
  }
}))

const DeductForm = (props) => {
  const classes = useStyles()

  const { formData } = props
  const { errors, values, setFieldValue } = useFormikContext()

  const [deductionReasons, setDeductionReasons] = useState([])

  //UseEffect for fetching deduction reasons array
  React.useEffect(() => {
    ;(async () => {
      const deductionReasonsConfig = await fetchDeductionReasons()
      setDeductionReasons(deductionReasonsConfig.results[0].value)
    })()
  }, [])

  React.useEffect(() => {
    if (formData?.current_balance) {
      setFieldValue('current_balance', formData?.current_balance)
    }
  }, [formData?.current_balance])

  React.useEffect(() => {
    if (values?.coins > 0) {
      const price = values?.current_balance - values?.coins
      setFieldValue('new_balance', price)
    }
  }, [values?.coins, values?.current_balance])

  const validateCoins =
    ({ isRequired } = {}) =>
    (value) => {
      return validator(isRequired, value, () => {
        if (!isNil(value) && typeof value !== 'string') {
          value = value.toString()
        }
        if (
          (typeof value === 'string' &&
            (isEmpty(value) || value === '<p></p>/n')) ||
          parseInt(value) <= 0
        ) {
          return 'Please enter a valid amount'
        }
      })
    }

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.current_balance) ? { error: true } : null)}
        required>
        <TextField
          name={'current_balance'}
          label={'Current Balance'}
          variant="outlined"
          required
          disabled
          validate={validateFee({ isRequired: true })}
        />
        {!isEmpty(errors.current_balance) && (
          <FormHelperText>{errors.current_balance}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.coins) ? { error: true } : null)}
        required>
        <TextField
          name={'coins'}
          label={'Coins to deduct'}
          variant="outlined"
          required
          validate={validateCoins({ isRequired: true })}
        />
        {!isEmpty(errors.coins) && (
          <FormHelperText>{errors.coins}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.new_balance) ? { error: true } : null)}
        required>
        <TextField
          name={'new_balance'}
          label={'New Balance'}
          variant="outlined"
          required
          disabled
          validate={validateFee({ isRequired: true })}
        />
        {!isEmpty(errors.new_balance) && (
          <FormHelperText>{errors.new_balance}</FormHelperText>
        )}
      </FormControl>

      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.addCoinsType) ? { error: true } : null)}
        required>
        <InputLabel>{'Reason'}</InputLabel>
        <Select
          name={'reason'}
          defaultValue={''}
          label={'Reason'}
          validate={validateNotEmpty({ isRequired: true })}>
          {deductionReasons?.map((key, index) => {
            return (
              <MenuItem
                key={deductionReasons[index]}
                value={deductionReasons[index]}>
                {deductionReasons[index]}
              </MenuItem>
            )
          })}
        </Select>
        {!isEmpty(errors.addCoinsType) && (
          <FormHelperText>{errors.addCoinsType}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.reason) ? { error: true } : null)}
        required>
        <TextField
          name={'notes'}
          label={'Notes'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
        />
        {!isEmpty(errors.reason) && (
          <FormHelperText>{errors.reason}</FormHelperText>
        )}
        {values.reason === 'Others' ? (
          <FormHelperText className={classes.errorText}>
            Please note: This message will be{' '}
            <span className={classes.demiSpan}>
              visible to parents on the app
            </span>
            . Ensure it is clear, professional and informative.
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  )
}

export default DeductForm
