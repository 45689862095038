import React from 'react'
import * as styles from './styles'
import UnreadCount from 'app/components/generic/UnreadCount'
import Avatar from 'app/components/generic/Avatar'
import PillList from 'app/components/generic/PillList'
import IconText from 'app/components/generic/IconText'
import { formatTimestamp } from '../../helpers'

const StudentListCard = (props) => {

  const {
    name,
    thumbnailUrl,
    timestamp,
    pillData,
    iconURL,
    lastMessage,
    lastMessageStatus,
    lastMessageAuthor,
    unreadCount,
    onClick,
    isSelected,
    lastListItem,
    avatarImageStyles = {},
    avatarTextStyles = {},
    showAvatarPill = false,
    avatarPillText,
    showPill = true,
    showPillIcon,
    showCustomLastMessage = false,
    showOnlyAdminReadReceipts = false
  } = props

  const displayedLastMessage = showCustomLastMessage
    ? 'start a conversation'
    : lastMessage?.length > 40
      ? `${lastMessage?.substring(0, 40).trim()}...`
      : lastMessage

  const showAdminReadReciepts = lastMessageStatus === 'read' && lastMessageAuthor === 'admin' ? true : false

  return (
    <div onClick={onClick}>
      <styles.SelectedCardContainer isSelected={isSelected} lastListItem={lastListItem}>
        <styles.InnerCardContainer>
          <Avatar
            thumbnailUrl={thumbnailUrl}
            name={name}
            avatarTextStyles={avatarTextStyles}
            avatarImageStyles={avatarImageStyles}
            showAvatarPill={showAvatarPill}
            avatarPillText={avatarPillText}
          />
          <styles.CardDetails>
            <styles.TopRow>
              <styles.Title>{name?.toLowerCase()}</styles.Title>
              <styles.Time>{formatTimestamp(timestamp)}</styles.Time>
            </styles.TopRow>
            {showPill &&
              <PillList
                data={pillData}
                isSelected={isSelected}
                truncationLength={20}
                showPillIcon={showPillIcon}
              />
            }
            <styles.BottomRow>
              <IconText
                url={iconURL}
                text={displayedLastMessage}
                isDisplayIcon={showOnlyAdminReadReceipts ? showAdminReadReciepts : (lastMessageStatus === 'read' && !showCustomLastMessage) ? true : false}
                customStyle={showCustomLastMessage ? styles.CustomLastMessageTextStyles : styles.TextStyles}
              />
              <UnreadCount unreadCount={unreadCount} />
            </styles.BottomRow>
          </styles.CardDetails>
        </styles.InnerCardContainer>
      </styles.SelectedCardContainer>
    </div >
  )
}

export default StudentListCard