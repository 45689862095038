import { Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import ConfirmIcon from '@material-ui/icons/ThumbUpAlt'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { isEmpty } from 'lodash'
import React from 'react'

import { LoadingButton } from 'app/components/generic/LoadingButton'

import * as Styles from './styles'

const Actions = ({
  isCheckoutMode,
  onNextClick,
  onEditClick,
  onConfirmClick,
  isOrderInProcessing,
  invalidCoins,
  commonCourse,
  showEditOption,
  disableConfirm
}) => {
  const theme = useTheme()

  return (
    <Styles.ActionContainer isCheckoutMode={isCheckoutMode}>
      <Styles.Wrapper>
        {!isEmpty(commonCourse) ? (
          <>
            <p>
              Please note: Your selections have existing bookings to extend:{' '}
            </p>
            <p>
              {commonCourse.map((item, index) => {
                return ` ${item.name}  ${item.start_date} - ${item.end_date} ${
                  commonCourse.length - 1 === index ? '' : `|`
                } `
              })}
            </p>
            <p>
              The sessions will be booked after the last session of the current
              booking.
            </p>
          </>
        ) : null}
      </Styles.Wrapper>
      <Styles.ButtonWapper>
        {isCheckoutMode ? (
          <>
            {!showEditOption ? (
              <Button
                color="primary"
                variant="contained"
                onClick={onEditClick}
                startIcon={<EditIcon />}>
                Edit
              </Button>
            ) : null}

            <MuiThemeProvider theme={theme}>
              <LoadingButton
                loading={isOrderInProcessing}
                disabled={isOrderInProcessing || disableConfirm}
                loadingPosition="start"
                startIcon={<ConfirmIcon />}
                variant="contained"
                color="primary"
                onClick={onConfirmClick}>
                {isOrderInProcessing ? 'Processing' : 'Confirm'}
              </LoadingButton>
            </MuiThemeProvider>
          </>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={onNextClick}
            disabled={invalidCoins || disableConfirm}>
            Next
          </Button>
        )}
      </Styles.ButtonWapper>
    </Styles.ActionContainer>
  )
}

export { Actions }
