import { isEmpty } from '@openhouse-technologies/utils'
import dayjs from 'dayjs'

export const pauseRequestBodyStructure = (data) => {
  const {
    month,
    resume_date,
    reason,
    delete_makeups,
    reason_for_using_alfred
  } = data

  const formattedResumeDate = dayjs(resume_date).format('YYYY-MM-DD')
  const monthDetails = data?.monthList?.find((item) => item.month === month)
  const { year, is_current_month, coin_refund_calculation } = monthDetails || {}
  const refund_value = coin_refund_calculation?.total_refund_amount

  return {
    month,
    year,
    is_current_month,
    resume_date: formattedResumeDate,
    reason: reason === '' ? null : reason,
    refund_amount: refund_value ? refund_value : 0,
    delete_makeups: delete_makeups ? delete_makeups : false,
    reason_for_using_alfred
  }
}

export const unsubscribeRequestBodyStructure = (data) => {
  const { month, year, last_session_date, reason } = data
  return {
    month,
    year,
    last_session_date,
    reason
  }
}

export const changeBatchTimingsRequestBodyStructure = (data) => {
  const { new_batch, reason } = data
  return {
    batch: new_batch,
    reason: isEmpty(reason) ? null : reason
  }
}

export const submitDiscoveryReportRequestBodyStructure = (data) => {
  const { level, milestone, is_discovery_successful } = data
  return {
    is_discovery_successful,
    level: is_discovery_successful && level !== 'N/A' ? level : null,
    milestone: is_discovery_successful && milestone !== 'N/A' ? milestone : null
  }
}

export const getUpcomingMakeupsQueryParams = (data) => {
  const { batch, student_uuid } = data
  const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
  return {
    batch,
    student: student_uuid,
    enrolment_type: 'make_up_session',
    enrolment_status: 'active,upcoming',
    upcoming_time: currentDateTime
  }
}

export const transformMakeupSessions = (data) =>
  data?.results?.map((session) => session?.schedule?.date)

export const transformLevelOptions = (levels) =>
  levels.map((level) => ({
    label: level.name,
    value: level.uuid,
    milestones: level.milestones.map((milestone) => ({
      label: milestone.sequence + ': ' + milestone.name,
      value: milestone.uuid
    }))
  }))
