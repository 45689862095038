import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api'
import { AutoComplete } from 'app/components/generic/FormElements'
import { validator } from 'app/validators'

const ChangePlanForm = (props) => {
  const { formData } = props
  const { errors, setFieldValue } = useFormikContext()
  const prevValue = formData.plan

  const validatePlan =
    ({ isRequired } = {}) =>
    (value) => {
      return validator(isRequired, value, () => {
        if (!isNil(value) && typeof value !== 'string') {
          value = value.toString()
        }
        if (
          (typeof value === 'string' &&
            (isEmpty(value) || value === '<p></p>/n')) ||
          prevValue === value
        ) {
          return 'Please select a valid plan'
        }
      })
    }

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.plan) ? { error: true } : null)}
        required
      >
        <AutoComplete
          name="plan"
          placeholder="Select plan"
          minCharactersToSearch={0}
          getStructuredValues={(data) => {
            const getItemStructure = (item) => {
              return item
                ? {
                    uuid: item.uuid,
                    name: item.name
                  }
                : null
            }

            if (Array.isArray(data)) {
              return data?.map((item) => {
                return getItemStructure(item)
              })
            } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
              return data?.results?.map((item) => {
                return getItemStructure(item)
              })
            }

            return [getItemStructure(data)]
          }}
          getFormDataValue={(data) => {
            const getItemStructure = (item) => {
              return item.uuid
            }
            return getItemStructure(data)
          }}
          getOptionLabelKey="name"
          getOptionSelectedKey="uuid"
          resultStructure={{
            keyName: 'plan',
            keyValue: 'uuid'
          }}
          apiOptions={{
            method: 'get',
            url: apiEndpoints.plans
          }}
          required={true}
          setFieldValue={setFieldValue}
          formData={formData}
          {...{
            validate: validatePlan({
              isRequired: true
            })
          }}
        />
        {!isEmpty(errors.plan) && (
          <FormHelperText>{errors.plan}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default ChangePlanForm
