import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'app/components/generic/Button'

export const RecordingsTable = (props) => {
  const { data } = props
  const history = useHistory()
  const open = (url) => {
    window.open(url)
  }
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Recording</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((recording, index) => (
            <TableRow key={index}>
              <TableCell>{recording.date}</TableCell>
              <TableCell>
                {recording.recordingUrl ? (
                  <Button onClick={() => open(recording.recordingUrl)}>
                    Show Recording
                  </Button>
                ) : (
                  'No Recording'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

RecordingsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
