/* eslint-disable react/prop-types */
import { isArray, isEmpty } from 'lodash'
import React from 'react'

import * as styles from './styles'

import ListIcon from '../../../assets/images/list_icon.svg'

export const TitleDescription = (props) => {
  const {
    heading,
    description,
    size,
    color,
    flexType,
    subtitle,
    align,
    showHeading,
    parentUrl,
    descriptionTextTransform = 'lowercase'
  } = props

  return (
    <styles.Body>
      <styles.InfoBlock>
        {showHeading ? (
          <styles.Content heading type="dark">
            {heading}
          </styles.Content>
        ) : null}

        {isArray(description) ? (
          !isEmpty(description) ? (
            description.map((item) => {
              return (
                <styles.Content
                  description
                  size={size}
                  color={color}
                  key={item?.text}
                  type="light"
                >
                  <styles.Icon src={ListIcon} />
                  {item?.text || item}
                </styles.Content>
              )
            })
          ) : (
            <styles.Content description type="light" size={size} color={color}>
              {'not available'}
            </styles.Content>
          )
        ) : parentUrl ? (
          <styles.Content description type="light" size={size} color={color}>
            <a href={parentUrl}>{description}</a>
          </styles.Content>
        ) : (
          <styles.Content
            description
            type="light"
            size={size}
            textTransform={descriptionTextTransform}
            color={color}
          >
            {description}
          </styles.Content>
        )}

        {subtitle && (
          <styles.Content subtitle type="medium">
            {subtitle}
          </styles.Content>
        )}
      </styles.InfoBlock>
    </styles.Body>
  )
}
