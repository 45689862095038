export const enrolmentCardVisibilityList = [
  'excel',
  'exploration',
  'membership',
  'event',
  'explore'
]

export const enrolmentCardProgramList = ['excel', 'exploration', 'membership']

export const enrolmentCardEventList = ['event']

export const enrolmentCardTrialList = ['explore']
