export const scheduleColors = [
  '#95C48C', //green
  '#E87D7C', //red
  '#58B7E6', //blue
  '#F3C520', //yellow
  '#58B7E6', //blue
  '#F3C520', //yellow
  '#95C48C', //green
  '#E87D7C', //red
  '#F3C520', //yellow
  '#95C48C', //green
  '#E87D7C', //red
  '#58B7E6', //blue
  '#F3C520', //yellow
  '#58B7E6', //blue
  '#E87D7C' //red
]

export const subjectsList = [
  { name: 'art', image: require('assets/images/art.svg') },
  { name: 'dance', image: require('assets/images/dance.svg') },
  { name: 'karate', image: require('assets/images/karate.svg') },
  { name: 'vocals', image: require('assets/images/vocals.svg') },
  { name: 'commerce', image: require('assets/images/commerce.svg') },
  { name: 'science', image: require('assets/images/science.svg') },
  { name: 'fitness', image: require('assets/images/fitness.svg') },
  { name: 'theatre', image: require('assets/images/theatre.svg') }
]

export const batchTypesColorList = {
  class: '#ef5c34', //orange
  event: '#58B7E6', //blue
  exploration: '#F3C520', //yellow
  explore: '#95C48C', //green
  membership: '#ef5c34', //orange
  exploration_camp: '#E9C9E1', //lavender
  disabled: 'rgba(0, 0, 0, 0.36)'
}

export const dataColumns = {
  attendance: {
    type: 'ATTENDANCE',
    heading: 'Attendance',
    tableHeadings: [
      { label: 'Student', type: 'text' },
      { label: 'Attendance', type: 'checkbox' }
    ],
    accessors: ['student', 'attended']
  },
  pastAttendance: {
    type: 'PAST_ATTENDANCE',
    heading: 'Attendance',
    tableHeadings: [
      { label: 'Student', type: 'text' },
      { label: 'Attendance', type: 'text' }
    ],
    accessors: ['student', 'attendance']
  },
  futureAttendance: {
    type: 'FUTURE_ATTENDANCE',
    heading: 'Attendance',
    tableHeadings: [
      { label: 'Student', type: 'text' },
      { label: 'Parent Name', type: 'text' },
      { label: 'Parent Number', type: 'text' }
    ],
    accessors: ['student', 'parent_name', 'parent_number']
  },
  student: {
    type: 'STUDENT_ATTENDANCE',
    heading: 'Attendance',
    tableHeadings: [
      { label: 'Course', type: 'text' },
      { label: 'Timings', type: 'text' },
      { label: 'Teacher', type: 'text' },
      { label: 'Attendance', type: 'checkbox' }
    ],
    accessors: ['course', 'timings', 'teacher', 'attended']
  },
  futureStudent: {
    type: 'FUTURE_STUDENT_ATTENDANCE',
    heading: 'Attendance',
    tableHeadings: [
      { label: 'Course', type: 'text' },
      { label: 'Timings', type: 'text' },
      { label: 'Teacher', type: 'text' }
    ],
    accessors: ['course', 'timings', 'teacher']
  }
}

export const emptyStudent = {
  full_name: '',
  phone_number: '',
  uuid: '',
  parent_id: '',
  student_id: '',
  attendances: []
}

export const attendanceType = {
  past: 'pastAttendance',
  future: 'futureAttendance',
  present: 'attendance'
}

export const studentType = {
  past: 'student',
  future: 'futureStudent',
  present: 'student'
}
