import React from 'react'

import * as styles from './styles'
export const ReverifyButton = ({ errorMessage, validateParents }) => {
  return (
    <styles.Wrapper>
      <div>{errorMessage}</div>
      if updated, click{' '}
      <span
        onClick={validateParents}
        style={{
          textDecoration: 'underline',
          color: 'blue',
          cursor: 'pointer'
        }}
      >
        here
      </span>{' '}
      to verify
    </styles.Wrapper>
  )
}
