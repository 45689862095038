import React from 'react'

import * as styles from './styles'

import { Details } from '../components/Details'
export const ChildDetails = (props) => {
  const { studentData } = props

  if (studentData) {
    return (
      <styles.Container>
        <styles.CardColumns>
          {studentData
            ?.filter((_, idx) => !(idx % 2))
            .map((item) => {
              return (
                <div key={item?.id}>
                  <Details
                    heading={item?.heading}
                    icon={item?.icon}
                    detailsData={item.data}
                    isDetailsInRows={false}
                  />
                </div>
              )
            })}
        </styles.CardColumns>
        <styles.CardColumns>
          {studentData
            ?.filter((_, idx) => idx % 2)
            .map((item) => {
              return (
                <div key={item?.id}>
                  <div>
                    <Details
                      heading={item?.heading}
                      icon={item?.icon}
                      detailsData={item.data}
                    />
                  </div>
                </div>
              )
            })}
        </styles.CardColumns>
      </styles.Container>
    )
  } else {
    return <styles.NoDataWrapper>{'No Student Data'}</styles.NoDataWrapper>
  }
}
