import { createTheme } from '@material-ui/core'
import styled from 'styled-components'

export const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
`

export const ModalContent = styled.div`
  width: 70vw;
  height: 70vh;
  border-radius: 4px;
  overflow: hidden scroll;
`

export const tableTheme = createTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        width: '100%',
        height: '100%',
        overflow: 'auto'
      },
      responsiveBase: {
        padding: '24px'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#000',
        color: '#fff'
      }
    },
    MUIDataTableFooter: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fff'
      }
    }
  }
})

export const modalSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const closeIconSx = {
  position: 'absolute',
  right: 12,
  top: 12,
  color: (theme) => theme.palette.grey[500],
  zIndex: 1
}

export const boxSx = {
  position: 'relative'
}
