import MuiDialogActions from '@material-ui/core/DialogActions'
import { withStyles, useTheme } from '@material-ui/core/styles'
import BackIcon from '@material-ui/icons/ArrowBack'
import NextIcon from '@material-ui/icons/ArrowForward'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import React from 'react'

import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

import * as Styles from './styles'
import { isEmpty } from 'lodash'
import MasterForm from '../MasterForm'

const steps = ['Enter Tax Amount', 'Enter Dispatch details']

const DialogActions = withStyles((theme) => ({
  root: {
    height: 64,
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    justifyContent: 'space-between'
  }
}))(MuiDialogActions)

const DispatchForm = (props) => {
  const { isModalOpen, action, onSaveClick, data, editIndex, closeModal } = props
  const formRef = React.useRef(null)
  const [formData, setFormData] = React.useState({
    count: '',
    value: '',
    notes: '',
    tax: '',
    mode: '',
    utr_id: '',
    teacher_name: '',
    final_dispatch_amount: ''
  })

  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})
  const theme = useTheme()

  const onModalClose = React.useCallback(() => {
    setActiveStep(0)
    setCompleted({})
    closeModal()
  }, [])

  const handleOnSaveClick = React.useCallback(async (type, editIndex, data) => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current

      try {
        const errors = await validateForm(values)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()

          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
          if (type === 'edit')
            return true
        } else {
          if (type === 'edit')
            return false
          if (type === 'dispatch')
            await onSaveClick(values, type, editIndex, data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = async () => {
    let error = false
    if (activeStep === 0) {
      error = await handleOnSaveClick('edit')
      console.log(error);
    }

    if (activeStep === 0 && !error) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1
      setActiveStep(newActiveStep)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  React.useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      teacher_name: data?.data[editIndex]?.teacher?.user?.full_name,
      value: data?.data[editIndex]?.value
    }))
  }, [data, editIndex])

  const DialogActionsElement = (
    <DialogActions>
      <MuiThemeProvider theme={theme}>
        {activeStep > 0 && <Styles.LoadingButton
          loading={false}
          loadingPosition="start"
          variant="contained"
          color="primary"
          onClick={handleBack}
          startIcon={<BackIcon />}
          disabled={activeStep === 0}
        >
          back
        </Styles.LoadingButton>}
      </MuiThemeProvider>

      <Styles.NextButtonWrapper>
        <MuiThemeProvider theme={theme}>
          {activeStep === 0 ? (
            <Styles.LoadingButton
              loading={false}
              loadingPosition="start"
              variant="contained"
              color="primary"
              startIcon={<NextIcon />}
              onClick={handleNext}
            >
              next
            </Styles.LoadingButton>
          ) : (
            <Styles.LoadingButton
              loadingPosition="start"
              variant="contained"
              color="primary"
              onClick={() => handleOnSaveClick('dispatch', editIndex, data)}
            >
              confirm
            </Styles.LoadingButton>
          )}
        </MuiThemeProvider>
      </Styles.NextButtonWrapper>
    </DialogActions>
  )

  const renderStep = () => {
    if (activeStep === 0 || activeStep === 1) {
      return (
        <MasterForm
          activeStep={activeStep}
          action={action}
          data={data?.data[editIndex]}
          formRef={formRef}
          formData={formData}
        />
      )
    }

    return null
  }

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      enableSave={false}
      showCloseIcon
      modalTitle="Dispatch"
      disableBackdropClick
      onModalClose={onModalClose}
      modalCss={`
        .MuiDialog-paperScrollPaper {
            overflow-y: unset;
        }
      `}
      DialogActions={DialogActionsElement}
    >
      <Styles.Container>
        <Styles.FormContainer>
          <MuiThemeProvider theme={theme}>
            <Stepper
              activeStep={activeStep}
              orientation="horizontal"
              color="inherit"
            >
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {renderStep()}
          </MuiThemeProvider>
        </Styles.FormContainer>
      </Styles.Container>
    </ModalForm>
  )
}

export { DispatchForm }
