import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty, validateName, validateCount } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'quantity',
    label: 'Quantity',
    editable: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'quantity'
    }
  },
  {
    name: 'label',
    label: 'Label',
    editable: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'label'
    }
  },
  {
    name: 'price_after_discount',
    label: 'Price after discount',
    validate: validateCount,
    editable: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'price_after_discount'
    }
  },
  {
    name: 'price_before_discount',
    label: 'Price before discount',
    validate: validateCount,
    editable: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'price_before_discount'
    }
  },
  {
    name: 'discount_in_rupees',
    label: 'You save',
    validate: validateCount,
    editable: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'discount_in_rupees'
    }
  }
]
