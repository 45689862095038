import React from 'react';
import * as styles from './styles';

const HeadingDescription = (props) => {
    const { heading, description } = props
    return (
        <>
            <styles.Heading>{heading}</styles.Heading>
            <styles.Description>{description}</styles.Description>
        </>
    )
}

export default HeadingDescription