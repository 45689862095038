import { commonAttributes, tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Name',
    ...tableColumnDefaultOption
  }
]
