import React from 'react'
import * as styles from './styles'
import MessageBubble from '../MessageBubble'
import IconText from 'app/components/generic/IconText'
import { checkMark } from '../../constants'
import Avatar from 'app/components/generic/Avatar'

const MessageComponent = ({
  authorName,
  authorType,
  authorIcon,
  timestamp,
  status,
  contentText,
  contentType,
  contentMedia,
  contentCta,
  avatarTextStyles,
  showOnlyAdminReadReceipts = false
}) => {

  const isRightAligned = authorType === 'admin'
  const showAdminReadReciepts = status === 'read' && authorType === 'admin' ? true : false

  return (
    <styles.Container isRightAligned={isRightAligned}>
      {authorType !== 'admin' &&
        <Avatar
          name={authorName}
          thumbnailUrl={authorIcon}
          avatarImageStyles={styles.avatarImageStyles}
          avatarTextStyles={{ ...styles.avatarTextStyles, ...avatarTextStyles }}
        />
      }
      <styles.InnerContainer>
        {authorType === 'admin' && <styles.Name>{authorName?.toLowerCase()?.trim()}</styles.Name>}
        <MessageBubble
          text={contentText}
          authorType={authorType}
          contentType={contentType}
          contentMedia={contentMedia}
          contentCta={contentCta}
        />
        <IconText
          isRightAligned={isRightAligned}
          url={checkMark}
          text={timestamp}
          isDisplayIcon={showOnlyAdminReadReceipts ? showAdminReadReciepts : (status === 'read' && authorType !== 'admin') ? true : false}
          customStyle={styles.TextStyles}
        />
      </styles.InnerContainer>
    </styles.Container>
  )
}

export default MessageComponent