import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const LifeTimeHealth = (props) => {
  const { selectedParentDetails, lifeTimeHealthData } = props
  const customer_start_date = selectedParentDetails?.customer_start_date
  return !isEmpty(lifeTimeHealthData?.data) ? (
    <styles.Body>
      <styles.ColumnFlexCenter>
        <TitleDescription
          heading="Member Since"
          size="medium"
          description={
            customer_start_date
              ? dayjs(customer_start_date).format('D MMM, YY')
              : 'NA'
          }
          align="center"
        />
        <styles.InfoBlock>
          <TitleDescription
            heading="Sessions Attended"
            size="medium"
            description={`${lifeTimeHealthData?.data?.attendedCount}`}
          />

          <TitleDescription
            heading="Attendance %"
            size="medium"
            description={`${lifeTimeHealthData?.data?.attendancePercentage} %`}
          />
        </styles.InfoBlock>
      </styles.ColumnFlexCenter>
    </styles.Body>
  ) : (
    <NotFoundBlock message="Oh no! No data to show." />
  )
}
