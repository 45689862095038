import * as types from '../types/spaceEntity'

export const fetchCentreData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_CENTRE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchCentreDataSuccess = (value, entity) => ({
  type: types.FETCH_CENTRE_DATA_SUCCESS,
  value,
  entity
})

export const fetchRoomData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_ROOM_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchRoomDataSuccess = (value, entity) => ({
  type: types.FETCH_ROOM_DATA_SUCCESS,
  value,
  entity
})

export const updateSpaceData = (entity, event, value) => ({
  type: types.UPDATE_SPACE_DATA,
  entity,
  event,
  value
})

export const updateSpaceDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_SPACE_DATA_SUCCESS,
  entity,
  event,
  value
})
