import _ from 'lodash'
import React from 'react'

import { tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    name: 'uuid',
    label: 'uuid',
    disabledInFormOnCreate: true,
    editable: false,
    optional: true,
    options: {
      ...tableColumnDefaultOption.options,
      display: false
    }
  },
  {
    name: 'title',
    label: 'session title',
    widgetFilter: true,

    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      filter: false,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  }
]
