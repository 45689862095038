import * as types from '../types/eventEntity'

export const fetchEventData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_EVENT_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchEventDataSuccess = (value, entity) => ({
  type: types.FETCH_EVENT_DATA_SUCCESS,
  value,
  entity
})

export const fetchEventRegistrationData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_EVENT_REGISTRATION_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchEventRegistrationDataSuccess = (value, entity) => ({
  type: types.FETCH_EVENT_REGISTRATION_DATA_SUCCESS,
  value,
  entity
})

export const updateEventData = (entity, event, value) => ({
  type: types.UPDATE_EVENT_DATA,
  entity,
  event,
  value
})

export const updateEventDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_EVENT_DATA_SUCCESS,
  entity,
  event,
  value
})
