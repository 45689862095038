import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin-right: 20px;
`

export const Icon = styled.img`
  height: 20px;
  width: 20px;
`

export const TextWrapper = styled.div`
  margin-top: -5px;
  font-size: 20px;
  color: var(--oh-orange);
  font-family: Avenir;
  font-weight: 600;
`
