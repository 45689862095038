import config from '../../common/config'

export const columns = [
  config.parentName,
  config.parentNumber,
  { ...config.centre, name: 'parent.centre_preference.name' },
  config.plan,
  config.dueDate,
  { ...config.invoiceAmount, label: 'Amount Due' },
  config.invoiceType,
  config.invoiceGeneratedBy,
  {
    ...config.link,
    name: 'payment_link',
    label: 'Payment Link'
  }
]
