import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import React from 'react'

import {
  batchTypes,
  commonAttributes,
  tableColumnDefaultOption
} from 'app/config'
import { validateNotEmpty } from 'app/validators'

dayjs.extend(customParseFormat)

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    ...commonAttributes.date,
    name: 'date',
    label: 'Date',
    options: {
      ...commonAttributes.date.options,
      filterKey: 'date',
      customBodyRender: (value) => {
        return value ? <div>{dayjs(value).format('D MMM, YY')}</div> : null
      }
    }
  },
  {
    name: 'batch.course',
    label: 'Course',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'course_name',
      customBodyRender: (value) => {
        return value ? <div>{value.name}</div> : null
      }
    }
  },
  {
    name: 'batch.centre',
    label: 'Centre',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_name',
      customBodyRender: (value) => {
        return value ? <div>{value.name}</div> : null
      }
    }
  },
  {
    name: 'batch.type',
    label: 'Batch Type',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: batchTypes,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'type',
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    ...commonAttributes.teacher,
    name: 'batch.teacher.user.full_name',
    label: 'teacher',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    }
  }
]
