/* eslint-disable react/prop-types */
import { Autocomplete, TextField } from '@mui/material'
import dayjs from 'dayjs'
import _, { get, startCase, isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { apiEndpoints } from 'api'
import AssetUploadWidget from 'app/components/AssetUploadWidget'
import BaseEntity from 'app/components/BaseEntity'
import {
  NO_OF_YEARS_FOR_OPPORTUNITIES,
  commonAttributes,
  tableColumnDefaultOption,
  uploadPresets,
  yesNoOptions
} from 'app/config'
import constants, { products } from 'app/constants'
import { formatLevel } from 'app/helpers'
import {
  fetchCourseData,
  fetchOpportunitiesData,
  updateClassData
} from 'app/store/actions/classEntity'
import { sanitize } from 'app/utils'
import {
  validateBulkImages,
  validateCount,
  validateFee,
  validateNotEmpty,
  validateRange
} from 'app/validators'

import { columns } from './config'

const Opportunities = () => {
  const opportunitiesData = useSelector(
    (state) => state.classEntityReducer.opportunitiesData
  )

  const [selectedSubject, setSelectedSubject] = React.useState(null)
  const [subjectLevelMapping, setSubjectLevelMapping] = React.useState({})

  React.useEffect(() => {
    const fetchLevelData = async () => {
      const response = await fetch(
        `${apiEndpoints.subject}?uuid=${selectedSubject}`
      )
      if (response?.ok) {
        const levelOptionsJson = await response?.json()
        const levelOptionsArray = levelOptionsJson?.results?.[0]?.subject_levels
        const levelOptionsMapping =
          levelOptionsArray?.length > 0
            ? levelOptionsArray.map((item) => {
                return {
                  uuid: item.uuid,
                  name: item.name
                }
              })
            : []

        return levelOptionsMapping
      }
    }

    const fetchDefaultLevelData = async () => {
      const response = await fetch(
        `${apiEndpoints.configApp}?key=EXCEL_COURSE_LEVELS`
      )

      if (response?.ok) {
        const levelOptionsJson = await response?.json()
        const levelOptionsObject = levelOptionsJson?.results?.[0]?.value
        const levelOptionsMapping = !isEmpty(levelOptionsObject)
          ? Object.values(levelOptionsObject).map((item) => {
              return {
                uuid: item,
                name: item
              }
            })
          : []

        return levelOptionsMapping
      }
    }
    if (selectedSubject) {
      fetchLevelData().then((levelOptionsMapping) => {
        setSubjectLevelMapping(levelOptionsMapping)
      })
    } else {
      fetchDefaultLevelData().then((levelOptionsMapping) => {
        setSubjectLevelMapping(levelOptionsMapping)
      })
    }
  }, [selectedSubject])

  const years = _.range(
    2018,
    dayjs().add(NO_OF_YEARS_FOR_OPPORTUNITIES.after, 'year').year()
  ).reduce((o, key) => ({ ...o, [key]: key }), {})

  const months = _.range(0, 12).reduce(
    (o, key) => ({
      ...o,
      [key + 1]: dayjs().month(key).format('MMMM')
    }),
    {}
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 4),
      {
        name: 'year',
        label: 'Year',
        widget: 'select',
        widgetFilter: true,
        validate: validateNotEmpty,
        valueLabelMappings: years,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'year',
          customBodyRender: (value) => {
            return <div>{value}</div>
          }
        }
      },
      {
        name: 'month',
        label: 'Month',
        widget: 'select',
        widgetFilter: true,
        isValueKey: true,
        validate: validateNotEmpty,
        valueLabelMappings: months,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'month',
          customBodyRender: (value) => {
            return <div>{months[value]}</div>
          }
        }
      },
      ...columns.slice(4, 6),
      {
        name: 'levels',
        label: 'levels',
        formDataKey: 'levels',
        widget: 'autocomplete',
        valueLabelMappings: subjectLevelMapping,
        widgetFilter: true,
        multiple: true,
        optional: false,
        validate: validateNotEmpty,
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            return item.uuid
          }
          return data.map((item) => {
            return getItemStructure(item)
          })
        },
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return item
              ? {
                  uuid: item.uuid,
                  name: item?.name
                }
              : null
          }
          if (Array.isArray(data)) {
            return data?.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
            return data?.results?.map((item) => {
              return getItemStructure(item)
            })
          }

          return [getItemStructure(data)]
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'levels',
          keyValue: 'uuid'
        },
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          filterKey: 'level',
          customBodyRender: (value) =>
            value ? <span>{value?.map((el) => el.name)?.join(',')}</span> : null
        },
        conditionalRender: (data) => {
          let subjectData = get(data, 'subject')
          setSelectedSubject(subjectData)
          return !!subjectData
        }
      },
      {
        name: 'images',
        label: 'Upload image(s)',
        viewOnlyInModal: true,
        modalEdit: true,
        optional: true,
        validate: validateBulkImages,
        customMin: 4,
        customMax: 6,
        widget: AssetUploadWidget,
        newMedia: true,
        options: {
          display: false,
          disableFilter: true
        },
        conditionalRender: (data) => {
          let month = get(data, 'month')
          let year = get(data, 'year')
          if (
            parseInt(year) < dayjs().year() ||
            (parseInt(year) <= dayjs().year() &&
              parseInt(month) < dayjs().month() + 1)
          )
            return true
          return false
        }
      }
    ]
  }, [opportunitiesData, subjectLevelMapping])

  return (
    <BaseEntity
      entity={constants.OPPORTUNITIES}
      label={constants.OPPORTUNITIES_LABEL}
      columns={modifiedColumns}
      data={opportunitiesData}
      fetchData={fetchOpportunitiesData}
      updateEntityData={updateClassData}
      disableDelete={true}
    />
  )
}

export default Opportunities
