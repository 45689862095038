import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;

  width: max-content;
  position: fixed;
  bottom: 0;
  right: -10px;
  z-index: 1;

  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px -4px 8px rgb(0 0 0 / 20%);
  border-radius: 8px 8px 0px 0px;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 10px;

  .MuiButton-contained {
    border-radius: 2em;
    margin-top: 0;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0px 2.5px;
  }
`
