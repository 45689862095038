import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Button } from '@mui/material'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import { apiEndpoints } from 'api'
import { TextField, AutoComplete } from 'app/components/generic/FormElements'
import { validateNotEmpty } from 'app/validators'

import * as styles from './styles'

const SuggestSchedule = (props) => {
  const { formData, setFormData } = props
  const { errors, setFieldValue } = useFormikContext()
  const [isAdded, setIsAdded] = useState(false)

  const handleAdd = () => {
    let data = { ...formData }
    data.scheduleSuggestions.push({
      course: '',
      ideal_timings: '',
      other_timings: '',
      child_name: ''
    })
    setFormData(data)
    setIsAdded(true)
  }

  const handleRemove = (index) => {
    let data = { ...formData }
    data.scheduleSuggestions.splice(index, 1)
    if (data.scheduleSuggestions.length === 1) setIsAdded(false)
    setFormData(data)
  }
  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.parent_name) ? { error: true } : null)}
        required
      >
        <TextField
          name={'parent_name'}
          label={'Parent Name'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.parent_name) && (
          <FormHelperText>{errors.parent_name}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.parent_number) ? { error: true } : null)}
        required
      >
        <TextField
          name={'parent_number'}
          label={'Parent Number'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.parent_number) && (
          <FormHelperText>{errors.parent_number}</FormHelperText>
        )}
      </FormControl>
      {formData?.scheduleSuggestions.map((item, index) => {
        return (
          <>
            <FormControl
              variant="outlined"
              {...(!isEmpty(errors.course) ? { error: true } : null)}
              required
            >
              <AutoComplete
                name={`scheduleSuggestions[${index}].course`}
                placeholder="Course"
                minCharactersToSearch={0}
                getStructuredValues={(data) => {
                  const getItemStructure = (item) => {
                    return item
                      ? {
                          uuid: item.uuid,
                          name: item.name
                        }
                      : null
                  }
                  let items = getItemStructure(data)
                  if (Array.isArray(data)) {
                    items = data?.map(getItemStructure)
                  } else if ('results' in data) {
                    items = data?.results?.map(getItemStructure)
                  }

                  return items
                }}
                getFormDataValue={(data) => {
                  const getItemStructure = (item) => {
                    return item.uuid
                  }
                  return getItemStructure(data)
                }}
                getOptionLabelKey="name"
                getOptionSelectedKey="uuid"
                resultStructure={{
                  keyName: 'course',
                  keyValue: 'uuid'
                }}
                apiOptions={{
                  method: 'get',
                  url: apiEndpoints.course
                }}
                required
                setFieldValue={setFieldValue}
                formData={formData}
                validate={validateNotEmpty({ isRequired: true })}
              />
              {!isEmpty(errors.course) && (
                <FormHelperText>{errors.course}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              variant="outlined"
              {...(!isEmpty(errors.ideal_timings) ? { error: true } : null)}
              required
            >
              <TextField
                name={`scheduleSuggestions[${index}].ideal_timings`}
                label={'Ideal Timings'}
                placeholder="Example: Monday 3-5"
                variant="outlined"
                required
                validate={validateNotEmpty({ isRequired: true })}
              />
              {!isEmpty(errors.ideal_timings) && (
                <FormHelperText>{errors.ideal_timings}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              variant="outlined"
              {...(!isEmpty(errors.other_timings) ? { error: true } : null)}
              required
            >
              <TextField
                name={`scheduleSuggestions[${index}].other_timings`}
                label={'Other Timings'}
                variant="outlined"
                placeholder="Example: Wednesday 5-7"
                required
                validate={validateNotEmpty({ isRequired: true })}
              />
              {!isEmpty(errors.other_timings) && (
                <FormHelperText>{errors.other_timings}</FormHelperText>
              )}
            </FormControl>
            <FormControl
              variant="outlined"
              {...(!isEmpty(errors.child_name) ? { error: true } : null)}
              required
            >
              <TextField
                name={`scheduleSuggestions[${index}].child_name`}
                label={'Child Name'}
                variant="outlined"
                required
                validate={validateNotEmpty({ isRequired: true })}
              />
              {!isEmpty(errors.child_name) && (
                <FormHelperText>{errors.child_name}</FormHelperText>
              )}
            </FormControl>
          </>
        )
      })}
      {/* <>
        <styles.Container>
          <Button onClick={handleAdd}>Add Another</Button>
          {isAdded ? (
            <Button onClick={handleRemove} color="error">
              Remove
            </Button>
          ) : null}
        </styles.Container>
      </> */}
    </>
  )
}

export default SuggestSchedule
