import Dayjs from 'dayjs'
import React from 'react'

import { apiEndpoints } from 'api/common'
import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty } from 'app/validators'

const advancedFormat = require('dayjs/plugin/advancedFormat')
Dayjs.extend(advancedFormat)

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'event.name',
    label: 'Event Name',
    widget: 'autocomplete',
    formDataKey: 'event',
    minCharactersToSearch: 3,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      }

      return data.results.map((item) => {
        return getItemStructure(item)
      })
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'event',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.event}/event`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'event_name'
    }
  },
  {
    ...commonAttributes.student,
    name: 'student.user',
    label: 'Registrant User',
    options: {
      ...commonAttributes.student.options,
      filterKey: 'registrant_name'
    }
  },
  {
    name: 'student.user.phone_number',
    label: 'Registrant Mobile Number',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registrant_number'
    }
  },
  {
    name: 'student.user.email',
    label: 'Registrant email',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registrant_email_address'
    }
  },
  {
    name: 'school',
    label: 'School',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registrant_school'
    }
  },
  {
    name: 'standard',
    label: 'Standard',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registrant_standard'
    }
  },
  {
    name: 'date_of_event',
    label: 'Event Date',
    disabledInForm: true,
    options: {
      filterKey: 'event_date',
      customBodyRender: (value) => (
        <span>
          {value ? <>{Dayjs(`${value}`).format('Do MMM, YYYY')}</> : null}
        </span>
      )
    }
  },
  {
    name: 'created',
    label: 'RSVP Date',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'registration_date',
      customBodyRender: (value) => (
        <span>
          {value ? <>{Dayjs(`${value}`).format('Do MMM, YYYY')}</> : null}
        </span>
      )
    }
  },
  {
    name: 'num_registered_events',
    label: 'Number of registered events',
    disabledInForm: true,
    options: {
      disableFilter: true
    }
  },
  {
    name: 'class_enrolled',
    label: 'Classes enrolled',
    disabledInForm: true,
    options: {
      filterKey: 'class_enrollment',
      customBodyRender: (value) => {
        return <span>{value === true ? 'Yes' : 'No'}</span>
      }
    }
  },
  {
    name: 'club_enrolled',
    label: 'Clubs enrolled',
    disabledInForm: true,
    options: {
      filterKey: 'club_enrollment',
      customBodyRender: (value) => {
        return <span>{value === true ? 'Yes' : 'No'}</span>
      }
    }
  }
]
