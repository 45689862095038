import {
  createMuiTheme,
  ThemeProvider,
  createTheme
} from '@material-ui/core/styles'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'

import { createReportCard } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import FileUpload from 'app/components/generic/FileUpload'
import { Loading } from 'app/components/generic/LottieComponents'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { fetchReportCardData } from 'app/store/actions/communicationEntity'

import { columns } from './config'
import { LoadingView, LoadingViewText, ErrorModalTitle } from './styles'

const theme = createTheme({
  overrides: {
    Dialog: {
      backgroundColor: 'red'
    }
  }
})

const ReportCard = () => {
  const reportCardData = useSelector(
    (state) => state.communicationEntityReducer.reportCardData
  )

  const [modalOpen, setModalOpen] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const dispatch = useDispatch()

  const onModalClose = () => {
    setErrors('')
    setModalOpen(false)
  }

  const download = React.useCallback(
    (dataIndex) => {
      const { pdf_url } = reportCardData.data[dataIndex]
      window.open(pdf_url)
    },
    [reportCardData]
  )

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'pdf_url',
        isAction: true,
        disabledInForm: true,
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => download(dataIndex)}
              >
                preview
              </Button>
            )
          }
        }
      }
    ]
  }, [download])

  const sendReportGeneratedNotification = () => {
    Swal.fire({
      title: '',
      text: 'reports generated and shared!',
      confirmButtonText: 'ok',
      icon: 'success',
      padding: 30,
      borderRadius: 300
    })
  }

  const onUpload = async (e) => {
    try {
      setModalOpen(true)
      const res = await createReportCard(e.target.files[0])
      if (res.success) {
        setModalOpen(false)
        sendReportGeneratedNotification()
        dispatch(fetchReportCardData({}, constants.REPORT_CARD))
      }
    } catch (e) {
      setErrors(e.message)
      setModalOpen(true)
    }
  }

  const CustomToolbarElement = (
    <FileUpload
      label="upload & share"
      onUpload={onUpload}
      inputProps={{ accept: '.csv' }}
    />
  )

  const loadingView = (
    <LoadingView>
      <Loading height={200} width={200} />
      <LoadingViewText>
        {' '}
        please wait while we generate your reports
      </LoadingViewText>
    </LoadingView>
  )

  return (
    <>
      <BaseEntity
        entity={constants.REPORT_CARD}
        label={constants.REPORT_CARD_LABEL}
        fetchData={fetchReportCardData}
        data={reportCardData}
        columns={modifiedColumns}
        readOnly
        CustomToolbarElement={CustomToolbarElement}
      />
      <ThemeProvider theme={theme}>
        <ModalForm
          disableBackdropClick={!errors}
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={modalOpen}
          onModalClose={onModalClose}
          enableSave={false}
          modalTitle={
            errors && <ErrorModalTitle>Found Errors!</ErrorModalTitle>
          }
        >
          {errors || loadingView}
        </ModalForm>
      </ThemeProvider>
    </>
  )
}

export default ReportCard
