import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import React from 'react'
import TaxAmountForm from './DispatchForm/components/TaxAmountForm'
import IndependentDispatchForm from './DispatchForm/components/IndependentDispatchForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const MasterForm = (props) => {
  const classes = useStyles()

  const { activeStep, data, action, formRef, formData } = props

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {({ }) => {
          return (
            <Form>
              <FormControl className={classes.formControl} fullWidth>
                {activeStep === 0 ? (
                  <TaxAmountForm action={action} data={data} formData={formData} />
                ) : activeStep === 1 ? (
                  <IndependentDispatchForm action={action} data={data} formData={formData} />
                ) : null}
              </FormControl>
            </Form>
          )
        }}
      </Formik>
    )
  }, [activeStep, classes, formData])

  return (
    <>
      {formElement}
    </>
  )
}

export default MasterForm
