import React from 'react'
import * as styles from './styles';
import { progressReport } from '../../../constants';

const MessageReport = ({ text, contentCta }) => {
  return (
    <>
      <styles.ProgressReportContainer>
        <styles.Icon src={progressReport} alt='icon' />
        <styles.VerticalLine />
        <styles.Text>{text}</styles.Text>
      </styles.ProgressReportContainer>

      {contentCta?.length > 0 && (
        <styles.CtaContainer> {contentCta[0]?.label} → </styles.CtaContainer >
      )}
    </>
  );
};

export default MessageReport;