import { FormControl } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import ChatIcon from '@material-ui/icons/Chat'
import { Button, Menu, MenuItem } from '@mui/material'
import _, { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  apiEndpoints,
  fetchTransactionData,
  fetchEnrolment,
  fetchUser,
  membershipActions,
  fetchProgramEnrolments,
  updateTicket
} from 'api'
import { Error } from 'app/components/Error'
import { AutoComplete } from 'app/components/generic/FormFields'
import History from 'app/components/generic/history/history'
import { Loading } from 'app/components/generic/LottieComponents'
import Slider from 'app/components/generic/Slider/slider'
import ModalForm from 'app/components/ModalForm'
import { navigationPaths, nonMemberActions, parentActions } from 'app/config'
import constants, {
  FORCE_UPDATE_ERROR_MESSAGE,
  USER_EXISTS_ERROR_MESSAGE
} from 'app/constants'
import { confirm, eventAlert, eventEmitter, makeRequestUrl } from 'app/helpers'
import { batchTypesMap } from 'app/pages/Class/Inventory/constants'
import { parentDataTransformer } from 'app/transformer'

import MasterModal from './components/MasterModal'
import { MembershipBlock } from './components/MembershipBlock'
import { SummaryBlock } from './components/SummaryBlock'
import { blocks, dataColumns } from './config'
import {
  getEnrolmentData,
  getIcon,
  getRatingSessions,
  getWalletData,
  getScheduleSuggestion,
  getEnrolmentExtensions,
  fetchAttendanceData,
  fetchLifeTimeAttendanceData,
  getInteractionsData,
  getTicketsData,
  getJourneyData
} from './helpers'
import * as styles from './styles'

import { ParentDetails } from '../../../components/shared/ParentDetails'
import MasterForm from '../../User/Parent/MasterForm'


const ParentDashboard = () => {
  const [loading, setLoading] = React.useState(true)
  const [selectedParentDetails, setSelectedParentDetails] = React.useState({})
  const [enrolmentData, setEnrolmentData] = useState({})
  const [summaryData, setSummaryData] = useState(blocks)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isFormOpen, setIsFormOpen] = React.useState(false)
  const [modalData, setModalData] = React.useState({})
  const [action, setAction] = React.useState('')
  const [refresh, setRefresh] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [profileDetailsModalOpen, setProfileDetailsModalOpen] =
    React.useState(false)
  const [parentsDetails, setParentDetails] = React.useState([])
  const [extraFormData, setExtraFormData] = React.useState({})
  let globalSummaryData = summaryData

  const openModal = React.useCallback((e, action) => {
    e.preventDefault()
    setIsModalOpen(true)
    setAction(action)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openForm = React.useCallback((e, action, data) => {
    if (e) {
      e.preventDefault()
    }
    setIsFormOpen(true)
    setAction(action)
    setExtraFormData(data)
  }, [])

  const closeForm = () => {
    setIsFormOpen(false)
  }

  const onActionButtonClick = async (e, action, data) => {
    handleClose()
    openForm(e, action, data)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const slackNotificationTrigger = (action, data) => {
    onActionButtonClick(null, action, data)
  }

  const openModalForm = (data) => {
    const { event, action, actionData } = data
    openForm(event, action, actionData)
  }

  const onSaveClick = async (newData, action) => {
    try {
      const formData = { ...newData, ...extraFormData }
      let res
      if (action === 'edit_ticket_status') {
        res = await updateTicket({
          user: formData.uuid,
          status: newData.status,
          resolution_summary: newData.resolution_summary,
          uuid: formData.ticketId,
          follow_up_date: newData?.follow_up_date
        })
      } else res = await membershipActions(formData, action)
      if (
        res?.success ||
        res?.subscription?.uuid ||
        res?.uuid ||
        res?.user_id ||
        res?.title
      ) {
        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Done', constants.SUCCESS)
        closeForm()
        let search = selectedParentDetails.uuid
        if (res?.subscription?.uuid) {
          search = res?.subscription?.user?.phone
        } else if (res?.data) {
          search = res?.data?.user
        } else if (res?.success || res?.uuid || res?.title) {
          search = selectedParentDetails?.uuid
        }
        const parent = await fetchParentDetails(search)
        if (!isEmpty(parent)) handleParentSelection(null, parent[0])
        setTimeout(() => {
          setRefresh((prevstate) => !prevstate)
        }, 200)
      }
    } catch (error) {
      const errorType = error.message.split('-')[0]
      const errorMessage = error.message.split('-')[1]
      console.log(error)
      if (errorType === 'error_user_exists') {
        setExtraFormData(newData)
        confirm(
          async () =>
            await onSaveClick(
              { ...newData, force_update_number: true },
              'change_phone_number'
            ),
          USER_EXISTS_ERROR_MESSAGE,
          () => {
            return
          }
        )
      } else if (errorType === 'error_force_delete_user') {
        closeForm()
        confirm(
          () =>
            slackNotificationTrigger('parent_number_deletion_request', {
              errorMessage,
              newPhoneNumber: newData?.new_phone_number
            }),
          FORCE_UPDATE_ERROR_MESSAGE,
          () => {
            return
          }
        )
      } else {
        eventAlert(error.message, constants.ERROR)
      }
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
    }
  }

  const setData = (type, data) => {
    const icon = getIcon(type)
    const tempSummaryData = [...globalSummaryData]
    const currentIndex = tempSummaryData.findIndex((el) => el.type === type)

    if (currentIndex !== -1) {
      if (tempSummaryData[currentIndex].type === type) {
        tempSummaryData[currentIndex].data = data
        tempSummaryData[currentIndex].icon = icon
        globalSummaryData = tempSummaryData
        return setSummaryData(tempSummaryData)
      }
    } else {
      let newData = {
        type: type,
        data: data,
        icon: icon
      }
      //Adding the card to the second last index to keep the interactions block at the end
      tempSummaryData.splice(tempSummaryData.length - 1, 0, newData)
      globalSummaryData = tempSummaryData

      return setSummaryData(tempSummaryData)
    }
  }

  const profileButtonOnclickHandler = () => {
    setProfileDetailsModalOpen(true)
  }

  const closeProfileDetailsModal = () => {
    setProfileDetailsModalOpen(false)
  }

  useEffect(() => {
    ; (async () => {
      if (selectedParentDetails?.phone_number) {
        const enrolmentUrl = makeRequestUrl(apiEndpoints.fetchEnrolment, {
          no_page: 'True',
          exclude_type: 'make_up_session',
          batch_type_ne: `${batchTypesMap.exploration},${batchTypesMap.exploration_camp}`,
          parent_phone_number: selectedParentDetails?.phone_number,
          limit: 100
        })

        const [enrolments, programEnrolments] = await Promise.all([
          fetchEnrolment({ url: enrolmentUrl }),
          fetchProgramEnrolments({
            url: null,
            queries: { parent: selectedParentDetails.uuid }
          })
        ])

        const explorationProgramEnrolments = programEnrolments.results.filter(
          (el) => el?.program?.type === 'exploration'
        )

        const finalEnrolmentsData = [
          ...(enrolments?.results || []),
          ...explorationProgramEnrolments
        ].sort((a, b) => new Date(b.start_date) - new Date(a.start_date))

        setEnrolmentData({ data: finalEnrolmentsData })
      }
    })()
  }, [selectedParentDetails?.phone_number])

  useEffect(() => {
    ; (async () => {
      if (selectedParentDetails.uuid && enrolmentData?.data?.length >= 0) {
        if (enrolmentData?.data?.length > 0) {
          const data = getEnrolmentData(enrolmentData, openModal, setModalData)
          setData('enrolments', data)
          // setData('engagement', data)
          const attendanceData = await fetchAttendanceData(
            selectedParentDetails.uuid
          )
          setData('monthly attendance', { ...attendanceData, ...data })
          const ratingResult = await getRatingSessions(
            selectedParentDetails.uuid,
            null,
            openModal,
            setModalData,
            data
          )

          setData('classroom experience', ratingResult)
          const lifetimeAttendance = await fetchLifeTimeAttendanceData(
            selectedParentDetails.uuid
          )
          setData('lifetime health', lifetimeAttendance)
          // const extensionData = await getEnrolmentExtensions(
          //   selectedParentDetails.uuid,
          //   data
          // )
          // setData('enrolments extension', extensionData)
        } else {
          setData('enrolments', [])
          setData('monthly attendance', [])
          // setData('engagement', [])
          // setData('enrolments extension', {})
        }

        const res = await fetchTransactionData({
          parent: selectedParentDetails.uuid
        })
        const dataResult = getWalletData(res, openModal)
        setData('wallet', dataResult)
      } else {
        setData('wallet', [])
      }
    })()
  }, [selectedParentDetails.uuid, enrolmentData])

  useEffect(() => {
    ; (async () => {
      if (selectedParentDetails.uuid) {
        const journeyData = await getJourneyData(selectedParentDetails?.uuid)
        setData('journeys', journeyData)

        const scheduleSuggestions = await getScheduleSuggestion(
          selectedParentDetails.uuid
        )
        if (scheduleSuggestions.length)
          setData('Schedule Suggestion', scheduleSuggestions)

        const ticketsData = await getTicketsData(
          selectedParentDetails.phone_number,
          openModalForm
        )
        setData('tickets log', ticketsData)

        const interactionData = await getInteractionsData(
          selectedParentDetails.phone_number
        )
        setData('interactions', interactionData)
      }
    })()
  }, [selectedParentDetails.uuid, refresh])

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const blockType = params.get('type')
    const key = params.get('key')
    const value = params.get('value')
    if (params.get('openSlider') === 'true' && selectedParentDetails.uuid) {
      const emitData = {
        blockOpenType: blockType,
        key,
        value
      }
      eventEmitter.emit(constants.SLIDER_OPENER, emitData)
    }

    {
      ; (async () => {
        const parentData = await parentDataTransformer(selectedParentDetails)
        setParentDetails(parentData)
      })()
    }
  }, [selectedParentDetails.uuid])

  const fetchParentDetails = async (searchedText) => {
    setError(null)
    searchedText = searchedText.trim()

    try {
      var numberReg = /^\d+$/
      var aplhaReg = /^[a-zA-Z ]*$/
      var uuidReg =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

      if (searchedText?.length >= 0) {
        let queries = {}

        if (numberReg.test(searchedText))
          queries = { phone_number: searchedText }
        else if (aplhaReg.test(searchedText)) queries = { name: searchedText }
        else if (uuidReg.test(searchedText)) queries = { uuid: searchedText }

        const response = await fetchUser({ queries }, constants.PARENT)

        if (response.results.length > 0) {
          return response.results.map((item) => {
            const {
              uuid,
              first_name,
              last_name,
              phone_number,
              active_subscription,
              credits,
              member_status,
              address_detail,
              state
            } = item?.user

            const {
              centre_preference,
              students,
              user,
              additional_information,
              customer_start_date,
              is_policy_acknowledged_alfred,
              crm = {}
            } = item

            user.full_name = `${first_name} ${last_name}`

            setLoading(false)

            return {
              uuid,
              value: uuid,
              name: `${phone_number} (${first_name} ${last_name})`,
              first_name,
              last_name,
              phone_number,
              students: students,
              centre: centre_preference,
              active_subscription: active_subscription,
              user,
              credits: credits,
              member_status,
              address_detail,
              state,
              additional_information,
              customer_start_date,
              is_policy_acknowledged_alfred,
              crm_flags: crm?.flags ?? [],
              crm_tags: crm?.tags ?? []
            }
          })
        } else if (response.results.length > 1 && searchedText.length === 10) {
          throw new Error('Multiple user found with same number')
        } else if (response.results.length === 0) {
          setError('Parent Not Found')
          return []
        } else {
          return []
        }
      }
    } catch (error) {
      setLoading(false)
      console.log('Error in fetching user :- ', error)
      throw error
    }
  }

  const handleParentSelection = (name, value) => {
    setData('children', value?.students)
    setData('journeys', null)
    setSelectedParentDetails(value || {})
    localStorage.setItem('parent', JSON.stringify(value || {}))
  }
  const formElement = React.useMemo(() => {
    const prevParent = JSON.parse(localStorage.getItem('parent'))
    const searchText = new URLSearchParams(window.location.search)?.get(
      'parent'
    )
    return (
      <FormControl variant="outlined" fullWidth required={true}>
        <AutoComplete
          async={true}
          name={'user'}
          label="Search Parent with name or number"
          multiple={false}
          fetchValues={fetchParentDetails}
          onChange={handleParentSelection}
          initialSelectIndex={0}
          selectOnFocus={true}
          searchText={
            searchText ? searchText : prevParent?.uuid ? prevParent?.uuid : ''
          }
          size="small"
          fetchOnMount
        />
      </FormControl>
    )
  }, [refresh])

  const buttonElement = React.useMemo(() => {
    const status =
      selectedParentDetails?.active_subscription?.current_cycle?.status
    const dashBoardActions = status ? parentActions : nonMemberActions

    return (
      <styles.ButtonWrapper>
        <Button
          id={'Button' + selectedParentDetails?.active_subscription?.uuid}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          onClick={profileButtonOnclickHandler}
          className="button-dashboard">
          view profile details
        </Button>

        <Button
          id={'Button' + selectedParentDetails?.active_subscription?.uuid}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          className="button-dashboard">
          Actions
        </Button>

        {/* <Button
          variant="contained"
          startIcon={<ChatIcon />}
          className="button-dashboard"
          component={Link}
          to={navigationPaths.centreParentChat}>
          chat with parent
        </Button> */}

        <Menu
          id={'Menu' + selectedParentDetails?.active_subscription?.uuid}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          {Object.keys(dashBoardActions)
            .filter((el) =>
              el === 'extend' && status === 'due' ? false : true
            )
            .map((action, index) => {
              return (
                <MenuItem
                  onClick={(e) => {
                    onActionButtonClick(e, action)
                  }}
                  disableRipple
                  key={index}>
                  {dashBoardActions[action]}
                </MenuItem>
              )
            })}
        </Menu>
      </styles.ButtonWrapper>
    )
  }, [selectedParentDetails, open])

  return (
    <styles.Container>
      <styles.Header>
        <styles.Dropdown>{formElement}</styles.Dropdown>
        {buttonElement}
      </styles.Header>
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Loading />
      ) : (
        <styles.Widgets>
          <MembershipBlock selectedParentDetails={selectedParentDetails} />

          {summaryData?.map((data, index) => {
            if (data.slider) {
              return (
                <Slider
                  width="50%"
                  key={'Slide' + index}
                  fullWidth={data.fullWidth || false}
                  placeholder={
                    <SummaryBlock
                      keyIndex={'Summary' + index}
                      selectedParentDetails={selectedParentDetails}
                      summaryData={data}
                      enrolmentData={enrolmentData}
                    />
                  }
                  blockType={data.type}
                  childComponent={
                    <History
                      openModalForm={openModalForm}
                      selectedParentDetails={selectedParentDetails}
                      type={dataColumns[data.type]?.type}
                      heading={dataColumns[data.type]?.heading}
                      userInfo={selectedParentDetails?.user}
                      data={data.data}
                      tableHeadings={dataColumns[data.type]?.tableHeadings}
                      accessors={dataColumns[data.type]?.accessors}
                      readOnly={true}
                      editableDetails={false}
                      addDetails={false}
                      headerData={data.headerData}
                    />
                  }
                />
              )
            } else if (data?.link) {
              return (
                <Link
                  to={makeRequestUrl(`${data?.link}`, data?.data?.queryParams)}
                  onClick={(e) => {
                    if (!data?.data?.isClickable) {
                      // Condition when the link should be disabled
                      e.preventDefault()
                    }
                  }}
                  style={{
                    cursor: data?.data?.isClickable ? 'pointer' : 'default',
                    textDecoration: 'none',
                    color: 'inherit',
                    fontWeight: 'normal'
                  }}>
                  <SummaryBlock
                    key={'Slide' + index}
                    keyIndex={'Summary' + index}
                    selectedParentDetails={selectedParentDetails}
                    summaryData={data}
                  />
                </Link>
              )
            } else {
              return (
                <SummaryBlock
                  key={'Slide' + index}
                  keyIndex={'Summary' + index}
                  fullWidth={data.fullWidth || false}
                  selectedParentDetails={selectedParentDetails}
                  summaryData={data}
                  enrolmentData={enrolmentData}
                />
              )
            }
          })}
        </styles.Widgets>
      )}
      {/* MasterForm => all parent action forms */}
      <MasterForm
        isModalOpen={isFormOpen}
        onSaveClick={onSaveClick}
        closeModal={closeForm}
        action={action}
        data={selectedParentDetails}
        extraFormData={extraFormData}
      />

      {/* MasterModal => all modal grid data */}
      <MasterModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        action={action}
        data={modalData || {}}
        parentData={selectedParentDetails}
        enrolmentData={enrolmentData}
      />

      {/* ModalForm => parent details modal*/}
      <ModalForm
        isModalOpen={profileDetailsModalOpen}
        onModalClose={closeProfileDetailsModal}
        enableSave={false}>
        <ParentDetails parentDetails={parentsDetails} />
      </ModalForm>
    </styles.Container>
  )
}

export default ParentDashboard
