import { InputLabel, MenuItem, Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'

import { fetchProgramCapacity, getProgramCost } from 'api'
import { TextField } from 'app/components/generic/FormElements'
import { getWidgetTypeName } from 'app/config'
import constants from 'app/constants'
import { createScheduleData, eventAlert } from 'app/helpers'
import { validateNotEmpty } from 'app/validators'
import { sentryLogger } from 'sentry'

import { ProgramCapacityIndicator } from './styles'

const ExplorationForm = (props) => {
  const { formData } = props
  const students = formData?.students
  const { errors, values, setFieldValue } = useFormikContext()
  const [programEndDateList, setProgramEndDateList] = useState([])
  const [programDetails, setProgramDetails] = useState(null)

  React.useEffect(() => {
    if (values?.program && values?.start_date) {
      const schedule_events = createScheduleData(values?.start_date)
      setProgramEndDateList(schedule_events)
    }
  }, [values?.child_name, values?.start_date])

  const studentSelected = (e) => {
    setFieldValue('student', e.target?.value)
  }

  const endDateSelected = (e) => {
    setFieldValue('program_end_date', e.target?.value)
  }

  React.useEffect(() => {
    ;(async () => {
      if (values?.program && values?.start_date && values?.program_end_date) {
        try {
          const queries = {
            start_date: values?.start_date,
            end_date: values?.program_end_date,
            student_uuid: values?.student?.user.uuid,
            program_uuid: values?.program?.uuid
          }
          const cost = await getProgramCost({ queries })
          setFieldValue('program_fee', cost?.cost)
        } catch (err) {
          sentryLogger(err)
          eventAlert(err.message, constants.ERROR)
        }
      }
    })()
  }, [values?.start_date, values?.program_end_date])

  React.useEffect(() => {
    ;(async () => {
      if (values?.student) {
        const details = await fetchProgramCapacity({
          centre: formData?.centre?.uuid,
          standards: values?.student?.standard.uuid,
          program: values?.program?.uuid
        })
        setProgramDetails({ ...details })
      }
    })()
  }, [values?.student])

  React.useEffect(() => {
    if (
      programDetails &&
      programDetails?.capacity === programDetails?.active_students
    ) {
      eventAlert('Slots for this centre are full!', constants.ERROR)
    }
  }, [programDetails])

  return (
    <>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.student) ? { error: true } : null)}
        required
      >
        <InputLabel>Child Name</InputLabel>
        <Select
          name={'student'}
          label="Child Name"
          validate={validateNotEmpty({ isRequired: true })}
          setFieldValue={setFieldValue}
          onChange={studentSelected}
        >
          {students.map((item) => (
            <MenuItem key={item.user.uuid} value={item}>
              {`${item.user.full_name} (${`${
                item.standard?.name ? item.standard.name : ''
              }`})`}
            </MenuItem>
          ))}
        </Select>
        {!isEmpty(errors.student) && (
          <FormHelperText>{errors.student}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        required={true}
        {...(errors.start_date ? { error: true } : null)}
      >
        <TextField
          name={'start_date'}
          label="Start Date"
          type={getWidgetTypeName('datepicker')}
          variant="outlined"
          required={true}
          {...{
            validate: validateNotEmpty({
              isRequired: true
            })
          }}
          {...{
            InputLabelProps: {
              shrink: true
            }
          }}
          {...{
            inputProps: {
              ...{
                min: dayjs().format('YYYY-MM-DD'),
                max: dayjs().add(2, 'month').format('YYYY-MM-DD')
              }
            }
          }}
          {...(errors.start_date ? { error: true } : null)}
        />
        {errors.start_date && (
          <FormHelperText>{errors.start_date}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.program_end_date) ? { error: true } : null)}
        required
      >
        <InputLabel>Program End Date</InputLabel>
        <Select
          name={'program_end_date'}
          label="Program End Date"
          setFieldValue={setFieldValue}
          onChange={endDateSelected}
          required={true}
        >
          {programEndDateList?.map((item) => (
            <MenuItem key={item.value} value={item.label}>
              {dayjs(item.label).format('DD/MM/YYYY')}
            </MenuItem>
          ))}
        </Select>
        {!isEmpty(errors.program_end_date) && (
          <FormHelperText>{errors.program_end_date}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.program_fee) ? { error: true } : null)}
        required
      >
        <TextField
          name={'program_fee'}
          label={'Coins Required'}
          variant="outlined"
          defaultValue={formData?.program_fee}
          disabled
        />
        {!isEmpty(errors.program_fee) && (
          <FormHelperText>{errors.program_fee}</FormHelperText>
        )}
      </FormControl>
      {programDetails && (
        <ProgramCapacityIndicator
          isFull={programDetails?.capacity === programDetails?.active_students}
        >
          Booked Slots : {programDetails?.active_students} /{' '}
          {programDetails?.capacity}
        </ProgramCapacityIndicator>
      )}
    </>
  )
}

export default ExplorationForm
