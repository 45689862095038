import { fetchDemoSchedule } from 'api'
export const getScheduleSlots = async (el, studentData, centre) => {
  const queries = {
    type: 'exploration_camp',
    date_gte: el?.end_date,
    date_lte: el?.end_date,
    centre_uuid: centre,
    standard_uuid: studentData?.standard?.uuid
  }

  const response = await fetchDemoSchedule({
    url: null,
    queries
  })

  const cleanedData = response?.results?.map((item) => {
    const res = {
      teacher_id: item?.schedule_activities[0]?.teacher?.user_id,
      teacher_name: item?.schedule_activities[0]?.teacher?.user?.full_name,
      start_time: item?.schedule_activities[0]?.start_time,
      end_time: item?.schedule_activities[0]?.end_time,
      grade: item?.batch?.course?.standards.map((item) => item?.name).join(','),
      vacancy: item?.batch?.vacancy,
      centreId: item?.batch?.centre?.uuid,
      batchId: item?.batch?.uuid,
      date: item?.date
    }
    return res
  })

  return cleanedData
}

export const transformRechargeSlabs = (data) => {
  const mappedSlabs = data.map((item) => ({
    label: item.coins,
    value: item.coins,
    key: item.coins,
    list_price: item.cost_price,
    discount: item.discount_percentage
  }))

  mappedSlabs.sort((a, b) => a.value - b.value)

  mappedSlabs.push({
    label: 'Custom',
    key: 'Custom',
    value: 0,
    list_price: 0,
    discount: 0
  })

  return mappedSlabs
}

export const discountPercentCalculator = (coins_value, slabs) => {
  const sortedFilteredSlabs = slabs
    .filter((slab) => slab.key !== 'Custom')
    .sort((a, b) => a.value - b.value)

  if (coins_value < sortedFilteredSlabs[0]?.value) return 0

  for (let i = 0; i < sortedFilteredSlabs?.length; i++) {
    const currentSlab = sortedFilteredSlabs?.[i]
    const nextSlab = sortedFilteredSlabs?.[i + 1]

    if (
      nextSlab &&
      coins_value >= currentSlab?.value &&
      coins_value < nextSlab?.value
    ) {
      return currentSlab?.discount
    }

    if (!nextSlab && coins_value >= currentSlab?.value) {
      return currentSlab?.discount
    }
  }
  return 0 //default case, should never be encountered
}
