import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Select, TextField } from 'app/components/generic/FormElements'
import ModalForm from 'app/components/ModalForm'
import { paymentModes } from 'app/config'
import constants from 'app/constants'
import { confirm, eventEmitter } from 'app/helpers'
import { validateFee, validateNotEmpty } from 'app/validators'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const DueRenewalActionForm = (props) => {
  const [formData] = React.useState({
    payment_mode: '',
    value: '',
    notes: ''
  })

  const classes = useStyles()
  const formRef = React.useRef(null)

  const { isModalOpen, closeModal, onSaveClick, action } = props

  const handleOnSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current
      try {
        const errors = await validateForm(values)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        } else {
          // form is valid
          try {
            const onSave = async () => {
              await onSaveClick(values, action)
            }
            const onError = () => {
              eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
            }
            if (action === 'cancel') {
              const isConfirm = confirm(
                onSave,
                'If you cancel, a month will automatically be added to the current cycle of the user but no coins will be added.',
                onError
              )
            } else onSave()
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [onSaveClick, action])

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {({ errors }) => (
          <Form>
            <FormControl className={classes.formControl} fullWidth>
              {action === 'collect' ? (
                <FormControl
                  variant="outlined"
                  {...(!isEmpty(errors.payment_mode) ? { error: true } : null)}
                  required
                >
                  <InputLabel>{'Payment Mode'}</InputLabel>
                  <Select
                    name={'payment_mode'}
                    defaultValue={formData.payment_mode || ''}
                    label={'Payment Mode'}
                    validate={validateNotEmpty({ isRequired: true })}
                  >
                    {Object.keys(paymentModes).map((key, index) => (
                      <MenuItem key={index} value={key}>
                        {paymentModes[key]}
                      </MenuItem>
                    ))}
                  </Select>
                  {!isEmpty(errors.payment_mode) && (
                    <FormHelperText>{errors.payment_mode}</FormHelperText>
                  )}
                </FormControl>
              ) : action === 'edit' ? (
                <TextField
                  name={'value'}
                  label={'Invoice Amount'}
                  variant="outlined"
                  required
                  validate={validateFee({ isRequired: true })}
                />
              ) : null}
              <TextField
                name={'notes'}
                label={action === 'cancel' ? 'Reason' : 'Notes'}
                variant="outlined"
                required
                validate={validateNotEmpty({ isRequired: true })}
              />
            </FormControl>
          </Form>
        )}
      </Formik>
    )
  }, [action, classes, formData])

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}
    >
      {formElement}
    </ModalForm>
  )
}

DueRenewalActionForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default DueRenewalActionForm
