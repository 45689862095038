import { isEmpty } from 'lodash'
import React from 'react'

import { TitleDescription } from 'app/components/TitleDescription'
import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock'

import * as styles from './styles'

export const Details = (props) => {
  const { heading, icon, detailsData, isDetailsInRows } = props
  return (
    <styles.Container>
      <styles.InnerContainer>
        <styles.Wrapper>
          <styles.Icon src={icon} />
          <styles.Heading>{heading}</styles.Heading>
        </styles.Wrapper>
        {!isEmpty(detailsData) ? (
          isDetailsInRows ? (
            <styles.DescriptionWrapper>
              {detailsData?.map((item) => {
                return (
                  <TitleDescription
                    heading={item.display_text}
                    key={item.key}
                    description={item.response}
                    showHeading={item?.showHeading}
                    parentUrl={item?.parentUrl}
                    descriptionTextTransform={item?.descriptionTextTransform}
                  />
                )
              })}
            </styles.DescriptionWrapper>
          ) : (
            <styles.CardWrapper>
              {detailsData?.map((item) => {
                return (
                  <TitleDescription
                    heading={item.display_text}
                    key={item.key}
                    description={item.response}
                    showHeading={item?.showHeading}
                  />
                )
              })}
            </styles.CardWrapper>
          )
        ) : (
          <NotFoundBlock message="Oh no! No Details found." />
        )}
      </styles.InnerContainer>
    </styles.Container>
  )
}
