import { isEmpty } from 'lodash'

import { apiEndpoints, validateDeleteRequest } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const commonAttributes = (data) => ({
  max_seat: data.max_seat,
  description: data.description,
  leader_description: data.leader_description,
  visible_on_website: data.visible_on_website
})

const tribeRequestStructure = (data) => ({
  ...commonAttributes(data),
  tribe_name: data.name,
  tribe_leader_first_name: data?.leader?.user?.first_name,
  tribe_leader_last_name: data?.leader?.user?.last_name,
  tribe_leader_phone_number: data?.leader?.user?.phone_number,
  club_uuid: data.club,
  visible_on_website: data.visible_on_website
})

const tribeUpdateRequestStructure = (data) => ({
  ...commonAttributes(data),
  name: data.name,
  club: data.club,
  mode: data.mode
})

const fetchTribe = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.tribe}/alfred`, queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    throw error
  }
}

const fetchTribeViaUniqueCode = async (uniqueCode) => {
  let queries = {
    unique_code: uniqueCode
  }
  const requestUrl = makeRequestUrl(
    `${apiEndpoints.website}/tribes/code`,
    queries
  )
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    throw error
  }
}

const fetchTribesForClub = async (clubId) => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.tribeList}/${clubId}`)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    throw error
  }
}

const createTribe = async (data) => {
  try {
    const response = await post(apiEndpoints.tribe, tribeRequestStructure(data))
    return response.data
  } catch (error) {
    throw error
  }
}

const showTribeRecordings = async (data) => {
  try {
    const response = await get(
      makeRequestUrl(`${apiEndpoints.oh_schedules}/dashboard`, {
        tribe_uuid: data.uuid
      })
    )
    return response.data
  } catch (error) {
    console.log('error in show recording :- ', error.message)
    throw error
  }
}

const updateTribeIsRecordable = async (data, is_recordable) => {
  try {
    const response = await patch(`${apiEndpoints.tribe}/${data.uuid}/`, {
      is_recordable
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const updateTribeVisibleOnWebsite = async (data, visible_on_website) => {
  try {
    const response = await patch(`${apiEndpoints.tribe}/${data.uuid}/`, {
      visible_on_website
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const updateTribe = async (data) => {
  try {
    const response = await patch(
      `${apiEndpoints.tribe}/${data.uuid}/`,
      tribeUpdateRequestStructure(data)
    )
    return response.data
  } catch (error) {
    throw error
  }
}

const deleteTribe = async ({ ids }) => {
  validateDeleteRequest(ids)
  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.tribe}/${id}`)
      })
    )
    return ids
  } catch (error) {
    throw error
  }
}

export {
  fetchTribe,
  fetchTribeViaUniqueCode,
  fetchTribesForClub,
  deleteTribe,
  createTribe,
  updateTribe,
  showTribeRecordings,
  updateTribeIsRecordable,
  updateTribeVisibleOnWebsite
}
