import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCentre } from 'api'
import EmptyDataIcon from 'app/components/generic/EmptyDataIcon'
import { AutoComplete } from 'app/components/generic/FormFields'
import Loading from 'app/components/generic/Loading'
import SearchComponent from 'app/components/generic/SearchComponent'
import constants from 'app/constants'
import {
  fetchTeacherListData,
  fetchStudentListData
} from 'app/store/actions/communicationEntity'
import { selectTeacherListData } from 'app/store/selectors/communicationEntity/chat'

import * as styles from './styles'

import TeacherListCard from '../TeacherListCard'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 135
  }
}))

const TeacherList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const [loading, setLoading] = React.useState(false)
  const [selectedCentres, setSelectedCentres] = React.useState('')
  const [selectedTeacher, setSelectedTeacher] = React.useState(
    localStorage.getItem('SELECTED_TEACHER')
  )
  const [initialRender, setInitialRender] = React.useState(false)

  // const updateLoadingValue = (value) => setLoading(value)

  React.useEffect(() => {
    setInitialRender(true)
  }, [])

  const fetchCentreValues = async (searchedText) => {
    try {
      const queries = {
        limit: 1000
      }

      if (!isEmpty(searchedText)) {
        queries.name = searchedText
      }

      const response = await fetchCentre({ queries })

      return response.results.map((item) => ({
        value: item.uuid,
        name: item.name,
        city: item.city
      }))
    } catch (error) {
      console.log('Error in fetching centres :- ', error)
      throw error
    }
  }

  const handleAutoCompleteOnChange = (name, value) => {
    localStorage.setItem('SELECTED_CENTRE', JSON.stringify(value))
    setSelectedCentres(value)
    dispatch(
      fetchTeacherListData(
        {
          queries: {
            centre_uuid: value?.value,
            page_size: 1000
          }
        },
        false,
        false,
        constants.TEACHER_LIST
      )
    )
  }

  const handleTeacherCardClick = (event, item) => {
    setInitialRender(false)
    localStorage.setItem('SELECTED_TEACHER', item.uuid)
    setSelectedTeacher(item.uuid)
    dispatch(
      fetchStudentListData(
        {
          queries: {
            teacher_uuid: item.uuid,
            centre_uuid: selectedCentres?.value,
            page_size: 1000
          }
        },
        false,
        false,
        constants.STUDENT_LIST
      )
    )
  }

  const teacherListData = useSelector(selectTeacherListData)
  const data = teacherListData.data
  const loading = teacherListData.isLoading
  const sortedData = data?.sort(
    (a, b) => b?.unread_messages_count - a?.unread_messages_count
  )

  return (
    <styles.TeacherListContainer>
      <styles.HeaderContainer>
        <styles.InnerHeaderContainer>
          <styles.HeaderDescription>
            <div style={styles.ChatTitle}>chat</div>
            <div>teacher's list</div>
          </styles.HeaderDescription>
          <FormControl
            key={'centre'}
            variant="outlined"
            className={classes.formControl}
          >
            <AutoComplete
              async={false}
              name={'centre'}
              label={'centre'}
              fetchValues={fetchCentreValues}
              onChange={handleAutoCompleteOnChange}
              initialSelectIndex={0}
              minCharactersToSearch={0}
              searchWithEmptyValue={true}
              defaultValue={
                JSON.parse(localStorage.getItem('SELECTED_CENTRE')) ?? null
              }
            />
          </FormControl>
        </styles.InnerHeaderContainer>
        <SearchComponent
          label={'search by name, number, category'}
          fetchDataAction={fetchTeacherListData}
          initialQueries={{
            centre_uuid: JSON.parse(localStorage.getItem('SELECTED_CENTRE'))
              ?.value,
            page_size: 1000
          }}
          constants={constants.TEACHER_LIST}
          // updateLoadingValue={updateLoadingValue}
        />
      </styles.HeaderContainer>
      <styles.BodyContainer>
        {loading ? (
          <Loading />
        ) : sortedData.length > 0 ? (
          sortedData.map((item, index) => (
            <TeacherListCard
              uuid={item.uuid}
              thumbnailUrl={item.profile_picture}
              name={item.name.toLowerCase()}
              centres={item.centres}
              categories={item.categories}
              unreadCount={item.unread_messages_count}
              color={styles.AvatarColor}
              onClick={(event) => handleTeacherCardClick(event, item)}
              isSelected={
                !initialRender &&
                item.uuid === localStorage.getItem('SELECTED_TEACHER')
              }
              lastListItem={index === sortedData.length - 1}
            />
          ))
        ) : (
          <EmptyDataIcon message={'teachers'} />
        )}
      </styles.BodyContainer>
    </styles.TeacherListContainer>
  )
}

export default TeacherList
