export const FETCH_STUDENT_DATA = 'FETCH_STUDENT_DATA'
export const FETCH_STUDENT_DATA_SUCCESS = 'FETCH_STUDENT_DATA_SUCCESS'

export const FETCH_TEACHER_DATA = 'FETCH_TEACHER_DATA'
export const FETCH_TEACHER_DATA_SUCCESS = 'FETCH_TEACHER_DATA_SUCCESS'

export const FETCH_SUPERPROVIDER_DATA = 'FETCH_SUPERPROVIDER_DATA'
export const FETCH_SUPERPROVIDER_DATA_SUCCESS =
  'FETCH_SUPERPROVIDER_DATA_SUCCESS'

export const FETCH_ADMIN_DATA = 'FETCH_ADMIN_DATA'
export const FETCH_ADMIN_DATA_SUCCESS = 'FETCH_ADMIN_DATA_SUCCESS'

export const FETCH_PARENT_DATA = 'FETCH_PARENT_DATA'
export const FETCH_PARENT_DATA_SUCCESS = 'FETCH_PARENT_DATA_SUCCESS'

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'
