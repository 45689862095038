import PropTypes from 'prop-types'
import React from 'react'
import Lottie from 'react-lottie'

import { generateDefaultOptions, styles } from './config'

const animationData = require('assets/lottiefiles/loading.json')

const defaultOptions = generateDefaultOptions(animationData)

const Loading = ({ height, width }) => {
  return (
    <div style={styles}>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height={height}
        width={width}
      />
    </div>
  )
}

Loading.defaultProps = {
  width: 400,
  height: 400
}

Loading.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}

export default Loading
