import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { fetchWhatsAppTemplates } from 'api'
import { AutoComplete } from 'app/components/generic/FormFields'
import {
  dispatchHandleUpdateSelectedMessageTemplate,
  dispatchHandleUpdateMessageTemplateParameter,
  dispatchUpdateSendMessageModalState,
  dispatchUpdateCreateMessageTemplateModalState
} from 'app/store/dispatcher'
import {
  selectSelectedMessageTemplate,
  selectFieldErrors
} from 'app/store/selectors'

import * as Styles from './styles'

const AddNewTemplate = ({ onClick }) => {
  return (
    <Styles.NoTemplate>
      Can't find? <span onClick={onClick}>Add template</span>
    </Styles.NoTemplate>
  )
}

const useStyles = makeStyles((theme) => ({
  template: {
    marginTop: theme.spacing(3)
  },
  filter: {
    marginTop: theme.spacing(0.5),

    width: '48%'
  }
}))

const MessageTemplate = ({ disabled }) => {
  const selectedMessageTemplate = useSelector(selectSelectedMessageTemplate)

  const fieldErrors = useSelector(selectFieldErrors)

  const classes = useStyles()

  const { formattedTemplate, value: selectedMessageTemplateValue } =
    selectedMessageTemplate

  const onHandleMessageTemplateSelection = (name, value) => {
    dispatchHandleUpdateSelectedMessageTemplate(value)
  }

  const onHandleParameterChange = (event) => {
    dispatchHandleUpdateMessageTemplateParameter(event)
  }

  const parametersElements = React.useMemo(() => {
    const { parameters } = selectedMessageTemplate

    const totalParameters = parameters.length

    if (totalParameters === 0) return null

    return parameters.map((item, index) => {
      return (
        <Styles.ParameterFieldWithErrorContainer key={item.uuid}>
          <Styles.ParameterFieldContainer>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.filter}
            >
              <InputLabel>Parameter {index + 1}</InputLabel>
              <Select
                value={parameters[index]?.value || ''}
                name={`parameter-${index}`}
                label={`Parameter ${index + 1}`}
                onChange={onHandleParameterChange}
                disabled={disabled}
              >
                <MenuItem value="first_name">First Name</MenuItem>
                <MenuItem value="last_name">Last Name</MenuItem>
                <MenuItem value="full_name">Full Name</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>

              {fieldErrors.parameters[index + 1] && (
                <FormHelperText error>
                  {fieldErrors.parameters[index + 1]}
                </FormHelperText>
              )}
            </FormControl>
            {!parameters[index]?.isDynamic ? (
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.filter}
              >
                <TextField
                  name={`staticparameter-${index}`}
                  label={`Parameter ${index + 1}`}
                  variant="outlined"
                  value={parameters[index]?.staticValue || ''}
                  onChange={onHandleParameterChange}
                  disabled={disabled}
                />
              </FormControl>
            ) : null}
          </Styles.ParameterFieldContainer>
        </Styles.ParameterFieldWithErrorContainer>
      )
    })
  }, [selectedMessageTemplate, fieldErrors, disabled])

  const onClickAddNewTemplate = () => {
    dispatchUpdateSendMessageModalState(false)
    dispatchUpdateCreateMessageTemplateModalState(true)
  }

  return (
    <Styles.Container>
      <FormControl fullWidth variant="outlined" size="small">
        <AutoComplete
          label="Message Template"
          minCharactersToSearch={0}
          required
          name="message-template"
          async={false}
          multiple={false}
          fetchOnMount
          searchWithEmptyValue
          disabled={disabled}
          clearOnBlur={false}
          fetchValues={fetchWhatsAppTemplates}
          onChange={onHandleMessageTemplateSelection}
          {...(!isEmpty(selectedMessageTemplateValue)
            ? { defaultValue: selectedMessageTemplateValue }
            : null)}
        />

        {fieldErrors.messageTemplate && (
          <FormHelperText error>{fieldErrors.messageTemplate}</FormHelperText>
        )}

        {/* <AddNewTemplate onClick={onClickAddNewTemplate} /> */}
      </FormControl>
      <Styles.MessageContainer>
        <Styles.MessageLabel>Message</Styles.MessageLabel>
        <Styles.MessageContent
          dangerouslySetInnerHTML={{ __html: formattedTemplate || '' }}
        />
      </Styles.MessageContainer>
      <Styles.ParametersContainer>
        {parametersElements}
      </Styles.ParametersContainer>
    </Styles.Container>
  )
}

export { MessageTemplate }
