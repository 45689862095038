import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;


export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;

export const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 0.25em;
`

export const IconTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5em;
`;

export const Title = styled.div`
  font-family: 'AvenirDemi';
  font-size: 1em;
  font-weight: 600;
  color: #282E3C;
`;

export const Description = styled.div`
  font-size: 0.9em;
  font-family: Avenir;
  font-weight: 400;
  color: var(--oh-black-secondary);
`;
