import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    name: 'notes',
    label: 'Add details about this termination reason',
    widget: 'wysiwyg',
    validate: validateNotEmpty
  }
]
