import { Radio } from '@material-ui/core'
import { get } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { apiEndpoints } from 'api'
import { generateOtp } from 'app/common'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import { tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import { fetchTeacherData, updateUserData } from 'app/store/actions/userEntity'
import { validateNotEmpty } from 'app/validators'

import { columns } from './config'

const Teacher = () => {
  const teacherData = useSelector(
    (state) => state.userEntityReducer.teacherData
  )
  const radioButtons = [
    {
      value: 'superprovider',
      control: <Radio />,
      label: 'Superprovider'
    },
    {
      value: 'independent',
      control: <Radio />,
      label: 'Independent'
    }
  ]
  const [value, setValue] = React.useState(radioButtons[0].value)
  const handleRadioButtonChange = (e) => {
    setValue(e.target.value)
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: 'teacher_type',
        label: 'Teacher type',
        widget: 'radiobutton',
        validate: validateNotEmpty,
        radioButtons: radioButtons,
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'teacher_type'
        }
      },
      {
        name: 'super_provider',
        label: 'Superprovider',
        widget: 'autocomplete',
        conditionalRender: (data) => {
          return data['teacher_type'] === 'superprovider'
        },
        validate: validateNotEmpty,
        minCharactersToSearch: 0,
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            if (item)
              return {
                uuid: item.uuid,
                name: item.name
              }
          }

          if (Array.isArray(data)) {
            return data.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
            return data.results.map((item) => {
              return getItemStructure(item)
            })
          }
          return [getItemStructure(data)]
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            return item?.uuid
          }
          return getItemStructure(data)
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'super_provider',
          keyValue: 'uuid'
        },
        apiOptions: {
          method: 'get',
          url: apiEndpoints.superprovider
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'super_provider_name',
          customBodyRender: (value) => <span>{value ? value.name : null}</span>
        }
      },
      {
        name: '',
        disabledInForm: true,
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { user } = teacherData.data[dataIndex]

            return (
              <Button onClick={() => generateOtp(user)}>Generate Otp</Button>
            )
          }
        }
      }
    ]
  }, [teacherData])

  return (
    <BaseEntity
      entity={constants.TEACHER}
      label={constants.TEACHER_LABEL}
      columns={modifiedColumns}
      data={teacherData}
      fetchData={fetchTeacherData}
      updateEntityData={updateUserData}
      disableDelete
    />
  )
}

export default Teacher
