import PropTypes from 'prop-types'
import React from 'react'
import Lottie from 'react-lottie'

import { dummyStyles, generateDefaultOptions } from './config'
import * as styles from './dummyStyles'

const animationData = require('assets/lottiefiles/dummyanimation.json')

const defaultOptions = generateDefaultOptions(animationData)

const Dummy = ({ height, width }) => {
  return (
    <div style={dummyStyles} height={height} width={width}>
      <styles.animationStyles></styles.animationStyles>
    </div>
  )
}

Dummy.defaultProps = {
  width: 400,
  height: 400
}

Dummy.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
}

export default Dummy
