import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import React from 'react'

import { programList } from 'app/config'

import ExplorationCampForm from './ExplorationCampForm'
import ExplorationForm from './ExplorationForm'
import ProgramSelectionForm from './ProgramSelectionForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const MainForm = (props) => {
  const classes = useStyles()
  const { activeStep, formRef, formData } = props

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {({ values }) => {
          return (
            <Form>
              <FormControl className={classes.formControl} fullWidth>
                {activeStep === 0 ? (
                  <ProgramSelectionForm formData={formData} />
                ) : activeStep === 1 &&
                  values?.program?.type === programList.exporation ? (
                  <ExplorationForm formData={formData} />
                ) : activeStep === 1 &&
                  values?.program?.type === programList.summer_explore ? (
                  <ExplorationCampForm formData={formData} />
                ) : null}
              </FormControl>
            </Form>
          )
        }}
      </Formik>
    )
  }, [activeStep, classes, formData])

  return <>{formElement}</>
}

export default MainForm
