import Dayjs from 'dayjs'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import {
  commonAttributes,
  tableColumnDefaultOption,
  yesNoOptions,
  uploadPresets
} from 'app/config'
import constants from 'app/constants'
import { sanitize } from 'app/utils'
import { validateNotEmpty, validateURL, validateCount } from 'app/validators'

const validateEndTime =
  ({ isRequired, formValues }) =>
  (value) => {
    const { start_time, end_time } = formValues

    const startTime = new Date(`11-12-12 ${start_time}`).getTime()
    const endTime = new Date(`11-12-12 ${end_time}`).getTime()
    const invalidTime = endTime <= startTime

    // if the end time is selected without selecting the start time
    if (isEmpty(start_time) && !isEmpty(value))
      return 'Please select a start time first.'

    // if left empty
    if (isRequired && isEmpty(value)) return 'Please enter a valid value'

    // if end time is less than the start time
    if (invalidTime && typeof value === 'string' && !isEmpty(value))
      return 'End time can not be less than the start time.'
  }

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Event Name',
    validate: validateNotEmpty,
    ...tableColumnDefaultOption
  },
  {
    name: 'date',
    label: 'Date',
    widget: 'datepicker',
    validate: validateNotEmpty,
    options: {
      customBodyRender: (value) => (
        <span>
          {value ? <>{Dayjs(`${value}`).format('D MMM, YYYY')}</> : null}
        </span>
      )
    }
  },
  {
    name: 'start_time',
    label: 'Start Time',
    widget: 'timepicker',
    validate: validateNotEmpty,
    options: {
      customBodyRender: (value) => (
        <span>
          {value ? (
            <>
              {Dayjs(`11-12-12 ${value}`).format('HH:mma')}
              {/* Used a constant date above as dayjs wont convert just time stamp , 
              it always requires a dummy date, 
              it wont effect anywhere in data just for conversion its being used */}
            </>
          ) : null}
        </span>
      )
    }
  },
  {
    name: 'end_time',
    label: 'End Time',
    widget: 'timepicker',
    validate: validateEndTime,
    formValuesRequired: true,
    options: {
      customBodyRender: (value) => (
        <span>
          {value ? (
            <>
              {Dayjs(`11-12-12 ${value}`).format('HH:mma')}
              {/* Used a constant date above as dayjs wont convert just time stamp , 
              it always requires a dummy date, 
              it wont effect anywhere in data just for conversion its being used */}
            </>
          ) : null}
        </span>
      )
    }
  },

  {
    name: 'meeting_link',
    label: 'Meeting Link',
    contentType: 'link',
    validate: validateURL,
    ...tableColumnDefaultOption
  },
  {
    name: 'must_attend',
    label: 'Must Attend',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: yesNoOptions,
    ...tableColumnDefaultOption
  },
  {
    name: 'status',
    label: 'Status',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: {
      active: 'Active',
      inactive: 'Inactive'
    },
    ...tableColumnDefaultOption
  },
  {
    name: 'host_first_name',
    label: 'Host First name',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'host_first_name',
      display: false
    }
  },
  {
    name: 'host_last_name',
    label: 'Host last name',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'host_last_name',
      display: false
    }
  },
  {
    name: '',
    label: 'host name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'hostname',
      customBodyRender: (value, tableMeta) => {
        const rowData = tableMeta.rowData
        return (
          <span>
            {rowData[8]} {rowData[9]}
          </span>
        )
      }
    }
  },
  {
    name: 'host_details',
    label: 'About the host',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'host_details'
    }
  },
  {
    name: 'outcome',
    label: 'From this event, we hope you learn..',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'outcome'
    }
  },
  {
    name: 'number_of_seats',
    label: 'Number of Seats',
    validate: validateCount,
    ...tableColumnDefaultOption
  },
  {
    name: 'recent_enrollments_only',
    label: 'Is Recent Enrolment Only',
    widget: 'select',
    validate: validateNotEmpty,
    valueLabelMappings: yesNoOptions,
    ...tableColumnDefaultOption
  },
  {
    ...commonAttributes.user,
    label: 'Organiser',
    name: 'organiser',
    formDataKey: 'organiser',
    resultStructure: {
      keyName: 'organiser',
      keyValue: 'uuid'
    },
    options: {
      ...commonAttributes.user.options,
      filterKey: 'organiser'
    }
  },
  {
    name: 'feedback_url',
    label: 'Feedback (google form/typeform)',
    optional: true,
    options: {
      filterKey: 'feedback_url'
    }
  },
  {
    ...commonAttributes.standard,
    name: 'standards',
    label: 'Standards',
    formDataKey: 'standards',
    resultStructure: {
      keyName: 'standards',
      keyValue: 'uuid'
    },
    multiple: true,
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    options: {
      ...commonAttributes.standard.options,
      customBodyRender: (value) => {
        return (
          <span>
            {Array.isArray(value) &&
              value
                .map((item) => {
                  return item.name
                })
                .join(', ')}
          </span>
        )
      }
    }
  },
  {
    ...commonAttributes.batches,
    name: 'batches',
    label: 'Batches',
    optional: true,
    options: {
      ...commonAttributes.batches.options,
      filterKey: 'batch'
    }
  },
  {
    ...commonAttributes.teacher,
    name: 'teachers',
    label: 'Teachers',
    optional: true,
    formDataKey: 'teachers',
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'user')) {
          return {
            uuid: item?.user?.uuid,
            name: `${item?.user?.first_name} ${item?.user?.last_name}`
          }
        } else {
          return {
            uuid: item?.uuid,
            name: item?.name
          }
        }
      }
      if (Object.prototype.hasOwnProperty.call(data, 'user')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    resultStructure: {
      keyName: 'teachers',
      keyValue: 'uuid'
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item?.uuid
      }

      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    multiple: true,
    options: {
      ...commonAttributes.teacher.options,
      filterKey: 'teacher',
      customBodyRender: (value) => {
        return (
          <span>
            {Array.isArray(value) &&
              value
                .map((item) => {
                  const name = get(item, 'name', '')
                  return name
                })
                .join(', ')}
          </span>
        )
      }
    }
  },
  {
    ...commonAttributes.image,
    name: 'poster',
    label: 'Poster',
    fileUrlOnly: true,
    uploadPreset: uploadPresets[constants.EVENT_POSTER]
  },
  {
    ...commonAttributes.image,
    name: 'illustration',
    label: 'illustration',
    fileUrlOnly: true,
    uploadPreset: uploadPresets[constants.EVENT_POSTER]
  },
  {
    name: 'description',
    label: 'Description',
    widget: 'wysiwyg',
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'description',
      customBodyRender: (value) => {
        return <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      }
    }
  }
]
