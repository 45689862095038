/* eslint-disable react/prop-types */
import { MenuItem, FormControl, Select, InputLabel } from '@material-ui/core'
import React from 'react'

import { SubTitle } from '../../styles'

const style = {
  fontSize: '14px'
}

const studentSources = [
  {
    label: 'Openhouse',
    value: 'openhouse'
  },
  {
    label: 'Teacher',
    value: 'teacher'
  },
]

export const StudentSource = ({
  isCheckoutMode,
  batchId,
  value,
  handleOnChange
}) => {
  const onChange = (e) => {
    const { name: batchId, value } = e.target
    handleOnChange(batchId, 'studentSource', {
      label: studentSources.find((item) => item.value === value).label,
      value
    })
  }

  React.useEffect(() => {
    handleOnChange(batchId, 'studentSource', studentSources[0])
  }, [batchId])

  if (isCheckoutMode) return <SubTitle>{value?.label || ''}</SubTitle>

  return (
    <FormControl variant="standard" size="small">
      <InputLabel id="Student-Source-select-label">Student Source</InputLabel>
      <Select
        labelId="Student-Source-select-label"
        label="Student Source"
        name={batchId}
        value={value?.value || ''}
        onChange={onChange}
        style={style}
      >
        {studentSources.map(({ label, value }) => (
          <MenuItem key={value} value={value} style={style}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
