import { tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    name: 'date',
    label: 'Date',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'date'
    }
  },
  {
    name: 'start_time',
    label: 'Start time',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'start_time'
    }
  }
]
