import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateMockPaperVisibleOnWebsite } from 'api/mockTest'
import BaseEntity from 'app/components/BaseEntity'
import constants from 'app/constants'
import { showToast } from 'app/helpers'
import {
  fetchMockPaperData,
  updateMockPaperData
} from 'app/store/actions/mockTestEntity'

import { getColumns } from './config'

const Paper = () => {
  const dispatch = useDispatch()
  const paperData = useSelector(
    (state) => state.mockTestEntityReducer.paperData
  )

  const onChangeVisibility = useCallback(
    (row, visible) => {
      updateMockPaperVisibleOnWebsite(row, visible)
        .then((data) => {
          if (data.success) {
            showToast('Data saved successfully', constants.SUCCESS)
          } else {
            dispatch(fetchMockPaperData({}, constants.PAPER))
            showToast('Oops, Unable to save data', constants.ERROR)
          }
        })
        .catch((e) => {
          dispatch(fetchMockPaperData({}, constants.PAPER))
          showToast(
            e.message
              ? e.message
              : 'Unable to update website visibility status',
            constants.ERROR
          )
        })
    },
    [dispatch, updateMockPaperVisibleOnWebsite]
  )

  const columns = getColumns(paperData, onChangeVisibility)
  return (
    <BaseEntity
      entity={constants.PAPER}
      label={constants.PAPER_LABEL}
      columns={columns}
      data={paperData}
      fetchData={fetchMockPaperData}
      updateEntityData={updateMockPaperData}
    />
  )
}

export default Paper
