
export const chatStatusPillList = [
  {
    displayName: 'open',
    name: 'open',
    key: 'open'
  },
  {
    displayName: 'resolved',
    name: 'resolved',
    key: 'resolved'
  },
  {
    displayName: 'all',
    name: 'all',
    key: 'all'
  }
]