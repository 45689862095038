import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => props.isCheckoutMode && `margin-top: 10px;`}
  align-self: flex-end;
  .MuiButton-contained {
    border-radius: 2em;
    margin: 0 0.5em;
    padding: 0.5em 2em;
    font-weight: bold;
    text-transform: capitalize;
  }
`

export const ButtonWrapper = styled.div`
  padding-top: 15px;
`
