const academics = {
  primary: 'rgba(88, 183, 230, 1)',
  secondary: 'rgba(222, 241, 250, 1)',
  text: 'rgba(21, 102, 142, 1)'
}

export const categoryColorMap = {
  // Academic
  Physics: academics,
  Mathematics: academics,
  Chemistry: academics,
  Biology: academics,
  Economics: academics,
  English: academics,
  Literature: academics,
  Computer: academics,
  Hindi: academics,
  Accounts: academics,
  // Extracurricular
  Dance: {
    primary: 'rgba(239, 92, 52, 1)',
    secondary: 'rgba(255, 225, 217, 1)',
    text: 'rgba(239, 92, 52, 1)'
  },
  Art: {
    primary: 'rgba(149, 196, 140, 1)',
    secondary: 'rgba(213, 231, 209, 1)',
    text: 'rgba(72, 121, 62, 1)'
  },
  Theatre: {
    primary: 'rgba(233, 201, 225, 1)',
    secondary: 'rgba(250, 238, 247, 1)',
    text: 'rgba(189, 92, 164, 1)'
  },
  Music: {
    primary: 'rgba(243, 197, 32, 1)',
    secondary: 'rgba(253, 243, 210, 1)',
    text: 'rgba(116, 92, 6, 1)'
  },
  Robotics: {
    primary: 'rgba(126, 70, 1, 1)',
    secondary: 'rgba(251, 245, 237, 1)',
    text: 'rgba(126, 70, 1, 1)'
  },
  Yoga: {
    primary: 'rgba(249, 103, 147, 1)',
    secondary: 'rgba(254, 233, 239, 1)',
    text: 'rgba(249, 103, 147, 1)'
  },
  'Public Speaking': {
    primary: 'rgba(146, 208, 185, 1)',
    secondary: 'rgba(211, 236, 227, 1)',
    text: 'rgba(60, 139, 110, 1)'
  },
  'Early Childhood': {
    primary: 'rgba(184, 39, 65, 1)',
    secondary: 'rgba(250, 230, 233, 1)',
    text: 'rgba(184, 39, 65, 1)'
  },
  disabled: {
    primary: 'rgba(0, 0, 0, 0.56)',
    secondary: 'rgba(0, 0, 0, 0.12)',
    text: 'rgba(0, 0, 0, 0.56)'
  },
  fallback: {
    primary: 'rgba(239, 92, 52, 1)',
    secondary: 'rgba(255, 225, 217, 1)',
    text: 'rgba(239, 92, 52, 1)'
  }
}
