import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock'
import OhIcon from 'assets/images/oh_logo_transparent.svg'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const ActiveStudents = (props) => {
  const { selectedTeacherDetails, activeStudentsData } = props
  return (
    <styles.Body>
      <styles.InfoBlock>
        <TitleDescription
          heading="name"
          description={
            selectedTeacherDetails?.user
              ? selectedTeacherDetails?.user?.full_name
              : 'NA'
          }
        />
        <TitleDescription
          heading="state"
          description={
            selectedTeacherDetails?.teacherStateName ? (
              <styles.Heading>
                <styles.Icon src={OhIcon} alt="oh-icon" />
                {` ${selectedTeacherDetails?.teacherStateName?.toLowerCase()}`}
              </styles.Heading>
            ) : (
              'NA'
            )
          }
        />
      </styles.InfoBlock>
      {!isEmpty(activeStudentsData) ? (
        <styles.RowFlexCenter>
          <TitleDescription
            heading="active students"
            description={
              activeStudentsData?.activeStudentsCount
                ? activeStudentsData?.activeStudentsCount
                : 0
            }
            size="big"
            align="center"
          />
        </styles.RowFlexCenter>
      ) : (
        <NotFoundBlock message="Oh no! User has no active student data." />
      )}
    </styles.Body>
  )
}
