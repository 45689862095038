import React from 'react'
import * as styles from './styles'
import { NewMomentText, VideoFallbackText } from '../../../constants'

const MessageVideo = ({ text, contentMedia }) => {

  return (
    <styles.Container>
      {NewMomentText}
      <styles.InnerContainer>
        {text}
        <styles.VideoContainer>
          {contentMedia ?
            <video controls width='280' height='280'>
              <source src={contentMedia} type="video/mp4" />
              {VideoFallbackText}
            </video>
            : <div>{VideoFallbackText}</div>
          }
        </styles.VideoContainer>
      </styles.InnerContainer>
    </styles.Container>
  )
}

export default MessageVideo