import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants.js'
import { confirm, eventEmitter } from 'app/helpers'

import { getConfirmModalHtml, journeyFormActions } from './constants.js'
import ChangeBatchTimingsForm from './forms/ChangeBatchTimingsForm/ChangeBatchTimingsForm.js'
import DiscoveryReportForm from './forms/DiscoveryReportForm/DiscoveryReportForm.js'
import PauseEnrolmentForm from './forms/PauseEnrolmentForm/PauseEnrolmentForm'
import UnsubscribeForm from './forms/UnsubscribeForm/UnsubscribeForm'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const MasterForm = (props) => {
  const classes = useStyles()
  const formRef = React.useRef(null)

  const { isModalOpen, closeModal, onSaveClick, action, data, extraFormData } =
    props
  const [formData, setFormData] = React.useState({
    student_name: data?.student_name || '',
    subject_name: data?.subject_name || '',
    teacher_name: data?.teacher_name || '',
    month: data?.month || '',
    last_session_date: data?.last_session_date || '',
    reason: '',
    resume_date: data?.resume_date || '',
    first_new_session_date: data?.first_new_session_date || '',
    last_current_session_date: data?.last_current_session_date || '',
    spoken_to_parent: false,
    is_discovery_successful: null,
    delete_makeups: null,
    progress_enabled: data?.progress_enabled || false,
    reason_for_using_alfred: ''
  })

  React.useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      ...data,
      ...extraFormData
    }))
  }, [data, extraFormData])

  const handleOnSaveClick = React.useCallback(
    async (type = null) => {
      if (formRef.current) {
        const { submitForm, validateForm, values } = formRef.current
        try {
          const errors = await validateForm(values)
          console.log(errors)
          if (!isEmpty(errors)) {
            submitForm()
            eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
            if (type === 'program') return true
          } else {
            if (type === 'program') return false
            else {
              try {
                confirm(
                  () => {
                    onSaveClick(values, action)
                  },
                  '',
                  () => {
                    eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
                  },
                  {
                    html: getConfirmModalHtml(action, values),
                    icon: '',
                    confirmButtonText: 'Confirm'
                  }
                )
              } catch (error) {
                console.log(error)
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    [onSaveClick, action]
  )

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}>
        {({}) => {
          return (
            <Form>
              <FormControl className={classes.formControl} fullWidth>
                {action === journeyFormActions.PAUSE_ENROLMENT ? (
                  <PauseEnrolmentForm formData={formData} />
                ) : action === journeyFormActions.UNSUBSCRIBE ? (
                  <UnsubscribeForm formData={formData} />
                ) : action === journeyFormActions.CHANGE_BATCH_TIMINGS ? (
                  <ChangeBatchTimingsForm formData={formData} />
                ) : action === journeyFormActions.SUBMIT_DISCOVERY_REPORT ? (
                  <DiscoveryReportForm formData={formData} />
                ) : null}
              </FormControl>
            </Form>
          )
        }}
      </Formik>
    )
  }, [action, classes, formData])

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}>
      {formElement}
    </ModalForm>
  )
}

MasterForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
}

export default MasterForm
