import React from 'react';
import * as styles from './styles';
import { showMoreCharacterLength } from '../../../pages/Journey/constants';

const IconTitleDescription = (props) => {
  const { title, description, iconUrl, isShowMore } = props;
  const [expandedRow, setexpandedRow] = React.useState(false)

  const toggleRows = (event) => {
    event.preventDefault()
    setexpandedRow((prevexpandedRow) => !prevexpandedRow)
  }

  const data = description
  const truncatedData = data?.slice(0, showMoreCharacterLength)
  const isTruncated = data?.length > truncatedData?.length
  const isExpanded = expandedRow
  const displayData = isExpanded ? data : truncatedData

  return (
    <styles.ContentWrapper>
      <styles.IconTitle>
        <styles.Icon src={iconUrl} alt="Icon" />
        <styles.Title>{title}</styles.Title>
      </styles.IconTitle>
      <styles.Description>{isShowMore ?
        <span>
          {!displayData
            ? 'N/A'
            : displayData?.length < showMoreCharacterLength
              ? displayData
              : !isExpanded
                ? displayData + '...'
                : displayData}
          <br />
          {isTruncated && (
            <div style={{ textAlign: 'left' }}>
              <a
                href="#"
                onClick={toggleRows}
                style={{ textDecoration: 'none', cursor: 'pointer', fontSize: '0.9em' }}>
                {isExpanded ? 'show less' : 'show more'}
              </a>
            </div>
          )}
        </span>
        : description
      }
      </styles.Description>
    </styles.ContentWrapper>
  );
};

export default IconTitleDescription;
