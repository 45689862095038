import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import EmptyImage from 'assets/images/empty.svg'
import { Loading } from 'app/components/generic/LottieComponents'

export const CalculationsTable = (props) => {
  const { data } = props

  return (
    <TableContainer>
      {!!data ?
        data.length ? <Table>
          <TableHead
            sx={{
              backgroundColor: '#202020',
              color: '#fff'
            }}
          >
            <TableRow>
              <TableCell>Course</TableCell>
              <TableCell>Batch type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Timing</TableCell>
              <TableCell>Centre</TableCell>
              <TableCell>No. of students</TableCell>
              <TableCell>Teacher attendance</TableCell>
              <TableCell>Arrival status</TableCell>
              {/* <TableCell>Session value / student</TableCell>
            <TableCell>Calculation</TableCell> */}
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              (session, index) =>
                session && (
                  <TableRow key={index}>
                    <TableCell>{session?.course}</TableCell>
                    <TableCell>{session?.batch_type}</TableCell>
                    <TableCell>{session?.date}</TableCell>
                    <TableCell>{session?.timing}</TableCell>
                    <TableCell>{session?.centre}</TableCell>
                    <TableCell>{session?.students}</TableCell>
                    <TableCell>{session?.teacher_attendance}</TableCell>
                    <TableCell>{session?.teacher_arrival}</TableCell>
                    {/* <TableCell>{session?.coinsPerSession}</TableCell>
                  <TableCell>{session?.calculation}</TableCell> */}
                    <TableCell>{session?.amount}</TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table> :
          <div className="emptyWrapper">
            <img src={EmptyImage} width="20%" />
            <div className="empty">Oops, This teacher has no taken any classes yet.</div>
          </div>
        : <Loading />
      }
    </TableContainer>
  )
}

CalculationsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
