import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import minMax from 'dayjs/plugin/minMax'
import React from 'react'

// import { fetchSubject } from 'api'

import { scheduleColors, subjectsList } from '../config'
import * as styles from '../styles'

dayjs.extend(customParseFormat)
dayjs.extend(minMax)

const MarketSubjects = (props) => {
  const { schedule, formData, limit } = props
  const defaultImage = require('assets/images/default.svg')

  const [subjects, setSubjects] = React.useState([])

  // const fetchSubjects = async () => {
  //   try {
  //     if (formData.centre?.value) {
  //       const response = await fetchSubject({
  //         url: null,
  //         queries: {
  //           centre_uuid: formData.centre.value,
  //           limit: 1000
  //         }
  //       })

  //       if (response) {
  //         const allSubjects = response.results.filter((t) =>
  //           subjectsList.includes(t.name.toLowerCase())
  //         )

  //         let limit = 4
  //         if (schedule.length === 0) limit = 8

  //         setSubjects(allSubjects.filter((t, index) => index < limit))
  //       }
  //     }
  //   } catch (error) {
  //     console.log('error in fetchSubjects :- ', error)
  //     // setIsLoading(false)
  //   }
  // }

  React.useEffect(() => {
    if (schedule.length <= 4) {
      let limit = 4
      if (schedule.length === 0) limit = 8
      setSubjects(subjectsList.filter((t, index) => index < limit))
    }
  }, [formData.centre, schedule.length])

  return (
    <styles.BottomPage>
      <styles.SubjectHeader>
        <styles.Today>more courses at openhouse</styles.Today>
      </styles.SubjectHeader>
      <styles.Body nomargin>
        {subjects.map((value, index) => {
          return (
            <styles.CardContainer key={index}>
              <styles.SubjectCardBody width={limit}>
                <styles.Info width={limit}>
                  <styles.Name color={scheduleColors[index]}>
                    {value?.name}
                  </styles.Name>
                </styles.Info>
              </styles.SubjectCardBody>
              <styles.SubjectImage
                src={value?.image ? value?.image : defaultImage}
                widthProp={limit}
                alt={value?.name}
              />
            </styles.CardContainer>
          )
        })}
      </styles.Body>
    </styles.BottomPage>
  )
}

export default MarketSubjects
