/* eslint-disable react/prop-types */
import { Divider, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'

import { SeparationContainer } from 'app/pages/Class/Inventory/components/styles'
import {
  batchTypesMap,
  BookingTypes,
  levelMap
} from 'app/pages/Class/Inventory/constants'
import { composeBatchDetails } from 'app/pages/Class/Inventory/transformers'
import { formatAmount, formatCoins } from 'app/utils'

import {
  fetchEndDates,
  fetchExcelScheduledEvents,
  fetchScheduledEvents
} from '../../../../../helpers'
import { FirstTimeFee } from '../../component/FirstTimeFee'
import { SchedulesDate } from '../../component/SchedulesDate'
import { StudentSource } from '../../component/StudentSource'
import * as Styles from '../../styles'
import { EnrolmentTypeSelection } from '../EnrolmentTypeSelection'

const ExtraDetails = ({
  cartItems = [],
  isCheckoutMode,
  deselectBatch,
  bookingType,
  onEditClick,
  batchInfo,
  onBatchInfoChange,
  selectedBatchType,
  showEditOptions,
  selectedBatch,
  index,
  errorSchema
}) => {
  const totalProducts = cartItems?.length

  const {
    uuid,
    subject,
    teacherName,
    alternateBatches,
    location,
    price,
    coins,
    level,
    coinsTotal,
    coinsNoOfSessions,
    coinsBySession,
    batchStartDate,
    batchEndDate,
    coinPredictionError,
    creditsApplied,
    subjectsName
  } = composeBatchDetails(selectedBatch)

  const endDate = React.useMemo(() => {
    return (
      <SchedulesDate
        batchId={uuid}
        value={batchInfo[uuid]?.endDate}
        propertyChange={'endDate'}
        handleOnChange={onBatchInfoChange}
        isCheckoutMode={isCheckoutMode}
        selectedBatchType={selectedBatchType}
        placeholder={'end date'}
        scheduleFunction={() =>
          fetchEndDates(
            batchInfo[uuid]?.startDate?.label,
            uuid,
            onBatchInfoChange
          )
        }
      />
    )
  }, [batchInfo[uuid]?.startDate, batchInfo[uuid]?.endDate, isCheckoutMode])

  return (
    <React.Fragment key={uuid}>
      <Styles.FormContainer>
        <Styles.Container>
          <Styles.TopContainer>
            <Styles.LeftContainer>
              {subject?.image ? (
                <Styles.ImageContainer>
                  <Styles.Image src={subject?.image} />
                </Styles.ImageContainer>
              ) : null}

              <Styles.DescriptionContainer>
                {subjectsName && <Styles.Title>{subjectsName}</Styles.Title>}
                {level && <Styles.SubTitle>{levelMap[level]}</Styles.SubTitle>}
                {teacherName && (
                  <Styles.SubTitle>{teacherName}</Styles.SubTitle>
                )}
              </Styles.DescriptionContainer>
            </Styles.LeftContainer>
            <Styles.RightContainer $isCheckoutMode={isCheckoutMode}>
              {!isCheckoutMode && !showEditOptions ? (
                <Styles.ActionButtons>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      onEditClick()
                      setTimeout(() => {
                        const scheduleCard = document.getElementById(
                          `ScheduleCard-${selectedBatch.uuid}`
                        )
                        scheduleCard &&
                          scheduleCard.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center'
                          })
                      })
                    }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      deselectBatch(selectedBatch)
                    }}>
                    <DeleteIcon
                      color={!!coinPredictionError ? 'error' : 'inherit'}
                    />
                  </IconButton>
                </Styles.ActionButtons>
              ) : null}
            </Styles.RightContainer>
          </Styles.TopContainer>
          <Styles.BottomContainer>
            {alternateBatches && selectedBatchType !== batchTypesMap.excel && (
              <>
                <Styles.TimingsContainer>
                  {alternateBatches.map((batchTiming, index) => (
                    <Styles.BodyText key={index}>{batchTiming}</Styles.BodyText>
                  ))}
                </Styles.TimingsContainer>
                <SeparationContainer>
                  {selectedBatchType !== batchTypesMap.excel && (
                    <Divider orientation="vertical" />
                  )}
                </SeparationContainer>
              </>
            )}

            <Styles.OtherDetailsContainer $isCheckoutMode={isCheckoutMode}>
              {location && <Styles.BodyText>{location}</Styles.BodyText>}
              {bookingType === BookingTypes.CLASS && (
                <>
                  <SchedulesDate
                    batchId={uuid}
                    value={batchInfo[uuid]?.startDate}
                    handleOnChange={onBatchInfoChange}
                    isCheckoutMode={isCheckoutMode}
                    propertyChange={'startDate'}
                    selectedBatchType={selectedBatchType}
                    scheduleFunction={fetchScheduledEvents}
                  />
                  <StudentSource
                    batchId={uuid}
                    value={batchInfo[uuid]?.studentSource}
                    handleOnChange={onBatchInfoChange}
                    isCheckoutMode={isCheckoutMode}
                  />
                  <FirstTimeFee
                    batchId={uuid}
                    value={batchInfo[uuid]?.firstTimeFee}
                    handleOnChange={onBatchInfoChange}
                    isCheckoutMode={isCheckoutMode}
                    max={price}
                  />
                </>
              )}
              {subject?.discovery_enabled ? (
                <EnrolmentTypeSelection
                  handleOnChange={onBatchInfoChange}
                  batchId={uuid}
                  value={batchInfo[uuid]?.isDiscover}
                  isCheckoutMode={isCheckoutMode}
                  errorSchema={errorSchema}
                />
              ) : null}
              {bookingType === BookingTypes.EXCEL && (
                <>
                  <SchedulesDate
                    batchId={uuid}
                    value={batchInfo[uuid]?.startDate}
                    handleOnChange={onBatchInfoChange}
                    isCheckoutMode={isCheckoutMode}
                    propertyChange={'startDate'}
                    selectedBatchType={selectedBatchType}
                    scheduleFunction={fetchExcelScheduledEvents}
                    placeholder={'start date'}
                  />
                  {endDate}
                </>
              )}
            </Styles.OtherDetailsContainer>
          </Styles.BottomContainer>
        </Styles.Container>
        {
          bookingType === BookingTypes.CLASS ? (
            <Styles.PriceContainer>{formatAmount(price)}</Styles.PriceContainer>
          ) : coinsTotal && !coinPredictionError ? (
            <Styles.PriceDetailsContainer>
              <Styles.DetailsContainer>
                {selectedBatchType === batchTypesMap.explore
                  ? `${batchStartDate}`
                  : `${batchStartDate} to ${batchEndDate}`}
              </Styles.DetailsContainer>
              {selectedBatchType !== batchTypesMap.excel ? (
                <Styles.DetailsContainer>{`${coinsNoOfSessions} sesn. x ${formatCoins(
                  coinsBySession
                )}`}</Styles.DetailsContainer>
              ) : null}

              <Styles.PriceContainer>
                {formatCoins(coinsTotal)}
              </Styles.PriceContainer>
            </Styles.PriceDetailsContainer>
          ) : coinPredictionError ? (
            <Styles.PriceDetailsContainer>
              <Styles.ErrorContainer>{`${coinPredictionError.replaceAll(
                '_',
                ' '
              )}`}</Styles.ErrorContainer>
              {coinPredictionError === 'insufficient_coins' && (
                <>
                  {selectedBatchType !== batchTypesMap.excel ? (
                    <Styles.DetailsContainer>{`${coinsNoOfSessions} sesn. x ${formatCoins(
                      coinsBySession
                    )}`}</Styles.DetailsContainer>
                  ) : null}

                  <Styles.PriceReqContainer>{`${formatCoins(
                    coinsTotal
                  )} required`}</Styles.PriceReqContainer>
                </>
              )}
            </Styles.PriceDetailsContainer>
          ) : creditsApplied ? (
            <Styles.PriceDetailsContainer>
              <Styles.DetailsContainer>{`${batchStartDate}`}</Styles.DetailsContainer>
              <Styles.DetailsContainer>{`${coinsNoOfSessions} sesn.`}</Styles.DetailsContainer>
              <Styles.PriceContainer>{`${creditsApplied} free trial`}</Styles.PriceContainer>
            </Styles.PriceDetailsContainer>
          ) : null
          // ) : (
          //   <Styles.PriceContainer>{creditsApplied}</Styles.PriceContainer>
          // )
        }
      </Styles.FormContainer>
      {index !== totalProducts - 1 ? (
        <Divider style={{ margin: '1.5em' }} />
      ) : null}
    </React.Fragment>
  )
}

export default ExtraDetails
