import Button from '@material-ui/core/Button'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  cancelSingleSession,
  fetchDueAmount,
  terminateEnrolment as terminateEnrolmentApi,
  terminateProgramEnrolment
} from 'api'
import BaseEntity from 'app/components/BaseEntity'
import { useStyles } from 'app/components/generic/Button'
import ButtonGroup from 'app/components/generic/ButtonGroup'
import { enrolmentActions } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter } from 'app/helpers'
import {
  fetchEnrolmentData,
  updateClassData
} from 'app/store/actions/classEntity'
import { getCurrentMonth } from 'app/utils'

import CancelModal from './components/Cancel/CancelModal'
import { columns } from './config'

import MasterModal from '../components/MasterModal'
import { batchTypesMap } from '../Inventory/constants'

const Enrolment = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false)
  const [action, setAction] = React.useState('')
  const [cancelId, setCancelId] = React.useState('')
  const [editIndex, setEditIndex] = React.useState(null)
  const [dueAmount, setDueAmount] = React.useState({
    enrolment_uuid: '',
    data: [],
    dataIndex: null,
    due: 0
  })

  const enrolmentData = useSelector(
    (state) => state.classEntityReducer.enrolmentData
  )

  const dispatch = useDispatch()

  const openModal = React.useCallback((action, dataIndex) => {
    setIsModalOpen(true)
    setAction(action)
    setEditIndex(dataIndex)
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const closeCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const onActionButtonClick = async (e, action, dataIndex) => {
    if (action === 'terminate_enrolment') {
      terminateEnrolment(dataIndex)
    } else if (action === 'cancel_sessions') {
      openModal(action, dataIndex)
    } else if (action === 'cancel') {
      setIsCancelModalOpen(true)
    }
  }

  const terminateEnrolment = React.useCallback(
    async (dataIndex) => {
      const { uuid } = enrolmentData.data[dataIndex]
      const currentMonth = getCurrentMonth()

      try {
        const response = await fetchDueAmount({
          enrolment: uuid,
          month: currentMonth
        })

        const dueAmount = {
          enrolment_uuid: uuid,
          data: response.results,
          dataIndex: dataIndex,
          due: response.due
        }

        setDueAmount(dueAmount)

        if (dueAmount.data.length === 0 && dueAmount.dataIndex > -1) {
          openModal('terminate_enrolment', dueAmount.dataIndex)
        } else {
          eventAlert(
            'This enrolment has due invoices, please update status and try again',
            constants.ERROR
          )
        }
      } catch (error) {
        console.log(error)
        eventAlert(constants.ERROR_MESSAGE, constants.ERROR)
      }
    },
    [enrolmentData, openModal]
  )

  const onSaveClick = async (newData, action) => {
    const {
      uuid: enrolmentUuid,
      type: enrolmentType,
      program_enrolment: programEnrolmentData
    } = enrolmentData.data[editIndex]
    try {
      if (action === 'terminate_enrolment') {
        if (enrolmentType === 'program') {
          await terminateProgramEnrolment({
            enrolment_uuid: programEnrolmentData?.uuid,
            notes: newData.notes,
            endDate: newData.end_date,
            reason: newData.reason
          })
        } else {
          await terminateEnrolmentApi({
            enrolment_uuid: enrolmentUuid,
            amount_to_charge: dueAmount.due,
            notes: newData.notes
          })
        }

        eventEmitter.emit(constants.CLOSE_MODAL_FORM)
        eventAlert('Enrolment Terminated Successfully', constants.SUCCESS)
        closeModal()
      }

      if (action === 'cancel_sessions') {
        const response = await cancelSingleSession({
          sessionId: cancelId,
          queries: {
            attendance: {
              reason: !isEmpty(newData?.notes?.trim())
                ? `${newData.reason}##${newData.notes}`
                : `${newData.reason}`
            }
          }
        })
        if (response.success) {
          eventEmitter.emit(constants.CLOSE_MODAL_FORM)
          eventAlert('Session canceled Successfully', constants.SUCCESS)
        }
        closeCancelModal()
      }

      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchEnrolmentData({ ...queries }, false, false, constants.ENROLMENT)
      )
    } catch (error) {
      console.log(error)
      eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR, error)
      eventAlert(error.message, constants.ERROR)
    }
  }

  const classes = useStyles()

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns,
      {
        name: '',
        label: 'actions',
        disabledInForm: true,
        options: {
          disableFilter: true,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const { status, batch } = enrolmentData?.data[dataIndex] || {}
            return (
              status !== 'terminated' &&
              status !== 'expired' && (
                <ButtonGroup>
                  {Object.keys(enrolmentActions)
                    .filter(
                      (el) =>
                        !(
                          (batch?.type === batchTypesMap.event ||
                            batch?.type === batchTypesMap.explore) &&
                          el === 'cancel_sessions'
                        )
                    )
                    .map((action, index) => {
                      return (
                        <Button
                          key={'Enrolment' + index}
                          title={enrolmentActions[action]}
                          className={classes.button}
                          onClick={(e) => {
                            onActionButtonClick(e, action, dataIndex)
                          }}
                        >
                          {enrolmentActions[action]}
                        </Button>
                      )
                    })}
                </ButtonGroup>
              )
            )
          }
        }
      }
    ]
  }, [enrolmentData])

  return (
    <>
      <BaseEntity
        entity={constants.ENROLMENT}
        label={constants.ENROLMENT_LABEL}
        columns={modifiedColumns}
        data={enrolmentData}
        fetchData={fetchEnrolmentData}
        updateEntityData={updateClassData}
        createDependency
        disableDelete
      />
      <MasterModal
        isModalOpen={isModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeModal}
        action={action}
        data={enrolmentData.data[editIndex] || {}}
        onActionButtonClick={onActionButtonClick}
        setCancelId={setCancelId}
      />
      <CancelModal
        isModalOpen={isCancelModalOpen}
        onSaveClick={onSaveClick}
        closeModal={closeCancelModal}
        action={action}
      />
    </>
  )
}

export default Enrolment
