import {
  CircularProgress,
  FormControl,
  FormHelperText,
  makeStyles,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import { apiEndpoints, getSingleParent } from 'api'
import Button from 'app/components/generic/Button'
import { AutoComplete, TextField } from 'app/components/generic/FormElements'
import { ReverifyButton } from 'app/components/shared/components/ReverifyButton'
import { UserEditButton } from 'app/components/shared/components/UserEditButton'
import { getWidgetTypeName, navigationPaths } from 'app/config'
import constants, { DUMMY_PARENT_LABEL } from 'app/constants'
import {
  eventAlert,
  userEditModalHandler,
  validateParentDetails
} from 'app/helpers'
import { validateNotEmpty } from 'app/validators'

import * as styles from './styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(2)
    }
  },
  form: {
    width: '40%'
  },
  heading: {
    display: 'flex',
    margin: '20px'
  },
  formError: {
    marginTop: 0,
    marginBottom: 0
  }
}))

const ParentForm = (props) => {
  const { formData, handleOnSaveClick, loadingSubmit } = props
  const classes = useStyles()
  const { errors, values, setFieldValue } = useFormikContext()
  const [isReferral, setIsReferral] = useState(false)
  const [parentData, setParentData] = useState({})
  const [showEditOption, setshowEditOption] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const validateParents = async () => {
    const parent = await getSingleParent({ id: values?.user })
    const parentValidity = validateParentDetails(parent)
    const validity = parentValidity?.isValid
    setIsValid(parentValidity?.isValid)
    if (!validity) {
      eventAlert(
        `Parent details ${parentValidity.invalidAttributes.join(
          ', '
        )} is missing`,
        constants.ERROR
      )
    } else {
      setshowEditOption(false)
      setIsValid(true)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (values?.user) {
        const parent = await getSingleParent({ id: values?.user })
        setIsReferral(parent?.user?.is_referee_rewarded)
        if (
          parent?.user?.state === null ||
          parent?.centre_preference === null
        ) {
          setshowEditOption(true)
          setIsValid(false)
        } else {
          setIsValid(true)
        }
      }
    })()
  }, [values?.user])

  const checkboxHandler = (e) => {
    setFieldValue('dummy_first_cycle', e.target.checked)
  }

  return (
    <>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth
        required={true}
        {...(errors.user ? { error: true } : null)}
      >
        <AutoComplete
          name={'user'}
          placeholder="Enter Parent Mobile Number"
          minCharactersToSearch={10}
          getStructuredValues={(data) => {
            const getItemStructure = (item) => {
              if (Object.prototype.hasOwnProperty.call(item, 'user')) {
                const { uuid, first_name, last_name, phone_number } = item?.user
                setParentData(item)
                return {
                  uuid: uuid,
                  name: `${phone_number} (${first_name} ${last_name})`
                }
              } else {
                return {
                  uuid: item.uuid,
                  name: item.name
                }
              }
            }
            if (Array.isArray(data) && !isEmpty(data)) {
              return [getItemStructure(data[0])]
            } else if (Object.prototype.hasOwnProperty.call(data, 'user')) {
              return [getItemStructure(data)]
            } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
              return data.results.map((item) => {
                return getItemStructure(item)
              })
            } else {
              return data.map((item) => {
                return getItemStructure(item)
              })
            }
          }}
          getFormDataValue={(data) => {
            const getItemStructure = (item) => {
              if (Object.prototype.hasOwnProperty.call(item, 'user')) {
                return item.user.uuid
              }
            }
            if (Object.prototype.hasOwnProperty.call(data, 'user')) {
              return getItemStructure(data)
            }
          }}
          getOptionLabelKey="name"
          getOptionSelectedKey="uuid"
          resultStructure={{
            keyName: 'user',
            keyValue: 'uuid'
          }}
          apiOptions={{
            method: 'get',
            url: apiEndpoints.parent
          }}
          required={true}
          setFieldValue={setFieldValue}
          formData={formData}
          {...{
            validate: validateNotEmpty({
              isRequired: true
            })
          }}
        />
        {errors.user && (
          <FormHelperText className={classes.formError}>
            {errors.user}
          </FormHelperText>
        )}
        {showEditOption ? (
          <styles.EditWrapper>
            <ReverifyButton
              errorMessage={'Preferred Centre Or State data is missing'}
              validateParents={validateParents}
            />
            <UserEditButton
              buttonText={'edit parent'}
              onClick={() =>
                userEditModalHandler(
                  navigationPaths.parent,
                  parentData?.user_id
                )
              }
            />
          </styles.EditWrapper>
        ) : null}
      </FormControl>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth
        required={true}
        {...(errors.plan ? { error: true } : null)}
      >
        <AutoComplete
          name="plan"
          placeholder="Select plan"
          disabled={!isValid}
          minCharactersToSearch={0} //keep as 0 for auto load
          getStructuredValues={(data) => {
            const getItemStructure = (item) => {
              return item
                ? {
                    uuid: item.uuid,
                    name:
                      item.status === 'inactive'
                        ? `${item.name} (${item.status})`
                        : item.name
                  }
                : null
            }

            if (Array.isArray(data)) {
              return data?.map((item) => {
                return getItemStructure(item)
              })
            } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
              return data?.results?.map((item) => {
                return getItemStructure(item)
              })
            }

            return [getItemStructure(data)]
          }}
          getFormDataValue={(data) => {
            const getItemStructure = (item) => {
              return item.uuid
            }
            return getItemStructure(data)
          }}
          getOptionLabelKey="name"
          getOptionSelectedKey="uuid"
          resultStructure={{
            keyName: 'plan',
            keyValue: 'uuid'
          }}
          apiOptions={{
            method: 'get',
            url: apiEndpoints.plans
          }}
          required={true}
          setFieldValue={setFieldValue}
          formData={formData}
          {...{
            validate: validateNotEmpty({
              isRequired: true
            })
          }}
        />
        {errors.plan && (
          <FormHelperText className={classes.formError}>
            {errors.plan}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth
        required={true}
        {...(errors.start_date ? { error: true } : null)}
      >
        <TextField
          name="start_date"
          label="Start Date"
          type={getWidgetTypeName('datepicker')}
          variant="outlined"
          required={true}
          disabled={!isValid}
          {...{
            validate: validateNotEmpty({
              isRequired: true
            })
          }}
          {...{
            InputLabelProps: {
              shrink: true
            }
          }}
          {...(errors.start_date ? { error: true } : null)}
        />
        {errors.start_date && (
          <FormHelperText className={classes.formError}>
            {errors.start_date}
          </FormHelperText>
        )}
      </FormControl>
      {!isReferral ? (
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth
          {...(errors.referral_code ? { error: true } : null)}
        >
          <TextField
            name="referral_code"
            label="Referral code"
            type="text"
            variant="outlined"
            disabled={!isValid}
            {...{
              InputLabelProps: {
                shrink: true
              }
            }}
            {...(errors.referral_code ? { error: true } : null)}
          />
          {errors.referral_code && (
            <FormHelperText className={classes.formError}>
              {errors.referral_code}
            </FormHelperText>
          )}
        </FormControl>
      ) : null}
      <FormControl className={classes.formControl}>
        <FormControlLabel
          name="dummy_first_cycle"
          label={DUMMY_PARENT_LABEL}
          control={<Checkbox color="primary" onChange={checkboxHandler} />}
          disabled={!isValid}
        />
      </FormControl>
      <FormControl required={true} className={classes.formControl} fullWidth>
        <Button
          variant="contained"
          onClick={handleOnSaveClick}
          disabled={!isValid}
        >
          {loadingSubmit ? (
            <CircularProgress color="primary" />
          ) : (
            'Create membership'
          )}
        </Button>
      </FormControl>
    </>
  )
}

export default ParentForm
