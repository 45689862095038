import { useEffect } from 'react'

export const useDragNDrop = (dropArea, dropHandler) => {
  useEffect(() => {
    const highlight = () => {
      const ele = dropArea.current
      if (ele) {
        ele.style.backgroundColor = '#e9e9e9'
        ele.style.borderWidth = '2px'
      }
    }

    const unHighlight = () => {
      const ele = dropArea.current
      if (ele) {
        ele.style.backgroundColor = '#fff'
        ele.style.borderWidth = '0.5px'
      }
    }

    const preventDefaults = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const handleDrop = (e) => {
      const { files } = e.dataTransfer || {}
      dropHandler(files)
    }

    if (!dropArea?.current) return

    const events1 = ['dragenter', 'dragover']
    const events2 = ['dragleave', 'drop']

    ;[...events1, ...events2].forEach((event) => {
      dropArea.current.addEventListener(event, preventDefaults, false)
    })
    events1.forEach((event) => {
      dropArea.current.addEventListener(event, highlight, false)
    })
    events2.forEach((event) => {
      dropArea.current.addEventListener(event, unHighlight, false)
    })

    dropArea.current.addEventListener(events2[1], handleDrop, false)

    return () => {
      if (!dropArea?.current) return
      ;[...events1, ...events2].forEach((event) => {
        dropArea.current.removeEventListener(event, preventDefaults, false)
      })
      events1.forEach((event) => {
        dropArea.current.removeEventListener(event, highlight, false)
      })
      events2.forEach((event) => {
        dropArea.current.removeEventListener(event, unHighlight, false)
      })

      dropArea.current.removeEventListener(events2[1], handleDrop, false)
    }
  }, [])
}
