import styled, { css } from 'styled-components'

export const ColumnFlexStart = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
`

export const ColumnFlexGap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;
`

export const RowFlexStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`

export const RowFlexSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

export const Title = styled.div`
  font-family: AvenirMedium;
  font-size: 20px;
  line-height: 32px;
`

export const Heading = styled.div`
  font-family: AvenirMedium;
  font-size: 16px;
  line-height: 24px;
`

export const Description = styled.div`
  font-family: AvenirDemi;
  font-size: 16px;
  line-height: 24px;
`

export const Body = styled.div`
  font-family: AvenirMedium;
  font-size: 14px;
  line-height: 20px;
  color: var(--oh-black-secondary);
`
