import React from 'react'

import Loading from 'app/components/generic/Loading'

import { ActiveStudents } from './components/ActiveStudents'
import { Dropout } from './components/Dropout'
import { StudentAttendance } from './components/StudentAttendance'
import * as styles from './styles'

export const SummaryBlock = (props) => {
  const { selectedTeacherDetails, summaryData, keyIndex, isLoading } = props
  const [isSelected, setIsSelected] = React.useState(false)

  return (
    <styles.Wrapper isSelected={isSelected} key={keyIndex}>
      {summaryData.type !== 'active student' ? (
        <styles.Header>
          <styles.CardName>
            {summaryData.icon}
            &nbsp;&nbsp;
            <styles.Heading>{`${summaryData.type}`}</styles.Heading>
          </styles.CardName>
        </styles.Header>
      ) : null}

      {isLoading ? (
        <Loading />
      ) : summaryData.type === 'active student' ? (
        <ActiveStudents
          selectedTeacherDetails={selectedTeacherDetails}
          activeStudentsData={summaryData?.data}
        />
      ) : summaryData.type === 'dropouts' ? (
        <Dropout dropoutStudentsData={summaryData?.data} />
      ) : summaryData.type === 'student attendance' ? (
        <StudentAttendance studentAttendanceData={summaryData?.data} />
      ) : null}
    </styles.Wrapper>
  )
}
