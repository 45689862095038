import { isEmpty } from 'lodash'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, post } from '../request'

const localityRequestStructure = (data) => ({
  name: data.name,
  city: data.city
})

export const fetchStates = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.geography}/state`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching states :- ', error.message)
    throw error
  }
}

export const fetchCities = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.geography}/city`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching cities :- ', error.message)
    throw error
  }
}

export const fetchLocalities = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.geography}/locality`, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    console.log('error in fetching localities :- ', error.message)
    throw error
  }
}

export const createLocality = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.geography}/locality`,
      localityRequestStructure(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating locality :- ', error.message)
    throw error
  }
}
