import { Typography } from '@material-ui/core'
import { styled as muiStyled } from '@mui/material/styles'
import styled from 'styled-components'

import { LoadingButton as LoadingButtonComponent } from 'app/components/generic/LoadingButton'

export const Container = styled.div`
  padding: 1em;
`

export const FormContainer = styled.div``

export const Heading = styled(Typography)`
  font-size: 1.5em;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 2em;
`

export const NoTemplate = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 8, 0.5);
  text-align: right;
  span {
    color: #228bec;
    cursor: pointer;
  }
`

export const TotalUsers = styled.div`
  font-size: 1.1em;
  line-height: 1;
  font-weight: bold;
  margin-right: 1em;
`

export const LoadingButton = muiStyled(LoadingButtonComponent)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  textTransform: 'lowercase',
  '&:hover': {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    backgroundColor: '#d5d5d5'
  }
}))

export const NextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`
