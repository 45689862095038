import React from 'react'
import ParentMessageList from '../components/ParentMessageList'
import ParentList from '../components/ParentList'
import { useWebSocket } from 'app/WebSocketProvider'

import * as styles from './styles'

const CentreParentChat = () => {

  const { sendPresenceMessage, clearPresenceTimer, isSocketOpen } = useWebSocket()
  const [selectedChat, setSelectedChat] = React.useState('')
  const [selectedPill, setSelectedPill] = React.useState('open')
  const [searchValue, setSearchValue] = React.useState('')
  const [data, setData] = React.useState([]) //for setting parent list data
  const [nextUrl, setNextUrl] = React.useState(null) //for setting parent list next url
  const [hasMore, setHasMore] = React.useState(true) //for setting parent list has more

  React.useEffect(() => {
    if (selectedChat) {
      sendPresenceMessage(selectedChat)
    }
    return () => { clearPresenceTimer() }
  }, [selectedChat])

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        clearPresenceTimer()
      } else if (document.visibilityState === 'visible' && selectedChat) {
        sendPresenceMessage(selectedChat)
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [selectedChat, isSocketOpen])

  return (
    <styles.ListContainer>
      <ParentList
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        selectedPill={selectedPill}
        setSelectedPill={setSelectedPill}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        data={data}
        setData={setData}
        nextUrl={nextUrl}
        setNextUrl={setNextUrl}
        hasMore={hasMore}
        setHasMore={setHasMore}
      />
      <ParentMessageList
        selectedChat={selectedChat}
        selectedPill={selectedPill}
        searchValue={searchValue}
        setParentListData={setData}
        setParentListNextUrl={setNextUrl}
        setParentListHasMore={setHasMore}
      />
    </styles.ListContainer>
  )
}

export default CentreParentChat
