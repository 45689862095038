import styled, { css } from 'styled-components'

export const FormContainer = styled.div`
  flex: 0.4;
  display: flex;
  flex-direction: column;

  .inventory-input {
    *:not(svg) {
      font-size: 14px;
    }
  }

  .parent-selection div:first-child,
  .student-selection div:first-child {
    flex-grow: 1;
  }

  .student-selection {
    margin-top: 2em;
    margin-bottom: 1em;
  }
`

export const WidgetWithIcon = styled.div`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  flex-grow: 1;

  *:not(svg) {
    font-size: 14px;
  }

  div[class^='MuiInputBase-root'] {
    display: flex;
  }
`

export const NoUser = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 8, 0.5);
  text-align: right;
`

export const Button = styled.button`
  border: none;
  padding: unset;
  span {
    color: #228bec;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
      span {
        color: rgba(0, 0, 8, 0.5);
      }
    `};
`
