import { Box } from '@mui/material'
import React from 'react'

import { footer } from './styles'

import Loading from '../Loading'

export const tableOptions = (isLoading) => ({
  enableNestedDataAccess: '.',
  filter: false,
  search: false,
  sort: false,
  print: false,
  download: false,
  delete: false,
  viewColumns: false,
  customToolbar: null,
  responsive: 'vertical',
  selectableRowsHideCheckboxes: true,
  customFooter: () =>
    isLoading ? (
      <Box style={footer}>
        <Loading />
      </Box>
    ) : (
      <tfoot />
    ),
  rowsPerPage: Infinity,
  textLabels: {
    body: {
      noMatch: !isLoading && (
        <span style={{ color: 'rgba(0, 0, 8, 0.5)', fontSize: '18px' }}>
          No data!
        </span>
      )
    }
  }
})
