import isEmpty from 'lodash/isEmpty'

import { makeRequestUrl } from 'app/helpers'

import {
  apiEndpoints,
  validateUpdateRequest,
  validateDeleteRequest
} from './common'

import { post, patch, get, remove } from '../request'

const createMockPaperObj = ({
  subject,
  standard,
  board,
  thumbnail,
  sample_paper
}) => ({
  subject,
  standard,
  board,
  thumbnail,
  sample_paper
})

const createURL = (url, endPoint, queries) => {
  const baseUrl = apiEndpoints.mockTest
  const formattedUrl = `${baseUrl}/${endPoint}`
  return !isEmpty(url) ? url : makeRequestUrl(formattedUrl, queries)
}

const fetchMockPaper = async ({ url, queries } = {}) => {
  const requestUrl = createURL(url, 'mock_paper', queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching mock papers :- ', error.message)
    throw error
  }
}

const createMockPaper = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.mockTest}/mock_paper`,
      createMockPaperObj(data)
    )

    return response.data
  } catch (error) {
    console.log('error in creating mock papers :- ', error.message)
    throw error
  }
}

const updateMockPaper = async (data) => {
  validateUpdateRequest(data)
  const url = `${apiEndpoints.mockTest}/mock_paper/${data.uuid}/`
  try {
    const response = await patch(url, data)

    return response.data
  } catch (error) {
    console.log('error in updating paper :- ', error.message)
    throw error
  }
}

const updateMockPaperVisibleOnWebsite = async (data, visibility_on_website) => {
  validateUpdateRequest(data)
  const url = `${apiEndpoints.mockTest}/mock_paper/${data.uuid}/`
  try {
    const response = await patch(url, {
      visibility_on_website
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const deleteMockPaper = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.mockTest}/mock_paper/${id}/`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting paper :- ', error.message)
    throw error
  }
}

const fetchMockPricing = async ({ url, queries } = {}) => {
  const requestUrl = createURL(url, 'mock_paper_pricing_model', queries)
  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching pricing :- ', error.message)
    throw error
  }
}

const createMockPricing = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.mockTest}/mock_paper_pricing_model`,
      data
    )

    return response.data
  } catch (error) {
    console.log('error in creating pricing :- ', error.message)
    throw error
  }
}

const updateMockPricing = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.mockTest}/mock_paper_pricing_model/${data.uuid}/`,
      data
    )

    return response.data
  } catch (error) {
    console.log('error in updating pricing :- ', error.message)
    throw error
  }
}

const deleteMockPricing = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.mockTest}/mock_paper_pricing_model/${id}`)
      })
    )

    return ids
  } catch (error) {
    console.log('error in deleting pricing :- ', error.message)
    throw error
  }
}
const fetchMockTestReportCard = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
        `${apiEndpoints.mockTest}/mock_paper/report_card/dashboard/`,
        queries
      )

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: response.data.results.map((item) => ({
        ...item,
        created: item.created
      }))
    }
  } catch (error) {
    throw error
  }
}

const createMockTestReportCard = async (data) => {
  try {
    // Create an object of formData
    const formData = new FormData()
    // Update the formData object
    formData.append('file', data, data.name)
    const response = await post(
      `${apiEndpoints.mockTest}/mock_paper/report_card/generate-e2e/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export {
  fetchMockPaper,
  createMockPaper,
  updateMockPaper,
  updateMockPaperVisibleOnWebsite,
  deleteMockPaper,
  fetchMockPricing,
  createMockPricing,
  updateMockPricing,
  deleteMockPricing,
  createMockTestReportCard,
  fetchMockTestReportCard
}
