import React from 'react'
import * as styles from './styles'
import Avatar from '../Avatar'
import { navigationPaths } from 'app/config'
import { Tooltip } from '@mui/material'

const AvatarTitleDescription = (props) => {

  const {
    uuid,
    name,
    thumbnailUrl,
    centres,
    categories,
    isTitleClickable,
    color,
    avatarImageStyles = {},
    avatarTextStyles = {},
  } = props

  const centresString = centres?.join(' | ')
  const categoriesString = categories?.join(' | ')
  const description = (centresString && categoriesString) ? `${centresString} | ${categoriesString}` : centresString || categoriesString
  const truncatedDescription = description?.length > 30 ? `${description.substring(0, 30).trim()}...` : description

  return (
    <styles.Container>
      <Avatar
        name={name}
        thumbnailUrl={thumbnailUrl}
        avatarImageStyles={avatarImageStyles}
        avatarTextStyles={avatarTextStyles}
      />
      <styles.InnerContainer>
        {isTitleClickable ? (
          <styles.ClickableTitle href={`${navigationPaths.teacherdashboard}?teacher=${uuid}`}>
            {name}
          </styles.ClickableTitle>
        ) : (
          <styles.NonClickableTitle>
            {name}
          </styles.NonClickableTitle>
        )}
        <Tooltip title={description} arrow>
          <styles.Description>{truncatedDescription}</styles.Description>
        </Tooltip>
      </styles.InnerContainer>
    </styles.Container >
  )
}

export default AvatarTitleDescription