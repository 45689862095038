import dayjs from 'dayjs'
import { get, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { apiEndpoints, fetchTicketTypeOptions, getConfigValue } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import {
  tableColumnDefaultOption,
  commonAttributes,
  navigationPaths
} from 'app/config'
import constants, {
  FOLLOW_UP_DATE_MESSAGE,
  TICKET_STATUS_OPTIONS
} from 'app/constants'
import {
  fetchTicketData,
  updateTicketData
} from 'app/store/actions/communicationEntity'
import { selectTicketsData } from 'app/store/selectors'
import { validateNotEmpty, validateNotNil } from 'app/validators'

import { columns } from './config'
const Ticket = () => {
  const [ticketTypeOptions, setTicketTypeOptions] = useState([])
  const [ticketStatusOptions, setTicketStatusOptions] = useState([])
  const [isCreate, setIsCreate] = useState(false)

  const ticketData = useSelector(selectTicketsData)

  const [expandedRows, setExpandedRows] = React.useState({})
  const toggleRows = (rowsId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowsId]: !prevExpandedRows[rowsId]
    }))
  }

  React.useEffect(() => {
    ;(async () => {
      fetchTicketTypes()
      fetchTicketStatuses()
    })()
  }, [])

  const fetchTicketTypes = async () => {
    try {
      const response = await fetchTicketTypeOptions()
      if (response) {
        const optionsArray = response?.results?.[0]?.value

        const optionsMapping =
          optionsArray?.length > 0
            ? optionsArray.map((item) => {
                return {
                  uuid: item.key,
                  name: item.label,
                  showOnlyInFilter: item.show_only_in_filter
                }
              })
            : {}
        setTicketTypeOptions(optionsMapping)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error.toString())
    }
  }

  const fetchTicketStatuses = async () => {
    try {
      const response = await getConfigValue({ key: 'CRM_TICKET_STATUSES' })
      if (response) {
        const optionsArray = response

        const optionsMapping =
          optionsArray?.length > 0
            ? optionsArray.map((item) => {
                return {
                  uuid: item,
                  name: item,
                  showInCreate: item == 'Resolved' ? false : true
                }
              })
            : {}

        setTicketStatusOptions(optionsMapping)
      }
    } catch (error) {
      console.error('Failed to fetch data:', error.toString())
    }
  }

  const modifiedColumns = React.useMemo(() => {
    const updatedColumns = [
      {
        ...commonAttributes.uuid
      },
      ...columns.slice(0, 1),
      {
        name: 'user',
        label: 'Parent',
        widget: 'autocomplete',
        minCharactersToSearch: 10,
        modalEdit: true,
        viewOnlyInModal: true,
        defaultValue: () => {
          return new URLSearchParams(window.location.search).get('parent')
        },
        validate: validateNotNil,
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            if (Object.prototype.hasOwnProperty.call(item, 'user')) {
              const { uuid, first_name, last_name, phone_number } = item?.user

              return {
                uuid: uuid,
                name: `${phone_number} (${first_name} ${last_name})`
              }
            } else {
              return {
                uuid: item.uuid,
                name: item.name
              }
            }
          }
          if (Array.isArray(data) && !isEmpty(data)) {
            return [getItemStructure(data[0])]
          } else if (Object.prototype.hasOwnProperty.call(data, 'user')) {
            return [getItemStructure(data)]
          } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
            return data.results.map((item) => {
              return getItemStructure(item)
            })
          } else {
            return data.map((item) => {
              return getItemStructure(item)
            })
          }
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => {
            if (Object.prototype.hasOwnProperty.call(item, 'user')) {
              return item.user.uuid
            }
          }
          if (Object.prototype.hasOwnProperty.call(data, 'user')) {
            return getItemStructure(data)
          }
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'user',
          keyValue: 'uuid'
        },
        apiOptions: {
          method: 'get',
          url: apiEndpoints.parent
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'user_name',
          customBodyRender: (value) => {
            return value ? (
              <span>
                <a
                  href={`${navigationPaths.parentdashboard}?parent=${value?.user.uuid}`}
                >
                  {value?.user.full_name}
                </a>
              </span>
            ) : null
          }
        }
      },
      ...columns.slice(1, 3),
      {
        name: 'status',
        label: 'Ticket Status',
        widget: 'autocomplete',
        widgetFilter: true,
        valueLabelMappings: ticketStatusOptions,
        modalEdit: true,
        validate: validateNotEmpty,
        defaultValue: (data) => {
          return get(data, 'status') || ticketStatusOptions?.[0]?.uuid
        },
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return item && typeof item === 'string'
              ? { uuid: item, name: item }
              : item && (isCreate ? item.showInCreate : true)
              ? {
                  uuid: item.uuid,
                  name: item.name
                }
              : null
          }

          if (Array.isArray(data)) {
            return data?.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
            return data?.results?.map((item) => {
              return getItemStructure(item)
            })
          }

          return [getItemStructure(data)]
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => item
          return getItemStructure(data)
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'status',
          keyValue: 'uuid'
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'status',
          setCellProps: () => ({
            style: {
              minWidth: '240px',
              maxWidth: '240px'
            }
          })
        }
      },
      {
        name: 'type',
        label: 'Ticket Type',
        widget: 'autocomplete',
        widgetFilter: true,
        isValueKey: true,
        valueLabelMappings: ticketTypeOptions,
        modalEdit: true,
        editable: isCreate ? true : false,
        validate: validateNotEmpty,
        getStructuredValues: (data) => {
          const getItemStructure = (item) => {
            return item && typeof item === 'string'
              ? { uuid: item, name: item }
              : item && (isCreate ? !item?.showOnlyInFilter : true)
              ? {
                  uuid: item.uuid,
                  name: item.name
                }
              : null
          }

          if (Array.isArray(data)) {
            return data?.map((item) => {
              return getItemStructure(item)
            })
          } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
            return data?.results?.map((item) => {
              return getItemStructure(item)
            })
          }

          return [getItemStructure(data)]
        },
        getFormDataValue: (data) => {
          const getItemStructure = (item) => item
          return getItemStructure(data)
        },
        getOptionLabelKey: 'name',
        getOptionSelectedKey: 'uuid',
        resultStructure: {
          keyName: 'type',
          keyValue: 'uuid'
        },
        options: {
          ...tableColumnDefaultOption.options,
          filterKey: 'type',
          setCellProps: () => ({
            style: {
              minWidth: '240px',
              maxWidth: '240px'
            }
          })
        }
      },
      ...columns.slice(3, 4),
      {
        name: 'description',
        label: 'ticket description',
        validate: validateNotEmpty,
        modalEdit: true,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          setCellProps: () => ({
            style: {
              minWidth: '400px',
              maxWidth: '400px',
              whiteSpace: 'pre-wrap'
            }
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = ticketData?.data[dataIndex]?.description
            const truncatedData = data?.slice(0, 100)
            const isTruncated = data?.length > truncatedData?.length
            const isExpanded = expandedRows[dataIndex]
            const displayData = isExpanded ? data : truncatedData

            return (
              <div>
                <span>
                  {!displayData
                    ? 'N/A'
                    : displayData?.length < 100
                    ? displayData
                    : !isExpanded
                    ? displayData + '...'
                    : displayData}
                  <br />
                  {isTruncated && (
                    <div style={{ textAlign: 'right' }}>
                      <a
                        href="#"
                        onClick={() => toggleRows(dataIndex)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {isExpanded ? 'show less' : 'show more'}
                      </a>
                    </div>
                  )}
                </span>
              </div>
            )
          }
        }
      },
      ...columns.slice(4),
      {
        name: 'resolution_summary',
        label: 'resolution summary',
        validate: validateNotEmpty,
        modalEdit: true,
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          setCellProps: () => ({
            style: {
              minWidth: '400px',
              maxWidth: '400px',
              whiteSpace: 'pre-wrap'
            }
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = ticketData?.data[dataIndex]?.resolution_summary
            const truncatedData = data?.slice(0, 100)
            const isTruncated = data?.length > truncatedData?.length
            const isExpanded = expandedRows[dataIndex]
            const displayData = isExpanded ? data : truncatedData

            return (
              <div>
                <span>
                  {!displayData
                    ? 'N/A'
                    : displayData?.length < 100
                    ? displayData
                    : !isExpanded
                    ? displayData + '...'
                    : displayData}
                  <br />
                  {isTruncated && (
                    <div style={{ textAlign: 'right' }}>
                      <a
                        href="#"
                        onClick={() => toggleRows(dataIndex)}
                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {isExpanded ? 'show less' : 'show more'}
                      </a>
                    </div>
                  )}
                </span>
              </div>
            )
          }
        },
        conditionalRender: (data) =>
          get(data, 'status') === TICKET_STATUS_OPTIONS.RESOLVED
      },
      {
        name: 'follow_up_text',
        label: 'follow_up_text',
        widget: 'string',
        modalEdit: true,
        viewOnlyInModal: true,
        disabledInFormOnCreate: true,
        message: {
          text: FOLLOW_UP_DATE_MESSAGE,
          style: {
            marginLeft: '10px',
            marginBottom: '10px',
            color: 'black',
            fontSize: '0.9rem',
            fontFamily: 'Avenir'
          }
        },
        conditionalRender: (data) =>
          dayjs(get(data, 'follow_up_date')).isAfter(dayjs()),
        options: {
          ...tableColumnDefaultOption.options,
          display: false
        }
      }
    ]
    return updatedColumns
  }, [
    ticketData,
    expandedRows,
    ticketTypeOptions,
    ticketStatusOptions,
    isCreate
  ])

  return (
    <>
      <BaseEntity
        entity={constants.TICKET}
        label={constants.TICKET_LABEL}
        columns={modifiedColumns}
        data={ticketData}
        fetchData={fetchTicketData}
        updateEntityData={updateTicketData}
        setIsCreateClicked={setIsCreate}
        disableDelete
      />
    </>
  )
}

export default Ticket
