import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { get, isEmpty, set } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { getConfigValue } from 'api'
import BaseForm from 'app/components/BaseForm'
import { commonAttributes, programTypeList } from 'app/config'
import { convertArrayToObject } from 'app/helpers'
import { validateNotEmpty } from 'app/validators'

import { columns } from '../../components/terminationConfig'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  invoiceTextsContainer: {
    textAlign: 'center'
  },
  invoiceText: {
    textTransform: 'capitalize'
  },
  editor: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: theme.spacing(1)
  }
}))

const headerTextMessage =
  '**Termination date is the new end date on which the enrolments need to be terminated.'

const errorInitialState = {
  isError: false,
  errorMessage: '',
  fieldErrors: {}
}

const EnrolmentForm = (props) => {
  const [terminationData, setTerminationData] = React.useState({
    reason: '',
    notes: ''
  })
  const [currentEditingData, setCurrentEditingData] = React.useState({})
  const [error, setError] = React.useState(errorInitialState)
  const [terminationReasons, setTerminationReasons] = useState([])
  const { isModalOpen, closeModal, onSaveClick, action, enrolmentData } = props

  useEffect(() => {
    ;(async () => {
      try {
        const reasons = await getConfigValue({
          key: 'ALFRED_TERMINATION_REASONS',
          app_name: 'alfred'
        })
        if (Array.isArray(reasons)) {
          reasons.push('Other')
          setTerminationReasons(reasons)
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  const modifiedColumns = React.useMemo(() => {
    let modifiedColumnsList = [
      {
        name: 'reason',
        label: 'Reason for termination',
        widget: 'select',
        validate: validateNotEmpty,
        valueLabelMappings: convertArrayToObject(terminationReasons)
      },
      ...columns
    ]

    if (
      enrolmentData?.type === 'program' ||
      programTypeList.includes(enrolmentData?.program?.type)
    ) {
      modifiedColumnsList = [
        {
          ...commonAttributes.date,
          name: 'end_date',
          label: 'Termination Date',
          widget: 'datepicker',
          minDate: dayjs().format('YYYY-MM-DD'),
          maxDate: enrolmentData?.end_date,
          validate: validateNotEmpty
        },
        ...modifiedColumnsList
      ]
    }

    return modifiedColumnsList
  }, [enrolmentData, terminationReasons])

  const handleFormData = (newFormData, modifiedColumns, elements) => {
    modifiedColumns.forEach((column) => {
      if (!column.disabledInForm) {
        const element = get(elements, column.formDataKey || column.name)
        if (element !== null && element !== undefined) {
          if (element instanceof HTMLElement) {
            if (element.type !== 'file') {
              set(newFormData, column.formDataKey || column.name, element.value)
            }
          } else {
            if (!isEmpty(column.resultStructure)) {
              if (column.widget === 'autocomplete') {
                set(
                  newFormData,
                  column.resultStructure.keyName,
                  typeof element === 'string'
                    ? element
                    : column.getFormDataValue(element)
                )
              } else {
                set(
                  newFormData,
                  column.resultStructure.keyName,
                  typeof element === 'string'
                    ? element
                    : get(element, column.resultStructure.keyValue)
                )
              }
            } else {
              set(newFormData, column.formDataKey || column.name, element)
            }
          }
        }
      }
    })
  }

  return (
    <>
      {action === 'terminate_enrolment' ? (
        <>
          <BaseForm
            formData={terminationData}
            setFormData={setTerminationData}
            onSaveClick={(val) => onSaveClick(val, action)}
            isModalOpen={isModalOpen}
            currentEditingData={currentEditingData}
            error={error}
            columns={modifiedColumns}
            handleFormData={handleFormData}
            handleModalClose={closeModal}
            headerText={headerTextMessage}
          />
        </>
      ) : null}
    </>
  )
}

EnrolmentForm.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  courseBatches: PropTypes.object.isRequired,
  enrolmentData: PropTypes.object.isRequired
}

export default EnrolmentForm
