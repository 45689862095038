import React from 'react'
import * as styles from './styles'
import { downloadButton, MessageFileText, pdfIcon, FileNotFoundText } from '../../../constants'

const MessageFile = ({ text, contentMedia }) => {

  return (
    <styles.Container>
      {text}
      <styles.InnerContainer>
        {contentMedia ?
          <div>
            <styles.FileContainer>
              <styles.Icon src={pdfIcon} />
              <styles.Text>{MessageFileText}</styles.Text>
            </styles.FileContainer>
            <a
              href={contentMedia}
              target="_blank"
              download>
              <styles.Icon src={downloadButton} alt="click here" />
            </a>
          </div> :
          <div>{FileNotFoundText}</div>
        }
      </styles.InnerContainer>
    </styles.Container>
  )
}

export default MessageFile