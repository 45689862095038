import styled from 'styled-components'

export const ActionContainer = styled.div`
  display: flex;

  width: max-content;
  position: fixed;
  bottom: 0;
  right: -10px;
  z-index: 1;

  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px -4px 8px rgb(0 0 0 / 20%);
  border-radius: 8px 8px 0px 0px;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 10px;

  .MuiButton-contained {
    border-radius: 2em;
    margin-top: 0;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0px 2.5px;
  }
`

export const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0;
`
export const EmptyCartContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const EmptyCartSubcontainer = styled.div``

export const CartImageContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CartImage = styled.img`
  width: 100px;
`

export const EmptyCartHeading = styled.div`
  font-family: AvenirDemi;
  text-align: center;
`

export const EmptyCartSubheading = styled.div``
