/* eslint-disable react/prop-types */
import { Badge, Button, IconButton } from '@material-ui/core'
import { ShoppingCart } from '@material-ui/icons'
import React from 'react'

import * as Styles from './styles'

import { batchTypes, batchTypesMap } from '../../../../constants'

const Actions = ({
  isDisabled,
  onDemoBookClick,
  onClassBookClick,
  onMembershipPreviewBookClick,
  selectedBatchType,
  onCartClick,
  cartItemsLength = 4,
  onMembershipBookClick,
  onBookExplorationClick,
  onBookExcelClick,
  isNewFlow
}) => {
  return (
    <Styles.ActionContainer>
      {/* Cart Button */}
      <IconButton onClick={onCartClick}>
        <Badge badgeContent={cartItemsLength} color="primary">
          <ShoppingCart color="action" />
        </Badge>
      </IconButton>

      {isNewFlow ? null : selectedBatchType === batchTypesMap.explore ? (
        <Button
          color="primary"
          disabled={isDisabled}
          variant="contained"
          onClick={onDemoBookClick}>
          Book Trial session
        </Button>
      ) : selectedBatchType === batchTypesMap.exploration ? (
        <Button
          color="primary"
          disabled={isDisabled}
          variant="contained"
          onClick={onBookExplorationClick}>
          Book Explore session
        </Button>
      ) : selectedBatchType === batchTypesMap.excel ? (
        <Button
          color="primary"
          disabled={isDisabled}
          variant="contained"
          onClick={onBookExcelClick}>
          Book Excel session
        </Button>
      ) : selectedBatchType === batchTypesMap.class ? (
        <Button
          color="primary"
          disabled={isDisabled}
          variant="contained"
          onClick={onClassBookClick}>
          Book without membership
        </Button>
      ) : (
        <>
          <Button
            color="primary"
            disabled={isDisabled}
            variant="contained"
            onClick={onMembershipBookClick}>
            Book with membership
          </Button>
          <Button
            color="primary"
            disabled={isDisabled}
            variant="contained"
            onClick={onMembershipPreviewBookClick}>
            Membership Preview
          </Button>
        </>
      )}
    </Styles.ActionContainer>
  )
}

export default Actions
