import React from 'react'

import { apiEndpoints } from 'api'
import {
  commonAttributes,
  countList,
  tableColumnDefaultOption
} from 'app/config'
import {
  validateIntFee,
  validateMobile,
  validateNotEmpty,
  validatePostalCode
} from 'app/validators'

import { ParentOrChildrenWidget } from './ParentOrChildrenWidget/index'

import { columns as commonColumns } from '../common'

const property_paths = ['user.uuid', 'user.phone_number']

export const columns = [
  ...commonColumns.filter((item) => property_paths.includes(item.name)),

  {
    ...commonColumns.find((item) => ['user.first_name'].includes(item.name)),
    viewOnlyInModal: true,
    modalEdit: true,
    options: {
      ...commonColumns.find((item) => ['user.first_name'].includes(item.name))
        .options,
      display: false
    }
  },
  {
    ...commonColumns.find((item) => ['user.last_name'].includes(item.name)),
    viewOnlyInModal: true,
    modalEdit: true,
    options: {
      ...commonColumns.find((item) => ['user.first_name'].includes(item.name))
        .options,
      display: false
    }
  },
  {
    ...commonColumns.find((item) => ['user.email_address'].includes(item.name)),
    optional: false
  },
  {
    ...commonColumns.find((item) => ['user'].includes(item.name)),
    modalEdit: false,
    editable: false,
    disabledInFormOnEdit: true,
    disabledInFormOnCreate: true
  },
  {
    name: 'user.source',
    label: 'parent source',
    widget: 'autocomplete',
    optional: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
            name: item,
            uuid: item
          }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'user.source',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=PARENT_SOURCES`
    },
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  },
  {
    name: 'user.address_detail.postal_address',
    label: 'Postal address',
    optional: true,
    modalEdit: true,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      display: false
    }
  },
  {
    name: 'user.address_detail.postal_code',
    label: 'Pincode',
    optional: true,
    modalEdit: true,
    validate: validatePostalCode,
    options: {
      ...tableColumnDefaultOption.options,
      display: false
    }
  },
  {
    name: 'additional_information.NO_OF_CHILDREN',
    label: 'Number of children',
    widget: 'select',
    valueLabelMappings: countList,
    optional: true,
    modalEdit: true,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      display: false
    }
  },
  {
    name: 'additional_information.DAYS_PREFERRED',
    label: 'Days preferred for classes',
    widget: 'autocomplete',
    optional: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
            name: item,
            uuid: item
          }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value
          ?.filter((el) => el.key === 'DAYS_PREFERRED')[0]
          ?.options?.map((item) => {
            return getItemStructure(item)
          })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'additional_information.DAYS_PREFERRED',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_PARENT&app_name=alfred`
    },
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  },
  {
    name: 'additional_information.PARENTS_EMPLOYMENT',
    label: 'Are both parents employed?',
    widget: 'autocomplete',
    optional: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
            name: item,
            uuid: item
          }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value
          ?.filter((el) => el.key === 'PARENTS_EMPLOYMENT')[0]
          ?.options?.map((item) => {
            return getItemStructure(item)
          })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'additional_information.PARENTS_EMPLOYMENT',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_PARENT&app_name=alfred`
    },
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  },
  {
    name: 'additional_information.PICKUP_DROP',
    label: 'Pickup/ Drop person',
    widget: 'autocomplete',
    optional: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
            name: item,
            uuid: item
          }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value
          ?.filter((el) => el.key === 'PICKUP_DROP')[0]
          ?.options?.map((item) => {
            return getItemStructure(item)
          })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'additional_information.PICKUP_DROP',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_PARENT&app_name=alfred`
    },
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  },
  {
    name: 'additional_information.COMMUTE',
    label: 'Commute method',
    widget: 'autocomplete',
    optional: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
            name: item,
            uuid: item
          }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results[0]?.value
          ?.filter((el) => el.key === 'COMMUTE')[0]
          ?.options?.map((item) => {
            return getItemStructure(item)
          })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid || ''
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'additional_information.COMMUTE',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=CUSTOMER_DATA_PARENT&app_name=alfred`
    },
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  },
  {
    name: 'students',
    label: 'children data',
    viewOnlyInModal: true,
    modalEdit: true,
    optional: true,
    widget: ParentOrChildrenWidget,
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  },
  {
    name: 'centre_preference',
    label: 'Preferred Centre',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    defaultValue: () =>
      new URLSearchParams(window.location.search).get('centre'),
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
            uuid: item.uuid,
            name: item.name
          }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'centre_preference',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.centre
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre_preference',
      customBodyRender: (value) => (value ? <span>{value?.name}</span> : null)
    }
  },
  {
    name: 'user.coin_summary.remaining_coins',
    label: 'Coins Left',
    modalEdit: false,
    disabledInForm: true,
    validate: validateIntFee,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      customBodyRender: (value) => {
        return <span>{value}</span>
      }
    }
  },
  {
    name: 'user.active_subscription.plan.name',
    label: 'Plan',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'plan',
      customBodyRender: (value) => {
        return value ? <span>{value}</span> : <span>Free</span>
      }
    }
  },
  {
    ...commonAttributes.date,
    modalEdit: false,
    disabledInForm: true,
    name: 'user.active_subscription.start_date',
    label: 'Membership start date',
    options: {
      ...commonAttributes.date.options,
      filterKey: 'plan_purchase_date',
      fixedColumn: false
    }
  }
]
