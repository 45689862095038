import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
// import { PersistGate } from 'redux-persist/integration/react'

import App from 'app/App'
import { store } from 'app/store/store'

// import { store, persistor } from 'app/store/store'
import * as serviceWorker from './serviceWorker'
import './index.css'

// configure sentry
import './sentry'

smoothscroll.polyfill()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <App />
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// registering service worker for faster load (https://github.com/facebook/create-react-app/issues/5359)
serviceWorker.unregister()
