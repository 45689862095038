import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2em;
`

export const NoTemplate = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 8, 0.5);
  text-align: right;
  span {
    color: #228bec;
    cursor: pointer;
  }
`

export const FormFieldContainer = styled.div``

export const ParametersContainer = styled.div``

export const ParameterFieldWithErrorContainer = styled.div`
  margin-bottom: 8px;
`

export const ParameterFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 0;
`

export const MessageContainer = styled.div`
  margin-top: 1.5em;
`
export const MessageLabel = styled.div`
  margin-bottom: 0.5em;
  color: rgba(0, 0, 8, 0.5);
`
export const MessageContent = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 0.5em;
  padding: 1em;
  width: 100%;
  min-height: 100px;

  span {
    font-weight: bold;
    text-decoration: underline;
  }
`
