import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import React from 'react'

const defaultToolbarStyles = (theme) => ({
  iconButton: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  additionalElementContainer: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  }
})

const CustomToolbar = (props) => {
  const handleClick = () => {
    if (props.onCreateClick) {
      props.onCreateClick()
    }
  }

  return (
    <>
      {props.onCreateClick ? (
        <Tooltip title={'Create'}>
          <IconButton
            className={props.classes.iconButton}
            data-testid="create-button"
            onClick={handleClick}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <div className={props.classes.additionalElementContainer}>
        {props.additionalElement}
      </div>
    </>
  )
}

CustomToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  onCreateClick: PropTypes.func,
  additionalElement: PropTypes.node
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
  CustomToolbar
)
