import React from 'react'
import { useSelector } from 'react-redux'

import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import { tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import { fetchBulkMessagingData } from 'app/store/actions/communicationEntity'
import {
  dispatchUpdateSendMessageModalState,
  dispatchHandleFetchDeliveryStatsData
} from 'app/store/dispatcher'
import { selectIsCreateMessageTemplateModalOpen } from 'app/store/selectors'

import { DeliveryStats } from './components/DeliveryStats'
import { SendBulkMessage } from './components/SendBulkMessage'
import { startingColumns, endingColumns, deliveryTypes } from './config'
import { Link } from './styles'

const BulkMessaging = () => {
  const isCreateMessageTemplateModalOpen = useSelector(
    selectIsCreateMessageTemplateModalOpen
  )
  const bulkMessagingData = useSelector(
    (state) => state.communicationEntityReducer.bulkMessaging.data
  )

  const CustomToolbarElement = (
    <Button onClick={() => dispatchUpdateSendMessageModalState(true)}>
      send bulk message
    </Button>
  )

  const columns = React.useMemo(() => {
    const onClickDeliveredStats = (id) => {
      dispatchHandleFetchDeliveryStatsData(id, deliveryTypes.DELIVERED)
    }

    const onClickFailedStats = (id) => {
      dispatchHandleFetchDeliveryStatsData(id, deliveryTypes.FAILED)
    }

    const onClickPendingStats = (id) => {
      dispatchHandleFetchDeliveryStatsData(id, deliveryTypes.PENDING)
    }

    return [
      ...startingColumns,
      {
        name: 'delivered',
        label: 'Delivered',
        options: {
          ...tableColumnDefaultOption,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const value = bulkMessagingData.data[dataIndex]?.delivered

            if (value === 0) return <span>{value}</span>

            return (
              <Link
                href="#"
                onClick={() =>
                  onClickDeliveredStats(bulkMessagingData.data[dataIndex]?.uuid)
                }>
                {value}
              </Link>
            )
          }
        }
      },
      {
        name: 'failed',
        label: 'Failed',
        options: {
          ...tableColumnDefaultOption,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const value = bulkMessagingData.data[dataIndex]?.failed

            if (value === 0) return <span>{value}</span>

            return (
              <Link
                href="#"
                onClick={() =>
                  onClickFailedStats(bulkMessagingData.data[dataIndex]?.uuid)
                }>
                {value}
              </Link>
            )
          }
        }
      },
      {
        name: 'pending',
        label: 'Pending',
        options: {
          ...tableColumnDefaultOption,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const value = bulkMessagingData.data[dataIndex]?.pending

            if (value === 0) return <span>{value}</span>

            return (
              <Link
                href="#"
                onClick={() =>
                  onClickPendingStats(bulkMessagingData.data[dataIndex]?.uuid)
                }>
                {value}
              </Link>
            )
          }
        }
      },
      ...endingColumns
    ]
  }, [bulkMessagingData])

  return (
    <>
      <BaseEntity
        label={constants.BULK_MESSAGING_LABEL}
        entity={constants.BULK_MESSAGING}
        fetchData={fetchBulkMessagingData}
        data={bulkMessagingData}
        columns={columns}
        readOnly
        CustomToolbarElement={CustomToolbarElement}
      />
      <SendBulkMessage />
      <DeliveryStats />
    </>
  )
}

export default BulkMessaging
