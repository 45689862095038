import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { isEmpty } from 'lodash'
import React from 'react'

import { apiEndpoints } from 'api'
import {
  batchTypesMap,
  commonAttributes,
  statuses,
  tableColumnDefaultOption
} from 'app/config'
import { validateNotEmpty, validateCount, validateIntFee } from 'app/validators'

import { BatchActivitiesWidget } from './BatchActivitiesWidget'

dayjs.extend(customParseFormat)

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    ...commonAttributes.teacher,
    name: 'teacher_name',
    label: 'teacher',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    }
  },
  {
    name: 'course',
    label: 'Course',
    widget: 'autocomplete',
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: !isEmpty(item.name)
            ? item.name
            : `${item.subjects.map((b) => b.name).join(', ')} | ${
                item.subjects[0]?.subject_category?.type === 'academic'
                  ? `${item.standards
                      .map((b) => b.name)
                      .join(', ')} | ${item.boards
                      .map((b) => b.name)
                      .join(', ')}`
                  : `${item.min_age} - ${item.max_age}`
              }`
        }
      }

      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      }
      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'course',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.course
    },
    options: {
      display: 'false'
    },
    editable: false
  },
  {
    name: 'course.subjects',
    label: 'Course Subject',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'subjects',
      customBodyRender: (value) => (
        <span>
          {Array.isArray(value) && value.map((item) => item.name).join(', ')}
        </span>
      )
    }
  },
  {
    ...commonAttributes.standard,
    name: 'course.standards',
    label: 'Course Standard',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'standards',
      customBodyRender: (value) => (
        <span>
          {Array.isArray(value) && value.map((item) => item.name).join(', ')}
        </span>
      )
    }
  },
  {
    name: 'course.boards',
    label: 'course boards',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'course.boards',
      customBodyRender: (value) => (
        <span>
          {Array.isArray(value) && value.map((item) => item.name).join(', ')}
        </span>
      )
    },
    disabledInForm: true
  },
  {
    editable: false,
    ...commonAttributes.centre
  },
  {
    name: 'batch_activities',
    label: '',
    viewOnlyInModal: true,
    modalEdit: true,
    widget: BatchActivitiesWidget,
    options: {
      ...tableColumnDefaultOption.options,
      display: false,
      disableFilter: true
    }
  },
  {
    name: 'type',
    label: 'Batch Type',
    widget: 'select',
    widgetFilter: true,
    validate: validateNotEmpty,
    valueLabelMappings: batchTypesMap,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'type',
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    },
    editable: false
  },
  {
    ...commonAttributes.level,
    editable: false
  },
  {
    name: 'status',
    label: 'Batch Status',
    widget: 'select',
    widgetFilter: true,
    disabledInForm: true,
    validate: validateNotEmpty,
    valueLabelMappings: statuses,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'status'
    }
  },
  {
    name: 'enrolment_count',
    label: 'Enrolment Count',
    validate: validateCount,
    editable: false,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'enrolment_capacity',
    label: 'Enrolment Capacity',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'coins_per_session',
    label: 'Coins per session',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'coins_per_month',
    label: 'Coins per month',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  },
  {
    name: 'fee',
    label: 'Batch fees',
    validate: validateIntFee,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'fee'
    }
  },
  {
    name: 'single_session_capacity',
    label: 'Extra Capacity',
    validate: validateCount,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true
    }
  }
]
