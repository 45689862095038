import React from 'react'
import { useSelector } from 'react-redux'

import { targetAudienceSelectionModes } from 'app/config'
import { dispatchUpdateExcludedUsers } from 'app/store/dispatcher'
import { selectExcludedUsers } from 'app/store/selectors'

import { SelectedUsersBase } from '../Base'

const ExcludedUsers = ({ userType }) => {
  const excludedUsers = useSelector(selectExcludedUsers)

  return (
    <SelectedUsersBase
      userType={userType}
      data={excludedUsers}
      title="Users Excluded"
      dispatchUpdateUsers={dispatchUpdateExcludedUsers}
      selectionMode={targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION}
    />
  )
}

export { ExcludedUsers }
