import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import SlidingPane from 'react-sliding-pane'

import 'react-sliding-pane/dist/react-sliding-pane.css'
import './slider.css'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'
import CloseIcon from 'assets/images/close.svg'

const Slider = (props) => {
  const [isPaneOpen, setIsPaneOpen] = useState(false)
  const isMobile = false

  const clickHandler = () => {
    setIsPaneOpen(true)
  }

  useEffect(() => {
    const handler = (data) => {
      if (props.blockType === data?.blockOpenType) {
        if (props.childComponent.props.userInfo[data.key] === data?.value) {
          setIsPaneOpen(true)
        }
      }
    }
    eventEmitter.addListener(constants.SLIDER_OPENER, handler)
  }, [props.isPaneOpen, props.childComponent])

  const handleClose = () => {
    if (props.closePanel) {
      props.closePanel()
    }
    if (props?.onClose) {
      props.onClose()
    }
    setIsPaneOpen(false)
  }

  const closeSlider = () => {
    handleClose()
  }

  return (
    <>
      <div
        className="placeholder"
        onClick={clickHandler}
        style={props.fullWidth === true ? { width: '100%' } : null}>
        {props.placeholder}
      </div>
      <SlidingPane
        isOpen={isPaneOpen}
        width={isMobile ? '100vw' : props.width ? props.width : '65%'}
        className="slider-custom"
        hideHeader={props.hideHeader || false}
        closeIcon={
          <div>
            <img src={CloseIcon} alt="close-icon" />
          </div>
        }
        onRequestClose={handleClose}>
        {React.isValidElement(props.childComponent)
          ? React.cloneElement(props.childComponent, { closeSlider })
          : props.childComponent}
      </SlidingPane>
    </>
  )
}

Slider.propTypes = {
  placeholder: PropTypes.node.isRequired,
  childComponent: PropTypes.node.isRequired,
  data: PropTypes.any,
  isPaneOpen: PropTypes.bool,
  blockType: PropTypes.any,
  closePanel: PropTypes.func,
  onClose: PropTypes.func,
  width: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideHeader: PropTypes.bool
}

export default Slider
