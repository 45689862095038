import styled from 'styled-components'

export const LoadingView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const LoadingViewText = styled.span`
  font-size: 1.4rem;
  font-family: AvenirDemi;
  margin-bottom: 40px;
`

export const ErrorModalTitle = styled.span`
  font-family: AvenirDemi;
`

export const StatusIcon = styled.img`
  height: 30px;
  width: auto;
  cursor: pointer;
`
