import { FormControlLabel } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState, useEffect } from 'react'

import { getSubjectCurriculum } from 'api/journey'
import {
  Checkbox,
  Select,
  TextField
} from 'app/components/generic/FormElements'
import { validateNotNil } from 'app/validators'

import { transformLevelOptions } from '../../transformer'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  nudge: {
    color: theme.palette.error.main
  }
}))

const DiscoveryReportForm = (props) => {
  const classes = useStyles()
  const { formData } = props
  const { errors, values, setFieldValue } = useFormikContext()

  const discoverySuccessfulOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]
  const [levelOptions, setLevelOptions] = useState([])
  const [milestoneOptions, setMilestoneOptions] = useState([])
  useEffect(() => {})

  useEffect(() => {
    ;(async () => {
      const subjectCuricullum = await getSubjectCurriculum({
        subjectId: formData?.subject_uuid
      })

      setLevelOptions(transformLevelOptions(subjectCuricullum?.subject_levels))
    })()
  }, [])
  useEffect(() => {
    if (levelOptions.length > 0) {
      setFieldValue('level', levelOptions[0].value, false)
      setMilestoneOptions(levelOptions[0]?.milestones)
      setFieldValue('milestone', levelOptions[0]?.milestones[0]?.value, false)
    }
  }, [levelOptions])

  const isDiscoverySuccessfulChangeHandler = (event) => {
    const selectedDiscoverySuccessfulValue = event.target.value
    setFieldValue('is_discovery_successful', selectedDiscoverySuccessfulValue)
  }

  const levelChangeHandler = (event) => {
    const selectedLevelValue = event.target.value
    setFieldValue('level', selectedLevelValue)

    const selectedLevel = levelOptions.find(
      (level) => level.value === selectedLevelValue
    )
    setMilestoneOptions(selectedLevel?.milestones)
  }

  const milestoneChangeHandler = (event) => {
    const selectedMilestoneValue = event.target.value
    setFieldValue('milestone', selectedMilestoneValue)
  }

  const checkboxHandler = (event) => {
    const checked = event.target.checked
    setFieldValue('spoken_to_parent', checked)
  }

  const checkboxValidator = (value) => {
    if (!value) return 'Please check the box to proceed'
  }

  const formElement = React.useMemo(() => {
    return (
      <>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}
          required>
          <TextField
            name={'student_name'}
            label={'Student Name'}
            variant="outlined"
            defaultValue={formData.student_name}
            disabled
            required
          />
          {!isEmpty(errors.student_name) && (
            <FormHelperText>{errors.student_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}
          required>
          <TextField
            name="subject_name"
            label={'Subject'}
            variant="outlined"
            required
            defaultValue={formData.subject_name}
            disabled
          />
          {!isEmpty(errors.subject_name) && (
            <FormHelperText>{errors.subject_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.teacher_name) ? { error: true } : null)}
          required>
          <TextField
            name={'teacher_name'}
            label={'Teacher'}
            variant="outlined"
            defaultValue={formData?.teacher_name}
            disabled
            required
          />
          {!isEmpty(errors.teacher_name) && (
            <FormHelperText>{errors.teacher_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.is_discovery_successful)
            ? { error: true }
            : null)}
          required>
          <InputLabel>{'Is discovery successful?'}</InputLabel>
          <Select
            name={'is_discovery_successful'}
            defaultValue={null}
            label={'Is discovery successful?'}
            validate={validateNotNil({ isRequired: true })}
            onChange={isDiscoverySuccessfulChangeHandler}>
            {discoverySuccessfulOptions?.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {!isEmpty(errors.is_discovery_successful) && (
            <FormHelperText>{errors.is_discovery_successful}</FormHelperText>
          )}
        </FormControl>

        {values?.is_discovery_successful === true &&
        formData?.progress_enabled === true ? (
          <>
            <FormControl
              variant="outlined"
              {...(!isEmpty(errors.level) ? { error: true } : null)}
              required>
              <InputLabel>{'Level'}</InputLabel>
              <Select
                name={'level'}
                defaultValue={values?.level ?? ''}
                label={'Level'}
                validate={validateNotNil({ isRequired: true })}
                onChange={levelChangeHandler}>
                {levelOptions?.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!isEmpty(errors.level) && (
                <FormHelperText>{errors.level}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              variant="outlined"
              {...(!isEmpty(errors.milestone) ? { error: true } : null)}
              required>
              <InputLabel>{'Milestone'}</InputLabel>
              <Select
                name={'milestone'}
                defaultValue={values?.milestone ?? ''}
                label={'Milestone'}
                validate={validateNotNil({ isRequired: true })}
                onChange={milestoneChangeHandler}>
                {milestoneOptions?.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!isEmpty(errors.milestone) && (
                <FormHelperText>{errors.milestone}</FormHelperText>
              )}
            </FormControl>
          </>
        ) : null}
        {values?.is_discovery_successful === false ? (
          <FormControl
            className={classes.formControl}
            {...(!isEmpty(errors.spoken_to_parent) ? { error: true } : null)}>
            <FormControlLabel
              name="spoken_to_parent"
              label="I have spoken to the parent that the subscription has not been unlocked yet.*"
              control={
                <Checkbox
                  label="I have spoken to the parent that the subscription has not been unlocked yet.*"
                  required
                  color="primary"
                  validate={checkboxValidator}
                  onChange={checkboxHandler}
                  defaultValue={formData?.spoken_to_parent ?? false}
                />
              }
            />
            {!isEmpty(errors.spoken_to_parent) && (
              <FormHelperText>{errors.spoken_to_parent}</FormHelperText>
            )}
          </FormControl>
        ) : null}
      </>
    )
  }, [formData, errors, values, levelOptions, milestoneOptions])

  return <>{formElement}</>
}

export default DiscoveryReportForm
