import { tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    name: 'student.name',
    label: 'Student Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_name'
    }
  },
  {
    name: 'student.phone_number',
    label: 'Student Number',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'student_phone_number'
    }
  },
  {
    name: 'teacher.name',
    label: 'Teacher Name',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_name'
    }
  },
  {
    name: 'teacher.phone_number',
    label: 'Teacher Number',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'teacher_phone_number'
    }
  },
  {
    name: 'standards',
    label: 'Standards',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'standards'
    }
  },
  {
    name: 'month',
    label: 'Month',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'month'
    }
  },
  {
    name: 'created',
    label: 'Created At',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'created'
    }
  },
  {
    name: 'author',
    label: 'Uploaded By',
    disabledInForm: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'author'
    }
  }
]
