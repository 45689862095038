import { takeEvery, takeLatest, call } from 'redux-saga/effects'

import {
  createBoard,
  createCourse,
  createEnrolment,
  createTrailSessions,
  createStandard,
  createSubject,
  createSubjectCategory,
  createDemoClassBooking,
  deleteBoard,
  deleteCourse,
  deleteEnrolment,
  deleteTrialSessions,
  deleteStandard,
  deleteSubject,
  deleteSubjectCategory,
  fetchBoard,
  fetchCourse,
  fetchTrialSessions,
  fetchEnrolment,
  fetchAttendance,
  fetchStandard,
  fetchSubject,
  fetchSubjectCategory,
  fetchBatch,
  deleteBatch,
  fetchInventorySchedules,
  fetchSchoolAttendance,
  updateBatch,
  updateBoard,
  updateCourse,
  updateTrialSessions,
  updateEnrolment,
  updateStandard,
  updateSubject,
  updateSubjectCategory,
  updateAttendance,
  createTrialSessions,
  fetchPastAttendance,
  fetchFutureAttendance,
  fetchmakeUp,
  createClassBooking,
  fetchExploreProgressData,
  fetchDemoSchedule,
  programEnrolment,
  fetchExploreSessionEnrolments,
  fetchExploreEnrolment,
  fetchExcelEnrolment
} from 'api'
import {
  createOpendaySchedule,
  createOpportunities,
  fetchOpendaySchedule,
  fetchOpportunities,
  updateOpendaySchedule,
  updateOpportunities
} from 'api/schedules'
import {
  createOpendaySlots,
  deleteOpendaySlots,
  fetchOpendaySlots,
  updateOpendaySlots
} from 'api/slots'
import constants from 'app/constants'
import { eventEmitter, eventAlert } from 'app/helpers'
import {
  fetchBoardData,
  fetchBoardDataSuccess,
  fetchCourseData,
  fetchCourseDataSuccess,
  fetchTrialSessionsData,
  fetchTrialSessionsDataSuccess,
  fetchEnrolmentData,
  fetchEnrolmentDataSuccess,
  fetchMakeUpSessionData,
  fetchMakeUpSessionDataSuccess,
  fetchStandardData,
  fetchStandardDataSuccess,
  fetchSubjectCategoryData,
  fetchSubjectCategoryDataSuccess,
  fetchSubjectData,
  fetchSubjectDataSuccess,
  fetchBatchData,
  fetchBatchDataSuccess,
  fetchPastAttendanceData,
  fetchPastAttendanceDataSuccess,
  fetchFutureAttendanceData,
  fetchFutureAttendanceDataSuccess,
  fetchSchoolAttendanceData,
  fetchSchoolAttendanceDataSuccess,
  fetchInventorySchedulesData,
  fetchInventorySchedulesDataSuccess,
  updateClassDataSuccess,
  fetchExploreSessionFeedbackDataSucsess,
  fetchExploreSessionFeedbackData,
  fetchScheduleSessionActivityData,
  fetchScheduleSessionActivityDataSuccess,
  fetchExploreSessionDataSuccess,
  fetchExploreSessionData,
  fetchExploreEnrolmentData,
  fetchExploreEnrolmentDataSuccess,
  fetchOpendayScheduleData,
  fetchOpendayScheduleDataSuccess,
  fetchOpendaySlotsData,
  fetchOpendaySlotsDataSuccess,
  fetchExcelEnrolmentData,
  fetchExcelEnrolmentDataSuccess,
  fetchOpportunitiesData,
  fetchOpportunitiesDataSuccess
} from 'app/store/actions/classEntity'
import * as types from 'app/store/types/classEntity'

import { handleFetch, handleUpdate } from './saga.helper'

const mapping = {
  [constants.SUBJECT_CATEGORY]: {
    api: {
      fetch: fetchSubjectCategory,
      create: createSubjectCategory,
      update: updateSubjectCategory,
      delete: deleteSubjectCategory
    },
    action: {
      fetch: fetchSubjectCategoryData,
      fetchSuccess: fetchSubjectCategoryDataSuccess
    }
  },
  [constants.SUBJECT]: {
    api: {
      fetch: fetchSubject,
      create: createSubject,
      update: updateSubject,
      delete: deleteSubject
    },
    action: {
      fetch: fetchSubjectData,
      fetchSuccess: fetchSubjectDataSuccess
    }
  },
  [constants.STANDARD]: {
    api: {
      fetch: fetchStandard,
      create: createStandard,
      update: updateStandard,
      delete: deleteStandard
    },
    action: {
      fetch: fetchStandardData,
      fetchSuccess: fetchStandardDataSuccess
    }
  },
  [constants.BOARD]: {
    api: {
      fetch: fetchBoard,
      create: createBoard,
      update: updateBoard,
      delete: deleteBoard
    },
    action: {
      fetch: fetchBoardData,
      fetchSuccess: fetchBoardDataSuccess
    }
  },
  [constants.COURSE]: {
    api: {
      fetch: fetchCourse,
      create: createCourse,
      update: updateCourse,
      delete: deleteCourse
    },
    action: {
      fetch: fetchCourseData,
      fetchSuccess: fetchCourseDataSuccess
    }
  },
  [constants.TRIAL_SESSIONS]: {
    api: {
      fetch: fetchTrialSessions,
      create: createTrialSessions,
      update: updateTrialSessions,
      delete: deleteTrialSessions
    },
    action: {
      fetch: fetchTrialSessionsData,
      fetchSuccess: fetchTrialSessionsDataSuccess
    }
  },
  [constants.ENROLMENT]: {
    api: {
      fetch: fetchEnrolment,
      create: createEnrolment,
      update: updateEnrolment,
      delete: deleteEnrolment
    },
    action: {
      fetch: fetchEnrolmentData,
      fetchSuccess: fetchEnrolmentDataSuccess
    }
  },
  [constants.MAKE_UP_SESSION]: {
    api: { fetch: fetchmakeUp },
    action: {
      fetch: fetchMakeUpSessionData,
      fetchSuccess: fetchMakeUpSessionDataSuccess
    }
  },
  [constants.EXPLORE_SESSION]: {
    api: { fetch: fetchExploreSessionEnrolments },
    action: {
      fetch: fetchExploreSessionData,
      fetchSuccess: fetchExploreSessionDataSuccess
    }
  },
  [constants.EXPLORE_ENROLMENT]: {
    api: {
      fetch: fetchExploreEnrolment
    },
    action: {
      fetch: fetchExploreEnrolmentData,
      fetchSuccess: fetchExploreEnrolmentDataSuccess
    }
  },
  [constants.EXCEL_ENROLMENT]: {
    api: { fetch: fetchExcelEnrolment },
    action: {
      fetch: fetchExcelEnrolmentData,
      fetchSuccess: fetchExcelEnrolmentDataSuccess
    }
  },
  [constants.BATCH]: {
    api: {
      fetch: fetchBatch,
      update: updateBatch,
      delete: deleteBatch
    },
    action: {
      fetch: fetchBatchData,
      fetchSuccess: fetchBatchDataSuccess
    }
  },
  [constants.PAST_ATTENDANCE]: {
    api: {
      fetch: fetchPastAttendance
    },
    action: {
      fetch: fetchPastAttendanceData,
      fetchSuccess: fetchPastAttendanceDataSuccess
    }
  },
  [constants.FUTURE_ATTENDANCE]: {
    api: {
      fetch: fetchFutureAttendance
    },
    action: {
      fetch: fetchFutureAttendanceData,
      fetchSuccess: fetchFutureAttendanceDataSuccess
    }
  },
  [constants.SCHOOL_ATTENDANCE]: {
    api: {
      fetch: fetchSchoolAttendance,
      update: updateAttendance
    },
    action: {
      fetch: fetchSchoolAttendanceData,
      fetchSuccess: fetchSchoolAttendanceDataSuccess
    }
  },
  [constants.INVENTORY_SCHEDULES]: {
    api: {
      fetch: fetchInventorySchedules
    },
    action: {
      fetch: fetchInventorySchedulesData,
      fetchSuccess: fetchInventorySchedulesDataSuccess
    }
  },
  [constants.EXPLORE_SESSION_FEEDBACK]: {
    api: {
      fetch: fetchExploreProgressData
    },
    action: {
      fetch: fetchExploreSessionFeedbackData,
      fetchSuccess: fetchExploreSessionFeedbackDataSucsess
    }
  },
  [constants.SCHEDULE_SESSION_ACTIVITY]: {
    api: {
      fetch: fetchDemoSchedule
    },
    action: {
      fetch: fetchScheduleSessionActivityData,
      fetchSuccess: fetchScheduleSessionActivityDataSuccess
    }
  },
  [constants.OPENDAY_SCHEDULE]: {
    api: {
      fetch: fetchOpendaySchedule,
      create: createOpendaySchedule,
      update: updateOpendaySchedule
    },
    action: {
      fetch: fetchOpendayScheduleData,
      fetchSuccess: fetchOpendayScheduleDataSuccess
    }
  },
  [constants.OPENDAY_SLOTS]: {
    api: {
      fetch: fetchOpendaySlots,
      create: createOpendaySlots,
      update: updateOpendaySlots,
      delete: deleteOpendaySlots
    },
    action: {
      fetch: fetchOpendaySlotsData,
      fetchSuccess: fetchOpendaySlotsDataSuccess
    }
  },
  [constants.OPPORTUNITIES]: {
    api: {
      fetch: fetchOpportunities,
      create: createOpportunities,
      update: updateOpportunities
      // delete: deleteOpportunities
    },
    action: {
      fetch: fetchOpportunitiesData,
      fetchSuccess: fetchOpportunitiesDataSuccess
    }
  }
}

export function* _fetchClassEntity({ queries, entity } = {}) {
  const mappingData = mapping[entity]
  yield handleFetch(mappingData, queries, entity)
}

export function* _updateClassEntity({ entity, event, value } = {}) {
  const mappingData = mapping[entity]
  yield handleUpdate(mappingData, entity, event, value, updateClassDataSuccess)
}

export function* watchFetchClassEntity() {
  yield takeLatest(
    [
      types.FETCH_SUBJECT_CATEGORY_DATA,
      types.FETCH_SUBJECT_DATA,
      types.FETCH_STANDARD_DATA,
      types.FETCH_BOARD_DATA,
      types.FETCH_COURSE_DATA,
      types.FETCH_BATCH_DATA,
      types.FETCH_TRIAL_SESSIONS_DATA,
      types.FETCH_ENROLMENT_DATA,
      types.FETCH_PAST_ATTENDANCE_DATA,
      types.FETCH_FUTURE_ATTENDANCE_DATA,
      types.FETCH_SCHOOL_ATTENDANCE_DATA,
      types.FETCH_INVENTORY_SCHEDULES_DATA,
      types.FETCH_MAKE_UP_SESSION_DATA,
      types.FETCH_EXPLORE_SESSION_FEEDBACK_DATA,
      types.FETCH_SCHEDULE_SESSION_ACTIVITY_DATA,
      types.FETCH_EXPLORE_SESSION_DATA,
      types.FETCH_EXPLORE_ENROLMENT_DATA,
      types.FETCH_EXCEL_ENROLMENT_DATA,
      types.FETCH_OPENDAY_SCHEDULE_DATA,
      types.FETCH_OPENDAY_SLOTS_DATA,
      types.FETCH_OPPORTUNITIES_DATA
    ],
    _fetchClassEntity
  )
}

export function* watchUpdateClassEntity() {
  yield takeEvery(types.UPDATE_CLASS_DATA, _updateClassEntity)
}

export function* _watchCreateDemoClassBooking({ value } = {}) {
  try {
    const response = yield call(createDemoClassBooking, value)
    if (response.success) {
      eventEmitter.emit(constants.INVENTORY_BOOKING_SUCCESS)
      eventAlert('Successfully Booked!', constants.SUCCESS)
    }
  } catch (error) {
    console.log('error in demo class booking :- ', error)
    eventEmitter.emit(constants.INVENTORY_BOOKING_ERROR)
    eventAlert(error.message, constants.ERROR)
  }
}

export function* watchCreateDemoClassBooking() {
  yield takeEvery(types.CREATE_DEMO_CLASS_BOOKING, _watchCreateDemoClassBooking)
}

export function* _watchCreateClassBooking({ value } = {}) {
  try {
    const response = yield call(createClassBooking, value)
    if (response.success) {
      eventEmitter.emit(constants.INVENTORY_BOOKING_SUCCESS)
      eventAlert('Successfully Booked!', constants.SUCCESS)
    }
  } catch (error) {
    console.log('error in class booking :- ', error)
    eventEmitter.emit(constants.INVENTORY_BOOKING_ERROR)
    eventAlert(error.message, constants.ERROR)
  }
}

export function* watchCreateClassBooking() {
  yield takeEvery(types.CREATE_CLASS_BOOKING, _watchCreateClassBooking)
}

export function* _watchCreateExcelBooking({ value } = {}) {
  try {
    const response = yield call(programEnrolment, value)
    if (response.success || response?.program) {
      eventEmitter.emit(constants.INVENTORY_BOOKING_SUCCESS)
      eventAlert('Successfully Booked!', constants.SUCCESS)
    }
  } catch (error) {
    console.log('error in class booking :- ', error)
    eventEmitter.emit(constants.INVENTORY_BOOKING_ERROR)
    eventAlert(error.message, constants.ERROR)
  }
}

export function* watchCreateExcelBooking() {
  yield takeEvery(types.CREATE_EXCEL_BOOKING, _watchCreateExcelBooking)
}
