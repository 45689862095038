import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateAttendance } from 'api'
import BaseEntity from 'app/components/BaseEntity'
import Button from 'app/components/generic/Button'
import ModalForm from 'app/components/ModalForm'
import { tableColumnDefaultOption } from 'app/config'
import constants from 'app/constants'
import { eventAlert, eventEmitter, showToast } from 'app/helpers'
import { fetchPastAttendanceData } from 'app/store/actions/classEntity'

import { columns } from './config'
import { StudentsTable } from './StudentsTable'

const theme = createTheme({
  overrides: {
    Dialog: {
      backgroundColor: 'red'
    }
  }
})

const PastAttendance = () => {
  const pastattendanceData = useSelector(
    (state) => state.classEntityReducer.pastattendanceData
  )
  const [studentsModalOpen, setStudentsModalOpen] = React.useState(false)
  const [studentsData, setStudentsData] = React.useState('')
  const [componentDisabled, setComponentDisabled] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    localStorage.setItem('filterQueries', null)
  }, [])

  useEffect(() => {
    if (pastattendanceData?.data?.length > 0) {
      const index = localStorage.getItem('studentIndex')
      if (index >= 0) getStudentsDetails(index, true)
      setComponentDisabled(false)
    }
  }, [pastattendanceData])

  const getStudentsDetails = React.useCallback(
    (dataIndex, openModal) => {
      localStorage.setItem('studentIndex', dataIndex)
      const data = pastattendanceData.data[dataIndex]
      if (!isEmpty(data)) {
        const attendanceData = data?.attendances?.map((student) => {
          const attended = student.attended
            ? 'present'
            : student.cancelled
              ? 'cancelled'
              : 'absent'
          return {
            name: student?.student_name,
            attended: attended,
            color: student.attended
              ? 'green'
              : student.cancelled
                ? 'black'
                : 'red',
            check: student.attended,
            uuid: student.uuid
          }
        })
        setStudentsData(
          attendanceData.sort((a, b) => a.name.localeCompare(b.name))
        )
        if (!openModal) setStudentsModalOpen(true)
      } else {
        showToast('Oops, Unable to fetch data', constants.ERROR)
      }
    },
    [pastattendanceData]
  )

  const attendanceChange = async (index) => {
    try {
      setComponentDisabled(true)
      const updatedRes = await updateAttendance({ ...studentsData[index], attended: studentsData[index].attended === 'present' })
    } catch (error) {
      eventAlert(error.message, constants.ERROR)
    } finally {
      const queries = JSON.parse(localStorage.getItem('filterQueries'))
      dispatch(
        fetchPastAttendanceData(
          { ...queries },
          false,
          false,
          constants.PAST_ATTENDANCE
        )
      )
    }
  }

  const modifiedColumns = React.useMemo(() => {
    return [
      ...columns.slice(0, 2),
      {
        name: 'start_time',
        label: 'Timing',
        options: {
          ...tableColumnDefaultOption.options,
          disableFilter: true,
          customBodyRenderLite: (dataIndex) => {
            const data = pastattendanceData.data[dataIndex]
            return (
              <span>{`${dayjs(`${data.date} ${data.start_time}`).format(
                'H:mm a'
              )} - ${dayjs(`${data.date} ${data.end_time}`).format(
                'H:mm a'
              )}`}</span>
            )
          }
        }
      },
      ...columns.slice(2, 6),
      {
        name: 'action',
        disabledInForm: true,
        options: {
          customBodyRenderLite: (dataIndex) => {
            const data = pastattendanceData.data[dataIndex]

            return data?.attendances?.length > 0 ? (
              <Button onClick={() => getStudentsDetails(dataIndex)}>
                See attendance
              </Button>
            ) : null
          }
        }
      }
    ]
  }, [pastattendanceData])

  return (
    <>
      <BaseEntity
        entity={constants.PAST_ATTENDANCE}
        label={constants.PAST_ATTENDANCE_LABEL}
        columns={modifiedColumns}
        data={pastattendanceData}
        fetchData={fetchPastAttendanceData}
        readOnly
      />
      <ThemeProvider theme={theme}>
        <ModalForm
          containerStyles={{ borderRadius: 10 }}
          className="border"
          isModalOpen={studentsModalOpen}
          onModalClose={() => setStudentsModalOpen(false)}
          enableSave={false}
          modalTitle="Students"
        >
          <StudentsTable
            data={studentsData}
            attendanceChange={attendanceChange}
            componentDisabled={componentDisabled}
          />
        </ModalForm>
      </ThemeProvider>
    </>
  )
}

export default PastAttendance
