import { cloneDeep, isEmpty } from 'lodash'

import { getCustomerData, getIndianStates } from 'api'

import {
  childDataColumn,
  groupKeys,
  noHeadingList
} from './components/shared/config'
import { batchTypes, navigationPaths } from './config'
import { USER_STATUS } from './constants'

const parentCustomerData = async () => {
  return await getCustomerData('CUSTOMER_DATA_PARENT')
}

const studentCustomerData = async () => {
  return await getCustomerData('CUSTOMER_DATA_STUDENT')
}

export const parentDataTransformer = async (selectedParentDetails) => {
  const customerParentData = await parentCustomerData()

  const { data: indianStates } = await getIndianStates()

  const statesMap = {}
  indianStates.forEach((state) => {
    statesMap[state[0]] = state[1]
  })

  const parentData = {
    parentId: selectedParentDetails?.user?.uuid,
    parentName: `${selectedParentDetails?.user?.first_name} ${selectedParentDetails?.user?.last_name}`,
    parentMobileNumber: selectedParentDetails?.user?.phone_number,
    prefferedCentre: selectedParentDetails?.centre
      ? selectedParentDetails?.centre?.name
      : selectedParentDetails?.centre_preference
      ? selectedParentDetails?.centre_preference?.name
      : 'NA'
  }

  let parentBasicData = []

  if (!isEmpty(selectedParentDetails)) {
    parentBasicData = [
      {
        key: 'STATE',
        display_text: 'state',
        response: selectedParentDetails?.user?.state
          ? statesMap[selectedParentDetails?.user?.state]
          : 'Not Available',
        showHeading: true
      },
      {
        key: 'POSTAL_CODE',
        display_text: 'postal-code',
        response: selectedParentDetails?.user?.address_detail
          ? selectedParentDetails?.user?.address_detail?.postal_code
          : 'Not Available',
        showHeading: true
      },
      {
        key: 'POSTAL_ADDRESS',
        display_text: 'postal-address',
        response: selectedParentDetails?.user?.address_detail
          ? selectedParentDetails?.user?.address_detail?.postal_address
          : 'Not Available',
        showHeading: true
      }
    ]
  }
  let parentAdditionalDetails = []
  if (
    !isEmpty(selectedParentDetails) &&
    !isEmpty(selectedParentDetails?.additional_information)
  ) {
    const parentAdditionalDetailsDispalyKeys = Object.keys(
      selectedParentDetails?.additional_information
    )

    customerParentData.map((item) => {
      if (parentAdditionalDetailsDispalyKeys.includes(item.key)) {
        parentAdditionalDetails.push(item)
      }
    })

    parentAdditionalDetails.forEach((obj, index) => {
      parentAdditionalDetails[index] = {
        ...parentAdditionalDetails[index],
        response:
          selectedParentDetails?.additional_information[obj.key] ||
          'not available',
        showHeading: true
      }
    })
  }

  const isCustomer =
    selectedParentDetails?.user?.status === USER_STATUS.CUSTOMER

  if (
    !isEmpty(selectedParentDetails) &&
    isCustomer &&
    selectedParentDetails?.user?.referral_code
  ) {
    parentAdditionalDetails.push({
      key: 'REFERRAL_CODE',
      display_text: 'Referral Code',
      type: 'text',
      response: selectedParentDetails?.user?.referral_code,
      descriptionTextTransform: 'initial',
      showHeading: true
    })
  }

  let studentAdditionalInformation = []
  if (!isEmpty(selectedParentDetails)) {
    studentAdditionalInformation = await Promise.all(
      selectedParentDetails?.students?.map((item) => {
        const childData = childCustomerDataTransformer(item)
        return childData
      })
    )
  }

  return {
    parentData,
    parentBasicData,
    parentAdditionalDetails,
    studentAdditionalInformation
  }
}

export const childCustomerDataTransformer = async (selectedStudent) => {
  const customerStudentData = await studentCustomerData()
  let studentAdditionalInformation = cloneDeep(customerStudentData)
  let childConfigData = {
    studentName: selectedStudent?.user?.full_name,
    id: selectedStudent?.user?.uuid
  }
  if (
    !isEmpty(selectedStudent) &&
    !isEmpty(selectedStudent?.additional_information)
  ) {
    studentAdditionalInformation.forEach((obj, index) => {
      studentAdditionalInformation[index] = {
        ...studentAdditionalInformation[index],
        response:
          selectedStudent?.additional_information[obj.key] || 'not available',
        showHeading: !noHeadingList.includes(obj.key)
      }
    })

    let configData = cloneDeep(childDataColumn)

    studentAdditionalInformation.forEach((obj) => {
      configData[groupKeys[obj.key]].data.push(obj)
    })

    childConfigData = {
      ...childConfigData,
      additionalData: studentAdditionalInformation,
      configData: Object.values(configData)
    }
  }
  return childConfigData
}

export const studentsParentBasicDetailsTransformer = (
  selectedParentDetails,
  states
) => {
  return [
    {
      key: 'PARENT_NAME',
      display_text: 'parent name',
      response: selectedParentDetails?.user
        ? `${selectedParentDetails?.user?.first_name} ${selectedParentDetails?.user?.last_name}`
        : 'Not Available',
      showHeading: true,
      parentUrl: `${navigationPaths.parentdashboard}?parent=${selectedParentDetails?.user_id}`
    },
    {
      key: 'PARENT_NUMBER',
      display_text: 'parent number',
      response: selectedParentDetails?.user
        ? selectedParentDetails?.user?.phone_number
        : 'Not Available',
      showHeading: true
    },
    {
      key: 'STATE',
      display_text: 'state',
      response: selectedParentDetails?.user
        ? states[selectedParentDetails?.user?.state]
        : 'not available',
      showHeading: true
    },
    {
      key: 'CENTRE',
      display_text: 'centre',
      response: selectedParentDetails?.centre
        ? selectedParentDetails?.centre?.name
        : selectedParentDetails?.centre_preference
        ? selectedParentDetails?.centre_preference?.name
        : 'not available',
      showHeading: true
    }
  ]
}

export const batchDataTransformer = (batchData) => {
  const data = batchData?.data.map((batch) => {
    let teacher_name = null

    if (batch.type === batchTypes.exploration && batch.batch_activities) {
      teacher_name = batch.batch_activities.reduce(
        (prev, curr, currIndex) =>
          (prev += curr?.teacher?.user?.full_name
            ? (currIndex > 0 ? ', ' : '') + curr?.teacher?.user?.full_name
            : ''),
        ''
      )
    } else if (batch.teacher) {
      teacher_name = batch.teacher.user.full_name
    }

    return {
      teacher_name,
      ...batch
    }
  })

  return {
    ...batchData,
    data
  }
}

export const interactionDataTransformer = (interactionData) => {
  const results = interactionData?.results

  const transformedResults = results.map((interaction) => {
    return {
      ...interaction,
      interaction_topics_list: Array.isArray(interaction?.interaction_topics)
        ? interaction?.interaction_topics?.map((topic) => topic?.topic)
        : [],
      centre: {
        name: interaction.parent?.centre_preference?.name,
        uuid: interaction.parent?.centre_preference?.uuid
      },
      parent_full_name: interaction.parent?.user?.full_name,
      parent_phone_number: interaction.parent?.user?.phone_number
    }
  })

  const data = {
    ...interactionData,
    results: transformedResults
  }
  return data
}

export const ticketDataTransformer = (ticketData) => {
  const results = ticketData?.results

  const transformedResults = results.map((ticket) => {
    return {
      ...ticket,
      user: { user: ticket?.user },
      centre: {
        name: ticket?.centre?.name,
        uuid: ticket?.centre?.uuid
      },
      user_full_name: ticket?.user?.full_name,
      user_phone_number: ticket?.user?.phone_number,
      user_type: ticket?.user?.is_customer
    }
  })

  const transformedData = {
    ...ticketData,
    results: transformedResults
  }
  return transformedData
}
