import { FormControlLabel } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'

import {
  Select,
  TextField,
  Checkbox
} from 'app/components/generic/FormElements'
import { validateNotEmpty, validateNotNil } from 'app/validators'

import CoinRefundCalculation from './CoinRefundCalculation'

import { CURRENT_MONTH_MESSAGE } from '../../constants'
import { formatMakeupDatesList, getMonthName } from '../../helpers'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  nudge: {
    color: theme.palette.error.dark
  },
  nudgeBold: {
    fontWeight: 600
  }
}))

const PauseEnrolmentForm = (props) => {
  const classes = useStyles()
  const { formData } = props
  const { errors, values, setFieldValue, validateField } = useFormikContext()

  const [months, setMonths] = useState([])
  const [makeupsInPauseMonth, setMakeupsInPauseMonth] = useState([])
  const [isCurrentMonthSelected, setIsCurrentMonthSelected] = useState(false)
  const [refundCalculation, setRefundCalculation] = useState(null)

  React.useEffect(() => {
    setMonths(formData?.monthList || [])
  }, [formData])

  const monthChangeHandler = (event) => {
    const selectedMonthValue = event.target.value
    const selectedMonth = months.find(
      (month) => month.month === selectedMonthValue
    )
    if (selectedMonth) {
      setMakeupsInPauseMonth(
        selectedMonth?.makeup_sessions.map((session) => session?.schedule?.date)
      )

      setFieldValue(
        'resume_date',
        dayjs(selectedMonth.resume_date).format('D MMMM YYYY')
      )
      setFieldValue('month', selectedMonthValue, false)
      setIsCurrentMonthSelected(!!selectedMonth?.coin_refund_calculation)
      setFieldValue(
        'refund_value',
        selectedMonth?.refund_calculation?.total_refund_amount
      )
      setRefundCalculation(selectedMonth?.coin_refund_calculation)
      // Validate the month field after setting its value
      setTimeout(() => validateField('month'), 0)
    }
  }

  const checkboxHandler = (event) => {
    setFieldValue('delete_makeups', event.target.checked)
  }

  const formElement = React.useMemo(() => {
    return (
      <>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}
          required>
          <TextField
            name={'student_name'}
            label={'Student Name'}
            variant="outlined"
            defaultValue={formData.student_name}
            disabled
            required
          />
          {!isEmpty(errors.student_name) && (
            <FormHelperText>{errors.student_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.subject_name) ? { error: true } : null)}
          required>
          <TextField
            name="subject_name"
            label={'Subject'}
            variant="outlined"
            required
            defaultValue={formData.subject_name}
            disabled
          />
          {!isEmpty(errors.subject_name) && (
            <FormHelperText>{errors.subject_name}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.month) ? { error: true } : null)}
          required>
          <InputLabel>{'Month'}</InputLabel>
          <Select
            name={'month'}
            defaultValue={''}
            label={'Month'}
            validate={validateNotNil({ isRequired: true })}
            onChange={monthChangeHandler}>
            {months?.map((month, index) => (
              <MenuItem
                key={index}
                value={month.month}
                disabled={!month.pause_eligible}>
                {`${dayjs(`${month.year}-${month.month}-01`).format(
                  'MMMM YYYY'
                )}${
                  !month?.pause_eligible
                    ? ` (${month?.pause_ineligibility_reason})`
                    : ''
                }`}
              </MenuItem>
            ))}
          </Select>
          {!isEmpty(errors.month) && (
            <FormHelperText>{errors.month}</FormHelperText>
          )}
          {isCurrentMonthSelected ? (
            <FormHelperText className={classes.nudge}>
              {CURRENT_MONTH_MESSAGE}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.resume_date) ? { error: true } : null)}
          required>
          <TextField
            name={'resume_date'}
            label={'Resume Date'}
            variant="outlined"
            disabled
            value={values?.resume_date || ''}
          />
          {!isEmpty(errors.resume_date) && (
            <FormHelperText>{errors.resume_date}</FormHelperText>
          )}
        </FormControl>
        {makeupsInPauseMonth.length > 0 ? (
          <>
            <FormControl
              className={classes.formControl}
              {...(!isEmpty(errors.delete_makeups) ? { error: true } : null)}>
              <FormControlLabel
                name="delete_makeups"
                label="Delete all make-up classes*. The make-up classes can be rebooked against the original sessions."
                control={
                  <Checkbox
                    label="Delete all make-up classes*. The make-up classes can be rebooked against the original sessions."
                    required
                    color="primary"
                    validate={validateNotNil({ isRequired: true })}
                    onChange={checkboxHandler}
                    defaultValue={formData?.delete_makeups ?? false}
                  />
                }
              />
              {!isEmpty(errors.delete_makeups) && (
                <FormHelperText>{errors.delete_makeups}</FormHelperText>
              )}
            </FormControl>
            <FormHelperText className={classes.nudge}>
              {`*${makeupsInPauseMonth.length} make-up class${
                makeupsInPauseMonth.length > 1 ? 'es' : ''
              } booked for `}
              <span className={classes.nudgeBold}>
                {formatMakeupDatesList(makeupsInPauseMonth)}
              </span>
            </FormHelperText>
          </>
        ) : null}
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.reason) ? { error: true } : null)}
          required>
          <TextField name={'reason'} label={'Reason'} variant="outlined" />
          {!isEmpty(errors.reason) && (
            <FormHelperText>{errors.reason}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          {...(!isEmpty(errors.reason_for_using_alfred)
            ? { error: true }
            : null)}
          required>
          <TextField
            name={'reason_for_using_alfred'}
            label={'Reason for using Alfred instead of the App'}
            variant="outlined"
            required
            validate={validateNotEmpty({ isRequired: true })}
          />
          {!isEmpty(errors.reason_for_using_alfred) && (
            <FormHelperText>{errors.reason_for_using_alfred}</FormHelperText>
          )}
        </FormControl>
        {isCurrentMonthSelected ? (
          <CoinRefundCalculation
            coinRefundCalculation={{
              ...refundCalculation,
              month: getMonthName(values?.month)
            }}
          />
        ) : null}
      </>
    )
  }, [formData, errors, months, values, makeupsInPauseMonth])

  return <>{formElement}</>
}

export default PauseEnrolmentForm
