import React from 'react'

import { apiEndpoints } from 'api'
import { commonAttributes, tableColumnDefaultOption } from 'app/config'
import { validateNotEmpty } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'subject',
    label: 'Subject',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return item
          ? {
              uuid: item.uuid,
              name: item.name
            }
          : null
      }

      if (Array.isArray(data)) {
        return data?.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data?.results?.map((item) => {
          return getItemStructure(item)
        })
      }

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return getItemStructure(data)
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'subject',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.subject}?limit=500&offset=0`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'subject',
      customBodyRender: (value) => (value ? <span>{value?.name}</span> : null)
    }
  },
  {
    name: 'centres',
    label: 'Centres',
    formDataKey: 'centres',
    widget: 'autocomplete',
    widgetFilter: true,
    minCharactersToSearch: 0,
    validate: validateNotEmpty,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item.uuid,
          name: item.name
        }
      }
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'uuid')) {
        return [getItemStructure(data)]
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results.map((item) => {
          return getItemStructure(item)
        })
      } else {
        return data.map((item) => {
          return getItemStructure(item)
        })
      }
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data.map((item) => {
        return getItemStructure(item)
      })
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'centres',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: apiEndpoints.centre
    },
    multiple: true,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'centre',
      customBodyRender: (value) => (value ? <span>{value?.map(el => el.name)?.join(',')}</span> : null)
    }
  },
  {
    name: 'type',
    label: 'Type',
    widget: 'autocomplete',
    widgetFilter: true,
    optional: false,
    validate: validateNotEmpty,
    minCharactersToSearch: 0,
    getStructuredValues: (data) => {
      const getItemStructure = (item) => {
        return {
          uuid: item,
          name: item
        }
      }
      
      if (Array.isArray(data)) {
        return data.map((item) => {
          return getItemStructure(item)
        })
      } else if (Object.prototype.hasOwnProperty.call(data, 'results')) {
        return data.results[0].value.map((item) => {
          return getItemStructure(item)
        })
      } 

      return [getItemStructure(data)]
    },
    getFormDataValue: (data) => {
      const getItemStructure = (item) => {
        return item.uuid
      }
      return data?.results?.map((item) => getItemStructure(item))
    },
    getOptionLabelKey: 'name',
    getOptionSelectedKey: 'uuid',
    resultStructure: {
      keyName: 'type',
      keyValue: 'uuid'
    },
    apiOptions: {
      method: 'get',
      url: `${apiEndpoints.configApp}?key=OPPORTUNITY_TYPES`
    },
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'type'
    }
  },
  {
    name: 'title',
    label: 'title',
    widgetFilter: true,
    maxCharacters: 32,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'title',
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  },
  {
    name: 'description',
    label: 'description',
    widgetFilter: false,
    maxCharacters: 55,
    validate: validateNotEmpty,
    options: {
      ...tableColumnDefaultOption.options,
      disableFilter: true,
      setCellProps: () => ({
        style: {
          minWidth: '400px',
          maxWidth: '400px',
          whiteSpace: 'pre-wrap'
        }
      }),
      filter: false,
      customBodyRender: (value) => {
        return <div>{value}</div>
      }
    }
  }
]
