import styled, { css } from 'styled-components'


export const Main = styled.div`
  background-color: #f8f5ed;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
`

export const LoadingMain = styled.div`
  background-color: #f8f5ed;
  min-height: 100vh;
`

export const TableTitle = styled.div`
  font-family: AvenirDemi;
  font-weight: bold;
  font-size: 1.25em;
  margin-left: 0.25em;
  margin-bottom: 0.25em;
`

export const TableSubtitle = styled.div`
  font-family: Avenir;
  font-size: 1em;
  color: #EF5C34;
  margin-left: 0.5em;
  margin-bottom: 1.5em;
`

export const Block = styled.div`
  position: absolute;
  top: 38%;
  left: 52%;
`