import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

import { cancelSingleSession, getSessions } from 'api'
import Button, { useStyles } from 'app/components/generic/Button'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { confirm, eventAlert, eventEmitter, showToast } from 'app/helpers'
import { getSessionDetails } from 'app/pages/Dashboard/ParentDashboard/helpers'

import { AttendanceTable, SessionsTable } from './AttendanceTable'

dayjs.extend(isSameOrAfter)

const AttendanceSessions = (props) => {
  const { isModalOpen, closeModal, data } = props
  const [sessionsData, setSessionsData] = React.useState('')
  const [refresh, setRefresh] = React.useState(false)
  const classes = useStyles()

  const subtitle = `${data?.kid} - ${data?.course_name}`

  const getSessionData = React.useCallback(async () => {
    const sessions = await getSessionDetails(data?.uuid)
    setSessionsData(sessions)
  }, [data?.uuid])

  useEffect(() => {
    ;(async () => {
      await getSessionData()
    })()
  }, [data, refresh])

  return (
    <>
      <ModalForm
        containerStyles={{ borderRadius: 10 }}
        className="border"
        isModalOpen={isModalOpen}
        onModalClose={closeModal}
        enableSave={false}
        modalTitle="Attendance Sessions"
        modalSubtitle={subtitle}
        showCloseIcon
      >
        <AttendanceTable data={sessionsData} />
      </ModalForm>
    </>
  )
}

export default AttendanceSessions
