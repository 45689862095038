import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import React from 'react'

import { TextField } from 'app/components/generic/FormElements'
import { RENEW_EARLY_DESCRIPTION } from 'app/constants'
import { validator, validateFee, validateNotEmpty } from 'app/validators'

import RenewEarlyDescription from './components/RenewEarlyDescription'
const RenewNowForm = (props) => {
  const { formData } = props
  const { errors, values, setFieldValue } = useFormikContext()

  React.useEffect(() => {
    if (formData?.current_balance) {
      setFieldValue('current_balance', formData?.current_balance)
    }
  }, [formData?.current_balance])

  React.useEffect(() => {
    if (values?.coins > 0) {
      const price = values?.current_balance - values?.coins
      setFieldValue('new_balance', price)
    }
  }, [values?.coins, values?.current_balance])

  const validateCoins =
    ({ isRequired } = {}) =>
    (value) => {
      return validator(isRequired, value, () => {
        if (!isNil(value) && typeof value !== 'string') {
          value = value.toString()
        }
        if (
          (typeof value === 'string' &&
            (isEmpty(value) || value === '<p></p>/n')) ||
          parseInt(value) <= 0
        ) {
          return 'Please enter a valid amount'
        }
      })
    }

  return (
    <>
      <RenewEarlyDescription />
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.parent_name) ? { error: true } : null)}
        required
      >
        <TextField
          name={'parent_name'}
          label={'Parent Name'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.parent_name) && (
          <FormHelperText>{errors.parent_name}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.parent_number) ? { error: true } : null)}
        required
      >
        <TextField
          name={'parent_number'}
          label={'Parent Number'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.parent_number) && (
          <FormHelperText>{errors.parent_number}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="outlined"
        {...(!isEmpty(errors.renewal_amount) ? { error: true } : null)}
        required
      >
        <TextField
          name={'renewal_amount'}
          label={'Renewal Amount'}
          variant="outlined"
          required
          validate={validateNotEmpty({ isRequired: true })}
          disabled
        />
        {!isEmpty(errors.renewal_amount) && (
          <FormHelperText>{errors.renewal_amount}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default RenewNowForm
