import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel
} from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

import { targetAudienceSelectionModes } from 'app/config'
import { dispatchUpdateTargetAudienceSelectionMode } from 'app/store/dispatcher'
import { selectTargetAudienceSelectionMode } from 'app/store/selectors'

import { Container } from './styles'

const TargetAudienceSelectionMode = () => {
  const targetAudienceSelectionMode = useSelector(
    selectTargetAudienceSelectionMode
  )

  const handleChange = (event) => {
    dispatchUpdateTargetAudienceSelectionMode(event.target.value)
  }

  return (
    <Container>
      <FormControl>
        <FormLabel id="target-audience-selection-mode">
          Target Audience Selection Type
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="target-audience-selection-mode"
          name="target-audience-selection-mode"
          value={targetAudienceSelectionMode}
          onChange={handleChange}
        >
          <FormControlLabel
            value={targetAudienceSelectionModes.AUTOMATIC}
            control={<Radio color="primary" />}
            label="Send to all users"
          />
          <FormControlLabel
            value={targetAudienceSelectionModes.AUTOMATIC_WITH_EXCLUSION}
            control={<Radio color="primary" />}
            label="Exclude specific users"
          />
          <FormControlLabel
            value={targetAudienceSelectionModes.MANUAL}
            control={<Radio color="primary" />}
            label="Manually select users"
          />
        </RadioGroup>
      </FormControl>
    </Container>
  )
}

export { TargetAudienceSelectionMode }
