import ExpandMore from '@material-ui/icons/ExpandMore'
import { isEmpty } from 'lodash'
import React from 'react'

import { Details } from 'app/components/shared/components/Details'
import { navigationPaths } from 'app/config'
import { userEditModalHandler } from 'app/helpers'
import AdditionalDetailsIcon from 'assets/images/additional_details.svg'
import BasicDetailsIcon from 'assets/images/basic_details.svg'
import OhIcon from 'assets/images/oh_logo_transparent.svg'
import SeperatorIcon from 'assets/images/seperator.svg'

import * as styles from './styles'

import { ChildDetails } from '../ChildDetails'
import { EditButton } from '../EditButton'

export const ParentDetails = ({ parentDetails }) => {
  const {
    parentData,
    parentBasicData,
    parentAdditionalDetails,
    studentAdditionalInformation
  } = parentDetails
  return (
    <div>
      <styles.Header>
        <styles.Wrapper>
          <h1>{parentData?.parentName}</h1>
          <styles.SubWrapper>
            <styles.Phone>{parentData?.parentMobileNumber}</styles.Phone>
            <styles.Icon src={SeperatorIcon} />
            <styles.Centre>
              <styles.Icon src={OhIcon} alt="oh-icon" />
              <div>{parentData?.prefferedCentre?.toLowerCase()}</div>
            </styles.Centre>
          </styles.SubWrapper>
        </styles.Wrapper>
        <styles.EditButtonWrapper>
          <EditButton
            editHandler={() =>
              userEditModalHandler(navigationPaths.parent, parentData?.parentId)
            }
          />
        </styles.EditButtonWrapper>
      </styles.Header>
      <styles.CardsWrapper>
        <Details
          heading={'Basic Details'}
          icon={BasicDetailsIcon}
          detailsData={parentBasicData}
          isDetailsInRows={true}
        />
        <Details
          heading={'Additional Details'}
          icon={AdditionalDetailsIcon}
          detailsData={parentAdditionalDetails}
          isDetailsInRows={true}
        />
      </styles.CardsWrapper>
      {!isEmpty(studentAdditionalInformation) ? (
        <div>
          <h1>Children</h1>
          {studentAdditionalInformation?.map((student) => {
            return (
              <styles.AccordionWrapper key={student?.id}>
                <styles.Accordion disableGutters elevation={0}>
                  <styles.AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
                  >
                    <styles.AccordionHeader>
                      {student?.studentName}
                    </styles.AccordionHeader>
                  </styles.AccordionSummary>
                  <styles.AccordionDetails>
                    <styles.EditButtonWrapper>
                      <EditButton
                        editHandler={() =>
                          userEditModalHandler(
                            navigationPaths.student,
                            student?.id
                          )
                        }
                      />
                    </styles.EditButtonWrapper>
                    <ChildDetails studentData={student?.configData} />
                  </styles.AccordionDetails>
                </styles.Accordion>
              </styles.AccordionWrapper>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
