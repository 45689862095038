import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateUpdateRequest,
  validateOpendaySchedulePatchRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, post, patch } from '../request'

const opendayRequestStructure = (data) => {
  return {
    start_date: data.start_date,
    end_date: data.end_date,
    centres: data.centre,
    subjects: data.subjects
  }
}

const opendayRequestPatchStructure = (data) => {
  return {
    start_date: data.start_date,
    end_date: data.end_date,
    centre: Array.isArray(data.centre) ? data.centre[0] : data.centre,
    subjects: data.subjects
  }
}

const opportunitiesRequestStructure = (data) => {
  return {
    type: data?.type,
    subject: data?.subject,
    centres: data?.centres,
    month: data?.month,
    year: data?.year,
    title: data?.title,
    description: data?.description,
    levels: data?.levels,
    images: data?.images || []
  }
}

const fetchOpendaySchedule = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.openday, { ...queries })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching openday schedule')
    throw error
  }
}

const fetchOpendayShowcaseSchedule = async ({
  url,
  queries,
  schedule_uuid
} = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.openday}${schedule_uuid}/`)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching openday showcase schedule')
    throw error
  }
}

const createOpendaySchedule = async (data) => {
  try {
    const response = await post(
      apiEndpoints.openday,
      opendayRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating openday schedule')
    throw error
  }
}

const updateOpendaySchedule = async (data) => {
  validateUpdateRequest(data)
  validateOpendaySchedulePatchRequest(data)
  try {
    const response = await patch(
      `${apiEndpoints.openday}${data.uuid}/`,
      opendayRequestPatchStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating openday schedule')
    throw error
  }
}

const onConfirmScheduleSave = async (data) => {
  try {
    const response = await patch(`${apiEndpoints.openday}${data.uuid}/`, data)
    return response.data
  } catch (error) {
    sentryLogger(
      error,
      'error in updating openday schedule, on confirm button save'
    )
    throw error
  }
}

const fetchOpportunities = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.opportunities, { ...queries })

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching showcases & competitions')
    throw error
  }
}

const createOpportunities = async (data) => {
  try {
    const response = await post(
      apiEndpoints.opportunities,
      opportunitiesRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating showcases & competitions')
    throw error
  }
}

const updateOpportunities = async (data) => {
  validateUpdateRequest(data)

  try {
    const response = await patch(
      `${apiEndpoints.opportunities}${data.uuid}/`,
      opportunitiesRequestStructure(data)
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating showcases & competitions')
    throw error
  }
}

export {
  fetchOpendaySchedule,
  createOpendaySchedule,
  updateOpendaySchedule,
  fetchOpendayShowcaseSchedule,
  onConfirmScheduleSave,
  fetchOpportunities,
  createOpportunities,
  updateOpportunities
}
