import * as types from '../types/app'

export const updateDrawerNavigationMenuState = (value) => ({
  type: types.UPDATE_DRAWER_NAVIGATION_MENU_STATE,
  value
})

export const updateTheme = (value) => ({
  type: types.UPDATE_THEME,
  value
})

export const updateServerDataFetchError = ({ entity, error, message }) => ({
  type: types.UPDATE_SERVER_DATA_FETCH_ERROR,
  entity,
  error,
  message
})
