import {
  commonAttributes,
  subjectCategoryTypes,
  tableColumnDefaultOption,
  uploadPresets
} from 'app/config'
import constants from 'app/constants'
import { validateHexColor, validateNotEmpty } from 'app/validators'

export const columns = [
  {
    ...commonAttributes.uuid
  },
  {
    name: 'name',
    label: 'Category Name',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'category_name'
    }
  },
  {
    name: 'type',
    label: 'Category Type',
    widget: 'select',
    widgetFilter: true,
    validate: validateNotEmpty,
    valueLabelMappings: subjectCategoryTypes,
    ...tableColumnDefaultOption
  },
  {
    ...commonAttributes.image,
    name: 'icon',
    label: 'Icon',
    fileUrlOnly: true,
    uploadPreset: uploadPresets[constants.SUBJECT_CATEGORY]
  },
  {
    name: 'description',
    label: 'Category Description',
    options: {
      display: true,
      disableFilter: true
    }
  },
  {
    name: 'color_code',
    label: 'Colour Code',
    optional: true,
    validate: validateHexColor,
    options: {
      display: true,
      disableFilter: true
    }
  }
]
