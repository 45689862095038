import MUIDataTable from 'mui-datatables'
import styled from 'styled-components'

export const StyledMUIDataTable = styled(MUIDataTable)`
  width: 100% !important;

  tr:nth-child(even) {
    background-color: white !important;
  }
`
