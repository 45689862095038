import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import React from 'react'

import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

import EditFormElements from './DispatchForm/components/EditFormElements'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  fieldControl: {
    width: '65%'
  }
}))

const EditForm = (props) => {
  const classes = useStyles()
  const formRef = React.useRef(null)
  const [formData, setFormData] = React.useState({
    misc_charges: []
  })

  const {
    isModalOpen,
    closeModal,
    onSaveClick,
    data,
    editIndex,
    action,
    itemTypes
  } = props

  React.useEffect(() => {
    ;(async () => {
      if (!isEmpty(data.data) && editIndex != null) {
        let summaryData = data?.data[editIndex]
        const items = summaryData?.payout_items?.map((val) => {
          return {
            ...val,
            action: val.uuid ? 'update' : 'create',
            name: val.name
          }
        })
        const otherItemTypes = itemTypes?.filter(
          (el) =>
            items?.findIndex((val) => val.item_type.type === el.type) === -1 &&
            el.type !== 'miscellaneous_credit' &&
            el.type !== 'miscellaneous_debit'
        )
        const types = otherItemTypes?.map((val) =>
          items.push({
            item_type: val,
            name: val.name,
            value: 0,
            notes: '',
            action: 'create'
          })
        )
        summaryData = {
          ...summaryData,
          payout_items: items
        }
        setFormData({
          ...formData,
          misc_charges: [...summaryData?.payout_items],
          sessions_amount:
            summaryData?.payout_items?.find(
              (el) => el.item_type.type === 'sessions'
            )?.value || 0,
          final_amount: summaryData?.value
        })
      }
    })()
  }, [data, editIndex, itemTypes])

  const handleOnSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current

      try {
        const errors = await validateForm(values)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        } else {
          // form is valid
          try {
            let data = { ...values }
            let items = data.misc_charges?.map((val) => {
              if (
                val.item_type.type !== 'miscellaneous_debit' &&
                val.item_type.type !== 'miscellaneous_credit'
              )
                return {
                  ...val
                }

              let type_credit = itemTypes?.find(
                (el) => el.type === 'miscellaneous_credit'
              )
              let type_debit = itemTypes?.find(
                (el) => el.type === 'miscellaneous_debit'
              )
              return {
                ...val,
                item_type: {
                  ...val.item_type,
                  uuid:
                    val.item_type.status === 'debit'
                      ? type_debit.uuid
                      : type_credit.uuid,
                  type:
                    val.item_type.status === 'debit'
                      ? type_debit.type
                      : type_credit.type
                }
              }
            })
            let finalValues = {
              ...data,
              misc_charges: items
            }
            await onSaveClick(finalValues, action)
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [onSaveClick, action])

  const formElement = React.useMemo(() => {
    if (!isEmpty(formData.misc_charges))
      return (
        <Formik
          initialValues={formData}
          onSubmit={(values, { validate }) => {
            validate(values)
          }}
          innerRef={formRef}
          className={classes.root}
        >
          {({ errors, values }) => (
            <Form>
              <FormControl className={classes.formControl} fullWidth>
                <EditFormElements
                  formData={formData}
                  setFormData={setFormData}
                  action={action}
                  itemTypes={itemTypes}
                />
              </FormControl>
            </Form>
          )}
        </Formik>
      )
  }, [action, classes, formData, itemTypes])

  return (
    <ModalForm
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}
    >
      {formElement}
    </ModalForm>
  )
}

export default EditForm
