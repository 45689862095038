import React from 'react'

export const columns = [
  {
    name: 'user.uuid',
    label: 'uuid',
    options: {
      filter: true,
      display: 'excluded'
    }
  },
  {
    name: 'user.full_name',
    label: 'Name',
    options: {
      filter: true
    }
  },
  {
    name: 'standard.name',
    label: 'Standard',
    options: {
      filter: false
    }
  },
  {
    name: 'school.name',
    label: 'School',
    options: {
      filter: false
    }
  },
  {
    name: 'user.phone_number',
    label: 'Phone Number',
    options: {
      filter: false
    }
  },
  {
    name: 'user.date_of_birth',
    label: 'Date of birth',
    options: {
      filter: false
    }
  }
]

export const tableOptions = {
  enableNestedDataAccess: '.',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  customToolbar: null,
  responsive: 'vertical',
  textLabels: {
    body: {
      noMatch: (
        <span style={{ color: 'rgba(0, 0, 8, 0.5)', fontSize: '18px' }}>
          No students added! Please use the options above to search students or
          add a new student.
        </span>
      )
    }
  }
}
