/* eslint-disable react/prop-types */
import React from 'react'

import NotFound from 'assets/images/not_found.svg'

import * as styles from './styles'

export const NotFoundBlock = (props) => {
  return (
    <styles.Body>
      <styles.ColumnFlexCenter>
        <styles.Image src={NotFound} alt="not-found" />
        <styles.Content heading type="dark">
          {props.message}
        </styles.Content>
      </styles.ColumnFlexCenter>
    </styles.Body>
  )
}
