import { Casino } from '@material-ui/icons'

import {
  commonStructure,
  handleEntitiesUpdate,
  dataResponseHandler,
  dataFetchingHandler
} from './reducer.helper'

import * as types from '../types/classEntity'

const initialState = {
  subjectData: commonStructure(),
  subjectCategoryData: commonStructure(),
  boardData: commonStructure(),
  batchData: commonStructure(),
  standardData: commonStructure(),
  courseData: commonStructure(),
  enrolmentData: commonStructure(),
  trialSessionsData: commonStructure(),
  pastattendanceData: commonStructure(),
  futureattendanceData: commonStructure(),
  schoolAttendanceData: commonStructure(),
  inventorySchedulesData: commonStructure(),
  makeUpSessionData: commonStructure(),
  exploreSessionFeedbackData: commonStructure(),
  scheduleSessionActivityData: commonStructure(),
  exploreSessionData: commonStructure(),
  excelEnrolmentData: commonStructure(),
  exploreEnrolmentData: commonStructure(),
  opendayScheduleData: commonStructure(),
  opendaySlotsData: commonStructure(),
  opportunitiesData: commonStructure()
}

const classEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SUBJECT_DATA:
    case types.FETCH_SUBJECT_CATEGORY_DATA:
    case types.FETCH_BOARD_DATA:
    case types.FETCH_BATCH_DATA:
    case types.FETCH_STANDARD_DATA:
    case types.FETCH_COURSE_DATA:
    case types.FETCH_ENROLMENT_DATA:
    case types.FETCH_TRIAL_SESSIONS_DATA:
    case types.FETCH_PAST_ATTENDANCE_DATA:
    case types.FETCH_FUTURE_ATTENDANCE_DATA:
    case types.FETCH_SCHOOL_ATTENDANCE_DATA:
    case types.FETCH_INVENTORY_SCHEDULES_DATA:
    case types.FETCH_MAKE_UP_SESSION_DATA:
    case types.FETCH_EXPLORE_SESSION_FEEDBACK_DATA:
    case types.FETCH_SCHEDULE_SESSION_ACTIVITY_DATA:
    case types.FETCH_EXPLORE_SESSION_DATA:
    case types.FETCH_EXPLORE_ENROLMENT_DATA:
    case types.FETCH_EXCEL_ENROLMENT_DATA:
    case types.FETCH_OPENDAY_SCHEDULE_DATA:
    case types.FETCH_OPENDAY_SLOTS_DATA:
    case types.FETCH_OPPORTUNITIES_DATA:
      return dataFetchingHandler(state, `${action.entity}Data`, action)

    case types.FETCH_SUBJECT_DATA_SUCCESS:
    case types.FETCH_SUBJECT_CATEGORY_DATA_SUCCESS:
    case types.FETCH_BOARD_DATA_SUCCESS:
    case types.FETCH_BATCH_DATA_SUCCESS:
    case types.FETCH_STANDARD_DATA_SUCCESS:
    case types.FETCH_COURSE_DATA_SUCCESS:
    case types.FETCH_ENROLMENT_DATA_SUCCESS:
    case types.FETCH_TRIAL_SESSIONS_DATA_SUCCESS:
    case types.FETCH_PAST_ATTENDANCE_DATA_SUCCESS:
    case types.FETCH_FUTURE_ATTENDANCE_DATA_SUCCESS:
    case types.FETCH_SCHOOL_ATTENDANCE_DATA_SUCCESS:
    case types.FETCH_INVENTORY_SCHEDULES_DATA_SUCCESS:
    case types.FETCH_MAKE_UP_SESSION_DATA_SUCCESS:
    case types.FETCH_EXPLORE_SESSION_FEEDBACK_DATA_SUCCESS:
    case types.FETCH_SCHEDULE_SESSION_ACTIVITY_DATA_SUCCESS:
    case types.FETCH_EXPLORE_SESSION_DATA_SUCCESS:
    case types.FETCH_EXPLORE_ENROLMENT_DATA_SUCCESS:
    case types.FETCH_EXCEL_ENROLMENT_DATA_SUCCESS:
    case types.FETCH_OPENDAY_SCHEDULE_DATA_SUCCESS:
    case types.FETCH_OPENDAY_SLOTS_DATA_SUCCESS:
    case types.FETCH_OPPORTUNITIES_DATA_SUCCESS:
      return dataResponseHandler(state, `${action.entity}Data`, action)

    case types.UPDATE_CLASS_DATA_SUCCESS:
      return handleEntitiesUpdate(state, action)

    default:
      return state
  }
}

export { classEntityReducer }
