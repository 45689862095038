import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-width: 240px;

  input.custom-date-picker-input {
    height: 56px;
    font-size: 16px;
    padding: 18.5px 14px;

    border-color: rgba(0, 0, 0, 0.23);

    &:hover {
      border-color: #212121;
    }

    &:focus-visible {
      outline-color: var(--oh-orange);
    }
  }
`
