import FormControl from '@material-ui/core/FormControl'
import React from 'react'

import { COIN_IMAGE_SRC } from '../../constants'
import * as styles from '../styles'

const CoinRefundCalculation = ({ coinRefundCalculation }) => {
  const {
    remaining_sessions,
    prorata_refund_amount,
    penalty_amount,
    total_refund_amount,
    month
  } = coinRefundCalculation
  return (
    <FormControl>
      <styles.CoinRefundContainer>
        <styles.CoinRefundHeadingText>
          Coin Refund Calculation
        </styles.CoinRefundHeadingText>
        <styles.CoinRefundInnerContainer>
          <styles.CoinRefundFirstRow>
            <div>{`${month} (${remaining_sessions} sessions remaining)`}</div>
            <styles.CoinContainer>
              <styles.CoinIcon src={COIN_IMAGE_SRC} />
              {prorata_refund_amount}
            </styles.CoinContainer>
          </styles.CoinRefundFirstRow>

          <styles.CoinRefundSecondRow>
            <div>{`50% same month penalty`}</div>
            <styles.CoinPenaltyText>
              <styles.CoinIcon src={COIN_IMAGE_SRC} />-{penalty_amount}
            </styles.CoinPenaltyText>
          </styles.CoinRefundSecondRow>
          <styles.CoinRefundLastRow>
            <div>{`total coins refund`}</div>
            <styles.CoinContainer>
              <styles.CoinIcon src={COIN_IMAGE_SRC} />
              {total_refund_amount}
            </styles.CoinContainer>
          </styles.CoinRefundLastRow>
        </styles.CoinRefundInnerContainer>
      </styles.CoinRefundContainer>
    </FormControl>
  )
}

export default CoinRefundCalculation
