import { isEmpty } from 'lodash'

import { sentryLogger } from 'sentry'

import {
  apiEndpoints,
  validateDeleteRequest,
  validateUpdateRequest
} from './common'

import { makeRequestUrl } from '../app/helpers'
import { get, patch, post, remove } from '../request'

const subjectRequestStructure = (data) => ({
  name: data.name,
  internal_name: data?.internal_name,
  subject_category: data.subject_category,
  description: data.description,
  image: data.image,
  app_image: data.app_image,
  progress_enabled: data?.progress_enabled,
  discovery_enabled: data?.discovery_enabled,
  subject_ratings: [
    {
      rating: 1,
      media_url:
        data?.subject_ratings?.[2]?.media_url &&
          data?.subject_ratings?.[2]?.media_url !== ''
          ? data?.subject_ratings?.[2]?.media_url
          : null
    },
    {
      rating: 2,
      media_url:
        data?.subject_ratings?.[1]?.media_url &&
          data?.subject_ratings?.[1]?.media_url !== ''
          ? data?.subject_ratings?.[1]?.media_url
          : null
    },
    {
      rating: 3,
      media_url:
        data?.subject_ratings?.[0]?.media_url &&
          data?.subject_ratings?.[0]?.media_url !== ''
          ? data?.subject_ratings?.[0]?.media_url
          : null
    }
  ],
  centres: data?.centres,
  standard_group: data?.standard_group,
  coins_per_month: data?.coins_per_month,
  subject_media: data?.subject_media?.map((mediaData, index) => {
    return {
      caption: mediaData?.caption,
      file: mediaData?.file?.uuid,
      sequence: index + 1
    }
  }),
  certifications: data?.certifications
})

const fetchSubject = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.subject, queries)

  try {
    const response = await get(requestUrl)

    const result = {
      ...response.data,
      results: response.data.results?.map((subject) => {
        return {
          ...subject,
          subject_media: subject.subject_media
            ?.map((media) => {
              return {
                caption: media?.file?.caption,
                file: media?.file,
                sequence: media?.sequence
              }
            })
            .sort((a, b) => a.sequence - b.sequence)
        }
      })
    }

    return result
  } catch (error) {
    sentryLogger(error, 'error in fetching subject')
    throw error
  }
}

const validateSubjectPayload = (data) => {
  let payload = data
  let mediaValidation = payload.subject_media.filter(
    (val) => val.file && val.caption
  )
  if (mediaValidation.length) {
    payload.subject_media = mediaValidation
  } else {
    payload.subject_media = null
  }

  return payload
}

const createSubject = async (data) => {
  const payload = validateSubjectPayload(subjectRequestStructure(data))
  try {
    const response = await post(apiEndpoints.subject, payload)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating subject')
    throw error
  }
}

const updateSubject = async (data) => {
  validateUpdateRequest(data)

  const payload = validateSubjectPayload(subjectRequestStructure(data))

  try {
    const response = await patch(
      `${apiEndpoints.subject}/${data.uuid}/`,
      payload
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating subject')
    throw error
  }
}

const deleteSubject = async ({ ids }) => {
  validateDeleteRequest(ids)

  try {
    await Promise.all(
      ids.map((id) => {
        return remove(`${apiEndpoints.subject}/${id}`)
      })
    )

    return ids
  } catch (error) {
    sentryLogger(error, 'error in deleting subject')
    throw error
  }
}

export { fetchSubject, createSubject, updateSubject, deleteSubject }
