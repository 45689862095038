import { Divider, FormControl, Input, InputLabel } from '@material-ui/core'
import { isEmpty, isNil, get } from 'lodash'
import React, { useEffect } from 'react'

import { levelMap } from 'app/pages/Class/Inventory/constants'
import { composeBatchDetails } from 'app/pages/Class/Inventory/transformers'
import CoinIcon from 'assets/images/coin.png'

import { EnrolmentTypeSelection } from './components/EnrolmentTypeSelection'
import { SchedulesDate } from './components/SchedulesDate'
import * as Styles from './styles'

import { fetchExcelScheduledEvents } from '../../../helpers'

const CourseDetail = ({
  selectedItem,
  batchInfo,
  onBatchInfoChange,
  selectedBatchType,
  errorSchema,
  courseFormDisabled,
  isEditing,
  editingItem,
  enableDiscovery,
  discoveryEndDate,
  resubscribeStartDate
}) => {
  const discoveryPrefilled = !isNil(enableDiscovery)
  let batchDetails = {}
  let existingBatchInfo = {}

  if (isEditing) {
    batchDetails = composeBatchDetails(editingItem.batchDetails)
    existingBatchInfo = editingItem.batchFormInfo
  } else {
    try {
      batchDetails = composeBatchDetails(selectedItem)
    } catch (error) {
      console.error('Error in composeBatchDetails:', error)
    }
  }

  const {
    uuid = '',
    subject = {},
    subjectsName = '',
    level = '',
    teacherName = '',
    coins_monthly = 0
  } = batchDetails

  const currentBatchInfo = isEditing
    ? existingBatchInfo
    : get(batchInfo, uuid, {})

  const endDate = React.useMemo(() => {
    return (
      <FormControl>
        <InputLabel>end date</InputLabel>
        <Input
          label="end date"
          value={get(currentBatchInfo, 'endDate.label', '')}
          disabled={true}
          propertyChange={'endDate'}
          handleOnChange={onBatchInfoChange}
          selectedBatchType={selectedBatchType}
          placeholder={'end date'}
          batchInfo={currentBatchInfo}
        />
      </FormControl>
    )
  }, [currentBatchInfo, uuid, selectedBatchType, onBatchInfoChange])

  return (
    <Styles.FormContainer>
      <Styles.Container>
        <Styles.TopContainer>
          <Styles.LeftContainer>
            {subject?.image && (
              <Styles.ImageContainer>
                <Styles.Image src={subject.image} />
              </Styles.ImageContainer>
            )}
            <Styles.DescriptionContainer>
              {subjectsName && <Styles.Title>{subjectsName}</Styles.Title>}
              {level && (
                <Styles.SubTitle>{levelMap[level] || level}</Styles.SubTitle>
              )}
              {teacherName && <Styles.SubTitle>{teacherName}</Styles.SubTitle>}
            </Styles.DescriptionContainer>
          </Styles.LeftContainer>
          <Styles.RightContainer>
            {currentBatchInfo?.price ? (
              <Styles.PriceContainer>
                <Styles.Icon src={CoinIcon} alt="coin image" />
                <Styles.PriceText>{currentBatchInfo?.price}</Styles.PriceText>
              </Styles.PriceContainer>
            ) : null}
          </Styles.RightContainer>
        </Styles.TopContainer>
        <Divider style={{ margin: '1em 0' }} />
        <Styles.BottomContainer>
          <Styles.OtherDetailsContainer>
            {subject?.discovery_enabled && (
              <EnrolmentTypeSelection
                handleOnChange={onBatchInfoChange}
                batchId={uuid}
                defaultValue={get(currentBatchInfo, 'isDiscover', '')}
                value={get(currentBatchInfo, 'isDiscover', '')}
                errorSchema={errorSchema}
                disabled={courseFormDisabled || discoveryPrefilled}
              />
            )}
            <SchedulesDate
              batchId={uuid}
              value={get(currentBatchInfo, 'startDate')}
              handleOnChange={onBatchInfoChange}
              propertyChange={'startDate'}
              selectedBatchType={selectedBatchType}
              placeholder={'start date'}
              scheduleFunction={fetchExcelScheduledEvents}
              batchInfo={currentBatchInfo}
              disabled={
                isNil(get(currentBatchInfo, 'isDiscover')) || courseFormDisabled
              }
              discoveryEndDate={discoveryEndDate}
              resubscribeStartDate={resubscribeStartDate}
            />
            {get(currentBatchInfo, 'isDiscover') && endDate}
          </Styles.OtherDetailsContainer>
        </Styles.BottomContainer>
      </Styles.Container>
    </Styles.FormContainer>
  )
}

export { CourseDetail }
