import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { isEmpty } from 'lodash'

import { fetchAttendance, fetchCentre, fetchUser, fetchAttendanceV1 } from 'api'
import constants from 'app/constants'
import { sentryLogger } from 'sentry'

import { emptyStudent } from './config'

dayjs.extend(isToday)

export const fetchSchedules = async (
  type,
  id,
  setIsLoading,
  formData,
  setSchedule,
  setFilterAttendance,
  timer,
  startDate
) => {
  clearTimeout(timer)
  try {
    if (formData.centre) {
      const date = startDate ? dayjs(startDate) : dayjs()
      let response
      if (type === 'parent') {
        response = await fetchAttendanceV1({
          queries: {
            date_gte: date.format('YYYY-MM-DD'),
            date_lte: date.format('YYYY-MM-DD'),
            parent: id
          }
        })
      } else if (type === 'student') {
        response = await fetchAttendanceV1({
          queries: {
            date_gte: date.format('YYYY-MM-DD'),
            date_lte: date.format('YYYY-MM-DD'),
            student: id
          }
        })
      } else
        response = await fetchAttendance({
          queries: {
            centre_uuid: formData.centre.value,
            date_gte: date.format('YYYY-MM-DD'),
            date_lte: date.format('YYYY-MM-DD'),
            limit: 100
          }
        })

      setIsLoading(false)
      if (type == 'student' || type == 'parent') {
        response = [...response.results]
        setFilterAttendance(response)
      } else {
        if (response) {
          const temp = [...response.results]

          let sortedSchedule = filterSort(
            temp,
            type,
            id,
            setIsLoading,
            formData,
            setSchedule,
            timer
          )

          if (isEmpty(sortedSchedule)) sortedSchedule = [{ ...emptyStudent }]
          setSchedule(sortedSchedule)

          if (response.results.length) {
            return true
          }
          return false
        }
      }
    }
  } catch (error) {
    sentryLogger(error, 'error in fetchSchedules')
    setIsLoading(false)
  }
}

export const filterSort = (
  schedule,
  type,
  id,
  setIsLoading,
  formData,
  setSchedule,
  timer
) => {
  const temp = [...schedule]
  const sorted = temp.sort((a, b) => {
    const first = dayjs(dayjs().format('YYYY-MM-DD ') + a.end_time)
    const second = dayjs(dayjs().format('YYYY-MM-DD ') + b.end_time)
    if (first.diff(second) < 0) {
      return -1
    }
    if (first.diff(second) > 0) {
      return 1
    }
    // a must be equal to b
    return 0
  })

  if (type === null) {
    const end_time = sorted[0]?.end_time
    const delay = end_time
      ? dayjs(dayjs().format('YYYY-MM-DD ') + end_time).diff(
          dayjs(),
          'millisecond'
        )
      : 0

    timer = setTimeout(() => {
      setIsLoading(true)
      fetchSchedules(null, null, setIsLoading, formData, setSchedule, timer)
    }, (delay < 0 ? -1 * delay : delay) + 60000)
  }

  const filteredSchedule = schedule
    .filter(
      (t) =>
        t.room?.name.split(' ')[0].toLowerCase() !== 'online' &&
        t.batch?.type !== 'class'
    )
    .sort(
      (a, b) =>
        a.start_time === b.start_time &&
        a.end_time === b.end_time &&
        a.batch.course.name.localeCompare(b.batch.course.name)
    )

  function compare(a, b) {
    const atime = dayjs(`${a.date} ${a.end_time}`)
    const btime = dayjs(`${a.date} ${b.end_time}`)
    const now = dayjs(`${a.date} ${dayjs().format('HH:mm')}`)
    if (atime.isAfter(now, 'second') && btime.isBefore(now, 'second')) {
      return -1
    }
    if (btime.isAfter(now, 'second') && atime.isBefore(now, 'second')) {
      return 1
    }
    return 0
  }

  let sortedSchedule = filteredSchedule
  if (filteredSchedule[0] && dayjs(filteredSchedule[0]?.date).isToday()) {
    sortedSchedule = filteredSchedule.sort(compare)
  }

  return sortedSchedule
}

export const getCentre = async (searchedText) => {
  try {
    const queries = {
      limit: 1000
    }

    if (!isEmpty(searchedText)) {
      queries.name = searchedText
    }

    const response = await fetchCentre({ queries })

    return response.results.map((item) => ({
      value: item.uuid,
      name: item.name
    }))
  } catch (error) {
    sentryLogger(error, 'Error in fetching centres')
    throw error
  }
}

export const fetchStudent = async (searchedText) => {
  try {
    var numberReg = /^\d+$/
    var aplhaReg = /^[a-zA-Z ]*$/
    var uuidReg =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

    if (searchedText?.length >= 0) {
      let queries = {}

      if (numberReg.test(searchedText)) queries = { phone_number: searchedText }
      else if (aplhaReg.test(searchedText)) queries = { name: searchedText }
      else if (uuidReg.test(searchedText)) queries = { uuid: searchedText }

      const response = await fetchUser({ queries }, constants.STUDENT)

      if (response.results.length >= 0) {
        return response.results.map((item) => {
          const { uuid, first_name, last_name } = item?.user

          const { parent, user } = item

          user.full_name = `${first_name} ${last_name}`

          return {
            uuid,
            value: uuid,
            name: `${first_name} ${last_name} ${
              parent?.user?.uuid
                ? `(${parent?.user?.first_name} ${parent?.user?.last_name})`
                : ''
            }`,
            first_name,
            last_name,
            user
          }
        })
      } else if (response.results.length > 1 && searchedText.length === 10) {
        throw new Error('Multiple user found with same number')
      } else {
        return []
      }
    }
  } catch (error) {
    sentryLogger(error, 'Error in fetching user')
    throw error
  }
}

export const fetchParent = async (searchedText) => {
  try {
    var numberReg = /^\d+$/
    var aplhaReg = /^[a-zA-Z ]*$/
    var uuidReg =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

    if (searchedText?.length >= 0) {
      let queries = {}

      if (numberReg.test(searchedText)) queries = { phone_number: searchedText }
      else if (aplhaReg.test(searchedText)) queries = { name: searchedText }
      else if (uuidReg.test(searchedText)) queries = { uuid: searchedText }

      const response = await fetchUser({ queries }, constants.PARENT)

      if (response.results.length >= 0) {
        return response.results.map((item) => {
          const {
            uuid,
            first_name,
            last_name,
            phone_number,
            active_subscription,
            credits,
            member_status
          } = item?.user

          const { centre_preference, students, user } = item

          user.full_name = `${first_name} ${last_name}`

          return {
            uuid,
            value: uuid,
            name: `${phone_number} (${first_name} ${last_name})`,
            first_name,
            last_name,
            phone_number,
            students: students,
            centre: centre_preference,
            active_subscription: active_subscription,
            user,
            credits: credits,
            member_status
          }
        })
      } else if (response.results.length > 1 && searchedText.length === 10) {
        throw new Error('Multiple user found with same number')
      } else {
        return []
      }
    }
  } catch (error) {
    sentryLogger(error, 'Error in fetching user')
    throw error
  }
}

export const updateAttendanceData = (
  attendanceResponse,
  formData,
  filterAttendance,
  setFilterAttendance,
  schedule,
  setSchedule,
  sliderAttendance,
  setSliderAttendance
) => {
  const attendanceUUID = attendanceResponse?.attendance?.uuid
  const scheduleUUID = attendanceResponse?.attendance?.schedule?.uuid

  /* for student and parent filter */
  if (formData.student || formData.parent) {
    updateStudentParentAttendanceData(
      attendanceResponse,
      attendanceUUID,
      filterAttendance,
      setFilterAttendance
    )
  }

  updateSchedule(attendanceResponse, scheduleUUID, schedule, setSchedule)

  /* for slider card when we click on schedules cards */
  updateSliderStudentAttendanceData(
    attendanceResponse,
    attendanceUUID,
    sliderAttendance,
    setSliderAttendance
  )
}

export const updateStudentParentAttendanceData = (
  attendanceResponse,
  attendanceUUID,
  filterAttendance,
  setFilterAttendance
) => {
  const updatedAttendanceData = [...filterAttendance].map((att) =>
    att.uuid === attendanceUUID ? attendanceResponse?.attendance : att
  )

  setFilterAttendance(updatedAttendanceData)
}

export const updateSchedule = (
  attendanceResponse,
  scheduleUUID,
  schedule,
  setSchedule
) => {
  const updatedSchedule = [...schedule].map((obj) => {
    if (obj.uuid === scheduleUUID) {
      return {
        ...obj,
        reserved_count:
          attendanceResponse?.attendance?.schedule?.reserved_count,
        attended_count:
          attendanceResponse?.attendance?.schedule?.attended_count,
        cancelled_count:
          attendanceResponse?.attendance?.schedule?.cancelled_count
      }
    }
    return obj
  })

  setSchedule(updatedSchedule)
}

export const updateSliderStudentAttendanceData = (
  attendanceResponse,
  attendanceUUID,
  sliderAttendance,
  setSliderAttendance
) => {
  const updatedAttendance = {
    ...attendanceResponse?.attendance,
    attendances: [...sliderAttendance.attendances].map((att) =>
      att.uuid === attendanceUUID ? attendanceResponse?.attendance : att
    ),
    reserved_count: attendanceResponse?.attendance?.schedule?.reserved_count,
    attended_count: attendanceResponse?.attendance?.schedule?.attended_count,
    cancelled_count: attendanceResponse?.attendance?.schedule?.cancelled_count
  }

  setSliderAttendance(updatedAttendance)
}
