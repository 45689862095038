import React from 'react'
import * as styles from './styles'
import Pill from '../Pill'
import { PillListIcon } from 'app/pages/Communication/Chat/constants'

const PillList = (props) => {
  const {
    data = {},
    pillStyles = {},
    isSelected,
    truncationLength,
    singleIconList = false,
    singleIconUrl,
    showPillIcon
  } = props

  return (
    <>
      <styles.PillListContainer>
        {Object?.entries(data)?.map(([key, value]) => (
          <Pill
            url={singleIconList ? singleIconUrl : PillListIcon[key]}
            text={value?.toLowerCase()}
            pillStyles={pillStyles}
            isSelected={isSelected}
            truncationLength={truncationLength}
            showPillIcon={showPillIcon}
          />
        ))}
      </styles.PillListContainer>
    </>
  )
}

export default PillList
