import { makeStyles, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

import { cancelSingleSession } from 'api'
import constants from 'app/constants'
import { eventAlert } from 'app/helpers'

import {
  fetchCancelledSessions,
  fetchMakeUpSessions,
  fetchNoShowSessions
} from './helper'
import MakeUpForm from './MakeUpForm'
const MAKE_UP_BOOKING_FOR_INITIAL_VALUE = 'cancelled'
const initialData = {
  parentNumber: '',
  student: '',
  make_up_for: MAKE_UP_BOOKING_FOR_INITIAL_VALUE,
  cancelled: '',
  make_up_session: '',
  reason_for_using_alfred: ''
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(2)
    }
  },
  form: {
    width: '40%'
  },
  heading: {
    display: 'flex',
    margin: '20px'
  }
}))
const MakeUpSession = () => {
  const classes = useStyles()
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [selectedParentDetails, setSelectedParentDetails] = React.useState({})
  const [selectedStudentDetails, setSelectedStudentDetails] = React.useState()
  const [selectedCancelledSession, setSelectedCancelledSession] =
    React.useState()
  const [selectedMakeUpSession, setSelectedMakeUpSession] = React.useState()
  const [students, setStudents] = React.useState([])
  const [cancelledSessions, setCancelledSessions] = React.useState([])
  const [makeUpSessions, setMakeUpSessions] = React.useState([])
  const [alfredReason, setAlfredReason] = React.useState('')
  const [bookingFor, setBookingFor] = React.useState(
    MAKE_UP_BOOKING_FOR_INITIAL_VALUE
  )
  const [formData, setFormData] = React.useState(initialData)

  const formRef = React.useRef(null)

  const handleParentSelection = (name, value) => {
    setSelectedParentDetails(value)

    if (Array.isArray(value?.students) && !isEmpty(value?.students)) {
      setStudents(value?.students)
    } else {
      setStudents([])
      setSelectedStudentDetails('')
      setSelectedCancelledSession('')
      setSelectedMakeUpSession('')
      eventAlert('No students found for the selected parent', constants.ERROR)
    }
    setFormData((state) => {
      return {
        ...state,
        parentNumber: value?.phone_number
      }
    })
  }
  //comment
  useEffect(() => {
    ;(async () => {
      if (!isEmpty(selectedStudentDetails)) {
        try {
          const sessions =
            bookingFor === MAKE_UP_BOOKING_FOR_INITIAL_VALUE
              ? await fetchCancelledSessions(selectedStudentDetails)
              : await fetchNoShowSessions(selectedStudentDetails)
          if (!isEmpty(sessions)) {
            setCancelledSessions(sessions)
          } else {
            setCancelledSessions([])
            eventAlert(
              'No sessions to book make-up against for the student',
              constants.ERROR
            )
          }
        } catch (e) {
          eventAlert(e.message, constants.ERROR)
        }
      }
    })()
  }, [selectedStudentDetails, bookingFor])

  const handleStudentSelection = (value) => {
    setSelectedStudentDetails(value?.target?.value)
    setSelectedCancelledSession('')
    setSelectedMakeUpSession('')
    setFormData((state) => {
      return {
        ...state,
        student: value?.target?.value
      }
    })
  }

  const handleRadioChange = (value) => {
    setSelectedCancelledSession('')
    setSelectedMakeUpSession('')
    setFormData((state) => {
      return {
        ...state,
        make_up_for: value?.target?.value
      }
    })
    setBookingFor(value?.target?.value)
  }

  useEffect(() => {
    ;(async () => {
      if (!isEmpty(selectedCancelledSession)) {
        try {
          const makeUpsessionsList = await fetchMakeUpSessions(
            selectedCancelledSession,
            selectedStudentDetails
          )
          if (!isEmpty(makeUpsessionsList)) {
            setMakeUpSessions(makeUpsessionsList)
          } else {
            eventAlert(`Oops! can't find any make-up session`, constants.ERROR)
          }
        } catch (e) {
          eventAlert(e.message, constants.ERROR)
        }
      }
    })()
  }, [selectedCancelledSession])

  const handleCancelledSessionSelecetion = (value) => {
    try {
      setSelectedCancelledSession(value?.target?.value)
      setFormData((state) => {
        return {
          ...state,
          cancelled: value?.target?.value
        }
      })
    } catch (error) {
      console.log('Error in handling canclled session :- ', error)
      throw error
    }
  }

  const handleMakeUpSessionSelection = (value) => {
    setSelectedMakeUpSession(value.target.value)
    setFormData((state) => {
      return {
        ...state,
        make_up_session: value.target.value
      }
    })
  }

  const handleReasonChange = (event) => {
    setAlfredReason(event.target.value)
    const value = event.target.value
    setFormData((prevState) => ({
      ...prevState,
      reason_for_using_alfred: value
    }))
  }

  const OnBookClick = async (formData) => {
    console.log('formData', formData)
    const { submitForm, validateForm, values } = formRef.current
    console.log('values', values)

    try {
      const errors = await validateForm({ ...values, ...formData })

      console.log(errors)
      if (!isEmpty(errors)) {
        // call submit form , it will ask to validate all fields
        submitForm()
        // emit event to enable save button again
        setLoadingSubmit(false)
      } else {
        const response = await cancelSingleSession({
          sessionId: formData?.cancelled.uuid,
          queries: {
            attendance: {
              reason: `${formData?.cancelled?.reason}`
            },
            make_up_session: {
              schedule_uuid: formData?.make_up_session?.schedule_uuid,
              student_uuid: formData?.student,
              start_date: formData?.make_up_session?.date_gte
            },
            reason_for_using_alfred: values?.alfred_reason
          }
        })
        if (response?.enrolment) {
          eventAlert('Session booked Successfully', constants.SUCCESS)
          return response
        }
      }
    } catch (error) {
      // eventAlert(error.message, constants.ERROR)
      throw error
    }
  }

  const handleOnBookClick = React.useCallback(async () => {
    setLoadingSubmit(true)
    try {
      const saved = await OnBookClick(formData)
      if (saved?.enrolment) {
        setLoadingSubmit(false)
        setFormData({ ...initialData })
        window.location.reload(false)
      }
    } catch (error) {
      console.log(error)
      eventAlert(error.message, constants.ERROR)
      setLoadingSubmit(false)
    }
  }, [OnBookClick])

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {() => {
          return (
            <Form>
              <MakeUpForm
                formData={formData}
                selectedParentDetails={selectedParentDetails}
                handleParentSelection={handleParentSelection}
                loadingSubmit={loadingSubmit}
                handleStudentSelection={handleStudentSelection}
                selectedStudentDetails={selectedStudentDetails}
                cancelledSessions={cancelledSessions}
                handleCancelledSessionSelecetion={
                  handleCancelledSessionSelecetion
                }
                selectedCancelledSession={selectedCancelledSession}
                makeUpSessions={makeUpSessions}
                handleRadioChange={handleRadioChange}
                handleMakeUpSessionSelection={handleMakeUpSessionSelection}
                selectedMakeUpSession={selectedMakeUpSession}
                students={students}
                handleOnBookClick={handleOnBookClick}
                bookingFor={bookingFor}
                handleReasonChange={handleReasonChange}
              />
            </Form>
          )
        }}
      </Formik>
    )
  }, [
    formData,
    loadingSubmit,
    cancelledSessions,
    makeUpSessions,
    students,
    alfredReason
  ])

  return (
    <div className={classes.form}>
      <div className={classes.heading}>
        {' '}
        <Typography variant="h6">Book Make-Up Session</Typography>
      </div>
      {formElement}
    </div>
  )
}

export default MakeUpSession
