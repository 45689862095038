import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import { uploadInstructions, uploadAssignments } from 'api'
import FileUpload from 'app/components/generic/FileUpload'
import { notify } from 'app/helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(2),
    width: 125,
    '& label': {
      width: '100%'
    }
  }
}))

const inputProps = { accept: '.csv' }

const Portfolio = () => {
  const classes = useStyles()

  const handleUploadInstructions = async (e) => {
    try {
      const res = await uploadInstructions(e.target.files[0])
      res && notify('Instructions uploaded successfully', ' ', 'success')
    } catch (e) {
      notify(e.message, '', 'danger')
    }
  }

  const handleUploadAssignments = async (e) => {
    try {
      const res = await uploadAssignments(e.target.files[0])
      res && notify('Assignments uploaded successfully', ' ', 'success')
    } catch (e) {
      notify(e.message, '', 'danger')
    }
  }

  const instructions = (
    <div className={classes.button}>
      <FileUpload
        label="instructions"
        onUpload={handleUploadInstructions}
        inputProps={inputProps}
      />
    </div>
  )

  const assignments = (
    <div className={classes.button}>
      <FileUpload
        label="assignments"
        onUpload={handleUploadAssignments}
        inputProps={inputProps}
      />
    </div>
  )

  return (
    <Box className={classes.root}>
      <Typography variant="h6">Upload</Typography>
      <div className={classes.buttonWrapper}>
        {instructions}
        {assignments}
      </div>
    </Box>
  )
}

export default Portfolio
