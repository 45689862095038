export const selectTeacherListData = (state) =>
  state.communicationEntityReducer.teacherListData

export const selectStudentListData = (state) =>
  state.communicationEntityReducer.studentListData

export const selectChatMessagesData = (state) =>
  state.communicationEntityReducer.chatMessagesData

export const selectChatMessagesHeaderData = (state) =>
  state.communicationEntityReducer.chatMessagesHeaderData

export const selectParentListData = (state) =>
  state.communicationEntityReducer.parentListData

export const selectParentChatMessagesData = (state) =>
  state.communicationEntityReducer.parentChatMessagesData

export const selectParentChatMessagesHeaderData = (state) =>
  state.communicationEntityReducer.parentChatMessagesHeaderData