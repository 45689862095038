import styled from 'styled-components';

export const ParentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-left: 0px;
  max-width: 32%;
  min-width: 32%;
  height: 100vh;
  overflow: hidden;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 0.2rem;
  margin-bottom: 1.8rem;
  z-index: 1;
  position: sticky;
  top: 0;
  min-height: 20vh;
  max-height: 20vh;
`

export const InnerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const HeaderDescription = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-left: 0.5rem;
  font-size: 16px;
`

export const Heading = styled.div`
  font-size: 16px;
  margin-top: 1.5rem;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0.45rem;
  padding-top: 0rem;
  border-top: 1px solid #ccc;
  height: 100%;
`

export const ChatStatusPillContainer = styled.div`
  padding: 10px;
  padding-left: 0px;
  padding-bottom: 5px;
  background-color: white;
  display: flex;
`

export const SearchComponent = {
  border: '1px solid black',
  padding: '0.5rem',
  borderRadius: '4px'
}

export const ChatTitle = {
  fontSize: '20px', fontWeight: 600
}

export const selectedAvatarStyles = {
  backgroundColor: '#58B7E6',
  color: 'white'
}

export const unselectedAvatarStyles = {
  backgroundColor: '#DEF1FA',
  color: '#58B7E6'
}

export const pillStyles = {
  backgroundColor: '#F6F6F6',
  border: '1px solid #B3B3B566',
  cursor: 'pointer'
}

export const selectedPillStyles = {
  backgroundColor: '#FFE1D9',
  border: '1px solid #EF5D3466',
  fontWeight: '600',
  color: '#EF5D34',
  cursor: 'pointer'
}