import styled, { css } from 'styled-components'

export const Body = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .MuiDivider-root {
    width: 100%;
  }

  &:hover {
    color: inherit !important;
  }
`

export const Content = styled.div`
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  // text-transform: lowercase;
  color: var(--oh-black-primary);
  ${(props) =>
    props.plan &&
    css`
      font-family: AvenirDemi;
      font-size: 40px;
    `}

  ${(props) =>
    props.subheading &&
    css`
      font-family: AvenirMedium;
      font-size: 12px;
    `}
    
        ${(props) =>
    props.heading &&
    css`
      font-family: AvenirMedium;
      font-size: 14px;
    `}
  
      ${(props) =>
    props.type &&
    css`
      color: ${props.type === 'light'
        ? 'var(--oh-black-tertiary)'
        : props.type === 'medium'
          ? 'var(--oh-black-secondary)'
          : 'var(--oh-black-primary)'};
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

export const ColumnFlexStart = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
`

export const ColumnFlexGap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0px;
`

export const ColumnFlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`

export const RowFlexStart = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
`

export const RowFlexSpace = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
`
export const Heading = styled.div`
  font-family: AvenirDemi;
  font-size: 14px;
  line-height: 24px;
`

export const Image = styled.img`
  width: 120px;
`
