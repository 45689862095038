import React from 'react'
import Lottie from 'react-lottie'

import { generateDefaultOptions, styles } from './config'

const animationData = require('assets/lottiefiles/no-data.json')

const defaultOptions = generateDefaultOptions(animationData)

const NoData = () => {
  return (
    <div style={styles}>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height={400}
        width={400}
      />
    </div>
  )
}

export default NoData
