import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 0px;
  padding-top: 24px;
  width: 100%;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const RightContainer = styled.div``

export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;
  border-bottom: 1px solid #ccc;

  font-family: AvenirDemi;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`
export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`
export const EmptyCartButton = styled.button`
  border: none;
  background: var(--oh-orange);
  border-radius: 24px;
  padding: 8px 16px;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 2px;

  color: white;
  font-size: 14px;
  font-family: AvenirDemi;
`
export const HeadingText = styled.div`
  font-family: AvenirDemi;
  font-size: 28px;
  margin: 0;
  line-height: 0.8;
`

export const SubHeadingText = styled.div`
  font-size: 14px;
  font-family: Avenir;
`

export const ParentDetailsText = styled.div`
  font-size: 14px;
  margin-top: 8px;
`

export const ParentNumber = styled.span`
  font-size: 14px;
  font-family: Avenir;
  margin-top: 8px;
`
export const CoinImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 2px;
`
export const CoinsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`
