import { Tooltip, withStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import React from 'react'

import {
  CART_TOOLTIP_DISCOVERY,
  CART_TOOLTIP_SUBSCRIPTION
} from 'app/pages/Class/Inventory/constants'
import CoinIcon from 'assets/images/coin.png'

import * as Styles from './styles'

import { transformCartItem } from '../../../../../helpers'

const CustomTooltip = withStyles({
  tooltip: {
    color: 'while',
    fontSize: '14px',
    backgroundColor: 'rgba(0,0,0,0.85)',
    padding: '10px'
  }
})(Tooltip)

const CartItem = ({ item, onEdit, onDelete }) => {
  const {
    subjectName,
    subjectImage,
    batchLevel,
    bookingType,
    batchTimings,
    startDate,
    endDate,
    price
  } = transformCartItem(item)
  return (
    <Styles.ItemContainer>
      {/* <Styles.StudentName>
        {item.studentName} (Class {item.class})
      </Styles.StudentName> */}

      <Styles.CourseContainer>
        <Styles.CourseIcon src={subjectImage} alt={subjectName} />
        <Styles.CourseDetails>
          <Styles.CourseName>{subjectName}</Styles.CourseName>
          <Styles.CourseType>{bookingType}</Styles.CourseType>
        </Styles.CourseDetails>
        <Styles.ActionButtons>
          {/* <Styles.IconButtonLeft onClick={() => onEdit(item)}> */}
          {/* <EditIcon /> */}
          {/* </Styles.IconButtonLeft> */}
          <Styles.IconButtonRight onClick={() => onDelete(item)}>
            <DeleteIcon />
          </Styles.IconButtonRight>
        </Styles.ActionButtons>
      </Styles.CourseContainer>

      <Styles.InfoGrid>
        <Styles.InfoItem>
          <Styles.InfoLabel>Level</Styles.InfoLabel>
          <Styles.InfoValue>{batchLevel ? batchLevel : 'N/A'}</Styles.InfoValue>
        </Styles.InfoItem>
        <Styles.InfoItem>
          <Styles.InfoLabel>Batch timings</Styles.InfoLabel>
          <Styles.InfoValue>{batchTimings}</Styles.InfoValue>
        </Styles.InfoItem>
        <Styles.InfoItem>
          <Styles.InfoLabel>Start Date</Styles.InfoLabel>
          <Styles.InfoValue>{startDate}</Styles.InfoValue>
        </Styles.InfoItem>
        {bookingType === 'Discovery' && (
          <Styles.InfoItem>
            <Styles.InfoLabel>End Date</Styles.InfoLabel>
            <Styles.InfoValue>{endDate}</Styles.InfoValue>
          </Styles.InfoItem>
        )}
      </Styles.InfoGrid>

      <Styles.PriceContainer>
        <Styles.PriceLabel>Price</Styles.PriceLabel>
        <Styles.Price>
          <Styles.CoinImage src={CoinIcon} alt="Coin" />
          {price}
          <CustomTooltip
            arrow
            placement="top-end"
            title={
              bookingType === 'Discovery'
                ? CART_TOOLTIP_DISCOVERY
                : CART_TOOLTIP_SUBSCRIPTION
            }>
            <InfoIcon
              style={{ color: 'rgba(0, 0, 0, 0.36)', height: 18 }}
              color="rgba(0, 0, 0, 0.36)"
            />
          </CustomTooltip>
        </Styles.Price>
      </Styles.PriceContainer>
    </Styles.ItemContainer>
  )
}

export default CartItem
