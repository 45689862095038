import styled from 'styled-components'

export const animationStyles = styled.div`
  width: 10px;
  height: 10px;
  background-color: var(--oh-light-orange);
  position: relative;
  animation-name: leftRight;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes leftRight {
    0% {
      left: 0px;
      right: 0px;
    }
    50% {
      left: 10px;
      right: 0px;
    }
    100% {
      left: 0px;
      right: 10px;
    }
  }
`
