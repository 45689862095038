import styled from 'styled-components'

const breakpoint = '1024px'

export const Container = styled.div`
  width: 89%;

  @media only screen and (max-width: ${breakpoint}) {
    width: 85%;
  }

  .fc table,
  .fc td,
  .fc th,
  .fc tr {
    border: none !important;
  }

  .fc .fc-toolbar-title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.86);
    margin-left: 15px;
    font-style: normal;
    font-weight: normal;
  }

  .fc .fc-toolbar-chunk {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fc .fc-prev-button,
  .fc .fc-next-button {
    background: none;
    border: none;
    padding: 0;
    .fc-icon-chevron-left,
    .fc-icon-chevron-right {
      color: rgba(0, 0, 0, 0.86);
      font-size: 15px;
    }
  }

  .fc .fc-prev-button:active,
  .fc .fc-prev-button:hover,
  .fc .fc-prev-button:focus,
  .fc .fc-prev-button:focus-visible,
  .fc .fc-prev-button:not(:disabled):active:focus,
  .fc .fc-next-button:active,
  .fc .fc-next-button:hover,
  .fc .fc-next-button:focus,
  .fc .fc-next-button:focus-visible,
  .fc .fc-next-button:not(:disabled):active:focus {
    background: none;
    border: none;
    box-shadow: none;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: var(--oh-box-gray);
  }

  .fc-daygrid-dot-event {
    justify-content: center;
  }

  .fc-daygrid-dot-event:hover {
    background: none;
  }

  .fc .fc-col-header-cell-cushion {
    cursor: default;
  }
`
