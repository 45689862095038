import React from 'react'
import { useDispatch } from 'react-redux'

import constants from 'app/constants'
import { fetchTeacherListData } from 'app/store/actions/communicationEntity'

import MessageList from '../components/MessageList'
import StudentList from '../components/StudentList'
import TeacherList from '../components/TeacherList'
import * as styles from './styles'

const TeacherParentChat = () => {
  const dispatch = useDispatch()
  //get centre from local storage
  const centre = JSON.parse(localStorage.getItem('SELECTED_CENTRE'))

  React.useEffect(() => {
    dispatch(
      fetchTeacherListData(
        {
          queries: {
            centre_uuid: centre?.value,
            page_size: 1000
          }
        },
        false,
        false,
        constants.TEACHER_LIST
      )
    )
  }, [])

  return (
    <styles.ListContainer>
      <TeacherList />
      <StudentList />
      <MessageList />
    </styles.ListContainer>
  )
}

export default TeacherParentChat
