import { commonAttributes, tableColumnDefaultOption } from 'app/config'

export const columns = [
  {
    ...commonAttributes.student,
    name: 'user',
    label: 'Name',
    options: {
      ...commonAttributes.student.options,
      filterKey: 'name'
    }
  },
  {
    name: 'user.phone_number',
    label: 'Number',
    options: {
      ...tableColumnDefaultOption.options,
      filterKey: 'phone_number'
    }
  },
  {
    name: 'total_collected',
    label: 'Total Collected',
    ...commonAttributes.amount
  },
  {
    name: 'holding_amount',
    label: 'Total Pending',
    ...commonAttributes.amount
  },
  {
    name: 'collected_oh_share',
    label: 'Collected OH Share',
    ...commonAttributes.amount
  },
  {
    name: 'collected_partner_share',
    label: 'Collected Partner Share',
    ...commonAttributes.amount
  },
  {
    name: 'collected_tax',
    label: 'Collected GST',
    ...commonAttributes.amount
  }
]
