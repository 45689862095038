import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles'

export const MomentsTable = (props) => {
  const { data } = props
  return (
    <styles.Container>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#202020',
              color: '#fff'
            }}
          >
            <TableRow>
              <TableCell>Observation</TableCell>
              <TableCell>Media Link</TableCell>
              <TableCell>Skills Tagged</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(data)
              ? data?.moment_consumers?.map(
                  (moment, index) =>
                    moment && (
                      <TableRow key={index}>
                        <TableCell className="table-cell-custom">
                          {moment?.moment?.body}
                        </TableCell>
                        <TableCell>
                          {moment?.moment?.file?.url ? (
                            <a
                              href={moment?.moment?.file?.url}
                              target={'_blank'}
                            >
                              Click here to view/download
                            </a>
                          ) : (
                            'NA'
                          )}
                        </TableCell>
                        <TableCell>
                          {moment?.moment?.tags
                            ?.map((item) => {
                              return item?.name
                            })
                            .join(',')}
                        </TableCell>
                      </TableRow>
                    )
                )
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </styles.Container>
  )
}

MomentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
