
export const AvatarImageStyles = {
  height: '2rem',
  width: '2rem',
  borderRadius: '50%',
  flexShrink: 0
}

export const AvatarTextStyles = {
  width: '2rem',
  height: '2rem',
  backgroundColor: '#979797',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '700',
  borderRadius: '50%',
  textTransform: 'uppercase',
  flexShrink: 0
}

export const AvatarPillTextStyles = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '14px',
  backgroundColor: '#FAE6E9',
  color: '#EB5757',
  fontSize: '10px',
  fontWeight: '600',
  borderRadius: '14px',
  textTransform: 'lowercase',
  bottom: '-9px'
}