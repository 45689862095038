import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import TableComponent from 'app/components/generic/Table'
import { readOnlyTableDefaultOption } from 'app/config'

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    height: 'auto'
  }
}))

const Table = ({ title, data, columns, tableOptions, ...rest }) => {
  const classes = useStyles()

  const modifiedTableOptions = {
    ...readOnlyTableDefaultOption,
    download: false,
    sort: false,
    elevation: 0,
    rowsPerPageOptions: [10, 25, 50, 100],
    rowsPerPage: 25,
    ...tableOptions
  }

  return (
    <TableComponent
      title={title}
      data={data}
      columns={columns}
      options={modifiedTableOptions}
      className={classes.table}
      disableFullHeight
      {...rest}
    />
  )
}

export { Table }
