import React, { useEffect } from 'react'

import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'

export const useBatchSelection = () => {
  const [selectedBatches, setSelectedBatches] = React.useState([])
  const deselectBatch = (batch) => {
    if (batch.bulk) {
      selectedBatches.forEach((selectedBatch) => {
        eventEmitter.emit(constants.BATCH_SELECTION, selectedBatch)
      })
      setSelectedBatches([])
      return
    }

    eventEmitter.emit(constants.BATCH_SELECTION, batch)
    const temp = selectedBatches.filter(
      (selectedBatch) => selectedBatch.uuid !== batch.uuid
    )
    setSelectedBatches(temp)
  }

  useEffect(() => {
    const toggleBatchSelection = (batch) => {
      if (batch.reselect) return
      setSelectedBatches((selectedBatches) => {
        const index = selectedBatches.findIndex(
          (selectedBatch) => selectedBatch.uuid === batch.uuid
        )
        const isSelected = index > -1
        if (isSelected) {
          const temp = [...selectedBatches]
          temp.splice(index, 1)
          return temp
        }

        return [batch, ...selectedBatches]
      })
    }
    eventEmitter.addListener(constants.BATCH_SELECTION, toggleBatchSelection)
    return () => {
      eventEmitter.removeListener(
        constants.BATCH_SELECTION,
        toggleBatchSelection
      )
    }
  }, [])

  useEffect(() => {
    const handleReselection = (batch) => {
      if (batch?.bulk_deselect) deselectBatch({ bulk: true })

      /* Batch Data / Cart Data Persistence logic */
      setSelectedBatches((selectedBatches) => {
        selectedBatches.forEach((batch) => {
          eventEmitter.emit(constants.BATCH_SELECTION, {
            ...batch,
            reselect: true
          })
        })
        return selectedBatches
      })
    }
    eventEmitter.addListener(constants.BATCH_RESELECTION, handleReselection)
    return () => {
      eventEmitter.removeListener(
        constants.BATCH_RESELECTION,
        handleReselection
      )
    }
  }, [])

  return {
    selectedBatches,
    setSelectedBatches,
    deselectBatch
  }
}
