import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'

import { SUPPORT_FORM_LINK } from 'app/constants'

import {
  LoginButton,
  MobileInput,
  MobileInputValidation,
  MobileLabel,
  MobileNumberBox,
  OTPValidation,
  OtpButton,
  OtpInput,
  OtpLabel,
  Parent,
  SubTitle,
  Title,
  SupportLink
} from './styles'

export default function LoginForm({
  isValidPhone,
  setPhone,
  isValidOTP,
  setOTP,
  loginDisabled,
  sendOtp,
  sendLoginRequest,
  phone,
  otp,
  otpSent,
  loggingIn,
  sendingOtp,
  otpTimeLeft
}) {
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'Enter' && isValidOTP) sendLoginRequest()
    }

    window.addEventListener('keydown', onKeyDown)

    return () => window.removeEventListener('keydown', onKeyDown)
  }, [])

  return (
    <Parent>
      <Title>Welcome Admin!</Title>
      <SubTitle>Please Login First</SubTitle>
      <MobileLabel>Registered mobile number</MobileLabel>
      <MobileNumberBox>
        <MobileInput
          data-testid="phone-input"
          autoFocus
          invalid={!isValidPhone}
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
        />
        <OtpButton
          data-testid="send-otp-btn"
          isDisabled={otpTimeLeft > 0}
          onClick={() => sendOtp()}
        >
          {otpSent
            ? sendingOtp
              ? 'sending'
              : otpTimeLeft > 0
              ? `resend in ${otpTimeLeft}`
              : 'resend OTP'
            : 'send OTP'}
        </OtpButton>
      </MobileNumberBox>
      <MobileInputValidation
        data-testid="phone-validation"
        invalid={!isValidPhone}
      >
        please use 10 digit valid mobile number
      </MobileInputValidation>
      <OtpLabel>OTP</OtpLabel>
      <OtpInput
        data-testid="otp-input"
        invalid={!isValidOTP}
        onChange={(e) => setOTP(e.target.value)}
        value={otp}
      />
      <OTPValidation data-testid="otp-validation" invalid={!isValidOTP}>
        please use 6 digit OTP which you have received
      </OTPValidation>
      <LoginButton
        data-testid="login-btn"
        isDisabled={loginDisabled}
        onClick={() => sendLoginRequest()}
        type="submit"
      >
        {loggingIn ? 'Logging in...' : 'Login'}
      </LoginButton>
      <SupportLink>
        <a href={SUPPORT_FORM_LINK}>
          <p>Stuck? Summon Support! </p>
        </a>
      </SupportLink>
    </Parent>
  )
}

LoginForm.propTypes = {
  isValidPhone: PropTypes.bool.isRequired,
  setPhone: PropTypes.func.isRequired,
  isValidOTP: PropTypes.bool.isRequired,
  setOTP: PropTypes.func.isRequired,
  loginDisabled: PropTypes.bool.isRequired,
  sendOtp: PropTypes.func.isRequired,
  sendLoginRequest: PropTypes.func.isRequired,
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  otp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  otpSent: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  sendingOtp: PropTypes.bool.isRequired,
  otpTimeLeft: PropTypes.number.isRequired
}
