import { InputLabel, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Select } from 'app/components/generic/FormElements'
import { TextField } from 'app/components/generic/FormElements/TextField'
import ModalForm from 'app/components/ModalForm'
import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'
import { validateFee, validateNotEmpty, validateCount } from 'app/validators'

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))

const TeacherDispatchForm = (props) => {
  const classes = useStyles()
  const formRef = React.useRef(null)

  const { isModalOpen, closeModal, onSaveClick, formData } = props

  const handleOnSaveClick = React.useCallback(async () => {
    if (formRef.current) {
      const { submitForm, validateForm, values } = formRef.current
      try {
        const errors = await validateForm(values)
        if (!isEmpty(errors)) {
          // call submit form , it will ask to validate all fields
          submitForm()
          // emit event to enable save button again
          eventEmitter.emit(constants.MODAL_FORM_SAVE_ERROR)
        } else {
          // form is valid
          try {
            await onSaveClick(values)
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [onSaveClick])

  const formElement = React.useMemo(() => {
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { validate }) => {
          validate(values)
        }}
        innerRef={formRef}
        className={classes.root}
      >
        {({ errors, setFieldValue, values }) => (
          <Form>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'count'}
                label={'Count'}
                variant="outlined"
                required
                disabled
                validate={validateCount({ isRequired: true })}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'value'}
                label={'Value'}
                variant="outlined"
                required
                disabled
                validate={validateFee({ isRequired: true })}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="dispatchMode-label">Mode of Dispatch</InputLabel>
              <Select
                label="Mode of Dispatch"
                name="mode"
                defaultValue={
                  get(formData, 'mode') !== undefined
                    ? get(formData, 'mode')
                    : ''
                }
                validate={validateNotEmpty({ isRequired: true })}
              >
                <MenuItem key={'cash'} value="cash">
                  {' '}
                  Cash{' '}
                </MenuItem>
                <MenuItem key={'bank_transfer'} value="bank_transfer">
                  {' '}
                  Bank Transfer{' '}
                </MenuItem>
                <MenuItem key={'razorpay'} value="razorpay">
                  {' '}
                  Razorpay{' '}
                </MenuItem>
              </Select>
            </FormControl>

            {values.mode === 'bank_transfer' ? (
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  name={'utr_id'}
                  label={'UTR ID'}
                  variant="outlined"
                  required
                  validate={validateNotEmpty({ isRequired: true })}
                />
              </FormControl>
            ) : null}
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <TextField
                name={'notes'}
                label={'Notes'}
                variant="outlined"
                required
                validate={validateNotEmpty({ isRequired: true })}
              />
            </FormControl>
          </Form>
        )}
      </Formik>
    )
  }, [classes, formData])

  return (
    <ModalForm
      modalTitle="Dispatch Form"
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      onSaveClick={handleOnSaveClick}
      buttonLabel="Confirm"
    >
      {formElement}
    </ModalForm>
  )
}

TeacherDispatchForm.propTypes = {
  formData: PropTypes.object.isRequired,
  handleFormData: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired
}

export default TeacherDispatchForm
