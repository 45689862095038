import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableHead } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles'

export const StudentsTable = (props) => {
  const { data } = props
  const [studentData] = data

  return (
    <styles.Container>
      <TableContainer>
        <Table>
          <TableHead
            className="header"
            sx={{
              backgroundColor: '#202020',
              color: '#fff'
            }}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              {studentData?.parentName ? (
                <TableCell>Parent Name</TableCell>
              ) : (
                <TableCell />
              )}
              {studentData?.parentNumber ? (
                <TableCell>Phone Number</TableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              (student, index) =>
                student && (
                  <TableRow key={index}>
                    <TableCell>{student?.name}</TableCell>
                    <TableCell>{student?.parentName}</TableCell>
                    <TableCell>{student?.parentNumber}</TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </styles.Container>
  )
}

StudentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}
