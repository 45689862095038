import { generateRandomId } from '@openhouse-technologies/utils'
import { isEmpty } from 'lodash'

import { apiEndpoints } from './common'

import { makeRequestUrl } from '../app/helpers'
import { get } from '../request'

export const fetchExploreProgressData = async ({ url, queries } = {}) => {
  const filterQueries = {
    batch_type: 'exploration,exploration_camp',
    ...queries
  }
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.scheduleFeedback}`, filterQueries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching states :- ', error.message)
    throw error
  }
}
