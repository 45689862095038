import * as types from '../types/classEntity'

export const fetchSubjectData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_SUBJECT_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchSubjectDataSuccess = (value, entity) => ({
  type: types.FETCH_SUBJECT_DATA_SUCCESS,
  value,
  entity
})

export const fetchSubjectCategoryData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_SUBJECT_CATEGORY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchSubjectCategoryDataSuccess = (value, entity) => ({
  type: types.FETCH_SUBJECT_CATEGORY_DATA_SUCCESS,
  value,
  entity
})

export const fetchBatchData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_BATCH_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchBatchDataSuccess = (value, entity) => ({
  type: types.FETCH_BATCH_DATA_SUCCESS,
  value,
  entity
})

export const fetchExploreSessionFeedbackData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_EXPLORE_SESSION_FEEDBACK_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchExploreSessionFeedbackDataSucsess = (value, entity) => ({
  type: types.FETCH_EXPLORE_SESSION_FEEDBACK_DATA_SUCCESS,
  value,
  entity
})

export const fetchScheduleSessionActivityData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_SCHEDULE_SESSION_ACTIVITY_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchScheduleSessionActivityDataSuccess = (value, entity) => ({
  type: types.FETCH_SCHEDULE_SESSION_ACTIVITY_DATA_SUCCESS,
  value,
  entity
})

export const fetchBoardData = (queries, isPaginating, isFiltering, entity) => ({
  type: types.FETCH_BOARD_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchBoardDataSuccess = (value, entity) => ({
  type: types.FETCH_BOARD_DATA_SUCCESS,
  value,
  entity
})

export const fetchStandardData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_STANDARD_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchStandardDataSuccess = (value, entity) => ({
  type: types.FETCH_STANDARD_DATA_SUCCESS,
  value,
  entity
})

export const fetchCourseData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_COURSE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchCourseDataSuccess = (value, entity) => ({
  type: types.FETCH_COURSE_DATA_SUCCESS,
  value,
  entity
})

export const fetchTrialSessionsData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_TRIAL_SESSIONS_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchTrialSessionsDataSuccess = (value, entity) => ({
  type: types.FETCH_TRIAL_SESSIONS_DATA_SUCCESS,
  value,
  entity
})

export const fetchEnrolmentData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_ENROLMENT_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchEnrolmentDataSuccess = (value, entity) => ({
  type: types.FETCH_ENROLMENT_DATA_SUCCESS,
  value,
  entity
})

export const fetchMakeUpSessionData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_MAKE_UP_SESSION_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchMakeUpSessionDataSuccess = (value, entity) => ({
  type: types.FETCH_MAKE_UP_SESSION_DATA_SUCCESS,
  value,
  entity
})

export const fetchExploreSessionData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_EXPLORE_SESSION_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchExploreSessionDataSuccess = (value, entity) => ({
  type: types.FETCH_EXPLORE_SESSION_DATA_SUCCESS,
  value,
  entity
})

export const fetchExploreEnrolmentData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_EXPLORE_ENROLMENT_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchExploreEnrolmentDataSuccess = (value, entity) => ({
  type: types.FETCH_EXPLORE_ENROLMENT_DATA_SUCCESS,
  value,
  entity
})

export const fetchExcelEnrolmentData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_EXCEL_ENROLMENT_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchExcelEnrolmentDataSuccess = (value, entity) => ({
  type: types.FETCH_EXCEL_ENROLMENT_DATA_SUCCESS,
  value,
  entity
})

export const fetchPastAttendanceData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_PAST_ATTENDANCE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchPastAttendanceDataSuccess = (value, entity) => ({
  type: types.FETCH_PAST_ATTENDANCE_DATA_SUCCESS,
  value,
  entity
})

export const fetchFutureAttendanceData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_FUTURE_ATTENDANCE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchFutureAttendanceDataSuccess = (value, entity) => ({
  type: types.FETCH_FUTURE_ATTENDANCE_DATA_SUCCESS,
  value,
  entity
})

export const fetchSchoolAttendanceData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_SCHOOL_ATTENDANCE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchSchoolAttendanceDataSuccess = (value, entity) => ({
  type: types.FETCH_SCHOOL_ATTENDANCE_DATA_SUCCESS,
  value,
  entity
})

export const fetchInventorySchedulesData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_INVENTORY_SCHEDULES_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchInventorySchedulesDataSuccess = (value, entity) => ({
  type: types.FETCH_INVENTORY_SCHEDULES_DATA_SUCCESS,
  value,
  entity
})

export const fetchOpendayScheduleData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_OPENDAY_SCHEDULE_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchOpendayScheduleDataSuccess = (value, entity) => ({
  type: types.FETCH_OPENDAY_SCHEDULE_DATA_SUCCESS,
  value,
  entity
})

export const fetchOpendaySlotsData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_OPENDAY_SLOTS_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchOpendaySlotsDataSuccess = (value, entity) => ({
  type: types.FETCH_OPENDAY_SLOTS_DATA_SUCCESS,
  value,
  entity
})

export const fetchOpportunitiesData = (
  queries,
  isPaginating,
  isFiltering,
  entity
) => ({
  type: types.FETCH_OPPORTUNITIES_DATA,
  queries,
  isPaginating,
  isFiltering,
  entity
})

export const fetchOpportunitiesDataSuccess = (value, entity) => ({
  type: types.FETCH_OPPORTUNITIES_DATA_SUCCESS,
  value,
  entity
})

export const updateClassData = (entity, event, value) => ({
  type: types.UPDATE_CLASS_DATA,
  entity,
  event,
  value
})

export const updateClassDataSuccess = (entity, event, value) => ({
  type: types.UPDATE_CLASS_DATA_SUCCESS,
  entity,
  event,
  value
})

export const createDemoClassBooking = (value) => ({
  type: types.CREATE_DEMO_CLASS_BOOKING,
  value
})

export const createClassBooking = (value) => ({
  type: types.CREATE_CLASS_BOOKING,
  value
})

export const createExcelBooking = (value) => ({
  type: types.CREATE_EXCEL_BOOKING,
  value
})
